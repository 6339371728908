import { Dialog } from "@abb/abb-common-ux-react";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { hideDialog } from "../../store/dialog/action";

const CustomDialog = (
    props: ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    return props.isOpen && props.component ? (
        <Dialog
            containerClassName={
                props.customClassName || props.data.customClassName
            }
            closeOnEscape={false}
            dimBackground={props.removeBackground ? false : true}
            title={props.modalTitle}
            isOpen={props.isOpen}
            showCloseButton={true}
            onClose={() => {
                props.closeDialog();
            }}
            standardButtonsOnBottom={
                props.data.standardButtonsOnBottom && [
                    ...props.data.standardButtonsOnBottom,
                ]
            }
            // standardButtonsOnTop={[]}
        >
            <props.component {...props.data} handleClose={props.closeDialog} />
        </Dialog>
    ) : (
        <></>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        isOpen: state.dialog.show,
        component: state.dialog.component,
        data: state.dialog.data || {},
        modalTitle: state.dialog.modalTitle,
        customClassName: state.dialog.customClassName,
        removeBackground: state.dialog.removeBackground,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        closeDialog: () => {
            dispatch(hideDialog());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialog);
