import {
    fontSize,
    canvasPrimaryTextColor,
    canvasPrimaryBorderColor,
    functionTitleTopPadding,
    OBJECT_BLOCK_TITLE_HEIGHT,
    GRID_PIXEL,
    PINS_DISTANCE,
    BLOCK_BOTTOM_PADDING,
} from './UiConfig';
import { fabric } from 'fabric';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import { FabricDropDownTitle } from './titleRectangle';

interface Params {
    asset: ObjectTypeDetail;
    parent: FabricDropDownTitle;
}

export class FabricObjectRectangle extends fabric.Group {
    data: { asset: ObjectTypeDetail; parent: FabricDropDownTitle };
    constructor(options: Params) {
        const { assetName, assetVersion, scale: scaleX, scale: scaleY, outputs } = options.asset;
        const { asset } = options;
        let width = GRID_PIXEL * 15;
        const length = outputs.length > 0 ? outputs.length : 1;
        const height =
            OBJECT_BLOCK_TITLE_HEIGHT + (outputs.length - 1) * PINS_DISTANCE + BLOCK_BOTTOM_PADDING;

        const titleText = new fabric.Text(`${assetName.length > 15 ? assetName.substring(0,15)+"..." : assetName} ${assetVersion}`, {
            fontSize: fontSize,
            originX: 'center',
            originY: 'center',
            borderColor: canvasPrimaryTextColor,
            top: functionTitleTopPadding + fontSize / 2,
        });

        titleText.data = { tooltip: `${assetName} ${assetVersion}` };

        const textWidth = titleText.getScaledWidth() + 60;

        width = textWidth > width ? textWidth : width;
        const rectLeft = -width / 2;

        let rectHeight = height;

        const rect = new fabric.Rect({
            width: width,
            height: rectHeight,
            originX: 'left',
            originY: 'top',
            left: rectLeft,
            fill: 'white',
            backgroundColor: 'transparent',
            stroke: canvasPrimaryBorderColor,
            name: 'rect',
        });
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        super([rect, titleText], {
            left: x,
            top: y + (rect.getScaledHeight() / 2) * scaleY + options.parent.getScaledHeight() / 2,
            subTargetCheck: true,
            originX: 'center',
            originY: 'center',
            name: 'group-object',
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
            borderColor: 'transparent',
            hoverCursor: 'pointer',
            selectable: false,
            evented: false,
        });

        this.data = {
            asset,
            parent: options.parent,
        };
    }
}
