import _ from 'lodash';
import './style.scss';
import uuid from 'uuid';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import React, { useState, useEffect } from 'react';
import SearchBar from '../../../../components/SearchBar';
import TabNavList from '../../../../components/TabNavList';
import CanvasController from '../../../../components/Fabric';
import { MODEL_TYPES } from '../../../../utils/constants/appConstants';
import { handleSidebarToggle } from '../../../../store/modelsPage/actions';
import { Icon, Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import { AssetType } from '../../../../transformers/AssetType';
import { FixedSizeList as List } from 'react-window';
import DraggableListRow from './DraggableRow';
import Condition from '../../../../components/shared/Condition';
import Loader from '../../../../components/Loader';
import { filterListData } from '../../../../utils/helpers';
import AutoSizer from 'react-virtualized-auto-sizer';

import './style.scss';
import { TOOLTIP_MESSAGES } from '../../../../utils/constants/uiConstants';

interface EditToolBoxProps {
    isAssetLoading: boolean;
    canvas: CanvasController;
    objectTypeList: AssetType[];
    functionTypeList: AssetType[];
    objectListSearch: (search: string) => void;
    functionListSearch: (search: string) => void;
    computeModels: AssetType[];
}

const EditToolBox = (
    props: EditToolBoxProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const { canvas, objectTypeList, functionTypeList, objectListSearch, functionListSearch } =
        props;
    const [searchVal, updateSearchVal] = useState('');
    const [typeSearchVal, updateTypeSearVal] = useState('');
    const [fileteredtypeList, updateTypeList] = useState([...props.computeModels] as AssetType[]);
    const [itemList, updateItemList] = useState(props.objectTypeList as AssetType[]);
    const [tabSelected, updateTabSelected] = useState(MODEL_TYPES.OBJECTS.camelCaseLabel);

    const [tabNavList, updateTabNavList] = useState([
        MODEL_TYPES.OBJECTS.camelCaseLabel,
        MODEL_TYPES.FUNCTIONS.camelCaseLabel,
        MODEL_TYPES.TYPES.camelCaseLabel,
    ] as any[]);

    const onTabChange = (oldIndex: any, newIndex: number) => {
        updateTabSelected(tabNavList[newIndex]);

        if (tabNavList[newIndex] === MODEL_TYPES.OBJECTS.camelCaseLabel) {
            const FilterobjectTypeList=objectTypeList.filter(x => x.assetRef===props.activeAsset!.assetRef);
            updateItemList(FilterobjectTypeList);
            updateSearchVal(props.objectSearchValue);
        } else if (tabNavList[newIndex] === MODEL_TYPES.FUNCTIONS.camelCaseLabel) {
            updateItemList(functionTypeList);
            updateSearchVal(props.functionSearchValue);
        } else {
            updateItemList([...fileteredtypeList]);
            updateSearchVal(typeSearchVal);
        }
    };

    useEffect(() => {
        if (tabSelected === MODEL_TYPES.OBJECTS.camelCaseLabel) {
            objectListSearch('');
        }
    }, []);
    
    useEffect(() => {
        if (tabSelected === MODEL_TYPES.OBJECTS.camelCaseLabel) {
            
            const FilterobjectTypeList=objectTypeList.filter(x => x.assetRef===props.activeAsset!.assetRef);

            updateItemList(FilterobjectTypeList);
        } else if (tabSelected === MODEL_TYPES.FUNCTIONS.camelCaseLabel) {
            updateItemList(functionTypeList);
                } 
        else {
            updateItemList([...fileteredtypeList]);
        }
        
    }, [objectTypeList, functionTypeList, fileteredtypeList]);

    const handleSearch = (value: string) => {
        updateSearchVal(value);
        if (tabSelected === MODEL_TYPES.OBJECTS.camelCaseLabel) {
            objectListSearch(value);
        } else if (tabSelected === MODEL_TYPES.FUNCTIONS.camelCaseLabel) {
            functionListSearch(value);
        } else {
            const filteredTypeListData = filterListData(props.computeModels, 'assetName', value);
            updateTypeList(filteredTypeListData);
            updateTypeSearVal(value);
        }
    };

    useEffect(() => {
        if (props.canvas) {
            props.canvas.resizeCanvas && props.canvas.resizeCanvas();
        }
    }, [props.toggleStatus]);

    const toggleSidebar = () => {
        if (props.toggleStatus) {
            props.handleSidebarToggle(false);
        } else {
            props.handleSidebarToggle(true);
        }
    };
    function listRowRenderer({
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it)
    }: {
        // key: string;
        index: number;
        style: any;
    }) {
        return (
            <div style={style} className="edit-left-nav">
                <DraggableListRow
                    id={uuid()}
                    item={itemList[index]}
                    canvas={canvas}
                    editMode={true}
                />
            </div>
        );
    }

    return props.toggleStatus ? (
        <div className="toolbox-sidebar">
            <div className="toolbox-text" onClick={toggleSidebar}>
                Toolbox
            </div>
        </div>
    ) : (
        <div className="tool-box">
            <div className="toolbox-heading">
                <div className="toolbox">Toolbox</div>
                <WithTooltip>
                    <Icon name="abb/pin" onClick={toggleSidebar} className="pin-icon" />
                    <Tooltip>{TOOLTIP_MESSAGES.TOGGLE_SIDEBAR}</Tooltip>
                </WithTooltip>
            </div>
            <TabNavList
                errorFlag={false}
                allowRemove={false}
                tabNavList={tabNavList}
                onTabChange={onTabChange}
                activeTab={_.indexOf(tabNavList, tabSelected)}
            />
            <div className="search-bar-div">
                <SearchBar
                    placeholder={`Search ${tabSelected}`}
                    borderColor="border-dark-gray"
                    value={searchVal}
                    onChange={handleSearch}
                />
            </div>
            <div className="object-function-list">
                <Condition when={props.isAssetLoading}>
                    <Loader sizeClass={'small'} type={'radial'} />
                </Condition>
                <Condition when={!props.isAssetLoading}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List
                                height={height}
                                itemCount={itemList.length}
                                itemSize={40}
                                width={width}
                            >
                                {listRowRenderer}
                            </List>
                        )}
                    </AutoSizer>
                </Condition>
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        toggleStatus: state.modelsPage.sidebarToggleStatus,
        objectSearchValue: state.modelsPage.objectTypeList.filters.search,
        functionSearchValue: state.modelsPage.functionTypeList.filters.search,
        activeAsset:state.modelsPage.activeAsset,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleSidebarToggle: (toggleStatus: boolean) => {
            dispatch(handleSidebarToggle(toggleStatus));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditToolBox);
