import _ from 'lodash';
import { AssetTypeDetail } from '..';
import { IODataTypes, AssetsType, AlarmEventType } from '../../../components/Fabric/types';
import { EventTypeConstructorOptions } from './types';
import { eventInputConverter, eventOutputConverter } from '../utils/eventIOConverter';

class EventTypeDetail extends AssetTypeDetail {
    outputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        isPublished?: boolean;
        connected: boolean;
        alarmId?: string;
        eventId?: string;
    }[];
    inputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        connected: boolean;
        defaultValue?: string;
    }[];
    assetType: AssetsType;
    modelId: string;
    type: AlarmEventType;
    constructor(options: EventTypeConstructorOptions) {
        super(options);
        const { properties } = options;

        this.outputs = [];
        this.inputs = [];

        this.assetType = AssetsType.Alarm;

        this.modelId = options.modelId;
        this.type = options.type;

        if (properties) {
            const { outputs, inputs } = properties;
            const inputKeys = Object.keys(inputs);

            this.outputs = eventOutputConverter({
                eventId: this.assetRef,
                modelId: this.modelId,
                outputObj: outputs,
                nodeId: this.nodeId,
                eventType: this.type,
            });
            this.inputs = eventInputConverter({
                keys: inputKeys,
                object: inputs,
                refPath: `inputs`,
                nodeId: this.nodeId,
            });
        }
    }
}

export function CreateEventTypeDetail(options: EventTypeConstructorOptions) {
    const { typeId, properties } = options;

    if (!(typeId || properties)) {
        return null;
    }

    return new EventTypeDetail(options);
}

export default EventTypeDetail;
