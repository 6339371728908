import React, { useState, useEffect, useMemo, useRef } from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    CollapsibleContainer,
    Collapsible,
    Checkbox,
    Icon,
} from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../store";
import { menuProps as AppProps } from "./types";
import Condition from "../shared/Condition";
import Menu, { SubMenu, MenuItem } from "rc-menu";
import FloatingMenu from "./FloatingMenu";
import CustomTooltip from "../../components/CustomTooltip";

function NthLevelMenu(
    props: AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const {
        data,
        key,
        keyIdentifier,
        label,
        labelFunction,
        showCheckbox,
        showCheckboxMethod,
        treeState,
        updateCheckState,
        setSelectedState,
        indices,
        nLevelMenuProps,
        nextLevelAsyncLoader,
        floatingMenuProps,
        floatingMenuPropMethod,
        icon,
        selected,
        updateSelected,
        showMenuOnlyOnSelect,
    } = props;
    const [checked, setChecked] = useState(false);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
    const [isOpenedOnce, setIsOpenedOnce] = useState(false);
    const [menuState, setMenuState] = useState({} as any);
    const menuProps = nLevelMenuProps[indices.length - 1];
    const [floatingMenuData, setFloatingMenuData] = useState(
        floatingMenuProps || []
    );
    const [displayCheckBox, setDisplayCheckBox] = useState(
        showCheckbox || false
    );

    const nameToDisplay = (): string => {
        if (labelFunction) {
            return labelFunction(data);
        }
        let labelKeyArray = label.split(".");
        let nameToBeDisplayed = data;
        labelKeyArray.forEach((labelKey) => {
            nameToBeDisplayed = nameToBeDisplayed[labelKey];
        });
        return nameToBeDisplayed;
    };

    useEffect(() => {
        if (
            indices.length === nLevelMenuProps.length &&
            Object.keys(menuState).length
        ) {
            if (selected !== data[keyIdentifier] && menuState.selected) {
                setSelectedState(indices, { selected: false });
            } else if (
                selected === data[keyIdentifier] &&
                !menuState.selected
            ) {
                setSelectedState(indices, { selected: true });
            }
        }
        if (menuState.selected && !isCollapsibleOpen && !isOpenedOnce) {
            const defaultModel = document.getElementById(
                `${data[keyIdentifier]}_collapsible`
            );
            if (defaultModel) {
                defaultModel.click();
                setIsCollapsibleOpen(true);
                setIsOpenedOnce(true);
            }
        }
    }, [selected, menuState]);

    useEffect(() => {
        let menuPropIndex = indices.length - 1;
        let currentObj: any = treeState;
        indices.forEach((childKey, index) => {
            let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
            if (nextLevelMenuKey && index < menuPropIndex) {
                currentObj = currentObj[childKey][nextLevelMenuKey];
            } else {
                currentObj = currentObj && currentObj[childKey];
            }
        });
        setMenuState(currentObj || {});
        setChecked(currentObj && currentObj.checked);
        if (
            nextLevelAsyncLoader &&
            isOpenedOnce &&
            currentObj &&
            currentObj.checked
        ) {
            let nextLevelMenuKey = nLevelMenuProps[menuPropIndex].nextLevelKey;
            let isAllChildChecked = true;
            currentObj &&
                currentObj[nextLevelMenuKey] &&
                currentObj[nextLevelMenuKey].forEach((item: any) => {
                    isAllChildChecked = isAllChildChecked && item.checked;
                });
            if (currentObj.checked !== isAllChildChecked) {
                updateCheckState(indices, { checked: isAllChildChecked });
            }
        }
        if (floatingMenuPropMethod) {
            setFloatingMenuData(floatingMenuPropMethod(data));
        }
        if (showCheckboxMethod) {
            setDisplayCheckBox(showCheckboxMethod(data));
        }
    }, [props.treeState]);

    const checkboxElement = (
        <Checkbox
            sizeClass="small"
            className="checkbox"
            disabled={
                indices.length !== nLevelMenuProps.length &&
                (!data[nLevelMenuProps[indices.length - 1].nextLevelKey] ||
                    !data[nLevelMenuProps[indices.length - 1].nextLevelKey]
                        .length)
            }
            value={
                checked &&
                (indices.length === nLevelMenuProps.length ||
                    (!!data[nLevelMenuProps[indices.length - 1].nextLevelKey] &&
                        !!data[nLevelMenuProps[indices.length - 1].nextLevelKey]
                            .length))
            }
            onChange={() => {
                updateCheckState(indices, { checked: !checked });
                if (props.onCheckBoxClick) {
                    props.onCheckBoxClick(data, !checked);
                }
            }}
        />
    );

    const contentElement = (
        <div
            onClick={(e) => {
                e.stopPropagation();
                updateSelected(data[keyIdentifier]);
                if (e.isPropagationStopped) {
                    props.onClick && props.onClick(data);
                }
            }}
            className={`content ${props.showToolTip ? "" : "no-tooltip"}`}
        >
            {icon && (
                <Icon
                    sizeClass="small"
                    name={icon || ""}
                    className="list-icon"
                />
            )}
            {nameToDisplay()}
        </div>
    );

    return (
        <>
            {props.children && indices.length < nLevelMenuProps.length ? (
                <CollapsibleContainer className="wrapper-func-collapsible-container">
                    <Condition when={displayCheckBox}>
                        <div className="select-all">{checkboxElement}</div>
                    </Condition>
                    <Collapsible
                        itemId={`${data[keyIdentifier]}_collapsible`}
                        icon={icon || undefined}
                        onClick={(id, e) => {
                            e.stopPropagation();
                        }}
                        onExpand={() => {
                            if (!data[menuProps.nextLevelKey]) {
                                nextLevelAsyncLoader &&
                                    nextLevelAsyncLoader(data);
                            }
                        }}
                        onExpanding={() => {
                            setIsCollapsibleOpen(true);
                        }}
                        onCollapsing={() => {
                            setIsCollapsibleOpen(false);
                        }}
                        title={
                            props.showToolTip ? (
                                <CustomTooltip
                                    position="top"
                                    text={nameToDisplay()}
                                >
                                    <>{nameToDisplay()}</>
                                </CustomTooltip>
                            ) : (
                                nameToDisplay()
                            )
                        }
                        id={`${data[keyIdentifier]}_collapsible`}
                    >
                        {props.children}
                        <Condition
                            when={
                                floatingMenuData &&
                                floatingMenuData.length &&
                                (showMenuOnlyOnSelect
                                    ? menuState.selected
                                    : true)
                            }
                        >
                            <FloatingMenu
                                data={data}
                                keyId={data[keyIdentifier]}
                                isChild={
                                    indices.length === nLevelMenuProps.length
                                }
                                floatingMenuProps={floatingMenuData || []}
                            />
                        </Condition>
                    </Collapsible>
                </CollapsibleContainer>
            ) : (
                <div
                    className={`menu-element ${
                        selected === data[keyIdentifier] ? "selected" : ""
                    } ${!displayCheckBox ? "no-checkbox" : ""} ${
                        floatingMenuData &&
                        floatingMenuData.length &&
                        (showMenuOnlyOnSelect ? menuState.selected : true)
                            ? "with-floating-menu"
                            : ""
                    }`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Condition when={displayCheckBox}>
                        {checkboxElement}
                    </Condition>
                    {props.showToolTip ? (
                        <CustomTooltip position="top" text={nameToDisplay()}>
                            {contentElement}
                        </CustomTooltip>
                    ) : (
                        contentElement
                    )}
                    <Condition
                        when={
                            floatingMenuData &&
                            floatingMenuData.length &&
                            (showMenuOnlyOnSelect ? menuState.selected : true)
                        }
                    >
                        <FloatingMenu
                            data={data}
                            keyId={data[keyIdentifier]}
                            isChild={indices.length === nLevelMenuProps.length}
                            floatingMenuProps={floatingMenuData || []}
                        />
                    </Condition>
                </div>
            )}
        </>
    );
}

function mapStateToProps(state: StoreState) {
    return {};
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NthLevelMenu);
