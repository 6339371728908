export const modelsPageActionTypes = <const>{
    CANCEL_UNSAVED_MODEL_CHANGES: "CANCEL_UNSAVED_MODEL_CHANGES",
    CHANGE_PAGINATION_DETAILS: "CHANGE_PAGINATION_DETAILS",
    SET_ACTIVE_MODAL_IMPORT: "SET_ACTIVE_MODAL_IMPORT",
    UPDATE_OVERAll_SEVERITY_FUNCTION_ID: "UPDATE_OVERAll_SEVERITY_FUNCTION_ID",
    UPDATE_ACTIVEMODEL_JSON_DATA: "UPDATE_ACTIVEMODEL_JSON_DATA",
    HANDLE_IS_FUNC_UPDATE_AVAILABLE: "HANDLE_IS_FUNC_UPDATE_AVAILABLE",
    HANDLE_UNIQUE_TYPEID_LOADING: "HANDLE_UNIQUE_TYPEID_LOADING",
    HANDLE_EXISTING_TYPEID_ERROR: "HANDLE_EXISTING_TYPEID_ERROR",
    HANDLE_UNIQUE_TYPEID:"HANDLE_UNIQUE_TYPEID",
    HANDLE_UNIQUE_TYPEID_MESSAGE:"HANDLE_UNIQUE_TYPEID_MESSAGE",
    HANDLE_UNIQUE_TYPEID_FAILED:"HANDLE_UNIQUE_TYPEID_FAILED",
    HANDLE_UNIQUE_TYPEID_ISEXIST:"HANDLE_UNIQUE_TYPEID_ISEXIST",
    HANDLE_UNIQUE_LIBRARY_ISEXIST:"HANDLE_UNIQUE_LIBRARY_ISEXIST"
};
