import React, { useState, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Checkbox, Button } from '@abb/abb-common-ux-react';

import './style.scss';
import {
    checkFilesValidity,
    getJsonArrayFromZip,
    getTypeDetailsFromImportedFile,
} from '../../utils/helpers';
import { StoreState } from '../../store';
import {
    importSingleType,
    setDetailsForTypeLevelImport,
    addToSelectedItemForImport,
    removeFromSelectedItemForImport,
    clearSelectedItemForImport,
} from '../../store/exportAsset/actions';
import {
    CONFIRMATION_BUTTON,
    CONNECTION_DISCONNECTED_CONFIRMATION_MESSAGE,
} from '../../utils/constants/uiConstants';
import { PayloadForTypeLevelImport } from '../../store/exportAsset/types';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { hideDialog, showDialog } from '../../store/dialog/action';
import MessageModal from '../MessageModal';
import {
    updateConfigurationToolMode,
    createConfigurationToolJson,
} from '../../store/configurationTool/actions';
import { setActiveModalForImport } from '../../store/modelsPage/actions';
import { CONFIGURATION_TOOL_MODE } from '../../utils/constants/appConstants';
import { AssetType } from '../../transformers/AssetType';
import { ModelsPageState } from '../../store/modelsPage/types';

const checkValidityForImportFile = (val: any) => {
    if (val && /.zip$/i.test(val.name) && val.name !== '') {
        return true;
    }
    return false;
};

interface ImportForSingleTypeProps {
    importObjectTypes: [];
    setImportObjectTypes: any;
    invalidSingleImport: any;
    setInvalidSingleImport: any;
    closeImportExportDialog: () => void;
}

const ImportForSingleType = (
    props: ImportForSingleTypeProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDisPatchToProps>
) => {
    const {
        activeAsset,
        importSingleType,
        showSpinnerModal,
        importObjectTypes,
        invalidSingleImport,
        setImportObjectTypes,
        setInvalidSingleImport,
        closeImportExportDialog,
        setDetailsForTypeLevelImport,
        showConfirmationDialog,
    } = props;

    const [mode, setMode] = useState('Import');
    const [filesForImport, setFilesForImport] = useState([] as any);
    const [selectedForImport, setSelectedForImport] = useState('');
    const [libraryReferences, setLibraryReferences] = useState([]);
    const [libraryDetails, setLibraryDetails] = useState([] as { libraryName: string }[]);

    useEffect(() => {
        let details: { libraryName: string }[] = [];
        if (libraryReferences && libraryReferences.length > 0) {
            libraryReferences.forEach((lib: any) => {
                let obj = { libraryName: '' };
                obj.libraryName = lib.name;
                details.push(obj);
            });
        }

        setLibraryDetails(details);
    }, [libraryReferences]);

    const handleSelectedFiles = async (event: any) => {
        if (!event.target.files[0]) {
            setImportObjectTypes([]);
        }
        const validator = checkValidityForImportFile(event.target.files[0]);
        if (validator) {
            try {
                let files = await getJsonArrayFromZip(event.target.files[0]);
                const filesValid = checkFilesValidity(files);
                if (!filesValid) {
                    setInvalidSingleImport('Format for jsons inside zip files is invalid');
                    setFilesForImport([]);
                    setImportObjectTypes([]);
                } else {
                    setInvalidSingleImport('');
                    const libraries = _.cloneDeepWith(files).filter((item: any) => !item.model)[0];
                    setLibraryReferences(libraries);
                    files = files.filter((item: any) => item.model);
                    setFilesForImport(files);
                    const typeNames = getTypeDetailsFromImportedFile(files);
                    if (typeNames) {
                        if (typeNames.length === 0) {
                            setInvalidSingleImport('No Models Available inside the zip');
                        }
                        setImportObjectTypes(_.groupBy(typeNames, 'objectType'));
                    }
                }
            } catch (error) {
                setInvalidSingleImport(error.message);
                console.log(error);
            }
        }
    };

    const handleObjectLevelImport = useCallback(() => {
        if (mode === 'Import') {
            const json = filesForImport.find((file: any) => {
                if (
                    `${file.model.properties.model.name.value} ${file.model.properties.model.version.value}` ===
                    selectedForImport
                ) {
                    return file;
                }
            });

            const payload: PayloadForTypeLevelImport = {
                dependantLibraries: libraryReferences,
                passwordDetails: libraryDetails,
                selectedItemsForImport: filesForImport.filter(
                    (file: any) =>
                        `${file.model.properties.model.name.value} ${file.model.properties.model.version.value}` ===
                        selectedForImport
                ),
            };

            const associateTypeId: string = json.model.properties.associatedObjectType.typeId.value;
            if (activeAsset && associateTypeId && activeAsset.assetRef !== associateTypeId) {
                closeImportExportDialog();
                showConfirmationDialog(props.computeModels, json, activeAsset, payload);
            } else {
                importSingleType({
                    zippedModel: json,
                    associatedObjectType: {
                        model: activeAsset && activeAsset.assetType,
                        typeId: activeAsset && activeAsset.assetRef,
                        version: activeAsset && activeAsset.assetVersion,
                    },
                });
                setDetailsForTypeLevelImport(payload);
            }
        } else {
            setMode('Import');
        }
    }, [mode, activeAsset, filesForImport, libraryDetails, importSingleType, selectedForImport]);

    const handleTypeSelection = useCallback(
        (objectType) => {
            setSelectedForImport(objectType.nameWithVersion);

            filesForImport.forEach((file: any) => {
                if (file.model.properties.model.name.value === objectType.name) {
                    props.clearSelectedImportItems();
                    props.addToSelectedImportItems(file);

                    if (file.libraryReferences && file.libraryReferences.length > 0) {
                        setLibraryReferences([
                            ..._.cloneDeepWith(libraryReferences).filter((lib: any) =>
                                _.find(file.libraryReferences, {
                                    libraryName: lib.name,
                                })
                            ),
                        ]);
                    }
                }
            });
            setMode('Import');
        },
        [libraryReferences, filesForImport, setSelectedForImport]
    );

    const isImportDisabled = useMemo(() => {
        let isDisabled = selectedForImport.length === 0;
        return isDisabled;
    }, [mode, libraryDetails, selectedForImport]);

    return (
        <div className="import-for-single-type-container">
            <div className="modal-body">
                <React.Fragment>
                    <input
                        type="file"
                        accept=".zip"
                        onChange={(event) => handleSelectedFiles(event)}
                    />
                    <div className="import-error">{invalidSingleImport}</div>
                    <div className="object-types-list">
                        {Object.entries(importObjectTypes).map((item: any, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <div>{item[0]}</div>
                                    {item[1].length &&
                                        item[1].map((objectType: any) => {
                                            return (
                                                <div
                                                    key={objectType.nameWithVersion}
                                                    className="object-type-version"
                                                >
                                                    <Checkbox
                                                        sizeClass="small"
                                                        value={
                                                            objectType.nameWithVersion ===
                                                            selectedForImport
                                                        }
                                                        onChange={() =>
                                                            handleTypeSelection(objectType)
                                                        }
                                                    />
                                                    <div>{objectType.nameWithVersion}</div>
                                                </div>
                                            );
                                        })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </React.Fragment>
            </div>

            <div className="footer-buttons">
                <Button
                    sizeClass="medium"
                    text={CONFIRMATION_BUTTON.CANCEL}
                    type="ghost"
                    disabled={showSpinnerModal}
                    onClick={closeImportExportDialog}
                />
                <Button
                    text={mode}
                    sizeClass="medium"
                    type="primary-blue"
                    onClick={handleObjectLevelImport}
                    disabled={isImportDisabled}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        activeAsset: state.modelsPage.activeAsset,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        computeModels: state.modelsPage.computeModels,
    };
};

const mapDisPatchToProps = (dispatch: Dispatch) => {
    return {
        importSingleType: (val: {
            zippedModel: { functionTypes: []; model: ComputeModelToJson };
            associatedObjectType: {
                model: string | null;
                typeId: string | null;
                version: string | null;
            };
        }) => {
            dispatch(importSingleType(val));
        },
        setDetailsForTypeLevelImport: (payload: PayloadForTypeLevelImport) =>
            dispatch(setDetailsForTypeLevelImport(payload)),
        showConfirmationDialog: (
            computeModels: ModelsPageState['computeModels'],
            json: any,
            activeAsset: AssetType,
            payload: PayloadForTypeLevelImport
        ) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.IMPORTWARNING,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: CONNECTION_DISCONNECTED_CONFIRMATION_MESSAGE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    updateConfigurationToolMode({
                                        mode: CONFIGURATION_TOOL_MODE.VIEW,
                                    });
                                    if (computeModels.entities.length > 0) {
                                        const json =
                                            computeModels.byId[computeModels.entities[0]].json;
                                        createConfigurationToolJson({ json });
                                        setActiveModalForImport(
                                            computeModels.byId[computeModels.entities[0]]
                                        );
                                    } else {
                                        createConfigurationToolJson({
                                            json: {
                                                assetData: [],
                                                connectionData: [],
                                            },
                                        });
                                    }
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.OK,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    dispatch(
                                        importSingleType({
                                            zippedModel: json,
                                            associatedObjectType: {
                                                model: activeAsset && activeAsset.assetType,
                                                typeId: activeAsset && activeAsset.assetRef,
                                                version: activeAsset && activeAsset.assetVersion,
                                            },
                                        })
                                    );

                                    dispatch(setDetailsForTypeLevelImport(payload));
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
        updateConfigurationToolMode: (payload: { mode: 'VIEW' | 'EDIT' | 'CREATE' | 'IMPORT' }) => {
            dispatch(updateConfigurationToolMode(payload));
        },
        createConfigurationToolJson: (val: any) => dispatch(createConfigurationToolJson(val)),
        setActiveModalForImport: (val: any) => {
            dispatch(setActiveModalForImport(val));
        },
        addToSelectedImportItems: (item: any) => {
            dispatch(addToSelectedItemForImport(item));
        },
        clearSelectedImportItems: () => {
            dispatch(clearSelectedItemForImport());
        },
    };
};

export default connect(mapStateToProps, mapDisPatchToProps)(ImportForSingleType);
