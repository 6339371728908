import React, { useState, useCallback } from "react";
import Flex from "../../../../components/shared/Flex";
import "./style.scss";
import ObjectSidebar from "../../ObjectSidebar";
import SelectedInstancesSidebar from "../../SelectedInstancesSidebar";
import AssetInstanceSidebar from "../AssetInstanceSidebar";
import { Icon } from "@abb/abb-common-ux-react";
import TabNavList from "../../../../components/TabNavList";
import { INSTANCE_NAV_BAR } from "../../../../utils/constants/appConstants";
import _ from "lodash";

interface ConfigurationSidebarProps {
    selectedInstances: any[];
    handleSidebarToggle: (isOpen: boolean) => void;
}
function ConfigurationSidebar(props: ConfigurationSidebarProps) {
    const [tabNavList, updateTabNavList] = useState([
        INSTANCE_NAV_BAR.AVAILABLE_INSTANCES,
        INSTANCE_NAV_BAR.SELECTED_INSTANCES,
    ] as any[]);
    const [tabSelected, updateTabSelected] = useState(
        INSTANCE_NAV_BAR.AVAILABLE_INSTANCES
    );

    const onTabChange = (oldIndex: any, newIndex: number) => {
        updateTabSelected(tabNavList[newIndex]);
    };

    return (
        <Flex className="wrapper-sidebar">
            <Flex column className="wrapper-details">
                <div className="instance-tool-box">
                    <div className="instance-toolbox-heading">
                        <div className="toolbox">Instances</div>
                        <Icon
                            name="abb/pin"
                            onClick={() => props.handleSidebarToggle(false)}
                            className="pin-icon"
                        />
                    </div>
                    <TabNavList
                        errorFlag={false}
                        allowRemove={false}
                        tabNavList={tabNavList}
                        onTabChange={onTabChange}
                        activeTab={_.indexOf(tabNavList, tabSelected)}
                    />

                    <div className="wrapper-data">
                        {tabSelected ===
                        INSTANCE_NAV_BAR.AVAILABLE_INSTANCES ? (
                            props.selectedInstances.length > 1 ? (
                                <ObjectSidebar />
                            ) : (
                                <AssetInstanceSidebar />
                            )
                        ) : (
                            <SelectedInstancesSidebar />
                        )}
                    </div>
                </div>
            </Flex>
        </Flex>
    );
}

export default ConfigurationSidebar;
