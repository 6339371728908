import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useState, useEffect, Fragment } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@abb/abb-common-ux-react';
import './style.scss';
import { StoreState } from '../../../../store';
import { VIEW_MODE } from '../../../../utils/constants/appConstants';
import { updateConfigurationToolActiveView } from '../../../../store/configurationTool/actions';
import CanvasController from '../../../../components/Fabric';
import { WithPopup, Popup, Button } from '@abb/abb-common-ux-react';

interface ActiveViewToggleButtonProps {}

function ActiveViewToggleButton(
    props: ActiveViewToggleButtonProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [selectedBtn, setSelectedBtn] = useState([0]);

    const handleActiveView = (selectedBtn: number[]) => {
        console.log('selectedBtn', selectedBtn);
        setSelectedBtn(selectedBtn);
    };

    useEffect(() => {
        if (props.activeView.value === VIEW_MODE.TABLE.value) {
            setSelectedBtn([1]);
        } else {
            setSelectedBtn([0]);
        }
    }, [props.activeView.value]);

    return (
        <>
            <div className="wrapper-canvas-toogle-btn">
                <ToggleButtonGroup
                    className="view-toggle-btn"
                    sizeClass="small"
                    selected={selectedBtn}
                    onChange={(selectedButton) => {
                        handleActiveView(selectedButton);
                        if (selectedBtn[0] !== selectedButton[0]) {
                            props.toggleActiveView();
                        }
                    }}
                >
                    <ToggleButton id="mDiagramViewBtn" text="Diagram View" />
                    <ToggleButton id="mTableViewBtn" text="Table View" />
                </ToggleButtonGroup>
            </div>
        </>
    );
}

export const mapStateToProps = (state: StoreState) => {
    return {
        activeView: state.configurationTool.activeView,
        canvasController: state.configurationTool.canvasController,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        toggleActiveView: () => {
            dispatch(updateConfigurationToolActiveView());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveViewToggleButton);
