import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import { Input, Button } from "@abb/abb-common-ux-react";
import { FormGroup, Label, ModalFooter } from "reactstrap";

import "./style.scss";
import { customReactSelectStyles } from "../../utils/helpers";
import { StoreState } from "../../store";
import { CONFIGURATION_TOOL_MODE } from "../../utils/constants/appConstants";
import { hideModal } from "../../store/modal/action";
import { AlarmEventType, ALARM_TYPE } from "../Fabric/types";
import Loader from "../Loader";
import {
    fetchEventsForModels,
    addEventTriggerToFunction,
} from "../../store/configurationTool/actions";
import FunctionTypeDetail from "../../transformers/AssetType/FunctionType";
import { AddEventToFunctionPayload } from "../../store/configurationTool/types";
import { validateForSwedishAndFinishCharacters } from "../../routes/Functions/helper";
import Condition from "../shared/Condition";
import ObjectTypeDetail from "../../transformers/AssetType/ObjectType";
import CustomABBInput from "../CustomABBInput";

type eventIdDetails = string[];

interface modelandEventDetailsForSpecific {
    [model: string]: eventIdDetails;
}

interface IDropdownItem {
    label: string;
    value: string;
}

interface AlarmEventTypeDropDownItem extends IDropdownItem {}

interface AppProps {
    mode?: String;
    submitBtnText: string;
    modalMode: string;
    functionType: FunctionTypeDetail;
}

const alarmEventTypeDropDownList: AlarmEventTypeDropDownItem[] = [
    {
        label: ALARM_TYPE.GENERIC,
        value: ALARM_TYPE.GENERIC,
    },
    {
        label: ALARM_TYPE.SPECIFIC,
        value: ALARM_TYPE.SPECIFIC,
    },
];

export function AddEventForm(
    props: AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const modelsDropDownList: any[] = props.modelsForEvents.map(
        (model: any) => {
            return {
                label: model.key,
                value: model.key,
            };
        }
    );
    const [selectedAlarmEventType, setSelectedAlarmEventType] =
        useState<AlarmEventTypeDropDownItem>(alarmEventTypeDropDownList[0]);
    const [name, updateName] = useState("");
    const [model, updateModel] = useState({
        label: "",
        value: "",
    });
    const [eventId, updateEventId] = useState({
        label: "",
        value: "",
    });
    const [objectId, updateObjectId] = useState("");

    // inputField error handling states
    const [errorName, updateErrorName] = useState(false);

    const [errorNameText, updateErrorNameText] = useState(
        "Please Enter Model Name"
    );

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const [modelListForSpecificEvents, setModelListForSpecificEvents] =
        useState({} as modelandEventDetailsForSpecific);

    useEffect(() => {
        if (!name || !model.value || !eventId.value || errorName) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [name, eventId, model, objectId]);

    const handleAddEvent = () => {
        const eventDetails = {
            name: name,
            eventId: eventId.value,
            eventLabel: eventId.label,
            modelId: model.value,
            type: selectedAlarmEventType.value as AlarmEventType,
        };

        if (selectedAlarmEventType.value === ALARM_TYPE.SPECIFIC) {
            let objectType = props.assetData.filter((item) => {
                return (
                    item instanceof ObjectTypeDetail &&
                    item.assetType === model.value &&
                    item.outputs.reduce(
                        (isEventPresent: boolean, outputDetails) =>
                            !!(
                                isEventPresent ||
                                (outputDetails.eventId &&
                                    outputDetails.eventId.split("/")[1] ===
                                        eventId.value)
                            ),
                        false
                    )
                );
            })[0] as ObjectTypeDetail | undefined;
            props.addEventToFunction({
                eventDetails,
                functionType: props.functionType,
                objectType,
            });
        } else {
            props.addEventToFunction({
                eventDetails,
                functionType: props.functionType,
            });
        }

        props.closeModal();
    };

    const handleModelChange = (value: any) => {
        updateModel(value);
        updateEventId({
            label: "",
            value: "",
        });
        if (!props.eventsFromModel[value.value]) {
            props.fetchEventsForModel(value.value);
        }
    };

    const handleEventChange = (value: any) => {
        updateEventId(value);
    };

    const handleNameChange = (value: string) => {
        updateName(value);
        if (!validateForSwedishAndFinishCharacters(value) || !value) {
            updateErrorName(true);
            updateErrorNameText("Enter a Valid Name");
        } else {
            updateErrorName(false);
            updateErrorNameText("");
        }
    };

    return (
        <div className="wrapper-create-alarm">
            <div className="form-wrapper">
                <div className="form-wrapper__child">
                    <FormGroup className="custom-form-group">
                        <Label for="model">
                            <sup>*</sup> Event Type
                        </Label>
                        <ReactSelect
                            styles={customReactSelectStyles}
                            isClearable={false}
                            options={alarmEventTypeDropDownList}
                            placeholder="Select Event Type"
                            value={selectedAlarmEventType}
                            getOptionLabel={(optionObj: any) => {
                                return `${optionObj.label}`;
                            }}
                            getOptionValue={(optionObj: any) => {
                                return `${optionObj.value}`;
                            }}
                            onChange={(value) => {
                                if (value) {
                                    setSelectedAlarmEventType(value);
                                    updateModel({
                                        value: "",
                                        label: "",
                                    });
                                    updateEventId({
                                        value: "",
                                        label: "",
                                    });
                                }
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="modelName">
                            <sup>*</sup> Event Name
                        </Label>

                        <CustomABBInput
                            dataType="text"
                            value={name}
                            type="normal"
                            id="modelName"
                            placeholder="Enter the name of the Model"
                            instantValidation={true}
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                            validator={() =>
                                errorName
                                    ? {
                                          valid: false,
                                          text: errorNameText,
                                      }
                                    : { valid: true, text: "OK!" }
                            }
                            onValueChange={(value) => handleNameChange(value)}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="model">
                            <sup>*</sup> Model
                        </Label>
                        <ReactSelect
                            styles={customReactSelectStyles}
                            isClearable={false}
                            options={
                                selectedAlarmEventType.value ===
                                ALARM_TYPE.SPECIFIC
                                    ? Object.keys(
                                          modelListForSpecificEvents
                                      ).map((modelId) => {
                                          return {
                                              label: modelId,
                                              value: modelId,
                                          };
                                      })
                                    : modelsDropDownList
                            }
                            placeholder="Select Model"
                            value={model}
                            getOptionLabel={(optionObj: any) => {
                                return `${optionObj.label}`;
                            }}
                            getOptionValue={(optionObj: any) => {
                                return `${optionObj.value}`;
                            }}
                            onChange={handleModelChange}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="modelDescription">
                            <sup>*</sup> Event ID
                        </Label>
                        <Condition when={props.isEventsLoading}>
                            <Loader sizeClass="small" type="radial" />
                        </Condition>
                        <Condition when={!props.isEventsLoading}>
                            <ReactSelect
                                styles={customReactSelectStyles}
                                isClearable={false}
                                isDisabled={
                                    !model.value ||
                                    !(selectedAlarmEventType.value ===
                                    ALARM_TYPE.SPECIFIC
                                        ? modelListForSpecificEvents[
                                              model.value
                                          ] &&
                                          modelListForSpecificEvents[
                                              model.value
                                          ].length
                                        : props.eventsFromModel[model.value]) ||
                                    props.isEventsLoading
                                }
                                options={
                                    model.value
                                        ? selectedAlarmEventType.value ===
                                          ALARM_TYPE.SPECIFIC
                                            ? modelListForSpecificEvents[
                                                  model.value
                                              ]
                                                ? modelListForSpecificEvents[
                                                      model.value
                                                  ].map((event) => ({
                                                      value: event,
                                                      label: event,
                                                  }))
                                                : []
                                            : props.eventsFromModel[model.value]
                                            ? props.eventsFromModel[
                                                  model.value
                                              ].map((event) => ({
                                                  value: event,
                                                  label: event,
                                              }))
                                            : []
                                        : []
                                }
                                placeholder="Select Event"
                                value={eventId}
                                getOptionLabel={(optionObj: any) => {
                                    return `${optionObj.label}`;
                                }}
                                getOptionValue={(optionObj: any) => {
                                    return `${optionObj.value}`;
                                }}
                                onChange={handleEventChange}
                            />
                        </Condition>
                    </FormGroup>
                </div>
            </div>
            <ModalFooter className="popup-footer">
                <div>
                    <Button
                        text={"CANCEL"}
                        type="normal"
                        sizeClass="small"
                        isLoading={props.isUniqueTypeIdLoading}
                        onClick={() => props.closeModal()}
                    />
                </div>
                <div>
                    <Button
                        text={"ADD"}
                        type="primary-blue"
                        sizeClass="small"
                        isLoading={props.isUniqueTypeIdLoading}
                        onClick={() => handleAddEvent()}
                        disabled={isSubmitDisabled}
                    />
                </div>
            </ModalFooter>
        </div>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        assetList: state.modelsPage.objectTypeList,
        activeAsset: state.modelsPage.activeAsset,
        canvasController: state.configurationTool.canvasController,
        modelDetails:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails,
        activeModel: state.modelsPage.activeModel,
        updateActiveModel: state.modelsPage.computeModels,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        configurationToolMode: state.configurationTool.mode,
        updatedJson: state.configurationTool.json,
        overAllSeverityFunctionId:
            state.configurationTool.overallSeverityFunctionId,
        supportedConditionMonitorList:
            state.supportedConditionMonitor.supportedConditionMonitorList,
        typeIdDisabledDuringImport:
            state.exportAsset.typeIdDisabledDuringImport,
        dependantLibraries: state.exportAsset.dependantLibraries,
        passwordDetails: state.exportAsset.passwordDetails,
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
        isUniqueTypeIdLoading: state.modelsPage.isUniqueTypeIdLoading,
        isUniqueTypeId: !state.modelsPage.isTypeIdExistError,
        modelsForEvents: state.configurationTool.modelsForTrigger,
        eventsFromModel: state.configurationTool.eventsForModels,
        isEventsLoading: state.loader.getEventsForModelsLoading.isLoading,
        assetData: state.configurationTool.json.assetData,
    };
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        closeModal: () => {
            dispatch(hideModal());
        },
        fetchEventsForModel: (modelId: string) => {
            dispatch(fetchEventsForModels({ modelId }));
        },
        addEventToFunction: (payload: AddEventToFunctionPayload) => {
            dispatch(addEventTriggerToFunction(payload));
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEventForm);
