import React, { useCallback, useState, useMemo } from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import {
    LibraryAndNodePayload,
    LibraryAndNodePayloadWithoutNodes,
} from '../../../../store/function/types';
import { AbilityService } from 'sce-engg-model-19.09';
import { payloadToExportFunctionWithLibraries } from '../../../../store/exportAsset/types';
import { GENERIC_FUNCTIONS } from '../../../../utils/constants/appConstants';

interface FunctionMenuProps {
    libraryDetail: LibraryAndNodePayload;
    showDeleteModal: (libraryDetail: LibraryAndNodePayload) => void;
    showAddFunctionsToExistingLibrary: (libraryDetail: LibraryAndNodePayload) => void;
    showRemoveFunctionsFromExistingLibrary: (libraryDetail: LibraryAndNodePayload) => void;
    encryptLibrariesModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    getExportableFunctionsRequest: (payload: payloadToExportFunctionWithLibraries[]) => void;
    showModalToExportSingleLibrary: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    showChangeLibraryPasswordModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    unlockLibraryModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    showGetPasswordForRename: (payload: {
        libraryDetails: LibraryAndNodePayloadWithoutNodes;
    }) => void;
    showGetFileNameModal: (exportMethod: () => void, fileName: string) => void;
}
function FunctionMenu(props: FunctionMenuProps) {
    const {
        libraryDetail,
        showDeleteModal,
        encryptLibrariesModal,
        getExportableFunctionsRequest,
        showModalToExportSingleLibrary,
        showAddFunctionsToExistingLibrary,
        showRemoveFunctionsFromExistingLibrary,
        unlockLibraryModal,
    } = props;

    const [popupOffset, setPopupOffset] = useState<[number, number]>([0, 0]);
    const isDisabled = !libraryDetail.id && !libraryDetail.root;
    const hasSameOrigin = libraryDetail.origin === AbilityService.tenantId;

    const handleSingleLibraryExport = useCallback(() => {
        let librariesToBeExported: payloadToExportFunctionWithLibraries[] = [];
        let libraryObj: payloadToExportFunctionWithLibraries = {
            isProtected: false,
            libraryId: '',
            libraryVersion: '',
            functionTypes: [],
        };
        libraryObj.isProtected = !!libraryDetail.isIPProtected;
        libraryObj.libraryId = libraryDetail.id;
        libraryObj.libraryVersion = libraryDetail.libraryVersion;
        libraryObj.functionTypes = [...libraryDetail.nodes];
        librariesToBeExported.push(libraryObj);
        props.showGetFileNameModal(
            () => getExportableFunctionsRequest(librariesToBeExported),
            libraryDetail.root ||
                (libraryObj.functionTypes.length === 1
                    ? libraryObj.functionTypes[0].name
                    : GENERIC_FUNCTIONS)
        );
    }, [libraryDetail, getExportableFunctionsRequest]);

    return (
        <Menu
            mode="horizontal"
            className="library-menu"
            onOpenChange={() => {
                const BOTTOM_MARGIN_1 = 40;
                const el = document.getElementById('root');
                const { bottom: containerBottom } = el!.getBoundingClientRect();
                let node = document.getElementsByClassName('library-submenu')[0];
                let popup = document.getElementsByClassName('rc-menu-submenu-popup')[0];
                let { top, bottom } = node.getBoundingClientRect();
                let popupPosition = popup.getBoundingClientRect();
                if (node) {
                    setPopupOffset([-10, 0]);
                }
            }}
        >
            <SubMenu
                popupOffset={popupOffset}
                title={<Icon name="abb/more" />}
                className={'library-submenu'}
            >
                {!isDisabled && hasSameOrigin && (
                    <MenuItem
                        key="RemoveFunctions"
                        className="model-menu-item"
                        disabled={isDisabled}
                    >
                        <div
                            className="menu-item-wrapper"
                            onClick={() =>
                                !isDisabled &&
                                hasSameOrigin &&
                                showRemoveFunctionsFromExistingLibrary(libraryDetail)
                            }
                        >
                            <Icon name="abb/minus" />
                            <div className="menu-item-name">Remove Functions</div>
                        </div>
                    </MenuItem>
                )}
                {!isDisabled && hasSameOrigin && (
                    <MenuItem key="AddFunctions" className="model-menu-item" disabled={isDisabled}>
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                !isDisabled &&
                                    hasSameOrigin &&
                                    showAddFunctionsToExistingLibrary(libraryDetail);
                            }}
                        >
                            <Icon name="abb/plus" />
                            <div className="menu-item-name">Add Functions</div>
                        </div>
                    </MenuItem>
                )}

                {!isDisabled && (
                    <MenuItem key="DeleteLibrary" className="model-menu-item" disabled={isDisabled}>
                        <div
                            className="menu-item-wrapper"
                            onClick={() => !isDisabled && showDeleteModal(libraryDetail)}
                        >
                            <Icon name="abb/trash" className="menu-item-icon" />
                            <div className="menu-item-name">Delete</div>
                        </div>
                    </MenuItem>
                )}

                {!isDisabled && hasSameOrigin && (
                    <MenuItem
                        key="librayLockUnlock"
                        className="model-menu-item"
                        disabled={isDisabled}
                    >
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                if (libraryDetail.isIPProtected) {
                                    unlockLibraryModal({ libraryDetail });
                                } else {
                                    encryptLibrariesModal({
                                        libraryDetail,
                                    });
                                }
                            }}
                        >
                            <Icon
                                name={
                                    libraryDetail.isIPProtected
                                        ? 'abb/lock-open'
                                        : 'abb/lock-closed'
                                }
                            />
                            <div className="menu-item-name">
                                {libraryDetail.isIPProtected ? 'Unlock' : 'Lock'}
                            </div>
                        </div>
                    </MenuItem>
                )}

                {!isDisabled && hasSameOrigin && (
                    <MenuItem key="librayRename" className="model-menu-item" disabled={isDisabled}>
                        <div
                            className="menu-item-wrapper"
                            onClick={() =>
                                props.showGetPasswordForRename({
                                    libraryDetails: { ...libraryDetail, password: '' },
                                })
                            }
                        >
                            <Icon name={'abb/edit'} />
                            <div className="menu-item-name">{'Rename'}</div>
                        </div>
                    </MenuItem>
                )}

                {(hasSameOrigin || !libraryDetail.id) && libraryDetail.nodes.length > 0 && (
                    <MenuItem key="ExportLibrary" className="model-menu-item">
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                console.log(libraryDetail);
                                libraryDetail.id !== null
                                    ? handleSingleLibraryExport()
                                    : showModalToExportSingleLibrary({ libraryDetail });
                            }}
                        >
                            <Icon name="abb/export" className="menu-item-icon" />
                            <div className="menu-item-name">Export Library</div>
                        </div>
                    </MenuItem>
                )}

                {!isDisabled && libraryDetail.isIPProtected && hasSameOrigin && (
                    <MenuItem key="changePassword" className="model-menu-item">
                        <div
                            className="menu-item-wrapper"
                            onClick={() => props.showChangeLibraryPasswordModal({ libraryDetail })}
                        >
                            <Icon name="abb/edit" className="menu-item-icon" />
                            <div className="menu-item-name">Change Password</div>
                        </div>
                    </MenuItem>
                )}
            </SubMenu>
        </Menu>
    );
}

export default FunctionMenu;
