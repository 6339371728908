import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Checkbox } from '@abb/abb-common-ux-react';
import React, { useEffect, useState, useCallback } from 'react';
import { StoreState } from '../../../store';
import {
    getNewUniqueName,
    convertedToJSONObj,
    getDisabledForButton,
    convertToCalculationArray,
} from '../helper';
import { FunctionMode, FunctionHeaderTypeDetails } from '../../../store/function/types';
import { Calculation } from '../constants';
import CalculationLogicTitle from './CalculationLogicTitle';
import { FUNCTION_MODE } from '../../../utils/constants/appConstants';
import { CONFIRMATION_BUTTON } from '../../../utils/constants/uiConstants';
import { updateCalculationDeleteStatus } from '../../../store/function/action';
import { dropDownOptions } from '../type';
import { ShowMessageDispatchProps } from '..';
import Flex from '../../../components/shared/Flex';
import { object } from 'prop-types';

interface CalculationLogicFunctionTypeProps extends ReturnType<typeof mapStateToProps> {
    calculationData?: any;
    functionMode: FunctionMode;
    droppedItem?: boolean;
    handleCalculation?: (data: any, delFlag?: boolean) => void;
    handleCalculationError?: (value: boolean) => void;
    isEncrypted: boolean;
}

function CalculationLogicFunctionType(
    props: CalculationLogicFunctionTypeProps &
        ReturnType<typeof mapDispatchToProps> &
        ShowMessageDispatchProps
) {
    console.log('props :', props);
    const [deleteModal, setDeleteModal] = useState(false);
    const [calculationFunctionTypeDetails, updateCalculationFunctionTypeDetails] = useState(
        [] as any[]
    );

    const [isSelected, setIsSelected] = useState(false);

    const handleMessageClose = (data: string) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            deleteSelection();
        }
        setDeleteModal(!deleteModal);
    };

    const deleteSelection = () => {
        let resultCalculationType: any = [];
        calculationFunctionTypeDetails.forEach((calculation: any) => {
            if (!calculation.hasOwnProperty('isDelete')) {
                resultCalculationType.push(calculation);
            }
        });
        updateCalculationFunctionTypeDetails([...resultCalculationType]);
        const payloadDetails: FunctionHeaderTypeDetails = {
            inputDetails: props.functionHeaderDetails.inputDetails,
            outputDetails: props.functionHeaderDetails.outputDetails,
            conditionDetails: props.functionHeaderDetails.conditionDetails,
            calculationDetails: convertedToJSONObj(resultCalculationType),
        };
        props.updateCalculationDeleteStatus(payloadDetails);
        props.handleCalculation &&
            props.handleCalculation(convertedToJSONObj(resultCalculationType), true);
    };

    const handleDeleteFunctionItems = (e: React.MouseEvent<HTMLInputElement>) => {
        props.showMessageModal!({
            title: 'Delete',
            rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            handleStandardButton: handleMessageClose,
            warningText: `Do you want to delete selected items ? `,
        });
    };

    const handleAddCalculations = () => {
        let calculationDetails =
            props.functionHeaderDetails.calculationDetails &&
            props.functionHeaderDetails.calculationDetails;
        const calculationArray = convertToCalculationArray(calculationDetails);
        const name = getNewUniqueName(
            calculationDetails,
            Calculation,
            props.functionHeaderDetails.outputDetails
        );
        const newCalculationObject = {
            name: name,
            outputType: {
                dataType: dropDownOptions.integer,
            },
            description: { dataType: 'string', value: '' },
            logic: { dataType: 'string', value: '' },
        };
        props.handleCalculation &&
            props.handleCalculation(
                convertedToJSONObj([...calculationArray, newCalculationObject])
            );
        updateCalculationFunctionTypeDetails([...calculationArray, newCalculationObject]);
    };

    const handleTitleDelete = (calToDel: string) => {
        const calArray = convertToCalculationArray(
            props.functionHeaderDetails.calculationDetails
        ).map((calculation: any) => {
            if (calculation.name === calToDel) {
                if (calculation.hasOwnProperty('isDelete')) {
                    delete calculation.isDelete;
                } else {
                    calculation.isDelete = true;
                }
            }
            return calculation;
        });
        const updatedCalculation = convertedToJSONObj(calArray);
        debugger;
        updateCalculationFunctionTypeDetails([...calArray]);
        const payloadDetails: FunctionHeaderTypeDetails = {
            inputDetails: props.functionHeaderDetails.inputDetails,
            outputDetails: props.functionHeaderDetails.outputDetails,
            conditionDetails: props.functionHeaderDetails.conditionDetails,
            calculationDetails: updatedCalculation,
        };
        props.updateCalculationDeleteStatus(payloadDetails);
    };

    useEffect(() => {
        const calculationDetails =
            props.functionHeaderDetails.calculationDetails &&
            props.functionHeaderDetails.calculationDetails;
        updateCalculationFunctionTypeDetails(convertToCalculationArray(calculationDetails));
        if (props.functionMode != FUNCTION_MODE.VIEW) {
            if (Object.keys(props.functionHeaderDetails.calculationDetails).length === 0) {
                handleAddCalculations();
            }
        }
    }, []);

    useEffect(() => {
        const calculationDetails = props.functionHeaderDetails.calculationDetails;
        if (calculationDetails) {
            let selectedCalculationDetailsLength = 0;
            Object.keys(calculationDetails).forEach((item) => {
                if (
                    calculationDetails[item].hasOwnProperty('isDelete') &&
                    calculationDetails[item].isDelete
                ) {
                    selectedCalculationDetailsLength++;
                }
            });
            if (Object.keys(calculationDetails).length === selectedCalculationDetailsLength) {
                setIsSelected(true);
            } else {
                setIsSelected(false);
            }
        }
    }, [props.functionHeaderDetails.calculationDetails]);
    const handleSelectAll = useCallback(() => {
        setIsSelected(!isSelected);

        const calArray = convertToCalculationArray(
            props.functionHeaderDetails.calculationDetails
        ).map((calculation: any) => {
            if (calculation.hasOwnProperty('isDelete')) {
                delete calculation.isDelete;
            }
            if (isSelected === false) {
                calculation.isDelete = !isSelected;
            }
            return calculation;
        });
        const updatedCalculation = convertedToJSONObj(calArray);
        updateCalculationFunctionTypeDetails([...calArray]);
        const payloadDetails: FunctionHeaderTypeDetails = {
            inputDetails: props.functionHeaderDetails.inputDetails,
            outputDetails: props.functionHeaderDetails.outputDetails,
            conditionDetails: props.functionHeaderDetails.conditionDetails,
            calculationDetails: updatedCalculation,
        };
        props.updateCalculationDeleteStatus(payloadDetails);
    }, [isSelected, props.functionHeaderDetails.calculationDetails]);

    return (
        <>
            {props.functionMode !== FUNCTION_MODE.VIEW && (
                <Flex row>
                    <Checkbox
                        label="select all"
                        value={isSelected}
                        sizeClass="small"
                        onChange={handleSelectAll}
                    />
                    <div className="add-delete-btns">
                        <Button
                            text="Add"
                            sizeClass="small"
                            shape="rounded"
                            type="primary-blue"
                            className="delete-btn"
                            onClick={(e: any) => {
                                console.log('add calculation button called');
                                handleAddCalculations();
                            }}
                        />
                        <Button
                            text="Delete"
                            sizeClass="small"
                            shape="rounded"
                            type="normal"
                            className="delete-btn"
                            onClick={(e: any) => {
                                handleDeleteFunctionItems(e);
                            }}
                            disabled={getDisabledForButton(
                                props.functionHeaderDetails.calculationDetails
                            )}
                        />
                    </div>
                </Flex>
            )}
            <div className="CustomFunctionView conditionFunctionOverview">
                {calculationFunctionTypeDetails.map((calculationData: any, index: number) => {
                    return (
                        <CalculationLogicTitle
                            calculationData={calculationData}
                            calculationDataIndex={index}
                            handleTitleDelete={handleTitleDelete}
                            functionMode={props.functionMode}
                            calculationDetails={calculationFunctionTypeDetails}
                            handleCalculationError={props.handleCalculationError}
                            isEncrypted={props.isEncrypted}
                        />
                    );
                })}
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateCalculationDeleteStatus: (payload: FunctionHeaderTypeDetails) => {
            dispatch(updateCalculationDeleteStatus(payload));
        },
    };
};
function mapStateToProps(state: StoreState) {
    return {
        functionHeaderDetails: state.functions.functionHeaderDetails,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationLogicFunctionType);
