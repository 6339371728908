import CanvasController from '..';
import { store } from '../../../containers/AppBoot/index';
import {
    updateOverallSeverityFunctionId,
    updateOverAllSeverityFunctionStatus,
} from '../../../store/configurationTool/actions';
import { AssetType } from '../../../transformers/AssetType';
import { getFunctionTypeDetailId } from '../../../utils/helpers';
import { Asset } from '../types';

export const addOverallSeverityFunctionToCanvas = (options: {
    canvas: CanvasController;
    overallSeverityFunction: AssetType | undefined;
    asset: Asset;
    nodeWidth: number;
    longestOutputPinWidth: number;
}) => {
    const { canvas, overallSeverityFunction, asset, nodeWidth, longestOutputPinWidth } = options;
    if (overallSeverityFunction) {
        canvas.addDraggable({
            asset: overallSeverityFunction,
            position: {
                ...asset.position,
                x: asset.position.x + nodeWidth + longestOutputPinWidth,
                y: asset.position.y + nodeWidth + longestOutputPinWidth
            },
            isOverallSeverityFunction: true,
        });
        store.dispatch(updateOverAllSeverityFunctionStatus(true));

        const overallSeverityFuncNodeId = getFunctionTypeDetailId({
            functionName: overallSeverityFunction.assetName,
            assetData: store.getState().configurationTool.json.assetData,
            oldAssetData: [],
        });
        store.dispatch(updateOverallSeverityFunctionId(overallSeverityFuncNodeId));
    }
};
