import { fabric } from "fabric";
import {
    outputCircleRadius,
    canvasPrimaryBorderColor,
    canvasIOCircleColor,
} from "./UiConfig";

export interface FabricIOCircleParams {
    radius?: number;
    // data: {
    //   id: string,
    //   nodeId: string,
    //   dataType: IODataTypes,
    //   assetRef: string,
    //   name: string,
    //   assetType: AssetsType,
    //   path: string
    // }
}

export class FabricIOCircle extends fabric.Circle {
    // data: IOCircleData
    constructor(options: FabricIOCircleParams) {
        super({
            radius:
                options && options.radius ? options.radius : outputCircleRadius,
            fill: canvasIOCircleColor,
            originX: "center",
            originY: "center",
            hoverCursor: "pointer",
            moveCursor: "pointer",
            selectable: false,
        });

        // this.data = options.data
    }
}

export class FabricOutputCircle extends FabricIOCircle {
    constructor(options: FabricIOCircleParams) {
        super(options);
    }
}

export class FabricInputCircle extends FabricIOCircle {
    constructor(options: FabricIOCircleParams) {
        super(options);
    }
}

export class FabricAlarmCircle extends FabricIOCircle {
    constructor(options: FabricIOCircleParams) {
        super(options);
    }
}
