import React, { useCallback } from "react";
import {
    PaginationItem,
    PaginationLink,
    Pagination as RStrapPagination,
} from "reactstrap";
import "./style.scss";
import CustomTooltip from "../CustomTooltip"; 
import { TOOLTIP_MESSAGES } from '../../utils/constants/uiConstants';

interface AppProps {
    totalPages: number;
    activePage: number;
    onChange: (pageNumber: number) => any;
    className?: string;
}

function Pagination(props: AppProps) {
    const handleFirst = useCallback(() => {
        props.onChange(1);
    }, []);

    const handlePrevious = useCallback(() => {
        if (props.activePage !== 1) {
            props.onChange(props.activePage - 1);
        }
    }, [props.activePage]);

    const handleNext = useCallback(() => {
        if (props.activePage !== props.totalPages) {
            props.onChange(props.activePage + 1);
        }
    }, [props.activePage, props.totalPages]);

    const handleLast = useCallback(() => {
        props.onChange(props.totalPages);
    }, [props.totalPages]);

    return (
        <div className={`${props.className}`}>
            <RStrapPagination aria-label="Page navigation example">
                <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.FIRST_PAGE}>
                <PaginationItem className={`${props.activePage === 1 ? "disabled-page" : ""}`} disabled={props.activePage === 1}>
                    <PaginationLink
                        first
                        // href='#'
                        // disabled={true}
                        onClick={handleFirst}
                    />
                    </PaginationItem>
                </CustomTooltip>
                
                <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.PREVIOUS}>
                <PaginationItem className={`${props.activePage === 1 ? "disabled-page" : ""}`} disabled={props.activePage === 1}>
                    <PaginationLink
                        previous
                        // href="#"
                        // disabled={props.activePage === 1}
                        onClick={handlePrevious}
                    />
                    </PaginationItem>
                     </CustomTooltip>
                
                <div className="wrapper-page-number">
                    Page {props.activePage} of {props.totalPages}
                </div>

                
                <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.NEXT}>
                <PaginationItem className={`${props.activePage === props.totalPages? "disabled-page" : ""}`} disabled={props.activePage === props.totalPages}>
                    <PaginationLink
                        next
                        // href="#"
                        // disabled={props.activePage === props.totalPages}
                        onClick={handleNext}
                    />
                    </PaginationItem>
                    </CustomTooltip>
                
                
                <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.LAST_PAGE}>
                <PaginationItem className={`${props.activePage === props.totalPages? "disabled-page" : ""}`} disabled={props.activePage === props.totalPages}>
                    <PaginationLink
                        last
                        // href="#"
                        // disabled={props.activePage === props.totalPages}
                        onClick={handleLast}
                    />
                    </PaginationItem>
                    </CustomTooltip>
                
            </RStrapPagination>
        </div>
    );
}

export default Pagination;
