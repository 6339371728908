import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { ActionTypes } from '../actionTypes';
import {
    ConfigurationToolState,
    RemoveConnectionOptions,
    AddConnectionOptions,
    AssetDropActionType,
    GetFunctionTypePayload,
    VersionUpdateType,
    OriginalFunctionTypes,
    CurrentRoutePathType,
    LatestFunctionDataPayload,
    showConfigPopupPayload,
    AddAlarmToFunctionPayload,
    AddEventToFunctionPayload,
    removeAlarmTriggerOptions,
    removeEventTriggerOptions,
    EditAlarmToFunctionPayload,
    EditNodeIdOfFunctionPayload,
} from './types';
import CanvasController from '../../components/Fabric';

import TableController, { TableData } from '../../model/TableController';
import { FunctionTypeDetailsWithMappings } from '../../model/TableController/types';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';
import {
    CONFIGURATION_TOOL_MODE_CHILDREN,
    ROUTE_PATHNAME,
} from '../../utils/constants/appConstants';
import { AssetType } from '../../transformers/AssetType';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { Asset } from '../../components/Fabric/types';
import { FabricSettingsIcon } from '../../components/Fabric/UIHelpers/functionRectangle/smartChilds/FabricSettingsIcon';
import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';

export function updateConfigurationToolJson(payload: {
    tableData: {
        objectTypeDetails: TableController['objectTypeDetails'];
        functionTypeDetails: TableController['functionTypeDetails'];
        alarmTypeDetails: TableController['alarmTypeDetails'];
        eventTypeDetails: TableController['eventTypeDetails'];
    };
}) {
    return <const>{
        type: ActionTypes.UPDATE_CONFIGURATION_TOOL_JSON,
        payload,
    };
}

export function fetchModelsForTriggers() {
    return <const>{
        type: ActionTypes.FETCH_MODELS_FOR_TRIGGERS,
    };
}

export function updateModelsForTriggers(payload: { models: any }) {
    return <const>{
        type: ActionTypes.UPDATE_MODELS_FOR_TRIGGERS,
        payload,
    };
}

export function fetchAlarmsForModels(payload: { modelId: string }) {
    return <const>{
        type: ActionTypes.FETCH_ALARMS_FOR_MODELS,
        payload,
    };
}

export function updateAlarmsForModels(payload: {
    alarms: string[];
    modelId: string;
}) {
    return <const>{
        type: ActionTypes.UPDATE_ALARMS_FOR_MODELS,
        payload,
    };
}

export function clearAlarmsForModels() {
    return <const>{
        type: ActionTypes.CLEAR_ALARMS_FOR_MODELS,
    };
}

export function fetchEventsForModels(payload: { modelId: string }) {
    return <const>{
        type: ActionTypes.FETCH_EVENTS_FOR_MODELS,
        payload,
    };
}

export function updateEventsForModels(payload: {
    events: string[];
    modelId: string;
}) {
    return <const>{
        type: ActionTypes.UPDATE_EVENTS_FOR_MODELS,
        payload,
    };
}

export function clearEventsForModels() {
    return <const>{
        type: ActionTypes.CLEAR_EVENTS_FOR_MODELS,
    };
}

export function updateConfigurationToolPrivileges(payload: {
    privileges: ConfigurationToolState['privileges'];
}) {
    return <const>{
        type: ActionTypes.UPDATE_CONFIGURATION_TOOL_PRIVILEGES,
        payload,
    };
}

export function updateConfigurationToolMode(payload: { mode: ConfigurationToolState['mode'] }) {
    return <const>{
        type: ActionTypes.UPDATE_CONFIGURATION_TOOL_MODE,
        payload,
    };
}

export function updateConfigurationToolActiveView(payload?: {
    activeView?: ConfigurationToolState['activeView'];
}) {
    return <const>{
        type: ActionTypes.UPDATE_CONFIGURATION_TOOL_ACTIVE_VIEW,
        payload,
    };
}

export function initializeConfigurationTool(payload: {
    activeView?: ConfigurationToolState['activeView'];
    privileges?: ConfigurationToolState['privileges'];
    mode?: ConfigurationToolState['mode'];
    json?: LocalJson;
    canvasController: CanvasController;
}) {
    return <const>{
        type: ActionTypes.INITIALIZE_CONFIGURATION_TOOL,
        payload,
    };
}

export function removeConnectionConfigurationTool(payload: RemoveConnectionOptions) {
    return <const>{
        type: ActionTypes.REMOVE_CONNECTION_CONFIGURATION_TOOL,
        payload,
    };
}

export function addConnectionConfigurationTool(payload: AddConnectionOptions) {
    return <const>{
        type: ActionTypes.ADD_CONNECTION_CONFIGURATION_TOOL,
        payload,
    };
}

export function addAlarmTriggerToFunction(payload: AddAlarmToFunctionPayload) {
    return <const>{
        type: ActionTypes.ADD_ALARM_TRIGGER_TO_FUNCTION,
        payload,
    };
}

export function editAlarmTriggerToFunction(payload: EditAlarmToFunctionPayload) {
    return <const>{
        type: ActionTypes.EDIT_ALARM_TRIGGER_TO_FUNCTION,
        payload,
    };
}

export function editNodeIdOfFunction(payload: EditNodeIdOfFunctionPayload) {
    return <const>{
        type: ActionTypes.EDIT_NODE_ID_OF_FUNCTION,
        payload,
    };
}

export function addEventTriggerToFunction(payload: AddEventToFunctionPayload) {
    return <const>{
        type: ActionTypes.ADD_EVENT_TRIGGER_TO_FUNCTION,
        payload,
    };
}

export function deleteAlarmTriggerToFunction(payload: removeAlarmTriggerOptions) {
    return <const>{
        type: ActionTypes.DELETE_ALARM_TRIGGER_TO_FUNCTION,
        payload,
    };
}

export function deleteEventTriggerToFunction(payload: removeEventTriggerOptions) {
    return <const>{
        type: ActionTypes.DELETE_EVENT_TRIGGER_TO_FUNCTION,
        payload,
    };
}

export function addDefaultValueConfigurationTool(payload: {
    asset: FunctionTypeDetailsWithMappings;
    circleData: FunctionTypeDetail['inputs'][0];
    defaultValue: string;
}) {
    return <const>{
        type: ActionTypes.ADD_DEFAULT_VALUE_CONFIGURATION_TOOL,
        payload,
    };
}

export function triggerConnectionConfigurationTool(payload: {
    asset: FunctionTypeDetailsWithMappings;
    circleData: FunctionTypeDetail['inputs'][0];
    trigger: boolean;
}) {
    return <const>{
        type: ActionTypes.TRIGGER_CONNECTION_CONFIGURATION_TOOL,
        payload,
    };
}

export function publishOutputConfigurationTool(payload: {
    asset: FunctionTypeDetailsWithMappings;
    circleData: FunctionTypeDetailsWithMappings['outputs'][0];
}) {
    return <const>{
        type: ActionTypes.PUBLISH_OUTPUT_CONFIGURATION_TOOL,
        payload,
    };
}

export function publishAlarmConfigurationTool(payload: { asset: FunctionTypeDetailsWithMappings }) {
    return <const>{
        type: ActionTypes.PUBLISH_ALARM_CONFIGURATION_TOOL,
        payload,
    };
}

export const handleAssetDropAction = (dataPayload: AssetDropActionType) => {
    return <const>{
        type: ActionTypes.ADD_ASSET_OBJECT_REQUEST,
        payload: dataPayload,
    };
};

export const handleInstanceDropAction = (payload: {
    instance: any;
    position: { x: number; y: number };
}) => {
    return <const>{
        type: ActionTypes.HANDLE_INSTANCE_DROP_ACTION,
        payload,
    };
};

export function updateActiveModelJson() {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVE_MODEL_JSON,
    };
}

export function createConfigurationToolJson(payload: {
    json: LocalJson;
    mode?: CONFIGURATION_TOOL_MODE_CHILDREN;
}) {
    return <const>{
        type: ActionTypes.CREATE_CONFIGURATION_TOOL_JSON,
        payload,
    };
}

export function markComputeModelDirty() {
    return <const>{
        type: ActionTypes.MARK_COMPUTE_MODEL_DIRTY,
    };
}

export function updateOverAllSeverityFunctionStatus(isOverAllSeverityFuncAdded: boolean) {
    return <const>{
        type: ActionTypes.UPDATE_OVERALL_SEVERITY_FUNCTION_STATUS,
        payload: isOverAllSeverityFuncAdded,
    };
}

export const updateOverallSeverityFunctionId = (nodeId: string) => {
    return <const>{
        type: ActionTypes.UPDATE_OVERAll_SEVERITY_FUNCTION_ID,
        payload: nodeId,
    };
};

export const removeOverallSeverityFunction = () => {
    return <const>{
        type: ActionTypes.REMOVE_OVERALL_SEVERITY_FUNCTION,
    };
};

export const updateOverAllSeverityFunctionData = (data: AssetType) => {
    return <const>{
        type: ActionTypes.UPDATE_OVERALL_SEVERITY_FUNC_DATA,
        payload: data,
    };
};

export const getSelectedFunctionDetails = (payload: GetFunctionTypePayload) => {
    return <const>{
        type: ActionTypes.GET_SELECTED_FUNCTION_DETAIL,
        payload,
    };
};

export const setSelectedFunctionDetails = (functionDetails: FunctionTypeDetail | null) => {
    return <const>{
        type: ActionTypes.SET_SELECTED_FUNCTION_DETAILS,
        payload: functionDetails,
    };
};

export const handleFunctionDetailsLoading = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_FUNCTION_DETAILS_LOADING,
        payload: isLoading,
    };
};

export const setVersionUpdateType = (versionUpdateType: VersionUpdateType) => {
    return <const>{
        type: ActionTypes.SET_VERSION_UPDATE_TYPE,
        payload: versionUpdateType,
    };
};

export const getOriginalFunctionTypes = (payload: {
    functionTypes: GetFunctionTypePayload[];
    currentRoutePath: CurrentRoutePathType;
}) => {
    return <const>{
        type: ActionTypes.GET_ORIGINAL_FUNCTION_TYPES,
        payload,
    };
};
export const setOriginalFunctionTypes = (payload: {
    originalFunctionTypes: OriginalFunctionTypes;
    currentRoutePath: CurrentRoutePathType;
}) => {
    return <const>{
        type: ActionTypes.SET_ORIGINAL_FUNCTION_TYPES,
        payload,
    };
};

export const addToOriginalSelectedFunctions = (payload: FunctionTypeDetail) => {
    return <const>{
        type: ActionTypes.ADD_TO_ORIGINAL_SELECTED_FUNCTION,
        payload,
    };
};

export const getOriginalFunctionDataFromJson = (payload: {
    json: ComputeModelFromJson;
    currentRoutePath: CurrentRoutePathType;
}) => {
    return <const>{
        type: ActionTypes.GET_ORIGINAL_FUNCTION_DATA_FROM_JSON,
        payload: payload,
    };
};

export const addIsDirtyToConditions = (addIsDirtyToCondition: boolean) => {
    return <const>{
        type: ActionTypes.ADD_IS_DIRTY_TO_CONDITION,
        payload: addIsDirtyToCondition,
    };
};

export const handleAllowOverlapOnCanvas = (isOverlapallowed: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_ALLOW_OVERLAP_ON_CANVAS,
        payload: isOverlapallowed,
    };
};
/**Check if latest version of function is avaialable or not */
export function getLatestFunctionsForType(payload: {
    activeModel: ComputeModelFromJson;
    currentRoutePath: CurrentRoutePathType;
}) {
    return <const>{
        type: ActionTypes.GET_LATEST_FUNCTIONS_FOR_TYPE,
        payload,
    };
}
export function updateLatestFunctionData(payload: LatestFunctionDataPayload) {
    return <const>{
        type: ActionTypes.UPDATE_LATEST_FUNCTION_DATA_PAYLOAD,
        payload,
    };
}

export function handleLatestFunctionDataLoading(isLoading: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_LATEST_FUNCTION_DATA_LOADING,
        payload: isLoading,
    };
}

export function updateCurrentSelectedFunctionWithLatestData(payload: {
    currentSelectedFunction: FunctionTypeDetail;
}) {
    return <const>{
        type: ActionTypes.UPDATE_CURRENT_SELECTED_FUNCTION_WITH_LATEST_DATA,
        payload,
    };
}

export function updateTypeWithLatestFunctions() {
    return <const>{
        type: ActionTypes.UPDATE_TYPE_WITH_LATEST_FUNCTIONS,
    };
}

export function updateConfigurationToolJsonWithLatestFunction(json: LocalJson) {
    return <const>{
        type: ActionTypes.UPDATE_CONFIGURATION_TOOL_JSON_WITH_LATEST_FUNCTIONS,
        payload: json,
    };
}

export function updateOriginalFunctiontypes(originalFunctionTypes: OriginalFunctionTypes) {
    return <const>{
        type: ActionTypes.UPDATE_ORIGINAL_FUNCTION_TYPES,
        payload: originalFunctionTypes,
    };
}

export function handleCurrentFunctionUpdateStatus(isUpdated: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_CURRENT_FUNCTION_UPDATE_STATUS,
        payload: isUpdated,
    };
}

export function handleCanvasZoomUpdate(val: number) {
    return <const>{
        type: ActionTypes.SET_ZOOM,
        payload: val,
    };
}

export function undoJSON() {
    return <const>{
        type: ActionTypes.UNDO,
    };
}

export function redoJSON() {
    return <const>{
        type: ActionTypes.REDO,
    };
}

export function clearUndoRedoStack() {
    return <const>{
        type: ActionTypes.CLEAR_UNDO_REDO_STACK,
    };
}

export function changeIsSeverityUpdate() {
    return <const>{
        type: ActionTypes.UPDATE_IS_SEVERITY_CHANGE,
    };
}

export function showConfigurationPopup(payload: showConfigPopupPayload) {
    return <const>{
        type: ActionTypes.SHOW_CONFIG_POPUP,
        payload,
    };
}

export function hideConfigurationPopup() {
    return <const>{
        type: ActionTypes.HIDE_CONFIG_POPUP,
    };
}
export function stopBacgroundTaskGetOriginalFunctionTypes() {
    return <const>{
        type: ActionTypes.STOP_BACKGROUND_SYNC_FOR_GET_ORIGINAL_FUNC_TYPES,
    };
}
export function stopBacgroundTaskGetLatestFunctions() {
    return <const>{
        type: ActionTypes.STOP_BACKGROUND_SYNC_FOR_GET_LATEST_FUNCTIONS,
    };
}

export function updateTimeTriggerForFunction(payload: { nodeId: string; timeTrigger: number }) {
    return <const>{
        type: ActionTypes.UPDATE_TIME_TRIGGER_FOR_FUNCTION,
        payload,
    };
}
