import * as _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import LeftNavigation from './LeftNavigation';
import { sce } from 'sce-engg-model-19.09';
import React, { useEffect, useState, Fragment, useMemo } from 'react';
import {
    BaseLayout,
    SidebarContainer,
    BodyContainer,
    NoAccess,
} from '../../containers/EditorLayout';
import { StoreState } from '../../store';
import {
    getSelectedFunctionType,
    handleFunctionMode,
    handleFunctionErrorFlag,
    getAllPermisssionForFunctionAction,
    getAlarmTypes,
    updateSelectedFunctionType,
    getLibraryAndNodesRequest,
} from '../../store/function/action';
import {
    LOADER_SIZE,
    LOADER_TYPE,
    FUNCTION_MODE,
    LOADER_TEXT,
} from '../../utils/constants/appConstants';
import FunctionConfiguration from './FunctionsView';
import TopNavigation from '../../components/Header';
import CreateEditFunction from './CreateEditFunction';
import { FunctionMode, IGetSelectedFunctionPayload } from '../../store/function/types';
import Condition from '../../components/shared/Condition';
import { handleUserLogin, redirectLogin } from '../../store/login/action';
import { hideDialog, showDialog } from '../../store/dialog/action';
import { CONFIRMATION_BUTTON } from '../../utils/constants/uiConstants';
import MessageModal from './MessageModal';
import SpinnerModal from '../../components/SpinnerModal';
import AppSettings from '../../services/AppSettings';
import { setAuthorize } from '../../store/modelsPage/actions';
import { checkPermissionRoles, handleTokenDecode } from '../../utils/helpers';
import VerticalNavBar from '../../containers/EditorLayout/VerticalNavBar';
import { commonAuthHelper } from '../../utils/types/commonInterface';
import { Dialog } from '@abb/abb-common-ux-react';

interface AppProps { }
export type ShowMessageDispatchProps = Partial<
    Pick<ReturnType<typeof mapDispatchToProps>, 'showMessageModal'>
>;

function Functions(
    props: AppProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
    const {
        handleFunctionMode,
        functionMode,
        handleFunctionErrorFlag,
        errorFlag,
        createFunctionData,
        getLibraryAndNodesRequest
    } = props;
    const [newFunction, updateNewFunction] = useState(false);
    const [editFunction, updateEditFunction] = useState(false);
    const [droppedItem, updateDroppedItem] = useState('');
    const [conditionFlag, updateConditionFlag] = useState(false);

    useEffect(() => {
        getLibraryAndNodesRequest();
    }, [window.location.pathname]);

    useEffect(() => {
        if (
            _.has(createFunctionData, 'modelDetails') ||
            _.has(createFunctionData, 'assetDetails')
        ) {
            updateNewFunction(true);
        }
    }, [createFunctionData, functionMode, errorFlag]);

    const handleConditionFlag = () => {
        updateConditionFlag(sce.isConditionSupported());
    };

    const handleAPIStatus = (data: boolean) => {
        updateEditFunction(false);
        updateNewFunction(false);
    };

    const canAccess = useMemo(() => {
        let access = false;
        const token = commonAuthHelper.getToken();
        if (!!token) {
            access = checkPermissionRoles(token);
        }
        return access;
    }, []);

    useEffect(() => {
        props.setAuthorize(canAccess);
        if (canAccess) {
            handleTokenDecode();
            handleConditionFlag();
            props.getAllPermisssionForFunction();
            props.getFunctionAlarmType();
        }
    }, [canAccess]);

    const getSelectedFunctionType = (options: IGetSelectedFunctionPayload) => {
        props.getSelectedFunctionTypeDetails(options);
    };

    const handleEdit = (value: boolean) => {
        updateEditFunction(value);
        handleFunctionMode(FUNCTION_MODE.EDIT);
    };

    const handleEmptyResponse = () => {
        // updateLoading(false);
    };

    const handleError = (value: boolean) => {
        handleFunctionErrorFlag(value);
    };

    return (
        <Fragment>
            <TopNavigation />
            <Condition when={!canAccess}>
                <BaseLayout>
                    <NoAccess />
                </BaseLayout>
            </Condition>
            <Condition when={canAccess}>
                <BaseLayout>
                    {/* <VerticalNavBar /> */}
                    {functionMode === FUNCTION_MODE.VIEW && (
                        <SidebarContainer useDraggable={functionMode === FUNCTION_MODE.VIEW}>
                            {functionMode === FUNCTION_MODE.VIEW && (
                                <LeftNavigation
                                    getSelectedFunctionType={getSelectedFunctionType}
                                    handleEmptyResponse={handleEmptyResponse}
                                    assetsDetails={
                                        props.functionTypeDetails && props.functionTypeDetails
                                    }
                                    showMessageModal={props.showMessageModal}
                                />
                            )}
                        </SidebarContainer>
                    )}

                    <BodyContainer fill>
                        <div className="bg-full overflow-hidden">
                            {props.isLoading && (
                                <div className="center-div1">
                                    <SpinnerModal
                                        mode={props.isLoadingText ? 'Modal' : 'Spinner'}
                                        showModal
                                        type={LOADER_TYPE.RADIAL}
                                        sizeClass={LOADER_SIZE.LARGE}
                                    >
                                        {props.isLoadingText && (
                                            <div className="instances-heading">
                                                {LOADER_TEXT.UPDATE_RELATED_INSTANCES}
                                            </div>
                                        )}
                                    </SpinnerModal>
                                </div>
                            )}
                            {functionMode === FUNCTION_MODE.CREATE && (
                                <CreateEditFunction
                                    handleAPIStatus={handleAPIStatus}
                                    draggedItem={droppedItem}
                                    errorFlag={errorFlag}
                                    handleError={handleError}
                                    showMessageModal={props.showMessageModal}
                                />
                            )}

                            {functionMode === FUNCTION_MODE.EDIT && (
                                <CreateEditFunction
                                    handleAPIStatus={handleAPIStatus}
                                    draggedItem={droppedItem}
                                    handleError={handleError}
                                    errorFlag={errorFlag}
                                    showMessageModal={props.showMessageModal}
                                />
                            )}

                            {functionMode === FUNCTION_MODE.VIEW && !props.isLoading && (
                                <FunctionConfiguration
                                    handleEdit={handleEdit}
                                    handleAPIStatus={handleAPIStatus}
                                    assetsDetails={
                                        props.functionTypeDetails && props.functionTypeDetails
                                    }
                                    showMessageModal={props.showMessageModal}
                                />
                            )}
                        </div>
                    </BodyContainer>
                </BaseLayout>
                {props.selectedFunctionType && props.selectedFunctionType.modelsUsed && props.selectedFunctionType.modelsUsed.length ? (
                    <Dialog
                    title="Delete"
                    dimBackground={true}
                    showCloseButton={true}
                    isOpen={ props.selectedFunctionType.modelsUsed.length > 0}
                    onClose={() => {
                        let updatedFunction = _.cloneDeepWith(props.selectedFunctionType);
                        delete updatedFunction!.modelsUsed;
                        props.updateFunctionTypeDetails({typeDetails: updatedFunction});
                    }}
                    standardButtonsOnBottom={[{
                        text: 'OK',
                        type: 'primary-blue',
                        handler: () => {
                            let updatedFunction = _.cloneDeepWith(props.selectedFunctionType);
                        delete updatedFunction!.modelsUsed;
                        props.updateFunctionTypeDetails({typeDetails: updatedFunction});
                        },
                    },]}
                    
                >
                    <div>Can't Delete this function type. Following Models are using this function type:</div>
                    <div className='delete-function-table'>
                        <div className='delete-function-table-area'>
                            <table>
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>TYPEID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.selectedFunctionType.modelsUsed.map((model:any, index:number) => {
                                return (
                                    <tr key={index}>
                                    <td>{model.name}</td>
                                    <td className='path-class'>{model.typeId}</td>
                                </tr>
                                )
                                
                            })}
                        </tbody>
                    </table>
                        </div>
                    </div>
                    
                </Dialog>
                ) : (<></>)}
                
            </Condition>
        </Fragment>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        createFunctionData: state.functions.currentActiveView,
        isLoading: state.functions.isLoading,
        isLoadingText: state.functions.isLoadingText,
        functionTypeDetails: state.functions.typeDetails,
        functionMode: state.functions.functionMode,
        functionErrorFlag: state.functions.functionErrorFlag,
        isLoggedIn: state.login.isLoggedIn,
        errorFlag: state.functions.functionErrorFlag,
        selectedFunctionType: state.functions.typeDetails
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getLibraryAndNodesRequest: () => {
            dispatch(getLibraryAndNodesRequest());
        },
        getSelectedFunctionTypeDetails: (payload: IGetSelectedFunctionPayload) => {
            dispatch(getSelectedFunctionType(payload));
        },
        updateFunctionTypeDetails: (payload: any) => {
            dispatch(updateSelectedFunctionType(payload));
        },
        handleFunctionMode: (functionMode: FunctionMode) => {
            dispatch(handleFunctionMode(functionMode));
        },
        handleFunctionErrorFlag: (errorFlag: boolean) => {
            dispatch(handleFunctionErrorFlag(errorFlag));
        },
        getAllPermisssionForFunction: () => {
            dispatch(getAllPermisssionForFunctionAction());
        },
        getFunctionAlarmType: () => {
            dispatch(getAlarmTypes());
        },
        redirectLogin: () => {
            dispatch(redirectLogin());
        },
        handleUserLogin: () => {
            dispatch(handleUserLogin());
        },
        setAuthorize: (access: boolean) => {
            dispatch(setAuthorize(access));
        },
        showMessageModal: (payload: {
            title: string;
            rejectButtonTitle: string;
            acceptButtonTitle: string;
            handleStandardButton: (data: string, tabSel?: any) => void;
            selectedTab?: string;
            warningText: string;
        }) => {
            const {
                title,
                rejectButtonTitle,
                acceptButtonTitle,
                selectedTab,
                handleStandardButton,
                warningText,
            } = payload;
            const rejectButtonText = rejectButtonTitle || CONFIRMATION_BUTTON.CANCEL;
            const acceptButtonText = acceptButtonTitle || CONFIRMATION_BUTTON.CONFIRM;
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: title || 'Discard Changes?',
                    customClassName: 'wrapper-message-modal',
                    data: {
                        selectedTab: selectedTab,
                        warningText: warningText,
                        standardButtonsOnBottom: [
                            {
                                text: rejectButtonText,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: acceptButtonText,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    handleStandardButton(CONFIRMATION_BUTTON.ACCEPT, selectedTab);
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Functions);
