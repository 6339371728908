import {
    fontSize,
    canvasPrimaryBorderColor,
    canvasPrimaryTextColor,
    GRID_PIXEL,
    outputCircleRadius,
} from '../UiConfig';
import { fabric } from 'fabric';
import { AlarmDetailsProps, Asset, AssetsType } from '../../types';
import { Node, SelfPositioningChild } from '../../NodeController/types';
import { FabricArrowIcon } from '../FabricArrowIcon';
import { DropDownConnectionHooks } from './smartChilds/connectionHooks';
import { DropDownAssetName } from './smartChilds/DropDownAssetName';

export class FabricDropDownTitle extends fabric.Group {
    nodeId: string;

    data: {
        asset: Asset;
        childs: SelfPositioningChild[];
        handleEditMode?: () => void;
        handleViewMode?: () => void;
        dropDownNode?: Node;
    };
    constructor(options: {
        asset: Asset;
        color: string;
        bgColor: string;
        iconPath: string;
        borderColor: string;
    }) {
        const { asset, color, bgColor, iconPath, borderColor } = options;
        const { position, scale: scaleX, scale: scaleY, nodeId } = asset;
        // @ts-ignore
        const assetName = asset.assetName ? asset.assetName : asset.alarmName;
        const height = 3 * GRID_PIXEL;
        let width = fontSize * 12;

        const titleGroup = new fabric.Group(undefined, {});
        width =
            asset.assetType === AssetsType.FUNCTION
                ? GRID_PIXEL * 16 + GRID_PIXEL * 4
                : GRID_PIXEL * 15;
        const icon = new fabric.Path(iconPath, {
            strokeWidth: 0,
            fill: color,
            left: -width / 2 + GRID_PIXEL,
            originX: 'left',
            originY: 'center',
            top: (3 * GRID_PIXEL) / 2,
            selectable: false,
        });
        titleGroup.addWithUpdate(icon);
        // const text = new fabric.Text(assetName, {
        //     fontSize: fontSize,
        //     borderColor: canvasPrimaryTextColor,
        //     left: -width / 2 + GRID_PIXEL + 11 + icon.getScaledWidth(),
        //     top: (3 * GRID_PIXEL) / 2,
        //     originX: 'left',
        //     originY: 'center',
        //     fill: color,
        // });
        // titleGroup.addWithUpdate(text);

        const rect = new fabric.Rect({
            width: width,
            height: height,
            fill: bgColor,
            stroke: borderColor,
            originY: 'top',
            originX: 'center',
            top: 0,
            name: 'rect',
        });

        super([rect, titleGroup], {
            left: position.x,
            top: position.y || 0,
            subTargetCheck: true,
            originX: 'center',
            originY: 'center',
            name: 'group-object',
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
            borderColor: 'transparent',
            hoverCursor: 'move',
        });

        const connectionHook = new DropDownConnectionHooks({ group: this, asset });
        const arrowIcon = new FabricArrowIcon({
            group: this,
            isOpen: false,
            left: width / 2 - GRID_PIXEL * 1.5,
            top: (3 * GRID_PIXEL) / 2,
        });
        const dropDownName = new DropDownAssetName({
            group: this,
            asset,
            color,
        });
        this.nodeId = nodeId;
        arrowIcon.fill = color;
        arrowIcon.stroke = color;

        this.data = {
            asset: asset,
            childs: [dropDownName, arrowIcon, connectionHook],
            handleEditMode: () => {
                console.log('handling edit mode from rect');
            },
            handleViewMode: () => {},
        };

        this.bringForward();
    }
}
