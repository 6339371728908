import { ActionTypes } from "../actionTypes";
import { AlarmModalState, AlarmModalAction } from "./types";

const initialState: AlarmModalState = {
    show: false,
    component: undefined,
    modalTitle: "",
    data: {},
};

const alarmModalReducer = (
    state: AlarmModalState = initialState,
    action: AlarmModalAction
): AlarmModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_ALARM_MODAL:
            const { component } = action.payload;

            if (component) {
                return {
                    show: true,
                    ...action.payload,
                };
            } else {
                return state;
            }

        case ActionTypes.HIDE_ALARM_MODAL:
            return { ...initialState };
        default:
            return state;
    }
};

export default alarmModalReducer;
