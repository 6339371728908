import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
// import ReactSelect from 'react-select';
import { List } from 'react-virtualized';
import { Button, Spinner, Checkbox } from '@abb/abb-common-ux-react';

import { LibraryAndNodePayload, UpdateActiveFunctionType } from '../../store/function/types';
import {
    getFunctionVersionsType,
    // functionVersionType,
    payloadToExportFunctionWithLibraries,
} from '../../store/exportAsset/types';
import { isPresentInFunctions, functionBelongsToLibrary } from '../../routes/Functions/helper';
import {
    getFunctionVersions,
    removedFromDropDown,
    storeFunctionsVersions,
    updateFunctionListToGetJson,
    handleSelectAllForGenericFuncExport,
    changeZipFileNameForExport,
} from '../../store/exportAsset/actions';
import { StoreState } from '../../store';
// import CollapsibleUnit from './CollapsibleUnit';
import { customReactSelectStyles, checkWhetherFunctionIsSelected } from '../../utils/helpers';
import { getExportableFunctionsRequest } from '../../store/function/action';
import { GENERIC_FUNCTIONS } from '../../utils/constants/appConstants';
import { showModal } from '../../store/modal/action';
import GetExportZipName from '../GetExportZipName';

interface SingleLibraryExportProps {
    libraryDetail: LibraryAndNodePayload;
    singleExport?: boolean;
}

const SingleLibraryExport = (
    props: SingleLibraryExportProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        libraryDetail,
        showSpinnerModal,
        // removedFromDropDown,
        // getFunctionVersions,
        // storeFunctionsVersions,
        // functionVersionDetails,
        selectedFunctionDetails,
        updateFunctionListToGetJson,
        getExportableFunctionsRequest,
        // handleSelectAllForGenericFunction,
    } = props;

    // const [selectedDropDownList, updateSelectedDropDownList] = useState(
    //     [] as UpdateActiveFunctionType[]
    // );

    const handleExport = useCallback(() => {
        // only used for generic functions lib level export
        let librariesToBeExported: payloadToExportFunctionWithLibraries[] = [];
        [libraryDetail].forEach((item) => {
            let libraryObj: payloadToExportFunctionWithLibraries = {
                isProtected: false,
                libraryId: '',
                libraryVersion: '',
                functionTypes: [],
            };
            libraryObj.isProtected = !!libraryDetail.isIPProtected;
            libraryObj.libraryId = item.id;
            libraryObj.libraryVersion = item.libraryVersion;
            libraryObj.functionTypes = item.nodes.filter((functionDetail) =>
                isPresentInFunctions(functionDetail.typeId, selectedFunctionDetails)
            );
            librariesToBeExported.push(libraryObj);
        });
        props.showGetFileNameModal(
            () => getExportableFunctionsRequest(librariesToBeExported),
            libraryDetail.root ||
                (librariesToBeExported[0].functionTypes.length === 1
                    ? librariesToBeExported[0].functionTypes[0].name
                    : GENERIC_FUNCTIONS)
        );
    }, [libraryDetail, selectedFunctionDetails, getExportableFunctionsRequest]);

    // const handleDropDownChange = useCallback(
    //     (selectedOptions: UpdateActiveFunctionType[], actionMeta: any) => {
    //         updateSelectedDropDownList(selectedOptions as UpdateActiveFunctionType[]);
    //         const option: UpdateActiveFunctionType = actionMeta.option;
    //         if (actionMeta.action === 'select-option') {
    //             // const optionIsPartOfLib = functionBelongsToLibrary(option, [libraryDetail]);
    //             const payload: getFunctionVersionsType = {
    //                 model: option.model,
    //                 typeId: option.typeId,
    //                 name: option.name,
    //                 description: option.description,
    //                 tags: option.tags,
    //             };
    //             // if (!optionIsPartOfLib) {
    //             //     getFunctionVersions(payload);
    //             // } else {
    //             storeFunctionsVersions([{ ...payload, versions: [option.version] }]);
    //             // }
    //         } else if (actionMeta.action === 'remove-value') {
    //             let updatedFunctionVersions = _.cloneDeepWith(functionVersionDetails).filter(
    //                 (item) => item.typeId !== actionMeta.removedValue.typeId
    //             );
    //             let updatedSelectedDetails = _.cloneDeepWith(selectedFunctionDetails);
    //             updatedSelectedDetails = updatedSelectedDetails.filter(
    //                 (item) => item.typeId !== actionMeta.removedValue.typeId
    //             );
    //             removedFromDropDown({
    //                 functionVersionDetails: updatedFunctionVersions,
    //                 selectedFunctionDetails: updatedSelectedDetails,
    //             });
    //         }
    //     },
    //     [
    //         updateSelectedDropDownList,
    //         libraryDetail,
    //         getFunctionVersions,
    //         storeFunctionsVersions,
    //         removedFromDropDown,
    //         functionVersionDetails,
    //         selectedFunctionDetails,
    //     ]
    // );

    const handleLibExport = useCallback(
        (libDetail: UpdateActiveFunctionType) => {
            if (checkWhetherFunctionIsSelected(libDetail, selectedFunctionDetails)) {
                updateFunctionListToGetJson({
                    checkboxValue: true,
                    functionDetails: [libDetail],
                });
            } else {
                updateFunctionListToGetJson({
                    checkboxValue: false,
                    functionDetails: [libDetail],
                });
            }
        },
        [selectedFunctionDetails, updateFunctionListToGetJson]
    );

    const functionsRenderer = ({
        key,
        index,
        isScrolling,
        isVisible,
        style,
    }: {
        key: string;
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: any;
    }) => {
        return (
            <div
                key={
                    libraryDetail.nodes[index].typeId +
                    libraryDetail.nodes[index].model +
                    libraryDetail.nodes[index].name +
                    libraryDetail.nodes[index].version
                }
                className="function-label"
            >
                <Checkbox
                    sizeClass="small"
                    value={checkWhetherFunctionIsSelected(
                        libraryDetail.nodes[index],
                        selectedFunctionDetails
                    )}
                    onChange={() => handleLibExport(libraryDetail.nodes[index])}
                />
                {libraryDetail.nodes[index].name} {libraryDetail.nodes[index].version}
            </div>
        );
    };

    return (
        <div className="single-library-export-container">
            <div className="library-name">{libraryDetail.root || GENERIC_FUNCTIONS}</div>

            <div className="library-export-input">
                {libraryDetail.nodes.length > 0 ? (
                    <React.Fragment>
                        {!props.singleExport ? (
                            <Checkbox
                                label="Select All"
                                sizeClass="small"
                                value={
                                    selectedFunctionDetails.length === libraryDetail.nodes.length
                                }
                                onChange={() => {
                                    let functionsToSendInPayload: UpdateActiveFunctionType[] = [];

                                    if (
                                        selectedFunctionDetails.length <= libraryDetail.nodes.length
                                    ) {
                                        libraryDetail.nodes.forEach((lib) => {
                                            let isPresent = false;
                                            selectedFunctionDetails.forEach((functionDetail) => {
                                                if (lib.typeId === functionDetail.typeId) {
                                                    isPresent = true;
                                                }
                                            });
                                            if (!isPresent) {
                                                functionsToSendInPayload.push(lib);
                                            }
                                        });
                                    }

                                    updateFunctionListToGetJson({
                                        functionDetails: functionsToSendInPayload,
                                        checkboxValue:
                                            selectedFunctionDetails.length ===
                                            libraryDetail.nodes.length,
                                    });
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <div className="available-function-list">
                            <List
                                width={418}
                                height={libraryDetail.nodes.length * 37}
                                rowHeight={37}
                                rowRenderer={functionsRenderer}
                                rowCount={libraryDetail.nodes.length}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="library-export-spinner">
                        There are no functions in this library
                    </div>
                )}
            </div>

            {showSpinnerModal && (
                <div className="library-export-spinner">
                    <Spinner sizeClass="medium" color="blue" />
                </div>
            )}

            {libraryDetail.nodes.length > 0 && (
                <div className="buttons-library-export">
                    <Button
                        text="Export"
                        sizeClass="medium"
                        type="primary-blue"
                        onClick={handleExport}
                        disabled={!selectedFunctionDetails.length || showSpinnerModal}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        selectedFunctionDetails: state.exportAsset.selectedFunctionDetails,
        functionVersionDetails: state.exportAsset.functionVersionDetails,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleSelectAllForGenericFunction: (isSelected: boolean) => {
            dispatch(handleSelectAllForGenericFuncExport(isSelected));
        },
        getFunctionVersions: (val: getFunctionVersionsType) => {
            dispatch(getFunctionVersions(val));
        },
        storeFunctionsVersions: (val: any) => {
            dispatch(storeFunctionsVersions(val));
        },
        removedFromDropDown: (val: any) => {
            dispatch(removedFromDropDown(val));
        },
        getExportableFunctionsRequest: (payload: payloadToExportFunctionWithLibraries[]) => {
            dispatch(getExportableFunctionsRequest(payload));
        },
        updateFunctionListToGetJson: (payload: {
            functionDetails: UpdateActiveFunctionType[];
            checkboxValue: boolean;
        }) => dispatch(updateFunctionListToGetJson(payload)),
        showGetFileNameModal: (exportMethod: () => any, defaultFileName: string) => {
            dispatch(
                showModal({
                    component: GetExportZipName,
                    modalTitle: 'Add Export File Name',
                    data: {
                        exportMethod,
                        defaultFileName,
                    },
                    closeModal: () => dispatch(changeZipFileNameForExport({ fileName: '' })),
                    customClassName: 'delete-library-modal',
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleLibraryExport);
