import React, { Fragment } from 'react';

interface ConditionProps {
  when: boolean | number | string | object | Function | null | undefined;
  children: React.ReactNode;
}

/**
 * Declarative IF component
 */
const Condition = (props: ConditionProps) => {
  return Boolean(props.when) ? (
    <Fragment>{props.children || ''}</Fragment>
  ) : null;
};

export default Condition;
