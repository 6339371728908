import React, { useState, useCallback, useEffect } from "react";
import { FormGroup, Label, CustomInput, ModalFooter, Form } from "reactstrap";

import { connect } from "react-redux";
import { StoreState } from "../../../../store";
import { Dispatch } from "redux";
import { hideModal, showModal } from "../../../../store/modal/action";
import {
    handleAssetToggle,
    handleInstanceMonitoring,
} from "../../../../store/assets/actions";
import {
    handleAssetModelConfig,
    updateInstanceMonitoringList,
} from "../../../../store/instanceConfig/actions";
import "./style.scss";
import { Input, Button, Checkbox } from "@abb/abb-common-ux-react";
import CriticalityModalItem from "./CriticalityModalItem";
import { InstanceMonitoringType } from "../../../../store/assets/types";
import {
    InstanceMonitoringItem,
    ACTION_MENU_VALUE,
} from "../../../../store/instanceConfig/types";
import AssetConfigModal from "../../AssetConfigModal";
import { CREATE_NEW_MODEL } from "../../../../utils/constants/uiConstants";
import _ from "lodash";

interface AssetCriticalityModalFormProps {}

export const AssetCriticalityModalForm = (
    props: AssetCriticalityModalFormProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [selectAllItemStatus, updateSelectAllItemStatus] = useState(false);
    const [
        availableMonitoringListItems,
        updateAvailableMonitoringListItem,
    ] = useState([] as InstanceMonitoringItem[]);

    const handleInstanceMonitoringType = () => {
        if (
            props.selectedMenuItem &&
            props.selectedMenuItem === ACTION_MENU_VALUE.configure
        ) {
            props.hideAssetCriticalityModal();
            props.showAssetConfigureModal();
        } else {
            props.handleInstanceMonitoring();
            props.hideAssetCriticalityModal();
            props.handleInstanceMonitoringList([]);
        }
    };
    const updateConfiguredAsset = () => {
        props.hideAssetCriticalityModal();
        props.handleAssetToggle();
    };

    const handleSelectAllCheckboxStatus = () => {
        if (selectAllItemStatus) {
            props.handleInstanceMonitoringList([]);
            updateSelectAllItemStatus(false);
        } else {
            updateSelectAllItemStatus(true);
            let filteredMonitoringListItems = _.cloneDeep(
                availableMonitoringListItems
            ).map((item: any) => {
                let value: string[] = [];
                item.value.forEach((monitorModel: string) => {
                    const amInfo = props.instanceTableDataById[item.key].amInfo;
                    let present = false;
                    amInfo.forEach((amInfoDetails: any) => {
                        if (
                            amInfoDetails.assetMonitorModel === monitorModel &&
                            props.selectedMenuItem &&
                            props.selectedMenuItem.search(
                                amInfoDetails.configurationStatus
                                    .toLowerCase()
                                    .slice(0, -1)
                            ) === -1
                        ) {
                            present = true;
                        }
                    });
                    if (present) {
                        value.push(monitorModel);
                    }
                });
                item.value = value;

                return item;
            });

            props.handleInstanceMonitoringList([
                ...filteredMonitoringListItems,
            ]);
        }
    };

    useEffect(() => {
        const monitoringListItems = props.selectedInstances.map(
            (instanceId) => {
                const allItems = props.instanceTableDataById[
                    instanceId
                ].amInfo.map((item: any) => {
                    return item.assetMonitorModel;
                });

                return {
                    key: instanceId,
                    value: [...allItems],
                };
            }
        );

        updateAvailableMonitoringListItem(monitoringListItems);
    }, []);

    const [filteredInstances, setFilteredInstances] = useState([] as any[]);
    useEffect(() => {
        let updatedFilteredInstances: any[] = [];

        _.cloneDeep(props.selectedInstances).forEach((item: any) => {
            const amInfo = props.instanceTableDataById[item].amInfo;
            let present = false;
            amInfo.forEach((amInfoDetails: any) => {
                if (
                    props.selectedMenuItem &&
                    props.selectedMenuItem.search(
                        amInfoDetails.configurationStatus
                            .toLowerCase()
                            .slice(0, -1)
                    ) === -1
                ) {
                    present = true;
                }
            });
            if (present) {
                updatedFilteredInstances.push(item);
            }
        });
        setFilteredInstances(updatedFilteredInstances);
    }, []);

    useEffect(() => {
        let selectAllStatus = true;

        // filtered the array on the basis of value length
        const instanceMonitoringListItems = props.instanceMonitoringList.filter(
            (item) => item.value.length
        );
        if (
            instanceMonitoringListItems.length === filteredInstances.length &&
            filteredInstances.length > 0
        ) {
            props.instanceMonitoringList.forEach((item) => {
                const updatedAmInfo = _.cloneDeep(
                    props.instanceTableDataById[item.key].amInfo
                ).filter(
                    (item) =>
                        props.selectedMenuItem &&
                        props.selectedMenuItem.search(
                            item.configurationStatus.toLowerCase().slice(0, -1)
                        ) === -1
                );
                if (item.value.length !== updatedAmInfo.length) {
                    selectAllStatus = false;
                }
            });
            if (!selectAllStatus) {
                updateSelectAllItemStatus(false);
            } else {
                updateSelectAllItemStatus(true);
            }
        } else {
            updateSelectAllItemStatus(false);
        }
    }, [props.instanceMonitoringList]);

    return (
        <div className="wrapper-asset-criticality-modal">
            <div>
                <Checkbox
                    id={`selectAllCriticalityITems`}
                    value={selectAllItemStatus}
                    label="Select all items"
                    sizeClass="small"
                    onChange={handleSelectAllCheckboxStatus}
                />
            </div>
            <div className="body-asset-criticality">
                {props.selectedInstances.map((instanceId) => {
                    return (
                        <CriticalityModalItem
                            data={props.instanceTableDataById[instanceId]}
                            selectAllItems={selectAllItemStatus}
                            selectedMenuItem={props.selectedMenuItem}
                        />
                    );
                })}
            </div>
            <ModalFooter>
                {props.selectedMenuItem &&
                    props.selectedMenuItem === ACTION_MENU_VALUE.configure && (
                        <Button
                            text={props.updateBtnText}
                            type="primary-blue"
                            sizeClass="small"
                            onClick={updateConfiguredAsset}
                            disabled={props.instanceMonitoringList.length === 0}
                        />
                    )}

                <Button
                    text={props.submitBtnText}
                    type="primary-blue"
                    sizeClass="small"
                    onClick={handleInstanceMonitoringType}
                    disabled={props.instanceMonitoringList.length === 0}
                />
            </ModalFooter>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        submitBtnText: state.modal.data && state.modal.data.submitBtnText,
        updateBtnText: state.modal.data && state.modal.data.updateBtnText,
        modelDetails:
            state.instanceConfig.activeModel.modelInstance.modelDetails,
        configurationMode: state.assets.configurationMode,
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances,
        instanceTableDataById:
            state.instanceConfig.instancesTable.tableData.byId,
        instanceMonitoringList: state.instanceConfig.instanceMonitoringList,
        selectedMenuItem: state.instanceConfig.selectedMenuItem,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleAssetToggle: () => {
            dispatch(handleAssetToggle());
        },
        hideAssetCriticalityModal: () => dispatch(hideModal()),
        handleInstanceMonitoring: () => dispatch(handleInstanceMonitoring()),
        handleInstanceMonitoringList: (payload: InstanceMonitoringItem[]) => {
            dispatch(updateInstanceMonitoringList(payload));
        },
        showAssetConfigureModal: () => {
            dispatch(
                showModal({
                    component: AssetConfigModal,
                    modalTitle: CREATE_NEW_MODEL.ASSET_CONFIG_MODEL,
                    data: {
                        submitBtnText: CREATE_NEW_MODEL.ASSET_CREATE_TEXT,
                    },
                })
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetCriticalityModalForm);
