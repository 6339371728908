import React, { useState } from 'react';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import './style.scss';
import { Table } from 'reactstrap';
import CalculationTableHeader from '../../../../routes/Functions/CalculationLogicFunctionType/CalculationTableHeader';
import CalculationTableBody from '../../../../routes/Functions/CalculationLogicFunctionType/CalculationTableBody';
import { FUNCTION_MODE } from '../../../../utils/constants/appConstants';

interface CalculationConfigurationProps {
    calculationDetails: any;
    isEncrypted: boolean;
}

function CalculationConfiguration(props: CalculationConfigurationProps) {
    const { calculationDetails } = props;
    const [selectedValue, updateSelectedValue] = useState([] as any);

    let DefaultDropDownOptions: any = [];

    const handleDropDownSelectedValue = (selectedObj: any) => {
        updateSelectedValue(selectedObj);
    };

    if (calculationDetails) {
        Array.from(Object.keys(calculationDetails)).map((val: string) => {
            DefaultDropDownOptions.push({
                label: val,
                value: val,
            });
        });

        if (selectedValue.length === 0) {
            selectedValue.push(DefaultDropDownOptions[0]);
        }
    }

    const calculationArray = Object.keys(calculationDetails)
        .filter((item) => item === selectedValue[0].value)
        .map((item, index) => {
            return {
                name: item,
                description: calculationDetails[item].description,
                logic: calculationDetails[item].logic,
                outputType: calculationDetails[item].outputType,
            };
        });

    return (
        <div className="wrapper-calc-config">
            <div className="DropDownList">
                <span>Calculations</span>
                <Dropdown
                    placeholder="Select Calculation"
                    value={selectedValue}
                    onChange={(val) => handleDropDownSelectedValue(val)}
                >
                    {DefaultDropDownOptions.length > 0 &&
                        DefaultDropDownOptions.map((option: any) => {
                            return (
                                <DropdownOption
                                    key={option.value}
                                    label={option.label}
                                    value={option.value}
                                />
                            );
                        })}
                </Dropdown>
            </div>

            <div className="calculation-content">
                <Table className="customTableOverflow">
                    <CalculationTableHeader />
                    <CalculationTableBody
                        tableData={[...calculationArray]}
                        // handleCalcFieldChange={handleCalcFieldChange}
                        functionMode={FUNCTION_MODE.VIEW}
                        isConfigurationMode={true}
                        isEncrypted={props.isEncrypted}
                        // handleLogicDialog={handleLogicDialog}
                    />
                </Table>
            </div>
        </div>
    );
}

export default CalculationConfiguration;
