import React from 'react';
import './style.scss';
import CreateModelForm from './Form';

interface AppProps {
    mode?: String;
}

function CreateModel(props: AppProps) {
    return (
        <div>
            <CreateModelForm mode={props.mode} />
        </div>
    );
}

export default CreateModel;
