import React from "react";
import Flex from "../../../components/shared/Flex";
import { connect } from "react-redux";
import { StoreState } from "../../../store";
import { DraggableItem } from "../../../components/LeftNavigationData/DataRow";
import { AssetTableDataJson } from "../../../store/instanceConfig/types";
import "../ObjectSidebar/style.scss";

export function SelectedInstancesSidebar(
    props: ReturnType<typeof mapStateToProps>
) {
    const selectedInstacesData = props.selectedInstances.map((item) => {
        return props.tableDataById[item];
    });
    return (
        <Flex column className="wrapper-object-sidebar">
            <ul className="list">
                {selectedInstacesData.map((node: AssetTableDataJson) => {
                    return (
                        <DraggableItem
                            asset={node}
                            editMode={true}
                            id={node.objectId}
                            className="list-item"
                            children={node.name}
                        ></DraggableItem>
                    );
                })}
            </ul>
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances,
        tableDataById: state.instanceConfig.instancesTable.tableData.byId,
    };
};

export default connect(mapStateToProps)(SelectedInstancesSidebar);
