import { IODataTypes } from "../../components/Fabric/types";

export function defaultValueFromJson(options: { value: string }) {
    const { value } = options;

    return value.toString();
}

export function defaultValueToJson(options: {
    dataType: IODataTypes;
    value: string;
}) {
    const { dataType, value } = options;
    let valueArray: string[] = [];
    const resultArray: (string | number | boolean)[] = [];

    if (dataType.includes("array")) {
        valueArray = value.split(",");
    } else {
        valueArray = [value];
    }

    valueArray.forEach(value => {
        if (dataType.includes("string")) {
            resultArray.push(value);
        } else if (dataType.includes("number")) {
            try {
                resultArray.push(parseFloat(value));
            } catch {}
        } else if (dataType.includes("integer")) {
            try {
                resultArray.push(parseInt(value));
            } catch {}
        } else if (dataType.includes("boolean")) {
            try {
                resultArray.push(JSON.parse(value));
            } catch {}
        }
    });

    if (dataType.includes("array")) {
        return resultArray;
    } else {
        return resultArray[0];
    }
}
