import { fabric } from "fabric";
import { canvasPrimaryBorderColor, triggerIconSize } from "../../UiConfig";
import { SelfPositioningChild } from "../../../NodeController/types";

const triggerIconPath = [
    { x: 5.42, y: 0 },
    { x: 10.24, y: 0 },
    { x: 6.99, y: 5.94 },
    { x: 12.43, y: 3.77 },
    { x: 6.11, y: 16 },
    { x: 8.47, y: 8.03 },
    { x: 3.65, y: 10.06 },
    { x: 5.42, y: 0 }
];
const scale = triggerIconSize / 12;

export class FabricTriggerIcon extends fabric.Polygon
    implements SelfPositioningChild {
    data: SelfPositioningChild["data"];

    constructor(group: fabric.Group) {
        super(triggerIconPath, {
            stroke: canvasPrimaryBorderColor,
            fill: canvasPrimaryBorderColor,
            originX: "left",
            originY: "bottom",
            scaleX: scale,
            scaleY: scale,
            selectable: false,
            hoverCursor: "default",
            top: group.getPointByOrigin("left", "top").y || 0,
            left: (group.getPointByOrigin("left", "top").x || 0) - 10
        });
        this.data = {
            rePosition: () => {
                this.left = (group.getPointByOrigin("left", "top").x || 0) - 10;
                this.top = group.getPointByOrigin("left", "top").y || 0;
                this.scaleX = (group.scaleX || 1) * scale;
                this.scaleY = (group.scaleX || 1) * scale;
                this.setCoords();
            },
            tooltip: "Trigger"
        };
    }
}
