import { fabric } from 'fabric';
import { canvasPrimaryBorderColor } from './UiConfig';


export class FabricCrossIcon extends fabric.Group {
  constructor() {
    const circle = new fabric.Circle({
      radius: 15,
      fill: canvasPrimaryBorderColor,
      left: 0,
      top: 0,
      originX: 'center',
      originY: 'center'
    })

    const cross = new fabric.Text('+', {
      fontSize: 28,
      fontWeight: 900,
      originX: 'center',
      originY: 'center'
    })

    cross.rotate(135)

    super([circle, cross], {
      hoverCursor: 'pointer',
      selectable: false,
      name: 'deleteSelection',
      originY: 'center',
      originX: 'center'
    })

  }
}


