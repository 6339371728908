import React, { useState } from 'react';
import Flex from '../../../../../components/shared/Flex';
import './styles.scss';
import { connect } from 'react-redux';
import {
    handleAssetToggle,
    updateFilterAssetQuery,
    resetAssetsFilter,
} from '../../../../../store/assets/actions';
import { Dispatch } from 'redux';
import { StoreState } from '../../../../../store';
import {
    handleAssetConfigureBtnClickAction,
    changeEnteriesPerPageInstanceConfig,
    handleAssetEnableBtnClickAction,
} from '../../../../../store/instanceConfig/actions';
import { Button, Dialog, Icon, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';
import FilterConfigModal from '../../../FilterInstanceModal';
import { IFilteredItem } from '../../../../../store/assets/types';
import { CONFIGURATION_STATUS } from '../../../../../utils/constants/appConstants';
import { ACTION_MENU_LIST, IAssetPermission } from '../../../../../store/instanceConfig/types';
import { hideDialog, showDialog } from '../../../../../store/dialog/action';
import { showModal, hideModal } from '../../../../../store/modal/action';
import UpdateModels from '../../../../../components/UpdateModel/Index';
import { CREATE_NEW_MODEL } from '../../../../../utils/constants/uiConstants';
import AssetUpdateModal from '../../../AssetUpdateModal';

// any
// todo

export const AssetInstanceHeader = (
    props: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
) => {
    const handleConfigureBtn = () => {
        !props.configureDisableStatus && props.handleConfigurationBtnClick();
    };
    const { handeUpdateAction } = props;
    const permissionList = [
        { key: 'Configure', value: IAssetPermission.hasMonitorInstance },
        { key: 'Enable', value: IAssetPermission.hasEnableInstance },
        { key: 'Disable', value: IAssetPermission.hasDisableInstance },
        { key: 'Remove', value: IAssetPermission.hasDeleteInstance },
    ];
    const [showFilterDialog, setFilterDialog] = useState(false);
    const [selectedBtn, setselectedBtn] = useState(false);
    const [actsObj, setactsObj] = useState(false);
    const [update, setupdate] = useState();

    const handleSelectedButton = (event: any) => {
        console.log(
            'configureDisableStatus',
            props.configureDisableStatus,
            CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED,
            CONFIGURATION_STATUS.DISABLED,
            CONFIGURATION_STATUS.ENABLED,
            ACTION_MENU_LIST
        );
        let hasPermission: any;
        let actObj: any;

        props.handleAssetEnableBtnClick(event);
    };
    const handleSelectUpdate = (e: any) => {
        handeUpdateAction(props.selectedInstances);
    };
    return (
        <Flex className="asset-instance-header-container" fill justifyContentBetween>
            <Flex id="assetInstanceHeaderTitle" className="title">
                Instances
            </Flex>
            <Dialog
                containerClassName="new-layout filter-dialog-parent "
                dimBackground={true}
                title="Filter Instances"
                isOpen={showFilterDialog}
                showCloseButton={true}
                onClose={() => {
                    setFilterDialog(false);
                }}
            >
                <FilterConfigModal
                    onApplyFilter={(filteredItems, filter) => {
                        setFilterDialog(false);
                        props.updateFilterAssetQuery(filteredItems, filter);
                        props.changeEnteriesPerPageInstanceConfig(props.currentTotalPageCount);
                    }}
                />
            </Dialog>
            <Flex>
                {props.filterQuery.applyFilter ? (
                    <div className="icon-filter">
                        <WithTooltip>
                            <Icon
                                name="abb/filter"
                                sizeClass="small"
                                onClick={() => {
                                    props.resetAssetsFilter();
                                    props.updateFilterAssetQuery([], false);
                                    props.changeEnteriesPerPageInstanceConfig(
                                        props.currentTotalPageCount
                                    );
                                }}
                            />
                            <Tooltip>
                                <span>Clear Filter</span>
                            </Tooltip>
                        </WithTooltip>
                    </div>
                ) : null}

                <Button
                    id="btnFiltre"
                    icon="abb/filter"
                    style={{ marginRight: `12px` }}
                    type="primary-blue"
                    text="Filter"
                    sizeClass="small"
                    disabled={props.selectedParentTreeNodeId == null}
                    onClick={() => {
                        setFilterDialog(true);
                    }}
                />
                <Button
                    icon="abb/trash"
                    style={{ marginRight: `12px` }}
                    type="primary-blue"
                    text="Remove"
                    sizeClass="small"
                    disabled={props.configureRemoveStatus ? true : actsObj}
                    onClick={(event) => {
                        handleSelectedButton(ACTION_MENU_LIST[3]);
                    }}
                />
                <Button
                    icon="abb/not-allowed"
                    style={{ marginRight: `12px` }}
                    type="primary-blue"
                    text="Disable"
                    sizeClass="small"
                    disabled={props.configureDisabledStatus ? true : actsObj}
                    onClick={(event) => {
                        handleSelectedButton(ACTION_MENU_LIST[2]);
                    }}
                />
                <Button
                    icon="abb/power-on-off"
                    style={{ marginRight: `12px` }}
                    type="primary-blue"
                    text="Enable"
                    sizeClass="small"
                    disabled={props.configureEnableStatus ? true : actsObj}
                    onClick={() => {
                        handleSelectedButton(ACTION_MENU_LIST[1]);
                    }}
                />
                {console.log(
                    'SELECTED_INSTANCES',
                    props.instanceTableDataById[props.selectedInstances[0]]
                )}
                <Button
                    icon="abb/synchronize"
                    style={{ marginRight: `12px` }}
                    type="primary-blue"
                    text="Update"
                    sizeClass="small"
                    disabled={
                        props.selectedInstances.length <= 0 ||
                        !props.selectedInstances.reduce((isUpdateAvailable, instanceId) => {
                            return (
                                isUpdateAvailable &&
                                props.instanceTableDataById[instanceId].amInfo.reduce(
                                    (isAllModelUpdateavailable, modelInfo) =>
                                        isAllModelUpdateavailable || modelInfo.isUpdateAvailable,
                                    false
                                )
                            );
                        }, true)
                    }
                    onClick={(e) => {
                        handleSelectUpdate(e);
                    }}
                />
                <Button
                    id="assetInstanceConfigBtn"
                    icon="abb/connect"
                    type="primary-blue"
                    disabled={!props.hasMonitorInstance ? true : props.configureDisableStatus}
                    text="Configure"
                    sizeClass="small"
                    onClick={handleConfigureBtn}
                />
            </Flex>
        </Flex>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        isConfiguredInstance: state.instanceConfig.instancesTable.tableData.selectedInstances[0]
            ? !(
                state.instanceConfig.instancesTable.tableData.byId[
                    state.instanceConfig.instancesTable.tableData.selectedInstances[0]
                ].amInfo[0].configurationStatus === CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED
            )
            : false,
        configureDisableStatus: state.instanceConfig.instancesTable.actionMenuList[0].isDisabled,
        currentTotalPageCount: state.instanceConfig.instancesTable.pagination.enteriesPerPage,
        selectedParentTreeNodeId: state.assets.selectedParentTreeNodeId,
        hasMonitorInstance: state.assets.assetPermission.hasMonitorInstance,
        hasUpdateAvilableInstace: state.assets.assetPermission.hasUpdateAvilableInstace,
        configureEnableStatus: state.instanceConfig.instancesTable.actionMenuList[1].isDisabled,
        configureDisabledStatus: state.instanceConfig.instancesTable.actionMenuList[2].isDisabled,
        configureRemoveStatus: state.instanceConfig.instancesTable.actionMenuList[3].isDisabled,
        configureUpdateStatus: state.instanceConfig.instancesTable.actionMenuList[4].isDisabled,
        selectedInstances: state.instanceConfig.instancesTable.tableData.selectedInstances,
        filterQuery: state.assets.filterQuery,
        instanceTableDataById: state.instanceConfig.instancesTable.tableData.byId,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleConfigurationBtnClick: () => {
            dispatch(handleAssetConfigureBtnClickAction());
        },
        handleAssetEnableBtnClick: (selectedActionMenu: typeof ACTION_MENU_LIST) => {
            dispatch(handleAssetEnableBtnClickAction(selectedActionMenu));
        },
        updateFilterAssetQuery: (filteredItems: IFilteredItem[], applyFilter: boolean) => {
            dispatch(updateFilterAssetQuery(filteredItems, applyFilter));
        },
        resetAssetsFilter: () => dispatch(resetAssetsFilter()),
        changeEnteriesPerPageInstanceConfig: (item: number) => {
            dispatch(
                changeEnteriesPerPageInstanceConfig({
                    enteriesPerPage: item,
                })
            );
        },
        handeUpdateAction: (selectedInstances: any[]) => {
            dispatch(
                showDialog({
                    component: UpdateModels,
                    modalTitle: 'Update Model',
                    data: {
                        closeModal: () => dispatch(hideDialog()),
                        onUpdate: () => {
                            dispatch(
                                showModal({
                                    component: AssetUpdateModal,
                                    modalTitle: 'Update Model',
                                    data: {
                                        submitBtnText: CREATE_NEW_MODEL.ASSET_UPDATE_TEXT,
                                        selectedInstances,
                                    },
                                })
                            );
                        },
                    },
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetInstanceHeader);
