import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useCallback, useState, useEffect } from 'react';
import './style.scss';

import Loader from '../../../Loader';
import SearchBar from '../../../SearchBar';
import Pagination from '../../../Pagination';
import { StoreState } from '../../../../store';
import Condition from '../../../shared/Condition';
import { LOADER_TYPE, LOADER_SIZE } from '../../../../utils/constants/appConstants';
import { ModelUsedInstanceType, InstancePaginationType } from '../../../../store/modelsPage/types';
import { getModelUsedbyInstances } from '../../../../store/modelsPage/actions';

interface InstancesTableProps {
    usedInstanceList: any;
}

const InstancesTable = (
    props: InstancesTableProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { usedInstanceList, loading } = props;
    const [instancesList, updateInstancesList] = useState(usedInstanceList);
    const [searchFilter, updateSearchFilter] = useState('');
    const handleActivePageChange = useCallback(
        (pageNumber: number) => {
            props.showUsedInstanceModel({
                pageSize: props.entriesPerPage,
                pageNumber: pageNumber,
            });
            updateSearchFilter('');
        },
        [props.activePage]
    );
    useEffect(() => {
        updateInstancesList(usedInstanceList);
    }, [usedInstanceList]);
    const handleSearch = (value: string) => {
        updateSearchFilter(value);
        let updatedInstanceList = _.cloneDeep(usedInstanceList);
        updatedInstanceList = updatedInstanceList.filter((item: any) => {
            return item.name.toUpperCase().search(value.toUpperCase()) >= 0;
        });
        updateInstancesList(updatedInstanceList);
    };
    return (
        <div className="instance-wrapper">
            <div className="search-instance">
                <SearchBar
                    placeholder={`Search for Instance`}
                    borderColor="border-dark-gray"
                    value={searchFilter}
                    onChange={(val: string) => handleSearch(val)}
                />
            </div>
            <div className="instance-table">
                <div className="instance-table-area">
                    {loading ? (
                        <Loader type={LOADER_TYPE.RADIAL} sizeClass={LOADER_SIZE.SMALL} />
                    ) : (
                        <table>
                            <tbody>
                                <tr>
                                    <th className="width-40">INSTANCE</th>
                                    <th className="width-60">PATH</th>
                                    {/* <th>ACTIONS</th> */}
                                </tr>


                                {props.usedInstanceList.length===0 && <tr className='noinstance'><div>No Instances Found</div></tr>}
                                {instancesList.map(
                                    (value: ModelUsedInstanceType, valueIndex: number) => {
                                        return (
                                            <tr key={valueIndex}>
                                                <td className="path-class">
                                                    {value.name ? `${value.name}` : ``}
                                                </td>
                                                <td className="path-class">
                                                    {value.path ? `${value.path}` : ``}
                                                </td>
                                                {/* <td>
                                        <Button
                                            icon="abb/openInNewWindow"
                                            id="instanceButton"
                                            text="Open"
                                            size="small"
                                            type="primary-blue"
                                        />
                                    </td> */}
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
                <Condition when={props.totalPages >= 1}>
                    <div className="pagination-details">
                        <div>
                            Showing{' '}
                            {props.activePage * props.entriesPerPage - (props.entriesPerPage - 1)}-
                            {props.activePage * props.entriesPerPage -
                                (props.entriesPerPage - 1) +
                                (instancesList.length - 1)}{' '}
                            of {props.totalRecords}
                        </div>
                        <Pagination
                            className="asset-table-pagination"
                            activePage={props.activePage}
                            totalPages={props.totalPages || 1}
                            onChange={handleActivePageChange}
                        />
                    </div>
                </Condition>
            </div>
        </div>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        totalPages: state.modelsPage.modelsPagination.totalPages,
        activePage: state.modelsPage.modelsPagination.activePage,
        totalRecords: state.modelsPage.modelsPagination.totalRecords,
        entriesPerPage: state.modelsPage.modelsPagination.entriesPerPage,
        loading: state.loader.paginationLoader.isLoading,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showUsedInstanceModel: (payload: InstancePaginationType) => {
            dispatch(getModelUsedbyInstances(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InstancesTable);
