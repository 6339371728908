import { fabric } from 'fabric';
import {
    canvasLineColor,
    noTriggerLineStrokeWidth,
    triggerLineStrokeWidth,
    triggerColor,
    noTriggerStrokeWidth,
} from './UiConfig';

interface Params {
    left: number;
    top: number;
    path?: any[];
}

export class FabricConnectionLine extends fabric.Path {
    triggerOn: () => void;
    triggerOff: () => void;
    rePosition: () => void;

    constructor(options: Params) {
        const { left, top, path } = options;
        super(path ? path : `M ${left} ${top} Q ${left}, ${top}, ${left}, ${top}`, {
            fill: '',
            stroke: canvasLineColor,
            strokeWidth: noTriggerLineStrokeWidth,
            selectable: false,
            objectCaching: false,
            perPixelTargetFind: true,
            hoverCursor: 'crosshair',
        });

        this.triggerOn = () => {
            this.set('stroke', triggerColor);
            this.set('strokeWidth', triggerLineStrokeWidth);
        };

        this.triggerOff = () => {
            this.set('stroke', canvasLineColor);
            this.set('strokeWidth', noTriggerLineStrokeWidth);
        };

        this.rePosition = () => {
            const p = new fabric.Path(this.path);
            console.log('%cLINE', 'background-color: white', this.height, p.height);
            this.left = p.left;
            this.top = p.top;
            this.width = p.width;
            this.pathOffset = p.pathOffset;
            this.height = p.height;
        };
    }
}
