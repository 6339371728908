import React from "react";

import "./style.scss";

import { CONFIRMATION_BUTTON } from "../../../utils/constants/uiConstants";
import { Dialog, Button } from "@abb/abb-common-ux-react";

interface MessageModalProps {
    // isOpen: boolean;
    data: any;
    title?: string;
    warningText: string;

    // acceptButtonTitle?: string;
    // rejectButtonTitle?: string;
    // handleSubmit: (name: string, tabSelected?: any) => void;
}

const MessageModal = (props: MessageModalProps) => {
    // const handleSubmit = (name: string) => {
    //     props.handleSubmit(name, props.data.tabSelected);
    // };

    console.log("MessageModal props :", props);
    return (
        // <Dialog
        //     title={props.title || "Discard Changes?"}
        //     dimBackground={true}
        //     closeOnEscape={false}
        //     showCloseButton={true}
        //     isOpen={props.isOpen}
        //     onClose={() => props.handleSubmit(CONFIRMATION_BUTTON.REJECT)}
        //     className="wrapper-message-modal"
        // >
        <div className="wrapper-warning-msg">
            <div className="wrapper-warning-data">
                {/* {props.data && ( */}
                <div className="message-modal-text">{props.warningText}</div>
                {/* )} */}
            </div>
            {/* <div className="yes-no-btn">
                    <div
                        id={CONFIRMATION_BUTTON.REJECT}
                        onClick={() => handleSubmit(CONFIRMATION_BUTTON.REJECT)}
                    >
                        <Button
                            className="conf-btn"
                            type="discreet-black"
                            text={
                                props.rejectButtonTitle ||
                                CONFIRMATION_BUTTON.CANCEL
                            }
                            sizeClass="small"
                        />
                    </div>
                    <div
                        id={CONFIRMATION_BUTTON.ACCEPT}
                        onClick={() => handleSubmit(CONFIRMATION_BUTTON.ACCEPT)}
                    >
                        <Button
                            className="conf-btn"
                            type="primary-blue"
                            sizeClass="small"
                            text={
                                props.acceptButtonTitle ||
                                CONFIRMATION_BUTTON.DISCARD_CHANGES
                            }
                        />
                    </div>
                </div> */}
        </div>
        // </Dialog>
    );
};

export default MessageModal;
