import React, { useCallback, useState, Fragment, useEffect } from "react";
import "./styles.scss";
import * as _ from "lodash";
import { Icon, Input } from "@abb/abb-common-ux-react";
import { If } from "../../../../routes/Functions/helper";
import ReactSelect from "react-select";
import {
    customReactSelectStyles,
    reactSelectMenuStyle,
} from "../../../../utils/helpers";
import { HIDDEN_LOGIC_TEXT } from "../../../../utils/constants/uiConstants";
import CustomABBInput from "../../../CustomABBInput";
import { columns } from "../../../../routes/Functions/type";

enum dataType {
    string = "string",
    integer = "integer",
}

function ConditionListView(props: any) {
    const [values, updatevalues] = useState("" as any);

    const [conditionsArr, updateConditionsArr] = useState(
        props.conditions as any
    );

    const TABLE_COLUMNS_ORDER = [
        columns.description,
        columns.severity,
        columns.possibleCause,
        columns.suggestedAction,
        columns.correctiveAction,
        columns.logic,
        columns.status,
    ];

    console.log("&&&props :", props);

    useEffect(() => {
        updateConditionsArr(props.conditions);
    }, [props.conditions]);

    const handleInputChange = (val: string, type: string) => {
        if (type === "severity") {
            if (!val) {
                props.handleInputChange(
                    val,
                    props.condition,
                    type,
                    true,
                    `${type} cannot be empty`
                );
            } else if (val !== "" && parseInt(val, 10) < 0) {
                props.handleInputChange(val, props.condition, type, true);
            } else {
                props.handleInputChange(val, props.condition, type);
            }
        } else {
            props.handleInputChange(val, props.condition, type);
        }

        updatevalues(Math.random());
    };

    return (
        <tr>
            <td className="width-15">{props.condition}</td>
            {TABLE_COLUMNS_ORDER.map((name: any, index: number) => {
                let info: any = _.get(props.conditions, [name]);
                return (
                    <Fragment>
                        <If cond={name === "severity"}>
                            <td className="width-14">
                                <CustomABBInput
                                    type="normal"
                                    dataType="number"
                                    value={info["value"]}
                                    onValueChange={(val) =>
                                        handleInputChange(val, "severity")
                                    }
                                    disabled={props.isDisabled}
                                    className="functionClass"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    indicateChanged={info["isDirty"]}
                                    validator={() =>
                                        info["severityError"] ||
                                        info["value"] === ""
                                            ? {
                                                  valid: info["severityError"]
                                                      ? info["severityError"][
                                                            "valid"
                                                        ]
                                                      : false,
                                                  text: info["severityError"]
                                                      ? info["severityError"][
                                                            "text"
                                                        ]
                                                      : "Severity cannot be empty",
                                              }
                                            : { valid: true, text: "OK!" }
                                    }
                                />
                            </td>
                        </If>
                        <If cond={name === "description"}>
                            <td className="width-14">
                                <div className="wrapper-text-area-fields">
                                    <CustomABBInput
                                        type="normal"
                                        dataType="textarea"
                                        value={info["value"]}
                                        indicateChanged={info["isDirty"]}
                                        onValueChange={(val) =>
                                            handleInputChange(
                                                val,
                                                "description"
                                            )
                                        }
                                        className="functionClass"
                                        disabled={props.isDisabled}
                                    />
                                    {info["isDirty"] && (
                                        <div className={"changedBorder"} />
                                    )}
                                </div>
                            </td>
                        </If>
                        <If cond={name === "possibleCause"}>
                            <td className="width-14">
                                <div className="wrapper-text-area-fields">
                                    <CustomABBInput
                                        type="normal"
                                        dataType="textarea"
                                        value={info["value"]}
                                        indicateChanged={info["isDirty"]}
                                        onValueChange={(val) =>
                                            handleInputChange(
                                                val,
                                                "possibleCause"
                                            )
                                        }
                                        className="functionClass"
                                        disabled={props.isDisabled}
                                    />
                                    {info["isDirty"] && (
                                        <div className={"changedBorder"} />
                                    )}
                                </div>
                            </td>
                        </If>
                        <If cond={name === "suggestedAction"}>
                            <td className="width-14">
                                <div className="wrapper-text-area-fields">
                                    <CustomABBInput
                                        type="normal"
                                        dataType="textarea"
                                        value={info["value"]}
                                        indicateChanged={info["isDirty"]}
                                        onValueChange={(val) =>
                                            handleInputChange(
                                                val,
                                                "suggestedAction"
                                            )
                                        }
                                        className="functionClass"
                                        disabled={props.isDisabled}
                                    />
                                    {info["isDirty"] && (
                                        <div className={"changedBorder"} />
                                    )}
                                </div>
                            </td>
                        </If>

                        <If cond={name === "correctiveAction"}>
                            <td className="width-14">
                                <div className="wrapper-text-area-fields">
                                    <CustomABBInput
                                        type="normal"
                                        dataType="textarea"
                                        value={info["value"]}
                                        indicateChanged={info["isDirty"]}
                                        onValueChange={(val) =>
                                            handleInputChange(
                                                val,
                                                "correctiveAction"
                                            )
                                        }
                                        className="functionClass"
                                        disabled={props.isDisabled}
                                    />
                                    {info["isDirty"] && (
                                        <div className={"changedBorder"} />
                                    )}
                                </div>
                            </td>
                        </If>

                        <If cond={name === "logic"}>
                            <td className="width-15">
                                <div className="customClassInput1">
                                    <div className="wrapper-text-area-fields">
                                        {props.isEncrypted ? (
                                            HIDDEN_LOGIC_TEXT
                                        ) : (
                                            <>
                                                <CustomABBInput
                                                    type="normal"
                                                    dataType="textarea"
                                                    disabled={true}
                                                    value={info["value"]}
                                                    indicateChanged={
                                                        info["isDirty"]
                                                    }
                                                    onValueChange={(val) =>
                                                        handleInputChange(
                                                            val,
                                                            "logic"
                                                        )
                                                    }
                                                    className="functionClass"
                                                />
                                                {info["isDirty"] && (
                                                    <div
                                                        className={
                                                            "changedBorder"
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {!props.isEncrypted && (
                                        <Icon
                                            name="abb/plus-in-circle"
                                            sizeClass="small"
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                left: "97%",
                                                bottom: "37px",
                                            }}
                                            onClick={() =>
                                                props.handleLogicDialog(
                                                    info["name"],
                                                    info["value"]
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </td>
                        </If>
                        <If cond={name === "status"}>
                            <td className="width-14">
                                <div className="wrapper-text-area-fields">
                                    <ReactSelect
                                        styles={{
                                            ...customReactSelectStyles,
                                            menu: reactSelectMenuStyle,
                                        }}
                                        isClearable={true}
                                        options={props.terminalsData}
                                        placeholder="Status"
                                        isDisabled={props.isDisabled}
                                        value={
                                            info["value"] && {
                                                label: info["value"],
                                                value: info["value"],
                                            }
                                        }
                                        getOptionLabel={(optionObj: any) => {
                                            return `${optionObj.label}`;
                                        }}
                                        getOptionValue={(optionObj: any) => {
                                            return `${optionObj.value}`;
                                        }}
                                        onChange={(value, actionMeta) => {
                                            let selectedValue =
                                                (value && value.value) || "";

                                            handleInputChange(
                                                selectedValue,
                                                "status"
                                            );
                                        }}
                                    />

                                    {info["isDirty"] && (
                                        <div className={"changedBorder"} />
                                    )}
                                </div>
                            </td>
                        </If>
                    </Fragment>
                );
            })}
        </tr>
    );
}

export default ConditionListView;
