import JSZip from 'jszip';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { AbilityService } from 'sce-engg-model-19.09';

import {
    dropDownOptions,
    columns,
    functionType,
    AssetsInfo,
    DropDownItem,
    FunctionTypeList,
    FunctionComponents,
} from './type';
import {
    FunctionHeaderTypeDetails,
    DragDropTableData,
    AlarmMapping,
    DropTableData,
    Functions,
    LibraryAndNodePayload,
    FunctionAlarmType,
    LibraryAndNodePayloadWithoutNodes,
    UpdateActiveFunctionType,
} from '../../store/function/types';
import { VersionUpdateType, VERSION_UPDATE_TYPE } from '../../store/configurationTool/types';
import { LIBRARIES, TABLE_IO_TYPE, GENERIC_FUNCTIONS } from '../../utils/constants/appConstants';
import { CreateFunctionAssetDetails } from '../../store/function/action';
import { FunctionDetailsRequiredByLibrary } from './CreateLibraryPopup';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';

type severityLogic = {
    returnVal: boolean;
    fieldName: string;
    name: string;
};

export const customValuesObj = (detailsList: any) => {
    let customObj: any = [];
    if (detailsList && !detailsList.error) {
        detailsList.map((data: any, index: number) => {
            customObj.push({
                assetDescription: data.description.value,
                assetName: data.name.value,
                assetRef: data.id.value,
                assetTags: data.tags,
                assetType: data.model.value,
                assetVersion: data.version.value,
                isActive: index === 0 ? true : false,
            });
        });
    }

    return customObj;
};
export const convertFuncDetailsToFuncType = (
    detail: CreateFunctionAssetDetails | UpdateActiveFunctionType
): FunctionTypeList => {
    return {
        assetRef: detail.typeId,
        assetDescription: detail.description,
        assetName: detail.name,
        assetType: detail.model,
        assetVersion: detail.version,
        assetTags: detail.tags,
    };
};

export const customValuesObjForMutuallyExclusiveFunctions = (
    detailsList: UpdateActiveFunctionType
) => {
    let customObj: any = [];
    customObj.push({
        assetDescription: detailsList.description,
        assetName: detailsList.name,
        assetRef: detailsList.typeId,
        assetTags: detailsList.tags,
        assetType: detailsList.model,
        assetVersion: detailsList.version,
        isActive: true,
    });
    return customObj;
};

export const findActiveIndexForFunctionsLibrary = (
    libraryAndFunctionsList: LibraryAndNodePayload[]
) => {
    let index = 0;
    const listLength = libraryAndFunctionsList.length;
    while (!libraryAndFunctionsList[index].nodes.length && index <= listLength - 1) {
        index++;
    }
    if (index === listLength - 1 && !libraryAndFunctionsList[index].nodes.length) {
        return -1;
    }
    return index;
};

// @ts-ignore
export const If = ({ cond, children }) => (cond ? children : null);

export const labelValueUpdate = (conditionsArr: any) => {
    let customObj: any = [];
    if (conditionsArr.length > 0) {
        conditionsArr.map((data: any, index: number) => {
            let obj = data[0][Object.keys(data[0])[0]];
            customObj.push({
                name: Object.keys(data[0])[0],
                ...obj,
            });
        });
    }
    return customObj;
};

export const convertedConditionObj = (conditionsArr: any) => {
    let customObjArr: any = [];
    Array.from(Object.keys(conditionsArr)).map((condition: string, conditionIndex: number) => {
        let customObj: any = [];
        const conditionsList: any = _.get(conditionsArr, [condition]);
        Object.keys(conditionsList.subConditions).map((name: any, index: number) => {
            let sublist: any = _.get(conditionsList.subConditions, [name]);
            customObj.push([{ [name]: sublist }]);
        });
        if (conditionsList.hasOwnProperty('deleteFlag')) {
            customObjArr.push({
                [condition]: labelValueUpdate(customObj),
                deleteFlag: true,
            });
        } else {
            customObjArr.push({ [condition]: labelValueUpdate(customObj) });
        }
    });
    const clonedCustomObjArr = _.cloneDeep(customObjArr);
    return clonedCustomObjArr;
};

export const convertedObj = (conditionsArr: any) => {
    let customObj: any = [];
    Array.from(Object.keys(conditionsArr)).map((condition: string, conditionIndex: number) => {
        const conditionsList: any = _.get(conditionsArr, [condition]);
        customObj.push([{ [condition]: conditionsList }]);
    });
    return labelValueUpdate(customObj);
};

export const convertedToJSONObj = (conditionsArr: any) => {
    let customObj: any = {};
    conditionsArr.map((condition: any, conditionIndex: number) => {
        const name = condition['name'];
        const defaultObj = { ...condition };
        if (!customObj[name]) {
            customObj[name] = defaultObj;
        } else {
            customObj[`${name}-${conditionIndex}`] = defaultObj;
        }
    });

    return customObj;
};

export const getSelectedDataType = (selectedObj: string) => {
    let customObj: any = {};
    if (
        selectedObj === dropDownOptions.stringArr ||
        selectedObj === dropDownOptions.numberArr ||
        selectedObj === dropDownOptions.integerArr ||
        selectedObj === dropDownOptions.booleanArr
    ) {
        customObj['dataType'] = 'array';
        customObj['items'] = selectedObj.replace(' (arr)', '');
    } else {
        customObj['dataType'] = selectedObj;
    }
    return customObj;
};

export const hasWhiteSpace = (s: string) => {
    return /\s/g.test(s);
};

export const hasSpecialCharacters = (s: string) => {
    return /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/.test(s);
};

export const validateForSwedishAndFinishCharacters = (s: string) => {
    let valid = true;
    const pattern = new RegExp('^[A-Za-z0-9]{0,150}$');
    valid = pattern.test(s);
    return valid;
};

export const validateForSpecialCharacters_Including_HyphenAndUnderscore = (s: string) => {
    let valid = true;
    const pattern = new RegExp('^[A-Za-z0-9-_]{0,150}$');
    valid = pattern.test(s);
    return valid;
};

export const hasSpecialCharactersExcept_Underscore_Hyphen_DotAtBetween = (s: string) => {
    var patt = new RegExp('^(?=.{1,150}$)[\\w\\-]([\\w\\-]|[\\.][\\w\\-])*$');
    return patt.test(s);
}

export const hasSpecialCharacter = (value: string) => {
    let isSpecialChar = true;
    let splCharPattern = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    if (splCharPattern.test(value)) {
        isSpecialChar = false;
    }
    return isSpecialChar
}

export const hasSpecialCharactersExceptDot = (s: string) => {
    return /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/.test(s);
};
export const hasNoSpecialCharactersAndDot = (s: string) => {
    let valid = true;
    valid = /[-@!$%^&*\(\)_+|~=`\\#{}\[\]:";'<>?,.\/]/.test(s);
    return valid;
};
export const hasNegativeValue = (n: string) => {
    return /^[1-9][0-9]*$/.test(n);
};

export const checkIntegerArray = (n: string) => {
    return /^[0-9,]*$/.test(n);
};

export const checkNumberArray = (n: string) => {
    return /^((([-]?[0-9])+([\.]*[0-9]*[,]*))*)$/.test(n);
};
export const onlyNumber = (n: string) => {
    return /^[0-9]*$/.test(n);
};

export const finalInputJSON = (conditionsArr: any) => {
    Array.from(Object.keys(conditionsArr)).forEach((condition: any, index: number) => {
        const conditionsList: any = _.get(conditionsArr, [condition]);
        delete conditionsList['name'];
    });
    return removeEmptyValueAndConvertArray(conditionsArr);
};

export const removeEmptyValueAndConvertArray = (inputFinalJSON: any) => {
    Array.from(Object.keys(inputFinalJSON)).map((inpList: any, index: number) => {
        let inputData: any = _.get(inputFinalJSON, [inpList]);
        if (_.has(inputData, 'value') && typeof inputData['value'] === 'object') {
            if (inputData.items === 'string') {
                inputData['value'] = inputData['value'].filter((v: any) => v !== '');
            } else {
                if (inputData.items === 'boolean') {
                    inputData['value'] = inputData['value'].filter((v: any) => v !== '')
                } else {
                    inputData['value'] = inputData['value']
                        .filter((v: any) => v !== '')
                        .map((val: any) => Number(val));
                }
            }
        }
    });

    return inputFinalJSON;
};

export const finalConditionJSON = (conditionsArr: any) => {
    let customObj: any = {};
    conditionsArr.map((condition: any, conditionIndex: number) => {
        Object.keys(condition).map((subCondition: string, subConditionIndex: number) => {
            let customSubObj: any = {};
            const conditionsList: any = _.get(condition, [subCondition]);

            if (subCondition !== 'error' && subCondition !== 'deleteFlag') {
                conditionsList.map((list: any, listIndex: number) => {
                    if (customSubObj[list.name]) {
                        customSubObj[`${list.name}${listIndex}`] = list;
                    } else {
                        customSubObj[list.name] = list;
                    }

                    if (condition.hasOwnProperty('deleteFlag')) {
                        customObj[subCondition] = {
                            subConditions: customSubObj,
                            deleteFlag: true,
                        };
                    } else {
                        customObj[subCondition] = {
                            subConditions: customSubObj,
                        };
                    }
                });
            }
        });
    });
    return customObj;
};

export const computefinalConditionJSON = (conditionsArr: any) => {
    Array.from(Object.keys(conditionsArr)).map((condition: string, conditionIndex: number) => {
        let conditionsList: any = _.get(conditionsArr, [condition]);
        Array.from(Object.keys(conditionsList.subConditions)).map(
            (subCondition: string, subConditionIndex: number) => {
                let subConditionList: any = _.get(conditionsList.subConditions, [subCondition]);
                delete subConditionList['name'];
                if (!subConditionList['description']['value']) {
                    delete subConditionList['description']['value'];
                }
                if (!subConditionList['possibleCause']['value']) {
                    delete subConditionList['possibleCause']['value'];
                }
                if (!subConditionList['suggestedAction']['value']) {
                    delete subConditionList['suggestedAction']['value'];
                }
                if (!subConditionList['correctiveAction']['value']) {
                    delete subConditionList['correctiveAction']['value'];
                }
                if (!subConditionList['status']['value']) {
                    delete subConditionList['status']['value'];
                }
            }
        );
    });
    return conditionsArr;
};

export const initalInput = (inputProps: any) => {
    let filteredInputProperties = {};
    Object.keys(inputProps).map((key) => {
        if (
            key !== columns.conditions &&
            key !== columns.calculations &&
            key !== columns.alarmMapping
        ) {
            filteredInputProperties[key] = inputProps[key];
        }
    });
    return filteredInputProperties;
};

export const setDataTypes = (data: any) => {
    let outputSelectedValues: any = [];
    Array.from(Object.keys(data)).map((condition: string, conditionIndex: number) => {
        let conditionsList: any = _.get(data, [condition]);

        Object.keys(conditionsList).map((name: any, index: number) => {
            let info: any = _.get(conditionsList, [name]);
            if (name === columns.dataType) {
                let type: string = info;

                if (conditionsList.items && conditionsList.items === 'integer') {
                    type = dropDownOptions.integerArr;
                }

                if (conditionsList.items && conditionsList.items === 'number') {
                    type = dropDownOptions.numberArr;
                }

                if (conditionsList.items && conditionsList.items === 'string') {
                    type = dropDownOptions.stringArr;
                }

                if (conditionsList.items && conditionsList.items === 'boolean') {
                    type = dropDownOptions.booleanArr;
                }

                outputSelectedValues.push([{ label: type, value: type, name: condition }]);
            }
        });
    });
    return outputSelectedValues;
};
let conditionArray: any[] = [];
let fieldName: any[] = [];
export const checkSeverityLogic = (conditionDetails: any) => {
    fieldName = [];
    let returnVal: severityLogic = { returnVal: false, fieldName: '', name: '' };
    Array.from(Object.keys(conditionDetails)).map(
        (conditionName: string, conditionIndex: number) => {
            const conditionList: any = _.get(conditionDetails, [conditionName]);
            console.log(
                'condition',
                Array.from(Object.keys(conditionList['subConditions'])),
                conditionDetails
            );
            Array.from(Object.keys(conditionList['subConditions'])).map(
                (subCondition: string, subConditionIndex: number) => {
                    conditionArray = Array.from(Object.keys(conditionList['subConditions']));
                    if (subCondition !== '') {
                        const subConditionList: any = _.get(conditionList['subConditions'], [
                            subCondition,
                        ]);

                        console.log(subConditionList, 'subConditionList');
                        if (subConditionList['severity']['value'] === '') {
                            debugger;
                            returnVal = {
                                returnVal: true,
                                fieldName: 'Severity',
                                name: subConditionList.name,
                            };
                            fieldName.push([{ [subConditionList.name]: 'Severity' }]);
                        }
                        if (subConditionList['logic']['value'] === '') {
                            debugger;
                            returnVal = {
                                returnVal: true,
                                fieldName: 'Logic',
                                name: subConditionList.name,
                            };
                            fieldName.push([{ [subConditionList.name]: 'Logic' }]);
                        }
                    } else {
                        returnVal = {
                            returnVal: true,
                            fieldName: 'SubCondition',
                            name: '',
                        };
                    }
                }
            );
        }
    );
    console.log(fieldName, 'fieldName');
    return returnVal;
    return fieldName;
};

export const defaultErrorObj = (title: string, detail: string) => {
    let data = {};
    data['meta'] = [];
    data['status'] = 400;
    data['title'] = title;
    data['detail'] = detail;
    return data;
};

export const checkDefaultFunctionName = (
    typeFunction: any,
    counter: number,
    functionName: string
) => {
    let countingIndex: number = counter;
    let countName: string = functionName;

    const functionType: string = functionName.includes('Input') ? 'Input' : 'Output';

    typeFunction.forEach((typeName: any) => {
        const nameIndex: number = _.findIndex(typeFunction, {
            name: countName,
        });
        if (nameIndex > 0) {
            countName = `${functionType}${countingIndex}`;
            countingIndex++;
        }
    });

    return countName;
};
const getIODetails = (details: []) => {
    let detailsObj = {};
    details.forEach((detail: any) => {
        detailsObj[detail.name] = detail;
    });
    return detailsObj;
};
export const updateHeaderDetailsObj = (
    componentType: 'INPUT' | 'OUTPUT',
    details: [],
    functionHeaderDetails: FunctionHeaderTypeDetails
) => {
    let payloadObj: FunctionHeaderTypeDetails = {
        inputDetails: {},
        outputDetails: {},
    };
    if (componentType === TABLE_IO_TYPE.INPUT) {
        payloadObj.inputDetails = getIODetails(details);
        payloadObj.outputDetails = functionHeaderDetails.outputDetails;
        payloadObj.conditionDetails = functionHeaderDetails.conditionDetails;
        payloadObj.calculationDetails = functionHeaderDetails.calculationDetails;
    } else if (componentType === TABLE_IO_TYPE.OUTPUT) {
        payloadObj.inputDetails = functionHeaderDetails.inputDetails;
        payloadObj.outputDetails = getIODetails(details);
        payloadObj.conditionDetails = functionHeaderDetails.conditionDetails;
        payloadObj.calculationDetails = functionHeaderDetails.calculationDetails;
    }
    return payloadObj;
};

export const getCheckBoxDisabled = (details: {}, componentType: string, hasConditions: boolean) => {
    let status = true;
    if (componentType === TABLE_IO_TYPE.INPUT) {
        Object.keys(details).forEach((inputKey) => {
            const obj = details[inputKey];
            if (!obj['isDelete']) {
                status = false;
            }
        });
    } else {
        if (hasConditions && Object.keys(details).length === 1) {
            return false;
        }
        Object.keys(details).forEach((outputKey) => {
            const obj = details[outputKey];
            if (outputKey !== 'severity' || !hasConditions) {
                if (!obj['isDelete'] && !obj['isDisabled']) {
                    status = false;
                }
            }
        });
    }
    return status;
};
export const getDisabledForButton = (details: {}) => {
    let status = true;
    Object.values(details).forEach((condition: any) => {
        if (condition.hasOwnProperty('deleteFlag') || condition.hasOwnProperty('isDelete')) {
            status = false;
        }
        // if (condition.hasOwnProperty('subConditions')) {
        //     Object.values(condition.subConditions).forEach((subCondition: any) => {
        //         if (subCondition.hasOwnProperty('deleteFlag')) {
        //             status = false;
        //         }
        //     });
        // }
    });
    return status;
};

export const getDisabledForChildren = (currentCondition: any) => {
    let status = true;
    const conditionKeys = Object.keys(currentCondition).filter((key) => key !== 'deleteFlag');
    if (conditionKeys.length > 0) {
        const statusArray: any = currentCondition[conditionKeys[0]];
        statusArray.forEach((condition: any) => {
            if (!condition.hasOwnProperty('deleteFlag')) {
                status = false;
            }
        });
    }
    return status;
};

export const convertToCalculationArray = (data: any) => {
    let calculationArray: any[] = [];
    if (data) {
        Object.values(data).forEach((item) => {
            calculationArray.push(item);
        });
    }
    return calculationArray;
};

export const arrangeTabNavList = (tabNavList: FunctionComponents[]) => {
    let updatedTabList: FunctionComponents[] = [];
    if (tabNavList.includes(FunctionComponents.INPUT)) {
        updatedTabList.push(FunctionComponents.INPUT);
    }
    if (tabNavList.includes(FunctionComponents.CONDITION)) {
        updatedTabList.push(FunctionComponents.CONDITION);
        if (tabNavList.includes(FunctionComponents.ALARM)) {
            updatedTabList.push(FunctionComponents.ALARM);
        }
    }
    if (tabNavList.includes(FunctionComponents.CALCULATION)) {
        updatedTabList.push(FunctionComponents.CALCULATION);
    }
    if (tabNavList.includes(FunctionComponents.OUTPUT)) {
        updatedTabList.push(FunctionComponents.OUTPUT);
    }

    return updatedTabList;
};

export function checkFunctionValidation(functionHeaderDetails: FunctionHeaderTypeDetails) {
    let returnFlag = true;
    const { conditionDetails, inputDetails, outputDetails, calculationDetails } =
        functionHeaderDetails;
    let errorMessages: string[] = [];
    let emptyErrors: boolean = false;
    let errorTitle: 'Function validation error';

    const calculatonData = convertToCalculationArray(calculationDetails);

    if (checkSeverityLogic(conditionDetails)['returnVal']) {
        const detail = `${checkSeverityLogic(conditionDetails)['fieldName']} cannot be empty ${checkSeverityLogic(conditionDetails)['name']
            }`;

        returnFlag = handleSubmitFlag(false, 'Condition', detail);
        fieldName.map((itemName) => {
            itemName.map((element: any) => {
                emptyErrors = true;
                errorMessages.push(
                    `${Object.values(element)} cannot be empty for  ${Object.keys(element)}`
                );
            });
        });
    }

    if (Object.keys(finalInputJSON(inputDetails)).length === 0) {
        returnFlag = handleSubmitFlag(false, 'Input', 'Input Function not found');
        if (returnFlag === false) {
            errorMessages.push('Input Function not found');
        }
    }

    if (Object.keys(finalInputJSON(outputDetails)).length === 0 && calculatonData.length === 0) {
        returnFlag = handleSubmitFlag(false, 'Output', 'Output Function not found');
        if (returnFlag === false) {
            errorMessages.push('Output Function not found');
        }
    }

    const inputTypeDetails = finalInputJSON(inputDetails);
    Object.keys(inputTypeDetails).some((val: string) => {
        if (val === '') {
            returnFlag = handleSubmitFlag(false, 'Input', 'Input variable name cannot be empty');
            if (returnFlag === false) {
                errorMessages.push('Input variable name cannot be empty');

                return true;
            }
        }
        if (inputTypeDetails[val]['numberError']) {
            returnFlag = false;
            errorMessages.push(inputTypeDetails[val]['numberError']['text']);

            return true;
        }
        return false;
    });

    Object.keys(finalInputJSON(outputDetails)).forEach((val: string) => {
        if (val === '') {
            console.log('inside val', val);
            returnFlag = handleSubmitFlag(false, 'Output', 'Output variable name cannot be empty');
            if (returnFlag === false) {
                console.log('returnFlag :', returnFlag);

                errorMessages.push('Output variable name cannot be empty');
            }
        }
    });

    calculatonData.forEach((item) => {
        const itemName: string = item.name;
        const logicValue: string = item.logic.value;

        if (itemName === '') {
            returnFlag = false;

            errorMessages.push('Calculation name Cannot be left empty');
        }
        if (logicValue === '') {
            returnFlag = false;
            errorMessages.push(`Logic cannot be empty for ${itemName}`);
            emptyErrors = true;
        }
    });

    return {
        isValid: returnFlag,
        errorMessages,
        emptyErrors,
    };
}

function handleSubmitFlag(val: boolean, fieldName: string, description: string) {
    let returnFlag: boolean = true;
    if (!val) {
        const title = `${fieldName} Validation Error`;
        const detail = `${description}`;
        const data = defaultErrorObj(title, detail);
        // handleResponse(data);
        // updateLoader(false);
        returnFlag = false;
    }
    return returnFlag;
}

export const validateName = (data: string, details: any) => {
    let isAvailable = true;
    details.forEach((item: any) => {
        if (item.name === data) {
            isAvailable = false;
        }
    });
    return isAvailable;
};

export const validateOutputForCondition = (value: string, details: any) => {
    let isAvailable = true;
    details.forEach((item: any) => {
        if (item === value) {
            isAvailable = false;
        }
    });
    return isAvailable;
};
export const getOutputCalculationDisabled = (
    tableType: string,
    condition: any,
    calculationObject: any
) => {
    const calculationKeys = Object.keys(calculationObject);
    if (tableType === TABLE_IO_TYPE.OUTPUT && calculationKeys.includes(condition.name)) {
        return true;
    }
    return false;
};

export const getNewUniqueName = (detailsObj: any, nameType: string, outputObj?: any) => {
    let name = '';
    let largest = 0;
    let outputArray: string[] = [];
    const countsArray: number[] = [];
    const detailsArray = Object.keys(detailsObj);
    if (outputObj) {
        outputArray = Object.keys(outputObj);
    }
    detailsArray.forEach((calculationKey: string) => {
        let keyCopy = _.cloneDeep(calculationKey);
        if (keyCopy.includes(nameType)) {
            let count = parseInt(keyCopy.replace(nameType, ''));
            if (count || count === 0) {
                countsArray.push(count);
            }
        }
    });
    if (countsArray.length !== 0) {
        largest = Math.max(...countsArray);
    }
    name = `${nameType}${largest + 1}`;
    while (outputArray && outputArray.includes(name)) {
        largest += 1;
        name = `${nameType}${largest + 1}`;
    }
    return name;
};

export const getDragTableData = (options: { sourceData: any }) => {
    const { sourceData } = options;
    let dragTableData: DragDropTableData['dragTableData'] = [];
    dragTableData.push({ label: 'condition', value: 0, dataType: 'string' });
    dragTableData.push({ label: 'subCondition', value: 1, dataType: 'string' });
    const dataItem = Object.keys(sourceData)[0];
    const subConditionKey = Object.keys(sourceData[dataItem]['subConditions'])[0];
    const data = _.cloneDeepWith(sourceData[dataItem]['subConditions'][subConditionKey]);
    delete data['name'];
    delete data['deleteFlag'];
    Object.keys(data)
        .filter((item) => item !== 'logic')
        .forEach((item: any, index: number) => {
            dragTableData.push({
                label: item,
                value: index + 2,
                dataType: data[item]['dataType'],
            });
        });
    return dragTableData;
};

export const getAlarmMapping = (
    selectedAlarmType: FunctionAlarmType,
    dropTableData: DropTableData[]
) => {
    const alarmName = selectedAlarmType.name;
    const alarmVersion = selectedAlarmType.version.split('.')[0];
    const alarmAttributes: AlarmMapping['']['attributes'] = {};
    dropTableData.forEach((item) => {
        alarmAttributes[item.label] = {
            dataType: item.dataType,
        };
        if (item.mappedAlarm) {
            if (item.mappedAlarm === 'condition') {
                alarmAttributes[item.label]['value'] = '#condition';
            } else {
                alarmAttributes[item.label]['value'] = `#condition/${item.mappedAlarm}`;
            }
        }
    });
    const alarmMapping: AlarmMapping = {};
    alarmMapping[alarmName] = {
        alarmType: {
            dataType: 'string',
            value: selectedAlarmType.typeId + '@' + alarmVersion,
        },
        attributes: alarmAttributes,
    };
    return alarmMapping;
};

export const getUpdatedDropTableData = (alarmType: FunctionAlarmType[]) => {
    const updatedDropTableData: DropTableData[] = Object.keys(alarmType[0].properties).map(
        (item, index) => {
            return {
                label: item,
                value: index,
                dataType: alarmType[0].properties[item].dataType,
            };
        }
    );
    return updatedDropTableData;
};

export const updateAlarmMappingTableData = (options: {
    dragDropTableData: DragDropTableData;
    alarmMapping: AlarmMapping;
}) => {
    const { dragDropTableData, alarmMapping } = options;
    const { dragTableData, dropTableData } = dragDropTableData;

    const selectedAlarmName = Object.keys(alarmMapping)[0];
    const selectedAlarm = alarmMapping[selectedAlarmName];
    const attributes = selectedAlarm['attributes'];
    const dropTableDataWithouMapping = removeDropTableDataMapping(dropTableData);
    const dropTableDataValue = dropTableDataWithouMapping;
    let dragTableDataValue = _.cloneDeepWith(dragTableData);

    Object.keys(attributes).forEach((attribute) => {
        if (_.has(attributes[attribute], 'value')) {
            const link = attributes[attribute].value;
            let mappedItemName = '';
            if (link) {
                let pathString = link.split('/');
                pathString = pathString[pathString.length - 1].split('#');
                mappedItemName = pathString[pathString.length - 1];
            }

            const dropTableDataItem = dropTableData.find((item) => item.label === attribute);

            const dragTableDataItem = dragTableData.find((item) => item.label === mappedItemName);
            if (dragTableDataItem) {
                dragTableDataValue[dragTableDataItem['value']]['label'] = '';
            }

            if (dropTableDataItem) {
                dropTableDataValue[dropTableDataItem['value']]['mappedAlarm'] = mappedItemName;
            }
        }
    });
    return {
        dragTableData: dragTableDataValue,
        dropTableData: dropTableDataValue,
    };
};

export const removeDropTableDataMapping = (dropTableData: DropTableData[]) => {
    dropTableData.forEach((item) => {
        if (item.mappedAlarm) {
            delete item['mappedAlarm'];
        }
    });

    return dropTableData;
};

export const computeToHandleError = (typeFunction: any) => {
    let handleError = false;
    typeFunction.forEach((ioObj: any) => {
        if (ioObj.hasOwnProperty('error')) {
            handleError = true;
        }
    });
    return handleError;
};

export const search = (typeFunctionArray: any, fncName: string) => {
    let isPresent = false;
    typeFunctionArray.forEach((fnc: any) => {
        if (!fnc.hasOwnProperty('error') && fnc.name === fncName) {
            isPresent = true;
        }
    });
    return isPresent;
};

export const checkForFunctionDuplicateTypeIdAndName = (
    typeId: string,
    name: string,
    functionList: UpdateActiveFunctionType[]
) => {
    let isTypeIdExist = false;
    let isNameExist = false;
    functionList.forEach((item) => {
        if (
            item.typeId === `abb.local.${typeId}.TenantId.${AbilityService.tenantId}`
            // checkDuplicateTypeId({ typeId: typeId, originalTypeId: item.id.value })
        ) {
            isTypeIdExist = true;
            // if (item.name.value === name) {
            //     isNameExist = true;
            // }
        }
        if (item.name === name) {
            isNameExist = true;
        }
    });

    return { isTypeIdExist, isNameExist };
};

export const checkDuplicateTypeId = (options: { typeId: string; originalTypeId: string }) => {
    const { typeId, originalTypeId } = options;
    let isTypeIdExist = false;

    let lTypeId = typeId.toLowerCase();
    let lOriginalTypeId = originalTypeId.toLowerCase();
    if (lOriginalTypeId === lTypeId) {
        isTypeIdExist = true;
    }
    if (lOriginalTypeId.includes('.tenantid.')) {
        isTypeIdExist =
            lOriginalTypeId === `${lTypeId}.TenantId.${AbilityService.tenantId}`.toLowerCase();
    }
    if (lOriginalTypeId.includes('abb.local.')) {
        isTypeIdExist =
            lOriginalTypeId ===
            `abb.local.${lTypeId}.TenantId.${AbilityService.tenantId}`.toLowerCase();
    }

    return isTypeIdExist;
};

export const checkForChangeInHeaderDetails = (
    functionHeaderDetails: FunctionHeaderTypeDetails,
    typeDetails: CreateFunctionAssetDetails,
    alarmDetails: AlarmMapping,
    assetsInfo: AssetsInfo
) => {
    let versionChange: VersionUpdateType = VERSION_UPDATE_TYPE.DEFAULT;

    const incomingInputDetails = Object.entries({
        ...functionHeaderDetails.inputDetails,
    }).filter(
        (item) =>
            item[0] !== 'conditions' && item[0] !== 'calculations' && item[0] !== 'alarmMapping'
    );
    const oldInputDetails = Object.entries({
        ...typeDetails.properties.inputs,
    }).filter(
        (item) =>
            item[0] !== 'conditions' && item[0] !== 'calculations' && item[0] !== 'alarmMapping'
    );
    let incomingOutputDetails = Object.entries({
        ...functionHeaderDetails.outputDetails,
    })
        .filter((item) => item[0] !== 'conditions')
        .sort(function (a: any, b: any) {
            return a[0].localeCompare(b[0]);
        });

    let oldOutputDetails = Object.entries({
        ...typeDetails.properties.outputs,
    })
        .filter((item) => item[0] !== 'conditions')
        .sort(function (a: any, b: any) {
            return a[0].localeCompare(b[0]);
        });

    let incomingConditionDetails = {
        ...functionHeaderDetails.conditionDetails,
    };
    incomingConditionDetails = computefinalConditionJSON(incomingConditionDetails);
    const oldConditionDetails = { ...typeDetails.properties.inputs.conditions };

    const incomingCalculationDetails = Object.entries({
        ...functionHeaderDetails.calculationDetails,
    });
    const oldCalculationsDetails = Object.entries({
        ...typeDetails.properties.inputs.calculations,
    });

    const incomingAlarmMapping = Object.entries({ ...alarmDetails });
    const oldAlarmMapping = Object.entries({
        ...typeDetails.properties.inputs.alarmMapping,
    });

    const { description: incomingDescription, name: incomingName } = assetsInfo;
    const { description: oldDescription, name: oldName } = typeDetails;

    oldOutputDetails = oldOutputDetails.filter((output: any) => {
        let itemPresent = false;
        oldCalculationsDetails.forEach((calculation: any) => {
            if (calculation[0] === output[0]) {
                itemPresent = true;
            }
        });
        if (itemPresent === false) {
            return output;
        }
    });
    if (typeDetails.properties.inputs.calculations) {
        incomingOutputDetails = incomingOutputDetails.filter((output: any) => {
            let itemPresent = false;
            incomingCalculationDetails.forEach((calculation: any) => {
                if (calculation[0] === output[0]) {
                    itemPresent = true;
                }
            });

            if (!itemPresent) {
                return output;
            }
        });
    }

    const modifiedArrayWithoutDesc = (arr: any) => {
        return arr.map((subArray: any) =>
            // @ts-ignore
            subArray.map(({ description, name, isDelete, ...rest }) => rest)
        );
    };

    const getDesc = (a: any) => {
        return a.map((element: any) => {
            if (element[1].hasOwnProperty('description')) {
                return element[1]['description'];
            } else return '';
        });
    };

    const getInputsName = (a: any) => {
        return a.map((element: any) => {
            if (element[0]) {
                return element[0];
            } else return '';
        });
    };

    const getVariableType = (a: any) => {
        return a.map((element: any) => {
            if (element[1].hasOwnProperty('dataType')) {
                return element[1]['dataType'];
            } else return '';
        });
    };

    const getInputValue = (a: any) => {
        return a.map((element: any) => {
            if (element[1].hasOwnProperty('value')) {
                return element[1]['value'];
            } else return '';
        });
    };

    const modifiedIncomingInputDetails = modifiedArrayWithoutDesc(incomingInputDetails);
    const modifiedOldInputDetails = modifiedArrayWithoutDesc(oldInputDetails);
    const modifiedIncomingOutputDetails = modifiedArrayWithoutDesc(incomingOutputDetails);
    const modifiedOldOutputDetails = modifiedArrayWithoutDesc(oldOutputDetails);
    const modifiedIncomingAlarmDetails = modifiedArrayWithoutDesc(incomingAlarmMapping);
    const modifiedOldAlarmDetails = modifiedArrayWithoutDesc(oldAlarmMapping);
    debugger;
    if (
        !_.isEqual(modifiedIncomingInputDetails, modifiedOldInputDetails) ||
        !_.isEqual(modifiedIncomingOutputDetails, modifiedOldOutputDetails) ||
        !_.isEqual(modifiedIncomingAlarmDetails, modifiedOldAlarmDetails) ||
        !_.isEqual(incomingName, oldName)
    ) {
        if (!_.isEqual(getVariableType(incomingInputDetails), getVariableType(oldInputDetails))) {
            versionChange = VERSION_UPDATE_TYPE.MAJOR;
            return versionChange;
        } else if (!_.isEqual(getInputValue(incomingInputDetails), getInputValue(oldInputDetails))) {
            //refer discussion in Bug 125891: SCE: Update of function in asset model does not update values
            versionChange = VERSION_UPDATE_TYPE.MAJOR;
            return versionChange;
        } else {
            versionChange = VERSION_UPDATE_TYPE.MAJOR;
            return versionChange;
        }
    }

    if (
        !_.isEqual(getDesc(incomingInputDetails), getDesc(oldInputDetails)) ||
        !_.isEqual(getDesc(incomingOutputDetails), getDesc(oldOutputDetails)) ||
        !_.isEqual(getDesc(incomingAlarmMapping), getDesc(oldAlarmMapping))
    ) {
        versionChange = VERSION_UPDATE_TYPE.MINOR;
    }

    if (!_.isEqual(incomingDescription, oldDescription)) {
        versionChange = VERSION_UPDATE_TYPE.PATCH;
    }

    if (JSON.stringify(incomingConditionDetails) !== JSON.stringify(oldConditionDetails)) {
        if (versionChange === VERSION_UPDATE_TYPE.DEFAULT) {
            versionChange = VERSION_UPDATE_TYPE.PATCH;
        }
    }
    console.log(incomingConditionDetails, oldConditionDetails);

    if (!_.isEqual(incomingConditionDetails, oldConditionDetails)) {
        const updatedIncomingConditionDetails = Object.entries({
            ...incomingConditionDetails,
        });
        const updatedOldConditionDetails = Object.entries({
            ...oldConditionDetails,
        });
        if (updatedIncomingConditionDetails.length !== updatedOldConditionDetails.length) {
            versionChange = VERSION_UPDATE_TYPE.MAJOR;
            return versionChange;
        } else {
            updatedIncomingConditionDetails.forEach((item: any) => {
                const condition = item[0];
                console.log(condition);
                const isPresentInOldCondition = _.findIndex(
                    updatedOldConditionDetails,
                    (oldCondition: any) => oldCondition[0] === condition
                );
                if (isPresentInOldCondition === -1) {
                    versionChange = VERSION_UPDATE_TYPE.MAJOR;
                    return;
                } else {
                    if (
                        Object.entries(item[1].subConditions).length !==
                        Object.entries(
                            (updatedOldConditionDetails[isPresentInOldCondition][1] as any)
                                .subConditions
                        ).length
                    ) {
                        versionChange = VERSION_UPDATE_TYPE.MAJOR;
                        return;
                    } else if (
                        JSON.stringify(item[1]) !==
                        JSON.stringify(updatedOldConditionDetails[isPresentInOldCondition][1])
                    ) {
                        const incomingSubConditions = Object.entries(item[1].subConditions);
                        const oldSubConditions: any = Object.entries(
                            (updatedOldConditionDetails[isPresentInOldCondition][1] as any)
                                .subConditions
                        );
                        let majorFlag = false;
                        let patchFlag = false;

                        incomingSubConditions.forEach((subCondition: any) => {
                            const isPresentInOldSubCondition = _.findIndex(
                                oldSubConditions,
                                (oldCondition: any) => oldCondition[0] === subCondition[0]
                            );

                            if (isPresentInOldSubCondition == -1) {
                                majorFlag = true;
                            } else {
                                if (
                                    subCondition[1].description.value !==
                                    oldSubConditions[isPresentInOldCondition][1].description.value
                                ) {
                                    patchFlag = false;
                                }
                            }
                        });
                        if (
                            !_.isEqual(
                                modifiedArrayWithoutDesc(incomingSubConditions),
                                modifiedArrayWithoutDesc(oldSubConditions)
                            )
                        ) {
                            patchFlag = false;
                        }
                        if (majorFlag) {
                            versionChange = VERSION_UPDATE_TYPE.MAJOR;
                            return;
                        } else {
                            versionChange = patchFlag
                                ? VERSION_UPDATE_TYPE.PATCH
                                : VERSION_UPDATE_TYPE.MINOR;
                        }
                    }
                }
            });
        }
    }
    debugger;
    if (!_.isEqual(incomingCalculationDetails, oldCalculationsDetails)) {
        let isMajorFlag = false;
        let patchFlag = false;
        if (incomingCalculationDetails.length !== oldCalculationsDetails.length) {
            isMajorFlag = true;
        } else {
            incomingCalculationDetails.forEach((calculation: any) => {
                let calculationPresent = false;
                oldCalculationsDetails.forEach((oldCalculation: any, index: number) => {
                    if (oldCalculation[0] === calculation[0]) {
                        calculationPresent = true;
                        if (
                            calculation[1]['outputType']['dataType'] !==
                            oldCalculation[1]['outputType']['dataType']
                        ) {
                            isMajorFlag = true;
                        } else {
                            if (
                                calculation[1].description.value !==
                                oldCalculation[1].description.value
                            ) {
                                patchFlag = false;
                            }
                        }
                    }
                });
                if (!calculationPresent) {
                    isMajorFlag = true;
                }
            });
            if (
                !_.isEqual(
                    modifiedArrayWithoutDesc(incomingCalculationDetails),
                    modifiedArrayWithoutDesc(oldCalculationsDetails)
                )
            ) {
                patchFlag = false;
            }
        }

        if (isMajorFlag) {
            versionChange = VERSION_UPDATE_TYPE.MAJOR;
        } else {
            versionChange = patchFlag ? VERSION_UPDATE_TYPE.PATCH : VERSION_UPDATE_TYPE.MINOR;
        }
    }
    return versionChange;
};

export const checkForValidLogicExpression = (expression: string) => {
    let isExpressionValid = true;

    // check there is one operator between two operands.
    let operators = expression.split(/\s?\$\'[A-Za-z0-9]+\'\s?/).slice(1, -1);

    operators.forEach((operator) => {
        if (operator === '') {
            isExpressionValid = false;
        }
    });

    return isExpressionValid;
};

export const getDataForStatusKey = (dataSet: string[]) => {
    let statusDataSet: DropDownItem[] = [];
    statusDataSet = dataSet.map((item) => {
        let obj = {} as DropDownItem;
        obj['value'] = item;
        obj['label'] = item;
        return obj;
    });
    return statusDataSet;
};

export const addPropertiesToConditionIfNotPresent = (
    conditions: any,
    properties: string[],
    addIsNew: boolean
) => {
    let updatedConditions = _.cloneDeepWith(conditions);
    Object.keys(updatedConditions).forEach((item) => {
        let subConditions = updatedConditions[item]['subConditions'];
        Object.keys(subConditions).forEach((subCondition) => {
            let subConditionDetail = updatedConditions[item]['subConditions'][subCondition];
            properties.forEach((property: string) => {
                if (!subConditionDetail.hasOwnProperty(property)) {
                    if (addIsNew) {
                        updatedConditions[item]['subConditions'][subCondition] = {
                            ...subConditionDetail,
                            [property]: { dataType: 'string', isNew: true },
                        };
                    } else {
                        updatedConditions[item]['subConditions'][subCondition] = {
                            ...subConditionDetail,
                            [property]: { dataType: 'string' },
                        };
                    }
                }
            });
        });
    });
    return updatedConditions;
};

const getLogicVariables = (logic: any) => {
    const variableRegex = /\$\'[A-Za-z_][A-Za-z_0-9]+\'+((\[([0-9]+)\])*)?/g;
    const logicVariablesArrayObject = [...logic.matchAll(variableRegex)];
    let logicVariables: string[] = [];
    const smallerRegEx = /'[A-Za-z_][A-Za-z_0-9]+\'/;
    logicVariablesArrayObject.forEach((logicDetail: any) => {
        let list = logicDetail[0].match(smallerRegEx)[0];
        list = list.replace(/[']/g, '');
        if (list !== '' && logicVariables.indexOf(list) === -1) {
            logicVariables.push(list);
        }
    });
    return logicVariables;
};

const checkWhetherLogicInputsAreValid = (logic: string, inputKeys: string[]) => {
    const logicVariables = getLogicVariables(logic);
    let isValid = true;
    let errorMessages: string[] = [];
    let invalidInputs: string[] = [];
    logicVariables.forEach((item) => {
        if (inputKeys.indexOf(item) === -1) {
            isValid = false;
            errorMessages.push(`${item} is not present in inputs`);
            invalidInputs.push(item);
        }
    });
    return {
        isValid: isValid,
        errorMessages: errorMessages,
        invalidInputs: invalidInputs,
    };
};

export const validateConditions = (conditions: any, inputKeys: string[]) => {
    let isLogicValid = true;
    let overallMessagesErrorMessages: string[] = [];
    let overallInvalidInputs: string[] = [];
    Object.keys(conditions).forEach((conditionName) => {
        const subConditions = conditions[conditionName]['subConditions'];
        if (subConditions && Object.keys(subConditions).length) {
            Object.keys(subConditions).forEach((subConditionName) => {
                const logic = subConditions[subConditionName]['logic']['value'];
                const { isValid, errorMessages, invalidInputs } = checkWhetherLogicInputsAreValid(
                    logic,
                    inputKeys
                );
                if (!isValid) {
                    isLogicValid = false;
                    overallMessagesErrorMessages.push(
                        `Error in condition ${conditionName} and subCondition ${subConditionName}`,
                        ...errorMessages
                    );
                    overallInvalidInputs.push(...invalidInputs);
                }
            });
        }
    });
    return {
        isLogicValid,
        overallMessagesErrorMessages,
        overallInvalidInputs,
    };
};

export const validateCalculations = (calculations: any, inputKeys: string[]) => {
    let isLogicValid = true;
    let overallMessagesErrorMessages: string[] = [];
    let overallInvalidInputs: string[] = [];
    Object.keys(calculations).forEach((calculationName) => {
        const logic = calculations[calculationName]['logic']['value'];
        const { isValid, errorMessages, invalidInputs } = checkWhetherLogicInputsAreValid(
            logic,
            inputKeys
        );
        if (!isValid) {
            isLogicValid = false;
            overallMessagesErrorMessages.push(
                `Error in calculation ${calculationName}`,
                ...errorMessages
            );
            overallInvalidInputs.push(...invalidInputs);
        }
    });
    return {
        isLogicValid,
        overallMessagesErrorMessages,
        overallInvalidInputs,
    };
};

export const getInputKeysSet = (typeFunction: []) => {
    let inputKeysToNotDelete: string[] = [];
    let inputKeysToDelete: string[] = [];
    typeFunction.forEach(
        (item: { name: string; isMandatory: boolean; dataType: string; isDelete?: boolean }) => {
            if (!(item.hasOwnProperty('isDelete') && item['isDelete'] === true)) {
                inputKeysToNotDelete.push(item.name);
            } else {
                inputKeysToDelete.push(item.name);
            }
        }
    );
    return {
        inputKeysToNotDelete,
        inputKeysToDelete,
    };
};

export const inValidAndDeletingInputsMatch = (
    inputKeysToDelete: string[],
    overallInvalidInputs: string[]
) => {
    let valid = false;
    inputKeysToDelete.forEach((item) => {
        if (overallInvalidInputs.indexOf(item) !== -1) {
            valid = true;
        }
    });
    return valid;
};

export const checkIsFunctionUpdatesAvailable = (json: LocalJson) => {
    let isUpdateAvailable = false;
    json.assetData.some((asset) => {
        if (asset instanceof FunctionTypeDetail && asset.isUpdateAvailable) {
            isUpdateAvailable = true;
            return true;
        }
        return false;
    });
    return isUpdateAvailable;
};

export const isPresentInFunctions = (id: string, nodeList: UpdateActiveFunctionType[]) => {
    let isPresent = false;
    nodeList.forEach((functionDetail) => {
        if (functionDetail.typeId === id) {
            isPresent = true;
        }
    });
    return isPresent;
};

export const generateAndSaveZipFileToSystem = (res: any, fileName: string) => {
    var zip = new JSZip();
    // let fileName =
    //     res.details.length > 1
    //         ? LIBRARIES
    //         : res.details[0].library
    //             ? res.details[0].library.name
    //             : GENERIC_FUNCTIONS;
    res.details.forEach((libraryDetail: any) => {
        let libName = libraryDetail.library 
                            ? libraryDetail.library.name
                            : (
                                libraryDetail.functionTypes 
                                && libraryDetail.functionTypes.length === 1
                                ? `${libraryDetail.functionTypes[0].name}_${libraryDetail.functionTypes[0].version}` 
                                : GENERIC_FUNCTIONS
                            );
        zip.file(libName + '.json', JSON.stringify(libraryDetail));
    });

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
        FileSaver.saveAs(content, `${fileName}.zip`);
    });
};

export const functionBelongsToLibrary = (
    selectedFunc: UpdateActiveFunctionType,
    libraryToExport: LibraryAndNodePayload[]
) => {
    let isPartOfLib = false;
    libraryToExport.forEach((library) => {
        if (
            library.id !== null &&
            library.root !== null &&
            isPresentInFunctions(selectedFunc.typeId, library.nodes)
        ) {
            isPartOfLib = true;
        }
    });
    return isPartOfLib;
};

function initialConditionObj(conditionObj: any) {
    let newCalcObj = { ...conditionObj };

    Object.keys(newCalcObj).forEach((key) => {
        newCalcObj[key]['name'] = key;
    });
    return newCalcObj;
}

export const getOptionsForUpdatingFunctions = (
    typeDetails: CreateFunctionAssetDetails,
    dropTableDataFromState: DropTableData[]
) => {
    let dragDropTableData: DragDropTableData = {
        dragTableData: [],
        dropTableData: [...dropTableDataFromState],
    };
    const inputDetails = initalInput(typeDetails.properties.inputs);
    const outputDetails = initalInput(typeDetails.properties.outputs);
    const assetsDetails: CreateFunctionAssetDetails = {
        ...typeDetails,
    };
    let conditionObj: any = {};
    let calculationObj: any = {};

    if (_.has(typeDetails.properties, 'inputs.conditions')) {
        conditionObj = typeDetails.properties.inputs.conditions;
        conditionObj = addPropertiesToConditionIfNotPresent(conditionObj, ['status'], false);
        delete conditionObj['isMandatory'];
        typeDetails.properties.inputs.conditions = conditionObj;
    }
    const conditionDetails = conditionObj;

    if (_.has(typeDetails.properties, 'inputs.calculations')) {
        calculationObj = initialConditionObj(typeDetails.properties.inputs.calculations);
    }

    const functionHeaderDetails = {
        inputDetails,
        outputDetails,
        conditionDetails,
        calculationDetails: calculationObj,
    };

    let alarmMapping: AlarmMapping = {};

    if (_.has(typeDetails.properties, 'inputs.alarmMapping')) {
        const dragTableData: DragDropTableData['dragTableData'] = getDragTableData({
            sourceData: functionHeaderDetails.conditionDetails,
        });
        const dropTableData: DragDropTableData['dropTableData'] = dropTableDataFromState;

        if (Object.keys(typeDetails.properties.inputs.alarmMapping).length > 0) {
            alarmMapping = typeDetails.properties.inputs.alarmMapping;
            dragDropTableData = updateAlarmMappingTableData({
                dragDropTableData: { dragTableData, dropTableData },
                alarmMapping,
            });
        } else {
            dropTableData.forEach((item) => {
                if (item.mappedAlarm) {
                    delete item['mappedAlarm'];
                }
            });
        }
    }

    let assetsInfo = {} as AssetsInfo;
    if (typeDetails) {
        const endpointValue = typeDetails.properties.settings.endpoint.value;
        assetsInfo = {
            typeId: typeDetails.typeId,
            version: typeDetails.version,
            name: typeDetails.name,
            description: typeDetails.description,
            tags: typeDetails.tags,
            endpoint: endpointValue ? endpointValue : '',
        };
    }
    return {
        assetsInfo,
        functionHeaderDetails,
        currentActiveView: { assetsDetails: assetsDetails },
        alarmMapping,
        dragDropTableData,
    };
};

export const findTypeDetailsFromLibrariesAndFunctionsList = (
    libraryAndFunctionsList: LibraryAndNodePayload[],
    typeDetails: CreateFunctionAssetDetails | AssetsInfo
) => {
    let typeDetailsOfActiveFunction: UpdateActiveFunctionType = {
        description: '',
        model: '',
        name: '',
        tags: [],
        typeId: '',
        version: '',
    };

    let libraryDetailsOfCurrentFunction: LibraryAndNodePayloadWithoutNodes = {
        root: '',
        id: '',
        libraryVersion: '',
        isIPProtected: false,
        password: '',
    };

    libraryAndFunctionsList.forEach((libraryDetails) => {
        libraryDetails.nodes.forEach((nodeDetail) => {
            if (nodeDetail.name === typeDetails.name && nodeDetail.typeId === typeDetails.typeId && nodeDetail.version === typeDetails.version) {
                typeDetailsOfActiveFunction = nodeDetail;
                libraryDetailsOfCurrentFunction = {
                    root: libraryDetails.root,
                    id: libraryDetails.id,
                    libraryVersion: libraryDetails.libraryVersion,
                    isIPProtected: libraryDetails.isIPProtected,
                    password: '',
                };
            }
        });
    });

    return {
        typeDetails: typeDetailsOfActiveFunction,
        libraryDetailsOfCurrentFunction,
    };
};

export const optionToViewDecryptedFunction = (
    libraryAndFunctionsList: LibraryAndNodePayload[],
    assetsDetails?: CreateFunctionAssetDetails | undefined
) => {
    let viewDecrypted = false;
    if (assetsDetails) {
        const { libraryDetailsOfCurrentFunction } = findTypeDetailsFromLibrariesAndFunctionsList(
            libraryAndFunctionsList,
            assetsDetails
        );

        if (libraryDetailsOfCurrentFunction.isIPProtected) {
            viewDecrypted = true;
        }
    }

    return viewDecrypted;
};

export const checkForEquality = (
    updatedMutuallyExclusiveFunctionTypes: UpdateActiveFunctionType[],
    functionList: FunctionDetailsRequiredByLibrary[]
) => {
    let isEqual = true;
    if (
        updatedMutuallyExclusiveFunctionTypes.length !== functionList.length &&
        updatedMutuallyExclusiveFunctionTypes.length > functionList.length
    ) {
        isEqual = false;
    } else {
        updatedMutuallyExclusiveFunctionTypes.forEach((functionDetail) => {
            let isPresent = false;
            functionList.forEach((ele) => {
                if (ele.typeId === functionDetail.typeId) {
                    isPresent = true;
                }
            });
            if (!isPresent) {
                isEqual = false;
            }
        });
    }
    return isEqual;
};

export const removeSeverityFromOutputs = (outputDetails: any) => {
    if (outputDetails['severity']) {
        delete outputDetails['severity'];
    }
    return outputDetails;
};

export const createRandomString = () => {
    var chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
    var random = _.sampleSize(chars, 12);
    return random.join('');
};
