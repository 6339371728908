export interface DropdownInterface {
    value: any;
    label: string;
    isNew: boolean;
}

export const dropdownConstants = [
    {
        value: "Condition",
        label: "Alarm Condition",
        isNew: false,
    },
    {
        value: "Process",
        label: "Alarm Process",
        isNew: false,
    },
];

export const ALARM_ATTRIBUTES = "ALARM ATTRIBUTES";
export const CONDITION_ATTRIBUTES = "CONDITION ATTRIBUTES";
export const AVAILABLE_CONDITION_ATTRIBUTES = "AVAILABLE CONDITION ATTRIBUTES";
export const UNMAPPED_CONDITION_ATTRIBUTES = "UNMAPPED CONDITION ATTRIBUTES";

export const ALARM_TABLE_DRAGGABLE_ITEMS = {
    ALARM_DRAGGABLE: "DRAG_DROP_ALARM_DRAG_TABLE_ROW",
    ALARM_DROPPABLE: "DROP_DRAG_ALARM_DRAG_TABLE_ROW",
};

export const dropTableConstants = [
    {
        value: 0,
        label: "Conditions",
    },
    {
        value: 1,
        label: "Sub condition",
    },
    {
        value: 2,
        label: "Message",
    },
    {
        value: 3,
        label: "Severity",
    },
    {
        value: 4,
        label: "Possible cause",
    },
    {
        value: 5,
        label: "Suggested action",
    },
    {
        value: 6,
        label: "Corrective action",
    },
];

export const dragTableConstants = [
    {
        label: "Sub condition",
        value: 0,
    },
    {
        label: "Description",
        value: 1,
    },
    {
        label: "Severity",
        value: 2,
    },
    {
        label: "Device Measurement Value",
        value: 3,
    },
    {
        label: "Suggested action",
        value: 4,
    },
    {
        label: "Corrective action",
        value: 5,
    },
    {
        label: "Condition",
        value: 6,
    },
];
