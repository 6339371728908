import React, { Fragment, useState, useCallback, useEffect } from "react";
import TR from "../../../../Table/TableRow";
import { Collapse } from "reactstrap";
import { IoTableTabsProps } from "../../..";
import {
    FunctionTypeDetailsWithMappings,
    TableDropDownData,
} from "../../../../../model/TableController/types";
import Td from "../../../../Table/TableData";
import Condition from "../../../../shared/Condition";
import _ from "lodash";
import { ToggleSwitch, Icon, Input } from "@abb/abb-common-ux-react";
import Checkbox from "../../../../CustomCheckbox";
import ConnectionDropdown from "../../ConnectionDropdown";
import { defaultValueValidation } from "../../../../../transformers/ComputeModel/validation/defaultValue";
import { IODataTypes } from "../../../../Fabric/types";
import { useDrop } from "react-dnd";
import {
    DRAGGABLE_ITEMS,
    ROUTE_PATHNAME,
} from "../../../../../utils/constants/appConstants";
import "../style.scss";
import {
    handletableDropDownData,
    checkFunctionIdDuplication,
} from "../../../../../utils/helpers";
import FunctionTypeDetail from "../../../../../transformers/AssetType/FunctionType";
import { VALIDATION_MESSAGE } from "../../../../../utils/constants/uiConstants";
import { validateForSpecialCharacters_Including_HyphenAndUnderscore } from "../../../../../routes/Functions/helper";
import CollapseTitle from "../../../../CollapseTitle";
import CustomABBInput from "../../../../CustomABBInput";

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

const ExpandableInputRows = (props: ExpandTableRowProps & IoTableTabsProps) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    const rows = [...props.asset.inputs, ...props.asset.inhibits];

    const rowCount = rows.length;

    const rowArray = isExpanded ? rows : [rows[0]];

    const defaultValueValidator = useCallback(
        (defaultValue: string, dataType: string) => {
            if (defaultValue) {
                return defaultValueValidation({
                    dataType: dataType as IODataTypes,
                    value: defaultValue,
                });
            } else {
                return { isInvalid: false, errorMessage: "" };
            }
        },
        []
    );

    return (
        <Fragment>
            {rowArray.length === 0 ? (
                <TR>Empty</TR>
            ) : (
                <>
                    <CollapseTitle title={props.asset.nodeId} isCollapse={isExpanded} toggleCollapse={toggleExpand} />
                    <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                        {rowArray.map((input, index) => {
                            const defaultValueValidations =
                                defaultValueValidator(
                                    input.defaultValue || "",
                                    input.dataType
                                );
                            return (
                                <InputRow
                                    key={input.id}
                                    {...props}
                                    asset={props.asset}
                                    defaultValueValidations={
                                        defaultValueValidations
                                    }
                                    index={index}
                                    input={input}
                                    isExpanded={isExpanded}
                                    rowCount={rowCount}
                                    toggleExpand={toggleExpand}
                                />
                            );
                        })}
                    </Collapse>
                </>
            )}
        </Fragment>
    );
};

const InputRow = (
    props: {
        index: number;
        rowCount: number;
        isExpanded: boolean;
        input: FunctionTypeDetailsWithMappings["inputs"][0];
        asset: FunctionTypeDetailsWithMappings;
        toggleExpand: () => void;
        defaultValueValidations: { isInvalid: boolean; errorMessage: string };
    } & IoTableTabsProps
) => {
    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEMS.TABLE_VIEW.ioVariables.src,
        drop: (data: any, monitor) => {
            props.addConnectionConfigurationTool({
                outputAsset: data.data.outputAsset,
                outputCircleData: data.data.circleData,
                inputAsset: props.asset,
                inputCircleData: props.input,
            });
        },
    });

    let initialNodeId = props.asset.nodeId;

    const [nodeId, updateNodeId] = useState(props.asset.nodeId);
    const [defaultValue, updateDefaultValue] = useState(
        props.input.defaultValue || ""
    );
    const [switchState, updateSwitchState] = useState(
        props.input.defaultValue ? true : undefined
    );
    const [dropDownOptionsData, setDropDownOptionsData] = useState(
        [] as TableDropDownData[]
    );
    const [errorFuncName, updateErrorFuncName] = useState("");
    const [isFunctionValid, updateIsFunctionValid] = useState(true);
    const [selectedDropDownOptionsIndex, setSelectedDropDownOptionsIndex] =
        useState([] as number[]);

    useEffect(() => {
        if (props.input.mappingDetails.length > 0) {
            updateSwitchState(undefined);
        }
        if (
            props.tableData.objectTypeDetails &&
            props.input &&
            props.tableData.functionTypeDetails
        ) {
            const { dropDownOptions, selectedDropDownItemIndex } =
                handletableDropDownData({
                    tableData: props.tableData,
                    input: props.input,
                });

            setDropDownOptionsData(dropDownOptions);
            setSelectedDropDownOptionsIndex(selectedDropDownItemIndex);
        }
    }, [props.input.mappingDetails.length, props.tableData]);

    const {
        index,
        rowCount,
        isExpanded,
        input,
        asset,
        toggleExpand,
        defaultValueValidations,
        inViewMode,
    } = props;

    const handleTriggerChange = useCallback(() => {
        if (
            asset.nodeId !== props.overallSeverityFunctionId ||
            (asset.nodeId === props.overallSeverityFunctionId &&
                asset.inputs[0].id !== input.id)
        ) {
            props.triggerConnectionConfigurationTool({
                asset,
                circleData: input,
                trigger: !input.trigger,
            });
        }
    }, [input.trigger]);

    const handleSwitchToggle = useCallback(
        (newState) => {
            if (newState === true && input.mappingDetails.length > 0) {
                props.removeConnectionConfigurationTool({
                    inputAsset: asset,
                    removeAll: true,
                    inputCircleData: input,
                });
            } else if (
                newState === false &&
                (input.defaultValue || defaultValue)
            ) {
                updateDefaultValue("");
                props.addDefaultValueConfigurationTool({
                    circleData: input,
                    asset,
                    defaultValue: "",
                });
            }

            updateSwitchState(newState);
        },
        [input.mappingDetails.length, input.defaultValue, input, defaultValue]
    );

    const handleDefaultValuefocusOut = useCallback(() => {
        props.addDefaultValueConfigurationTool({
            circleData: input,
            asset,
            defaultValue: defaultValue,
        });
    }, [defaultValue, input]);

    let debounceTimeChange: NodeJS.Timeout;

    const onFunctionNameFocusOut = useCallback(() => {
        //updateNodeId(value);
        if (isFunctionValid) {
            props.editNodeIdOfFunction({
                newName: nodeId,
                functionType: props.asset,
            });
        }
    }, [nodeId, isFunctionValid]);

    const onValueChange = (value: string) => {
        updateNodeId(value);
        validateNodeId(value);
    };

    // useEffect(() => {
    //     if (debounceTimeChange) {
    //         clearTimeout(debounceTimeChange);
    //     }
    //     debounceTimeChange = setTimeout(() => {
    //         onDebounceNodeIdChange(nodeId);
    //     }, 500);
    // }, [nodeId]);

    const validateNodeId = useCallback(
        (value: string) => {
            const oldFunctionList = props.modelInstance.json.assetData.filter(
                (item) => item instanceof FunctionTypeDetail
            );
            const currentFunctionList = props.currentJson.assetData.filter(
                (item) => item instanceof FunctionTypeDetail
            );
            const isValid =
                value === initialNodeId ||
                (checkFunctionIdDuplication({
                    id: value,
                    assetData: currentFunctionList,
                }) &&
                    checkFunctionIdDuplication({
                        id: value,
                        assetData: oldFunctionList,
                    }) &&
                    value !== props.asset.assetName);

            if (isValid) {
                if (
                    !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                        value
                    )
                ) {
                    updateIsFunctionValid(false);
                    updateErrorFuncName(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
                } else if (value === "") {
                    updateIsFunctionValid(false);
                } else {
                    updateIsFunctionValid(true);
                    updateErrorFuncName("");
                }
            } else if (value === props.asset.assetName) {
                updateIsFunctionValid(false);
                updateErrorFuncName(VALIDATION_MESSAGE.ORIGINAL_FUNCTION_NAME);
            } else {
                updateIsFunctionValid(isValid);
                updateErrorFuncName(VALIDATION_MESSAGE.FUNCTION_NAME_ERROR);
            }
            return isValid;
        },
        [nodeId]
    );

    const hasDefaultValue = !!input.defaultValue;
    const switchToggled =
        typeof switchState === "boolean" && input.mappingDetails.length === 0
            ? !!switchState
            : hasDefaultValue;

    return (
        <TR>
            <Fragment>
                {/* <Td tdBorderTop={index !== 0 ? 'remove-inner-row-border-top' : ''}>
                    <Condition when={index === 0 && rowCount > 1}>
                        <Icon name={isExpanded ? 'abb/minus' : 'abb/plus'} onClick={toggleExpand} />
                    </Condition>
                </Td> */}
                <Td
                    tdBorderTop={
                        index !== rowCount - 1
                            ? "remove-inner-row-border-bottom"
                            : ""
                    }
                >
                    {index === 0 ? (
                        <CustomABBInput
                            id={`${asset.assetRef}_nodeId`}
                            value={nodeId}
                            dataType="text"
                            disabled={
                                !props.isEditMode ||
                                window.location.pathname.includes(
                                    ROUTE_PATHNAME.OBJECTS
                                ) ||
                                asset.nodeId === props.overallSeverityFunctionId
                            }
                            onValueChange={(value) => onValueChange(value)}
                            onLostFocus={onFunctionNameFocusOut}
                            instantValidation
                            validator={() =>
                                !isFunctionValid
                                    ? nodeId === ""
                                        ? {
                                              valid: false,
                                              text: VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE,
                                          }
                                        : {
                                              valid: false,
                                              text: errorFuncName,
                                          }
                                    : { valid: true, text: "OK!" }
                            }
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                        />
                    ) : (
                        ""
                    )}
                </Td>
                <Td
                    tdBorderTop={
                        index !== rowCount - 1
                            ? "remove-inner-row-border-bottom"
                            : ""
                    }
                >
                    {index === 0 ? asset.assetName : ""}
                </Td>
                <Td> {input.name}</Td>
                <Td> {input.dataType}</Td>
                <Fragment>
                    <Td>
                        <ToggleSwitch
                            value={switchToggled}
                            onChange={handleSwitchToggle}
                            disabled={inViewMode}
                        />
                    </Td>

                    <Fragment>
                        {props.isEditMode ? (
                            switchToggled ? (
                                <Td>
                                    <CustomABBInput
                                        dataType="text"
                                        placeholder="Enter default value"
                                        value={defaultValue}
                                        onValueChange={updateDefaultValue}
                                        onLostFocus={() =>
                                            handleDefaultValuefocusOut()
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        validator={() =>
                                            defaultValueValidations.isInvalid
                                                ? {
                                                      valid: false,
                                                      text:
                                                          defaultValueValidations.errorMessage ||
                                                          "Invalid input",
                                                  }
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                </Td>
                            ) : (
                                <Td drop={drop}>
                                    <ConnectionDropdown
                                        {...props}
                                        dropDownOptions={[
                                            ...dropDownOptionsData,
                                        ]}
                                        selectedOptions={[
                                            ...selectedDropDownOptionsIndex,
                                        ]}
                                        inputAsset={asset}
                                        inputCircleData={input}
                                    />
                                </Td>
                            )
                        ) : switchToggled ? (
                            <Td>
                                <span className="text-center">
                                    {input.defaultValue}
                                </span>
                            </Td>
                        ) : (
                            <Td>
                                <span className="text-center">
                                    {input.mappingDetails.map(
                                        (mapping) =>
                                            `${mapping.output.circleData.name};`
                                    )}
                                </span>
                            </Td>
                        )}
                    </Fragment>
                </Fragment>
            </Fragment>
        </TR>
    );
};

export default ExpandableInputRows;
