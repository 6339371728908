import { LineWithTriggerParams, FabricILWT, FabricOLWP } from './FabricLineWithTrigger';
import { fabric } from 'fabric';
import {
    fontSize,
    canvasIOInhibitColor,
    outputCircleRadius,
    triggerCircleRadius,
    fullFunctionPinLength,
} from '../UiConfig';

export interface LineWithNameParams extends LineWithTriggerParams {
    text: string;
}

export class FabricILWN extends FabricILWT {
    lineText: fabric.Text;
    constructor(options: LineWithNameParams) {
        super(options);
        const { text } = options;
        // const circleTip = circleBefore ? this.ioCircle.left || 0 : (this.ioCircle.left || 0) + (this.ioCircle.radius || 0)
        const circleTip = this.ioCircle.left || 0;
        const pinText = text.length > 24 ? text.slice(0, 21) + '...' : text;
        this.lineText = new fabric.Text(pinText, {
            fontSize: fontSize * 0.8,
            // originX: circleBefore ? 'right' : 'left',
            originX: 'left',
            originY: 'center',
            // left: circleBefore ? circleTip - 10 : circleTip + 10,
            left: fullFunctionPinLength + 2 * outputCircleRadius,
            selectable: false,
            hoverCursor: 'default',
        });
        this.lineText.data = { tooltip: text };
    }
}

export class FabricOLWN extends FabricOLWP {
    lineText: fabric.Text;
    constructor(options: LineWithNameParams) {
        super(options);
        const { text } = options;

        const circleTip = this.ioCircle.left || 0;
        const pinText = text.length > 24 ? text.slice(0, 21) + '...' : text;
        this.lineText = new fabric.Text(pinText, {
            fontSize: fontSize * 0.8,
            // originX: 'left',
            originX: 'right',
            originY: 'center',
            // left: circleTip + 10,
            left: -outputCircleRadius,
            selectable: false,
            hoverCursor: 'default',
        });
        this.lineText.data = { tooltip: text };
    }
}

export class FabricALWN {
    constructor() {}
}
