import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { WithPopup, Icon, Popup, Button, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';

import EditFunctionInfo from '../../EditFunction/EditFunctionInfo';
import { StoreState } from '../../../../store';
import {
    handlePopoverOpen,
    updateAssetInfo,
    handleFunctionSave,
    handlePopoverInfo,
    handleFunctionDelete,
    handleFunctionMode,
    handleIsFunctionDirty,
    CreateFunctionAssetDetails,
    updateSelectedFunctionType,
    changeFunctionVersionUpdate,
    getSelectedFunctionType,
    setDeletingLibraryDetails,
    setGlobalLibraryError,
    setEmptyValidationErrors,
} from '../../../../store/function/action';
import { AssetsInfo } from '../../type';
import { ModelId } from '../../constants';
import {
    FunctionSaveType,
    FunctionMode,
    IGetSelectedFunctionPayload,
    LibraryAndNodePayloadWithoutNodes,
} from '../../../../store/function/types';
import {
    FUNCTION_SAVE_TYPE,
    FUNCTION_MODE,
    NOTIFICATION_MODAL_STATUS,
    DEL_FUNCTION_MSG,
} from '../../../../utils/constants/appConstants';
import {
    validateConditions,
    validateCalculations,
    checkFunctionValidation,
    checkForChangeInHeaderDetails,
    convertFuncDetailsToFuncType,
    optionToViewDecryptedFunction,
    findTypeDetailsFromLibrariesAndFunctionsList,
} from '../../helper';
import { showNotificationModal } from '../../../../store/notificationModal/action';
import { ShowNotificationModalPayload } from '../../../../store/notificationModal/types';
import {
    UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
    CONFIRMATION_BUTTON,
    TOOLTIP_MESSAGES,
} from '../../../../utils/constants/uiConstants';
import { checkVersionUpdateStatus } from '../../../../utils/helpers';
import { VersionUpdateType, VERSION_UPDATE_TYPE } from '../../../../store/configurationTool/types';
import { ShowMessageDispatchProps } from '../..';
import { showModal } from '../../../../store/modal/action';
import GetPasswordForLibraries from '../../../../components/GetPasswordForLibraries';
import CustomTooltip from '../../../../components/CustomTooltip';

interface EditHeaderPopupProps {
    errorFlag: boolean;
    functionSaveType: FunctionSaveType;
}

function EditHeaderPopup(
    props: EditHeaderPopupProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToprops> &
        ShowMessageDispatchProps
) {
    const [disableDelete, setdisableDelete] = useState(false);
    const {
        assetInfo,
        errorFlag,
        handleFunctionSave,
        functionSaveType,
        handleFunctionMode,
        showMessageModal,
        changeFunctionVersionUpdate,
    } = props;

    const toggleInfo = () => props.handlePopoverInfo(!props.isPopoverInfoOpen);
    const toggle = () => props.handlePopoverOpen(!props.isPopoverOpen);
    const addToEditFunction = (data: any) => {
        props.updateAssetInfo({ assetsInfo: data });
        toggleInfo();
    };

    const handleDelete = (data: string) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            props.handleIsFunctionDirty(false);
            props.handleFunctionMode(FUNCTION_MODE.VIEW);
        }
    };
    const handleCancel = (data: string) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            if (props.isDirty) {
                props.handleIsFunctionDirty(false);
            }
            props.handleFunctionMode(FUNCTION_MODE.VIEW);
            debugger;
            if (props.typeDetails) {
                // props.updateSelectedFunctionType(props.typeDetails);
                props.getSelectedFunctionType({
                    activeLibId: props.activeLibId,
                    selectedFunction: convertFuncDetailsToFuncType(props.typeDetails),
                });
            }
        }
    };

    const deleteFunction = () => {
        toggle();

        if (
            optionToViewDecryptedFunction(
                props.libraryAndFunctionsList,
                props.createFunctionData.assetsDetails
            )
        ) {
            props.showGetPasswordForLibrariesToDeleteIPProtectedFunction({
                libraryDetails: findTypeDetailsFromLibrariesAndFunctionsList(
                    props.libraryAndFunctionsList,
                    props.createFunctionData.assetsDetails
                ).libraryDetailsOfCurrentFunction,
                functions: [props.createFunctionData.assetsDetails],
                submitBtnText: 'Delete',
            });
        } else {
            props.showMessageModal!({
                title: 'Delete',
                rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                handleStandardButton: () =>
                    props.handleFunctionDelete({
                        modelId: ModelId,
                        typeId: props.createFunctionData.assetsDetails.typeId,
                        functionName:
                            props.createFunctionData.assetsDetails &&
                            props.createFunctionData.assetsDetails.name,
                        tags: props.createFunctionData.assetsDetails.tags,
                    }),
                warningText: DEL_FUNCTION_MSG,
            });
        }
        // if (functionSaveType === FUNCTION_SAVE_TYPE.CREATE) {
        // showMessageModal &&
        //     showMessageModal({
        //         acceptButtonTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
        //         rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
        //         title: CONFIRMATION_BUTTON.DISCARD_CHANGES,
        //         warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
        //         handleStandardButton: handleDelete,
        //     });

        // }
        // else {
        // props.handleFunctionDelete({
        //     modelId: ModelId,
        //     typeId: props.createFunctionData.assetsDetails.typeId,
        //     functionName:
        //         props.createFunctionData.assetsDetails &&
        //         props.createFunctionData.assetsDetails.name,
        //     tags: props.createFunctionData.assetsDetails.tags,
        // });
        // }
    };
    const cancelFunctionChanges = () => {
        toggle();
        if (props.isDirty && props.typeDetails) {
            showMessageModal &&
                showMessageModal({
                    acceptButtonTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                    title: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                    handleStandardButton: handleCancel,
                });
        } else {
            handleCancel(CONFIRMATION_BUTTON.ACCEPT);
        }
    };
    const checkFieldsValidation = () => {
        const validationResponse = checkFunctionValidation({
            ...props.functionHeaderDetails,
        });

        const conditions = _.cloneDeepWith(props.functionHeaderDetails.conditionDetails);
        const inputKeys = Object.keys(_.cloneDeepWith(props.functionHeaderDetails.inputDetails));
        const calculations = _.cloneDeepWith(props.functionHeaderDetails.calculationDetails);

        if (conditions) {
            const { isLogicValid, overallMessagesErrorMessages } = validateConditions(
                conditions,
                inputKeys
            );
            if (!isLogicValid) {
                props.showNotificationModal({
                    title: "Function validation error",
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: overallMessagesErrorMessages,
                });
                return false;
            }
        }
        if (calculations) {
            const { isLogicValid, overallMessagesErrorMessages } = validateCalculations(
                calculations,
                inputKeys
            );
            if (!isLogicValid) {
                props.showNotificationModal({
                    title: "Function validation error",
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: overallMessagesErrorMessages,
                });
                return false;
            }
        }

        const { isValid, errorMessages, emptyErrors } = validationResponse;
        if (emptyErrors) {
            props.setEmptyValidationsError(errorMessages);
            props.showNotificationModal({
                title: 'Function validation error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: errorMessages,
            });
            return isValid;
        }
        if (!isValid) {
            props.showNotificationModal({
                title: "Function validation error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: errorMessages,
            });
        }
        if (props.functionMode === FUNCTION_MODE.EDIT) {
            let versionType = checkForChangeInHeaderDetails(
                props.functionHeaderDetails,
                props.typeDetails!,
                props.alarmMapping!,
                props.assetInfo!
            );

            if (props.assetInfo && props.typeDetails) {
                if (
                    props.assetInfo.endpoint !==
                        props.typeDetails.properties.settings.endpoint.value ||
                    props.assetInfo.tags.join() !== props.typeDetails.tags.join()
                ) {
                    versionType = VERSION_UPDATE_TYPE.MINOR;
                }
            }

            if (versionType === undefined) {
                versionType = VERSION_UPDATE_TYPE.MAJOR;
            }

            changeFunctionVersionUpdate(versionType);

            let options: any = {};
            if (
                versionType !== VERSION_UPDATE_TYPE.DEFAULT &&
                props.typeDetails &&
                props.assetsInfo
            ) {
                options = checkVersionUpdateStatus({
                    oldVersion: props.typeDetails.version as string,
                    currentVersion: props.assetsInfo.version as string,
                    versionUpdateType: versionType,
                });
                const { errorVersionUpdateText, successVersionUpdate } = options;
                errorVersionUpdateText?errorMessages.push(errorVersionUpdateText):errorVersionUpdateText
                if (!successVersionUpdate) {
                    props.showNotificationModal({
                        title:"Function validation error",
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: errorMessages,
                    });
                } else if (successVersionUpdate && !isValid) {
                    props.showNotificationModal({
                        title: "Function validation error",
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: errorMessages,
                    });
                }
            }
            if (versionType === VERSION_UPDATE_TYPE.DEFAULT) {
                props.showNotificationModal({
                    title: "Function validation warning",
                    resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                    type: 'confirmation',
                    details: ['No new changes detected!'],
                });
            }

            return isValid && options.successVersionUpdate;
        }

        return isValid;
    };

    useEffect(() => {
        let inputs = props.functionHeaderDetails.inputDetails;
        let outputs = props.functionHeaderDetails.outputDetails;
        let disabled = false;
        if (Object.keys(inputs).length || Object.keys(outputs).length) {
            Object.keys(inputs).forEach((element) => {
                if (inputs[element].isDelete) {
                    disabled = true;
                    return;
                }
            })
            Object.keys(outputs).forEach((element) => {
                if (outputs[element].isDelete) {
                    disabled = true;
                    return;
                }
            })
            setdisableDelete(disabled);
        }


    }, [props.functionHeaderDetails])

    console.log('EditHeaderPopup props :', props);
    return (
        <>
            <WithPopup>
                <div>
                    <CustomTooltip position="right" text={TOOLTIP_MESSAGES.ASSET_DETAILS}>
                        <Icon
                            name="abb/information-circle-2"
                            sizeClass="small"
                            style={{
                                cursor: 'pointer',
                                paddingRight: '7px',
                                marginTop: '2px',
                                top:'3px',
                            }}
                        />
                    </CustomTooltip>
                </div>
                <Popup
                    trigger="click"
                    position="right center"
                    className="edit-info"
                    showCloseButton={true}
                    closeOnLostFocus={true}
                    closeOnEscape={true}
                    onOpen={() => toggleInfo()}
                    onClose={() => toggleInfo()}
                    render={(props: any) => (
                        <div>
                            <EditFunctionInfo
                                {...props}
                                toggle={toggleInfo}
                                assetsDetails={assetInfo && assetInfo}
                                addToEditFunction={addToEditFunction}
                                functionSaveType={functionSaveType}
                            />
                        </div>
                    )}
                />
            </WithPopup>

            <b className="func-name-header">
                {assetInfo && `${assetInfo.name} ${assetInfo.version}`}
            </b>
            <div className="func-top-bar-btns">
                <Button
                    className="ml-8"
                    sizeClass="small"
                    type={props.functionMode !== FUNCTION_MODE.CREATE ? 'normal' : 'primary-blue'}
                    text="Cancel"
                    onClick={() => cancelFunctionChanges()}
                />
                <Button
                    className="ml-8"
                    type="normal"
                    sizeClass="small"
                    text="Delete"
                    disabled={disableDelete}
                    onClick={() => {
                        if (props.functionMode !== FUNCTION_MODE.CREATE) {
                            deleteFunction();
                        }
                    }}
                />
                <Button
                    text="Save"
                    type="primary-blue"
                    sizeClass="small"
                    className="ml-8"
                    disabled={errorFlag}
                    onClick={() => {
                        const isValid = checkFieldsValidation();
                        if (isValid) {
                            handleFunctionSave(functionSaveType);
                            props.setEmptyValidationsError([]);
                        }
                    }}
                />
            </div>
        </>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        isPopoverOpen: state.functions.isPopoverOpen,
        isPopoverInfoOpen: state.functions.isPopoverInfoOpen,
        assetInfo: state.functions.assetsInfo,
        createFunctionData: state.functions.currentActiveView,
        functionMode: state.functions.functionMode,
        functionHeaderDetails: state.functions.functionHeaderDetails,
        isDirty: state.functions.isDirty,
        typeDetails: state.functions.typeDetails,
        hasSave: state.functions.functionPermission.hasSave,
        hasEdit: state.functions.functionPermission.hasEdit,
        hasDelete: state.functions.functionPermission.hasDelete,
        alarmMapping: state.functions.alarmMapping,
        assetsInfo: state.functions.assetsInfo,
        activeLibId: state.functions.activeLibId,
        libraryAndFunctionsList: state.functions.libraryAndFunctionsList,
    };
}
function mapDispatchToprops(dispatch: Dispatch) {
    return {
        handlePopoverOpen: (isOpen: boolean) => {
            dispatch(handlePopoverOpen(isOpen));
        },
        handlePopoverInfo: (isOpen: boolean) => {
            dispatch(handlePopoverInfo(isOpen));
        },
        updateAssetInfo: (payload: { assetsInfo: AssetsInfo }) => {
            dispatch(updateAssetInfo(payload));
        },
        handleFunctionSave: (saveType: FunctionSaveType) => {
            dispatch(handleFunctionSave(saveType));
        },
        handleFunctionDelete: (payload: {
            modelId: string;
            typeId: string;
            functionName: string;
            tags: string[];
        }) => {
            dispatch(handleFunctionDelete(payload));
        },
        handleFunctionMode: (mode: FunctionMode) => {
            dispatch(handleFunctionMode(mode));
        },
        showNotificationModal: (payload: ShowNotificationModalPayload) => {
            dispatch(showNotificationModal(payload));
        },
        handleIsFunctionDirty: (isDirty: boolean) => {
            dispatch(handleIsFunctionDirty(isDirty));
        },

        getSelectedFunctionType: (payload: IGetSelectedFunctionPayload) => {
            dispatch(getSelectedFunctionType(payload));
        },

        updateSelectedFunctionType: (typeDetails: CreateFunctionAssetDetails) => {
            dispatch(updateSelectedFunctionType({ typeDetails }));
        },
        setEmptyValidationsError: (payload: string[]) => {
            dispatch(setEmptyValidationErrors(payload));
        },
        changeFunctionVersionUpdate: (updateVersionType: VersionUpdateType) =>
            dispatch(changeFunctionVersionUpdate(updateVersionType)),
        showGetPasswordForLibrariesToDeleteIPProtectedFunction: (payload: {
            libraryDetails: LibraryAndNodePayloadWithoutNodes;
            functions: CreateFunctionAssetDetails[];
            submitBtnText: string;
        }) => {
            dispatch(
                showModal({
                    component: GetPasswordForLibraries,
                    modalTitle: 'Enter Library Password',
                    data: {
                        submitBtnText: payload.submitBtnText,
                        submitBtnAction: (
                            functionsDetailsList: CreateFunctionAssetDetails[],
                            libraryDetails: LibraryAndNodePayloadWithoutNodes[]
                        ) => {
                            dispatch(
                                setDeletingLibraryDetails({
                                    libraryId: libraryDetails[0].id,
                                    libraryVersion: libraryDetails[0].libraryVersion,
                                    password: libraryDetails[0].password,
                                })
                            );
                            dispatch(
                                handleFunctionDelete({
                                    modelId: ModelId,
                                    typeId: functionsDetailsList[0].typeId,
                                    functionName: functionsDetailsList[0].name,
                                    tags: functionsDetailsList[0].tags,
                                })
                            );
                        },
                        functionsDetailsList: payload.functions,
                        libraryDetails: [payload.libraryDetails],
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: '' })),
                        editOrView: true,
                        deletingFunction: true,
                    },
                    closeModal: () => dispatch(setGlobalLibraryError({ error: '' })),
                    customClassName: 'change-password-modal',
                })
            );
        },
    };
}

export default connect(mapStateToProps, mapDispatchToprops)(EditHeaderPopup);
