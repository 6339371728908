import {
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    CONFIGURATION_TOOL_PRIVILEGES,
    ROUTE_PATHNAME,
} from '../../utils/constants/appConstants';

import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import {
    updateConfigurationToolJson,
    updateConfigurationToolActiveView,
    updateConfigurationToolMode,
    updateConfigurationToolPrivileges,
    initializeConfigurationTool,
    removeConnectionConfigurationTool,
    addConnectionConfigurationTool,
    updateActiveModelJson,
    createConfigurationToolJson,
    markComputeModelDirty,
    updateOverAllSeverityFunctionStatus,
    updateOverallSeverityFunctionId,
    removeOverallSeverityFunction,
    updateOverAllSeverityFunctionData,
    getSelectedFunctionDetails,
    setSelectedFunctionDetails,
    handleFunctionDetailsLoading,
    setVersionUpdateType,
    getOriginalFunctionTypes,
    setOriginalFunctionTypes,
    addToOriginalSelectedFunctions,
    getOriginalFunctionDataFromJson,
    addIsDirtyToConditions,
    handleAllowOverlapOnCanvas,
    updateLatestFunctionData,
    updateCurrentSelectedFunctionWithLatestData,
    getLatestFunctionsForType,
    updateTypeWithLatestFunctions,
    updateConfigurationToolJsonWithLatestFunction,
    handleLatestFunctionDataLoading,
    updateOriginalFunctiontypes,
    handleCurrentFunctionUpdateStatus,
    handleCanvasZoomUpdate,
    undoJSON,
    redoJSON,
    clearUndoRedoStack,
    changeIsSeverityUpdate,
    showConfigurationPopup,
    hideConfigurationPopup,
    stopBacgroundTaskGetOriginalFunctionTypes,
    stopBacgroundTaskGetLatestFunctions,
    updateModelsForTriggers,
    fetchModelsForTriggers,
    fetchAlarmsForModels,
    updateAlarmsForModels,
    clearAlarmsForModels,
    clearEventsForModels,
    fetchEventsForModels,
    updateEventsForModels,
    updateTimeTriggerForFunction,
} from './actions';
import CanvasController from '../../components/Fabric';
import { TableData } from '../../model/TableController';
import { FunctionTypeDetailsWithMappings } from '../../model/TableController/types';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';
import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import { AssetType } from '../../transformers/AssetType';
import { ActiveModelType } from '../modelsPage/types';
import { MovePosition } from '../../components/Fabric/ConnectionController/types';
import { Asset, AssetsType, AlarmEventType } from '../../components/Fabric/types';
import AlarmTypeDetail from '../../transformers/AssetType/AlarmType';
import EventTypeDetail from '../../transformers/AssetType/EventType';

export interface ConfigurationToolState {
    mode:
        | typeof CONFIGURATION_TOOL_MODE.VIEW
        | typeof CONFIGURATION_TOOL_MODE.EDIT
        | typeof CONFIGURATION_TOOL_MODE.CREATE
        | typeof CONFIGURATION_TOOL_MODE.IMPORT;
    activeView: typeof VIEW_MODE.TABLE | typeof VIEW_MODE.DIAGRAM;
    json: LocalJson;
    tableData: TableData;
    privileges:
        | typeof CONFIGURATION_TOOL_PRIVILEGES.ASSETS_PAGE
        | typeof CONFIGURATION_TOOL_PRIVILEGES.MODELS_PAGE;
    canvasController: CanvasController | null;
    isDirty: boolean;
    isOverAllSeverityFuncAdded: boolean;
    overallSeverityFunctionId: string;
    severityFunctionData?: AssetType;
    selectedFunctionDetails?: FunctionTypeDetail | null;
    showFunctionLoading: boolean;
    versionUpdateType: VersionUpdateType;
    originalFunctionTypesUsed: OriginalFunctionTypes;
    addIsDirtyToConditions: boolean;
    isOverlapAllowed: boolean;
    latestFunctionTypes: LatestFunctionDataPayload;
    isLatestFuncLoading: boolean;
    isUpdatedWithLatestFunction: boolean;
    canvasZoom?: number;
    undoStack: LocalJson[];
    redoStack: LocalJson[];
    previousJson?: LocalJson;
    isSeverityUpdate: boolean;
    configPopupDetails: showConfigPopupPayload | undefined;
    showConfigPopup: boolean;
    modelsForTrigger: any[];
    alarmsForModels: {
        [key: string]: string[];
    };
    eventsForModels: {
        [key: string]: string[];
    };
}

export interface showConfigPopupPayload {
    asset: Asset;
    handleFunctionSettings: () => void;
}
export type IConfigurationToolMode =
    | typeof CONFIGURATION_TOOL_MODE.VIEW
    | typeof CONFIGURATION_TOOL_MODE.EDIT
    | typeof CONFIGURATION_TOOL_MODE.CREATE
    | typeof CONFIGURATION_TOOL_MODE.IMPORT;
export interface LatestFunctionDataPayload {
    [key: string]: LatestFunctionDataType;
}
export interface LatestFunctionDataType {
    description: string;
    isDeleted: boolean;
    isExtensible: boolean;
    model: AssetsType.FUNCTION;
    name: string;
    properties: object;
    tags: string[];
    typeId: string;
    version: string;
}

export const VERSION_UPDATE_TYPE = <const>{
    MAJOR: 'MAJOR',
    MINOR: 'MINOR',
    PATCH: 'PATCH',
    DEFAULT: 'DEFAULT',
};
export type VersionUpdateType = 'MAJOR' | 'MINOR' | 'PATCH' | 'DEFAULT';
export type RemoveConnectionOptions = {
    inputCircleData: FunctionTypeDetailsWithMappings['inputs'][0];
    inputAsset: FunctionTypeDetailsWithMappings;
} & (
    | {
          outputCircleData: FunctionTypeDetail['outputs'][0] | ObjectTypeDetail['outputs'][0];
          outputAsset: FunctionTypeDetailsWithMappings | ObjectTypeDetail;
      }
    | { removeAll: true }
);

export type AddConnectionOptions = {
    inputCircleData: FunctionTypeDetailsWithMappings['inputs'][0];
    outputCircleData: FunctionTypeDetail['outputs'][0] | ObjectTypeDetail['outputs'][0];
    inputAsset: FunctionTypeDetailsWithMappings;
    outputAsset: FunctionTypeDetailsWithMappings | ObjectTypeDetail;
};

export type removeAlarmTriggerOptions = {
    inputCircleData: FunctionTypeDetailsWithMappings['alarmInputs'][0];
    outputCircleData: AlarmTypeDetail['outputs'][0];
    inputAsset: FunctionTypeDetailsWithMappings;
    outputAsset: AlarmTypeDetail;
};

export type removeEventTriggerOptions = {
    inputCircleData: FunctionTypeDetailsWithMappings['eventInputs'][0];
    outputCircleData: EventTypeDetail['outputs'][0];
    inputAsset: FunctionTypeDetailsWithMappings;
    outputAsset: EventTypeDetail;
};

export interface AssetDropActionType {
    asset: AssetType;
    currentActiveView: string;
    activeModel: ActiveModelType;
    canvasAssets: Asset[];
    position?: MovePosition;
}

export interface GetFunctionTypePayload {
    assetType: string;
    assetRef: string;
    assetVersion: string;
}

// export interface GetOriginnalFunctionTypesPayload extends GetFunctionTypePayload{
//     position:
// }
export interface OriginalFunctionTypes {
    byIdWithVersion: {
        [key: string]: FunctionTypeDetail;
    };
    entities: string[];
}

export interface AddAlarmToFunctionPayload {
    alarmDetails: {
        name: string;
        type: AlarmEventType;
        alarmId: string;
        alarmLabel: string;
        modelId: string;
    };
    functionType: FunctionTypeDetail;
    objectType?: ObjectTypeDetail;
    objectOutputId?: string;
}

export interface EditAlarmToFunctionPayload {
    alarmType: AlarmTypeDetail;
    newName: string;
    functionType: FunctionTypeDetail;
}

export interface EditNodeIdOfFunctionPayload {
    newName: string;
    functionType: FunctionTypeDetail;
}

export interface AddEventToFunctionPayload {
    eventDetails: {
        name: string;
        type: AlarmEventType;
        eventId: string;
        eventLabel: string;
        modelId: string;
    };
    functionType: FunctionTypeDetail;
    objectType?: ObjectTypeDetail;
}

export type CurrentRoutePathType = typeof ROUTE_PATHNAME.HOME | typeof ROUTE_PATHNAME.OBJECTS;

export type Actions = ReturnType<
    | typeof updateConfigurationToolJson
    | typeof updateConfigurationToolActiveView
    | typeof updateConfigurationToolMode
    | typeof updateConfigurationToolPrivileges
    | typeof initializeConfigurationTool
    | typeof removeConnectionConfigurationTool
    | typeof addConnectionConfigurationTool
    | typeof updateActiveModelJson
    | typeof createConfigurationToolJson
    | typeof markComputeModelDirty
    | typeof updateOverAllSeverityFunctionStatus
    | typeof updateOverallSeverityFunctionId
    | typeof removeOverallSeverityFunction
    | typeof updateOverAllSeverityFunctionData
    | typeof getSelectedFunctionDetails
    | typeof setSelectedFunctionDetails
    | typeof handleFunctionDetailsLoading
    | typeof setVersionUpdateType
    | typeof getOriginalFunctionTypes
    | typeof setOriginalFunctionTypes
    | typeof addToOriginalSelectedFunctions
    | typeof getOriginalFunctionDataFromJson
    | typeof addIsDirtyToConditions
    | typeof handleAllowOverlapOnCanvas
    | typeof getLatestFunctionsForType
    | typeof updateLatestFunctionData
    | typeof updateCurrentSelectedFunctionWithLatestData
    | typeof updateTypeWithLatestFunctions
    | typeof updateConfigurationToolJsonWithLatestFunction
    | typeof handleLatestFunctionDataLoading
    | typeof updateOriginalFunctiontypes
    | typeof handleCurrentFunctionUpdateStatus
    | typeof handleCanvasZoomUpdate
    | typeof undoJSON
    | typeof redoJSON
    | typeof clearUndoRedoStack
    | typeof changeIsSeverityUpdate
    | typeof showConfigurationPopup
    | typeof hideConfigurationPopup
    | typeof stopBacgroundTaskGetOriginalFunctionTypes
    | typeof stopBacgroundTaskGetLatestFunctions
    | typeof updateModelsForTriggers
    | typeof fetchModelsForTriggers
    | typeof fetchAlarmsForModels
    | typeof updateAlarmsForModels
    | typeof clearAlarmsForModels
    | typeof fetchEventsForModels
    | typeof updateEventsForModels
    | typeof clearEventsForModels
    | typeof updateTimeTriggerForFunction
>;
