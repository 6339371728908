import { fabric } from 'fabric';
import { outputCircleRadius, fontSize, canvasPrimaryTextColor, GRID_PIXEL } from '../../UiConfig';
import { SelfPositioningChild } from '../../../NodeController/types';
import { Asset, AssetsType } from '../../../types';
import FunctionTypeDetail from '../../../../../transformers/AssetType/FunctionType';

import ObjectTypeDetail from '../../../../../transformers/AssetType/ObjectType';
import AlarmTypeDetail from '../../../../../transformers/AssetType/AlarmType';
/**
 * It will add the circles on both the sides of a dropdownRectangle to add connections.
 *
 * @export
 * @class DropDownConnectionHooks
 * @extends {fabric.Group}
 * @implements {SelfPositioningChild}
 */
export class DropDownAssetName extends fabric.Text implements SelfPositioningChild {
    data: SelfPositioningChild['data'];
    constructor(options: { group: fabric.Group; asset: Asset; color: string }) {
        const { group, asset, color } = options;

        const { assetName, assetVersion, nodeId } = asset;

        const titleText = `${asset.assetType === AssetsType.FUNCTION ? nodeId : assetName} ${assetVersion}`;
        
        super(assetName.length > 20 ? assetName.substring(0,20)+"..." : assetName, {
            originX: 'left',
            originY: 'center',
            fontSize: fontSize,
            fill: color,
            borderColor: canvasPrimaryTextColor,
            left:
                group.getPointByOrigin('center', 'center').x -
                group.getScaledWidth() / 2 +
                3 * GRID_PIXEL * (group.scaleX || 1),
            top: group.getPointByOrigin('center', 'center').y,
            selectable: false,
            hoverCursor: 'pointer',
            visible: true,
        });

        this.data = {
            tooltip: titleText,
            rePosition: () => {
                this.left =
                    group.getPointByOrigin('center', 'center').x -
                    group.getScaledWidth() / 2 +
                    3 * GRID_PIXEL * (group.scaleX || 1);
                this.top = group.getPointByOrigin('center', 'center').y || 0;
                this.scaleX = group.scaleX || 1;
                this.scaleY = group.scaleX || 1;
                this.bringForward();
                this.setCoords();
            },
        };
    }
}
