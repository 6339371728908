import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input } from "@abb/abb-common-ux-react";
import { FormGroup, Label } from "reactstrap";
import { StoreState } from "../../../store";
import { Dispatch } from "redux";
import { deleteCustomVariableRequest } from "../../../store/assets/actions";
import ButtonBar from "../ButtonBar";
import "./style.scss";
import ConfigurationHeader from "../Header";
import { SettingsLeftNavOptions } from "../../../store/settings/types";
import CustomABBInput from "../../../components/CustomABBInput";

const CustomVariableDeleteTab = (
    props: any &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [objectId, setObjectId] = useState("");
    const [modelId, setModelId] = useState("");

    const [showDialog, setDialog] = useState(false);
    // inputField error handling states
    const [errorObjectId, updateErrorObjectId] = useState(false);
    const [errorModelId, updateErrorModelId] = useState(false);

    return (
        <div className="wrapper-connect-models">
            <ConfigurationHeader
                headerText={SettingsLeftNavOptions.DELETE_INSTANCE}
            />
            <div className="connect-models-form">
                <div className="parent-asset-config-form">
                    <div className="asset-config-form delete-instance-form ">
                        <FormGroup className="custom-form-group">
                            <CustomABBInput
                                label={"Object Id"}
                                value={objectId}
                                onValueChange={(value) => {
                                    setObjectId(value);
                                    updateErrorObjectId(false);
                                }}
                                type="normal"
                                dataType="text"
                                id="objectId"
                                placeholder="Enter Object Id"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={true}
                                validator={() =>
                                    errorObjectId
                                        ? {
                                              valid: false,
                                              text: "Please Enter Object Id",
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </FormGroup>
                        <FormGroup className="custom-form-group">
                            <CustomABBInput
                                label={"Model Id"}
                                value={modelId}
                                onValueChange={(value) => {
                                    setModelId(value);
                                    updateErrorModelId(false);
                                }}
                                type="normal"
                                dataType="text"
                                id="modelId"
                                placeholder="Enter Model Id"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={true}
                                validator={() =>
                                    errorModelId
                                        ? {
                                              valid: false,
                                              text: "Please Enter Model Id",
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <ButtonBar className="delete-instance-btn-bar">
                <Button
                    text="Delete"
                    sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    className="customDeleteButton"
                    style={{ marginLeft: `12px` }}
                    onClick={() => {
                        if (objectId == "") {
                            updateErrorObjectId(true);
                            return;
                        }
                        if (modelId == "") {
                            updateErrorModelId(true);
                            return;
                        }
                        props.deleteCustomVariable(objectId, modelId);
                        setObjectId("");
                        setModelId("");
                        setDialog(!showDialog);
                    }}
                />
            </ButtonBar>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        deleteCustomVariable: (objectId: any, modelId: any) => {
            dispatch(
                deleteCustomVariableRequest({
                    objectId: objectId,
                    modelId: modelId,
                })
            );
        },
    };
};
const mapStateToProps = (store: StoreState) => {
    return {
        isSystemCategorySelected: store.assets.systemCategorySelected,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomVariableDeleteTab);
