import React from "react";
import { useDrag, useDrop } from "react-dnd";
import "./style.scss";
import { DroppableTableRowProps } from "../DroppableTableRow";
import { ALARM_TABLE_DRAGGABLE_ITEMS } from "../dropdownConstants";
import dragDropImages from "../../../assets/icons/leftNavigationToolBox/drag drop icon.svg";
import _ from "lodash";

export interface DraggableTableRowProps {
    dragItem: {
        value: number;
        label: string;
        dataType: string;
    };
    updateAlarmDragDropTables: (payload: {
        dropTableData: DroppableTableRowProps["dropItem"][];
        dragTableData: DraggableTableRowProps["dragItem"][];
    }) => void;
    dropTableData: DroppableTableRowProps["dropItem"][];
    dragTableData: DraggableTableRowProps["dragItem"][];
}
const DraggableTableRow = (props: DraggableTableRowProps) => {
    const {
        dragItem,
        dragTableData,
        dropTableData,
        updateAlarmDragDropTables,
    } = props;
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DRAGGABLE,
            data: dragItem,
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    const [, dropUnmappedAlarm] = useDrop({
        accept: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DROPPABLE,
        drop: (data: any, monitor) => {
            if (dragItem.label === "") {
                const updatedDragItemData: DraggableTableRowProps["dragItem"][] =
                    _.cloneDeep(dragTableData).map(
                        (dragTableItem: DraggableTableRowProps["dragItem"]) => {
                            if (dragTableItem.value === dragItem.value) {
                                dragTableItem.label = data.data.mappedAlarm;
                            }
                            return dragTableItem;
                        }
                    );
                const updatedDropTableData: DroppableTableRowProps["dropItem"][] =
                    _.cloneDeep(dropTableData).map(
                        (dropTableItem: DroppableTableRowProps["dropItem"]) => {
                            if (dropTableItem.value === data.data.value) {
                                dropTableItem.mappedAlarm = "";
                            }
                            return dropTableItem;
                        }
                    );
                updateAlarmDragDropTables({
                    dropTableData: updatedDropTableData,
                    dragTableData: updatedDragItemData,
                });
            }
        },
    });

    return (
        <tr key={dragItem.value} ref={dropUnmappedAlarm}>
            <td>
                {dragItem.label !== "" && (
                    <div className="table-data-div" ref={drag}>
                        <img src={dragDropImages} alt="dragDropImages" />
                        <div className="table-data-title">{dragItem.label}</div>
                    </div>
                )}
            </td>
        </tr>
    );
};
export default DraggableTableRow;
