import { Input } from "reactstrap";
import { isNilOrEmpty } from "../../utils/helpers";
import { LocalJson } from "../../transformers/ComputeModel/fromJson/types";
import { ACTIVE_INPUT_TAB } from "../../components/IoTableTabs/constant";
import _ from "lodash";
import { IODataTypes } from "../../components/Fabric/types";
import { Connection } from "../../components/Fabric/ConnectionController/types";
import { ComputeModelToJson } from "../../transformers/ComputeModel/toJson/ComputeModelToJson";
import { ComputeModelFromJson } from "../../transformers/ComputeModel/fromJson/ComputeModelFromJson";
import FunctionTypeDetail from "../../transformers/AssetType/FunctionType";

export const isArrayTypeConnection = (connectionType: string) => {
    if (isNilOrEmpty(connectionType)) {
        return false;
    }
    const arrayInputTypes = [
        IODataTypes.ARRAY_BOOLEAN,
        IODataTypes.ARRAY_INTEGER,
        IODataTypes.ARRAY_NUMBER,
        IODataTypes.ARRAY_STRING,
    ];
    const foundIndex = _.findIndex(arrayInputTypes, (type) => {
        return type === connectionType;
    });

    if (foundIndex !== -1) {
        return true;
    }

    return false;
};

export const getPrimitiveType = (arrayConnectionType: string) => {
    if (isNilOrEmpty(arrayConnectionType)) {
        return arrayConnectionType;
    }

    return arrayConnectionType.split("_")[1];
};

export const isValidIOConnectionType = (
    targetType: string,
    srcType: string
) => {
    if (isNilOrEmpty(targetType) || isNilOrEmpty(srcType)) {
        return false;
    }

    if (targetType === srcType) {
        return true;
    }

    if (isArrayTypeConnection(targetType) || isArrayTypeConnection(srcType)) {
        const primitiveTargetType = isArrayTypeConnection(targetType)
            ? getPrimitiveType(targetType)
            : targetType;
        const primitiveSrcType = isArrayTypeConnection(srcType)
            ? getPrimitiveType(srcType)
            : srcType;

        if (primitiveTargetType === primitiveSrcType) {
            return true;
        }
    }

    return false;
};
// @ts-ignore
// todo
export const isConnectionExist = (
    connectionArr: Connection["data"][],
    output: Connection["data"]["output"],
    input: Connection["data"]["input"]
) => {
    if (isNilOrEmpty(connectionArr)) {
        return false;
    }

    if (isNilOrEmpty(output) || isNilOrEmpty(input)) {
        return false;
    }

    const findIndex = _.findIndex(connectionArr, (connectionObj) => {
        if (
            connectionObj.input.circleData.id === input.circleData.id &&
            connectionObj.output.circleData.id === output.circleData.id
        ) {
            return true;
        }
        return false;
    });

    return findIndex !== -1;
};

export interface UpdateTypeRelatedInstacePayloadType {
    model: string;
    typeId: string;
    version: string;
    properties?: { functions: object };
}

export const updateTypeRelatedInstacePayload = (
    originalJson: ComputeModelFromJson,
    updatedJson: ComputeModelToJson
) => {
    let payload = {} as UpdateTypeRelatedInstacePayloadType;
    payload["model"] = updatedJson.properties["model"].model.value;
    payload["typeId"] = updatedJson.properties["model"].typeId.value;
    payload["version"] = updatedJson.properties["model"].version.value;
    payload["properties"] = { functions: {} };

    const originalFunctions = originalJson.json.assetData.filter(
        (item) => item instanceof FunctionTypeDetail
    ) as FunctionTypeDetail[];
    const originalFunctionObj = originalFunctions.reduce(
        (obj, item) =>
            Object.assign(obj, {
                [item.nodeId]: item,
            }),
        {}
    );
    const updatedFunctions = updatedJson.properties["functions"];

    Object.keys(updatedFunctions).forEach((item) => {
        const updatedEndpoint = updatedFunctions[item].endpoint.value;
        if (payload.properties) {
            if (updatedEndpoint !== originalFunctionObj[item].endpoint) {
                payload["properties"]["functions"][item] = {
                    ...payload["properties"]["functions"][item],
                };
                payload["properties"]["functions"][item]["endpoint"] = {
                    value: updatedEndpoint,
                };
            }
            if (updatedFunctions[item].inputs.conditions) {
                const updatedConditions =
                    updatedFunctions[item].inputs.conditions;
                const changedConditions = checkForChangedConditionFields(
                    originalFunctionObj[item].conditions,
                    updatedConditions
                );
                if (Object.keys(changedConditions).length) {
                    payload["properties"]["functions"][item] = {
                        ...payload["properties"]["functions"][item],
                    };
                    payload["properties"]["functions"][item]["inputs"] = {
                        ...payload["properties"]["functions"][item]["inputs"],
                    };
                    payload["properties"]["functions"][item]["inputs"][
                        "conditions"
                    ] = {
                        ...changedConditions,
                    };
                }
            }
        }
    });
    if (Object.keys(payload["properties"]["functions"]).length === 0) {
        delete payload["properties"];
    }
    return payload;
};

export const checkForChangedConditionFields = (
    oldConditions: any,
    newConditions: any
) => {
    let changedConditions = {};
    Object.keys(newConditions).forEach((conditionName) => {
        const conditionItem = newConditions[conditionName];
        const oldConditionItem = oldConditions[conditionName];
        const subConditions = conditionItem["subConditions"];
        const oldSubConditions = oldConditionItem["subConditions"];
        Object.keys(subConditions).forEach((subConditionName) => {
            const subConditionItem = subConditions[subConditionName];
            const oldSubConditionItem = oldSubConditions[subConditionName];

            Object.keys(subConditionItem).forEach((item) => {
                const subConditionItemProperties = subConditionItem[item];
                const oldSubConditionItemProperties = oldSubConditionItem[item];

                if (
                    subConditionItemProperties["value"] !==
                    oldSubConditionItemProperties["value"]
                ) {
                    changedConditions[conditionName] = {
                        ...changedConditions[conditionName],
                    };
                    changedConditions[conditionName]["subConditions"] = {
                        ...changedConditions[conditionName]["subConditions"],
                    };

                    changedConditions[conditionName]["subConditions"][
                        subConditionName
                    ] = {
                        ...changedConditions[conditionName]["subConditions"][
                            subConditionName
                        ],
                    };
                    changedConditions[conditionName]["subConditions"][
                        subConditionName
                    ][item] = { ...subConditionItem[item] };
                }
            });
        });
    });
    return changedConditions;
};
