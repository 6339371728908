import React, { Fragment, useState, useCallback, useEffect } from 'react';
import TR from '../../../../Table/TableRow';
import { Collapse } from 'reactstrap';
import { IoTableTabsProps } from '../../..';
import { FunctionTypeDetailsWithMappings } from '../../../../../model/TableController/types';
import Td from '../../../../Table/TableData';
import { Icon } from '@abb/abb-common-ux-react';
import _ from 'lodash';
// import Checkbox from "../../../../CustomCheckbox";

import { AlarmMapping } from '../../../../../store/function/types';
import { ROUTE_PATHNAME } from '../../../../../utils/constants/appConstants';
import { Checkbox } from '@abb/abb-common-ux-react';
import CollapseTitle from '../../../../CollapseTitle';

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

const ExpandableAlarmRows = (props: ExpandTableRowProps & IoTableTabsProps) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    return (
        <Fragment>
            {props.asset.alarmMapping ? (
                <>
                    <CollapseTitle title={props.asset.nodeId} isCollapse={isExpanded} toggleCollapse={toggleExpand} />
                    <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                <AlarmRow
                    {...props}
                    alarmMapping={props.asset.alarmMapping}
                    alarmPins={props.asset.alarmPins}
                    asset={props.asset}
                />
                    </Collapse>
                </>

            ) : (
                <></>
            )}
        </Fragment>
    );
};

export const AlarmRow = (
    options: {
        alarmMapping: AlarmMapping;
        alarmPins: FunctionTypeDetailsWithMappings['alarmPins'];
        asset: FunctionTypeDetailsWithMappings;
    } & IoTableTabsProps
) => {
    const { asset, alarmMapping, alarmPins, inViewMode } = options;

    const handleCheckboxClick = useCallback(() => {
        options.publishAlarmConfigurationTool({ asset });
    }, []);
    const alarmName = Object.keys(alarmMapping)[0];

    return (
        <TR>
            <Td>{asset.nodeId}</Td>
            <Td>{asset.assetName}</Td>
            <Td>{alarmName}</Td>
            <Td> {alarmMapping[alarmName].alarmType.dataType}</Td>
            <Td>
                <Checkbox
                    sizeClass="small"
                    value={alarmPins.isPublished ? true : false}
                    disabled={inViewMode}
                    onChange={handleCheckboxClick}
                />
            </Td>
        </TR>
    );
};

export default ExpandableAlarmRows;
