import React, { useState, useMemo, useEffect } from "react";
import { Input, Button } from "@abb/abb-common-ux-react";
import { LibraryAndNodePayload } from "../../../store/function/types";
import { connect } from "react-redux";
import { StoreState } from "../../../store";
import { ModalFooter } from "reactstrap";
import "./style.scss";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface IPasswordModal extends ReturnType<typeof mapStateToProps> {
    submitBtnText: string;
    libraryDetail: LibraryAndNodePayload;
}
const PasswordModal = (props: IPasswordModal) => {
    console.log(props);
    const {
        libraryGlobalError,
        isLoading,
        submitBtnText,
        modalData,
        libraryDetail,
    } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const toggleViewBtn = () => {
        setShowPassword(!showPassword);
    };

    const isSubmitBtnDisabled = useMemo(() => {
        return password.length === 0 || isLoading;
    }, [password, isLoading]);

    useEffect(() => {
        if (libraryGlobalError.length > 0 && !isLoading) {
            setPasswordError(libraryGlobalError);
        }
    }, [libraryGlobalError, isLoading]);

    const handleSubmit = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        if (modalData && modalData.handleSubmitBtn) {
            modalData.handleSubmitBtn(password);
        }
        setPasswordError("");
    };
    return (
        <form className="wrapper-password-modal" onSubmit={handleSubmit}>
            <CustomABBInput
                className="password-input-item"
                label={libraryDetail.root}
                dataType={showPassword ? "text" : "password"}
                value={password}
                placeholder={"Enter library password"}
                onValueChange={(value) => {
                    setPassword(value.trim());
                    setPasswordError("");
                }}
                icon={showPassword ? "abb/hide" : "abb/view"}
                onIconClick={toggleViewBtn}
                validator={() =>
                    passwordError.length > 0
                        ? { valid: false, text: passwordError }
                        : { valid: true, text: "OK!" }
                }
                instantValidation
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
            />
            <ModalFooter>
                <div className="footer-part">
                    {libraryDetail.isIPProtected && (
                        <div>
                            <p>
                                <span className="note">{NOTE_MESSAGE}</span>
                                <span className="admin_help_message">
                                    {ADMIN_HELP_MESSAGE}
                                </span>
                            </p>
                        </div>
                    )}
                    <Button
                        className="submit-btn"
                        text={submitBtnText}
                        type="primary-blue"
                        sizeClass="small"
                        disabled={isSubmitBtnDisabled}
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    />
                </div>
            </ModalFooter>
        </form>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        modalData: state.modal.data,
        libraryGlobalError: state.functions.libraryGlobalError,
        isLoading: state.functions.isPasswordBtnLoading,
    };
};
export default connect(mapStateToProps, undefined)(PasswordModal);
