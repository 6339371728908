import { fabric } from 'fabric';
import {
    alramIconSize,
    alarmIconColor,
    triggerColor,
    triggerStrokeWidth,
    canvasIOTriggerColor,
    noTriggerStrokeWidth,
    triggerAlarmStrokeWidth,
    noTriggerAlarmStrokeWidth,
} from './UiConfig';

const alarmIconPath =
    'M14,21a2,2,0,1,1-4,0Zm5-8c0-3.68-1.44-8.29-6-8.93V2a1,1,0,0,0-2,0V4.07C6.44,4.71,5,9.32,5,13a6.85,6.85,0,0,1-2,4.29V20H21V17.29A6.85,6.85,0,0,1,19,13Z';

const scale = alramIconSize / 18;
export interface FabricAlarmIconParams {
    triggered: boolean;
}
export class FabricAlarmIcon extends fabric.Path {
    triggerOn: () => void;
    triggerOff: () => void;

    constructor(options: FabricAlarmIconParams) {
        super(alarmIconPath, {
            stroke: options.triggered ? triggerColor : alarmIconColor,
            strokeWidth: noTriggerAlarmStrokeWidth, // options.triggered ? triggerAlarmStrokeWidth : noTriggerAlarmStrokeWidth,
            fill: 'transparent',
            left: 0,
            originX: 'right',
            originY: 'center',
            scaleX: scale,
            scaleY: scale,
            top: 0,
            hoverCursor: 'pointer',
            selectable: false,
        });

        this.triggerOn = () => {
            console.log('triggeron');
            this.set('stroke', triggerColor);
            this.set('fill', triggerColor);
            // this.set("strokeWidth", triggerStrokeWidth);
        };

        this.triggerOff = () => {
            console.log('triggeroff');
            this.set('stroke', alarmIconColor);
            // this.set("strokeWidth", noTriggerAlarmStrokeWidth);
            this.set('fill', 'transparent');
        };
    }
}
