import { ActionTypes } from "../actionTypes";
import { ShowAlarmModelPayload } from "./types";

export function showAlarmModal(payload: ShowAlarmModelPayload) {
    return <const>{
        type: ActionTypes.SHOW_ALARM_MODAL,
        payload,
    };
}

export function hideAlarmModal() {
    return <const>{
        type: ActionTypes.HIDE_ALARM_MODAL,
    };
}
