import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, Button, Checkbox, TabControl, TabItem } from '@abb/abb-common-ux-react';

import './style.scss';
import { assetSelectedToDelete, deleteFromCanvas } from '../../../../utils/helpers';
import MessageModal from '../../../../routes/Functions/MessageModal';
import { CONFIRMATION_BUTTON } from '../../../../utils/constants/uiConstants';
import { handleOverallSeverityFuncDelete } from '../../../../store/configurationTool/saga';
import { ShowMessageDispatchProps } from '../../../Functions';
import ObjectTypeDetail from '../../../../transformers/AssetType/ObjectType';
import { TableData } from '../../../../model/TableController';
import { DeleteModalTabsType, DELETE_MODAL_TABS } from '../../../../utils/constants/appConstants';
import { FunctionTypeDetailsWithMappings } from '../../../../model/TableController/types';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';

interface DeleteTableAssetsModalProps {
    showDeleteTableAssetsModal: boolean;
    onClose: () => void;
    tableData: TableData;
    updateConfigurationToolJson?: (tableData: any) => void;
    overallSeverityFunctionId?: string;
    updateOverAllSeverityFunctionStatus: (val: boolean) => void;
    updateOverallSeverityFunctionId: (val: string) => void;
}

const DeleteTableAssetsModal = (props: DeleteTableAssetsModalProps & ShowMessageDispatchProps) => {
    const {
        showDeleteTableAssetsModal,
        onClose,
        tableData,
        overallSeverityFunctionId,
        updateConfigurationToolJson,
        updateOverallSeverityFunctionId,
        updateOverAllSeverityFunctionStatus,
    } = props;
    const [assetsArray, setAssetsArray] = useState([] as any);
    const [deleteModal, setDeleteModal] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabNavList] = useState([
        DELETE_MODAL_TABS.OBJECTS,
        DELETE_MODAL_TABS.FUNCTIONS,
        DELETE_MODAL_TABS.TYPES,
    ]);
    const [itemList, setItemList] = useState(
        [] as (FunctionTypeDetailsWithMappings | ObjectTypeDetail)[]
    );

    useEffect(() => {
        handleItemList(activeTabIndex);
    }, []);
    const addRemoveAssets = (asset: any, assetsArray: any) => {
        if (assetSelectedToDelete(assetsArray, asset) === -1) {
            setAssetsArray([...assetsArray, asset]);
        } else {
            let updatedAssetsArray = _.cloneDeep(assetsArray);
            updatedAssetsArray = updatedAssetsArray.filter((item: any, index: number) => {
                return item.nodeId !== asset.nodeId;
            });
            setAssetsArray([...updatedAssetsArray]);
        }
    };

    const handleDelete = (data: any) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            let updatedAssets = [...assetsArray];
            const isAllConditionFuncSelected = handleOverallSeverityFuncDelete(
                [...tableData.functionTypeDetails, ...tableData.objectTypeDetails],
                assetsArray
            );
            if (isAllConditionFuncSelected) {
                const severityFunc = tableData.functionTypeDetails.find(
                    (item: any) => item.nodeId === overallSeverityFunctionId
                );
                if (severityFunc) {
                    updatedAssets.push(severityFunc);
                    updateOverAllSeverityFunctionStatus(false);
                    updateOverallSeverityFunctionId('');
                }
            }
            const updatedTableData = deleteFromCanvas(updatedAssets, tableData);
            updateConfigurationToolJson &&
                updateConfigurationToolJson({ tableData: updatedTableData });

            onClose();
        }
        setDeleteModal(false);
    };

    const handleOnTabChange = (oldIndex: number, newIndex: number) => {
        setActiveTabIndex(newIndex);
        handleItemList(newIndex);
    };

    const handleItemList = useCallback(
        (activeTabIndex: number) => {
            if (tabNavList[activeTabIndex] === DELETE_MODAL_TABS.OBJECTS) {
                const objectTypeDetails = tableData.objectTypeDetails.filter(
                    (item) => !item.isReferencedType
                );
                setItemList([...objectTypeDetails]);
            } else if (tabNavList[activeTabIndex] === DELETE_MODAL_TABS.FUNCTIONS) {
                setItemList([...tableData.functionTypeDetails]);
            } else {
                const typesUsed = tableData.objectTypeDetails.filter(
                    (item) => item.isReferencedType
                );
                setItemList([...typesUsed]);
            }
        },
        [activeTabIndex]
    );

    return (
        <Dialog
            isOpen={showDeleteTableAssetsModal}
            showCloseButton={true}
            contentClassName="delete-table-asset-content"
            onClose={onClose}
            dimBackground={true}
            title="Select Objects,Models and Functions to Delete"
        >
            <div className="wrapper-tabs">
                <TabControl
                    type="primary"
                    className="tab-list"
                    activeTab={activeTabIndex}
                    onTabChange={handleOnTabChange}
                >
                    {tabNavList.map((list: string, listIndex: number) => {
                        return <TabItem key={listIndex} title={list} />;
                    })}
                </TabControl>

                <div className="item-list">
                    {itemList.length ? (
                        itemList.map(
                            (
                                item: FunctionTypeDetailsWithMappings | ObjectTypeDetail,
                                index: number
                            ) => {
                                return (
                                    <div key={item.nodeId} className="asset-selection-part">
                                        <Checkbox
                                            sizeClass="small"
                                            value={assetSelectedToDelete(assetsArray, item) !== -1}
                                            disabled={item.nodeId === overallSeverityFunctionId}
                                            onChange={() => addRemoveAssets(item, assetsArray)}
                                        />
                                        <div>{item instanceof FunctionTypeDetail ? item.nodeId : item.assetName}</div>
                                    </div>
                                );
                            }
                        )
                    ) : (
                        <div>No {tabNavList[activeTabIndex]} available!</div>
                    )}
                </div>
            </div>
            <div className="delete-assets-wrapper">
                <Button
                    className="delete-btn"
                    text="Delete"
                    disabled={assetsArray.length === 0}
                    type={'primary-blue'}
                    onClick={() =>
                        props.showMessageModal!({
                            title: 'Delete',
                            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                            rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                            handleStandardButton: handleDelete,
                            warningText: `Do you want to delete selected Objects,Types or Functions?`,
                        })
                    }
                />
            </div>
        </Dialog>
    );
};
export default DeleteTableAssetsModal;
