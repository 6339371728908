import _ from 'lodash';
import React from 'react';
import { useDrop, useDrag } from 'react-dnd';
import './style.scss';
import { DraggableTableRowProps } from '../DraggableTableRow';
import { ALARM_TABLE_DRAGGABLE_ITEMS } from '../dropdownConstants';
import dragDropImages from '../../../assets/icons/leftNavigationToolBox/drag drop icon.svg';
import { DropTableData } from '../../../store/function/types';

export interface DroppableTableRowProps {
    dropItem: DropTableData;
    updateAlarmDragDropTables: (payload: {
        dropTableData: DropTableData[];
        dragTableData: DraggableTableRowProps['dragItem'][];
    }) => void;
    dropTableData: DropTableData[];
    dragTableData: DraggableTableRowProps['dragItem'][];
}

const DISABLED_ALARM_ATTRIBUTES = ['isActive', 'activeTimeStamp', 'previousSeverity'];

const DroppableTableRow = (props: DroppableTableRowProps) => {
    const { dropItem, dropTableData, dragTableData, updateAlarmDragDropTables } = props;
    const [, dropAlarm] = useDrop({
        accept: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DRAGGABLE,
        drop: (data: any, monitor) => {
            let updatedDragTableData: DraggableTableRowProps['dragItem'][] = dragTableData;
            if (!dropItem.mappedAlarm) {
                const updatedDropTableData: DroppableTableRowProps['dropItem'][] = _.cloneDeep(
                    dropTableData
                ).map((dropTableItem: DroppableTableRowProps['dropItem']) => {
                    if (
                        dropTableItem.label === dropItem.label &&
                        !DISABLED_ALARM_ATTRIBUTES.includes(dropTableItem.label) &&
                        !dropTableItem.mappedAlarm
                    ) {
                        dropTableItem.mappedAlarm = data.data.label;
                        updatedDragTableData = _.cloneDeep(dragTableData).map(
                            (dragTableItem: DraggableTableRowProps['dragItem']) => {
                                if (dragTableItem.label === data.data.label) {
                                    dragTableItem.label = '';
                                }
                                return dragTableItem;
                            }
                        );
                    }
                    return dropTableItem;
                });
                updateAlarmDragDropTables({
                    dropTableData: updatedDropTableData,
                    dragTableData: updatedDragTableData,
                });
            }
        },
    });

    const [{ isDragging }, dargToUnMap] = useDrag({
        item: {
            type: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DROPPABLE,
            data: dropItem,
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    const [, dragToSwap] = useDrop({
        accept: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DROPPABLE,
        drop: (data: any, monitor) => {
            let initailMappedAlarm: any = '';

            const updatedDropTableData: DroppableTableRowProps['dropItem'][] = _.cloneDeep(
                dropTableData
            ).map((dropTableItem: DroppableTableRowProps['dropItem']) => {
                if (
                    dropTableItem.value === dropItem.value &&
                    !DISABLED_ALARM_ATTRIBUTES.includes(dropTableItem.label)
                ) {
                    initailMappedAlarm = dropItem.mappedAlarm;
                    dropItem.mappedAlarm = data.data.mappedAlarm;
                }
                return dropTableItem;
            });
            const updatedDropTableDataForRemoval: DroppableTableRowProps['dropItem'][] =
                _.cloneDeep(dropTableData).map(
                    (dropTableItem: DroppableTableRowProps['dropItem']) => {
                        if (dropTableItem.value === data.data.value) {
                            if (initailMappedAlarm) {
                                dropTableItem.mappedAlarm = initailMappedAlarm;
                            } else {
                                if (!DISABLED_ALARM_ATTRIBUTES.includes(dropItem.label)) {
                                    delete dropTableItem.mappedAlarm;
                                }
                            }
                        }
                        return dropTableItem;
                    }
                );
            updateAlarmDragDropTables({
                dropTableData: updatedDropTableDataForRemoval,
                dragTableData,
            });
            // }
        },
    });

    return (
        <tr key={dropItem.value}>
            <td
                className="conditon-labels"
                style={
                    DISABLED_ALARM_ATTRIBUTES.includes(dropItem.label)
                        ? { opacity: 0.4 }
                        : { opacity: 1 }
                }
            >
                {dropItem.label}
            </td>
            <td ref={dropAlarm}>
                <div className="swap-drop" ref={dragToSwap}>
                    {dropItem.mappedAlarm && (
                        <div className="initial-drop" ref={dargToUnMap}>
                            <img src={dragDropImages} alt="dragDropImages" />
                            <div className="initial-drop-label">{dropItem.mappedAlarm}</div>
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};
export default DroppableTableRow;
