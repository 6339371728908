import { fabric } from 'fabric';
import {
    triggerCircleRadius,
    triggerColor,
    triggerStrokeWidth,
    noTriggerStrokeWidth,
    canvasIOTriggerColor,
} from './UiConfig';

interface Params {
    radius?: number;
    triggered: boolean;
}

class FabricIOTrigger extends fabric.Rect {
    triggerOn: () => void;
    triggerOff: () => void;

    constructor(options: Params) {
        super({
            // radius: options.radius || triggerCircleRadius,
            width: options.radius || triggerCircleRadius,
            height: options.radius ? 2 * options.radius : 2 * triggerCircleRadius,
            stroke: options.triggered ? triggerColor : canvasIOTriggerColor,
            // strokeWidth: options.triggered ? triggerStrokeWidth : noTriggerStrokeWidth,
            strokeWidth: noTriggerStrokeWidth,
            fill: options.triggered ? triggerColor : 'white',
            originX: 'center',
            originY: 'center',
            hoverCursor: 'pointer',
            moveCursor: 'pointer',
            name: 'output',
            selectable: false,
            absolutePositioned: true,
        });

        this.triggerOn = () => {
            this.set('stroke', triggerColor);
            // this.set('strokeWidth', triggerStrokeWidth);
            this.set('fill', triggerColor);
        };

        this.triggerOff = () => {
            this.set('stroke', canvasIOTriggerColor);
            this.set('fill', 'white');
            // this.set('strokeWidth', noTriggerStrokeWidth);
        };
    }
}

export class FabricInputTrigger extends FabricIOTrigger {
    constructor(options: Params) {
        super(options);
    }
}

export class FabricOutputPublish extends FabricIOTrigger {
    constructor(options: Params) {
        super(options);
    }
}
