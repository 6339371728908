import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Input, Button } from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../store";
import { DEL_FUNCTION_MSG } from "../../utils/constants/appConstants";
import {
    CreateFunctionAssetDetails,
    renameLibraryRequest,
} from "../../store/function/action";
import { LibraryAndNodePayloadWithoutNodes } from "../../store/function/types";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../utils/constants/appConstants";
import { Dispatch } from "redux";
import CustomABBInput from "../CustomABBInput";

interface GetPasswordForLibrariesProps {
    submitBtnText: string;
    requiredActionAfterSubmit?: (
        typeDetails: CreateFunctionAssetDetails
    ) => void;
    submitBtnAction: (
        libraryDetails: LibraryAndNodePayloadWithoutNodes
    ) => void;
    libraryDetails: LibraryAndNodePayloadWithoutNodes;
    resetGlobalLibraryError: () => void;
}
const GetPasswordForLibraries = (
    props: GetPasswordForLibrariesProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        typeDetails,
        submitBtnText,
        renameLoading,
        libraryDetails,
        submitBtnAction,
        libraryGlobalError,
        decryptedLibraryDetails,
        resetGlobalLibraryError,
        requiredActionAfterSubmit,
        currentLibraryToDragAndDrop,
        addingFunctionsToExistingLibrary,
        loadingStateForDecryptedFunction,
    } = props;

    useEffect(() => {
        if (
            decryptedLibraryDetails.password &&
            typeDetails &&
            requiredActionAfterSubmit
        ) {
            requiredActionAfterSubmit(typeDetails);
        }
    }, [decryptedLibraryDetails, typeDetails, requiredActionAfterSubmit]);

    useEffect(() => {
        if(!props.renameLoading && props.isLibraryExist) {
            setErrorMessage("Library name already exists");
        }

    },[props.renameLoading,props.isLibraryExist])

    const [updatedLibraryDetails, setUpdatedLibraryDetails] = useState({
        ...libraryDetails,
    });
    const [passwordType, setPasswordType] = useState(true);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const handleOnChange = useCallback(
        (password: string) => {
            handleNameChange(updatedLibraryDetails.root);
            setUpdatedLibraryDetails({
                ...updatedLibraryDetails,
                password,
            });
        },
        [updatedLibraryDetails]
    );

    const handleNameChange = useCallback(
        (name: string) => {
            if (name === libraryDetails.root) {
                setErrorMessage("Library name already exists");
            } else if (name.length === 0) {
                setErrorMessage("Library name can't be empty");
            } else {
                setErrorMessage(null);
            }

            setUpdatedLibraryDetails({
                ...updatedLibraryDetails,
                root: name,
            });
        },
        [updatedLibraryDetails]
    );

    return (
        <React.Fragment>
            <React.Fragment key={libraryDetails.id}>
                <CustomABBInput
                    label={"New Library Name"}
                    dataType={"text"}
                    instantValidation={true}
                    value={updatedLibraryDetails.root}
                    onValueChange={(value) => {
                        if (libraryGlobalError.length > 0) {
                            resetGlobalLibraryError();
                        }
                        handleNameChange(value);
                    }}
                    validator={() =>
                        errorMessage
                            ? {
                                  valid: false,
                                  text: errorMessage,
                              }
                            : { valid: true, text: "OK!" }
                    }
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                />
                {libraryDetails.isIPProtected && (
                    <CustomABBInput
                        label={"Password"}
                        dataType={passwordType ? "password" : "text"}
                        value={updatedLibraryDetails.password}
                        onValueChange={(value) => {
                            if (libraryGlobalError.length > 0) {
                                resetGlobalLibraryError();
                            }

                            handleOnChange(value);
                        }}
                        icon={passwordType ? "abb/view" : "abb/hide"}
                        onIconClick={() => {
                            if (
                                updatedLibraryDetails.password.length > 0 ||
                                passwordType === false
                            ) {
                                togglePasswordType();
                            }
                        }}
                        validator={() =>
                            libraryGlobalError.length > 0
                                ? {
                                      valid: false,
                                      text: libraryGlobalError,
                                  }
                                : { valid: true, text: "OK!" }
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                )}
            </React.Fragment>

            <div className="submit-button">
                {libraryDetails.isIPProtected && (
                    <div className="help-msg-wrapper">
                        <p>
                            <span className="note">{NOTE_MESSAGE}</span>
                            <span className="admin_help_message">
                                {ADMIN_HELP_MESSAGE}
                            </span>
                        </p>
                    </div>
                )}
                <Button
                    type="primary-blue"
                    text={
                        loadingStateForDecryptedFunction ||
                        addingFunctionsToExistingLibrary
                            ? "Loading..."
                            : submitBtnText
                    }
                    disabled={
                        renameLoading ||
                        updatedLibraryDetails.root.length === 0 ||
                        (updatedLibraryDetails.isIPProtected &&
                            updatedLibraryDetails.password.length === 0) ||
                        libraryGlobalError.length > 0 ||
                        libraryDetails.root === updatedLibraryDetails.root
                    }
                    onClick={() => {
                        props.renameLibrary(updatedLibraryDetails);
                    }}
                    isLoading={
                        loadingStateForDecryptedFunction ||
                        addingFunctionsToExistingLibrary ||
                        renameLoading
                    }
                />
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        loadingStateForDecryptedFunction:
            state.functions.loadingStateForDecryptedFunction,
        typeDetails: state.functions.typeDetails,
        decryptedLibraryDetails: state.functions.decryptedLibraryDetails,
        addingFunctionsToExistingLibrary:
            state.functions.addingFunctionsToExistingLibrary,
        libraryGlobalError: state.functions.libraryGlobalError,
        currentLibraryToDragAndDrop:
            state.functions.currentLibraryToDragAndDrop,
        renameLoading: state.functions.isLoading,
        isLibraryExist: state.functions.isLibraryExist,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        renameLibrary: (libraryDetail: LibraryAndNodePayloadWithoutNodes) => {
            const { id, libraryVersion, password, root } = libraryDetail;
            dispatch(renameLibraryRequest(id, libraryVersion, password, root));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetPasswordForLibraries);
