import { IOApiOptions } from '../types';
import { dataTypeConverterFromJson } from '../../utils/dataTypeConverter';
import EventTypeDetail from '../EventType';
import {
    generateEventInputId,
    generateEventInputPath,
    generateEventId,
} from './eventsIOIdGenerator';
import { AlarmEventType } from '../../../components/Fabric/types';

export function eventInputConverter({
    keys,
    object,
    refPath,
    nodeId,
}: {
    keys: string[];
    object: IOApiOptions;
    refPath: 'inputs';
    nodeId: string;
}) {
    const data: EventTypeDetail['inputs'] = [];
    if (Array.isArray(keys) && typeof object === 'object') {
        keys.forEach((key) => {
            const dataType = object[key].dataType;
            const items = object[key].items;
            if (dataType) {
                const path = generateEventInputPath({
                    nodeId,
                    ioType: refPath,
                    ioName: key,
                });

                const temp: EventTypeDetail['inputs'][0] = {
                    name: key,
                    id: generateEventInputId({
                        nodeId,
                        ioType: refPath,
                        ioName: key,
                    }),
                    path,
                    dataType: dataTypeConverterFromJson({ dataType, items }),
                    connected: false,
                };
                data.push(temp);
            }
        });
    }
    return data;
}

export function eventOutputConverter({
    eventId,
    modelId,
    nodeId,
    outputObj,
    eventType,
}: {
    eventId: string;
    modelId: string;
    nodeId: string;
    outputObj: IOApiOptions;
    eventType: AlarmEventType;
}) {
    let outputs: EventTypeDetail['outputs'] = [];
    Object.keys(outputObj).forEach((outputName) => {
        const dataType = outputObj[outputName].dataType;
        const items = outputObj[outputName].items;
        const isPublished = !!outputObj[outputName].link;
        const eventIdWithName = outputObj[outputName].eventId;
        const isEventProperty = eventIdWithName ? false : true;
        const output: EventTypeDetail['outputs'][0] = {
            name: outputName,
            id: generateEventId({
                nodeId,
                eventId: eventId,
                modelId: modelId,
                ioName: outputName,
                isEventProperty,
                eventType,
            }),
            path: outputName,
            dataType: dataTypeConverterFromJson({ dataType, items }),
            connected: false,
            isPublished: isPublished,
        };
        if (eventIdWithName) {
            output.alarmId = eventIdWithName;
        }

        outputs.push(output);
    });

    return outputs;
}
