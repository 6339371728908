import React, { useState, Fragment, useEffect, useMemo } from "react";
import classnames from "classnames";
import * as _ from "lodash";
import "./style.scss";
import {
    If,
    optionToViewDecryptedFunction,
    findTypeDetailsFromLibrariesAndFunctionsList,
    convertFuncDetailsToFuncType,
} from "./helper";
import {
    Inputs,
    Endpoint,
    Output,
    Condition,
    ModelId,
    Calculation,
    Alarm,
    FUNCTION_MODAL,
} from "./constants";
import ConditionType from "./FunctionTypes/ConditionType";
import EndPoint from "./FunctionTypes/EndPoint";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showModal } from "../../store/modal/action";
import {
    createNewFunction,
    CreateFunctionAssetDetails,
    handleFunctionDelete,
    handleFunctionMode,
    handlePopoverOpen,
    getDecryptedFunctionTypeRequest,
    getSelectedFunctionType,
    setGlobalLibraryError,
    getDecryptedFunctionTypeSuccess,
    setDeletingLibraryDetails,
    handlePopoverInfo,
    handleSaveAsMessage,
    getfunctionEndPointList,
} from "../../store/function/action";
import { AssetsInfo, functionType } from "./type";
import {
    TabControl,
    TabItem,
    WithPopup,
    Popup,
    Button,
    Icon,
    WithTooltip,
    Tooltip,
} from "@abb/abb-common-ux-react";
import {
    LOADER_TYPE,
    LOADER_SIZE,
    TABLE_IO_TYPE,
    FUNCTION_MODE,
    FUNCTION_SAVE_TYPE,
    GENERIC_FUNCTIONS,
} from "../../utils/constants/appConstants";
import Loader from "../../components/Loader";

import { StoreState } from "../../store";
import ExportDialog from "../../components/ExportDialog";
import { EditDeletePopup } from "./Popups/EditDeletePopup";
import TableIOType from "./FunctionTypes/TableIOType";
import {
    FunctionMode,
    LibraryAndNodePayloadWithoutNodes,
    IGetSelectedFunctionPayload,
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
    IEndPointList,
} from "../../store/function/types";
import CalculationLogicFunctionType from "./CalculationLogicFunctionType";
import {
    getFunctionVersions,
    clearSelectedFunctions,
    showImportModal,
    removedFromDropDown,
} from "../../store/exportAsset/actions";
import AlarmFunctionType from "./AlarmFunctionType";
import SpinnerModal from "../../components/SpinnerModal";
import SummaryModal from "../../components/ExportDialog/SummaryModal";
import { sce, AbilityService } from "sce-engg-model-19.09";
import IF from "../../components/shared/Condition";
import EditFunctionInfo from "./EditFunction/EditFunctionInfo";
import CreateLibraryPopup from "./CreateLibraryPopup";
import { ShowMessageDispatchProps } from ".";
import {
    GetFuncVersionPayload,
    ImportModalPayload,
} from "../../store/exportAsset/types";
import { getSelectedLibrary, isGlobalTenantType } from "../../utils/helpers";
import GetPasswordForLibraries from "../../components/GetPasswordForLibraries";
import SingleLibraryExport from "../../components/ExportDialog/SingleLibraryExport";
import CustomTooltip from "../../components/CustomTooltip";
import { TOOLTIP_MESSAGES } from "../../utils/constants/uiConstants";
import Flex from "../../components/shared/Flex";

interface AppProps {
    assetsDetails?: CreateFunctionAssetDetails;
    handleEdit: (data: boolean) => void;
    handleAPIStatus: (data: boolean) => void;
}

function FunctionConfiguration(
    props: AppProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps> &
        ShowMessageDispatchProps
) {
    console.log("assetsDetails :", props.assetsDetails);
    const {
        isLoading,
        isPopoverOpen,
        showImportModal,
        showSpinnerModal,
        summaryModalDetails,
        handlePopoverOpen,
        getFunctionVersions,
        clearSelectedFunctions,
        showCreateLibraryModal,
        closeModalForLibraryExport,
        activeFunction,
        activeLibId,
        libraryAndFunctionsList,
        showModalToExportSingleLibrary,
        getFunctionEndPointLists,
        endpointUrlLists,
    } = props;
    const [tabSelected, updateTabSelected] = useState(
        functionType.Input as any
    );
    const [functionProperties, updateFunctionProperties] = useState(
        props.assetsDetails && props.assetsDetails.properties
    );
    const toggleInfo = () => props.handlePopoverInfo(!props.isPopoverInfoOpen);

    useEffect(() => {
        updateFunctionProperties(
            props.assetsDetails && props.assetsDetails.properties
        );
        getFunctionEndPointLists();
    }, [props.assetsDetails]);

    const toggle = () => handlePopoverOpen(!isPopoverOpen);

    let tabNavList: any = [];

    if (_.has(functionProperties, "inputs")) {
        tabNavList.push(functionType.Input);
    }

    if (_.has(functionProperties, "inputs.conditions")) {
        tabNavList.push(functionType.Condition);
        if (_.has(functionProperties, "inputs.alarmMapping")) {
            tabNavList.push(functionType.Alarm);
        }
    }

    if (_.has(functionProperties, "inputs.calculations")) {
        tabNavList.push(functionType.Calculation);
    }

    if (_.has(functionProperties, "outputs")) {
        tabNavList.push(functionType.Output);
    }

    if (_.has(functionProperties, "settings")) {
        tabNavList.push(functionType.Endpoint);
    }

    const commonProps = {
        activeTab: _.indexOf(tabNavList, tabSelected),
        onTabChange: (oldIndex: any, newIndex: number) =>
            updateTabSelected(tabNavList[newIndex]),
    };

    const isCurrentLibraryEncrypted = useMemo(() => {
        let isIPProtected = false;
        if (
            activeLibId !== GENERIC_FUNCTIONS &&
            !props.decryptedLibraryDetails.password
        ) {
            const currentLibraryIndex = _.findIndex(
                libraryAndFunctionsList,
                (libDetail) => libDetail.id === activeLibId
            );

            if (
                currentLibraryIndex !== -1 &&
                libraryAndFunctionsList[currentLibraryIndex]
            ) {
                isIPProtected =
                    libraryAndFunctionsList[currentLibraryIndex].isIPProtected;
            }
        }
        return isIPProtected;
    }, [
        activeLibId,
        libraryAndFunctionsList,
        props.decryptedLibraryDetails.password,
    ]);

    const currentLibraryDetail = useMemo(() => {
        return libraryAndFunctionsList.find(
            (item) => item.id === activeLibId || item.id === null
        );
    }, [libraryAndFunctionsList, activeLibId]);

    const hasSameOrigin = useMemo(() => {
        if (currentLibraryDetail && activeLibId !== GENERIC_FUNCTIONS) {
            return currentLibraryDetail.origin === AbilityService.tenantId;
        } else {
            return true;
        }
    }, [currentLibraryDetail]);

    const deleteFunction = (e: React.MouseEvent<HTMLDivElement>) => {
        toggle();
        if (props.assetsDetails) {
            props.handleFunctionDelete({
                modelId: ModelId,
                typeId: props.assetsDetails.typeId,
                functionName: props.assetsDetails.name,
                tags: props.assetsDetails.tags,
            });
        }
    };

    return (
        <div className="wrapper-function-configuration1 function-route">
            {isLoading && (
                <div className="center-div1">
                    <Loader
                        type={LOADER_TYPE.RADIAL}
                        sizeClass={LOADER_SIZE.LARGE}
                    />
                </div>
            )}

            <div className="headerFunction editHeaderPopUp">
                <Fragment>
                    {props.assetsDetails && props.assetsDetails.name && (
                        <div className="align-center-baseline">
                            <Fragment>
                                <WithPopup>
                                    <div>
                                        <CustomTooltip
                                            text={
                                                TOOLTIP_MESSAGES.ASSET_DETAILS
                                            }
                                            position="bottom"
                                        >
                                            <Icon
                                                name="abb/information-circle-2"
                                                sizeClass="small"
                                                style={{
                                                    cursor: "pointer",
                                                    paddingRight: "5px",
                                                    marginTop: "2px",
                                                    // position:'relative',
                                                    top: "3px",
                                                }}
                                            />
                                        </CustomTooltip>
                                    </div>
                                    <Popup
                                        trigger="click"
                                        position="right center"
                                        className="edit-info"
                                        showCloseButton={true}
                                        closeOnLostFocus={true}
                                        closeOnEscape={true}
                                        onOpen={() => toggleInfo()}
                                        onClose={() => toggleInfo()}
                                        render={(renderProps: any) => (
                                            <div>
                                                <EditFunctionInfo
                                                    {...renderProps}
                                                    toggle={toggleInfo}
                                                    assetsDetails={
                                                        props.assetsInfo
                                                    }
                                                    isViewReadOnly={true}
                                                    endpointUrlLists={
                                                        endpointUrlLists
                                                    }
                                                />
                                            </div>
                                        )}
                                    />
                                </WithPopup>
                                <b>
                                    {props.assetsDetails &&
                                        `${props.assetsDetails.name} ${props.assetsDetails.version}`}
                                </b>

                                {(hasSameOrigin ||
                                    (!hasSameOrigin &&
                                        currentLibraryDetail &&
                                        currentLibraryDetail.isIPProtected)) && (
                                    <WithPopup>
                                        <div
                                            className={
                                                props.gettingLibraryAndNodes
                                                    ? "save-delete-dots-disabled"
                                                    : "save-delete-dots"
                                            }
                                        >
                                            <CustomTooltip
                                                position="bottom"
                                                text={TOOLTIP_MESSAGES.ACTIONS}
                                            >
                                                <span>
                                                    <b>...</b>
                                                </span>
                                            </CustomTooltip>
                                        </div>
                                        <Popup
                                            trigger="click"
                                            position="bottom center"
                                            className={"save-delete"}
                                            closeOnLostFocus={true}
                                            closeOnEscape={true}
                                            disabled={
                                                props.gettingLibraryAndNodes
                                            }
                                            onOpen={() =>
                                                handlePopoverOpen(true)
                                            }
                                            onClose={() =>
                                                handlePopoverOpen(false)
                                            }
                                            render={(nextProps: any) => (
                                                <EditDeletePopup
                                                    isGenericFunction={
                                                        currentLibraryDetail &&
                                                        currentLibraryDetail.id ===
                                                            null
                                                    }
                                                    isGlobal={isGlobalTenantType(
                                                        (props.assetsInfo &&
                                                            props.assetsInfo
                                                                .tags) ||
                                                            [],
                                                        (props.assetsInfo &&
                                                            props.assetsInfo
                                                                .typeId) ||
                                                            ""
                                                    )}
                                                    hasSameOrigin={
                                                        hasSameOrigin
                                                    }
                                                    hasSave={props.hasSave}
                                                    hasDelete={props.hasDelete}
                                                    hasEdit={props.hasEdit}
                                                    hasExport={props.hasExport}
                                                    showDecryptedFunction={optionToViewDecryptedFunction(
                                                        libraryAndFunctionsList,
                                                        props.assetsDetails
                                                    )}
                                                    libraryModeTitle={
                                                        !props
                                                            .decryptedLibraryDetails
                                                            .password
                                                            ? "Decrypted"
                                                            : "Encrypted"
                                                    }
                                                    onClickCreate={(
                                                        mode: "EDIT" | "VIEW"
                                                    ) => {
                                                        if (
                                                            props.assetsDetails
                                                        ) {
                                                            let libraryReferences =
                                                                findTypeDetailsFromLibrariesAndFunctionsList(
                                                                    libraryAndFunctionsList,
                                                                    props.assetsDetails
                                                                ).libraryDetailsOfCurrentFunction;

                                                            if (
                                                                libraryReferences.isIPProtected ===
                                                                    true &&
                                                                !props
                                                                    .decryptedLibraryDetails
                                                                    .password
                                                            ) {
                                                                props.showGetPasswordForLibraries(
                                                                    {
                                                                        libraryDetails:
                                                                            libraryReferences,
                                                                        requiredActionAfterSubmit:
                                                                            (
                                                                                typeDetails: CreateFunctionAssetDetails
                                                                            ) => {
                                                                                props.addToCreateFunction(
                                                                                    {
                                                                                        assetsDetails:
                                                                                            typeDetails,
                                                                                    }
                                                                                );
                                                                                mode ===
                                                                                    "EDIT" &&
                                                                                    props.handleEdit(
                                                                                        true
                                                                                    );
                                                                                nextProps.close();
                                                                            },
                                                                        functions:
                                                                            [
                                                                                props.assetsDetails,
                                                                            ],
                                                                        submitBtnText:
                                                                            mode ===
                                                                            "EDIT"
                                                                                ? "Edit"
                                                                                : "Unlock",
                                                                    }
                                                                );
                                                            } else {
                                                                props.addToCreateFunction(
                                                                    {
                                                                        assetsDetails:
                                                                            props.assetsDetails,
                                                                    }
                                                                );
                                                                props.handleEdit(
                                                                    true
                                                                );
                                                                nextProps.close();
                                                            }
                                                        }
                                                    }}
                                                    onClickViewEncrypted={() => {
                                                        if (
                                                            props.assetsDetails
                                                        ) {
                                                            props.getSelectedFunctionType(
                                                                {
                                                                    selectedFunction:
                                                                        convertFuncDetailsToFuncType(
                                                                            props.assetsDetails
                                                                        ),
                                                                    activeLibId,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    onClickSaveAs={(e: any) => {
                                                        if (props.assetsInfo) {
                                                            const payload: {
                                                                assetsInfo: AssetsInfo;
                                                                functionsDetailsList: CreateFunctionAssetDetails[];
                                                                libraryDetails: LibraryAndNodePayloadWithoutNodes[];
                                                            } = {
                                                                assetsInfo:
                                                                    props.assetsInfo,
                                                                functionsDetailsList:
                                                                    [
                                                                        props.typeDetails as CreateFunctionAssetDetails,
                                                                    ],
                                                                libraryDetails:
                                                                    [
                                                                        getSelectedLibrary(
                                                                            libraryAndFunctionsList,
                                                                            (
                                                                                props.typeDetails as CreateFunctionAssetDetails
                                                                            )
                                                                                .typeId,
                                                                            (
                                                                                props.typeDetails as CreateFunctionAssetDetails
                                                                            )
                                                                                .version
                                                                        ),
                                                                    ],
                                                            };
                                                            props.showSaveAsModal(
                                                                payload
                                                            );
                                                        }
                                                        nextProps.close();
                                                    }}
                                                    onClickDelete={(e: any) => {
                                                        deleteFunction(e);
                                                        nextProps.close();
                                                    }}
                                                    showMessageModal={
                                                        props.showMessageModal
                                                    }
                                                    onExport={(
                                                        val: boolean
                                                    ) => {
                                                        // if (activeLibId === GENERIC_FUNCTIONS) {
                                                        //     const payload: GetFuncVersionPayload = {
                                                        //         model: activeFunction.model,
                                                        //         typeId: activeFunction.typeId,
                                                        //         name: activeFunction.name,
                                                        //         description: activeFunction.description,
                                                        //         tags: activeFunction.tags,
                                                        //     };
                                                        //     clearSelectedFunctions();
                                                        //     updateImportMode(false);
                                                        //     updateMultiple(false);
                                                        //     updateShowExportDialog(val);

                                                        //     getFunctionVersions(payload);
                                                        // } else {
                                                        let libraryDetail =
                                                            _.cloneDeepWith(
                                                                libraryAndFunctionsList
                                                            )[
                                                                _.findIndex(
                                                                    libraryAndFunctionsList,
                                                                    (
                                                                        libDetail
                                                                    ) =>
                                                                        libDetail.id ===
                                                                            activeLibId ||
                                                                        libDetail.id ===
                                                                            null
                                                                )
                                                            ];

                                                        if (
                                                            props.assetsDetails
                                                        ) {
                                                            const {
                                                                description,
                                                                model,
                                                                typeId,
                                                                tags,
                                                                name,
                                                                version,
                                                            } =
                                                                props.assetsDetails;
                                                            const functionDetail: UpdateActiveFunctionType =
                                                                {
                                                                    description,
                                                                    model,
                                                                    typeId,
                                                                    tags,
                                                                    version,
                                                                    name,
                                                                };

                                                            libraryDetail.nodes =
                                                                [
                                                                    functionDetail,
                                                                ];
                                                        }

                                                        showModalToExportSingleLibrary(
                                                            {
                                                                libraryDetail,
                                                            }
                                                        );
                                                        // }

                                                        nextProps.close();
                                                    }}
                                                    getPasswordForLibrariesToDeleteIPProtectedFunction={() => {
                                                        if (
                                                            props.assetsDetails
                                                        ) {
                                                            props.showGetPasswordForLibrariesToDeleteIPProtectedFunction(
                                                                {
                                                                    libraryDetails:
                                                                        findTypeDetailsFromLibrariesAndFunctionsList(
                                                                            libraryAndFunctionsList,
                                                                            props.assetsDetails
                                                                        )
                                                                            .libraryDetailsOfCurrentFunction,
                                                                    functions: [
                                                                        props.assetsDetails,
                                                                    ],
                                                                    submitBtnText:
                                                                        "Delete",
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </WithPopup>
                                )}
                            </Fragment>
                        </div>
                    )}
                </Fragment>
            </div>
            <div className="view-mode-func-wrapper">
                <div
                    className="headerNavLinkFunction"
                    onClick={() => handlePopoverOpen(false)}
                >
                    <TabControl type="primary" {...commonProps}>
                        {tabNavList.map((list: string, listIndex: number) => {
                            return <TabItem key={listIndex} title={list} />;
                        })}
                    </TabControl>
                    <div
                        className={`propertiesView ${
                            _.has(functionProperties, "inputs.conditions") &&
                            tabSelected === Condition
                                ? "conditions-tab"
                                : ""
                        }`}
                    >
                        {functionProperties && (
                            <Fragment>
                                <If
                                    cond={
                                        _.has(functionProperties, "inputs") &&
                                        tabSelected === Inputs
                                    }
                                >
                                    <TableIOType
                                        ioData={functionProperties.inputs}
                                        columnType={TABLE_IO_TYPE.INPUT}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(
                                            functionProperties,
                                            "inputs.conditions"
                                        ) && tabSelected === Condition
                                    }
                                >
                                    <ConditionType
                                        isEncrypted={isCurrentLibraryEncrypted}
                                        inputs={
                                            functionProperties.inputs.conditions
                                        }
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(
                                            functionProperties,
                                            "inputs.alarmMapping"
                                        ) && tabSelected === Alarm
                                    }
                                >
                                    <AlarmFunctionType />
                                </If>

                                <If
                                    cond={
                                        _.has(
                                            functionProperties,
                                            "inputs.calculations"
                                        ) && tabSelected === Calculation
                                    }
                                >
                                    <CalculationLogicFunctionType
                                        functionMode={FUNCTION_MODE.VIEW}
                                        isEncrypted={isCurrentLibraryEncrypted}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, "outputs") &&
                                        tabSelected === Output
                                    }
                                >
                                    <TableIOType
                                        ioData={functionProperties.outputs}
                                        columnType={TABLE_IO_TYPE.OUTPUT}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, "settings") &&
                                        tabSelected === Endpoint
                                    }
                                >
                                    <EndPoint
                                        settings={functionProperties.settings}
                                    />
                                </If>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        isLoading: state.functions.isLoading,
        isPopoverInfoOpen: state.functions.isPopoverInfoOpen,
        isPopoverOpen: state.functions.isPopoverOpen,
        hasSave: state.functions.functionPermission.hasSave,
        hasEdit: state.functions.functionPermission.hasEdit,
        hasDelete: state.functions.functionPermission.hasDelete,
        hasExport: state.functions.functionPermission.hasExport,
        hasImport: state.functions.functionPermission.hasImport,
        activeFunction: state.functions.currentActiveView.assetsDetails,
        summaryModalDetails: state.exportAsset.summaryModalDetails,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        assetsInfo: state.functions.assetsInfo,
        libraryAndFunctionsList: state.functions.libraryAndFunctionsList,
        decryptedLibraryDetails: state.functions.decryptedLibraryDetails,
        closeModalForLibraryExport: state.modal.closeModal,
        activeLibId: state.functions.activeLibId,
        typeDetails: state.functions.typeDetails,
        endpointUrlLists: state.functions.endPointUrlLists,
        gettingLibraryAndNodes: state.functions.gettingLibraryAndNodes,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handlePopoverInfo: (isOpen: boolean) => {
            dispatch(handlePopoverInfo(isOpen));
        },
        addToCreateFunction: (payload: any) => {
            dispatch(createNewFunction(payload));
        },
        showCreateModel: () => {
            dispatch(
                showModal({
                    //@ts-ignore
                    component: EditFunctionInfo,
                    modalTitle: "Create Function",
                })
            );
        },
        showSaveAsModal: (payload: {
            assetsInfo: AssetsInfo;
            functionsDetailsList: CreateFunctionAssetDetails[];
            libraryDetails: LibraryAndNodePayloadWithoutNodes[];
        }) => {
            if (
                (payload.libraryDetails[0].id === null &&
                    payload.libraryDetails[0].root === null) ||
                payload.libraryDetails[0].isIPProtected === false
            ) {
                if (
                    payload.libraryDetails[0].id &&
                    payload.libraryDetails[0].root &&
                    payload.libraryDetails[0].isIPProtected === false
                ) {
                    dispatch(
                        getDecryptedFunctionTypeSuccess({
                            decryptedLibraryDetails: payload.libraryDetails[0],
                        })
                    );
                }
                dispatch(
                    showModal({
                        component: EditFunctionInfo,
                        modalTitle: "Save As Function",
                        data: {
                            functionSaveType: FUNCTION_SAVE_TYPE.SAVE_AS,
                            assetsDetails: {
                                ...payload.assetsInfo,
                            },
                        },
                    })
                );
            } else {
                dispatch(
                    showModal({
                        component: GetPasswordForLibraries,
                        modalTitle: payload.libraryDetails[0].isIPProtected
                            ? "Enter Library Password"
                            : "Save As Function",
                        data: {
                            submitBtnText: "Save As",
                            submitBtnAction: (
                                assetsInfo: AssetsInfo,
                                functionsDetailsList: CreateFunctionAssetDetails[],
                                libraryDetails: LibraryAndNodePayloadWithoutNodes[]
                            ) => {
                                dispatch(
                                    handleSaveAsMessage({
                                        assetsInfo,
                                        functionsDetailsList,
                                        libraryDetails,
                                    })
                                );
                            },
                            functionsDetailsList: payload.functionsDetailsList,
                            libraryDetails: payload.libraryDetails,
                            resetGlobalLibraryError: () =>
                                dispatch(setGlobalLibraryError({ error: "" })),
                            editOrView: true,
                        },
                        closeModal: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                        customClassName: "change-password-modal",
                    })
                );
            }
        },
        handleFunctionDelete: (payload: {
            modelId: string;
            typeId: string;
            functionName: string;
            tags: string[];
        }) => {
            dispatch(handleFunctionDelete(payload));
        },
        handleFunctionMode: (functionMode: FunctionMode) => {
            dispatch(handleFunctionMode(functionMode));
        },
        handlePopoverOpen: (isOpen: boolean) => {
            dispatch(handlePopoverOpen(isOpen));
        },
        getFunctionVersions: (val: GetFuncVersionPayload) => {
            dispatch(getFunctionVersions(val));
        },
        clearSelectedFunctions: () => {
            dispatch(clearSelectedFunctions());
        },
        showCreateLibraryModal: () => {
            dispatch(
                showModal({
                    component: CreateLibraryPopup,
                    modalTitle: "Create Library",
                    data: {
                        primaryButtonAction: (payload: any) => {},
                    },
                    customClassName:
                        "create-library-container change-password-modal",
                })
            );
        },
        showImportModal: (payload: ImportModalPayload) =>
            dispatch(showImportModal(payload)),
        showGetPasswordForLibraries: (payload: {
            libraryDetails: LibraryAndNodePayloadWithoutNodes;
            requiredActionAfterSubmit?: (
                typeDetails: CreateFunctionAssetDetails
            ) => void;
            functions: CreateFunctionAssetDetails[];
            submitBtnText: "Edit" | "Unlock";
        }) => {
            dispatch(
                showModal({
                    component: GetPasswordForLibraries,
                    modalTitle:
                        _.cloneDeepWith([payload.libraryDetails]).filter(
                            (libDetail) => libDetail.isIPProtected === true
                        ).length === 0
                            ? "Add Function"
                            : "Enter Library Password",
                    data: {
                        submitBtnText: payload.submitBtnText,
                        requiredActionAfterSubmit:
                            payload.requiredActionAfterSubmit,
                        submitBtnAction: (
                            assetsInfo: AssetsInfo,
                            functionsDetailsList: CreateFunctionAssetDetails[],
                            libraryDetails: LibraryAndNodePayloadWithoutNodes[]
                        ) => {
                            dispatch(
                                getDecryptedFunctionTypeRequest({
                                    functionsDetailsList,
                                    libraryDetails,
                                })
                            );
                        },
                        functionsDetailsList: payload.functions,
                        libraryDetails: [payload.libraryDetails],
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                        editOrView: true,
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "change-password-modal",
                })
            );
        },
        showGetPasswordForLibrariesToDeleteIPProtectedFunction: (payload: {
            libraryDetails: LibraryAndNodePayloadWithoutNodes;
            functions: CreateFunctionAssetDetails[];
            submitBtnText: string;
        }) => {
            dispatch(
                showModal({
                    component: GetPasswordForLibraries,
                    modalTitle: "Enter Library Password",
                    data: {
                        submitBtnText: payload.submitBtnText,
                        submitBtnAction: (
                            assetsInfo: AssetsInfo,
                            functionsDetailsList: CreateFunctionAssetDetails[],
                            libraryDetails: LibraryAndNodePayloadWithoutNodes[]
                        ) => {
                            dispatch(
                                setDeletingLibraryDetails({
                                    libraryId: libraryDetails[0].id,
                                    libraryVersion:
                                        libraryDetails[0].libraryVersion,
                                    password: libraryDetails[0].password,
                                })
                            );
                            dispatch(
                                handleFunctionDelete({
                                    modelId: ModelId,
                                    typeId: functionsDetailsList[0].typeId,
                                    functionName: functionsDetailsList[0].name,
                                    tags: functionsDetailsList[0].tags,
                                })
                            );
                        },
                        functionsDetailsList: payload.functions,
                        libraryDetails: [payload.libraryDetails],
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                        editOrView: true,
                        deletingFunction: true,
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "change-password-modal",
                })
            );
        },
        getSelectedFunctionType: (payload: IGetSelectedFunctionPayload) =>
            dispatch(getSelectedFunctionType(payload)),
        showModalToExportSingleLibrary: (payload: {
            libraryDetail: LibraryAndNodePayload;
        }) =>
            dispatch(
                showModal({
                    modalTitle: FUNCTION_MODAL.EXPORT_FUNCTION,
                    component: SingleLibraryExport,
                    customClassName: "change-password-modal",
                    data: {
                        libraryDetail: payload.libraryDetail,
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                        singleExport: true,
                    },
                    closeModal: () => {
                        dispatch(
                            removedFromDropDown({
                                functionVersionDetails: [],
                                selectedFunctionDetails: [],
                            })
                        );
                        dispatch(setGlobalLibraryError({ error: "" }));
                    },
                })
            ),
        getFunctionEndPointLists: () => dispatch(getfunctionEndPointList()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionConfiguration);
