import React from 'react';
import './style.scss';

interface IConfigurationHeader {
    headerText: string;
}
const ConfigurationHeader = (props: IConfigurationHeader) => {
    return <div className="wrapper-config-header">{props.headerText}</div>;
};

export default ConfigurationHeader;
