import { ActionTypes } from "../actionTypes";

export const loginRequest = (payload: { tenantName: string }) => {
    return <const>{
        type: ActionTypes.LOGIN_REQUEST,
        payload: payload,
    };
};

export const redirectLogin = () => {
    return <const>{
        type: ActionTypes.REDIRECT_LOGIN,
    };
};

export const handleUserLogin = () => {
    return <const>{
        type: ActionTypes.HANDLE_USER_LOGIN,
    };
};
export const updateIsLoggedIn = (isLoggedIn: boolean) => {
    return <const>{
        type: ActionTypes.UPDATE_IS_LOGGED_IN,
        payload: isLoggedIn,
    };
};
export const handleLoginLoading = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_LOGIN_LOADING,
        payload: isLoading,
    };
};

export const initialiseLoginProvider = () => {
    return <const>{
        type: ActionTypes.INITIALISE_LOGIN_PROVIDER,
    };
};
export const handleLoadingStatus = (loadingStatus: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_LOADING_STATUS,
        payload: loadingStatus,
    };
};

export const handleErrorLogin = (errorSatus: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_ERROR_LOGIN,
        payload: errorSatus,
    };
};
