import _ from 'lodash';
import { Checkbox, Icon, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { checkValidityForMonitorsInput } from '../../../utils/helpers';
import SupportedConditionMonitorItem from './SupportedConditionMonitorItem';
import { ISupportedModelItem } from '../../../store/supportedConditionMonitors/types';
import { SUPPORTED_CONDITION_MONITORS_INFO } from '../../../utils/constants/appConstants';
import AddModelIdInput from './AddModelIdInput';

import '../style.scss';

interface SupportedConditionMonitorProps {
    checkWhetherAnyItemIsEmpty: boolean;
    conditionMonitorListToRender: ISupportedModelItem[];
    addedSupportedConditionMonitorModels: ISupportedModelItem[];
    setAddedSupportedConditionMonitorModels: Dispatch<SetStateAction<ISupportedModelItem[]>>;
    setSupportedConditionMonitorList: Dispatch<SetStateAction<ISupportedModelItem[]>>;
    handleCheckboxChange: (index: number) => void;
    handleCheckAll: () => void;
    isAllSelectedCheckbox: boolean;
    // handleTagsCheckboxChange: (index: number) => void;
    globalErrorForMonitorModels: string;
    resetGlobalMonitorModelsError: () => void;
    handleDelete: (modelItem: ISupportedModelItem | undefined) => void;
    areAllAddedConditionMonitorsValid: boolean;
}

const SupportedConditionMonitor = (props: SupportedConditionMonitorProps) => {
    const {
        handleCheckAll,
        handleDelete,
        handleCheckboxChange,
        isAllSelectedCheckbox,
        // handleTagsCheckboxChange,
        checkWhetherAnyItemIsEmpty,
        globalErrorForMonitorModels,
        conditionMonitorListToRender,
        resetGlobalMonitorModelsError,
        areAllAddedConditionMonitorsValid,
        addedSupportedConditionMonitorModels,
        setAddedSupportedConditionMonitorModels,
        setSupportedConditionMonitorList,
    } = props;

    const [invalidInput, setInvalidInput] = useState('');
    const handleOnInputNameChange = useCallback(
        (value, index) => {
            if (globalErrorForMonitorModels.length > 0) {
                resetGlobalMonitorModelsError();
            }
            if (index < addedSupportedConditionMonitorModels.length) {
                let updatedAddedSupportedModels: ISupportedModelItem[] = _.cloneDeepWith(
                    addedSupportedConditionMonitorModels
                );
                updatedAddedSupportedModels[index] = {
                    ...updatedAddedSupportedModels[index],
                    modelName: value,
                };
                setAddedSupportedConditionMonitorModels([...updatedAddedSupportedModels]);
                const updatedList: ISupportedModelItem[] = _.cloneDeepWith(
                    conditionMonitorListToRender
                );

                updatedList[index] = {
                    ...updatedList[index],
                    modelName: value,
                };
                setSupportedConditionMonitorList([...updatedList]);
            }
        },
        [
            addedSupportedConditionMonitorModels,
            setAddedSupportedConditionMonitorModels,
            globalErrorForMonitorModels,
            resetGlobalMonitorModelsError,
            conditionMonitorListToRender,
            setSupportedConditionMonitorList,
        ]
    );

    const handleAddModelId = useCallback(
        (value: string) => {
            if (value.length === 0) {
                setInvalidInput('Model Id cannot be empty');
            } else {
                setInvalidInput('');
            }
            if (
                value.length > 0 &&
                (!checkWhetherAnyItemIsEmpty || addedSupportedConditionMonitorModels.length === 0)
            ) {
                // by default tenant tag should be set as true;
                const newItem: ISupportedModelItem = {
                    modelName: value,
                    tenant: true,
                    isNew: true,
                    isSelected: false,
                    isUpdated: false,
                    isUpdatable: true,
                };
                setAddedSupportedConditionMonitorModels([
                    {
                        modelName: value,
                        tenant: true,
                        isNew: true,
                        isSelected: false,
                        isUpdated: false,
                        isUpdatable: true,
                    },
                    ...addedSupportedConditionMonitorModels,
                ]);
                setSupportedConditionMonitorList([newItem, ...conditionMonitorListToRender]);
            }
        },
        [
            setInvalidInput,
            addedSupportedConditionMonitorModels,
            setAddedSupportedConditionMonitorModels,
            conditionMonitorListToRender,
            setSupportedConditionMonitorList,
        ]
    );

    const validateConditionMonitorInputsOrIds = useCallback(
        (index, value, key) => {
            if (index < addedSupportedConditionMonitorModels.length && value && value.length) {
                return checkValidityForMonitorsInput(
                    index,
                    value,
                    key,
                    conditionMonitorListToRender
                );
            } else {
                return { valid: true, text: 'OK!' };
            }
        },
        [addedSupportedConditionMonitorModels, conditionMonitorListToRender]
    );

    return (
        <React.Fragment>
            <div className="condition-monitor-table-row-border" />

            <thead>
                <tr>
                    <th className="text-uppercase width-20">
                        <span className="wrapper-condition-monitor-table-header-item">
                            Model Id
                            {/* <WithTooltip>
                                <span className="tooltip-item">
                                    <Icon name="abb/information-circle-2" />
                                </span>
                                <Tooltip className="supported-models-tooltip">
                                    {SUPPORTED_CONDITION_MONITORS_INFO}
                                </Tooltip>
                            </WithTooltip> */}
                        </span>
                    </th>
                </tr>
            </thead>
            <div className="condition-monitor-table-heading-border" />

            <AddModelIdInput
                invalidModelIdError={invalidInput}
                areAllAddedConditionMonitorsValid={areAllAddedConditionMonitorsValid}
                handleAddModelId={handleAddModelId}
                clearInvalidInputError={() => setInvalidInput('')}
            />
            <div className="condition-monitor-table-row-border" />

            <Checkbox
                label="Select All"
                sizeClass="small"
                value={isAllSelectedCheckbox}
                onChange={handleCheckAll}
                disabled={conditionMonitorListToRender.length === 1}
            />

            <div className="present-modal-ids">
                {conditionMonitorListToRender.map(
                    (conditionMonitor: ISupportedModelItem, index: number) => {
                        return (
                            <SupportedConditionMonitorItem
                                index={index}
                                handleDelete={handleDelete}
                                conditionMonitor={conditionMonitor}
                                handleCheckboxChange={handleCheckboxChange}
                                handleOnInputNameChange={handleOnInputNameChange}
                                validateConditionMonitorInputsOrIds={
                                    validateConditionMonitorInputsOrIds
                                }
                                addedSupportedConditionMonitorModels={
                                    addedSupportedConditionMonitorModels
                                }
                            />
                        );
                    }
                )}
            </div>
        </React.Fragment>
    );
};

export default SupportedConditionMonitor;
