import React from 'react';
import { Table, Icon } from '@abb/abb-common-ux-react';

interface LibItem {
    libraryId: string;
    libraryName: string;
    libraryVersion: string;
    status: string;
    reason: string;
}
interface IProps {
    libraryDetail: LibItem[];
}

const DependentLibraryTable = (props: IProps) => {
    const { libraryDetail } = props;
    return (
        <div className="wrapper-library-summary">
            <h4 className="title">Library Details</h4>
            <Table>
                <thead>
                    <tr>
                        <th className="width-5 status-icon"></th>
                        <th className="width-25">Name</th>
                        <th className="width-15">Version</th>
                        <th className="width-15">Status</th>
                        <th className="width-40">Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {libraryDetail.map((tableRow) => {
                        const status = (tableRow.status as string).toLowerCase();
                        return (
                            <tr>
                                <td className="width-5">
                                    <Icon
                                        name={
                                            status === 'success' || status === 'imported'
                                                ? 'abb/check-mark-circle-1'
                                                : 'abb/information-circle-1'
                                        }
                                        color={
                                            status === 'success' || status === 'imported'
                                                ? 'green'
                                                : 'blue'
                                        }
                                    />
                                </td>
                                <td className="width-25">{tableRow.libraryName}</td>
                                <td className="width-15">{tableRow.libraryVersion}</td>
                                <td className="width-15">{status}</td>
                                <td className="width-40">{tableRow.reason}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default DependentLibraryTable;
