import _ from 'lodash';
import React, { useMemo, useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { List } from 'react-virtualized';
// import { Collapsible, CollapsibleContainer } from '@abb/abb-common-ux-react';
import FunctionMenu from '../Menu';
import CollapsibleNode from './CollapsibleNode';
import {
    LibraryAndNodePayload,
    LibraryAndNodePayloadWithoutNodes,
    LibraryDraggedDetails,
} from '../../../../store/function/types';
import { DRAG_DROP_FUNCTIONS_FROM_LIBRARIES } from '../../constants';
import { GENERIC_FUNCTIONS } from '../../../../utils/constants/appConstants';
import { FuncLeftNavCollapsibleContainerProps } from '..';
import './style.scss';
import { CreateFunctionAssetDetails } from '../../../../store/function/action';
import { AbilityService } from 'sce-engg-model-19.09';
import Condition from '../../../../components/shared/Condition';
import Collapsible from '../../Collapsible';
import { Icon } from '@abb/abb-common-ux-react';

interface LibraryCollapsibleProps
    extends Omit<
        FuncLeftNavCollapsibleContainerProps,
        'libraryAndFunctionsList' | 'selectedFunctionTypeDetails'
    > {
    item: LibraryAndNodePayload;
    currentLibraryToDragAndDrop: LibraryDraggedDetails[];
    showGetPasswordForLibraries: (payload: {
        libraryDetails: LibraryAndNodePayloadWithoutNodes;
        requiredActionAfterSubmit?: ((typeDetails: CreateFunctionAssetDetails) => void) | undefined;
        functions: CreateFunctionAssetDetails[];
        libraryInWhichFunctionIsDroppedRecently: LibraryDraggedDetails[];
    }) => void;
}

const LibraryCollapsible = (props: LibraryCollapsibleProps) => {
    const {
        activeLibId,
        item,
        handleTypeList,
        showDeleteModal,
        encryptLibrariesModal,
        selectedFunctionTypeId,
        selectedFunctionVersion,
        showGetPasswordForLibraries,
        getExportableFunctionsRequest,
        showModalToExportSingleLibrary,
        showModalToRemoveSingleFunction,
        showAddFunctionsToExistingLibrary,
        showRemoveFunctionsFromExistingLibrary,
        showChangeLibraryPasswordModal,
        currentLibraryToDragAndDrop,
        unlockLibraryModal,
    } = props;

    const [isCollapeOpen, setIsCollapseOpen] = useState(false);
    const [isHovered, setIsOvered] = useState(false);

    const hasSameOrigin = useMemo(() => {
        return item.origin === AbilityService.tenantId;
    }, [item]);

    const [{ canDrop, isOverCurrent }, dropFunctionToAdd] = useDrop({
        accept: DRAG_DROP_FUNCTIONS_FROM_LIBRARIES.DRAG_FUNCTIONS,
        drop: (data: any, monitor) => {
            if (
                data.data.fromLibrary.id !== item.id &&
                data.data.fromLibrary.id === null &&
                item.id !== null
            ) {
                let libraryDetailsWithoutPayload: LibraryAndNodePayloadWithoutNodes = {
                    root: item.root,
                    id: item.id,
                    libraryVersion: item.libraryVersion,
                    isIPProtected: item.isIPProtected,
                    password: '',
                };

                const payload = {
                    libraryDetails: libraryDetailsWithoutPayload,
                    functions: [data.data.function],
                    libraryInWhichFunctionIsDroppedRecently: currentLibraryToDragAndDrop,
                };

                hasSameOrigin && showGetPasswordForLibraries(payload);
            }
        },
        canDrop: (item) => {
            console.log('item :', item);
            return true;
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    });

    const functionsRenderer = ({
        key,
        index,
        isScrolling,
        isVisible,
        style,
    }: {
        key: string;
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: any;
    }) => {
        return (
            <div key={key} style={style} className="edit-left-nav">
                <CollapsibleNode
                    key={item.nodes[index].typeId}
                    data={item.nodes[index]}
                    item={item}
                    showModalToRemoveSingleFunction={showModalToRemoveSingleFunction}
                    selectedFunctionTypeId={selectedFunctionTypeId}
                    selectedFunctionVersion={selectedFunctionVersion}
                    handleTypeList={handleTypeList}
                />
            </div>
        );
    };

    return (
        <div ref={dropFunctionToAdd}>
            <Collapsible
                id={item.id || GENERIC_FUNCTIONS}
                key={item.id && item.root ? item.id : GENERIC_FUNCTIONS}
                // icon="abb/folder"
                title={
                    item.id && item.root
                        ? `${item.root}  ${item.libraryVersion}`
                        : GENERIC_FUNCTIONS
                }
                className={`library_collapsible ${
                    hasSameOrigin && canDrop && item.id && item.root && isOverCurrent
                        ? 'drop-area'
                        : ''
                }`}
                expanded={item.id === activeLibId || (item.id === null && activeLibId === GENERIC_FUNCTIONS)}
                iconsRight={
                    <FunctionMenu
                        libraryDetail={item}
                        showDeleteModal={showDeleteModal}
                        showAddFunctionsToExistingLibrary={showAddFunctionsToExistingLibrary}
                        showRemoveFunctionsFromExistingLibrary={
                            showRemoveFunctionsFromExistingLibrary
                        }
                        encryptLibrariesModal={encryptLibrariesModal}
                        getExportableFunctionsRequest={getExportableFunctionsRequest}
                        showModalToExportSingleLibrary={showModalToExportSingleLibrary}
                        showChangeLibraryPasswordModal={showChangeLibraryPasswordModal}
                        unlockLibraryModal={unlockLibraryModal}
                        showGetPasswordForRename={props.showGetPasswordForRename}
                        showGetFileNameModal={props.showGetFileNameModal}
                    />
                }
            >
                {item.nodes.length > 0 ? (
                    <List
                        width={318}
                        height={item.nodes.length * 32}
                        rowCount={item.nodes.length}
                        rowHeight={32}
                        rowRenderer={functionsRenderer}
                    />
                ) : (
                    <div>No function available</div>
                )}
            </Collapsible>
        </div>
    );
};

export default LibraryCollapsible;
