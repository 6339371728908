export const exportDialogActionTypes = <const>{
    GET_FUNCTION_VERSIONS: 'GET_FUNCTION_VERSIONS',
    GET_FUNCTION_VERSIONS_SUCCESS: 'GET_FUNCTION_VERSIONS_SUCCESS',
    REMOVED_FROM_DROPDOWN: 'REMOVED_FROM_DROPDOWN',
    UPDATE_FUNCTION_LIST_TO_GET_JSON: 'UPDATE_FUNCTION_LIST_TO_GET_JSON',
    GET_FUNCTION_JSON: 'GET_FUNCTION_JSON',
    CLEAR_SELECTED_FUNCTIONS: 'CLEAR_SELECTED_FUNCTIONS',
    IMPORT_FUNCTIONS: 'IMPORT_FUNCTIONS',
    SAVE_MODELS_TO_EXPORT: 'SAVE_MODELS_TO_EXPORT',
    EXPORT_MODELS: 'EXPORT_MODELS',
    SHOW_SUMMARY_MODAL: 'SHOW_SUMMARY_MODAL',
    CLOSE_SUMMARY_MODAL: 'CLOSE_SUMMARY_MODAL',
    COMPUTE_MODELS_API_FOR_EXPORT: 'COMPUTE_MODELS_API_FOR_EXPORT',
    COMPUTE_MODELS_API_FOR_EXPORT_SUCCESS: 'COMPUTE_MODELS_API_FOR_EXPORT_SUCCESS',
    SET_IMPORT_MODAL: 'SET_IMPORT_MODAL',
    IMPORT_MODALS: 'IMPORT_MODALS',
    IMPORT_FOR_SINGLE_TYPE: 'IMPORT_FOR_SINGLE_TYPE',
    UPDATE_SINGLE_IMPORT_FUNC_TYPES: 'UPDATE_SINGLE_IMPORT_FUNC_TYPES',
    HANDLE_FILES_TO_BE_IMPORTED: 'HANDLE_FILES_TO_BE_IMPORTED',
    SET_IMPORT_VALIDATION_STATUS: 'SET_IMPORT_VALIDATION_STATUS',
    HANDLE_FILES_IMPORT: 'HANDLE_FILES_IMPORT',
    SHOW_IMPORT_MODAL: 'SHOW_IMPORT_MODAL',
    HIDE_IMPORT_MODAL: 'HIDE_IMPORT_MODAL',
    LOAD_CHOSEN_FILE_DATA: 'LOAD_CHOSEN_FILE_DATA',
    LOAD_CHOSEN_FILE_DATA_SUCCESS: 'LOAD_CHOSEN_FILE_DATA_SUCCESS',
    ADD_TO_SELECTED_ITEMS_FOR_IMPORT: 'ADD_TO_SELECTED_ITEMS_FOR_IMPORT',
    REMOVE_FROM_SELECTED_ITEMS_FOR_IMPORT: 'REMOVE_FROM_SELECTED_ITEMS_FOR_IMPORT',
    CLEAR_SELECTED_ITEMS_FOR_IMPORT: 'CLEAR_SELECTED_ITEMS_FOR_IMPORT',
    SET_DETAILS_FOR_TYPE_LEVEL_IMPORT: 'SET_DETAILS_FOR_TYPE_LEVEL_IMPORT',
    HANDLE_SELCTALL_FOR_GENERIC_FUNCTION_EXPORT: 'HANDLE_SELCTALL_FOR_GENERIC_FUNCTION_EXPORT',
    HANDLE_TYPES_IMPORT: 'HANDLE_TYPES_IMPORT',
    GET_SELECTED_FUNCTION_TYPE_FOR_EXPORT: 'GET_SELECTED_FUNCTION_TYPE_FOR_EXPORT',
    UPDATE_SELECTED_FUNCTION_TYPE_FOR_EXPORT: 'UPDATE_SELECTED_FUNCTION_TYPE_FOR_EXPORT',
    RESET_SELECTED_FUNCTION_TYPE_FOR_EXPORT: 'RESET_SELECTED_FUNCTION_TYPE_FOR_EXPORT',
    CHANGE_ZIP_NAME_FOR_MODEL_EXPORT: 'CHANGE_ZIP_NAME_FOR_MODEL_EXPORT',
    UPDATE_EXPORTABLE_MODELS_LIST: 'UPDATE_EXPORTABLE_MODELS_LIST',
};
