import { push } from "connected-react-router";
import { put, takeLatest } from "redux-saga/effects";
import { userServices, sce, AbilityService } from "sce-engg-model-19.09";
import {
    handleErrorLogin,
    handleLoadingStatus,
    handleLoginLoading,
    handleUserLogin,
    loginRequest,
    redirectLogin,
    updateIsLoggedIn,
} from "../action";
import { ActionTypes } from "../../actionTypes";
import AppSettings from "../../../services/AppSettings";
import { showNotificationModal } from "../../notificationModal/action";
import {
    ROUTE_PATHNAME,
    NOTIFICATION_MODAL_STATUS,
    IS_LOGIN,
} from "../../../utils/constants/appConstants";
import { abbSDKGetErrorMessage } from "../../../utils/helpers";

function* loginUserRequest(action: ReturnType<typeof loginRequest>) {
    try {
        // AbilityService.setupLogin(
        //     AppSettings.b2cName,
        //     AppSettings.solution,
        //     action.payload.tenantName,
        //     AppSettings.clientId,
        //     AppSettings.redirectUrl,
        //     AppSettings.cacheLocation === "localStorage"
        //         ? "localStorage"
        //         : "sessionStorage"
        // );
        // yield AbilityService.loginRequest(
        //     AppSettings.b2cName,
        //     AppSettings.solution,
        //     AppSettings.clientId,
        //     AppSettings.cacheLocation === "localStorage"
        //         ? "localStorage"
        //         : "sessionStorage"
        // );

        // yield put(push(ROUTE_PATHNAME.HOME));
    } catch (error) {
        console.log("error :", error);
        yield put(handleErrorLogin(true));
        yield put(
            showNotificationModal({
                title: "Authorization Error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
        yield put(push("/login"));
    }
}

function* initialiseLogiProviderRequest() {
    try {
        yield put(handleLoadingStatus(true));
        let errorLogin = false;
        // AbilityService.getMsalAuthProvider(
        //     AppSettings.b2cName,
        //     AppSettings.solution,
        //     AppSettings.clientId,
        //     AppSettings.cacheLocation === "localStorage"
        //         ? "localStorage"
        //         : "sessionStorage"
        // );
        // const updateAccount = yield AbilityService.updateAccount(
        //     AppSettings.b2cName,
        //     AppSettings.solution,
        //     AppSettings.clientId,
        //     AppSettings.cacheLocation === "localStorage"
        //         ? "localStorage"
        //         : "sessionStorage",
        //     (errorMsg) => {
        //         console.log(errorMsg);
        //         errorLogin = true;
        //     }
        // );
        if (AbilityService.isLoggedIn) {
            AppSettings.cacheLocation === "localStorage"
                ? localStorage.setItem(IS_LOGIN, "true")
                : sessionStorage.setItem(IS_LOGIN, "true");
            // const refreshToken = yield AbilityService.getRefreshAccessToken(
            //     AppSettings.b2cName,
            //     AppSettings.solution,
            //     (errorMsg) => {
            //         console.log(errorMsg);
            //         errorLogin = true;
            //     }
            // );
            // yield put(updateIsLoggedIn(true));
        } else {
            // yield put(updateIsLoggedIn(false));
        }
        yield put(handleLoadingStatus(false));
        if (errorLogin) {
            removeTokenFromCacheLocation();
            yield put(push("/login"));
        }
    } catch (error) {
        console.log(error);
        yield put(handleLoadingStatus(false));
        removeTokenFromCacheLocation();
        yield put(push("/login"));
        yield put(
            showNotificationModal({
                title: "Authorization Error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* checkUserLogin() {
    try {
        let errorLogin = false;
        // const updateAccount = yield AbilityService.updateAccount(
        //     AppSettings.b2cName,
        //     AppSettings.solution,
        //     AppSettings.clientId,
        //     AppSettings.cacheLocation === "localStorage"
        //         ? "localStorage"
        //         : "sessionStorage",
        //     (errorMsg) => {
        //         console.log(errorMsg);
        //         errorLogin = true;
        //     }
        // );

        if (errorLogin) {
            yield put(redirectLogin());
        }
        if (AbilityService.isLoggedIn) {
            yield put(updateIsLoggedIn(true));
        } else {
            yield put(updateIsLoggedIn(false));
            yield put(redirectLogin());
        }
    } catch (error) {
        console.error(error);
        yield put(redirectLogin());
    }
}

function* redirectLoginPage(action: ReturnType<typeof redirectLogin>) {
    AppSettings.cacheLocation === "localStorage"
        ? localStorage.removeItem(IS_LOGIN)
        : sessionStorage.removeItem(IS_LOGIN);
    yield put(
        showNotificationModal({
            title: "Authorization Error",
            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
            type: "confirmation",
        })
    );
    yield put(push("/login"));
}

function removeTokenFromCacheLocation() {
    AppSettings.cacheLocation === "localStorage"
        ? localStorage.removeItem(IS_LOGIN)
        : sessionStorage.removeItem(IS_LOGIN);
}

export default [
    takeLatest(ActionTypes.LOGIN_REQUEST, loginUserRequest),
    takeLatest(
        ActionTypes.INITIALISE_LOGIN_PROVIDER,
        initialiseLogiProviderRequest
    ),
    takeLatest(ActionTypes.REDIRECT_LOGIN, redirectLoginPage),
    takeLatest(ActionTypes.HANDLE_USER_LOGIN, checkUserLogin),
];
