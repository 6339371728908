import React from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import './style.scss';

import { ACTION_MENU_LIST } from '../../store/instanceConfig/types';
import { IAssetPermission } from '../../store/assets/types';
import { Icon } from '@abb/abb-common-ux-react';

interface MenuProps {
    actionMenuList: typeof ACTION_MENU_LIST;
    handleActionMenuClick: (selectedAction: typeof ACTION_MENU_LIST[0]) => void;
    assetPermissions: IAssetPermission;
}

const CustomMenu = (props: MenuProps) => {
    const handleSelect = (selectedOption: any) => {
        if (props.actionMenuList && props.handleActionMenuClick && selectedOption.key)
            props.handleActionMenuClick(props.actionMenuList[selectedOption.key]);
    };

    const handleDeselect = () => {
        // console.log("deselecting ");
    };

    const titleRight = (
        <div className="menu-img-container">
            <Icon name="abb/menu-narrow" sizeClass="small" />
        </div>
    );

    console.log('actionMenuList', props.actionMenuList);

    const permissionList = [
        { key: 'Configure', value: props.assetPermissions.hasMonitorInstance },
        { key: 'Enable', value: props.assetPermissions.hasEnableInstance },
        { key: 'Disable', value: props.assetPermissions.hasDisableInstance },
        { key: 'Remove', value: props.assetPermissions.hasDeleteInstance },
    ];

    return (
        <div className="wrapper-menu">
            {/* <Menu
                forceSubMenuRender
                className="menu"
                onClick={handleSelect}
                onDeselect={handleDeselect}
            >
                <SubMenu title={titleRight} key="1">
                    <div className="triangle-left"></div>
                    {props.actionMenuList &&
                        props.actionMenuList.map((actionObj, index) => {
                            const hasPermission = permissionList.find(
                                (i) => i.key === actionObj.label
                            )!.value;
                            return (
                                // <MenuItem
                                //     key={index}
                                //     disabled={!hasPermission ? true : actionObj.isDisabled}
                                //     className="obj-menu-item"
                                // >
                                //     {actionObj.label}
                                // </MenuItem>
                               <></> 
                            );
                        })}
                </SubMenu>
            </Menu> */}
        </div>
    );
};

export default CustomMenu;