import { IOApiOptions } from '../types';
import { dataTypeConverterFromJson } from '../../utils/dataTypeConverter';
import {
    generateAlarmInputPath,
    generateAlarmId,
    generateAlarmInputId,
} from './alarmsIoldGenerator';
import AlarmTypeDetail from '../AlarmType';
import { AlarmEventType } from '../../../components/Fabric/types';

export function alarmInputConverter({
    keys,
    object,
    refPath,
    nodeId,
}: {
    keys: string[];
    object: IOApiOptions;
    refPath: 'inputs';
    nodeId: string;
}) {
    const data: AlarmTypeDetail['inputs'] = [];
    if (Array.isArray(keys) && typeof object === 'object') {
        keys.forEach((key) => {
            const dataType = object[key].dataType;
            const items = object[key].items;
            if (dataType) {
                const path = generateAlarmInputPath({
                    nodeId,
                    ioType: refPath,
                    ioName: key,
                });

                const temp: AlarmTypeDetail['inputs'][0] = {
                    name: key,
                    id: generateAlarmInputId({
                        nodeId,
                        ioType: refPath,
                        ioName: key,
                    }),
                    path,
                    dataType: dataTypeConverterFromJson({ dataType, items }),
                    connected: false,
                };
                data.push(temp);
            }
        });
    }
    return data;
}

export function alarmOutputConverter({
    alarmId,
    modelId,
    nodeId,
    outputObj,
    alarmType,
}: {
    alarmId: string;
    modelId: string;
    nodeId: string;
    outputObj: IOApiOptions;
    alarmType: AlarmEventType;
}) {
    let outputs: AlarmTypeDetail['outputs'] = [];
    Object.keys(outputObj).forEach((outputName) => {
        const dataType = outputObj[outputName].dataType;
        const items = outputObj[outputName].items;
        const isPublished = !!outputObj[outputName].link;
        const alarmIdWithName = outputObj[outputName].alarmId;
        const isAlarmProperty = alarmIdWithName ? false : true;
        const output: AlarmTypeDetail['outputs'][0] = {
            name: outputName,
            id: generateAlarmId({
                nodeId,
                alarmId: alarmId,
                modelId: modelId,
                ioName: outputName,
                isAlarmProperty,
                alarmType: alarmType,
            }),
            path: outputName,
            dataType: dataTypeConverterFromJson({ dataType, items }),
            connected: false,
            isPublished: isPublished,
        };
        if (alarmIdWithName) {
            output.alarmId = alarmIdWithName;
        }

        outputs.push(output);
    });

    return outputs;
}
