import React from 'react';
import { Table, Button } from '@abb/abb-common-ux-react';
import { IImportLibValidationDetails } from '../../../store/function/types';
import { ModalFooter } from 'reactstrap';
import { CONFIRMATION_BUTTON } from '../../../utils/constants/uiConstants';
import { UI } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import { Dispatch } from 'redux';
import { handleLibrariesImport } from '../../../store/function/action';
import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/action';
import './style.scss';
interface IPreImportLibValidationModal {
    validationDetails: IImportLibValidationDetails[];
    librariesToImport: UI.IImportableLibraryInfo[];
}
const PreImportLibraryValidationModal = (
    props: IPreImportLibValidationModal & ReturnType<typeof mapDispatchToProps>
) => {
    const handleCancel = () => {
        props.hideModal();
    };
    const handleOK = () => {
        props.hideModal();
        props.handleImportLibraries(props.librariesToImport);
    };
    return (
        <div className="wrapper-validate-library-modal">
            <div className="wrapper-content">
                {props.validationDetails.length > 0 && (
                    <Table className="validate-table">
                        <thead>
                            <tr>
                                <th className="width-20">Name</th>
                                <th className="width-10">Version</th>
                                <th className="width-20">Description</th>
                                <th className="width-50">Detail</th>
                            </tr>
                        </thead>
                        {props.validationDetails.map((item) => {
                            return (
                                <>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {item.libraryName === 'None'
                                                    ? item.functionName
                                                    : item.libraryName}
                                            </td>
                                            <td>
                                                {item.libraryVersion
                                                    ? item.libraryVersion
                                                    : item.functionVersion}
                                            </td>
                                            <td>{item.validationDescription}</td>
                                            <td>{item.userMessage}</td>
                                        </tr>
                                    </tbody>
                                </>
                            );
                        })}
                    </Table>
                )}
            </div>
            <div>Do you want to proceed?</div>
            <ModalFooter>
                <div className="footer-part">
                    <Button
                        className="submit-btn"
                        text={CONFIRMATION_BUTTON.CANCEL}
                        type="discreet-black"
                        sizeClass="small"
                        onClick={handleCancel}
                    />
                    <Button
                        className="submit-btn"
                        text={CONFIRMATION_BUTTON.OK}
                        type="primary-blue"
                        sizeClass="small"
                        onClick={handleOK}
                    />
                </div>
            </ModalFooter>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleImportLibraries: (payload: UI.IImportableLibraryInfo[]) => {
            dispatch(handleLibrariesImport(payload));
        },
        hideModal: () => {
            dispatch(hideModal());
        },
    };
};
export default connect(undefined, mapDispatchToProps)(PreImportLibraryValidationModal);
