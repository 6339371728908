import React from 'react';
import AppSettings from '../../services/AppSettings';
import { Link } from '@abb/abb-common-ux-react';
import { licensingData } from '../../utils/constants/licensingData';
import '../../assets/bootstrap/scss/bootstrap.scss';
import './styles.scss';
interface LicenseProps {
    handleCloseDialog: () => void;
}
const Licensing = (props: LicenseProps) => {
    return (
        <>
            {/* License Note */}
            <div className="wrapper-licensing">
                <div className="Card__card-header___37-zb">
                    <h3 className="Heading__root___3LTN7 Heading__font-medium___14zeN Heading__upperCase___10Rbg">
                        Licensing Information
                    </h3>
                    <hr />
                </div>
                <div className="wrapper-cards">
                    {licensingData.map((item, i) => {
                        return (
                            <div className="LicenseModal__license___3we9K">
                                <h2 className="Heading__root___3LTN7 Heading__upperCase___10Rbg">
                                    {item.packageName}
                                </h2>
                                <h3 className="Heading__root___3LTN7">{item.type}</h3>
                                <p>{item.data}</p>
                                <Link target="_blank" href={item.url}>
                                    {item.url}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Licensing;
