import { ActionTypes } from "../actionTypes";
import { LoaderState } from "./types";

export const initialState = {
    computeModelSaving: { isSaving: false },
    computeModelLoading: { isLoading: false },
    computeModelDeleting: { isDeleting: false, data: {error: false} },
    paginationLoader: { isLoading: false },
    importExportModal: { isProcessing: false },
    supportedTypes: { isLoading: false },
    getAlarmsForModelsLoading: {isLoading: false},
    getEventsForModelsLoading: {isLoading: false}
};

export const loaderReducer = (
    state: LoaderState = initialState,
    action: any
): LoaderState => {
    switch (action.type) {
        case ActionTypes.SAVE_COMPUTE_MODEL_API: {
            return {
                ...state,
                computeModelSaving: {
                    isSaving: true,
                    data: { saveOption: action.payload.saveOption },
                },
            };
        }

        case ActionTypes.ADD_SAVE_AS_COMPUTE_MODEL: {
            return {
                ...state,
                computeModelLoading: {
                    isLoading: true,
                },
            };
        }

        case ActionTypes.SAVE_COMPUTE_MODEL_API_ERROR:
        case ActionTypes.SAVE_COMPUTE_MODEL_STORE: {
            return {
                ...state,
                computeModelSaving: { isSaving: false },
                computeModelLoading: { isLoading: false },
            };
        }
        case ActionTypes.HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE: {
            const isLoading = action.payload;

            if (isLoading) {
                state.computeModelLoading = { isLoading: false };
                state.computeModelSaving = { isSaving: false };
            }
            return {
                ...state,
            };
        }
        case ActionTypes.GET_COMPUTE_MODELS_API: {
            return {
                ...state,
                computeModelLoading: { isLoading: true },
            };
        }
        case ActionTypes.GET_COMPUTE_MODELS_STORE: {
            return { ...state, computeModelLoading: { isLoading: false } };
        }
        case ActionTypes.DELETE_COMPUTE_MODEL_API: {
            return { ...state, computeModelLoading: { isLoading: true }, computeModelDeleting: { isDeleting: true,  data: {error: false} }, };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_API_ERROR: {
            return { ...state, computeModelLoading: { isLoading: false }, computeModelDeleting: { isDeleting: false,  data: {error: true} }};
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_STORE: {
            return { ...state, computeModelLoading: { isLoading: false } };
        }
        case ActionTypes.RESET_PAGINATION_LOADER: {
            return {
                ...state,
                paginationLoader: { isLoading: action.payload },
            };
        }
        case ActionTypes.TOGGLE_SPINNER_MODAL: {
            return {
                ...state,
                importExportModal: { isProcessing: action.payload },
            };
        }
        case ActionTypes.FETCH_ASSETS_LIST: {
            return {
                ...state,
                supportedTypes: { isLoading: true },
            };
        }

        case ActionTypes.UPDATE_ASSETS_LIST: {
            return {
                ...state,
                supportedTypes: { isLoading: false },
            };
        }

        case ActionTypes.CHANGE_ACTIVE_MODEL: {
            return {
                ...state,
                computeModelLoading: { isLoading: true },
            };
        }
        case ActionTypes.GET_ORIGINAL_FUNCTION_TYPES: {
            return {
                ...state,
                computeModelLoading: { isLoading: true },
            };
        }
        case ActionTypes.SET_ORIGINAL_FUNCTION_TYPES: {
            return {
                ...state,
                computeModelLoading: { isLoading: false },
            };
        }
        case ActionTypes.GET_EXPORTABLE_FUNCTIONS_REQUEST: {
            return { ...state, importExportModal: { isProcessing: true } };
        }
        case ActionTypes.GET_EXPORTABLE_FUNCTIONS_SUCCESS: {
            return {
                ...state,
                importExportModal: { isProcessing: false },
            };
        }
        case ActionTypes.GET_EXPORTABLE_FUNCTIONS_FAILURE: {
            return { ...state, importExportModal: { isProcessing: false } };
        }
        case ActionTypes.FETCH_ALARMS_FOR_MODELS: {
            return {...state, getAlarmsForModelsLoading: {isLoading: true}}
        }
        case ActionTypes.UPDATE_ALARMS_FOR_MODELS: {
            return {...state, getAlarmsForModelsLoading: {isLoading: false}}
        };
        case ActionTypes.FETCH_EVENTS_FOR_MODELS: {
            return {...state, getEventsForModelsLoading: {isLoading: true}}
        }
        case ActionTypes.UPDATE_EVENTS_FOR_MODELS: {
            return {...state, getEventsForModelsLoading: {isLoading: false}}
        };
        default: {
            return state;
        }
    }
};
