import * as _ from 'lodash';
import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import { Button, Checkbox, Input, Dropdown, DropdownOption, Icon } from '@abb/abb-common-ux-react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ConditionState } from '../../constants';
import CustomTitle from '../../../../components/CustomTitle';
import { CONFIRMATION_BUTTON, VALIDATION_MESSAGE } from '../../../../utils/constants/uiConstants';
import {
    getNewUniqueName,
    finalConditionJSON,
    getDisabledForButton,
    convertedConditionObj,
    hasNegativeValue,
    validateForSwedishAndFinishCharacters,
    getDataForStatusKey,
} from '../../helper';
import { ShowMessageDispatchProps } from '../..';
import { columns, DropDownItem } from '../../type';
import Flex from '../../../../components/shared/Flex';
import FunctionLogic from '../../FunctionLogic';
import { Dispatch } from 'redux';
import { FunctionHeaderTypeDetails } from '../../../../store/function/types';
import {
    updateFunctionHeaderTypeDetails,
    handleFunctionErrorFlag,
} from '../../../../store/function/action';
import {
    hasErrorInConditionsData,
    getUniqueName,
    checkForDuplicateConditionName,
} from '../../../../utils/helpers';
import CustomLeftNav from '../../../../components/CustomLeftNavigation';
import AddConditionModal from '../../AddConditionModal';
import SubConditionDetail from '.././SubConditionDetail';
import '../style.scss';
import Collapsible from '../../Collapsible';
import Condition from '../../../../components/shared/Condition';
import { jsxElement } from '@babel/types';
import DraggableListItem from './DraggableListItem';
import CustomTooltip from '../../../../components/CustomTooltip';

interface SubConditionsListProps {
    conditionFunctionType: any;
    updateConditionFunctionType: (conditionFunctionType: any) => any;
    handleConditionError: (value: boolean) => void;
    getConditionName: (index: number) => string;
    selectedConditionIndex: number;
    selectedConditionName: string;
    selectedSubConditionIndex: number;
    setSelectedSubConditionIndex: (index: number) => any;
    handleConditions: (conditionsArr: any) => any;
    handleDeleteFunctionItems: (functionToBeCalled: () => any) => any;
    errorFlag: boolean;
    isSelectAll: boolean;
}

function SubConditionsList(props: SubConditionsListProps) {
    const {
        conditionFunctionType,
        updateConditionFunctionType,
        setSelectedSubConditionIndex,
        selectedConditionIndex,
        selectedConditionName,
        handleDeleteFunctionItems,
        selectedSubConditionIndex,
    } = props;

    const [showSubConditionsDelete, setShowSubConditionsDelete] = useState(false);
    const subConditionsListContainer = useRef<HTMLDivElement>(null);

    const addSubCondition = () => {
        if (props.errorFlag) {
            return;
        }
        const nameListObj = conditionFunctionType[selectedConditionIndex][
            selectedConditionName
        ].reduce(
            (obj: { [key: string]: boolean }, curr: any) => ({
                ...obj,
                [curr.name]: true,
            }),
            {}
        );
        const newSubConditionName = getUniqueName(
            nameListObj,
            'subCondition',
            conditionFunctionType[selectedConditionIndex][selectedConditionName].length
        );
        const addingIndex =
            conditionFunctionType[selectedConditionIndex][selectedConditionName].length;
        delete conditionFunctionType[selectedConditionIndex]['deleteFlag'];
        const defaultObj = {
            name: newSubConditionName,
            description: { dataType: 'string', value: '' },
            severity: { dataType: 'integer', value: '' },
            possibleCause: { dataType: 'string', value: '' },
            suggestedAction: { dataType: 'string', value: '' },
            correctiveAction: { dataType: 'string', value: '' },
            logic: { dataType: 'string', value: '' },
            status: { dataType: 'string', value: '' },
        };
        conditionFunctionType[selectedConditionIndex][selectedConditionName].push(defaultObj);
        const hasErrorInCondition = hasErrorInConditionsData(conditionFunctionType);
        !hasErrorInCondition && props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType([...conditionFunctionType]);
        setSelectedSubConditionIndex(addingIndex);
        let checkedSubConditions = conditionFunctionType[selectedConditionIndex][
            selectedConditionName
        ].filter((item: any) => item['deleteFlag']);
        if (checkedSubConditions.length > 0) {
            setShowSubConditionsDelete(true);
        }
    };

    const handleSubConditionCheckbox = (index: number, value: boolean) => {
        if (value) {
            conditionFunctionType[selectedConditionIndex][selectedConditionName][index][
                'deleteFlag'
            ] = true;
        } else {
            delete conditionFunctionType[selectedConditionIndex][selectedConditionName][index][
                'deleteFlag'
            ];
        }
        let checkedSubConditions = conditionFunctionType[selectedConditionIndex][
            selectedConditionName
        ].filter((item: any) => item['deleteFlag']);
        if (checkedSubConditions.length === 0) {
            setShowSubConditionsDelete(false);
            delete conditionFunctionType[selectedConditionIndex]['deleteFlag'];
        } else if (
            checkedSubConditions.length ===
            conditionFunctionType[selectedConditionIndex][selectedConditionName].length
        ) {
            conditionFunctionType[selectedConditionIndex]['deleteFlag'] = true;
            setShowSubConditionsDelete(false);
        } else {
            delete conditionFunctionType[selectedConditionIndex]['deleteFlag'];
            if (checkedSubConditions.length > 0) {
                setShowSubConditionsDelete(true);
            }
        }
        delete conditionFunctionType[selectedConditionIndex]['error'];
        updateConditionFunctionType([...conditionFunctionType]);
    };

    const dropItem = (dragIndex: number, dropIndex: number) => {
        if (dragIndex !== dropIndex) {
            let subConditionToBeMoved = conditionFunctionType[selectedConditionIndex][
                selectedConditionName
            ].splice(dragIndex, 1)[0];
            let positionToBeInserted = dropIndex;
            conditionFunctionType[selectedConditionIndex][selectedConditionName].splice(
                positionToBeInserted,
                0,
                subConditionToBeMoved
            );
            updateConditionFunctionType([...conditionFunctionType]);
            props.handleConditions(finalConditionJSON(conditionFunctionType));
            setSelectedSubConditionIndex(positionToBeInserted);
        }
    };

    const handleMultipleSubConditionDelete = () => {
        const filteredSubConditions = conditionFunctionType[selectedConditionIndex][
            selectedConditionName
        ].filter((item: any) => {
            return !item['deleteFlag'];
        });
        conditionFunctionType[selectedConditionIndex][selectedConditionName] =
            filteredSubConditions;
        props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType([...conditionFunctionType]);
        if (selectedSubConditionIndex >= filteredSubConditions.length) {
            setSelectedSubConditionIndex(filteredSubConditions.length - 1);
        }
        setShowSubConditionsDelete(false);
    };

    const handleSingleSubConditionDelete = (index: number) => {
        conditionFunctionType[selectedConditionIndex][selectedConditionName].splice(index, 1);
        props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType([...conditionFunctionType]);
        if (
            selectedSubConditionIndex ===
            conditionFunctionType[selectedConditionIndex][selectedConditionName].length
        ) {
            setSelectedSubConditionIndex(selectedSubConditionIndex - 1);
        }
    }; 

    useEffect(() => {
        if (props.isSelectAll) {
            setShowSubConditionsDelete(false);
        }
    }, [props.isSelectAll]);

    return (
        <>
            <div className="condition-list-title">
                Sub Conditions
                <div className="right-icon">
                    <Icon
                        sizeClass="small"
                        name="abb/plus"
                        onClick={() => {
                            if (!props.errorFlag) {
                                addSubCondition();
                            }
                        }}
                        className={props.errorFlag ? 'disabled' : ''}
                    />
                    <Condition when={showSubConditionsDelete}>
                        <Icon
                            sizeClass="small"
                            name="abb/trash"
                            onClick={() =>
                                handleDeleteFunctionItems(handleMultipleSubConditionDelete)
                            }
                            className={props.errorFlag ? 'disabled' : ''}
                        />
                    </Condition>
                </div>
            </div>
            <Flex column className="list-wrapper">
                <div ref={subConditionsListContainer} style={{ overflow: 'auto' }}>
                    {
                        // TODO: Update with the original data here.
                        selectedConditionName &&
                            conditionFunctionType[selectedConditionIndex] &&
                            conditionFunctionType[selectedConditionIndex][selectedConditionName] &&
                            conditionFunctionType[selectedConditionIndex][
                                selectedConditionName
                            ].map((item: any, index: number) => (
                                <CustomTooltip key={index} position="top" text={item.name}>
                                    <DraggableListItem
                                        subConditionData={item}
                                        subConditionIndex={index}
                                        dropItem={dropItem}
                                        errorFlag={props.errorFlag}
                                        handleSubConditionCheckbox={handleSubConditionCheckbox}
                                        handleDeleteFunctionItems={handleDeleteFunctionItems}
                                        handleSingleSubConditionDelete={
                                            handleSingleSubConditionDelete
                                        }
                                        setSelectedSubConditionIndex={setSelectedSubConditionIndex}
                                        selectedSubConditionIndex={selectedSubConditionIndex}
                                    />
                                </CustomTooltip>
                            ))
                    }
                </div>
            </Flex>
        </>
    );
}
export default SubConditionsList;
