import React, { useState, useCallback } from "react";
import {
    Dropdown,
    DropdownGroup,
    DropdownOption,
    Button,
} from "@abb/abb-common-ux-react";
import "./style.scss";
import Flex from "../../../components/shared/Flex";
import { StoreState } from "../../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    saveComputeModelInstanceConfig,
    getLibraryReferencesForFunctionTypesRequest,
    updateComputeModelInstanceConfig,
} from "../../../store/instanceConfig/actions";
import { hideModal } from "../../../store/modal/action";
import {
    InstanceSaveType,
    FunctionTypeBasics,
} from "../../../store/instanceConfig/types";
import { INSTANCE_SAVE_MODE } from "../../../utils/constants/appConstants";
import { Asset } from "../../../components/Fabric/types";
import FunctionTypeDetail from "../../../transformers/AssetType/FunctionType";
import {
    getFunctionsList,
    getTypeBasicsForFunctionTypeDetail,
} from "../../../utils/helpers";

export function SaveInstanceConfigModal(
    props: ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [selectedAssets, updateSelectedAssets] = useState<
        { value: string; label: string; isNew: boolean }[]
    >(
        props.selectedInstances.map((selectedInstance) => ({
            value: selectedInstance.objectId,
            label: selectedInstance.name,
            isNew: false,
        }))
    );
    const handleChange = useCallback(
        (options: { value: string; label: string; isNew: boolean }[]) => {
            console.log(options);
            updateSelectedAssets(options);
            // updateSelectedAsset(objectId);
        },
        []
    );

    const handleSave = useCallback(() => {
        props.saveComputeModelInstance({
            objectIds: selectedAssets.map(
                (selectedAsset) => selectedAsset.value
            ),
            saveMode: INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY,
        });
    }, [selectedAssets]);

    return (
        <Flex column className="wrapper-save-instance-modal">
            <div className="title">
                Select the asset to which instance has to be created
            </div>
            <div className="wrapper-custom-select">
                <Dropdown
                    multiselect={true}
                    value={selectedAssets}
                    onChange={handleChange}
                    placeholder="Select an asset"
                >
                    {props.selectedInstances.map((selectedInstance) => {
                        return (
                            <DropdownOption
                                label={selectedInstance.name}
                                value={selectedInstance.objectId}
                                key={selectedInstance.objectId}
                            ></DropdownOption>
                        );
                    })}
                </Dropdown>

                {/* <CustomSelect
                    options={props.selectedInstances.map(selectedInstance => ({
                        label: selectedInstance.name,
                        value: selectedInstance.objectId
                    }))}
                    placeholder="Select an asset"
                    onChange={handleChange}
                    isOpen={false}
                /> */}
            </div>
            <div className="save-button">
                <Button
                    type="primary-blue"
                    text="Save"
                    sizeClass="small"
                    onClick={handleSave}
                />
            </div>
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances.map(
                (instanceId) => {
                    return state.instanceConfig.instancesTable.tableData.byId[
                        instanceId
                    ];
                }
            ),
        configurationToolJson: state.configurationTool.json,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveComputeModelInstance: (payload: {
            objectIds: string[];
            saveMode: InstanceSaveType;
        }) => {
            dispatch(saveComputeModelInstanceConfig(payload));
            dispatch(hideModal());
        },
        updateComputeModelInstance: (payload: {
            objectIds: string[];
            saveMode: InstanceSaveType;
        }) => {
            dispatch(updateComputeModelInstanceConfig(payload));
            dispatch(hideModal());
        },
        getLibraryReferencesForFunctionTypesRequest: (
            functionTypeBasicsList: FunctionTypeBasics[]
        ) =>
            dispatch(
                getLibraryReferencesForFunctionTypesRequest(
                    functionTypeBasicsList
                )
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveInstanceConfigModal);
