import React, { Fragment, useState, useEffect } from "react";
import { Input, Icon, Checkbox } from "@abb/abb-common-ux-react";
import ReactSelect from "react-select";
import {
    CalculationFields,
    CALCULATION_FIELD,
    dropDownOptions,
} from "../../type";
import {
    customReactSelectStyles,
    reactSelectMenuStyle,
} from "../../../../utils/helpers";
import { DropDownOptions } from "../../constants";
import { FunctionMode } from "../../../../store/function/types";
import {
    hasWhiteSpace,
    If,
    validateForSpecialCharacters_Including_HyphenAndUnderscore,
} from "../../helper";
import { FUNCTION_MODE } from "../../../../utils/constants/appConstants";
import {
    VALIDATION_MESSAGE,
    HIDDEN_LOGIC_TEXT,
} from "../../../../utils/constants/uiConstants";
import CustomABBInput from "../../../../components/CustomABBInput";

interface CalculationTableBodyProps {
    tableData: any[];
    handleCalcFieldChange?: (fieldName: CalculationFields, val: string) => void;
    functionMode: FunctionMode;
    handleLogicDialog?: (val: string) => void;
    isCalcNameValid?: boolean;
    handleCalculationError?: (value: boolean) => void;
    selectedValue?: any[];
    handleSelectedValue?: (selectedObj: any) => void;
    isEncrypted?: boolean;
    isConfigurationMode?: boolean;
    emptyErrors?: string[];
}

function CalculationTableBody(props: CalculationTableBodyProps) {
    const { selectedValue, handleSelectedValue } = props;
    const [errorCalculationName, updateCalculationNameError] = useState("");
    const handleErrorField = (fieldName: CalculationFields, val: string) => {
        switch (fieldName) {
            case CALCULATION_FIELD.NAME:
                if (
                    !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                        val
                    ) ||
                    val.trim() === ""
                ) {
                    let errorText = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
                    if (hasWhiteSpace(val)) {
                        errorText = VALIDATION_MESSAGE.SPACE;
                    }
                    if (val.trim() === "") {
                        errorText = VALIDATION_MESSAGE.EMPTY_NAME;
                    }
                    props.handleCalculationError &&
                        props.handleCalculationError(true);
                    updateCalculationNameError(errorText);
                } else {
                    updateCalculationNameError("");
                    props.handleCalculationError &&
                        props.handleCalculationError(false);
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        props.tableData.forEach((item: any) => {
            if (
                !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                    item.name
                ) ||
                item.name.trim() === ""
            ) {
                let errorText = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
                if (hasWhiteSpace(item.name)) {
                    errorText = VALIDATION_MESSAGE.SPACE;
                }
                if (item.name.trim() === "") {
                    errorText = VALIDATION_MESSAGE.EMPTY_NAME;
                }
                props.handleCalculationError &&
                    props.handleCalculationError(true);
                updateCalculationNameError(errorText);
            } else {
                updateCalculationNameError("");
            }
        });
    }, [props.tableData]);

    const setEmptyError = (name: string, logic: any) => {
        if (
            props.emptyErrors &&
            props.emptyErrors.length &&
            logic.value === ""
        ) {
            let error = "";
            props.emptyErrors.forEach((errorText: string): any => {
                if (errorText.includes(name)) {
                    error = errorText;
                }
            });
            if (error.length) {
                return { valid: false, text: error };
            } else {
                return { valid: true, text: "OK!" };
            }
        } else {
            return { valid: true, text: "OK!" };
        }
    };

    return (
        <tbody>
            {props.tableData.map((item: any, itemIndex: number) => {
                let type: string = item["outputType"]["dataType"];
                let arrayType: string | undefined = item.outputType.items;
                if (arrayType) {
                    if (arrayType === "integer") {
                        type = dropDownOptions.integerArr;
                    }

                    if (arrayType === "number") {
                        type = dropDownOptions.numberArr;
                    }

                    if (arrayType === "string") {
                        type = dropDownOptions.stringArr;
                    }

                    if (arrayType === "boolean") {
                        type = dropDownOptions.booleanArr;
                    }
                }
                return (
                    <tr key={itemIndex}>
                        <td className="width-3">
                            {props.functionMode !== FUNCTION_MODE.VIEW && (
                                <Checkbox
                                    sizeClass={"small"}
                                    value={false}
                                    onChange={() =>
                                        console.log("handle delete")
                                    }
                                />
                            )}
                        </td>

                        <td className="width-15">
                            {props.functionMode === "VIEW" ? (
                                item.name
                            ) : (
                                <CustomABBInput
                                    type="normal"
                                    dataType="text"
                                    value={item.name}
                                    onValueChange={(val) => {
                                        handleErrorField(
                                            CALCULATION_FIELD.NAME,
                                            val
                                        );

                                        props.handleCalcFieldChange &&
                                            props.handleCalcFieldChange(
                                                CALCULATION_FIELD.NAME,
                                                val
                                            );
                                    }}
                                    className="functionClass"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    validator={() =>
                                        errorCalculationName.length > 0
                                            ? {
                                                  valid: false,
                                                  text: errorCalculationName,
                                              }
                                            : { valid: true, text: "OK!" }
                                    }
                                />
                            )}
                        </td>

                        <td className="width-20">
                            <If cond={item.hasOwnProperty("outputType")}>
                                {props.functionMode === FUNCTION_MODE.VIEW ? (
                                    type
                                ) : (
                                    <div className="condition-function-configuration-Edit">
                                        <div className="DropDownList">
                                            <ReactSelect
                                                id={`${"DDL_Function_Output_DataType"}{conditionIndex}`}
                                                placeholder="Select DataType"
                                                styles={{
                                                    ...customReactSelectStyles,
                                                    menu: reactSelectMenuStyle,
                                                }}
                                                isClearable={false}
                                                options={DropDownOptions}
                                                value={
                                                    selectedValue && [
                                                        ...selectedValue,
                                                    ]
                                                }
                                                getOptionLabel={(
                                                    optionObj: any
                                                ) => {
                                                    return `${optionObj.label}`;
                                                }}
                                                getOptionValue={(
                                                    optionObj: any
                                                ) => {
                                                    return `${optionObj.value}`;
                                                }}
                                                onChange={(value: any) => {
                                                    handleSelectedValue &&
                                                        handleSelectedValue([
                                                            value,
                                                        ]);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </If>
                        </td>
                        <Fragment>
                            <If cond={item.hasOwnProperty("description")}>
                                <td className="width-13.5">
                                    {props.functionMode ===
                                    FUNCTION_MODE.VIEW ? (
                                        item.description.value
                                    ) : (
                                        <CustomABBInput
                                            type="normal"
                                            dataType="textarea"
                                            value={item.description.value}
                                            onValueChange={(val) =>
                                                props.functionMode !==
                                                    FUNCTION_MODE.VIEW &&
                                                props.handleCalcFieldChange &&
                                                props.handleCalcFieldChange(
                                                    CALCULATION_FIELD.DESCRIPTION,
                                                    val
                                                )
                                            }
                                            className="functionClass"
                                        />
                                    )}
                                </td>
                            </If>

                            <If cond={item.hasOwnProperty("logic")}>
                                <td className="width-13.5">
                                    <div
                                        className={
                                            props.functionMode !==
                                            FUNCTION_MODE.VIEW
                                                ? "logic-input-wrapper"
                                                : ""
                                        }
                                        onClick={() => {
                                            props.functionMode !==
                                                FUNCTION_MODE.VIEW &&
                                                props.handleLogicDialog &&
                                                props.handleLogicDialog(
                                                    item.logic.value
                                                );
                                        }}
                                    >
                                        {props.functionMode ===
                                        FUNCTION_MODE.VIEW ? (
                                            props.isEncrypted &&
                                            props.isConfigurationMode ? (
                                                HIDDEN_LOGIC_TEXT
                                            ) : (
                                                item.logic.value
                                            )
                                        ) : (
                                            <CustomABBInput
                                                type="normal"
                                                dataType="textarea"
                                                value={item.logic.value}
                                                onValueChange={(val) => {
                                                    if (
                                                        props.handleLogicDialog
                                                    ) {
                                                        props.handleLogicDialog(
                                                            item.logic.value
                                                        );
                                                    }
                                                }}
                                                className="logic-input"
                                                style={{ display: "block" }}
                                                showValidationBarWhenInvalid={
                                                    true
                                                }
                                                showValidationIconWhenInvalid={
                                                    true
                                                }
                                                instantValidation={true}
                                                validator={() =>
                                                    setEmptyError(
                                                        item.name,
                                                        item.logic
                                                    )
                                                }
                                            />
                                        )}
                                        {props.functionMode !==
                                            FUNCTION_MODE.VIEW && (
                                            <Icon
                                                name="abb/plus-in-circle"
                                                sizeClass="small"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    props.handleLogicDialog &&
                                                    props.handleLogicDialog(
                                                        item.logic.value
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                </td>
                            </If>
                        </Fragment>
                    </tr>
                );
            })}
        </tbody>
    );
}

export default CalculationTableBody;
