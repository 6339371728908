import {
    FUNCTION_MODE,
    TABLE_IO_TYPE,
} from "../../utils/constants/appConstants";
import React, { useState } from "react";
import { Checkbox, Icon } from "@abb/abb-common-ux-react";
import "./style.scss";
import CustomTooltip from "../CustomTooltip";

interface CustomTableHeaderProps {
    sortOrder: string;
    allDeleteStatus?: boolean;
    tableType: "INPUT" | "OUTPUT";
    handleSortOrder: (x: string) => void;
    tableMode: "VIEW" | "EDIT" | "CREATE";
    handleAllDeleteList?: (type: "INPUT" | "OUTPUT" | "CONDITION") => void;
    isDeleteDisabled?: boolean;
    deleteFunctionItems?: (e: any) => void;
}

const CustomTableHeader = (props: CustomTableHeaderProps) => {
    const {
        sortOrder,
        tableType,
        tableMode,
        handleSortOrder,
        allDeleteStatus,
        handleAllDeleteList,
        isDeleteDisabled,
        deleteFunctionItems,
    } = props;
    const [activeTableRow, setActiveTableRow] = useState("name");
    return (
        <thead className="func-table-header">
            <tr>
                {(tableMode === FUNCTION_MODE.EDIT ||
                    tableMode === FUNCTION_MODE.CREATE) && (
                    <th className="width-5">
                        <Checkbox
                            id={`CB_Function_Create_Input`}
                            sizeClass={"small"}
                            value={allDeleteStatus}
                            onChange={() => {
                                handleAllDeleteList &&
                                    handleAllDeleteList(props.tableType);
                            }}
                        />
                    </th>
                )}
                <th
                    className={`${
                        tableType === TABLE_IO_TYPE.OUTPUT
                            ? "text-uppercase width-30"
                            : tableMode === FUNCTION_MODE.VIEW
                            ? "text-uppercase width-25"
                            : "text-uppercase width-20"
                    } ${
                        activeTableRow === "name" && "border-bottom-blue"
                    } cursor-pointer`}
                    onClick={() => {
                        handleSortOrder("name");
                        setActiveTableRow("name");
                    }}
                >
                    Variable Name
                    {activeTableRow === "name" && (
                        <Icon
                            name={`${
                                sortOrder === "asc"
                                    ? "abb/triangle-down"
                                    : "abb/triangle-up"
                            }`}
                            sizeClass="small"
                            style={{
                                position: "relative",
                                top: "3px",
                                left: "3px",
                            }}
                        />
                    )}
                </th>

                <th
                    className={`${
                        tableType === TABLE_IO_TYPE.OUTPUT
                            ? "text-uppercase width-30"
                            : "text-uppercase width-20"
                    } ${
                        activeTableRow === "dataType" && "border-bottom-blue"
                    } cursor-pointer`}
                    onClick={() => {
                        handleSortOrder("dataType");
                        setActiveTableRow("dataType");
                    }}
                >
                    Variable Type
                    {activeTableRow === "dataType" && (
                        <Icon
                            name={`${
                                sortOrder === "asc"
                                    ? "abb/triangle-down"
                                    : "abb/triangle-up"
                            }`}
                            sizeClass="small"
                            style={{
                                position: "relative",
                                top: "3px",
                                left: "3px",
                            }}
                        />
                    )}
                </th>
                {tableType === TABLE_IO_TYPE.INPUT && (
                    <th
                        className={`text-uppercase ${
                            tableMode === FUNCTION_MODE.VIEW
                                ? "width-15"
                                : "width-10"
                        }`}
                    >
                        Mandatory
                    </th>
                )}
                {tableType === TABLE_IO_TYPE.INPUT && (
                    <th className="text-uppercase width-20">Value</th>
                )}
                <th
                    className={`text-uppercase ${
                        tableType === TABLE_IO_TYPE.OUTPUT
                            ? "width-30"
                            : tableMode === FUNCTION_MODE.VIEW
                            ? "width-20"
                            : "width-25"
                    }`}
                >
                    Description
                </th>
                {(tableMode === FUNCTION_MODE.EDIT ||
                    tableMode === FUNCTION_MODE.CREATE) && (
                        <th className="width-5 delete-icon">
                            <CustomTooltip position="top" text={`Delete Selected ${tableType === TABLE_IO_TYPE.OUTPUT ? "Outputs" : "Inputs"}`}>
                                <Icon
                                    name="abb/trash"
                                    sizeClass="medium"
                                    color="#f03040"
                                    className={isDeleteDisabled ? "disabled" : ""}
                                    onClick={(e) => {
                                        if (deleteFunctionItems && !isDeleteDisabled)
                                            deleteFunctionItems(e)
                                    }}
                                />
                            </CustomTooltip>

                        </th>
                    )}
            </tr>
        </thead>
    );
};

export default CustomTableHeader;
