import { fabric } from 'fabric';
import {
    outputLineLength,
    fontSize,
    canvasPrimaryBorderColor,
    canvasIOCircleColor,
} from './UiConfig';
import { dataTypeSymbolConverter } from '../utils/dataTypeSymbolConverter';

interface Params {
    outputLineLength?: number;
    lineText: string;
}

export class FabricIOLine extends fabric.Group {
    lineText: fabric.Text;

    constructor(options: Params) {
        super(undefined, {
            selectable: false,
            hoverCursor: 'default',
        });

        const line = new fabric.Line([0, 0, options.outputLineLength || outputLineLength, 0], {
            fill: canvasIOCircleColor,
            stroke: canvasIOCircleColor,

            strokeWidth: 1,
            selectable: false,
            evented: false,
            objectCaching: false,
        });

        this.addWithUpdate(line);

        this.lineText = new fabric.Text(dataTypeSymbolConverter(options.lineText) || '', {
            fontSize: fontSize / 1.5,
            left: (options.outputLineLength || outputLineLength) / 2,
            originX: 'center',
            top: -10,
            selectable: false,
        });

        this.addWithUpdate(this.lineText);
    }
}
