import { FabricIOLine } from '../FabricIOLine';
import { fabric } from 'fabric';
import { FabricInputCircle, FabricOutputCircle, FabricIOCircleParams } from '../FabricIOCircle';
import {
    fontSize,
    outputLineLength,
    outputPinPadding,
    outputCircleRadius,
    objectOutputPinLength,
    GRID_PIXEL,
} from '../UiConfig';
import { FabricOutputAlarmIcon } from './AlarmIcon';

export interface LineWithCircleParams extends FabricIOCircleParams {
    lineText: string;
}

interface IOutputNodeWithNameParams extends LineWithCircleParams {
    title: string;
    isAlarmPin: boolean;
}
interface IOutputPinType {
    type?: 'Object' | 'function';
}
class FabricLineWithCircle {
    ioLine: FabricIOLine;
    constructor(options: LineWithCircleParams & IOutputPinType) {
        const { lineText, type } = options;
        this.ioLine = new FabricIOLine({
            lineText: lineText,
            outputLineLength:
                type && type === 'Object'
                    ? objectOutputPinLength + GRID_PIXEL / 2
                    : outputLineLength + GRID_PIXEL,
        });
        this.ioLine.set('originY', 'bottom');
        this.ioLine.set('name', 'ioLine');
        this.ioLine.set('left', 0);
        this.ioLine.set('originX', 'left');
    }
}

export class FabricILWC extends FabricLineWithCircle {
    ioCircle: FabricInputCircle;
    constructor(options: LineWithCircleParams) {
        super(options);
        this.ioCircle = new FabricInputCircle(options);
        this.ioCircle.set('left', outputCircleRadius);
        this.ioCircle.set('originX', 'right');
    }
}

export class FabricOLWC extends FabricLineWithCircle {
    ioCircle: FabricOutputCircle;
    constructor(options: LineWithCircleParams & IOutputPinType) {
        super(options);
        const lineWidth = this.ioLine.getScaledWidth();
        this.ioCircle = new FabricOutputCircle(options);
        this.ioCircle.set('left', lineWidth - outputCircleRadius);
        this.ioCircle.set('originX', 'left');
    }
}

/** FabricOutputNodeWithName
 *  - used to add the output pin in FabricObjectRectangle
 * */
export class FabricONWN extends FabricOLWC {
    pinTitle: fabric.Text;
    alarmIcon?: FabricOutputAlarmIcon;
    constructor(options: IOutputNodeWithNameParams) {
        super({ lineText: options.lineText, type: 'Object' });
        const { title, isAlarmPin } = options;

        this.pinTitle = new fabric.Text(title, {
            fontSize: fontSize * 0.8,
            originX: 'right',
            originY: 'center',
            left: -outputPinPadding,
            selectable: false,
            hoverCursor: 'default',
        });
        if (isAlarmPin) {
            this.alarmIcon = new FabricOutputAlarmIcon();
            this.pinTitle.left = this.pinTitle.left! - this.alarmIcon!.getScaledWidth();
        }
    }
}
