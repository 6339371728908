import _ from 'lodash';

import { CreateObjectTypeDetail } from '../../../transformers/AssetType/ObjectType';
import { CreateFunctionTypeDetail } from '../../../transformers/AssetType/FunctionType';
import { sce } from 'sce-engg-model-19.09';
import { store } from '../../../containers/AppBoot';
import { getFunctionTypeDetailId, markConditionDataDirty } from '../../../utils/helpers';
import { addToOriginalSelectedFunctions } from '../../../store/configurationTool/actions';
import { ReferenceTypeValueItem } from '../../../transformers/ComputeModel/fromJson/types';
import { OutputVariablesFromJson } from '../../../transformers/outputVariableModel';

interface Options {
    assetName: string;
    assetType: string;
    assetRef: string;
    assetVersion: string;
    position: { x: number; y: number };
    isOverallSeverityFunction?: boolean;
    isReferencedType?: boolean;
    referencedTypes?: ReferenceTypeValueItem[];
    objectId?: string;
}

const isAssetObject = (assetType: Options['assetType'], supportedModels: any) => {
    let isObject = false;
    supportedModels.forEach((supportedModelType: any) => {
        const valuesArray: any[] = supportedModelType.value;
        if (valuesArray.length && assetType === valuesArray[0].assetType) {
            isObject = true;
        }
    });
    return isObject;
};

export function getAssetDetails(options: Options) {
    const {
        assetName,
        assetType,
        assetRef,
        assetVersion,
        position,
        isReferencedType,
        referencedTypes,
        objectId,
        isOverallSeverityFunction,
    } = options;
    const supportedModels = store.getState().modelsPage.supportedModels;
    if (isAssetObject(assetType, supportedModels) || isReferencedType) {
        debugger;
        if (isReferencedType) {
            return sce
                .getVariablesAlarmsAndEvents(assetType, assetRef, assetVersion)
                .then((res) => {
                    console.log('type variables details', res);
                    debugger;
                    const alarms = (res.details as any).alarms;
                    const events = (res.details as any).events;
                    const variables = (res.details as any).variables;
                    const objectTypeData = new OutputVariablesFromJson({
                        name: assetName,
                        version: assetVersion,
                        description: '',
                        model: assetType,
                        typeId: assetRef,
                        alarms,
                        events,
                        variables,
                    });
                    const referencedTypeObject = CreateObjectTypeDetail({
                        ...objectTypeData,
                        assetType: objectTypeData.model,
                        scale: 1,
                        position,
                        referencedType: true,
                    });
                    if (referencedTypeObject) {
                        referencedTypeObject.referencedTypes = referencedTypes
                            ? [...referencedTypes]
                            : [];
                        referencedTypeObject.objectId = objectId;
                    }
                    return referencedTypeObject || Promise.reject();
                });
        } else {
            return sce
                .getVariablesAlarmsAndEvents(assetType, assetRef, assetVersion)
                .then((res) => {
                    console.log('object type details', res);
                    const alarms = (res.details as any).alarms;
                    const events = (res.details as any).events;
                    const variables = (res.details as any).variables;
                    const objectTypeData = new OutputVariablesFromJson({
                        name: assetName,
                        version: assetVersion,
                        description: '',
                        model: assetType,
                        typeId: assetRef,
                        alarms,
                        events,
                        variables,
                    });

                    const obj = CreateObjectTypeDetail({
                        ...objectTypeData,
                        assetType: objectTypeData.model,
                        position,
                        scale: 1,
                    });
                    console.log(obj);
                    return obj || Promise.reject();
                });
        }
    } else {
        return sce
            .getFunctionTypeWithEncryptionStatus(assetType, assetRef, assetVersion)

            .then((res) => {
                console.log('function type details', res);
                debugger;
                //@ts-ignore
                res.details.functionType.properties['inhibits'] = {
                    referenceValue: {
                        dataType: 'boolean',
                    },
                    actualValue: {
                        dataType: 'boolean',
                    },
                };
                const state = store.getState();

                const nodeId = getFunctionTypeDetailId({
                    functionName: res.details.functionType.name,
                    assetData: state.configurationTool.json.assetData,
                    oldAssetData: isOverallSeverityFunction
                        ? []
                        : state.modelsPage.activeModel.modelInstance.json.assetData,
                });
                const obj = CreateFunctionTypeDetail({
                    ...res.details.functionType,
                    position,
                    nodeId,
                    properties: {
                        ...res.details.functionType.properties,
                        isEncrypted: res.details.isEncrypted,
                    },
                });

                // pre publish the output of the overallSeverity function.
                if (options.isOverallSeverityFunction) {
                    if (obj) {
                        obj.outputs.forEach((item) => {
                            item['isPublished'] = true;
                        });
                    }
                }

                // mark condition dirty on initial drag drop.
                if (obj) {
                    const originalFunctionDetails = _.cloneDeepWith(obj);
                    if (obj.conditions) {
                        const originalConditions = obj.conditions;
                        const changedConditions = {};
                        const { overRiddenCondition } = markConditionDataDirty({
                            originalConditons: originalConditions,
                            changedConditions: changedConditions,
                        });
                        obj.conditions = { ...overRiddenCondition };
                    }

                    store.dispatch(addToOriginalSelectedFunctions(originalFunctionDetails));
                }
                return obj || Promise.reject();
            });
    }
}
