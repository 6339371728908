import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Icon } from "@abb/abb-common-ux-react";
import { AbilityService } from "sce-engg-model-19.09";
import { hideModal } from "../../../store/modal/action";
import { Dispatch } from "redux";

import "./style.scss";
import {
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
    RemoveAndAddFunctionsToLibrariesPayload,
} from "../../../store/function/types";
import { StoreState } from "../../../store";
import { FUNCTION_MODAL } from "../constants";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface DeleteLibraryModalProps {
    libraryDetails: LibraryAndNodePayload;
    submitBtnText: string;
    currentFunction?: UpdateActiveFunctionType;
    onDelete: any;
    resetGlobalLibraryError: () => void;
    type: "Delete" | "Remove";
}

const DeleteLibraryModal = (
    props: DeleteLibraryModalProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        onDelete,
        submitBtnText,
        libraryDetails,
        currentFunction,
        deletingLibrary,
        libraryGlobalError,
        resetGlobalLibraryError,
        removingFunctionsFromExistingLibrary,
        closeModalForLibraryDelete,
        type,
    } = props;
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState(true);
    const isSameOrigin = libraryDetails.origin === AbilityService.tenantId;

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const handleDelete = useCallback(() => {
        if (currentFunction) {
            //To handle the remove Function call.
            const payload: RemoveAndAddFunctionsToLibrariesPayload = {
                password,
                functions: [currentFunction],
                libraryId: libraryDetails.id,
                libraryVersion: libraryDetails.libraryVersion,
                withIPProtection: libraryDetails.isIPProtected,
            };
            onDelete(payload);
        } else {
            // To handle the delete library call.
            const deleteAllFunctions = !isSameOrigin;
            onDelete(
                libraryDetails.id,
                libraryDetails.libraryVersion,
                password,
                libraryDetails.isIPProtected,
                deleteAllFunctions
            );
        }
    }, [currentFunction, libraryDetails.id, password, onDelete]);

    return (
        <div className="library-delete-modal">
            {!currentFunction && !isSameOrigin && (
                <>
                    <ul className="warning-message">
                        <strong>
                            <Icon
                                name="abb/warning-triangle"
                                sizeClass="small"
                            />{" "}
                            Warning!!!
                        </strong>
                        <li>
                            Deleting an imported library from different origin
                            will also delete all the functions associated with
                            it.
                        </li>
                        <li>
                            It is not possible to either reimport a deleted
                            function or to create a new function with the typeId
                            of an already deleted function.
                        </li>
                        <li> Do you want to proceed?</li>
                        <li className="error-message">
                            {libraryGlobalError.length > 0 &&
                                libraryGlobalError}
                        </li>
                    </ul>
                </>
            )}
            <div className="content">
                {isSameOrigin &&
                    libraryDetails.isIPProtected &&
                    (currentFunction
                        ? `Enter Password for ${libraryDetails.root} to remove ${currentFunction.name}`
                        : `Enter Password to delete ${libraryDetails.root}`)}

                {isSameOrigin && libraryDetails.isIPProtected && (
                    <CustomABBInput
                        dataType={passwordType ? "password" : "text"}
                        placeholder={
                            FUNCTION_MODAL.LIBRARY_PASSWORD_PLACEHOLDER_TEXT
                        }
                        value={password}
                        onValueChange={(value: string) => {
                            if (libraryGlobalError.length > 0) {
                                resetGlobalLibraryError();
                            }
                            setPassword(value);
                        }}
                        icon={passwordType ? "abb/view" : "abb/hide"}
                        onIconClick={() => {
                            if (password.length > 0 || passwordType === false) {
                                togglePasswordType();
                            }
                        }}
                        validator={() =>
                            libraryGlobalError.length > 0
                                ? { valid: false, text: libraryGlobalError }
                                : { valid: true, text: "OK!" }
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                )}

                {!libraryDetails.isIPProtected &&
                    isSameOrigin &&
                    (currentFunction
                        ? `Are you sure you want to remove ${currentFunction.name} from ${libraryDetails.root}?`
                        : `Are you sure you want to delete ${libraryDetails.root}?`)}

                {libraryDetails.isIPProtected &&
                    !isSameOrigin &&
                    currentFunction &&
                    `Are you sure you want to remove ${currentFunction.name} from ${libraryDetails.root}?`}
            </div>
            <div className="delete-button">
                <Button
                    type="primary-blue"
                    sizeClass="small"
                    disabled={
                        !password.length &&
                        libraryDetails.isIPProtected &&
                        isSameOrigin
                    }
                    text={
                        deletingLibrary || removingFunctionsFromExistingLibrary
                            ? `${
                                  type === "Remove"
                                      ? "Removing..."
                                      : "Deleting..."
                              }`
                            : submitBtnText
                    }
                    onClick={handleDelete}
                    isLoading={
                        deletingLibrary || removingFunctionsFromExistingLibrary
                    }
                />
            </div>

            <div className="delete-button">
                <Button
                    type="discreet-black"
                    sizeClass="small"
                    disabled={
                        deletingLibrary || removingFunctionsFromExistingLibrary
                    }
                    text="Cancel"
                    onClick={closeModalForLibraryDelete}
                />
            </div>
            {libraryDetails.isIPProtected && (
                <div>
                    <p>
                        <span className="note">{NOTE_MESSAGE}</span>
                        <span className="admin_help_message">
                            {ADMIN_HELP_MESSAGE}
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        deletingLibrary: state.functions.deletingLibrary,
        removingFunctionsFromExistingLibrary:
            state.functions.removingFunctionsFromExistingLibrary,
        libraryGlobalError: state.functions.libraryGlobalError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        closeModalForLibraryDelete: () => dispatch(hideModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLibraryModal);
