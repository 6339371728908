import _ from 'lodash';
import { AssetTypeDetail } from '..';
import { IODataTypes, AssetsType, AlarmEventType } from '../../../components/Fabric/types';
import { AlarmTypeConstructorOptions } from './types';
import { alarmOutputConverter, alarmInputConverter } from '../utils/alarmIOConverter';

class AlarmTypeDetail extends AssetTypeDetail {
    outputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        isPublished?: boolean;
        connected: boolean;
        alarmId?: string;
        eventId?: string;
    }[];
    inputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        connected: boolean;
        defaultValue?: string;
    }[];
    assetType: AssetsType;
    modelId: string;
    type: AlarmEventType;

    constructor(options: AlarmTypeConstructorOptions) {
        super(options);
        const { properties } = options;

        this.outputs = [];
        this.inputs = [];

        this.assetType = AssetsType.Alarm;

        this.modelId = options.modelId;
        this.type = options.type;

        if (properties) {
            const { outputs, inputs } = properties;
            const inputKeys = Object.keys(inputs);

            this.outputs = alarmOutputConverter({
                alarmId: this.assetRef,
                modelId: this.modelId,
                outputObj: outputs,
                nodeId: this.nodeId,
                alarmType: this.type,
            });
            this.inputs = alarmInputConverter({
                keys: inputKeys,
                object: inputs,
                refPath: `inputs`,
                nodeId: this.nodeId,
            });
        }
    }
}

export function CreateAlarmTypeDetail(options: AlarmTypeConstructorOptions) {
    const { typeId, properties } = options;

    if (!(typeId || properties)) {
        return null;
    }

    return new AlarmTypeDetail(options);
}

export default AlarmTypeDetail;
