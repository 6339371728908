import React, { useState, useEffect, useMemo, useRef } from "react";
import "./style.scss";
import "../customStyle.scss";
import * as _ from "lodash";
import {
    Input,
    Button,
    Dropdown,
    DropdownOption,
    Icon,
    Tooltip,
    WithTooltip,
} from "@abb/abb-common-ux-react";
import { StoreState } from "../../../store";
import { connect } from "react-redux";
import { getNameError } from "../CreateModal/modalValidations";
import { AssetsInfo, FunctionComponents } from "../type";
import MessageModal from "../MessageModal";
import { hideDialog, showDialog } from "../../../store/dialog/action";
import {
    CONFIRMATION_BUTTON,
    CONFIRMATION_TITLE,
    CONFIRMATION_ENDPOINT_DELETE,
} from "../../../utils/constants/uiConstants";
import { Dispatch } from "redux";
import {
    FunctionMode,
    FunctionSaveType,
    UpdateActiveFunctionType,
    IEndPointList,
} from "../../../store/function/types";
import {
    clearDropTableMapping,
    handleFunctionMode,
    handleFunctionSave,
    updateFunctionTabNavList,
    handleUniqueTypeIdFunction,
    handleSaveFunctionEndPoint,
    handleDeleteFunctionEndPoint,
} from "../../../store/function/action";
import {
    BUTTON_TITLE,
    FUNCTION_MODE,
    FUNCTION_SAVE_TYPE,
} from "../../../utils/constants/appConstants";
import {
    checkVersionUpdateStatus,
    validURL,
    isValidHttpUrl,
} from "../../../utils/helpers";
import { AbilityService } from "sce-engg-model-19.09";
import { hideModal } from "../../../store/modal/action";
import { ModalFooter } from "reactstrap";
import {
    checkDuplicateTypeId,
    checkForChangeInHeaderDetails,
    checkForFunctionDuplicateTypeIdAndName,
} from "../helper";
import {
    VersionUpdateType,
    VERSION_UPDATE_TYPE,
} from "../../../store/configurationTool/types";
import { VALIDATION_MESSAGE } from "../../../utils/constants/uiConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface AppProps {
    addToEditFunction?: any;
    assetsDetails?: any;
    toggle?: () => void;
    endPointDisable?: boolean;
    isViewReadOnly?: boolean;
    close?: () => void;
    functionSaveType?: FunctionSaveType;
}

export const whiteListUrl: string[] = [
    "http://rbemodule:5003/api/Evaluation/evaluate",
    "http://rbemodule:5003/api/Evaluation/evaluateCalculations",
];

export function EditFunctionInfo(
    props: AppProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) {
    const [name, updateName] = useState("");
    const [description, updateDescription] = useState("");
    const [typeId, updateTypeId] = useState("");
    const [endpoint, updateEndPoint] = useState("");
    const [version, updateVersion] = useState(
        props.functionMode === FUNCTION_MODE.VIEW ? "1.0.0" : ""
    );
    const [tags, updateTags] = useState("");
    const [errorName, updateErrorName] = useState(false);
    const [errorTypeId, updateErrorTypeId] = useState(false);
    const [errorDescription, updateErrorDescription] = useState(false);
    const [errorVersion, updateErrorVersion] = useState(false);
    const [errorEndPoint, updateErrorEndPoint] = useState(false);
    const [errorTags, updateErrorTags] = useState(false);
    const [errorTypeIdText, updateErrorTypeIdText] = useState(
        "Please Enter Type ID"
    );
    const [errorVersionText, updateErrorVersionText] = useState(
        "Please Enter Version"
    );
    const [errorEndPointText, updateErrorEndPointText] = useState(
        "Invalid endpoint url"
    );
    const [errorExist, seterrorExist] = useState(false);
    const [errorMessage, updateErrorMessage] = useState(" ");
    const [errorNameText, updateErrorNameText] = useState("");
    const isViewReadOnly = props.isViewReadOnly || false;

    const [endPointOptionArray, setEndPointOption] = useState(
        [] as IEndPointList[]
    );
    const [selectedValue, updateSelectedValue] = useState(
        [] as IEndPointList[]
    );
    const [isNewEndPoint, setIsNewEndPoint] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isPrefixEnabled, setPrefixEnabled] = useState(true);

    const menuRef: any = useRef();

    const genericFunctionList = useMemo(() => {
        let functionList: UpdateActiveFunctionType[] = [];
        props.libraryAndFunctionList.forEach((item) => {
            functionList = [...functionList, ...item.nodes];
        });
        return functionList;
    }, [props.libraryAndFunctionList]);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleOutsideClicks, true);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(
                "mousedown",
                handleOutsideClicks,
                true
            );
        };
    }, [isMenuOpen]);

    const handleOutsideClicks = (event: any) => {
        // const containerMenuId: string = !event.target.id == "endpointoption"
        if (!menuRef.current) {
            setMenuOpen(false);
        } else {
            if (
                isMenuOpen &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !event.target.classList.contains("endPointWrapper") &&
                event.target.id !== "endpointoption" &&
                event.target.id !== "endpointdeletecontent" &&
                !event.target.classList.contains("deleteendpoint") &&
                !event.target.classList.contains(
                    "ABB_CommonUX_Icon__icon_abb_16"
                )
            ) {
                setMenuOpen(false);
            }
        }
    };

    useEffect(() => {
        if (props.assetsDetails) {
            props.functionSaveType !== FUNCTION_SAVE_TYPE.SAVE_AS &&
                updateName(props.assetsDetails.name);
            updateDescription(props.assetsDetails.description);
            if (
                props.functionSaveType !== FUNCTION_SAVE_TYPE.SAVE_AS &&
                props.assetsDetails.typeId !== ""
            ) {
                const itemOne = props.assetsDetails.typeId
                    .toLowerCase()
                    .includes(".tenantid.")
                    ? props.assetsDetails.typeId
                          .split(".")
                          .reverse()
                          .splice(2)
                          .reverse()
                          .join(".")
                    : props.assetsDetails.typeId;
                const itemTwo = itemOne.toLowerCase().includes("abb.local.")
                    ? itemOne.split(".").splice(2).join(".")
                    : itemOne;
                updateTypeId(itemTwo);
            }
            props.functionSaveType === FUNCTION_SAVE_TYPE.SAVE_AS
                ? updateVersion("1.0.0")
                : updateVersion(props.assetsDetails.version);
            if (props.assetsDetails.endpoint) {
                const isHttpExists: boolean =
                    props.assetsDetails.endpoint.startsWith("http");
                if (isHttpExists) {
                    setPrefixEnabled(false);
                }
            }
            updateEndPoint(props.assetsDetails.endpoint);
            updateSelectedValue([
                {
                    label: props.assetsDetails.endpoint,
                    value: props.assetsDetails.endpoint,
                },
            ]);
            setIsNewEndPoint(false);
            if (
                props.assetsDetails.tags &&
                Array.isArray(props.assetsDetails.tags) &&
                props.assetsDetails.tags.length > 0
            ) {
                const temptTag = [...props.assetsDetails.tags];
                const nameType =
                    props.assetsDetails.tags[
                        props.assetsDetails.tags.length - 1
                    ].split("=")[0];
                const item =
                    nameType == "TenantId" ||
                    nameType == "Source" ||
                    nameType === "SourceId"
                        ? temptTag.reverse().slice(2).reverse().join()
                        : props.assetsDetails.tags.join();
                updateTags(item);
            }
        }
    }, [props.assetsDetails]);
    useEffect(() => {
        if(!props.isUniqueTypeIdLoading) {
            if (props.isExist === '' && props.message == "") {
            seterrorExist(false);
            updateErrorMessage(" ");
        } else if (props.isExist.length) {
            if(props.isExist === 'name') {
                updateErrorNameText("Function Name already exists");
            updateErrorName(true)
            } else if(props.isExist === 'typeId') {
                updateErrorTypeIdText("Type ID already exists");
            updateErrorTypeId(true);
            } else {
                seterrorExist(true);
            updateErrorMessage("TypeId Already Exists");
            }
            
        }
        }
        
    }, [props.isExist, props.message, props.isUniqueTypeIdLoading]);

    useEffect(() => {
        if (props.endpointUrlLists && props.endpointUrlLists.length > 0) {
            let functionEndPointList: IEndPointList[] = [];
            props.endpointUrlLists.forEach((item: string) => {
                functionEndPointList.push({
                    value: item,
                    label: item,
                });
            });

            setEndPointOption(functionEndPointList);
        }
    }, [props.endpointUrlLists]);
    const handleSubmit = () => {
        let returnVal: boolean = true;
        let overallVersionUpdateType: VersionUpdateType = "DEFAULT";
        if (!name) {
            returnVal = false;
            updateErrorName(true);
        }
        if (name) {
            var patt = new RegExp(
                "^(?=.{1,150}$)[\\w\\-]([\\w\\-]|[\\.][\\w\\-])*$"
            );
            var res = patt.test(name);
            if (!res) {
                returnVal = false;
                updateErrorNameText(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
                updateErrorName(true);
            }
        }
        if (getNameError(name, 40) !== "") {
            returnVal = false;
        }

        if (!typeId) {
            returnVal = false;
            updateErrorTypeId(true);
        }

        if (typeId) {
            var patt = new RegExp(
                "^(?=.{1,150}$)[\\w\\-]([\\w\\-]|[\\.][\\w\\-])*$"
            );
            var res = patt.test(typeId);
            if (!res) {
                returnVal = false;
                updateErrorTypeIdText(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
                updateErrorTypeId(true);
            }
        }

        if (!version) {
            returnVal = false;
            updateErrorVersion(true);
        }

        if (version) {
            var patt = new RegExp("^[0-9]+[\\.]+[0-9]+[\\.]+[0-9]+$");
            var res = patt.test(version);
            if (!res || version === "0.0.0") {
                returnVal = false;
                updateErrorVersionText("Invalid Version");
                updateErrorVersion(true);
            }
        }

        if (!endpoint) {
            returnVal = false;
            updateErrorEndPointText("Please Enter EndPoint");
            updateErrorEndPoint(true);
        }

        let endPointHttp: string = endpoint;
        if (endpoint) {
            endPointHttp = isHttpExists(endpoint);
            const isValidHttpEndpointURL = isValidHttpUrl(endPointHttp);
            if (!isValidHttpEndpointURL) {
                returnVal = false;
                updateErrorEndPointText("Invalid Endpoint URL");
                updateErrorEndPoint(true);
            }

            const isValidEndpointSlashURL = validURL(endPointHttp);
            if (!isValidEndpointSlashURL) {
                returnVal = false;
                updateErrorEndPointText("Invalid Endpoint URL");
                updateErrorEndPoint(true);
            }
        }

        if (
            endPointHttp &&
            props.typeDetails &&
            props.functionMode === FUNCTION_MODE.EDIT
        ) {
            if (
                endPointHttp !==
                    props.typeDetails.properties.settings.endpoint.value ||
                tags !== props.typeDetails.tags[0]
            ) {
                const latestAssetsInfo = {
                    typeId,
                    version,
                    name,
                    description,
                    tags: tags
                        ? tags
                              .split(",")
                              .concat(`Source=${AbilityService.tenantId}`)
                              .concat(`TenantId=${AbilityService.tenantId}`)
                        : [
                              `Source=${AbilityService.tenantId}`,
                              `TenantId=${AbilityService.tenantId}`,
                          ],
                    endpoint: endPointHttp,
                };
                let versionType: VersionUpdateType =
                    checkForChangeInHeaderDetails(
                        props.functionHeaderDetails,
                        props.typeDetails!,
                        props.alarmMapping!,
                        latestAssetsInfo
                    );

                const options = checkVersionUpdateStatus({
                    oldVersion: props.typeDetails.version as string,
                    currentVersion: version as string,
                    versionUpdateType: versionType as VersionUpdateType,
                });
                const { errorVersionUpdateText, successVersionUpdate } =
                    options;
                overallVersionUpdateType = versionType;
                if (!successVersionUpdate) {
                    returnVal = false;
                    updateErrorVersionText(errorVersionUpdateText);
                    updateErrorVersion(true);
                }
            }
        }
        // To check duplicated typeId and name during Create and SaveAs.

        let { isTypeIdExist, isNameExist } =
            checkForFunctionDuplicateTypeIdAndName(
                typeId,
                name,
                genericFunctionList
            );
        if (props.functionSaveType === FUNCTION_SAVE_TYPE.UPDATE) {
            const assetName = props.typeDetails!.name;
            const assetTypeId = props.typeDetails!.typeId;
            if (
                checkDuplicateTypeId({
                    typeId: typeId,
                    originalTypeId: assetTypeId,
                })
            ) {
                isTypeIdExist = false;

                if (name === assetName) {
                    isNameExist = false;
                }
            }
        }
        if (isTypeIdExist) {
            returnVal = false;
            updateErrorTypeIdText("Type ID already exists");
            updateErrorTypeId(true);
        }
        if (isNameExist) {
            returnVal = false;
            updateErrorNameText("Function Name already exists");
            updateErrorName(true);
        }
        const latestAssetsInfo = {
            typeId,
            version,
            name,
            description,
            tags: tags
                ? tags
                      .split(",")
                      .concat(`Source=${AbilityService.tenantId}`)
                      .concat(`TenantId=${AbilityService.tenantId}`)
                : [
                      `Source=${AbilityService.tenantId}`,
                      `TenantId=${AbilityService.tenantId}`,
                  ],
            endpoint: endPointHttp,
        };

        if (props.functionMode === FUNCTION_MODE.EDIT && returnVal) {
            let versionType: VersionUpdateType = checkForChangeInHeaderDetails(
                props.functionHeaderDetails,
                props.typeDetails!,
                props.alarmMapping!,
                latestAssetsInfo
            );

            if (versionType === undefined) {
                versionType = VERSION_UPDATE_TYPE.MAJOR;
            }

            if (
                (overallVersionUpdateType === "MINOR" &&
                    versionType === "MINOR") ||
                overallVersionUpdateType === "DEFAULT"
            ) {
                if (props.typeDetails) {
                    const options = checkVersionUpdateStatus({
                        oldVersion: props.typeDetails.version as string,
                        currentVersion: version as string,
                        versionUpdateType: versionType,
                    });

                    const { errorVersionUpdateText, successVersionUpdate } =
                        options;
                    if (!successVersionUpdate) {
                        if (versionType !== VERSION_UPDATE_TYPE.DEFAULT) {
                            returnVal = false;
                            updateErrorVersionText(errorVersionUpdateText);
                            updateErrorVersion(true);
                        }
                        if (versionType === VERSION_UPDATE_TYPE.DEFAULT) {
                            returnVal = false;
                            updateErrorVersionText(
                                "Version update is not required No new changes detected!"
                            );
                            updateErrorVersion(true);
                        }
                    }
                }
            }
        }
        if (name && typeId && version && endpoint && returnVal) {
            const assetsInfo: AssetsInfo = {
                name,
                typeId: `abb.local.${typeId}.TenantId.${AbilityService.tenantId}`,
                description,
                endpoint: endPointHttp,
                version,
                tags: tags
                    ? tags
                          .split(",")
                          .concat(`Source=${AbilityService.tenantId}`)
                          .concat(`TenantId=${AbilityService.tenantId}`)
                    : [
                          `Source=${AbilityService.tenantId}`,
                          `TenantId=${AbilityService.tenantId}`,
                      ],
            };
            if (
                props.functionMode === FUNCTION_MODE.VIEW &&
                props.functionSaveType !== FUNCTION_SAVE_TYPE.SAVE_AS
            ) {
                const payload = {
                    modelDetails: {
                        ...assetsInfo,
                    },
                };
                props.handleUniqueTypeIdFunction({
                    details: payload,
                    mode: FUNCTION_MODE.CREATE,
                    saveType: "CREATE",
                });
                props.updateTabNavList([]);
                props.clearDropTableMapping();
            } else {
                const payload: AssetsInfo = {
                    ...assetsInfo,
                };

                if (props.functionSaveType !== FUNCTION_SAVE_TYPE.SAVE_AS) {
                    props.addToEditFunction && props.addToEditFunction(payload);
                    if (props.functionSaveType === FUNCTION_SAVE_TYPE.UPDATE) {
                        props.handleFunctionMode(FUNCTION_MODE.EDIT);
                    }
                    props.close && props.close();
                } else {
                    props.handleUniqueTypeIdFunction({
                        details: assetsInfo,
                        mode: FUNCTION_MODE.EDIT,
                        saveType: "SAVE_AS",
                    });
                }
            }

            if (endPointOptionArray && endPointOptionArray.length > 0) {
                const endPointExists = endPointOptionArray.find(
                    (endPointUrl: IEndPointList) => {
                        return endPointUrl.value == isHttpExists(endpoint);
                    }
                );
                if (!endPointExists) {
                    props.handleFunctionEndPointSave(endpoint);
                }
            }
        }
        return returnVal;
    };

    const deleteEndPoint = (endPoint: string) => {
        props.showConfirmationDialog(
            endPoint,
            props,
            endPointOptionArray,
            setEndPointOption
        );
    };

    const removeEndpoint = (optionVal: string) => {
        const endPointData = endPointOptionArray.filter(
            (endPoint: IEndPointList) => {
                return endPoint.value !== optionVal;
            }
        );
        setEndPointOption(endPointData);
        const urlExists = props.endpointUrlLists.findIndex((data: string) => {
            return data === optionVal;
        });
        if (urlExists !== -1) {
            props.endpointUrlLists.splice(urlExists);
        }
    };

    const isHttpExists = (endpoint: string) => {
        let endPointHttp: string = endpoint;
        const isHttpExists: boolean = endpoint.startsWith("http");
        if (!isHttpExists) {
            endPointHttp = "http://" + endpoint;
        }
        return endPointHttp;
    };

    return (
        <div>
            <form>
                <div className="form-wrapper functionList text-capitalize">
                    <div className="form-wrapper__child">
                        <div className="custom-form-group-function">
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                label="Function Name"
                                disabled={
                                    isViewReadOnly ||
                                    (props.functionSaveType &&
                                        props.functionSaveType ===
                                            FUNCTION_SAVE_TYPE.UPDATE)
                                }
                                placeholder="Enter Function Name"
                                value={name}
                                onValueChange={(val: any) => {
                                    updateName(val);
                                    updateErrorNameText("");
                                    if (getNameError(val, 40) !== "") {
                                        updateErrorName(true);
                                    } else {
                                        updateErrorName(false);
                                    }
                                }}
                                className="functionClass required"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={true}
                                validator={() =>
                                    errorName
                                        ? {
                                              valid: false,
                                              text: errorNameText
                                                  ? errorNameText
                                                  : getNameError(name, 40),
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </div>

                        <div className="custom-form-group-function">
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                disabled={
                                    isViewReadOnly ||
                                    (props.functionSaveType &&
                                        props.functionSaveType ===
                                            FUNCTION_SAVE_TYPE.UPDATE)
                                }
                                label="Type ID"
                                value={typeId}
                                placeholder="Enter Type ID"
                                onValueChange={(val: any) => {
                                    updateTypeId(val);
                                    updateErrorTypeId(false);
                                    if (errorTypeIdText) {
                                        updateErrorTypeIdText("");
                                        updateErrorTypeId(false);
                                    }
                                }}
                                className="functionClass required"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={false}
                                validator={() => {
                                    if (errorTypeId) {
                                        return {
                                            valid: false,
                                            text: errorTypeIdText,
                                        };
                                    } else {
                                        return { valid: true, text: "OK!" };
                                    }
                                }}
                            />
                        </div>
                        <div className="custom-form-group-function">
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                label="Description"
                                disabled={isViewReadOnly}
                                placeholder="Enter Description"
                                value={description}
                                onValueChange={(val: any) =>
                                    updateDescription(val)
                                }
                                className="functionClass"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={false}
                                validator={() =>
                                    errorDescription
                                        ? {
                                              valid: false,
                                              text: "Please Enter Description",
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </div>

                        <div className="custom-form-group-function">
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                label="Version"
                                disabled={isViewReadOnly}
                                placeholder="Enter Version"
                                value={version}
                                onValueChange={(val: any) => {
                                    updateVersion(val);
                                    updateErrorVersion(false);
                                }}
                                className="functionClass required"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={false}
                                validator={() =>
                                    errorVersion
                                        ? {
                                              valid: false,
                                              text: errorVersionText,
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </div>

                        <div className="custom-form-group-function">
                            <WithTooltip>
                                <div className="endpoint_content">
                                    <CustomABBInput
                                        type="normal"
                                        dataType="text"
                                        label="Endpoint"
                                        showClearIcon={true}
                                        disabled={isViewReadOnly}
                                        placeholder=" Enter or search endpoint"
                                        value={endpoint}
                                        onValueChange={(val: any) => {
                                            updateEndPoint(val);
                                            updateErrorEndPoint(false);
                                            updateErrorEndPointText("");
                                            if (val) {
                                                const isHttpExists: boolean =
                                                    val.startsWith("http");
                                                if (isHttpExists) {
                                                    setPrefixEnabled(false);
                                                }
                                            }
                                            if (!val) {
                                                setMenuOpen(true);
                                                setDisabled(true);
                                                setPrefixEnabled(true);
                                            }
                                        }}
                                        onGotFocus={(e: any) => {
                                            setMenuOpen(true);
                                        }}
                                        className="functionClass required endpointlowerCase nospacehttp"
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={false}
                                        prefix={
                                            isPrefixEnabled ? "http://" : ""
                                        }
                                        validator={() =>
                                            errorEndPoint
                                                ? {
                                                      valid: false,
                                                      text: errorEndPointText,
                                                  }
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                    <div
                                        className={window.location.pathname === '/functions' ? 'menuContainerFunction' : 'menuContainer'}
                                        id="endpointoption"
                                    >
                                        <div className="menuContainerWrapper">
                                            {isMenuOpen &&
                                                endPointOptionArray &&
                                                endPointOptionArray
                                                    .filter(
                                                        (
                                                            endpointList: IEndPointList
                                                        ) =>
                                                            endpointList.value
                                                                .toLowerCase()
                                                                .includes(
                                                                    endpoint
                                                                )
                                                    )
                                                    .map(
                                                        (
                                                            option: IEndPointList,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                className="endPointContent endPointWrapper"
                                                                key={
                                                                    "endpoint" +
                                                                    index
                                                                }
                                                                ref={menuRef}
                                                            >
                                                                <div
                                                                    className="endPointWrapper endpointTextContainer"
                                                                    onClick={(
                                                                        evt: any
                                                                    ) => {
                                                                        updateEndPoint(
                                                                            option.value
                                                                        );
                                                                        const optionValue: boolean =
                                                                            option.value.startsWith(
                                                                                "http"
                                                                            );
                                                                        if (
                                                                            optionValue
                                                                        ) {
                                                                            setPrefixEnabled(
                                                                                false
                                                                            );
                                                                        }
                                                                        setMenuOpen(
                                                                            false
                                                                        );
                                                                        updateErrorEndPoint(
                                                                            false
                                                                        );
                                                                        updateErrorEndPointText(
                                                                            ""
                                                                        );
                                                                        setDisabled(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    <span className="endPointWrapper endPointText">
                                                                        {
                                                                            option.value
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {whiteListUrl &&
                                                                    !whiteListUrl.includes(
                                                                        option.value
                                                                    ) && (
                                                                        <div
                                                                            className="deleteendpoint"
                                                                            id="endpointdeletecontent"
                                                                            onClick={(
                                                                                e: any
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                deleteEndPoint(
                                                                                    option.value
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                name="abb/trash"
                                                                                sizeClass="small"
                                                                                className="list-icon deleteendpoint tfin"
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )
                                                    )}
                                        </div>
                                    </div>
                                </div>
                                <Tooltip disabled={disabled}>
                                    <p>{endpoint ? endpoint : ""}</p>
                                </Tooltip>
                            </WithTooltip>
                        </div>

                        <div className="custom-form-group-function">
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                label="Tags"
                                placeholder="Enter Tags"
                                value={tags}
                                disabled={isViewReadOnly}
                                onValueChange={(val: any) => {
                                    updateTags(val);
                                    updateErrorTags(false);
                                }}
                                className="functionClass"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={false}
                                validator={() =>
                                    errorTags
                                        ? {
                                              valid: false,
                                              text: "Please Enter Tags",
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </div>
                        {!isViewReadOnly &&
                            props.functionMode !== FUNCTION_MODE.VIEW && (
                                <div className="submitDefault default-float-width margin-bottom-30">
                                    <Button
                                        text={BUTTON_TITLE.SET}
                                        sizeClass="small"
                                        shape="rounded"
                                        type="primary-blue"
                                        style={{
                                            float: "right",
                                            marginTop: "-2%",
                                        }}
                                        className="createFunction"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            )}
                    </div>
                </div>

                {!isViewReadOnly && props.functionMode === FUNCTION_MODE.VIEW && (
                    <ModalFooter>
                        <Button
                            text={
                                props.functionSaveType !==
                                FUNCTION_SAVE_TYPE.SAVE_AS
                                    ? BUTTON_TITLE.SET
                                    : BUTTON_TITLE.SAVE
                            }
                            sizeClass="small"
                            shape="rounded"
                            type="primary-blue"
                            style={{
                                float: "right",
                                marginTop: "-2%",
                            }}
                            className="createFunction"
                            isLoading={props.isUniqueTypeIdLoading}
                            onClick={handleSubmit}
                        />
                    </ModalFooter>
                )}
            </form>
        </div>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        functionMode: state.functions.functionMode,
        libraryAndFunctionList: state.functions.libraryAndFunctionsList,
        functionHeaderDetails: state.functions.functionHeaderDetails,
        typeDetails: state.functions.typeDetails,
        versionUpdate: state.functions.versionUpdate,
        assetsInfo: state.functions.assetsInfo,
        alarmMapping: state.functions.alarmMapping,
        isExist: state.functions.isExist,
        message: state.functions.message,
        isUniqueTypeIdLoading: state.functions.isUniqueTypeIdLoading,
        endpointUrlLists: state.functions.endPointUrlLists,
    };
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        handleFunctionMode: (functionMode: FunctionMode) => {
            dispatch(handleFunctionMode(functionMode));
        },
        // addToCreateFunction: (payload: any) => {
        //     dispatch(createNewFunction(payload));
        //     dispatch(hideModal());
        // },
        updateTabNavList: (tabNavList: FunctionComponents[]) => {
            dispatch(updateFunctionTabNavList(tabNavList));
        },
        clearDropTableMapping: () => {
            dispatch(clearDropTableMapping());
        },
        // updateAssetInfo: (payload: { assetsInfo: AssetsInfo }) => {
        //     dispatch(updateAssetInfo(payload));
        // },
        handleFunctionSave: (saveType: FunctionSaveType) => {
            dispatch(handleFunctionSave(saveType));
            dispatch(hideModal());
        },
        handleUniqueTypeIdFunction: (payload: {
            details: any | { assetsInfo: AssetsInfo };
            saveType: FunctionSaveType;
            mode: FunctionMode;
        }) => {
            dispatch(handleUniqueTypeIdFunction(payload));
        },
        handleFunctionEndPointSave: (endpoint: string) => {
            let endPointHttp: string = endpoint;
            const isHttpExists: boolean = endpoint.startsWith("http");
            if (!isHttpExists) {
                endPointHttp = "http://" + endpoint;
            }
            dispatch(handleSaveFunctionEndPoint({ endpoint: endPointHttp }));
        },

        showConfirmationDialog: (
            endPoint: string,
            endPointProps: any,
            endPointOptionArray: IEndPointList[],
            setEndPointOption: any
        ) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_TITLE.DELETE_MODEL,
                    customClassName: "wrapper-message-modal",
                    data: {
                        warningText: CONFIRMATION_ENDPOINT_DELETE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: "discreet-black",
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.OK,
                                type: "primary-blue",
                                handler: (dlg: any) => {
                                    if (endPoint) {
                                        dispatch(
                                            handleDeleteFunctionEndPoint({
                                                endpoint: endPoint,
                                            })
                                        );
                                        const endPointData =
                                            endPointOptionArray.filter(
                                                (
                                                    endPointList: IEndPointList
                                                ) => {
                                                    return (
                                                        endPointList.value !==
                                                        endPoint
                                                    );
                                                }
                                            );
                                        setEndPointOption(endPointData);
                                        const urlExists =
                                            endPointProps.endpointUrlLists.findIndex(
                                                (data: string) => {
                                                    return data === endPoint;
                                                }
                                            );
                                        if (urlExists !== -1) {
                                            endPointProps.endpointUrlLists.splice(
                                                urlExists
                                            );
                                        }
                                        dispatch(hideDialog());
                                    }
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFunctionInfo);
