import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { StoreState } from '../../../../store';
import {
    getNewUniqueName,
    convertedToJSONObj,
    convertToCalculationArray,
} from '../../../../routes/Functions/helper';
import { FunctionMode, FunctionHeaderTypeDetails } from '../../../../store/function/types';
import { Calculation } from '../../../../routes/Functions/constants';
import CalculationLogicTitle from './CalculationLogicTitle';
import { FUNCTION_MODE } from '../../../../utils/constants/appConstants';
import { CONFIRMATION_BUTTON } from '../../../../utils/constants/uiConstants';
import { updateCalculationDeleteStatus } from '../../../../store/function/action';
import { dropDownOptions } from '../../../../routes/Functions/type';
import { ShowMessageDispatchProps } from '../../../../routes/Functions';

interface CalculationLogicFunctionTypeProps extends ReturnType<typeof mapStateToProps> {
    calculationData?: any;
    functionMode: FunctionMode;
    droppedItem?: boolean;
    handleCalculation?: (data: any, delFlag?: boolean) => void;
    handleCalculationError?: (value: boolean) => void;
    isEncrypted: boolean;
}

function CalculationLogicFunctionType(
    props: CalculationLogicFunctionTypeProps &
        ReturnType<typeof mapDispatchToProps> &
        ShowMessageDispatchProps
) {
    console.log('props :', props);
    const [deleteModal, setDeleteModal] = useState(false);
    const [calculationFunctionTypeDetails, updateCalculationFunctionTypeDetails] = useState(
        [] as any[]
    );

    const [isSelected, setIsSelected] = useState(false);

    const handleAddCalculations = () => {
        let calculationDetails =
            props.functionHeaderDetails.calculationDetails &&
            props.functionHeaderDetails.calculationDetails;
        const calculationArray = convertToCalculationArray(calculationDetails);
        const name = getNewUniqueName(
            calculationDetails,
            Calculation,
            props.functionHeaderDetails.outputDetails
        );
        const newCalculationObject = {
            name: name,
            outputType: {
                dataType: dropDownOptions.integer,
            },
            description: { dataType: 'string', value: '' },
            logic: { dataType: 'string', value: '' },
        };
        props.handleCalculation &&
            props.handleCalculation(
                convertedToJSONObj([...calculationArray, newCalculationObject])
            );
        updateCalculationFunctionTypeDetails([...calculationArray, newCalculationObject]);
    };

    useEffect(() => {
        const calculationDetails =
            props.functionHeaderDetails.calculationDetails &&
            props.functionHeaderDetails.calculationDetails;
        updateCalculationFunctionTypeDetails(convertToCalculationArray(calculationDetails));
    }, []);

    useEffect(() => {
        const calculationDetails = props.functionHeaderDetails.calculationDetails;
        if (calculationDetails) {
            let selectedCalculationDetailsLength = 0;
            Object.keys(calculationDetails).forEach((item) => {
                if (
                    calculationDetails[item].hasOwnProperty('isDelete') &&
                    calculationDetails[item].isDelete
                ) {
                    selectedCalculationDetailsLength++;
                }
            });
            if (Object.keys(calculationDetails).length === selectedCalculationDetailsLength) {
                setIsSelected(true);
            } else {
                setIsSelected(false);
            }
        }
    }, [props.functionHeaderDetails.calculationDetails]);

    return (
        <>
            <div className="CustomFunctionView conditionFunctionOverview">
                {calculationFunctionTypeDetails.map((calculationData: any, index: number) => {
                    return (
                        <CalculationLogicTitle
                            calculationData={calculationData}
                            calculationDataIndex={index}
                            functionMode={props.functionMode}
                            calculationDetails={calculationFunctionTypeDetails}
                            handleCalculationError={props.handleCalculationError}
                            isEncrypted={props.isEncrypted}
                        />
                    );
                })}
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};
function mapStateToProps(state: StoreState) {
    return {
        functionHeaderDetails: state.exportAsset.functionHeaderDetails,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationLogicFunctionType);
