import React from 'react';
import { connect } from 'react-redux';

import './styles.scss';
import { StoreState } from '../../store';
import { hideModal } from '../../store/modal/action';
import { ModalHeader, ModalBody, Modal } from 'reactstrap';

interface AppProps extends ReturnType<typeof mapStateToProps> {}
type AppMethods = ReturnType<typeof mapDispatchToProps>;

export function CustomModal(props: AppProps & AppMethods) {
    return props.isOpen && props.component ? (
        <Modal
            centered
            isOpen={props.isOpen}
            className={`${props.customClassName} create-model-modal`}
        >
            <ModalHeader
                toggle={() => {
                    if (props.customFunctionBeforeClose) props.customFunctionBeforeClose();
                    props.closeModal();
                }}
            >
                {props.modalTitle}
            </ModalHeader>
            <ModalBody>
                {props.component ? (
                    <props.component
                        {...props.data}
                        handleClose={() => {
                            if (props.customFunctionBeforeClose) props.customFunctionBeforeClose();
                            props.closeModal();
                        }}
                    />
                ) : (
                    <></>
                )}
            </ModalBody>
        </Modal>
    ) : (
        <></>
    );
}

const mapStateToProps = (state: StoreState) => {
    const appProps = {
        isOpen: state.modal.show,
        component: state.modal.component,
        data: state.modal.data || {},
        modalTitle: state.modal.modalTitle,
        customClassName: state.modal.customClassName,
        customFunctionBeforeClose: state.modal.closeModal,
    };

    return appProps;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => {
            dispatch(hideModal());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
