// import { SamplingAggregateValues } from "../../../utils/constants/appConstants";

import { parseInt } from 'lodash';
import { stringify } from 'querystring';
import { DurationType } from '../UIComponents/HistoryConfiguration/types';

const checkAddOrRemove = (unit: string, value: string, timeWindowState: string) => {
    let remove = false;
    let oldStateLength = 0;
    switch (unit) {
        case 'days':
            if (timeWindowState.split(':')[0]) {
                oldStateLength = timeWindowState.split(':')[0].length;
            }
            if (value.length < oldStateLength) {
                remove = true;
            }
            break;
        case 'hours':
            if (timeWindowState.split(':')[1]) {
                oldStateLength = timeWindowState.split(':')[1].length;
            }
            if (value.length < oldStateLength) {
                remove = true;
            }
            break;
        case 'minutes':
            if (timeWindowState.split(':')[2]) {
                oldStateLength = timeWindowState.split(':')[2].length;
            }
            if (value.length < oldStateLength) {
                remove = true;
            }
            break;
        case 'seconds':
            if (timeWindowState.split(':')[3]) {
                oldStateLength = timeWindowState.split(':')[3].length;
            }
            if (value.length < oldStateLength) {
                remove = true;
            }
            break;
    }
    return remove;
};

export const updateTimeWindow = (unit: string, value: string, timeWindowState: string) => {
    let updatedWindowState: string = '';
    const removing = checkAddOrRemove(unit, value, timeWindowState);
    switch (unit) {
        case 'days':
            updatedWindowState =
                value +
                ':' +
                (timeWindowState.split(':')[1] || (removing ? '' : '00')) +
                ':' +
                (timeWindowState.split(':')[2] || (removing ? '' : '00')) +
                ':' +
                (timeWindowState.split(':')[3] || (removing ? '' : '00'));
            break;
        case 'hours':
            updatedWindowState =
                (timeWindowState.split(':')[0] || (removing ? '' : '000')) +
                ':' +
                value +
                ':' +
                (timeWindowState.split(':')[2] || (removing ? '' : '00')) +
                ':' +
                (timeWindowState.split(':')[3] || (removing ? '' : '00'));
            break;
        case 'minutes':
            updatedWindowState =
                (timeWindowState.split(':')[0] || (removing ? '' : '000')) +
                ':' +
                (timeWindowState.split(':')[1] || (removing ? '' : '00')) +
                ':' +
                value +
                ':' +
                (timeWindowState.split(':')[3] || (removing ? '' : '00'));
            break;
        case 'seconds':
            updatedWindowState =
                (timeWindowState.split(':')[0] || (removing ? '' : '000')) +
                ':' +
                (timeWindowState.split(':')[1] || (removing ? '' : '00')) +
                ':' +
                (timeWindowState.split(':')[2] || (removing ? '' : '00')) +
                ':' +
                value;
            break;
        default:
            break;
    }
    return updatedWindowState;
};

export const historicalInputsValidator = (
    value: string,
    unit: String,
    requiredValueLength: number,
    maxLimitForValue: number
) => {
    let error = '';

    if (value.length > requiredValueLength) {
        error = `${unit} can be of only ${requiredValueLength} digits`;
    }
    if (Number(value) > maxLimitForValue) {
        error = `${unit} cannot be more than ${maxLimitForValue}`;
    }
    if (Number(value) < 0) {
        error = `Invalid ${unit}`;
    }
    return error;
};

export const isValidHistoryTimeInput = (historyTimeInput: string) => {
    if (!historyTimeInput || !historyTimeInput.length) {
        return false;
    }
    let validInput = true;
    const days = historyTimeInput.split(':')[0];
    const hours = historyTimeInput.split(':')[1];
    const minutes = historyTimeInput.split(':')[2];
    const seconds = historyTimeInput.split(':')[3];
    if (
        (!days || Number(days) === 0) &&
        (!hours || Number(hours) === 0) &&
        (!minutes || Number(minutes) === 0) &&
        (!seconds || Number(seconds) === 0)
    ) {
        validInput = false;
    }
    return validInput;
};

const padNumberWithZeroes = (number: string, length: number) => {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
};

export const convertTimeToDHMS = (duration: string, type: DurationType) => {
    let finalDHMSValue = '';
    if (!isNaN(parseInt(duration))) {
        const durationValue = parseInt(duration);

        switch (type) {
            case DurationType.Weeks: {
                const days = padNumberWithZeroes((durationValue * 7).toString(), 3);
                finalDHMSValue = `${days}:00:00:00`;
                break;
            }
            case DurationType.Days: {
                const days = padNumberWithZeroes(durationValue.toString(), 3);
                finalDHMSValue = `${days}:00:00:00`;
                break;
            }

            case DurationType.Hours: {
                const days = Math.floor(durationValue / 24);
                const hours = durationValue % 24;
                finalDHMSValue = `${padNumberWithZeroes(days.toString(), 3)}:${padNumberWithZeroes(
                    hours.toString(),
                    2
                )}:00:00`;
                break;
            }
            case DurationType.Minutes: {
                const minutes = durationValue % 60;
                const hours = Math.floor(durationValue / 60) % 24;
                const days = Math.floor(durationValue / (60 * 24));
                finalDHMSValue = `${padNumberWithZeroes(days.toString(), 3)}:${padNumberWithZeroes(
                    hours.toString(),
                    2
                )}:${padNumberWithZeroes(minutes.toString(), 2)}:00`;
                break;
            }

            case DurationType.Seconds: {
                const seconds = padNumberWithZeroes((durationValue % 60).toString(), 2);
                const minutes = padNumberWithZeroes(
                    (Math.floor(durationValue / 60) % 60).toString(),
                    2
                );
                const hours = padNumberWithZeroes(
                    (Math.floor(durationValue / 3600) % 24).toString(),
                    2
                );
                const days = padNumberWithZeroes(
                    Math.floor(durationValue / (3600 * 24)).toString(),
                    3
                );
                finalDHMSValue = `${days}:${hours}:${minutes}:${seconds}`;
            }

            default:
                break;
        }
    }

    return finalDHMSValue;
};

const getLocalDurationValue = (
    durationData: {
        weeks: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    },
    durationType: DurationType
) => {
    debugger;
    let durationValue: number = 0;
    const totalWeeks = durationData.weeks;
    const totalDays = totalWeeks * 7 + durationData.days;
    const totalhours = durationType !== DurationType.Days ? totalDays * 24 + durationData.hours : 0;
    const totalMinutes =
        durationType !== DurationType.Hours ? totalhours * 60 + durationData.minutes : 0;
    const totalSeconds =
        durationType !== DurationType.Minutes ? totalMinutes * 60 + durationData.seconds : 0;
    switch (durationType) {
        case DurationType.Seconds: {
            durationValue = totalSeconds;
            break;
        }
        case DurationType.Minutes: {
            durationValue = totalMinutes;
            break;
        }
        case DurationType.Hours: {
            durationValue = totalhours;
            break;
        }
        case DurationType.Days: {
            durationValue = totalDays;
            break;
        }
        case DurationType.Weeks: {
            durationValue = totalWeeks;
            break;
        }
    }
    return durationValue;
};
export const convertToDurationTypeFormat = (duration: string) => {
    debugger;
    const durationValue = duration.split(':');
    const durationData = {
        weeks: Math.floor(parseInt(durationValue[0]) / 7),
        days: Math.floor(parseInt(durationValue[0]) % 7),
        // parseInt(durationValue[0]) -
        // Math.floor(parseInt(durationValue[0]) / 7),
        hours: parseInt(durationValue[1]),
        minutes: parseInt(durationValue[2]),
        seconds: parseInt(durationValue[3]),
    };

    let durationType: DurationType = DurationType.Seconds;
    if (durationData.seconds > 0) {
        durationType = DurationType.Seconds;
    } else if (durationData.minutes > 0) {
        durationType = DurationType.Minutes;
    } else if (durationData.hours > 0) {
        durationType = DurationType.Hours;
    } else if (durationData.days > 0 && durationData.days % 7 !== 0) {
        durationType = DurationType.Days;
    } else if (durationData.days % 7 === 0) {
        durationType = DurationType.Weeks;
    }

    const currentDuration: number = getLocalDurationValue(durationData, durationType);
    return {
        value: currentDuration.toString(),
        type: durationType,
    };
};

export const checkForValidDurationValue = (duration: string) => {
    debugger;
    const durationToInt = parseInt(duration.split(':').join(''));
    let isValid = true;
    if (durationToInt > 31000000 || durationToInt <= 0) {
        isValid = false;
    }
    return isValid;
};

export const compareDurationForSamplingAndTimeWindow = (
    samplingWindowDuration: string,
    timeWindowDuration: string
) => {
    const samplingDurationValue = parseInt(samplingWindowDuration.split(':').join(''));
    const timeWndowDurtnValue = parseInt(timeWindowDuration.split(':').join(''));
    let isValid = true;
    if (samplingDurationValue >= timeWndowDurtnValue) {
        isValid = false;
    }
    return isValid;
};

export const getValidTimeInputs = (historyTimeInput: string) => {
    let days = historyTimeInput.split(':')[0];
    let hours = historyTimeInput.split(':')[1];
    let minutes = historyTimeInput.split(':')[2];
    let seconds = historyTimeInput.split(':')[3];
    if (!days && !hours && !minutes && !seconds) {
        return '';
    }

    if (!days) {
        days = '000';
    }
    if (!hours) {
        hours = '00';
    }
    if (!minutes) {
        minutes = '00';
    }
    if (!seconds) {
        seconds = '00';
    }
    days = padNumberWithZeroes(days, 3);
    hours = padNumberWithZeroes(hours, 2);
    minutes = padNumberWithZeroes(minutes, 2);
    seconds = padNumberWithZeroes(seconds, 2);
    return `${days}:${hours}:${minutes}:${seconds}`;
};

export const isEquivalent = (a: any, b: any): boolean => {
    let isEqual = true;
    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (propName !== 'sampleAggregate') {
            if (a[propName] !== b[propName]) {
                return false;
            }
        } else {
            isEqual = isEquivalent(a[propName], b[propName]);
        }
    }
    return isEqual;
};
