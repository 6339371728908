import { ActionTypes } from "../actionTypes";
import { DialogState, DialogAction } from "./types";

const initialState: DialogState = {
    show: false,
    component: undefined,
    modalTitle: "",
    data: {},
};

const dialogReducer = (
    state: DialogState = initialState,
    action: DialogAction
): DialogState => {
    switch (action.type) {
        case ActionTypes.SHOW_DIALOG:
            const { component } = action.payload;

            if (component) {
                return {
                    show: true,
                    ...action.payload,
                };
            } else {
                return state;
            }

        case ActionTypes.HIDE_DIALOG:
            return { ...initialState };
        default:
            return state;
    }
};

export default dialogReducer;
