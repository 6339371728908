import { ActionTypes } from '../actionTypes';
import { GridState, GridAction } from './types';

const initialState: GridState = {
    show: false,
};

const gridReducer = (state: GridState = initialState, action: GridAction): GridState => {
    switch (action.type) {
        case ActionTypes.SHOW_GRID_DISPLAY:
            const show = action.payload;
            return { show };

        case ActionTypes.HIDE_GRID_DISPLAY:
            return { ...initialState };
        default:
            return state;
    }
};

export default gridReducer;
