import React from 'react';
import Flex from '../../../components/shared/Flex';
import './style.scss';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { TreeNodeType } from '../../../store/assets/types';
import { DraggableItem } from '../../../components/LeftNavigationData/DataRow';
import { AssetTableDataJson } from '../../../store/instanceConfig/types';

interface ObjectSidebarProps {
    data: TreeNodeType[] | AssetTableDataJson[];
}

export function ObjectSidebar(props: ReturnType<typeof mapStateToProps>) {
    return (
        <Flex column className="wrapper-object-sidebar">
            {/* <div className="title">Available Instances</div> */}
            <ul className="list">
                {props.selectedTreeNodeChilds.map((node: TreeNodeType) => {
                    return (
                        <DraggableItem
                            asset={node}
                            editMode={true}
                            id={node.objectId as string}
                            className="list-item"
                            children={node.name}
                        ></DraggableItem>
                    );
                })}
            </ul>
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedTreeNodeChilds: state.assets.selectedTreeNode!.children,
    };
};

export default connect(mapStateToProps)(ObjectSidebar);
