import * as _ from "lodash";
import { connect } from "react-redux";
import { StoreState } from "../../../store";
import {
    Button,
    Checkbox,
    Input,
    Dropdown,
    DropdownOption,
    Icon,
} from "@abb/abb-common-ux-react";
import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    CSSProperties,
    useRef,
} from "react";
import { ConditionState } from "../constants";
import CustomTitle from "../../../components/CustomTitle";
import {
    CONFIRMATION_BUTTON,
    VALIDATION_MESSAGE,
} from "../../../utils/constants/uiConstants";
import {
    getNewUniqueName,
    finalConditionJSON,
    getDisabledForButton,
    convertedConditionObj,
    hasNegativeValue,
    validateForSwedishAndFinishCharacters,
    getDataForStatusKey,
    hasWhiteSpace,
    hasNoSpecialCharactersAndDot,
    validateForSpecialCharacters_Including_HyphenAndUnderscore,
} from "../helper";
import { ShowMessageDispatchProps } from "..";
import { columns, DropDownItem } from "../type";
import Flex from "../../../components/shared/Flex";
import FunctionLogic from "../FunctionLogic";
import { Dispatch } from "redux";
import { FunctionHeaderTypeDetails } from "../../../store/function/types";
import {
    updateFunctionHeaderTypeDetails,
    handleFunctionErrorFlag,
    setEmptyValidationErrors,
} from "../../../store/function/action";
import {
    hasErrorInConditionsData,
    getUniqueName,
    checkForDuplicateConditionName,
} from "../../../utils/helpers";
import CustomLeftNav from "../../../components/CustomLeftNavigation";
import AddConditionModal from "../AddConditionModal";
import SubConditionDetail from "./SubConditionDetail";
import "./style.scss";
import Collapsible from "../Collapsible";
import Condition from "../../../components/shared/Condition";
import { jsxElement } from "@babel/types";
import ConditionsList from "./SectionComponents/ConditionsList";
import SubConditionsList from "./SectionComponents/SubConditionsList";
import SubconditionsView from "./SectionComponents/SubconditionsView";
import { SidebarContainer } from "../../../containers/EditorLayout";
import CustomABBInput from "../../../components/CustomABBInput";

const availableConditionType: DropDownItem[] = [
    { label: "Condition Type A", value: "Condition Type A" },
];

interface FunctionConditionTypeProps {
    inputs: any;
    isActive?: boolean;
    droppedItem: boolean;
    handleSeverity: () => void;
    componentMode: "EDIT" | "CREATE";
    handleConditions: (data: any) => void;
    handleConditionError: (value: boolean) => void;
    handleLogicDialog: (condition: any, name: string, value: string) => void;
    dataSetForStatusKey: DropDownItem[];
}

function FunctionConditionType(
    props: FunctionConditionTypeProps &
        ShowMessageDispatchProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) {
    const [conditionFunctionType, updateConditionFunctionType] = useState(
        [] as any
    );
    const [count, updateCount] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sortOrder, setSortOrder] = useState([] as any);
    const [isSelected, setIsSelected] = useState(false);
    const [showLogicDialog, setShowLogicDialog] = useState(false);
    const [selectedConditionIndex, setSelectedConditionIndex] = useState(0);
    const [selectedConditionName, setSelectedConditionName] = useState("");
    const [selectedSubConditionIndex, setSelectedSubConditionIndex] =
        useState(0);
    const [logicValue, updateLogicValue] = useState({
        condition: "",
        name: "",
        value: "",
        subConditionIndex: 0,
    } as any);
    const { inputs, handleConditionError } = props;

    const getConditionName = (index: number) => {
        return Object.keys(conditionFunctionType[index] || {}).filter(
            (key) => key !== "error" && key !== "deleteFlag"
        )[0];
    };

    const handleAddCondition = () => {
        let itemCount = 0;
        let conditionName: any = {};
        if (conditionFunctionType.length === 0) {
            props.handleSeverity();
        } else {
            let lastElementCount = Object.keys(
                conditionFunctionType[conditionFunctionType.length - 1]
            )[0]
                .split("")
                .pop();
            if (!lastElementCount) {
                lastElementCount = "0";
            }
            itemCount = parseInt(lastElementCount) + 1;
        }
        const conditionCountName = getNewUniqueName(
            props.functionHeaderDetails.conditionDetails,
            ConditionState
        );
        const name: string = conditionCountName;
        let defaultObj: any = {
            name: `subCondition0`,
            description: { dataType: "string", value: "" },
            severity: { dataType: "integer", value: "" },
            possibleCause: { dataType: "string", value: "" },
            suggestedAction: { dataType: "string", value: "" },
            correctiveAction: { dataType: "string", value: "" },
            logic: { dataType: "string", value: "" },
            status: { dataType: "string", value: "" },
        };
        let addingIndex = conditionFunctionType.length;
        updateCount(count + 1);
        conditionName[name] = [defaultObj];
        conditionFunctionType.push(conditionName);
        sortOrder.push("asc");
        setSortOrder(sortOrder);
        props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType(conditionFunctionType);
        setSelectedConditionIndex(addingIndex);
        setSelectedConditionName(getConditionName(addingIndex));
        if (!selectedConditionName) {
            setSelectedConditionName(name);
        }
    };

    const getConditionNameUpdate = (val: string) => {
        let hasError = false;
        const conditionName = getConditionName(selectedConditionIndex);
        const hasDuplicateName = checkForDuplicateConditionName(
            conditionFunctionType,
            val
        );
        if (hasDuplicateName || val.trim() == "") {
            handleConditionError(true);
            let errorText =
                val.trim() == ""
                    ? VALIDATION_MESSAGE.EMPTY_NAME
                    : `${val} already exists`;

            conditionFunctionType[selectedConditionIndex]["error"] = {
                valid: false,
                text: errorText,
            };
            hasError = true;
        } else if (hasWhiteSpace(val)) {
            handleConditionError(true);
            let errorText = VALIDATION_MESSAGE.SPACE;
            conditionFunctionType[selectedConditionIndex]["error"] = {
                valid: false,
                text: errorText,
            };
            hasError = true;
        } else if (
            !validateForSpecialCharacters_Including_HyphenAndUnderscore(val)
        ) {
            let errorText: string = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
            handleConditionError(true);
            conditionFunctionType[selectedConditionIndex]["error"] = {
                valid: false,
                text: errorText,
            };
            hasError = true;
        } else {
            delete conditionFunctionType[selectedConditionIndex]["error"];
        }
        conditionFunctionType[selectedConditionIndex][val] =
            conditionFunctionType[selectedConditionIndex][conditionName];
        delete conditionFunctionType[selectedConditionIndex][conditionName];
        setSelectedConditionName(val);
        updateConditionFunctionType([...conditionFunctionType]);
        const hasErrorInCondition = hasErrorInConditionsData(
            conditionFunctionType
        );
        if (!hasError && !hasErrorInCondition) {
            handleConditionError(false);
            props.handleConditions(finalConditionJSON(conditionFunctionType));
        }
    };

    const handleCloseLogicDialog = () => {
        setShowLogicDialog(!showLogicDialog);
    };

    const handleSubmitLogicDialog = () => {
        setShowLogicDialog(!showLogicDialog);
    };

    const handleInputChange = (val: string) => {
        console.log(conditionFunctionType);
        conditionFunctionType[selectedConditionIndex][selectedConditionName][
            selectedSubConditionIndex
        ]["logic"]["value"] = val.trim();
        updateLogicValue({
            condition: conditionFunctionType[selectedConditionIndex],
            name: logicValue.name,
            value: val,
            subConditionIndex: selectedSubConditionIndex,
        });
        updateConditionFunctionType([...conditionFunctionType]);
        if (!props.errorFlag) {
            const hasErrorInCondition = hasErrorInConditionsData(
                conditionFunctionType
            );
            !hasErrorInCondition &&
                props.handleConditions(
                    finalConditionJSON(conditionFunctionType)
                );
        }
    };

    useEffect(() => {
        updateConditionFunctionType(convertedConditionObj({ ...inputs }));
    }, [inputs, props.functionHeaderDetails.conditionDetails]);

    useEffect(() => {
        Array.from(Object.keys(inputs)).map((name: string, index: number) => {
            sortOrder.push("asc");
        });
        setSortOrder(sortOrder);
    }, [inputs, sortOrder]);

    useEffect(() => {
        if (
            Object.keys(props.functionHeaderDetails.conditionDetails).length ===
            0
        ) {
            handleAddCondition();
        }
        props.setEmptyValidationsError([]);
    }, []);

    const handleMessageClose = (
        data: string,
        functionToBeCalled: () => any
    ) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            functionToBeCalled();
        }
        setDeleteModal(!deleteModal);
    };

    const handleDeleteFunctionItems = (functionToBeCalled: () => any) => {
        if (props.errorFlag) {
            return;
        }
        props.showMessageModal!({
            title: "Delete",
            rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            handleStandardButton: (data: string) =>
                handleMessageClose(data, functionToBeCalled),
            warningText: `Do you want to delete selected items ? `,
        });
    };

    useEffect(() => {
        let isAllChecked = true;
        isAllChecked = !conditionFunctionType.some((condition: any) => {
            // const valuesArray: any = Object.values(condition)[0];
            const conditionName = Object.keys(condition).filter(
                (key) => key !== "error" && key !== "deleteFlag"
            )[0];
            const valuesArray: any[] = condition[conditionName];
            let isUnchecked = false;
            if (valuesArray) {
                isUnchecked = valuesArray.some((value: any) => {
                    if (!value.hasOwnProperty("deleteFlag")) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            return isUnchecked;
        });
        setIsSelected(isAllChecked);
        if (
            conditionFunctionType.length > 0 &&
            !selectedConditionName &&
            selectedConditionIndex === undefined
        ) {
            handleConditionSelected(0);
        }

        if (selectedConditionIndex >= conditionFunctionType.length) {
            handleConditionSelected(conditionFunctionType.length - 1);
        } else {
            if (selectedConditionIndex !== -1) {
                setSelectedConditionIndex(selectedConditionIndex);
                setSelectedConditionName(
                    getConditionName(selectedConditionIndex)
                );
                if (selectedSubConditionIndex === -1) {
                    setSelectedSubConditionIndex(0);
                }
            } else {
                handleConditionSelected(0);
            }
        }
    }, [conditionFunctionType]);

    useEffect(() => {
        if (selectedConditionIndex >= 0) {
            handleConditionSelected(selectedConditionIndex);
        }
    }, [selectedConditionIndex]);

    const addDeleteFlag = () => {
        let resultConditionType: any = [];
        conditionFunctionType.forEach((condition: any) => {
            let updatedCondition = {};
            let values: any = [];
            const key = Object.keys(condition)[0];
            const valuesArray: any = Object.values(condition)[0];

            if (valuesArray) {
                valuesArray.forEach((value: any) => {
                    if (!isSelected) {
                        value["deleteFlag"] = true;
                    } else if (isSelected) {
                        delete value["deleteFlag"];
                    }
                    values.push(value);
                });
            }
            updatedCondition[key] = values;
            if (!isSelected) {
                condition["deleteFlag"] = true;
                updatedCondition["deleteFlag"] = true;
            } else if (isSelected) {
                delete condition["deleteFlag"];
                delete updatedCondition["deleteFlag"];
            }
            resultConditionType.push(updatedCondition);
        });
        props.handleConditionError(false);
        props.handleConditions(finalConditionJSON(resultConditionType));
        updateConditionFunctionType(resultConditionType);
    };

    const handleConditionSelected = (index: number) => {
        setSelectedConditionIndex(index);
        setSelectedConditionName(getConditionName(index));
        setSelectedSubConditionIndex(0);
    };
    console.log(
        "conditionFunctionType:",
        conditionFunctionType,
        selectedConditionIndex,
        selectedSubConditionIndex,
        selectedConditionName
    );

    return (
        <>
            <FunctionLogic
                handleSubmitLogicDialog={handleSubmitLogicDialog}
                handleCloseLogicDialog={handleCloseLogicDialog}
                handleInputChange={(val) => handleInputChange(val)}
                logicValue={logicValue}
                showLogicDialog={showLogicDialog}
                dataSet={[
                    ...Object.keys(props.functionHeaderDetails.inputDetails),
                ]}
            />
            <Flex column fill className="wrapper-condition-tab-data">
                <Flex row className="add-conditon-bar">
                    <Button
                        className="add-condition-btn"
                        text="Add Condition"
                        sizeClass="small"
                        icon="abb/plus"
                        onClick={() => {
                            if (!props.errorFlag) {
                                handleAddCondition();
                            }
                        }}
                        disabled={props.errorFlag}
                    />
                    <Flex fill row justifyContentBetween>
                        <CustomABBInput
                            className="condition-fields"
                            dataType="text"
                            value={selectedConditionName}
                            onValueChange={(value) =>
                                getConditionNameUpdate(value)
                            }
                            label="Condition Name"
                            placeholder="Enter Condition Name"
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                            instantValidation={true}
                            validator={() => {
                                const errorObj =
                                    conditionFunctionType[
                                        selectedConditionIndex
                                    ] &&
                                    conditionFunctionType[
                                        selectedConditionIndex
                                    ]["error"];
                                return errorObj
                                    ? {
                                          valid: false,
                                          text: errorObj["text"],
                                      }
                                    : {
                                          valid: true,
                                          text: "OK!",
                                      };
                            }}
                        />
                    </Flex>
                </Flex>
                <div className="horizontal-line"></div>

                <Flex fill row className="conditions-data">
                    <SidebarContainer
                        useDraggable={true}
                        dragId={"conditionDrag"}
                    >
                        <Flex fill className="condition-left-nav" column>
                            <ConditionsList
                                conditionFunctionType={conditionFunctionType}
                                updateConditionFunctionType={
                                    updateConditionFunctionType
                                }
                                inputs={inputs}
                                handleConditionError={handleConditionError}
                                getConditionName={getConditionName}
                                selectedConditionIndex={selectedConditionIndex}
                                selectedConditionName={selectedConditionName}
                                setSelectedConditionIndex={
                                    setSelectedConditionIndex
                                }
                                setSelectedConditionName={
                                    setSelectedConditionName
                                }
                                setSelectedSubConditionIndex={
                                    setSelectedSubConditionIndex
                                }
                                handleConditions={props.handleConditions}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                handleDeleteFunctionItems={
                                    handleDeleteFunctionItems
                                }
                                addDeleteFlag={addDeleteFlag}
                                errorFlag={props.errorFlag}
                                functionHeaderDetails={
                                    props.functionHeaderDetails
                                }
                                handleConditionSelected={
                                    handleConditionSelected
                                }
                            />
                        </Flex>
                    </SidebarContainer>
                    <SidebarContainer
                        useDraggable={true}
                        dragId={"subConditionDrag"}
                    >
                        <Flex fill className="sub-condition-left-nav" column>
                            <SubConditionsList
                                conditionFunctionType={conditionFunctionType}
                                updateConditionFunctionType={
                                    updateConditionFunctionType
                                }
                                handleConditionError={
                                    props.handleConditionError
                                }
                                getConditionName={getConditionName}
                                selectedConditionIndex={selectedConditionIndex}
                                selectedConditionName={selectedConditionName}
                                selectedSubConditionIndex={
                                    selectedSubConditionIndex
                                }
                                setSelectedSubConditionIndex={
                                    setSelectedSubConditionIndex
                                }
                                handleConditions={props.handleConditions}
                                handleDeleteFunctionItems={
                                    handleDeleteFunctionItems
                                }
                                errorFlag={props.errorFlag}
                                isSelectAll={isSelected}
                            />
                        </Flex>
                    </SidebarContainer>
                    <Flex fill className="condition-right-part" column>
                        <SubconditionsView
                            handleConditions={props.handleConditions}
                            handleConditionError={props.handleConditionError}
                            selectedSubCondition={
                                selectedConditionName &&
                                conditionFunctionType &&
                                conditionFunctionType[selectedConditionIndex] &&
                                conditionFunctionType[selectedConditionIndex][
                                    selectedConditionName
                                ] &&
                                conditionFunctionType[selectedConditionIndex][
                                    selectedConditionName
                                ][selectedSubConditionIndex]
                            }
                            selectedConditionIndex={selectedConditionIndex}
                            selectedConditionName={selectedConditionName}
                            selectedSubConditionIndex={
                                selectedSubConditionIndex
                            }
                            setSelectedSubConditionIndex={
                                setSelectedSubConditionIndex
                            }
                            getConditionName={getConditionName}
                            conditionFunctionType={conditionFunctionType}
                            updateConditionFunctionType={
                                updateConditionFunctionType
                            }
                            showLogicDialog={showLogicDialog}
                            setShowLogicDialog={setShowLogicDialog}
                            logicValue={logicValue}
                            updateLogicValue={updateLogicValue}
                            functionHeaderDetails={props.functionHeaderDetails}
                            emptyErrors={props.emptyValidationsErrors}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}
const mapStateToProps = (state: StoreState) => {
    return {
        functionHeaderDetails: state.functions.functionHeaderDetails,
        errorFlag: state.functions.functionErrorFlag,
        emptyValidationsErrors: state.functions.emptyValidationsError,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleFunctionDetails: (payload: FunctionHeaderTypeDetails) => {
            dispatch(updateFunctionHeaderTypeDetails(payload));
        },
        handleConditionError: (errorFlag: boolean) => {
            dispatch(handleFunctionErrorFlag(errorFlag));
        },
        setEmptyValidationsError: (payload: string[]) => {
            dispatch(setEmptyValidationErrors(payload));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionConditionType);
