import * as _ from 'lodash';
import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import { Button, Checkbox, Input, Dropdown, DropdownOption, Icon } from '@abb/abb-common-ux-react';
import React, { useState, useEffect, useCallback, useMemo, CSSProperties, useRef } from 'react';
import { ConditionState } from '../../constants';
import CustomTitle from '../../../../components/CustomTitle';
import { CONFIRMATION_BUTTON, VALIDATION_MESSAGE } from '../../../../utils/constants/uiConstants';
import {
    getNewUniqueName,
    finalConditionJSON,
    getDisabledForButton,
    convertedConditionObj,
    hasNegativeValue,
    validateForSwedishAndFinishCharacters,
    getDataForStatusKey,
} from '../../helper';
import { ShowMessageDispatchProps } from '../..';
import { columns, DropDownItem } from '../../type';
import Flex from '../../../../components/shared/Flex';
import FunctionLogic from '../../FunctionLogic';
import { Dispatch } from 'redux';
import { FunctionHeaderTypeDetails } from '../../../../store/function/types';
import {
    updateFunctionHeaderTypeDetails,
    handleFunctionErrorFlag,
} from '../../../../store/function/action';
import {
    hasErrorInConditionsData,
    getUniqueName,
    checkForDuplicateConditionName,
} from '../../../../utils/helpers';
import CustomLeftNav from '../../../../components/CustomLeftNavigation';
import AddConditionModal from '../../AddConditionModal';
import SubConditionDetail from '.././SubConditionDetail';
import '../style.scss';
import Collapsible from '../../Collapsible';
import Condition from '../../../../components/shared/Condition';
import { jsxElement } from '@babel/types';
import CustomTooltip from '../../../../components/CustomTooltip';

interface ConditionsListProps {
    conditionFunctionType: any;
    updateConditionFunctionType: (conditionFunctionType: any) => any;
    inputs: any;
    handleConditionError: (value: boolean) => void;
    getConditionName: (index: number) => string;
    selectedConditionIndex: number;
    selectedConditionName: string;
    setSelectedConditionIndex: (index: number) => any;
    setSelectedConditionName: (name: string) => any;
    setSelectedSubConditionIndex: (index: number) => any;
    handleConditions: (conditionsArr: any) => any;
    isSelected: boolean;
    setIsSelected: (value: boolean) => any;
    handleDeleteFunctionItems: (functionToBeCalled: () => any) => any;
    addDeleteFlag: () => any;
    errorFlag: boolean;
    functionHeaderDetails: any;
    handleConditionSelected: (index: number) => any;
}

function ConditionsList(props: ConditionsListProps) {
    const {
        isSelected,
        setIsSelected,
        errorFlag,
        handleConditions,
        handleConditionError,
        conditionFunctionType,
        updateConditionFunctionType,
        inputs,
        getConditionName,
        setSelectedConditionIndex,
        setSelectedConditionName,
        setSelectedSubConditionIndex,
        selectedConditionIndex,
        selectedConditionName,
        handleDeleteFunctionItems,
        addDeleteFlag,
        handleConditionSelected,
    } = props;

    const handleSingleConditionDelete = (index: number) => {
        conditionFunctionType.splice(index, 1);
        props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType([...conditionFunctionType]);
    };

    const handleMultipleConditionDelete = () => {
        const filteredConditions = conditionFunctionType.filter((item: any, index: number) => {
            return !item['deleteFlag'];
        });
        props.handleConditions(finalConditionJSON(filteredConditions));
        updateConditionFunctionType(filteredConditions);
    };

    const handleConditionCheckbox = (index: number, value: boolean) => {
        let conditionName = getConditionName(index);
        if (value) {
            conditionFunctionType[index]['deleteFlag'] = true;
            conditionFunctionType[index][conditionName].forEach((item: any) => {
                item['deleteFlag'] = true;
            });
        } else {
            delete conditionFunctionType[index]['deleteFlag'];
            conditionFunctionType[index][conditionName].forEach((item: any) => {
                delete item['deleteFlag'];
            });
        }
        props.handleConditions(finalConditionJSON(conditionFunctionType));
        updateConditionFunctionType([...conditionFunctionType]);
    };

    const handleSelectAll = useCallback(() => {
        setIsSelected(!isSelected);
        addDeleteFlag();
    }, [isSelected, conditionFunctionType]);

    console.log('conditionFunctionType:', conditionFunctionType, selectedConditionName);

    return (
        <>
            <div className="condition-list-title">Conditions</div>
            <Flex column className="list-wrapper">
                <div className="condition-menu-element select-all">
                    <Checkbox
                        sizeClass="small"
                        className="checkbox"
                        value={isSelected}
                        onChange={handleSelectAll}
                        disabled={props.errorFlag}
                    />
                    Select All
                    <Condition
                        when={
                            !(
                                getDisabledForButton(
                                    props.functionHeaderDetails.conditionDetails
                                ) || props.errorFlag
                            )
                        }
                    >
                        <Icon
                            sizeClass="small"
                            name="abb/trash"
                            onClick={(e) =>
                                handleDeleteFunctionItems(handleMultipleConditionDelete)
                            }
                            className={`right-icon ${props.errorFlag ? 'disabled' : ''}`}
                        />
                    </Condition>
                </div>
                <div style={{ overflow: 'auto', height: 'calc(100% - 34px)' }}>
                    {conditionFunctionType &&
                        conditionFunctionType.map((item: any, index: number) => {
                            // TODO: Update with the original data here.
                            return (
                                <CustomTooltip position="top" text={getConditionName(index)}>
                                    <div
                                        className={`condition-menu-element ${
                                            selectedConditionIndex === index ? 'selected' : ''
                                        }`}
                                        onClick={(e) => {
                                            if (!props.errorFlag) {
                                                handleConditionSelected(index);
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            sizeClass="small"
                                            className="checkbox"
                                            disabled={props.errorFlag}
                                            value={item['deleteFlag'] || false || isSelected}
                                            onChange={() =>
                                                handleConditionCheckbox(index, !item['deleteFlag'])
                                            }
                                        />
                                        <span className="text">{getConditionName(index)}</span>
                                        <Icon
                                            sizeClass="small"
                                            name="abb/trash"
                                            onClick={() =>
                                                handleDeleteFunctionItems(() =>
                                                    handleSingleConditionDelete(index)
                                                )
                                            }
                                            className={`right-icon ${
                                                props.errorFlag ? 'disabled' : ''
                                            }`}
                                        />
                                    </div>
                                </CustomTooltip>
                            );
                        })}
                </div>
            </Flex>
        </>
    );
}
export default ConditionsList;
