import { ActionTypes } from "../actionTypes";
import { ShowModelPayload } from "./types";

export function showModal(payload: ShowModelPayload) {
    return <const>{
        type: ActionTypes.SHOW_MODAL,
        payload
    };
}

export function hideModal() {
    return <const>{
        type: ActionTypes.HIDE_MODAL
    };
}
