import React, { useState, useMemo, useCallback } from "react";
import { Dialog, Icon, WithTooltip, Tooltip } from "@abb/abb-common-ux-react";
import CodeMirror from "codemirror";
import EditorComponent from "./Editor";
import "./style.scss";

interface FunctionLogicProps {
    showLogicDialog: boolean;
    handleCloseLogicDialog: () => void;
    handleSubmitLogicDialog: () => void;
    logicValue: any;
    handleInputChange: (val: string) => void;
    dataSet: string[];
}

function FunctionLogic(props: FunctionLogicProps) {
    const {
        showLogicDialog,
        handleCloseLogicDialog,
        handleSubmitLogicDialog,
        logicValue,
        dataSet,
    } = props;

    const [logicErrorText, setLogicErrorText] = useState("");
    const [codemirroInstance, setCodemirrorInstance] = useState(
        undefined as CodeMirror.EditorFromTextArea | undefined
    );
    const [isDark, setIsDark] = useState(false);

    const hanldeAreaInputValue = (val: any) => {
        props.handleInputChange(val);
        if (logicErrorText) {
            setLogicErrorText("");
        }
    };

    const dataSetForLogic = useMemo(() => {
        let data: { text: string; displayText: string }[] = [];
        dataSet.forEach((item: string) => {
            let obj = { text: `$'${item}'`, displayText: item };
            data.push(obj);
        });
        return data;
    }, [dataSet]);

    const updateCodeMirrorInstance = (
        cmInstance: CodeMirror.EditorFromTextArea
    ) => {
        setCodemirrorInstance(cmInstance);
    };

    const toggleTheme = useCallback(() => {
        setIsDark(!isDark);
        if (codemirroInstance) {
            if (isDark && codemirroInstance) {
                codemirroInstance.setOption("theme", "default");
            } else {
                codemirroInstance.setOption("theme", "solarized dark");
            }
        }
    }, [isDark, codemirroInstance]);

    return (
        <Dialog
            dimBackground={true}
            title="Logic Expression Builder"
            isOpen={showLogicDialog}
            showCloseButton={true}
            onClose={() => {
                handleCloseLogicDialog();
                setLogicErrorText("");
                setIsDark(false);
            }}
            standardButtonsOnBottom={[
                {
                    text: "Close",
                    type: "discreet-blue",
                    handler: () => {
                        setLogicErrorText("");
                        handleCloseLogicDialog();
                        setIsDark(false);
                    },
                },
                {
                    text: "Set",
                    type: "primary-blue",
                    handler: () => {
                        if (codemirroInstance) {
                            const value = codemirroInstance.getValue("");
                            props.handleInputChange(value);
                        }
                        setIsDark(false);
                        handleSubmitLogicDialog();
                    },
                },
            ]}
            className="wrapper-logic-builder"
        >
            <div className="wrapper-editor">
                <WithTooltip>
                    <Icon
                        name={`${isDark ? "abb/sun" : "abb/moon"}`}
                        color={`${isDark ? "yellow" : "black"}`}
                        className="theme-icon"
                        onClick={toggleTheme}
                    />
                    <Tooltip followCursor>
                        <span>{`${isDark ? "normal" : "dark"}`}</span>
                    </Tooltip>
                </WithTooltip>

                <EditorComponent
                    logicValue={logicValue.value}
                    dataSet={dataSetForLogic}
                    handleInputChange={hanldeAreaInputValue}
                    updateCodeMirrorInstance={updateCodeMirrorInstance}
                />
            </div>
            {logicErrorText && (
                <div className="logic-error">{logicErrorText}</div>
            )}
        </Dialog>
    );
}

export default FunctionLogic;
