import { FabricAlarmIcon } from '../FabricAlarmIcon';

import { fabric } from 'fabric';
import { GRID_PIXEL } from '../UiConfig';
import { FabricILWT } from './FabricLineWithTrigger';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';
import { FabricAlarmTriggerIcon } from './FabricAlarmTriggerIcon';
import { FabricEventTriggerIcon } from './FabricEventTriggerIcon';

export class FabricLineDeleteIcon extends fabric.Group {
    constructor() {
        const cross = new fabric.Text('+', {
            fontSize: 18,
            fontWeight: 900,
            originX: 'center',
            originY: 'center',
        });
        cross.rotate(135);
        super([cross], {
            hoverCursor: 'pointer',
            selectable: false,
            name: 'deleteSelection',
            originY: 'center',
            originX: 'center',
        });
    }
}

interface Params {
    lineText: string;
    id?: string;
    circleData?: FunctionTypeDetail['inputs'][0];
}

export class FabricILA extends FabricILWT {
    alarmIcon: FabricAlarmTriggerIcon;
    crossIcon: FabricLineDeleteIcon;
    constructor(options: Params) {
        super({ lineText: options.lineText });
        this.alarmIcon = new FabricAlarmTriggerIcon();
        const alarmIconWidth = this.alarmIcon.getScaledWidth();
        this.crossIcon = new FabricLineDeleteIcon();
        const left: number = this.alarmIcon.left! + alarmIconWidth / 2 + GRID_PIXEL / 2;
        this.crossIcon.set('left', left);
    }
}

export class FabricILE extends FabricILWT {
    eventIcon: FabricEventTriggerIcon;
    crossIcon: FabricLineDeleteIcon;
    constructor(options: Params) {
        super({ lineText: options.lineText });
        this.eventIcon = new FabricAlarmTriggerIcon();
        const eventIconWidth = this.eventIcon.getScaledWidth();
        this.crossIcon = new FabricLineDeleteIcon();
        const left: number = this.eventIcon.left! + eventIconWidth / 2 + GRID_PIXEL / 2;
        this.crossIcon.set('left', left);
    }
}
