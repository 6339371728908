import { FabricAlarmIcon } from '../FabricAlarmIcon';
import { fabric } from 'fabric';
import {
    outputLineLength,
    fontSize,
    canvasIOCircleColor,
    canvasTextColor,
    alarmLineLength,
    alramIconSize,
    outputCircleRadius,
} from '../UiConfig';
import { FabricAlarmCircle } from '../FabricIOCircle';

export interface LineWithAlarmParams {
    conditionNameText?: string;
    alarmLineLength?: number;
}

export class FabricLine extends fabric.Group {
    linewithCircle: fabric.Group;
    constructor(options: LineWithAlarmParams) {
        super(undefined, {
            selectable: false,
            hoverCursor: 'default',
        });

        const line = new fabric.Line([0, 0, alarmLineLength, 0], {
            fill: canvasIOCircleColor,
            stroke: canvasIOCircleColor,
            left: 0,
            strokeWidth: 1,
            selectable: false,
            evented: false,
            objectCaching: false,
            hoverCursor: 'default',
        });
        // const conditionOutputCircle = new FabricOutputCircle({ radius: 4 });
        // conditionOutputCircle.set("left", line.getScaledWidth() + 4);
        this.linewithCircle = new fabric.Group([
            line,
            // conditionOutputCircle,
        ]);
        this.addWithUpdate(this.linewithCircle);
    }
}

export class FabricLineWithConditionName {
    conditionName: fabric.Text;

    constructor(options: LineWithAlarmParams) {
        this.conditionName = new fabric.Text(
            (options.conditionNameText && options.conditionNameText) || '',
            {
                fontSize: fontSize,
                originX: 'right',
                selectable: false,
                left: 0,
                borderColor: canvasTextColor,
            }
        );
    }
}

export class FabricLineWithAlarm {
    alarmLine: FabricLine;
    constructor(options: LineWithAlarmParams) {
        this.alarmLine = new FabricLine({
            conditionNameText: options.conditionNameText,
        });

        this.alarmLine.set('originY', 'center');
        this.alarmLine.set('name', 'alarmLine');
        this.alarmLine.set('left', 0);
        this.alarmLine.set('top', 10);
        this.alarmLine.set('originX', 'left');
    }
}

export class FabricAlarmLineWithCircle extends FabricLineWithAlarm {
    alarmCircle: FabricAlarmCircle;
    constructor(options: LineWithAlarmParams) {
        super(options);
        const alarmLineWidth = this.alarmLine.getScaledWidth();
        this.alarmCircle = new FabricAlarmCircle({ radius: 4 });
        // this.alarmCircle.set("originX", "left");
        this.alarmCircle.set('left', alarmLineLength + alramIconSize / 2 - outputCircleRadius);
    }
}

// export class FabricLineWith
export class FabricOLWCA extends FabricAlarmLineWithCircle {
    alarmIcon: FabricAlarmIcon;
    constructor(options: LineWithAlarmParams) {
        super(options);
        this.alarmIcon = new FabricAlarmIcon({ triggered: false });
        // this.alarmIcon.set('left', alramIconSize / 2 + GRID_PIXEL);
        this.alarmIcon.set('left', 0);
    }
}
