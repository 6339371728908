import { MAX_CANVAS_HEIGHT, MAX_CANVAS_WIDTH } from "../UIHelpers/UiConfig";

export const handleMaxCanvasWidthHeightLimit = (option: {
    nodeWidth: number;
    nodeHeight: number;
    nodePosition: { x: number; y: number };
}) => {
    const { nodeHeight, nodeWidth, nodePosition } = option;
    let isExceedingCanvasArea = false;
    let updatedPosition = { ...nodePosition };

    if (nodePosition.y + nodeHeight >= MAX_CANVAS_HEIGHT) {
        updatedPosition.y = MAX_CANVAS_HEIGHT - nodeHeight;
        isExceedingCanvasArea = true;
    }
    if (updatedPosition.x + nodeWidth >= MAX_CANVAS_WIDTH) {
        updatedPosition.x = MAX_CANVAS_WIDTH - nodeWidth;
        isExceedingCanvasArea = true;
    }
    return { updatedPosition, isExceedingCanvasArea };
};
