import React from "react";
import { DraggableItem } from "../../../../../components/LeftNavigationData/DataRow";

const DraggableListRow = (props: any) => {
    return (
        <div className="data-row" id={`dataRow${props.id}`}>
            <div className="data-row__parent">
                <DraggableItem
                    id={props.id}
                    children={`${props.item.assetName} ${props.item.assetVersion}`}
                    asset={props.item}
                    className={`${
                        props.editMode ? "draggable" : "non-draggable"
                    } ${
                        !props.editMode &&
                        props.activeAsset &&
                        props.activeAsset.assetRef === props.item.assetRef
                            ? "active"
                            : ""
                    }`}
                    editMode={props.editMode}
                />
            </div>
        </div>
    );
};

export default DraggableListRow;
