import React from 'react';
import { Table, Button } from '@abb/abb-common-ux-react';
import { ModalFooter } from 'reactstrap';
import { CONFIRMATION_BUTTON } from '../../../utils/constants/uiConstants';
import { UI, API } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/action';
import './style.scss';
import { handleTypesImport } from '../../../store/exportAsset/actions';
interface IPreImportTypeValidationModal {
    validationDetails: API.IUserModelValidationResults;
    zippedModels: UI.SceZippedModels;
}
const PreImportTypeValidationModal = (
    props: IPreImportTypeValidationModal & ReturnType<typeof mapDispatchToProps>
) => {
    const { typeValidationResults, userLibValidationResults } = props.validationDetails;
    const handleCancel = () => {
        props.hideModal();
    };
    const handleOK = () => {
        props.hideModal();
        props.handleTypesImport(props.zippedModels);
    };
    return (
        <div className="validate-types-import-modal">
            <div className="wrapper-validation-table">
                {typeValidationResults.length > 0 ? (
                    <div>
                        <div className="title">Model Validation Results</div>
                        <Table className="validate-table">
                            <thead>
                                <tr>
                                    <th className="width-20">Name</th>
                                    <th className="width-10">Version</th>
                                    <th className="width-20">code</th>
                                    <th className="width-50">detail</th>
                                </tr>
                            </thead>
                            {typeValidationResults.map((item) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td className="width-20">{item.name}</td>
                                                <td className="width-10">{item.version}</td>
                                                <td className="width-20">{item.code}</td>
                                                <td className="width-50">{item.message}</td>
                                            </tr>
                                        </tbody>
                                    </>
                                );
                            })}
                        </Table>
                    </div>
                ) : (
                    <></>
                )}
                {userLibValidationResults.length > 0 ? (
                    <div className="library-section">
                        <div className="title">Library Validation Results</div>
                        <Table className="validate-table">
                            <thead>
                                <tr>
                                    <th className="width-20">Name</th>
                                    <th className="width-10">Version</th>
                                    <th className="width-20">Description</th>
                                    <th className="width-50">Detail</th>
                                </tr>
                            </thead>
                            {userLibValidationResults.map((item) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td className="width-20">
                                                    {item.libraryName === 'None'
                                                        ? item.functionName
                                                        : item.libraryName}
                                                </td>
                                                <td className="width-10">
                                                    {item.libraryVersion
                                                        ? item.libraryVersion
                                                        : item.functionVersion}
                                                </td>
                                                <td className="width-20">
                                                    {item.validationDescription}
                                                </td>
                                                <td className="width-50">{item.userMessage}</td>
                                            </tr>
                                        </tbody>
                                    </>
                                );
                            })}
                        </Table>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div>Do you want to proceed?</div>
            <ModalFooter>
                <div className="footer-part">
                    <Button
                        className="submit-btn"
                        text={CONFIRMATION_BUTTON.CANCEL}
                        type="discreet-black"
                        sizeClass="small"
                        onClick={handleCancel}
                    />
                    <Button
                        className="submit-btn"
                        text={CONFIRMATION_BUTTON.OK}
                        type="primary-blue"
                        sizeClass="small"
                        onClick={handleOK}
                    />
                </div>
            </ModalFooter>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleTypesImport: (zippedModels: UI.SceZippedModels) => {
            dispatch(handleTypesImport(zippedModels));
        },
        hideModal: () => {
            dispatch(hideModal());
        },
    };
};
export default connect(undefined, mapDispatchToProps)(PreImportTypeValidationModal);
