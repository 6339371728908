import React, { Fragment } from 'react';
import '../style.scss';
import * as _ from "lodash";
import { Table } from 'reactstrap';
import { If } from '../helper';
import { columns } from '../type';

interface AppProps {
    settings: any;
}

function InputType(props: AppProps) {

    // if (_.has(props.settings, "conditions")) {
    //     delete props.settings[columns.conditions];
    // }

    const getHeader = () => {
        return (
            <thead>
                <tr className={"no-top-border-header"} >
                    {/* <th className="text-uppercase">Variable Name</th> */}
                    <th className="text-uppercase">Variable Value</th>
                    <th className="text-uppercase">Variable Type</th>
                    {/* <th className="text-uppercase">Mandatory</th>
                    <th className="text-uppercase">Has Value?</th>*/}
                    <th className="text-uppercase">Description</th>
                </tr>
            </thead>
        );
    }

    const getBody = () => {
        return (<tbody>
            {Array.from(Object.keys(props.settings)).map((condition: string, conditionIndex: number) => {
                let conditionsList: any = _.get(props.settings, [condition]);

                // if (!conditionsList.isMandatory) {
                //     conditionsList.isMandatory = false;
                // } else {
                //     conditionsList.isMandatory = true;
                // }

                // if (!conditionsList.hasValue) {
                //     conditionsList.hasValue = false;
                // } else {
                //     conditionsList.hasValue = true;
                // }

                conditionsList = {
                    [columns.value]: conditionsList.value ? conditionsList.value : "-",
                    [columns.dataType]: conditionsList.dataType,
                    // [columns.isMandatory]: conditionsList.isMandatory,
                    // [columns.hasValue]: conditionsList.hasValue,
                    [columns.description]: (conditionsList.description) ? conditionsList.description : "-"
                };

                return condition !== columns.conditions && (
                    <tr key={conditionIndex} >
                        {/* <td> {condition} </td> */}
                        {Object.keys(conditionsList).map((name: any, index: number) => {
                            let info: any = _.get(conditionsList, [name]);
                            return (
                                <Fragment key={index}>
                                    <If cond={name === columns.value}>
                                        <td style={{ textTransform: "none" }}>
                                            {info}
                                        </td>
                                    </If>
                                    <If cond={name === columns.dataType}>
                                        <td>
                                            {info}
                                        </td>
                                    </If>
                                    {/* <If cond={name === columns.isMandatory}>
                                        <td>
                                            <CustomCheckBox
                                                isDisable={false}
                                                checked={info}
                                            />
                                        </td>
                                    </If>
                                    <If cond={name === columns.hasValue}>
                                        <td>
                                            <CustomCheckBox
                                                isDisable={false}
                                                checked={info}
                                            />
                                        </td>
                                    </If>*/}
                                    <If cond={name === columns.description}>
                                        <td>
                                            {info}
                                        </td>
                                    </If>
                                </Fragment>
                            );
                        })
                        }
                    </tr>
                );
            })
            }
        </tbody >);
    }

    return (
        <div className="CustomFunctionView">
            <Table>
                {getHeader()}
                {getBody()}
            </Table>
        </div>
    );
}

export default InputType;
