import _ from 'lodash';
import { FUNCTION_MODE, GENERIC_FUNCTIONS, FUNCTION_SAVE_TYPE } from '../../utils/constants/appConstants';
import {
    FunctionState,
    FunctionActions,
    DropTableData,
    DragDropTableData,
    IFunctionPermission,
    LibraryAndNodePayloadWithoutNodes,
} from './types';
import { ActionTypes } from '../actionTypes';
import {
    getUpdatedDropTableData,
    getOptionsForUpdatingFunctions,
} from '../../routes/Functions/helper';
import { AssetsInfo, EndPoint, FunctionComponents } from '../../routes/Functions/type';
import {
    updateHeaderDetails,
    getUpdatedConditionDetails,
    getUpdatedList,
} from '../../utils/helpers';
import { VERSION_UPDATE_TYPE } from '../configurationTool/types';
import { FunctionType } from 'sce-engg-model-19.09/lib/types/Type';

const activeViewInitialState: FunctionState = {
    currentActiveView: {},
    currentDragDropName: '',
    isLoading: false,
    isLoadingText: false,
    typeDetails: undefined,
    functionsList: [],
    assetsInfo: undefined,
    isPopoverOpen: false,
    isPopoverInfoOpen: false,
    functionHeaderDetails: {
        inputDetails: undefined,
        outputDetails: undefined,
        conditionDetails: undefined,
        calculationDetails: undefined,
    },
    endpoint: undefined,
    functionMode: FUNCTION_MODE.VIEW,
    FunctionSaveType: FUNCTION_SAVE_TYPE.SAVE_AS,
    isDirty: false,
    functionErrorFlag: false,
    functionPermission: {
        hasSave: true,
        hasDelete: true,
        hasEdit: true,
        hasImport: true,
        hasExport: true,
    } as IFunctionPermission,
    alarmType: [],
    alarmMapping: undefined,
    dropTableData: [],
    dragTableData: [],
    tabNavList: [],
    selectedAlarmType: undefined,
    versionUpdate: VERSION_UPDATE_TYPE.DEFAULT,
    mutuallyExclusiveFunctionTypes: [],
    mutuallyExclusiveFunctionTypesLoader: false,
    addingNewLibrary: false,
    gettingLibraryAndNodes: false,
    libraryAndFunctionsList: [],
    deletingLibrary: false,
    removingFunctionsFromExistingLibrary: false,
    addingFunctionsToExistingLibrary: false,
    gettingLibrariesForExport: false,
    loadingStateForDecryptedFunction: false,
    decryptedLibraryDetails: {} as LibraryAndNodePayloadWithoutNodes,
    libraryGlobalError: '',
    encryptingLibrary: false,
    currentLibraryToDragAndDrop: [],
    activeLibId: '',
    changePasswordLoading: false,
    libPasswordError: '',
    isPasswordBtnLoading: false,
    deletingLibraryDetails: {} as {
        libraryId: string;
        libraryVersion: string;
        password: string;
    },
    originalFunctionDetails: undefined,
    isUniqueTypeIdLoading: false,
    modelid: ' ',
    message: ' ',
    isExist: '',
    isLibraryExist: false,
    endPointUrlLists: [],
    emptyValidationsError: [],
};

const createFunctionReducer = (
    state = activeViewInitialState,
    action: FunctionActions
): FunctionState => {
    switch (action.type) {
        case ActionTypes.CREATE_FUNCTION: {
            const currentActiveView = { ...action.payload };
            const endpoint: EndPoint = {
                dataType: 'string',
                description: '',
                value: currentActiveView.modelDetails
                    ? currentActiveView.modelDetails.endpoint
                    : '',
                isMandatory: true,
            };
            let assetsInfo = {} as AssetsInfo;
            if (currentActiveView.modelDetails) {
                assetsInfo = {
                    typeId: currentActiveView.modelDetails.typeId,
                    version: currentActiveView.modelDetails.version,
                    name: currentActiveView.modelDetails.name,
                    description: currentActiveView.modelDetails.description,
                    tags: currentActiveView.modelDetails.tags,
                    endpoint: currentActiveView.modelDetails.endpoint,
                };
            }
            if (!currentActiveView.assetsDetails) {
                return {
                    ...state,
                    currentActiveView,
                    endpoint,
                    functionHeaderDetails: {
                        inputDetails: {},
                        outputDetails: {},
                        conditionDetails: {},
                        calculationDetails: {},
                    },
                    assetsInfo,
                    isDirty: true,
                };
            }

            return { ...state, currentActiveView, endpoint };
        }
        case ActionTypes.DRAG_DROP_FUNCTION: {
            const currentDragDropName = action.payload;
            return { ...state, currentDragDropName };
        }
        case ActionTypes.GET_FUNCTION_TYPES: {
            const models = action.payload;
            return {
                ...state,
            };
        }
        case ActionTypes.GET_SELECTED_FUNCTION_TYPE: {
            const { activeLibId } = action.payload;
            return {
                ...state,
                isLoading: true,
                activeLibId: activeLibId ? activeLibId : GENERIC_FUNCTIONS,
            };
        }
        case ActionTypes.UPDATE_SELECTED_FUNCTION_TYPE: {
            const typeDetails = { ...action.payload.typeDetails };

            let updatedTypeDetails = _.cloneDeepWith(typeDetails);
            if (
                !typeDetails.properties.settings &&
                typeDetails.properties.hasOwnProperty('endpoint')
            ) {
                delete updatedTypeDetails.properties['endpoint'];
                updatedTypeDetails.properties['settings'] = {
                    endpoint: (typeDetails.properties as any).endpoint,
                };
            }
            const {
                assetsInfo,
                functionHeaderDetails,
                currentActiveView,
                alarmMapping,
                dragDropTableData,
            } = getOptionsForUpdatingFunctions(updatedTypeDetails, state.dropTableData);

            return {
                ...state,
                typeDetails: updatedTypeDetails,
                isLoading: false,
                assetsInfo,
                functionHeaderDetails,
                currentActiveView,
                alarmMapping,
                dragTableData: dragDropTableData.dragTableData,
                dropTableData: dragDropTableData.dropTableData,
                decryptedLibraryDetails: {} as LibraryAndNodePayloadWithoutNodes,
                activeLibId: action.payload.activeLibId
                    ? action.payload.activeLibId
                    : state.activeLibId,
            };
        }
        case ActionTypes.HANDLE_FUNCTION_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_FUNCTION: {
            return {
                ...state,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_ISEXIST: {
            const isExist = action.payload;
            console.log('isExist', isExist);
            return {
                ...state,
                isExist,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_LIBRARY_ISEXIST: {
            const isExist = action.payload;
            console.log('isExist', isExist);
            return {
                ...state,
                isLibraryExist: isExist,
                addingNewLibrary: false,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_MESSAGE: {
            const message = action.payload;
            console.log('premessage', message);
            return {
                ...state,
                message,
            };
        }
        case ActionTypes.SET_EMPTY_VALIDATIONS_ERROR: {
            const errors = action.payload;
            return {
                ...state,
                emptyValidationsError: errors,
            }
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_LOADING: {
            const isUniqueTypeIdLoading = action.payload;
            return {
                ...state,
                isUniqueTypeIdLoading,
            };
        }
        case ActionTypes.SUCCESS_FUNCTION_DELETE: {
            const typeId = action.payload;
            const functionsList = [
                ...state.functionsList.filter((item) => item.id.value !== typeId),
            ];
            return {
                ...state,
                isLoading: false,
                functionsList,
                isDirty: false,
            };
        }

        case ActionTypes.ERROR_FUNCTION_DELETE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case ActionTypes.GET_FUNCTION_TYPES: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.ERROR_GET_FUNCTION_TYPES: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActionTypes.SET_FUNCTION_TYPES: {
            const { functionsList } = { ...action.payload };
            return {
                ...state,
                functionsList: [...functionsList],
                isLoading: false,
            };
        }
        case ActionTypes.SUCCESS_LOADING_DATA: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActionTypes.ERROR_LOADING_DATA: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActionTypes.UPDATE_FUNC_ASSETS_INFO: {
            const { assetsInfo } = { ...action.payload };
            return {
                ...state,
                assetsInfo,
            };
        }
        case ActionTypes.HANDLE_POPOVER_OPEN: {
            const isPopoverOpen = action.payload;

            return {
                ...state,
                isPopoverOpen,
            };
        }
        case ActionTypes.HANDLE_POPOVER_INFO: {
            const isPopoverInfoOpen = action.payload;
            return {
                ...state,
                isPopoverInfoOpen,
            };
        }

        case ActionTypes.UPDATE_FUNCTION_HEADER_TYPE_DETAILS: {
            // const functionHeaderDetails = action.payload;
            const { inputDetails, outputDetails, calculationDetails, conditionDetails } =
                action.payload;
            delete inputDetails['alarmMapping'];
            // delete outputDetails["condition"]
            const functionHeaderDetails = {
                inputDetails,
                outputDetails,
                calculationDetails,
                conditionDetails,
            };
            return {
                ...state,
                functionHeaderDetails,
                isDirty: true,
            };
        }

        case ActionTypes.UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS: {
            const { functionHeaderDetails, overallStatus, tableType } = action.payload;
            const updatedFunctionHeaderDetails = updateHeaderDetails(
                functionHeaderDetails,
                overallStatus,
                tableType
            );
            return {
                ...state,
                functionHeaderDetails: updatedFunctionHeaderDetails,
                isDirty: true,
            };
        }

        case ActionTypes.UPDATE_CONDITION_DELETE_STATUS: {
            const updatedConditionDetails = getUpdatedConditionDetails(
                action.payload,
                state.functionHeaderDetails.conditionDetails
            );
            const functionHeaderDetails = {
                inputDetails: state.functionHeaderDetails.inputDetails,
                outputDetails: state.functionHeaderDetails.outputDetails,
                conditionDetails: { ...updatedConditionDetails },
                calculationDetails: state.functionHeaderDetails.calculationDetails,
            };
            return { ...state, functionHeaderDetails, isDirty: true };
        }

        case ActionTypes.UPDATE_CALCULATION_DELETE_STATUS: {
            const functionHeaderDetails = {
                inputDetails: action.payload.inputDetails,
                outputDetails: action.payload.outputDetails,
                conditionDetails: action.payload.conditionDetails,
                calculationDetails: action.payload.calculationDetails,
            };
            return { ...state, functionHeaderDetails, isDirty: true };
        }

        case ActionTypes.HANDLE_FUNCTION_SAVE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.HANDLE_FUNCTION_MODE: {
            const functionMode = action.payload;
            if (functionMode === FUNCTION_MODE.CREATE) {
                state.alarmMapping = undefined;
            }
            if (functionMode === FUNCTION_MODE.VIEW) {
                state.functionErrorFlag = false;
            }
            return {
                ...state,
                functionMode,
            };
        }

        case ActionTypes.SUCCESS_FUNCTION_SAVE: {
            const functionData = action.payload;
            let newFunction = {
                name: { value: functionData.name },
                id: { value: functionData.typeId },
                version: { value: functionData.version },
                tags: [...functionData.tags],
                description: { value: functionData.description },
                model: { value: functionData.model },
            };
            const updatedFunctionList = getUpdatedList(state.functionsList, newFunction);
            return {
                ...state,
                isLoading: false,
                functionsList: updatedFunctionList,
                isDirty: false,
            };
        }

        case ActionTypes.HANDLE_IS_FUNCTION_DIRTY: {
            const isDirty = action.payload;
            return {
                ...state,
                isDirty,
            };
        }

        case ActionTypes.HANDLE_FUNCTION_ERROR_FLAG: {
            const functionErrorFlag = action.payload;
            return {
                ...state,
                functionErrorFlag,
            };
        }

        case ActionTypes.UPDATE_FUNCTION_PERMISSION: {
            return {
                ...state,
                functionPermission: { ...action.payload },
            };
        }
        case ActionTypes.SET_ALARM_TYPE: {
            const alarmType = action.payload;
            const dropTableData: DropTableData[] = getUpdatedDropTableData(alarmType);
            return {
                ...state,
                alarmType,
                dropTableData,
            };
        }

        case ActionTypes.UPDATE_SELECTED_ALARM_TYPE: {
            const selectedAlarmType = action.payload;
            return {
                ...state,
                selectedAlarmType,
            };
        }

        case ActionTypes.UPDATE_ALARM_MODAL_DRAG_DROP_TABLES: {
            let { dragTableData, dropTableData } = action.payload;
            return {
                ...state,
                dragTableData: dragTableData,
                dropTableData: dropTableData,
            };
        }
        case ActionTypes.UPDATE_ALARM_MAPPING_DETAILS: {
            const alarmMapping = action.payload;

            let tabNavList = [...state.tabNavList];
            if (Object.keys(alarmMapping).length > 0) {
                if (!tabNavList.includes(FunctionComponents.ALARM)) {
                    tabNavList.push(FunctionComponents.ALARM);
                }
            }
            return {
                ...state,
                alarmMapping,
                tabNavList,
            };
        }

        case ActionTypes.CLEAR_DROP_TABLE_MAPPING: {
            const dropTableData: DragDropTableData['dropTableData'] = state.dropTableData;
            dropTableData.forEach((item) => {
                if (item.mappedAlarm) {
                    delete item['mappedAlarm'];
                }
            });
            return {
                ...state,
                dropTableData,
            };
        }

        case ActionTypes.UPDATE_TAB_NAV_LIST: {
            const tabNavList = action.payload;

            return {
                ...state,
                tabNavList,
            };
        }

        case ActionTypes.UPDATE_VERSION_VARIABLE: {
            return { ...state, versionUpdate: action.payload };
        }

        case ActionTypes.TOGGLE_LOADER_TEXT: {
            return { ...state, isLoadingText: action.payload };
        }

        case ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_REQUEST: {
            return {
                ...state,
                mutuallyExclusiveFunctionTypesLoader: true,
            };
        }
        case ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_SUCCESS: {
            return {
                ...state,
                mutuallyExclusiveFunctionTypes: action.payload,
                mutuallyExclusiveFunctionTypesLoader: false,
            };
        }
        case ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_FAILURE: {
            return {
                ...state,
                mutuallyExclusiveFunctionTypesLoader: false,
            };
        }
        case ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_REQUEST: {
            return { ...state, addingNewLibrary: true };
        }
        case ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_SUCCESS: {
            return {
                ...state,
                addingNewLibrary: false,
            };
        }
        case ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_FAILURE: {
            return { ...state, addingNewLibrary: false };
        }
        case ActionTypes.GET_LIBRARY_AND_NODES_REQUEST: {
            return {
                ...state,
                gettingLibraryAndNodes: action.exportMode ? false : true,
                gettingLibrariesForExport: action.exportMode ? true : false,
            };
        }
        case ActionTypes.GET_LIBRARY_AND_NODES_SUCCESS: {
            return {
                ...state,
                gettingLibraryAndNodes: false,
                gettingLibrariesForExport: false,
                libraryAndFunctionsList: action.payload,
            };
        }
        case ActionTypes.GET_LIBRARY_AND_NODES_FAILURE: {
            return {
                ...state,
                gettingLibraryAndNodes: false,
                gettingLibrariesForExport: false,
            };
        }
        case ActionTypes.DELETE_LIBRARY_REQUEST: {
            return { ...state, deletingLibrary: true };
        }
        case ActionTypes.DELETE_LIBRARY_SUCCESS: {
            return {
                ...state,
                deletingLibrary: false,
            };
        }
        case ActionTypes.DELETE_LIBRARY_FAILURE: {
            return { ...state, deletingLibrary: false };
        }

        case ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_REQUEST: {
            return { ...state, removingFunctionsFromExistingLibrary: true };
        }
        case ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_SUCCESS: {
            return {
                ...state,
                removingFunctionsFromExistingLibrary: false,
            };
        }
        case ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_FAILURE: {
            return { ...state, removingFunctionsFromExistingLibrary: false };
        }

        case ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_REQUEST: {
            return {
                ...state,
                addingFunctionsToExistingLibrary: true,
                //gettingLibraryAndNodes: true,
            };
        }
        case ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_SUCCESS: {
            return {
                ...state,
                addingFunctionsToExistingLibrary: false,
                gettingLibraryAndNodes: false,
            };
        }
        case ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_FAILURE: {
            return {
                ...state,
                addingFunctionsToExistingLibrary: false,
                gettingLibraryAndNodes: false,
            };
        }

        case ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_REQUEST: {
            return {
                ...state,
                loadingStateForDecryptedFunction: true,
                isLoading: true,

            };
        }
        case ActionTypes.GET_DECRYPTED_PASSWORD_TYPE_REQUEST: {
            return {
                ...state

            }
        }

        case ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_SUCCESS: {
            return {
                ...state,
                loadingStateForDecryptedFunction: false,
                isLoading: false,
                decryptedLibraryDetails: action.payload.decryptedLibraryDetails,
            };
        }

        case ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_FAILURE: {
            return {
                ...state,
                loadingStateForDecryptedFunction: false,
                isLoading: false,
            };
        }

        case ActionTypes.SET_GLOBAL_LIBRARY_ERROR: {
            return { ...state, libraryGlobalError: action.payload.error };
        }

        case ActionTypes.ENCRYPT_LIBRARY_REQUEST: {
            return { ...state, encryptingLibrary: true };
        }

        case ActionTypes.ENCRYPT_LIBRARY_SUCCESS: {
            return {
                ...state,
                encryptingLibrary: false,
            };
        }

        case ActionTypes.ENCRYPT_LIBRARY_FAILURE: {
            return { ...state, encryptingLibrary: false };
        }

        case ActionTypes.SET_DRAGGED_AND_DROPPED_LIBRARY: {
            return {
                ...state,
                currentLibraryToDragAndDrop: action.payload.dragDroppedLib,
            };
        }
        case ActionTypes.HANDLE_CHANGE_PASSWORD_LOADING: {
            return {
                ...state,
                changePasswordLoading: action.payload,
            };
        }

        case ActionTypes.HANDLE_LIB_PASSWORD_ERROR: {
            return {
                ...state,
                libPasswordError: action.payload,
            };
        }

        case ActionTypes.RESET_LIB_PASSWORD_ERROR: {
            return {
                ...state,
                libPasswordError: ""
            }
        }

        case ActionTypes.HANDLE_PASSWORD_BTN_LOADING: {
            return {
                ...state,
                isPasswordBtnLoading: action.payload,
            };
        }
        case ActionTypes.HANDLE_GET_LIBRARY_AND_NODES_LOADING: {
            return {
                ...state,
                gettingLibraryAndNodes: action.payload,
            };
        }

        case ActionTypes.HANDLE_SELECTED_FUNCTION_LOADING: {
            return { ...state, isLoading: action.payload };
        }

        case ActionTypes.SET_DELETING_LIBRARY_DETAILS: {
            return { ...state, deletingLibraryDetails: action.payload };
        }
        case ActionTypes.UPDATE_ORIGINAL_FUNCTION_DETAIL: {
            return {
                ...state,
                originalFunctionDetails: action.payload,
            };
        }
        case ActionTypes.RENAME_LIBRARY_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ActionTypes.RENAME_LIBRARY_SUCCESS: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.RENAME_LIBRARY_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.UPDATE_ENDPOINT_FUNCTION_LISTS: {
            return {
                ...state,
                endPointUrlLists: action.payload.endPointlist,
            };
        }
        default:
            return { ...state };
    }
};

export default createFunctionReducer;
