import React, { ReactNode } from 'react';
import './style.scss';

interface ButtonBarProps {
    children: ReactNode;
    className?: string;
}

const ButtonBar = (props: ButtonBarProps) => {
    return (
        <div className={`wrapper-topbar ${props.className ? props.className : ''} `}>
            <div className="wrapper-btns">{props.children}</div>
        </div>
    );
};

export default ButtonBar;
