import { normalize } from 'normalizr';
import {
    CategoryObjTypes,
    NormalizeTreeNode,
    NormalizedNodeById
} from './types';
import { ASSET_CATEGORY_TYPES } from '../../utils/constants/appConstants';

export const isSystemCategorySelected = (
    categoryObj: CategoryObjTypes | null | undefined
) => {
    return Boolean(
        categoryObj && categoryObj.name === ASSET_CATEGORY_TYPES.SYSTEM.value
    );
};

export const mergeNormalizedTreeNodes = (
    normalizedNodes: NormalizeTreeNode,
    objectById: NormalizedNodeById,
    entitiesArr: string[]
) => {
    const normalizeTreeNodeList = {
        byId: {
            ...normalizedNodes.byId,
            ...objectById
        },
        entities: [...normalizedNodes.entities, ...entitiesArr]
    };
    return normalizeTreeNodeList;
};
