import React, { ReactNode } from "react";

import { CustomValidationResult, Input } from "@abb/abb-common-ux-react";
import { HtmlAttributes } from "@abb/abb-common-ux-react/utils/HtmlAttributes";

export interface InputProps extends HtmlAttributes {
    ref?: any;
    dataType:
        | "color"
        | "date"
        | "email"
        | "month"
        | "number"
        | "password"
        | "search"
        | "tel"
        | "text"
        | "textarea"
        | "time"
        | "url"
        | "week";
    required?: boolean;
    label?: string;
    additionalLabel?: string;
    description?: string;
    type?: "normal" | "discreet";
    suffix?: string;
    prefix?: string;
    onValueChange?: (value: string) => void;
    validator?: (
        value: null | undefined | string
    ) => string | boolean | CustomValidationResult;
    validationResult?: string | boolean | CustomValidationResult | null;
    icon?: string;
    placeholder?: null | string;
    showValidationBarWhenValid?: boolean;
    showValidationIconWhenValid?: boolean;
    showValidationBarWhenInvalid?: boolean;
    showValidationIconWhenInvalid?: boolean;
    disabled?: boolean;
    value?: null | undefined | string;
    inputAttributes?: {
        [index: string]: any;
    };
    clearOnEscape?: boolean;
    showClearIcon?: boolean;
    indicateChanged?: boolean;
    instantValidation?: boolean;
    resizable?: boolean;
    maxLength?: number;
    onKeyUp?: (
        key: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onIconClick?: (text: null | undefined | string) => void;
    onGotFocus?: (
        e:
            | React.FocusEvent<HTMLInputElement>
            | React.FocusEvent<HTMLTextAreaElement>
    ) => void;
    onLostFocus?: (
        e:
            | React.FocusEvent<HTMLInputElement>
            | React.FocusEvent<HTMLTextAreaElement>
    ) => void;
    step?: number | "any";
    children?: never;
}

function CustomABBInput(props: InputProps) {
    let customProps = { ...props };

    if (props.onValueChange) {
        if (props.dataType)
            customProps.onValueChange = (value: string) => {
                let newStr = value.replace(/[^ -~]+/g, "");
                props.onValueChange && props.onValueChange(newStr);
            };
    }

    return <Input {...customProps} />;
}

export default CustomABBInput;
