import React, { useState } from 'react';
import Condition from '../../../shared/Condition';
import Flex from '../../../shared/Flex';

import './styles.scss';

import {
    AppTopNaviDivider,
    AppTopNaviItem,
    AppTopNavi,
    WithTooltip,
    Tooltip,
} from '@abb/abb-common-ux-react';
import { AbilityService } from 'sce-engg-model-19.09';
import { AuthScheme } from '../../../../utils/constants/appConstants';
import AppSettings from '../../../../services/AppSettings';
// import CustomVariableDelete from '../../../CustomVariableDelete';
// import RestoreEdge from '../../../RestoreEdge';
// import SupportedModel from '../../../SupportedModel/SupportedModel';
import ReleaseLicense from '../../../ReleaseLicense/ReleaseLicense';
import { UserMenu } from '@abb/abb.ia.components.react';
import '@abb/abb.ia.components.react/index.css';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { showDialog } from '../../../../store/dialog/action';
import Licensing from '../../../Licensing';
import { StoreState } from '../../../../store';
import { commonAuthHelper } from '../../../../utils/types/commonInterface';
import { TOOLTIP_MESSAGES } from '../../../../utils/constants/uiConstants';

const logout = () => { return commonAuthHelper.logout(); };

const mapStateToProps = (state: StoreState) => {
    return {
        isAuthorized: state.modelsPage.isAuthorized,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showLicensingNotes: () => {
            dispatch(
                showDialog({
                    component: Licensing,
                    modalTitle: 'Licensing',
                    customClassName: 'release-license-dialog license-dialog',
                    removeBackground: true,
                })
            );
        },
        showReleaseNotes: () => {
            dispatch(
                showDialog({
                    component: ReleaseLicense,
                    modalTitle: 'Release Notes',
                    customClassName: 'release-license-dialog',
                    removeBackground: true,
                })
            );
        },
    };
};
const UserActions = connect(
    mapStateToProps,
    mapDispatchToProps
)((props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const tenantName =
        AppSettings.cacheLocation === 'localStorage'
            ? localStorage.getItem('tenant')
            : sessionStorage.getItem('tenant');


    return (
        <AppTopNavi>
            <Condition when={props.isAuthorized}>
                {/* <SupportedModel />
                    <CustomVariableDelete />
                    <RestoreEdge /> */}
                <WithTooltip>
                    <AppTopNaviItem
                        icon="abb/help-circle-1"
                        onClick={() => {
                            let prefixStartsSlash;
                            let prefixEndsSlash;
                            if (AppSettings.prefix !== "") {
                                prefixStartsSlash = AppSettings.prefix.startsWith("/");
                                prefixEndsSlash = AppSettings.prefix.endsWith("/");
                            }
                            const link = AppSettings.prefix == '' ? '/help/index.html'
                                : prefixStartsSlash && prefixEndsSlash ?
                                    `${AppSettings.prefix}help/index.html`
                                    : prefixStartsSlash && !prefixEndsSlash ?
                                        `${AppSettings.prefix}/help/index.html`
                                        : !prefixStartsSlash && prefixEndsSlash
                                            ? `/${AppSettings.prefix}help/index.html`
                                            : !prefixStartsSlash && !prefixEndsSlash
                                                ? `/${AppSettings.prefix}/help/index.html`
                                                : `${AppSettings.prefix}/help/index.html`;
                            window.open(link, '_blank');
                        }}
                    />
                    <Tooltip>{TOOLTIP_MESSAGES.USER_MANUAL}</Tooltip>
                </WithTooltip>
            </Condition>

            <AppTopNaviDivider />
            <WithTooltip>
                <UserMenu
                    tenant={tenantName || ''}
                    username={AbilityService.user.name || ''}
                    roles={AbilityService.userRoles || []}
                    instance={AppSettings.instanceName}
                    isOpen={isOpen}
                    trigger={
                        <AppTopNaviItem
                            icon="abb/user-in-circle"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        />
                    }
                    showSettings={false}
                    showSwitchTenant={false}
                    onReleaseNotes={() => {
                        console.log(' release notes');
                        props.showReleaseNotes();
                    }}
                    onLicensing={() => {
                        props.showLicensingNotes();
                    }}
                    onPrivacyPolicy={() => {
                        const link =
                            AppSettings.privacyURL === ''
                                ? `https://new.abb.com/privacy-notice/customer`
                                : AppSettings.privacyURL;
                        window.open(link, '_blank');
                    }}
                    onSignOut={logout}
                    onRequestClose={() => setIsOpen(false)}
                />
                <Tooltip>{TOOLTIP_MESSAGES.PROFILE}</Tooltip>
            </WithTooltip>
        </AppTopNavi>
    );
});

const ActionIcon = () => {
    return (
        <Flex className="action-icon-container">
            <UserActions />
        </Flex>
    );
};

ActionIcon.defaultProps = {
    actionArr: [],
};

export default ActionIcon;
