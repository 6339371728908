import React, { useState, useCallback, useEffect } from 'react';

import _ from 'lodash';
import { useDrag } from 'react-dnd';
import { sce } from 'sce-engg-model-19.09';
import Menu, { MenuItem, SubMenu } from 'rc-menu';
import { connect, DispatchProp } from 'react-redux';
import { Icon, Dialog } from '@abb/abb-common-ux-react';

import CanvasController from '../../Fabric';
import InstancesTable from './InstancesTable';
import { AssetType } from '../../../transformers/AssetType';
import ModalExportDialog from '../../ExportDialog/modalExportDialog';
import { getComputeModelsApi } from '../../../store/modelsPage/actions';
import { ModelUsedInstanceType, ModelsPageState } from '../../../store/modelsPage/types';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import dragDropIcons from '../../../assets/icons/leftNavigationToolBox/drag drop icon.svg';
import { DRAGGABLE_ITEMS, EXPORT_MODELS_MODES } from '../../../utils/constants/appConstants';
import { ComputeModelFromJson } from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';

import './style.scss';
import { TypeMenuAction } from '..';

interface AppProps {
    id: string;
    itemId?: string;
    item: AssetType;
    isDirty: boolean;
    permissions: {
        hasEdit?: boolean;
        hasDelete?: boolean;
        hasExport?: boolean;
        hasSave?: boolean;
    };
    editMode: boolean;
    activeModelId: string;
    canvas: CanvasController;
    isNew: boolean | undefined;
    filteredComputeModels?: any;
    activeAsset: AssetType | null;
    usedInstanceList: ModelUsedInstanceType[];
    computeModels: ModelsPageState['computeModels'];
    showConfirmationDialog: (modelId: string) => void;
    handeTypeMenuAction: (actionName: TypeMenuAction) => void;
    handleSelectedType: (
        modelId: string,
        node: AssetType,
        selectedItemId: string,
        computeModels: ModelsPageState['computeModels']
    ) => void;
}

export function LeftNavigationDataRow(props: AppProps) {
    const [isOpen, updateIsOpen] = useState(false);

    const handleToggle = useCallback(() => {
        updateIsOpen(!isOpen);
    }, [isOpen]);
    const [showModal, updateModalState] = useState(false);
    const [usedInstanceList, updateUsedInstanceList] = useState<ModelUsedInstanceType[]>([]);
    const [showExportDialog, updateShowExportDialog] = useState(false);

    useEffect(() => {
        updateUsedInstanceList(props.usedInstanceList);
    }, [props.usedInstanceList]);

    const handleActiveModelChange = (activeModelKey: string) => {
        props.itemId &&
            props.handleSelectedType(activeModelKey, props.item, props.itemId, props.computeModels);
    };

    return (
        <div className="data-row" id={`dataRow${props.id}`}>
            {!props.editMode ? (
                <div className={'data-row__parent'}>
                    {Object.keys(props.filteredComputeModels).map((key: string, index: number) => {
                        const modelName: string =
                            (props.filteredComputeModels[key] &&
                                props.filteredComputeModels[key].modelDetails.name) ||
                            '';
                        const isDisabled = props.activeModelId !== key;

                        return (
                            <div className="collapse-item-child">
                                <div
                                    className={
                                        props.activeModelId === key ? 'active' : 'model-text'
                                    }
                                    onClick={() =>
                                        props.isDirty || props.isNew
                                            ? props.showConfirmationDialog(key)
                                            : handleActiveModelChange(key)
                                    }
                                >
                                    <div className="types-names-p">
                                        <div className="types-names">{modelName}</div>
                                    </div>
                                </div>

                                <Menu
                                    mode="horizontal"
                                    triggerSubMenuAction="click"
                                    className="custom-rc-menu"
                                >
                                    <SubMenu
                                        popupOffset={[-10, 0]}
                                        title={<Icon name="abb/more" />}
                                        className={!isDisabled ? 'child-active' : ''}
                                    >
                                        <MenuItem
                                            key="mEdit"
                                            className="model-menu-item"
                                            disabled={
                                                !props.permissions.hasEdit ? true : isDisabled
                                            }
                                        >
                                            <div
                                                className="menu-item-wrapper"
                                                onClick={() => {
                                                    if (props.permissions.hasEdit && !isDisabled)
                                                        props.handeTypeMenuAction(
                                                            TypeMenuAction.OPEN_EDIT_MODE
                                                        );
                                                }}
                                            >
                                                <Icon name="abb/edit" />
                                                <div className="menu-item-name">Edit model</div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem
                                            key="mDetails"
                                            className="model-menu-item"
                                            disabled={isDisabled}
                                        >
                                            <div
                                                className="menu-item-wrapper"
                                                onClick={() => {
                                                    if (!isDisabled) {
                                                        props.handeTypeMenuAction(
                                                            TypeMenuAction.SHOW_UPDATE_MODAL
                                                        );
                                                    }
                                                }}
                                            >
                                                <Icon name="abb/information-circle-2" />
                                                <div className="menu-item-name">Details</div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem
                                            key="mSaveAs"
                                            className="model-menu-item"
                                            disabled={isDisabled}
                                        >
                                            <div
                                                className="menu-item-wrapper"
                                                onClick={() => {
                                                    if (!isDisabled) {
                                                        props.handeTypeMenuAction(
                                                            TypeMenuAction.SHOW_SAVEAS_MODAL
                                                        );
                                                    }
                                                }}
                                            >
                                                <Icon name="abb/save" />
                                                <div className="menu-item-name">Save As Model</div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem
                                            key="mViewInstances"
                                            className="model-menu-item"
                                            disabled={isDisabled}
                                        >
                                            <div
                                                className="menu-item-wrapper"
                                                onClick={() => {
                                                    if (!isDisabled) {
                                                        updateModalState(true);
                                                        props.handeTypeMenuAction(
                                                            TypeMenuAction.SHOW_USED_INSTANCE_MODAL
                                                        );
                                                    }
                                                }}
                                            >
                                                <Icon name="abb/view" className="menu-item-icon" />
                                                <div className="menu-item-name">View instances</div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem
                                            key="mDelete"
                                            className="model-menu-item"
                                            disabled={
                                                !props.permissions.hasDelete ? true : isDisabled
                                            }
                                        >
                                            <div
                                                className="menu-item-wrapper"
                                                onClick={() => {
                                                    if (props.permissions.hasDelete && !isDisabled)
                                                        props.handeTypeMenuAction(
                                                            TypeMenuAction.SHOW_DELETE_MODAL
                                                        );
                                                }}
                                            >
                                                <Icon name="abb/trash" className="menu-item-icon" />
                                                <div className="menu-item-name">Delete model</div>
                                            </div>
                                        </MenuItem>

                                        {sce.isDeveloper() && (
                                            <MenuItem
                                                key="mExport"
                                                className="model-menu-item"
                                                disabled={
                                                    !props.permissions.hasExport ? true : isDisabled
                                                }
                                            >
                                                <div
                                                    className="menu-item-wrapper"
                                                    onClick={() => {
                                                        if (
                                                            props.permissions.hasExport &&
                                                            !isDisabled
                                                        )
                                                            updateShowExportDialog(true);
                                                    }}
                                                >
                                                    <Icon
                                                        name="abb/export"
                                                        className="menu-item-icon"
                                                    />
                                                    <div className="menu-item-name">
                                                        Export model
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        )}
                                    </SubMenu>
                                </Menu>
                            </div>
                        );
                    })}

                    {showExportDialog && (
                        <ModalExportDialog
                            showExportDialog={showExportDialog}
                            onExportClose={() => updateShowExportDialog(false)}
                            modelMode={EXPORT_MODELS_MODES.MODEL}
                        />
                    )}
                    <Dialog
                        containerClassName="logic-add-dialog new-layout"
                        title="Instance List"
                        dimBackground={true}
                        showCloseButton={true}
                        isOpen={showModal}
                        onClose={() => {
                            updateModalState(false);
                            updateUsedInstanceList([]);
                        }}
                        className="instance-dialog-class"
                    >
                        <InstancesTable usedInstanceList={usedInstanceList} />
                    </Dialog>
                </div>
            ) : (
                <>
                    <div className="data-row__parent" onClick={handleToggle}>
                        <DraggableItem
                            id={props.id}
                            children={`${props.item.assetName} ${props.item.assetVersion}`}
                            asset={props.item}
                            className={`${props.editMode ? 'draggable' : 'non-draggable'} ${
                                !props.editMode &&
                                props.activeAsset &&
                                props.activeAsset.assetRef === props.item.assetRef
                                    ? 'active'
                                    : ''
                            }`}
                            editMode={props.editMode}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default LeftNavigationDataRow;

/** Draggable item  */
export const DraggableItem = connect()(SimpleDraggableItem);
export function SimpleDraggableItem(
    props: {
        asset: ObjectTypeDetail | FunctionTypeDetail | any;
        children: string;
        className?: string;
        editMode: boolean;
        id: string;
    } & DispatchProp
) {
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: DRAGGABLE_ITEMS.DIAGRAM_VIEW.canvas.src,
            asset: props.asset,
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const currentRoutePath = `/${
                window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
            }`;

            props.dispatch(
                getComputeModelsApi({
                    objectRef: props.asset.assetRef,
                    version: props.asset.assetVersion,
                    asset: props.asset,
                    currentRoutePath: currentRoutePath,
                })
            );
        },
        [props.asset]
    );

    return (
        <div
            ref={drag}
            onClick={!props.editMode ? handleClick : undefined}
            className={`${props.className} asset-name `}
            id={props.id}
        >
            <img src={dragDropIcons} alt="dragDropIcons" />
            <div className="draggable-item-title">
                <p className="drag-able-content">{props.children}</p>
            </div>
        </div>
    );
}
