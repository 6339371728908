import React, { Fragment } from "react";
import { sce } from "sce-engg-model-19.09";
import { Icon } from "@abb/abb-common-ux-react";

import { ShowMessageDispatchProps } from "../..";
import Condition from "../../../../components/shared/Condition";
import { DEL_FUNCTION_MSG } from "../../../../utils/constants/appConstants";
import { CONFIRMATION_BUTTON } from "../../../../utils/constants/uiConstants";
import "./style.scss";
interface EditDeletePopupProps {
    onClickCreate?: (mode: "EDIT" | "VIEW") => void;
    onClickDelete?:
        | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
        | undefined;
    hasSave?: boolean;
    hasDelete: boolean;
    hasEdit: boolean;
    hasExport: boolean;
    onExport?: ((val: boolean) => void) | undefined;
    onClickSaveAs?: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    showDecryptedFunction: boolean;
    libraryModeTitle: "Decrypted" | "Encrypted";
    onClickViewEncrypted: () => void;
    getPasswordForLibrariesToDeleteIPProtectedFunction: () => void;
    hasSameOrigin?: boolean;
    isGenericFunction?: boolean;
    isGlobal?: boolean;
}

export function EditDeletePopup(
    props: EditDeletePopupProps & ShowMessageDispatchProps
) {
    const handleFunctionDelete = (
        data: string,
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT && props.onClickDelete) {
            props.onClickDelete(event);
        }
    };

    return (
        <Fragment>
            {props.hasSameOrigin && !props.isGlobal && (
                <div
                    className={`active-view-btn-create ${
                        !props.hasEdit ? "disabledItem" : ""
                    }`}
                    onClick={() =>
                        props.onClickCreate && props.onClickCreate("EDIT")
                    }
                >
                    <Fragment>
                        <Icon name="abb/edit" sizeClass="small" />
                        <div>Edit</div>
                    </Fragment>
                </div>
            )}
            {props.showDecryptedFunction && (
                <div
                    className="active-view-btn-create"
                    onClick={() =>
                        props.libraryModeTitle === "Decrypted"
                            ? props.onClickCreate && props.onClickCreate("VIEW")
                            : props.onClickViewEncrypted()
                    }
                >
                    <Fragment>
                        <Icon
                            name={
                                props.libraryModeTitle === "Decrypted"
                                    ? "abb/view"
                                    : "abb/hide"
                            }
                            sizeClass="small"
                        />
                        <div>
                            View as{" "}
                            {props.libraryModeTitle === "Decrypted"
                                ? "Unlocked"
                                : "Locked"}
                        </div>
                    </Fragment>
                </div>
            )}
            {props.hasSameOrigin && (
                <>
                    <div
                        className={`active-view-btn-create ${
                            !props.hasSave ? "disabledItem" : ""
                        }`}
                        onClick={props.onClickSaveAs}
                    >
                        <Fragment>
                            <Icon name="abb/save" sizeClass="small" />
                            <div>Save As</div>
                        </Fragment>
                    </div>

                    <Condition when={!props.isGlobal}>
                        <div
                            className={`active-view-btn-create ${
                                !props.hasDelete ? "disabledItem" : ""
                            }`}
                            onClick={(event) => {
                                if (props.showDecryptedFunction) {
                                    props.getPasswordForLibrariesToDeleteIPProtectedFunction();
                                } else {
                                    props.showMessageModal!({
                                        title: "Delete",
                                        rejectButtonTitle:
                                            CONFIRMATION_BUTTON.CANCEL,
                                        acceptButtonTitle:
                                            CONFIRMATION_BUTTON.CONFIRM,
                                        handleStandardButton: (data) =>
                                            handleFunctionDelete(data, event),
                                        warningText: DEL_FUNCTION_MSG,
                                    });
                                }
                            }}
                        >
                            <Icon name="abb/trash" sizeClass="small" />
                            <div>Delete</div>
                        </div>
                    </Condition>

                    <Condition
                        when={
                            sce.isDeveloper() &&
                            props.isGenericFunction &&
                            !props.isGlobal
                        }
                    >
                        <div
                            className={`active-view-btn-create ${
                                !props.hasExport ? "disabledItem" : ""
                            }`}
                            onClick={() => {
                                props.onExport && props.onExport(true);
                            }}
                        >
                            <Icon name="abb/export" sizeClass="small" />
                            <div>Export</div>
                        </div>
                    </Condition>
                </>
            )}
        </Fragment>
    );
}
