import React, { useState } from 'react';
import { Icon, Popup, WithPopup } from '@abb/abb-common-ux-react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';

import ModalExportDialog from '../../../ExportDialog/modalExportDialog';
import { EXPORT_MODELS_MODES } from '../../../../utils/constants/appConstants';
import ModelForm from '../../../../routes/Home/CreateModel/PopUpForm';
import { CREATE_NEW_MODEL } from '../../../../utils/constants/uiConstants';

interface AssetMenuProps {
    data: any;
    activeAsset: any;
    showCreateModel: () => void;
}
function AssetMenu(props: AssetMenuProps) {
    const { activeAsset, data, showCreateModel } = props;

    const [showExportDialog, updateShowExportDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);

    const isDisabled = (activeAsset && data.assetRef !== activeAsset.assetRef) || false;

    return (
        <div
            className={`collapsible_2 ${
                activeAsset && data.assetRef !== activeAsset.assetRef ? 'disable' : ''
            } `}
        >
            <Menu mode="horizontal" triggerSubMenuAction="click" className="custom-rc-menu">
                <SubMenu
                    popupOffset={[-10, 0]}
                    title={<Icon name="abb/more" />}
                    className={!isDisabled ? 'item-active' : 'item-unactive'}
                >
                    <MenuItem key="mEdit" className="model-menu-item" disabled={isDisabled}>
                        {/* <WithPopup> */}
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                !isDisabled && showCreateModel();
                            }}
                        >
                            <Icon name="abb/edit" />
                            <div className="menu-item-name">Create model</div>
                        </div>

                        {/* <Popup
                                disabled={isDisabled}
                                trigger="click"
                                position="right center"
                                style={{ backgroundColor: 'red' }}
                                closeOnLostFocus
                                closeOnEscape
                                showCloseButton
                                render={(nextProps: any) => (
                                    <ModelForm
                                        modalMode="CREATE"
                                        submitBtnText={CREATE_NEW_MODEL.CREATE_BTN_TEXT}
                                    />
                                )}
                            />
                        </WithPopup> */}
                    </MenuItem>

                    <MenuItem key="mExport" className="model-menu-item" disabled={isDisabled}>
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                !isDisabled && updateShowExportDialog(true);
                            }}
                        >
                            <Icon name="abb/export" className="menu-item-icon" />
                            <div className="menu-item-name">Export model</div>
                        </div>
                    </MenuItem>
                    <MenuItem key="mImport" className="model-menu-item" disabled={isDisabled}>
                        <div
                            className="menu-item-wrapper"
                            onClick={() => {
                                !isDisabled && setImportDialog(true);
                            }}
                        >
                            <Icon name="abb/import" className="menu-item-icon" />
                            <div className="menu-item-name">Import model</div>
                        </div>
                    </MenuItem>
                </SubMenu>
            </Menu>

            {showExportDialog && (
                <ModalExportDialog
                    showExportDialog={showExportDialog}
                    onExportClose={() => updateShowExportDialog(false)}
                    modelMode={EXPORT_MODELS_MODES.OBJECT}
                />
            )}
            {importDialog && (
                <ModalExportDialog
                    showExportDialog={importDialog}
                    onExportClose={() => setImportDialog(false)}
                    importMode={true}
                    importForObjectType={true}
                />
            )}
        </div>
    );
}

export default AssetMenu;
