import React, { ReactNode, useState } from 'react';
import { Icon, Checkbox } from '@abb/abb-common-ux-react';
import { HtmlAttributes } from '@abb/abb-common-ux-react/utils/HtmlAttributes';;
import './style.scss';
import { on } from 'cluster';

interface ICollapsible extends HtmlAttributes {
    children?: ReactNode;
    title: string;
    iconsRight?: JSX.Element;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClickExpander?: (isExpanded: boolean) => void;
    isLeaf?: boolean;
    showCheckBox?: boolean;
    onCheck?: () => void;
    expanded? : boolean;
}
const Collapsible = (props: ICollapsible) => {
    const { children, title, iconsRight, onClickExpander, isLeaf, showCheckBox, onCheck } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(props.expanded ? props.expanded : false);
    const [showRightIcons, setShowRightIcons] = useState(false);
    return (
        <div
            id={props.id || ''}
            className={`wrapper-collapsible ${props.className ? props.className : ''}`}
            onMouseEnter={(e) => {
                e.stopPropagation();
                setShowRightIcons(true);
            }}
            onMouseOver={(e) => {
                e.stopPropagation();
                setShowRightIcons(true);
            }}
            onMouseLeave={(e) => {
                e.stopPropagation();
                setShowRightIcons(false);
            }}
        >
            <span>
                <div className="collapse-item">
                    {showCheckBox && (
                        <Checkbox sizeClass="small" value={false} onChange={onCheck} />
                    )}
                    {children && (
                        <>
                            <Icon
                                className="collapse-icon"
                                name={isExpanded ? 'abb/caret-down' : 'abb/caret-right'}
                                onClick={() => {
                                    setIsExpanded(!isExpanded);
                                    onClickExpander && onClickExpander(isExpanded);
                                }}
                            />

                            <Icon
                                className="label-icon"
                                name={isExpanded ? 'abb/folder-open' : 'abb/folder'}
                                onClick={() => {
                                    setIsExpanded(!isExpanded);
                                    onClickExpander && onClickExpander(isExpanded);
                                }}
                            />
                        </>
                    )}
                    <div
                        className={`collapse-title ${isLeaf ? 'leaf' : ''}`}
                        onClick={(e) => {
                            props.onClick && props.onClick(e);
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        {title}
                    </div>
                    {showRightIcons && (
                        <div className="icons-right">{iconsRight && iconsRight}</div>
                    )}
                </div>
            </span>

            {isExpanded && children}
        </div>
    );
};

export default Collapsible;
