interface Params {
  nodeId: string,
  ioType: string,
  ioName: string
}


export function generateFunctionId(options: Params) {
  const { nodeId, ioType, ioName } = options
  return `#/properties/functions/${nodeId}/${ioType}/${ioName}`

}
// same format is used by output finder
export function generateFunctionPath(options: Params) {
  const { nodeId, ioType, ioName } = options
  return `${nodeId}.${ioType}.${ioName}`
}