import React, { ReactNode, useEffect } from 'react';
import classnames from 'classnames';
import Flex from '../../components/shared/Flex';
import './styles.scss';

interface SidebarContainerProps {
    isCollapse?: boolean;
    children?: React.ReactNode;
    isModelEditMode?: boolean;
    useDraggable?: boolean;
    customClassName?: string;
    dragId?: string;
}

interface BaseLayoutProps {
    children: ReactNode;
    column?: boolean;
    hideTopBar?: boolean;
}

export const BaseLayout = (props: BaseLayoutProps) => {
    return (
        <Flex className={`base-layout-container ${props.hideTopBar ? 'hideTopBar' : ''}`}>
            {props.children}
        </Flex>
    );
};

export const NoAccess = () => {
    return (
        <div className="base-layout">
            <b style={{ fontSize: `large`, margin: `8% 0 0 25%` }}>
                You don't have required permissions and roles. Please check with admin for access.
            </b>
        </div>
    );
};

export const SidebarContainer = (props: SidebarContainerProps) => {
    let isSideBarDragging = false;

    let draggableId = props.dragId ? `${props.dragId}-sideBarContainer` : 'sideBarContainer';

    const handleDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        isSideBarDragging = true;
        if (props.useDraggable) {
            window.addEventListener('mousemove', moveSidebar);
        }
    };
    const moveSidebar = (e: MouseEvent) => {
        const element = document.getElementById(draggableId);
        console.log('moving sidebar');
        if (element && isSideBarDragging) {
            if (e.pageX > 298 && !props.dragId) {
                element.style.width = `${e.pageX + 2}px`;
            } else if (e.pageX - element.offsetLeft && props.dragId) {
                element.style.width = `${e.pageX - element.offsetLeft + 2}px`;
            }
        }
        if (props.useDraggable) {
            window.addEventListener('mouseup', handleDragOver);
        }
    };

    const handleDragOver = (e: MouseEvent) => {
        console.log('drag over', props.useDraggable, props.isModelEditMode);

        e.preventDefault();

        const element = document.getElementById(draggableId);
        if (element && isSideBarDragging) {
            if (e.pageX > 298 && !props.dragId) {
                element.style.width = `${e.pageX + 2}px`;
            } else if (e.pageX - element.offsetLeft > 298 && props.dragId) {
                element.style.width = `${e.pageX - element.offsetLeft + 2}px`;
            }
        }
        window.removeEventListener('mousemove', moveSidebar);
        window.removeEventListener('mouseup', handleDragOver);
        isSideBarDragging = false;
    };

    useEffect(() => {
        return () => {
            window.removeEventListener('mouseup', handleDragOver);
            window.removeEventListener('mousemove', moveSidebar);
        };
    }, []);

    useEffect(() => {
        if (props.isModelEditMode) {
            const element = document.getElementById(draggableId);
            if (element) {
                element.style.width = '330px';
            }
        }
        return () => {
            const element = document.getElementById(draggableId);
            if (element) {
                element.style.width = '303px';
            }
        };
    }, [props.isModelEditMode]);

    return (
        <Flex
            id={draggableId}
            className={classnames(`sidebar-container ${props.customClassName || ''}`, {
                'edit-model-sidebar': props.isModelEditMode,
                'is-collapse': props.isCollapse,
                'margin-left': props.isModelEditMode,
            })}
        >
            {props.children}
            {props.useDraggable && (
                <div
                    id={props.dragId ? props.dragId : 'dragbar'}
                    draggable
                    onMouseDown={handleDragStart}
                    className={'dragger-line'}
                ></div>
            )}
        </Flex>
    );
};

export const BodyContainer = (props: any) => {
    return (
        <Flex
            className={classnames('editor-body-container', {
                // "left-padding": !prop s.isModelEditMode,
            })}
            fill
        >
            {props.children}
        </Flex>
    );
};
