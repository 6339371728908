import { store } from '../../../containers/AppBoot';
import {
    DELETE_MODEL_TYPE,
    CONFIGURATION_TOOL_MODE,
    CONFIRMATION_MSG,
} from '../../../utils/constants/appConstants';
import MessageModal from '../../MessageModal';
import { deleteComputeModelApi } from '../../../store/modelsPage/actions';
import { Connection } from '../ConnectionController/types';
import {
    updateConfigurationToolMode,
    removeOverallSeverityFunction,
    updateCurrentSelectedFunctionWithLatestData,
    updateTypeWithLatestFunctions,
} from '../../../store/configurationTool/actions';
import { CONFIRMATION_BUTTON } from '../../../utils/constants/uiConstants';
import { handleOverallSeverityFuncDelete } from '../../../store/configurationTool/saga';
import { hideDialog, showDialog } from '../../../store/dialog/action';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import { FabricLineDeleteIcon } from '../UIHelpers/ioGroup/FabricILA';
import { FabricDropDownTitle } from '../UIHelpers/titleRectangle';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';

interface ConfirmationMessageProps {
    warningMessage: string;
    deleteType: string;
    target?: Connection;
    messageModalTitle?: string;
    acceptButtonTitle?: string;
    rejectButtonTitle?: string;
    node?: FabricDropDownTitle;
    asset?: FunctionTypeDetail;
    alarmEventPinCrossIconTarget?: FabricLineDeleteIcon;
}
const ConfirmationMessage = (props: ConfirmationMessageProps) => {
    const state = store.getState();

    const handleMessageClose = (value: string) => {
        if (value === CONFIRMATION_BUTTON.ACCEPT) {
            switch (props.deleteType) {
                case DELETE_MODEL_TYPE.MODEL: {
                    store.dispatch(deleteComputeModelApi());
                    if (store.getState().configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT) {
                        store.dispatch(
                            updateConfigurationToolMode({
                                mode: CONFIGURATION_TOOL_MODE.VIEW,
                            })
                        );
                    }
                    return;
                }
                case CONFIRMATION_MSG.UPDATE_FUNCTION: {
                    if (props.asset) {
                        store.dispatch(
                            updateCurrentSelectedFunctionWithLatestData({
                                currentSelectedFunction: props.asset,
                            })
                        );
                    }
                    return;
                }
                case CONFIRMATION_MSG.UPDATE_ALL_FUNCTION: {
                    store.dispatch(updateTypeWithLatestFunctions());
                    return;
                }
                case DELETE_MODEL_TYPE.OBJECT:

                case DELETE_MODEL_TYPE.FUNCTION: {
                    const canvasController = state.configurationTool.canvasController;
                    if (canvasController) {
                        canvasController.handleDeleteSelection();
                        if (
                            props.node &&
                            ((props.node.data.asset instanceof FunctionTypeDetail &&
                                props.node.data.asset.conditions) ||
                                (props.node.data.asset instanceof ObjectTypeDetail &&
                                    props.node.data.asset.isReferencedType))
                        ) {
                            const state = store.getState();
                            const isOverallSeverityFunctionDeletable =
                                handleOverallSeverityFuncDelete(
                                    state.configurationTool.json.assetData,
                                    []
                                );

                            if (isOverallSeverityFunctionDeletable) {
                                store.dispatch(removeOverallSeverityFunction());
                            }
                        }
                    }

                    return;
                }
                case DELETE_MODEL_TYPE.CONNECTION_LINE: {
                    const canvasController = state.configurationTool.canvasController;
                    if (canvasController && props.target) {
                        canvasController.handleDeleteConnection(props.target);
                    }
                    return;
                }
                case CONFIRMATION_MSG.DELETE_FUNCTION_ALARM_EVENT_INPUT_PIN: {
                    const canvasController = state.configurationTool.canvasController;
                    if (canvasController && props.alarmEventPinCrossIconTarget) {
                        const target = props.alarmEventPinCrossIconTarget;
                        const parentNode = target.data.parent;
                        canvasController.IOController.handleAlarmPinDelete({
                            ioId: target.data.circleData.id,
                            asset: target.data.asset,
                            parent: parentNode,
                        });
                        canvasController.markComputeModelDirty();
                    }
                    return;
                }
                default: {
                    console.log('invalid deleteModal type .');
                }
            }
        }
    };
    store.dispatch(
        showDialog({
            component: MessageModal,
            modalTitle: props.messageModalTitle || CONFIRMATION_BUTTON.DISCARD_CHANGES,
            customClassName: 'wrapper-message-modal',

            data: {
                warningText: props.warningMessage,
                standardButtonsOnBottom: [
                    {
                        text: props.rejectButtonTitle || CONFIRMATION_BUTTON.CANCEL,
                        type: 'discreet-black',
                        handler: (dlg: any) => {
                            handleMessageClose(CONFIRMATION_BUTTON.REJECT);
                            store.dispatch(hideDialog());
                        },
                    },
                    {
                        text: props.acceptButtonTitle || CONFIRMATION_BUTTON.CONFIRM,
                        type: 'primary-blue',
                        handler: (dlg: any) => {
                            handleMessageClose(CONFIRMATION_BUTTON.ACCEPT);
                            store.dispatch(hideDialog());
                        },
                    },
                ],
            },
        })
    );
};

export default ConfirmationMessage;
