import React, { ReactNode } from "react";
import { Modal } from "reactstrap";

import "./style.scss";
import Loader from "../Loader";

interface SpinnerModalProps {
    showModal: boolean;
    mode: "Spinner" | "Modal";
    type?: "radial" | "bar";
    sizeClass?: "small" | "medium" | "large";
    children?: ReactNode;
}

const SpinnerModal = (props: SpinnerModalProps) => {
    const { showModal, mode, type, sizeClass, children } = props;
    return mode === "Spinner" ? (
        showModal ? (
            <Loader sizeClass={sizeClass || "medium"} type={type || "radial"} />
        ) : null
    ) : (
        <Modal isOpen={showModal} backdrop={false} className="loading-modal">
            <Loader sizeClass="large" type="radial">
                {children}
            </Loader>
        </Modal>
    );
};

export default SpinnerModal;
