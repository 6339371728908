import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './style.scss';

import { Collapsible, CollapsibleContainer, WithPopup, Popup } from '@abb/abb-common-ux-react';
import { Button } from '@abb/abb-common-ux-react';

import Loader from '../Loader';
import SearchBar from '../SearchBar';
import CanvasController from '../Fabric';
import Condition from '../shared/Condition';
import DataRowItem from './DataRow/DataRowItem';
import { AssetType } from '../../transformers/AssetType';
import ModelForm from '../../routes/Home/CreateModel/PopUpForm';
import { MODEL_TYPES } from '../../utils/constants/appConstants';
import { ModelsPageState, ModelUsedInstanceType } from '../../store/modelsPage/types';

import './style.scss';
import { ImportModalPayload } from '../../store/exportAsset/types';
import Flex from '../shared/Flex';
import sce from 'sce-engg-model-19.09/lib/index/Sce';

interface AppProps {
    canvas: CanvasController;
    title?: string;
    editMode: boolean;
    searchValue: string;
    supportedModels: {
        key: string;
        value: any;
    }[];
    activeAsset: AssetType | null;
    searchText: string;
    computeModels: ModelsPageState['computeModels'];
    activeParentKey: string;
    computeModelsLoading: boolean;
    permissions: {
        hasSave?: boolean;
        hasExport?: boolean;
        hasEdit?: boolean;
        hasDelete?: boolean;
        hasImport?: boolean;
    };
    activeModelId: string;
    isDirty: boolean;
    isNew: boolean;
    usedInstanceList: ModelUsedInstanceType[];
    showMenu: boolean;
    selectedItemId: string;
    onSearch: (search: string) => void;
    getNodeData: (node: AssetType) => void;
    showCreateModel: () => void;
    updateActiveParentKey: (key: string) => void;
    showConfirmationDialog: (modelId: string) => void;
    handeTypeMenuAction: (actionName: TypeMenuAction) => void;
    handleSelectedItem: (itemId: string) => void;
    handleSelectedType: (
        modelId: string,
        node: AssetType,
        selectedItemId: string,
        computeModels: ModelsPageState['computeModels']
    ) => void;
    toggleImportExportDialog: () => void;
    showImportModal: (payload: ImportModalPayload) => void;
}
export enum TypeMenuAction {
    SHOW_UPDATE_MODAL = 'showUpdateModal',
    SHOW_SAVEAS_MODAL = 'showSaveAsModal',
    OPEN_EDIT_MODE = 'openEditMode',
    SHOW_DELETE_MODAL = 'showDeleteModel',
    SHOW_USED_INSTANCE_MODAL = 'showUsedInstanceModal',
}
export function LeftNavigationData(props: AppProps) {
    const [activeHeader, setActiveHeader] = useState('');
    const [supportedModels, setSupportedModels] = useState([] as any[]);
    // const [selectedItem, setSelectedItemId] = useState('');
    const [hasDefaultElement, setDefaultElement] = useState(false);
    const show = (clicked: Collapsible, parents: Collapsible[]) => {
        if (clicked.props.itemId) {
            setActiveHeader(clicked.props.itemId);
        }
    };
    useEffect(() => {
        if (supportedModels.length > 0) {
            if (!hasDefaultElement) {
                const defaultModel = document.getElementById(props.activeParentKey);
                if (defaultModel) {
                    setDefaultElement(true);
                    defaultModel.click();
                }
            }
        }
    }, [supportedModels]);

    useEffect(() => {
        const activeAsset = _.get(props.activeAsset, 'assetType', '');
        const modelIndex = props.supportedModels.findIndex((i) => i.key === activeAsset);
        setSupportedModels(props.supportedModels);

        if (modelIndex > -1 && props.supportedModels.length > 0) {
            setActiveHeader(props.supportedModels[0].key);
        }
    }, [props.supportedModels]);

    useEffect(() => {
        if (!props.searchText) {
            setSupportedModels(props.supportedModels);
        } else {
            let filteredList = [] as any[];
            props.supportedModels.forEach((model, index) => {
                let items = model.value.filter((x: AssetType) =>
                    x.assetName.toLowerCase().includes(props.searchText.toLowerCase())
                );
                filteredList.push({ key: model.key, value: [...items] });
            });
            setSupportedModels(filteredList);
        }
    }, [props.searchText]);

    const searchBarPlaceholderText =
        props.title === MODEL_TYPES.OBJECTS.label.toUpperCase()
            ? 'Search Object Types'
            : MODEL_TYPES.FUNCTIONS.value;

    console.log('###computemodels :', props.computeModels, props.activeAsset, props.selectedItemId);

    return (
        <div className="wrapper-left-navigation-data">
            <Condition when={sce.isDeveloper()}>
                <Flex row>
                    <Button
                        className="import-export-btn"
                        icon="abb/import"
                        text="Import"
                        sizeClass="small"
                        disabled={!props.permissions.hasImport ? true : props.computeModelsLoading}
                        onClick={() => {
                            props.showImportModal({
                                currentRoute: 'Types',
                            });
                        }}
                        id="mImportBtn"
                    />
                    <Button
                        className="import-export-btn"
                        icon="abb/export"
                        disabled={!props.permissions.hasExport ? true : props.computeModelsLoading}
                        text="Export"
                        sizeClass="small"
                        onClick={() => {
                            props.toggleImportExportDialog && props.toggleImportExportDialog();
                        }}
                        id="mExportBtn"
                    />
                </Flex>
            </Condition>

            {/* <WithPopup> */}
            <div>
                <Button
                    text="Create New Model"
                    icon="abb/plus"
                    sizeClass="small"
                    className="create-new-type-btn"
                    onClick={() => props.showCreateModel()}
                />
            </div>
            {/* <Popup
                    disabled={!props.permissions.hasSave}
                    trigger="click"
                    style={{ backgroundColor: 'red' }}
                    showCloseButton
                    render={(nextProps: any) => (
                        <ModelForm
                            modalMode="CREATE"
                            submitBtnText={CREATE_NEW_MODEL.CREATE_BTN_TEXT}
                        />
                    )}
                />
            </WithPopup> */}
            <div className="wrapper-data">
                <div className="left-search-bar">
                    <SearchBar
                        placeholder={`${searchBarPlaceholderText}`}
                        borderColor="border-dark-gray"
                        onChange={props.onSearch}
                        value={props.searchValue}
                    />
                </div>
                <div className="wrapper-data-list">
                    <div className="wrapper-collapsible-container">
                        <Condition when={!supportedModels || !supportedModels.length}>
                            <Loader sizeClass="small" type="radial" />
                        </Condition>
                        <Condition when={supportedModels.length}>
                            <CollapsibleContainer
                                onItemClick={show}
                                style={{
                                    width: '100%',
                                }}
                            >
                                {supportedModels &&
                                    supportedModels.map((modelItem, index) => {
                                        return (
                                            <Collapsible
                                                id={modelItem.key}
                                                icon="abb/folder"
                                                title={
                                                    modelItem.key && modelItem.key.length > 15
                                                        ? modelItem.key.substring(0, 25) + '...'
                                                        : modelItem.key
                                                }
                                                itemId={modelItem.key}
                                                data-tip={modelItem.key}
                                                key={modelItem.key}
                                            >
                                                {modelItem.value &&
                                                    modelItem.value.map(
                                                        (data: AssetType, itemIndex: number) => {
                                                            return (
                                                                <DataRowItem
                                                                    key={`${modelItem.key}_${data.assetRef}`}
                                                                    id={`${modelItem.key}_${data.assetRef}`}
                                                                    parentkey={modelItem.key}
                                                                    data={data}
                                                                    itemIndex={itemIndex}
                                                                    showCreateModel={
                                                                        props.showCreateModel
                                                                    }
                                                                    canvas={props.canvas}
                                                                    editMode={props.editMode}
                                                                    selectedItem={
                                                                        props.selectedItemId
                                                                    }
                                                                    handleSelectedItem={
                                                                        props.handleSelectedItem
                                                                    }
                                                                    activeAsset={props.activeAsset}
                                                                    computeModels={
                                                                        props.computeModels
                                                                    }
                                                                    computeModelsLoading={
                                                                        props.computeModelsLoading
                                                                    }
                                                                    getNodeData={props.getNodeData}
                                                                    permissions={props.permissions}
                                                                    updateActiveParentKey={
                                                                        props.updateActiveParentKey
                                                                    }
                                                                    activeModelId={
                                                                        props.activeModelId
                                                                    }
                                                                    isDirty={props.isDirty}
                                                                    isNew={props.isNew}
                                                                    showConfirmationDialog={
                                                                        props.showConfirmationDialog
                                                                    }
                                                                    usedInstanceList={
                                                                        props.usedInstanceList
                                                                    }
                                                                    showMenu={props.showMenu}
                                                                    handeTypeMenuAction={
                                                                        props.handeTypeMenuAction
                                                                    }
                                                                    handleSelectedType={
                                                                        props.handleSelectedType
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </Collapsible>
                                        );
                                    })}
                            </CollapsibleContainer>
                        </Condition>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeftNavigationData;
