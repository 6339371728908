import React, { ReactNode } from "react";
import "./style.scss";
interface AppProps {
  children: ReactNode;
}

function THead(props: AppProps) {
  return <thead className="table-header">{props.children}</thead>;
}

export default THead;
