import _ from 'lodash';
import { sce, AbilityService } from 'sce-engg-model-19.09';
import { put, takeLatest, select } from 'redux-saga/effects';

import {
    addNewConditionMonitorModelsFailure,
    addNewConditionMonitorModelsRequest,
    addNewConditionMonitorModelsSuccess,
    getSupportedConditionMonitorsFailure,
    getSupportedConditionMonitorsSuccess,
    deleteConditionMonitorModelsRequest,
    deleteConditionMonitorModelsSuccess,
    deleteConditionMonitorModelsFailure,
    updateConditionMonitorModelsRequest,
    updateConditionMonitorModelsSuccess,
    updateConditionMonitorModelsFailure,
    getSupportedConditionMonitorsRequest,
} from '../action';
import { StoreState } from '../..';
import { SupportedNewModel } from '../types';
import { ActionTypes } from '../../actionTypes';
import { abbSDKGetErrorMessage } from '../../../utils/helpers';
import { showNotificationModal } from '../../notificationModal/action';
import { NOTIFICATION_MODAL_STATUS, MODEL } from '../../../utils/constants/appConstants';

function* getSupportedConditionMonitors() {
    try {
        const res = yield sce.getSupportedCondintionModelWithTenantInfo();
        let updatedMonitors: SupportedNewModel[] = [];
        const modelsWithTenantSpecificTag = (res.details as SupportedNewModel[]).filter(
            (item) => item.tenant === true && item.modelName !== MODEL.DEFAULT_MODEL_ID
        );
        let publicModels = (res.details as SupportedNewModel[]).filter(
            (item) => item.tenant === false && item.modelName !== MODEL.DEFAULT_MODEL_ID
        );
        let defaultMonitor = (res.details as SupportedNewModel[]).filter(
            (item) => item.modelName === MODEL.DEFAULT_MODEL_ID
        )[0];
        updatedMonitors = [...modelsWithTenantSpecificTag, defaultMonitor];
        yield put(getSupportedConditionMonitorsSuccess(updatedMonitors));
    } catch (err) {
        yield put(getSupportedConditionMonitorsFailure());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(err)],
            })
        );
    }
}

function* addNewConditionMonitorModelsSaga(
    action: ReturnType<typeof addNewConditionMonitorModelsRequest>
) {
    const { payload } = action;

    let modelList: string[] = [];
    payload.forEach((modelId) => {
        modelList.push(modelId.modelName);
    });

    try {
        const res = yield sce.addNewConditionMonitorModels(modelList);
        yield put(
            addNewConditionMonitorModelsSuccess({
                message: res.details[0].title,
            })
        );
        yield put(getSupportedConditionMonitorsRequest());
        yield put(
            showNotificationModal({
                title: res.details[0].title,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
    } catch (error) {
        yield put(addNewConditionMonitorModelsFailure(abbSDKGetErrorMessage(error)));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* deleteConditionMonitorModelsSaga(
    action: ReturnType<typeof deleteConditionMonitorModelsRequest>
) {
    const { supportedConditionMonitors, onSuccess } = action.payload;
    try {
        const res = yield sce.removeConditionMonitorModels(supportedConditionMonitors);
        if (res.status === 200) {
            let supportedConditionMonitorList: SupportedNewModel[] = yield select(
                (state: StoreState) => state.supportedConditionMonitor.supportedConditionMonitorList
            );
            supportedConditionMonitorList = supportedConditionMonitorList.filter(
                (item) => !supportedConditionMonitors.includes(item.modelName)
            );
            onSuccess();
            yield put(deleteConditionMonitorModelsSuccess(supportedConditionMonitorList));

            yield put(
                showNotificationModal({
                    title: res.details[0].title,
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: 'banner',
                })
            );
        }
    } catch (error) {
        yield put(deleteConditionMonitorModelsFailure(abbSDKGetErrorMessage(error)));
        yield put(
            showNotificationModal({
                title: abbSDKGetErrorMessage(error),
                resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                type: 'banner',
            })
        );
        yield put(getSupportedConditionMonitorsRequest());
    }
}

function* updateConditionMonitorModelsSaga(
    action: ReturnType<typeof updateConditionMonitorModelsRequest>
) {
    try {
        const { payload } = action;

        let modelList: string[] = [];
    payload.forEach((modelId) => {
        modelList.push(modelId.modelName);
    });
        yield sce.updateConditionMonitorModels(modelList);
        yield put(getSupportedConditionMonitorsRequest());
        yield put(updateConditionMonitorModelsSuccess({ message: 'Updated Models Successfully' }));
        yield put(
            showNotificationModal({
                title: 'Updated Models Successfully',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
    } catch (error) {
        yield put(updateConditionMonitorModelsFailure(abbSDKGetErrorMessage(error)));
    }
}

export default [
    takeLatest(ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_REQUEST, getSupportedConditionMonitors),
    takeLatest(
        ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_REQUEST,
        addNewConditionMonitorModelsSaga
    ),
    takeLatest(
        ActionTypes.DELETE_CONDITION_MONITOR_MODELS_REQUEST,
        deleteConditionMonitorModelsSaga
    ),
    takeLatest(
        ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_REQUEST,
        updateConditionMonitorModelsSaga
    ),
];
