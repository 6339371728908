import { SupportedNewModel } from './types';
import { ActionTypes } from '../actionTypes';

export const getSupportedConditionMonitorsRequest = () => {
    return <const>{
        type: ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_REQUEST,
    };
};

export const getSupportedConditionMonitorsSuccess = (payload: SupportedNewModel[]) => {
    return <const>{
        type: ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_SUCCESS,
        payload,
    };
};

export const getSupportedConditionMonitorsFailure = () => {
    return <const>{
        type: ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_FAILURE,
    };
};

export const addNewConditionMonitorModelsRequest = (payload: SupportedNewModel[]) => {
    return <const>{
        type: ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_REQUEST,
        payload,
    };
};

export const addNewConditionMonitorModelsSuccess = (payload: { message: string }) => {
    return <const>{
        type: ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_SUCCESS,
        payload,
    };
};

export const addNewConditionMonitorModelsFailure = (error: string) => {
    return <const>{
        type: ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_FAILURE,
        payload: error,
    };
};

export const deleteConditionMonitorModelsRequest = (payload: {
    supportedConditionMonitors: string[];
    onSuccess: () => void;
}) => {
    return <const>{
        type: ActionTypes.DELETE_CONDITION_MONITOR_MODELS_REQUEST,
        payload,
    };
};

export const deleteConditionMonitorModelsSuccess = (payload: SupportedNewModel[]) => {
    return <const>{
        type: ActionTypes.DELETE_CONDITION_MONITOR_MODELS_SUCCESS,
        payload,
    };
};

export const deleteConditionMonitorModelsFailure = (error: string) => {
    return <const>{
        type: ActionTypes.DELETE_CONDITION_MONITOR_MODELS_FAILURE,
        payload: error,
    };
};

export const updateConditionMonitorModelsRequest = (payload: SupportedNewModel[]) => {
    return <const>{
        type: ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_REQUEST,
        payload,
    };
};

export const updateConditionMonitorModelsSuccess = (payload: { message: string }) => {
    return <const>{
        type: ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_SUCCESS,
        payload,
    };
};

export const updateConditionMonitorModelsFailure = (error: string) => {
    return <const>{
        type: ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_FAILURE,
        payload: error,
    };
};

export const resetGlobalMonitorModelsError = () => {
    return <const>{
        type: ActionTypes.RESET_GLOBAL_MONITOR_MODELS_ERROR,
    };
};
