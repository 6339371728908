import { FabricILWN, LineWithNameParams } from './FabricLineWithName';
import { fabric } from 'fabric';
import { fontSize, canvasIOInhibitColor, outputLineLength } from '../UiConfig';

interface Params extends LineWithNameParams {
    value: string;
}

export class FabricILWV extends FabricILWN {
    value: fabric.Text;
    constructor(options: Params) {
        super(options);

        const text = options.value.length > 10 ? options.value.slice(0, 7) + '...' : options.value;
        this.value = new fabric.Text(text, {
            fontSize: fontSize * 0.8,
            left: outputLineLength / 2,
            originX: 'center',
            selectable: false,
        });

        if (options.value.length > 10) {
            this.value.data = { tooltip: options.value };
        }
    }
}

// Input Line Inhibit - used in function inputs
export class FabricILI extends FabricILWV {
    constructor(options: Params) {
        super(options);

        this.ioCircle.fill = canvasIOInhibitColor;
        this.ioLine.getObjects('line')[0].stroke = canvasIOInhibitColor;
    }
}
