import React, { useState, useEffect, useMemo } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import './style.scss';
import { StoreState } from '../../store';
import { NavBarProps as AppProps } from './types';
import NthLevelMenu from './NthLevelMenu';
import Loader from '../Loader';
import { any } from 'prop-types';
import Condition from '../shared/Condition';
import { Checkbox } from '@abb/abb-common-ux-react';

function CustomLeftNav(
    props: AppProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
    const {
        nLevelMenuProps,
        navData,
        parentSelected,
        updateParentSelected,
        checkAllProps,
        filterText,
        resetStateTrigger,
    } = props;
    const [selected, updateSelected] = useState(parentSelected ? parentSelected : '');
    const [checkAll, updateCheckAll] = useState(false as boolean);

    useEffect(() => {
        if (checkAllProps && checkAllProps.checkAll !== checkAll) {
            checkAllProps && updateCheckAll(checkAllProps.checkAll);
            handleCheckAll(checkAllProps.checkAll);
        }
    }, [checkAllProps && checkAllProps.checkAll]);

    useEffect(() => {
        if (checkAllProps && checkAllProps.checkAll !== checkAll) {
            checkAllProps && checkAllProps.updateCheckAll(checkAll);
        }
    }, [checkAll]);

    useEffect(() => {
        if (parentSelected !== selected) {
            typeof parentSelected === 'string' && updateSelected(parentSelected);
        }

    }, [parentSelected]);

    useEffect(() => {
        if (parentSelected !== selected) {
            updateParentSelected && updateParentSelected(selected);
        }
    }, [selected]);

    const constructChildNodes = (menuPropIndex: number, listData: any[], indices: number[]) => {
        if (menuPropIndex === nLevelMenuProps.length) {
            return;
        }
        const menuProps = nLevelMenuProps[menuPropIndex];
        let nextLevelMenuIndex = menuPropIndex + 1;
        if (listData && listData.length === 0) {
            return <div className="empty-message">No items Available</div>;
        }
        const childNodes = listData.map((listDataItem, index) => {
            let listDataItemIndices = [...indices, index];
            if (
                menuProps.filter &&
                filterText &&
                menuProps.filterMethod &&
                !menuProps.filterMethod(listDataItem)
            ) {
                return <></>;
            }
            let menuItemChildNodes =
                menuProps.nextLevelAsyncLoader &&
                !(menuProps.nextLevelKey && listDataItem[menuProps.nextLevelKey]) ? (
                    <Loader sizeClass="small" type="radial" />
                ) : (
                    constructChildNodes(
                        nextLevelMenuIndex,
                        menuProps.nextLevelKey && listDataItem[menuProps.nextLevelKey],
                        listDataItemIndices
                    )
                );
            if (menuProps.component) {
                const CustomComponent = menuProps.component;
                const customComponentProps = menuProps.componentProps || {};
                return (
                    <CustomComponent
                        key={listDataItem[menuProps.keyIdentifier]}
                        {...menuProps}
                        indices={listDataItemIndices}
                        treeState={treeStates}
                        updateCheckState={updateCheckState}
                        setSelectedState={setSelectedState}
                        nLevelMenuProps={nLevelMenuProps}
                        data={listDataItem}
                        children={menuItemChildNodes}
                        selected={selected}
                        updateSelected={updateSelected}
                        {...customComponentProps}
                    />
                );
            }
            return (
                <NthLevelMenu
                    key={listDataItem[menuProps.keyIdentifier]}
                    {...menuProps}
                    indices={listDataItemIndices}
                    treeState={treeStates}
                    updateCheckState={updateCheckState}
                    setSelectedState={setSelectedState}
                    nLevelMenuProps={nLevelMenuProps}
                    data={listDataItem}
                    children={menuItemChildNodes}
                    selected={selected}
                    updateSelected={updateSelected}
                />
            );
        });
        return childNodes;
    };

    const constructMenuNode = () => {
        const menuProps = nLevelMenuProps[0];
        const childNodes = props.navData.map((listItem, listItemIndex) => {
            if (
                menuProps.filter &&
                filterText &&
                menuProps.filterMethod &&
                !menuProps.filterMethod(listItem)
            ) {
                return <></>;
            }
            let menuItemChildNodes =
                menuProps.nextLevelAsyncLoader &&
                !(menuProps.nextLevelKey && listItem[menuProps.nextLevelKey]) ? (
                    <Loader sizeClass="small" type="radial" />
                ) : (
                    constructChildNodes(
                        1,
                        menuProps.nextLevelKey && listItem[menuProps.nextLevelKey],
                        [listItemIndex]
                    )
                );
            return (
                <NthLevelMenu
                    key={listItem[menuProps.keyIdentifier]}
                    {...menuProps}
                    indices={[listItemIndex]}
                    treeState={treeStates}
                    updateCheckState={updateCheckState}
                    setSelectedState={setSelectedState}
                    nLevelMenuProps={nLevelMenuProps}
                    data={listItem}
                    children={menuItemChildNodes}
                    selected={selected}
                    updateSelected={updateSelected}
                />
            );
        });
        return childNodes;
    };

    const constructChildState = (menuPropIndex: number, listData: any[], treeData: any[]) => {
        if (menuPropIndex === nLevelMenuProps.length) {
            return;
        }
        const menuProps = nLevelMenuProps[menuPropIndex];
        let nextLevelMenuIndex = menuPropIndex + 1;
        if ((listData && listData.length === 0) || listData === undefined) {
            return listData && [];
        }
        if (treeData && treeData.length > listData.length) {
            treeData = treeData.filter((item: any) => {
                return (
                    listData.filter((listItem: any) => {
                        return listItem[menuProps.keyIdentifier] === item.id;
                    }).length > 0
                );
            });
        }
        const childState: any[] = listData.map((listDataItem, listDataIndex) => {
            let menuItemChildStates = constructChildState(
                nextLevelMenuIndex,
                menuProps.nextLevelKey && listDataItem[menuProps.nextLevelKey],
                treeData &&
                    treeData[listDataIndex] &&
                    menuProps.nextLevelKey &&
                    treeData[listDataIndex][menuProps.nextLevelKey]
            );
            return menuProps.nextLevelKey
                ? {
                      id: listDataItem[menuProps.keyIdentifier],
                      checked:
                          checkAll ||
                          (treeData &&
                              treeData[listDataIndex] &&
                              treeData[listDataIndex].checked) ||
                          false,
                      [menuProps.nextLevelKey]: menuItemChildStates,
                      selected: false,
                  }
                : {
                      id: listDataItem[menuProps.keyIdentifier],
                      checked:
                          checkAll ||
                          (treeData &&
                              treeData[listDataIndex] &&
                              treeData[listDataIndex].checked) ||
                          false,
                      selected: false,
                  };
        });
        return childState;
    };

    const constructState = () => {
        const menuProps = nLevelMenuProps[0];
        let currentState = [...treeStates];
        if (currentState && currentState.length > props.navData.length) {
            currentState = currentState.filter((item: any) => {
                return (
                    props.navData.filter((listItem: any) => {
                        return listItem[menuProps.keyIdentifier] === item.id;
                    }).length > 0
                );
            });
        }
        const childStates = props.navData.map((listItem, listItemIndex) => {
            let menuItemChildStates = constructChildState(
                1,
                menuProps.nextLevelKey && listItem[menuProps.nextLevelKey],
                currentState &&
                    currentState[listItemIndex] &&
                    menuProps.nextLevelKey &&
                    currentState[listItemIndex][menuProps.nextLevelKey]
            );
            return menuProps.nextLevelKey
                ? {
                      id: listItem[menuProps.keyIdentifier],
                      checked:
                          checkAll ||
                          (currentState &&
                              currentState[listItemIndex] &&
                              currentState[listItemIndex].checked) ||
                          false,
                      [menuProps.nextLevelKey]: menuItemChildStates,
                      selected: false,
                  }
                : {
                      id: listItem[menuProps.keyIdentifier],
                      checked:
                          checkAll ||
                          (currentState &&
                              currentState[listItemIndex] &&
                              currentState[listItemIndex].checked) ||
                          false,
                      selected: false,
                  };
        });
        return childStates;
    };

    const constructDefaultChildState = (
        menuPropIndex: number,
        listData: any[],
        treeData: any[]
    ) => {
        if (menuPropIndex === nLevelMenuProps.length) {
            return;
        }
        const menuProps = nLevelMenuProps[menuPropIndex];
        let nextLevelMenuIndex = menuPropIndex + 1;
        if ((listData && listData.length === 0) || listData === undefined) {
            return listData && [];
        }
        const childState: any[] = listData.map((listDataItem, listDataIndex) => {
            let menuItemChildStates = constructDefaultChildState(
                nextLevelMenuIndex,
                menuProps.nextLevelKey && listDataItem[menuProps.nextLevelKey],
                treeData &&
                    treeData[listDataIndex] &&
                    menuProps.nextLevelKey &&
                    treeData[listDataIndex][menuProps.nextLevelKey]
            );
            return menuProps.nextLevelKey
                ? {
                      checked: false,
                      [menuProps.nextLevelKey]: menuItemChildStates,
                      selected: false,
                  }
                : {
                      checked: false,
                      selected: false,
                  };
        });
        return childState;
    };

    const constructDefaultState = () => {
        const menuProps = nLevelMenuProps[0];
        const childStates = props.navData.map((listItem, listItemIndex) => {
            let menuItemChildStates = constructDefaultChildState(
                1,
                menuProps.nextLevelKey && listItem[menuProps.nextLevelKey],
                treeStates &&
                    treeStates[listItemIndex] &&
                    menuProps.nextLevelKey &&
                    treeStates[listItemIndex][menuProps.nextLevelKey]
            );
            return menuProps.nextLevelKey
                ? {
                      checked: false,
                      [menuProps.nextLevelKey]: menuItemChildStates,
                      selected: false,
                  }
                : {
                      checked: false,
                      selected: false,
                  };
        });
        return childStates;
    };

    const setCheckBoxValue = (
        indices: number[],
        updatedObj: { checked: boolean },
        updateParents = true,
        updateChildren = true
    ) => {
        if (indices.length > nLevelMenuProps.length && indices.length < 1) {
            return;
        }
        let menuPropIndex = indices.length - 1;
        let currentObj: any = treeStates;
        indices.forEach((childKey, index) => {
            let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
            if (nextLevelMenuKey && index < menuPropIndex) {
                currentObj = currentObj[childKey][nextLevelMenuKey];
            } else {
                currentObj = currentObj[childKey];
            }
        });
        if (currentObj.checked === updatedObj.checked) {
            return;
        }
        currentObj.checked = updatedObj.checked;

        /* Update Parent States */
        if (updateParents) {
            if (updatedObj.checked) {
                let siblingsArr: any[] = treeStates;
                indices.slice(0, indices.length - 1).forEach((childKey, index) => {
                    let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
                    if (nextLevelMenuKey) {
                        siblingsArr = siblingsArr[childKey][nextLevelMenuKey];
                    }
                });
                let filteredSiblingsArr = siblingsArr.filter((stateObj) => {
                    return stateObj.checked === true;
                });
                if (filteredSiblingsArr.length === siblingsArr.length) {
                    setCheckBoxValue(
                        indices.slice(0, indices.length - 1),
                        { ...updatedObj },
                        true,
                        false
                    );
                }
            } else {
                setCheckBoxValue(
                    indices.slice(0, indices.length - 1),
                    { ...updatedObj },
                    true,
                    false
                );
            }
        }

        /* Update Child States */
        if (indices.length && indices.length < nLevelMenuProps.length && updateChildren) {
            let currentLevelMenuIndex = indices.length - 1;
            let nextLevelMenuKey = nLevelMenuProps[currentLevelMenuIndex].nextLevelKey;
            nextLevelMenuKey &&
                currentObj[nextLevelMenuKey] &&
                currentObj[nextLevelMenuKey].forEach((childObj: any, index: number) => {
                    setCheckBoxValue([...indices, index], { ...updatedObj }, false, true);
                });
        }
    };

    const updateCheckState = (indices: number[], updatedObj: { checked: boolean }) => {
        setCheckBoxValue(indices, updatedObj);
        if (!updatedObj.checked && checkAll) {
            updateCheckAll(false);
        } else {
            let isAllChecked = true;
            treeStates.forEach((item: any) => {
                if (!item.checked) {
                    isAllChecked = false;
                }
            });
            if (isAllChecked) {
                updateCheckAll(true);
            }
        }
        setTreeStates([...treeStates]);
    };

    const handleCheckAll = (value: boolean) => {
        treeStates.forEach((item: any, index: number) => {
            setCheckBoxValue([index], { checked: value });
        });
        setTreeStates([...treeStates]);
    };

    useEffect(() => {
        setTreeStates([...treeStates]);
    }, [filterText]);

    const setSelectedState = (indices: number[], updatedObj: { selected: boolean }) => {
        if (indices.length > nLevelMenuProps.length && indices.length < 1) {
            return;
        }
        let menuPropIndex = indices.length - 1;
        let currentObj: any = treeStates;
        indices.forEach((childKey, index) => {
            let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
            currentObj = currentObj && currentObj[childKey];
            if (currentObj && nextLevelMenuKey && index < menuPropIndex) {
                currentObj = currentObj[nextLevelMenuKey];
            }
        });
        currentObj.selected = updatedObj.selected;
        updateSelectedFromChild(0, indices, treeStates[indices[0]]);
        setTreeStates([...treeStates]);
    };

    const updateSelectedFromChild = (currentLevel: number, indices: number[], stateObj: any) => {
        if (currentLevel === indices.length - 1 || !stateObj) {
            return;
        }
        let nextLevelMenuKey = nLevelMenuProps[currentLevel].nextLevelKey;
        updateSelectedFromChild(
            currentLevel + 1,
            indices,
            nextLevelMenuKey && stateObj[nextLevelMenuKey][indices[currentLevel + 1]]
        );
        let isChildSelected =
            nextLevelMenuKey &&
            stateObj[nextLevelMenuKey].filter((item: any) => item.selected).length > 0;
        stateObj.selected = isChildSelected;
    };

    const updateIsLoadingState = (indices: number[]) => {
        let menuPropIndex = indices.length - 1;
        let currentObj: any = treeStates;
        indices.forEach((childKey, index) => {
            let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
            if (nextLevelMenuKey && index < menuPropIndex) {
                currentObj = currentObj[childKey][nextLevelMenuKey];
            } else {
                currentObj = currentObj[childKey];
            }
        });
        currentObj.isLoading = true;
        setTreeStates([...treeStates]);
    };

    const [treeStates, setTreeStates] = useState([] as any[]);
    const [dragAndDropState, setDragAndDropState] = useState({
        isDragging: true,
        draggableData: {},
        droppableData: {},
    });

    useEffect(() => {
        setTreeStates(constructDefaultState());
    }, [resetStateTrigger]);

    useEffect(() => {
        setTreeStates(constructState());
    }, [props.navData]);

    return (
        <div className="custom-left-nav">
            {props.navData && treeStates.length === props.navData.length && constructMenuNode()}
        </div>
    );
}

function mapStateToProps(state: StoreState) {
    return {};
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLeftNav);
