import { FabricFunctionRectangle } from '../UIHelpers/functionRectangle';
import { FabricObjectRectangle } from '../UIHelpers/FabricObjectRectangle';
import {
    outputCircleRadius,
    triggerCircleRadius,
    outputLineLength,
    canvasBorderPadding,
} from '../UIHelpers/UiConfig';
import { FabricDropDownTitle } from '../UIHelpers/titleRectangle';

/**
 *
 * @param options Take node and canvasContext and return the width and height of AssetNode including both input and output pins if they are avaialble.
 */
export const calcAssetNodeDimension = (options: {
    node: FabricDropDownTitle;
    canvasContext: CanvasRenderingContext2D;
}) => {
    const { node, canvasContext } = options;
    const assetNodeHeight = node.getScaledHeight();
    let longestInputPinWidth = 0;
    let longestOutputPinWidth = 0;
    const nodeWidth = node.getScaledWidth();
    const nodeHeight = node.getScaledHeight();

    if (node instanceof FabricFunctionRectangle) {
        const inputTextWidth = canvasContext.measureText(node.data.asset.longestInputPinText).width;
        const outputTextWidth = canvasContext.measureText(
            node.data.asset.longestOutputPinText
        ).width;

        if (inputTextWidth !== 0) {
            longestInputPinWidth =
                2 * (outputCircleRadius + triggerCircleRadius) +
                // inputTextWidth +
                canvasBorderPadding +
                outputLineLength;
        }
        if (outputTextWidth !== 0) {
            longestOutputPinWidth =
                2 * (outputCircleRadius + triggerCircleRadius) +
                // outputTextWidth +
                canvasBorderPadding +
                outputLineLength;
        }

        const assetNodeWidth = longestInputPinWidth + nodeWidth + longestOutputPinWidth;

        return {
            assetNodeWidth,
            assetNodeHeight,
            longestInputPinWidth: 0,
            longestOutputPinWidth: 0,
            nodeWidth,
            nodeHeight,
        };
    } else {
        if (node.data.asset.outputs.length !== 0) {
            longestOutputPinWidth =
                2 * (outputCircleRadius + triggerCircleRadius) +
                canvasBorderPadding +
                outputLineLength;
        }
        const assetNodeWidth = node.getScaledWidth() + longestOutputPinWidth;
        return {
            assetNodeWidth,
            assetNodeHeight,
            longestInputPinWidth: 0,
            longestOutputPinWidth: 0,
            nodeWidth,
            nodeHeight,
        };
    }
};
