import {
    FUNCTION_NODE_COLOR,
    OBJECT_NODE_COLOR,
    ALARM_NODE_COLOR,
    FUNCTION_BORDER_COLOR,
    EVENT_NODE_COLOR,
} from './../UiConfig';
import {
    fontSize,
    canvasPrimaryBorderColor,
    canvasPrimaryTextColor,
    canvasSecondaryBorderColor,
    functionTitleTopPadding,
    PINS_DISTANCE,
    BLOCK_BOTTOM_PADDING,
    FUNC_BLOCK_TITLE_HEIGHT,
    GRID_PIXEL,
} from '../UiConfig';
import { fabric } from 'fabric';
import { AssetsType, ASSET_ICON_PATH, Asset } from '../../types';
import { SelfPositioningChild } from '../../NodeController/types';
import { FabricSettingsIcon } from './smartChilds/FabricSettingsIcon';
import { FabricPublishIcon } from './smartChilds/FabricPublishIcon';
import { FabricTriggerIcon } from './smartChilds/FabricTriggerIc';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';
import { FabricRefreshIcon } from './smartChilds/FabricRefreshIcon';
import { FabricDropDownTitle } from '../titleRectangle';
import ObjectTypeDetail from '../../../../transformers/AssetType/ObjectType';
import AlarmTypeDetail from '../../../../transformers/AssetType/AlarmType';

interface Params {
    asset: FunctionTypeDetail;
    parent: FabricDropDownTitle;
}
enum SupportedAssetTypes {
    object = 'object',
    model = 'model',
    function = 'function',
    alarm = 'alarm',
    event = 'event',
}
export const getDropdownThemeColors = (assetType: SupportedAssetTypes) => {
    switch (assetType) {
        case SupportedAssetTypes.function: {
            return {
                color: '#00000',
                bgColor: FUNCTION_NODE_COLOR,
                iconPath: ASSET_ICON_PATH.FUNCTION,
                borderColor: FUNCTION_BORDER_COLOR,
            };
        }
        case SupportedAssetTypes.object: {
            return {
                color: '#000000',
                bgColor: OBJECT_NODE_COLOR,
                iconPath: ASSET_ICON_PATH.OBJECT,
                borderColor: canvasPrimaryBorderColor,
            };
        }

        case SupportedAssetTypes.alarm: {
            return {
                color: '#FFFFFF',
                bgColor: ALARM_NODE_COLOR,
                iconPath: ASSET_ICON_PATH.ALARM,
                borderColor: 'transparent',
            };
        }

        case SupportedAssetTypes.event: {
            return {
                color: '#FFFFFF',
                bgColor: EVENT_NODE_COLOR,
                iconPath: ASSET_ICON_PATH.EVENT,
                borderColor: 'transparent',
            };
        }

        default: {
            return {
                color: '#FFFFFF',
                bgColor: ALARM_NODE_COLOR,
                iconPath: ASSET_ICON_PATH.ALARM,
                borderColor: 'transparent',
            };
        }
    }
};

export const getDropdownAssetType = (asset: Asset) => {
    if (asset instanceof FunctionTypeDetail) {
        return SupportedAssetTypes.function;
    } else if (asset instanceof ObjectTypeDetail) {
        return SupportedAssetTypes.object;
    } else if (asset instanceof AlarmTypeDetail) {
        return SupportedAssetTypes.alarm;
    } else {
        return SupportedAssetTypes.event;
    }
};

export class FabricFunctionRectangle extends fabric.Group {
    data: {
        asset: FunctionTypeDetail;
        childs: SelfPositioningChild[];
        handleEditMode?: () => void;
        handleViewMode?: () => void;
        parent: FabricDropDownTitle;
        reposition: () => void;
    };
    constructor(options: Params) {
        const {
            assetName,
            assetVersion,
            inputs,
            inhibits,
            outputs,
            scale: scaleX,
            scale: scaleY,
            position,
            conditions,
            nodeId,
        } = options.asset;
        const { asset } = options;

        /**
         * To accomodate the pins by adjusting Node height.
         * on minimum scale 3 @input pins and 6 @output pins can be added.
         *  */

        let length = 1;
        const conditionsLength = conditions ? 1 : 0;

        if (inputs) {
            const inputLength = inputs.length + inhibits.length;
            length = inputLength;
        }

        if (outputs) {
            const outputLength = outputs.length;
            if (outputLength + conditionsLength > length) {
                length = outputLength + conditionsLength;
            }
        }

        const height =
            FUNC_BLOCK_TITLE_HEIGHT + (length - 1) * PINS_DISTANCE + BLOCK_BOTTOM_PADDING;
        let width = fontSize * 12;
        const topBorderY = 1.5 * GRID_PIXEL;

        const text = new fabric.Text(`${nodeId.length > 20 ? nodeId.substring(0,20)+"..." : nodeId} ${assetVersion}`, {
            fontSize: fontSize,
            borderColor: canvasPrimaryTextColor,
            top: functionTitleTopPadding + fontSize / 2,
            originX: 'center',
            originY: 'center',
        });

        width = GRID_PIXEL * 16 + GRID_PIXEL * 4;
        const rectLeft = -width / 2;
        const rect = new fabric.Rect({
            width: width,
            height: height,
            fill: 'white',

            stroke: canvasPrimaryBorderColor,
            originY: 'top',
            originX: 'left',
            left: rectLeft,
            top: 0,
            name: 'rect',
        });

        const topBorder = new fabric.Line(
            [rectLeft + 5, topBorderY, rectLeft + width - 5, topBorderY],
            {
                stroke: canvasSecondaryBorderColor,
            }
        );
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        super([rect, text, topBorder], {
            left: x,
            top: y + (rect.getScaledHeight() * scaleY) / 2 + options.parent.getScaledHeight() / 2,
            subTargetCheck: true,
            originX: 'center',
            originY: 'center',
            name: 'group-object',
            scaleX: options.parent.scaleX || 1,
            scaleY: options.parent.scaleY || 1,
            borderColor: 'transparent',
            hoverCursor: 'pointer',
            selectable: false,
            evented: false,
        });

        const settingObject = new FabricSettingsIcon(this, topBorderY);
        const publishObject = new FabricPublishIcon(this);
        const triggerObject = new FabricTriggerIcon(this);

        this.data = {
            parent: options.parent,
            asset,
            childs: [settingObject, publishObject, triggerObject],
            handleEditMode: () => {
                console.log('handling edit mode from rect');
                topBorder.set('visible', true);
            },
            handleViewMode: () => {
                topBorder.set('visible', false);
            },
            reposition: () => {
                this.left = options.parent.left;
                this.top =
                    options.parent.top! + options.parent.getScaledHeight() + this.getScaledHeight();
                this.scaleX = options.parent.scaleX || 1;
                this.scaleY = options.parent.scaleX || 1;
                this.setCoords();
            },
        };
        if (asset.isUpdateAvailable) {
            const refreshIconObject = new FabricRefreshIcon({
                group: this,
                asset,
            });
            this.data.childs = [...this.data.childs, refreshIconObject];
        }
    }
}
