import React, { useState, useRef, useEffect } from "react";
import { Input, Icon, Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { IEndPointList } from "../../store/function/types";
import { validURL } from "../../utils/helpers";
import CustomABBInput from "../CustomABBInput";

interface AppProps {
    endpointValue: string;
    isViewReadOnly: boolean;
    endpointUrlLists: string[];
    handleEndpointChange: (val: string) => void;
}

export const whiteListUrl: string[] = [
    "http://rbemodule:5003/api/Evaluation/evaluate",
    "http://rbemodule:5003/api/Evaluation/evaluateCalculations",
];

function EndPointUrlList(props: AppProps) {
    const [endpoint, updateEndPoint] = useState("");
    const [errorEndPoint, updateErrorEndPoint] = useState(false);
    const [errorEndPointText, updateErrorEndPointText] = useState(
        "Invalid endpoint url"
    );

    const isViewReadOnly = props.isViewReadOnly || false;

    const [endPointOptionArray, setEndPointOption] = useState(
        [] as IEndPointList[]
    );
    const [selectedValue, updateSelectedValue] = useState(
        [] as IEndPointList[]
    );
    const [isNewEndPoint, setIsNewEndPoint] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isPrefixEnabled, setPrefixEnabled] = useState(true);
    const menuRef: any = useRef();

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleOutsideClicks, true);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(
                "mousedown",
                handleOutsideClicks,
                true
            );
        };
    }, [isMenuOpen]);

    const handleOutsideClicks = (event: any) => {
        // const containerMenuId: string = !event.target.id == "endpointoption"
        if (!menuRef.current) {
            setMenuOpen(false);
        } else {
            if (
                isMenuOpen &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !event.target.classList.contains("endPointWrapper") &&
                event.target.id !== "endpointoption" &&
                event.target.id !== "endpointdeletecontent" &&
                !event.target.classList.contains("deleteendpoint") &&
                !event.target.classList.contains(
                    "ABB_CommonUX_Icon__icon_abb_16"
                )
            ) {
                setMenuOpen(false);
            }
        }
    };

    useEffect(() => {
        if (props.endpointValue) {
            updateEndPoint(props.endpointValue);
            const isHttpExists: boolean =
                props.endpointValue.startsWith("http");
            if (isHttpExists) {
                setPrefixEnabled(false);
            }
        }
    }, []);

    useEffect(() => {
        if (props.endpointUrlLists && props.endpointUrlLists.length > 0) {
            let functionEndPointList: IEndPointList[] = [];
            props.endpointUrlLists.forEach((item: string) => {
                functionEndPointList.push({
                    value: item,
                    label: item,
                });
            });

            setEndPointOption(functionEndPointList);
        }
    }, [props.endpointUrlLists]);

    const handleEndPointChange = (val: string) => {
        updateEndPoint(val);
        updateErrorEndPoint(false);
        updateErrorEndPointText("");
        props.handleEndpointChange(val);
        if (val) {
            const isHttpExists: boolean = val.startsWith("http");
            if (isHttpExists) {
                setPrefixEnabled(false);
            }
        }
        if (!val) {
            setMenuOpen(true);
            setDisabled(true);
            setPrefixEnabled(true);
        }
        // if (value) {
        //     const isValidEndpointURL = validURL(value);
        //     if (!isValidEndpointURL) {
        //         updateErrorEndPointText('Invalid Endpoint URL');
        //         updateErrorEndPoint(true);
        //     }
        //     const isHttpExists: boolean = value.startsWith("http");
        //     if (isHttpExists) {
        //         setPrefixEnabled(false);
        //     }
        // }
        // if (!value) {
        //     setMenuOpen(true);
        //     setDisabled(true);
        //     setPrefixEnabled(true);
        // }
        // updateErrorEndPointText('');
        // updateErrorEndPoint(false);
    };

    return (
        <div>
            <WithTooltip>
                <div className="endpoint_content">
                    <CustomABBInput
                        type="normal"
                        dataType="text"
                        label="Endpoint"
                        showClearIcon={true}
                        disabled={isViewReadOnly}
                        placeholder=" Enter or search endpoint"
                        value={endpoint}
                        onValueChange={handleEndPointChange}
                        //     {
                        //     updateEndPoint(val);
                        //     updateErrorEndPoint(false);
                        //     updateErrorEndPointText("");
                        //     if (val) {
                        //         const isHttpExists: boolean = val.startsWith("http");
                        //         if (isHttpExists) {
                        //             setPrefixEnabled(false);
                        //         }
                        //     }
                        //     if (!val) {
                        //         setMenuOpen(true);
                        //         setDisabled(true);
                        //         setPrefixEnabled(true);
                        //     }
                        // }}
                        onGotFocus={(e: any) => {
                            setMenuOpen(true);
                        }}
                        className="functionClass required endpointlowerCase nospacehttp"
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                        instantValidation={false}
                        prefix={isPrefixEnabled ? "http://" : ""}
                        validator={() =>
                            errorEndPoint
                                ? {
                                      valid: false,
                                      text: errorEndPointText,
                                  }
                                : { valid: true, text: "OK!" }
                        }
                    />
                    <div className="menuContainer" id="endpointoption">
                        <div className="menuContainerWrapper">
                            {isMenuOpen &&
                                endPointOptionArray &&
                                endPointOptionArray
                                    .filter((endpointList: IEndPointList) =>
                                        endpointList.value
                                            .toLowerCase()
                                            .includes(endpoint)
                                    )
                                    .map(
                                        (option: IEndPointList, index: any) => (
                                            <div
                                                className="endPointContent endPointWrapper"
                                                key={"endpoint" + index}
                                                ref={menuRef}
                                            >
                                                <div
                                                    className="endPointWrapper endpointTextContainer"
                                                    onClick={(evt: any) => {
                                                        updateEndPoint(
                                                            option.value
                                                        );
                                                        props.handleEndpointChange(
                                                            option.value
                                                        );
                                                        const optionValue: boolean =
                                                            option.value.startsWith(
                                                                "http"
                                                            );
                                                        if (optionValue) {
                                                            setPrefixEnabled(
                                                                false
                                                            );
                                                        }
                                                        setMenuOpen(false);
                                                        updateErrorEndPoint(
                                                            false
                                                        );
                                                        updateErrorEndPointText(
                                                            ""
                                                        );
                                                        setDisabled(false);
                                                    }}
                                                >
                                                    <span className="endPointWrapper endPointText">
                                                        {option.value}
                                                    </span>
                                                </div>
                                                {whiteListUrl &&
                                                    !whiteListUrl.includes(
                                                        option.value
                                                    ) && (
                                                        <div
                                                            className="deleteendpoint"
                                                            id="endpointdeletecontent"
                                                            onClick={(
                                                                e: any
                                                            ) => {
                                                                e.stopPropagation();
                                                                // deleteEndPoint(option.value);
                                                            }}
                                                        >
                                                            <Icon
                                                                name="abb/trash"
                                                                sizeClass="small"
                                                                className="list-icon deleteendpoint tfin"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        )
                                    )}
                        </div>
                    </div>
                </div>
                <Tooltip disabled={disabled}>
                    <p>{endpoint ? endpoint : ""}</p>
                </Tooltip>
            </WithTooltip>
        </div>
    );
}

export default EndPointUrlList;
