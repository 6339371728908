import React, { useState, useCallback, useEffect } from "react";
import { FormGroup, Label, ModalFooter } from "reactstrap";

import { connect } from "react-redux";
import { StoreState } from "../../../../store";
import { Dispatch } from "redux";
import { hideModal } from "../../../../store/modal/action";
import {
    handleAssetToggle,
    handleCriticalityMonitoring,
    updateAssetPageStatus,
    updateModelStatus,
} from "../../../../store/assets/actions";
import {
    handleAssetModelConfig,
    updateSelectedCriticalityModel,
    saveComputeModelInstanceConfig,
    deleteComputeModelInstanceConfig,
    updateInstanceMonitoringList,
} from "../../../../store/instanceConfig/actions";
import "./style.scss";
import { updateAssetConfigModel } from "../types";

import { Input, ToggleSwitch, Button } from "@abb/abb-common-ux-react";

import { ComputeModelToJson } from "../../../../transformers/ComputeModel/toJson/ComputeModelToJson";
import {
    isTypePreviouslySelected,
    getConfiguredAndUnConfiguredType,
    getUpdatedSupportedValues,
    getUpdatedSupportedValuesName,
} from "../../../../utils/helpers";
import AssetConfigModalItem from "./AssetConfigModelItem";
import Condition from "../../../../components/shared/Condition";
import Flex from "../../../../components/shared/Flex";
import Loader from "../../../../components/Loader";
import {
    LOADER_TYPE,
    LOADER_SIZE,
    VIEW_MODE,
} from "../../../../utils/constants/appConstants";
import {
    SupportedTypes,
    InstanceMonitoringItem,
} from "../../../../store/instanceConfig/types";
import _ from "lodash";
import {
    handleCanvasZoomUpdate,
    updateConfigurationToolActiveView,
} from "../../../../store/configurationTool/actions";
import CustomABBInput from "../../../../components/CustomABBInput";
import { ASSET_MODAL_BUTTON } from '../../../../utils/constants/uiConstants';

interface AssetConfigModalProps {}

export const AssetConfigModalForm = (
    props: AssetConfigModalProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [name, setAssetName] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState(
        (props.modelDetails && props.modelDetails.typeId) || ""
    );
    const [tags, setTags] = useState("");
    const [showAdvancedOptions, toggleAdvancedOptions] = useState(false);
    const [errorName, updateErrorName] = useState(false);
    const [errorDescription, updateErrorDescription] = useState(false);
    const [errorTags, updateErrorTags] = useState(false);
    const [stopMonitoringDisableStatus, updateStopMonitoringDisableStatus] =
        useState(true);
    const [viewAndDeployDisableStatus, updateViewAndDeployDisableStatus] =
        useState(true);
    const [submitBtnDisableStatus, updateSubmitBtnDisableStatus] =
        useState(true);
        const [submitText, setSubmitText] = useState(props.submitBtnText);
    const [configSupportedType, updateConfigSupportedType] = useState(
        [] as SupportedTypes[]
    );
    const [unConfigSupportedType, updateUnConfigSupportedType] = useState(
        [] as SupportedTypes[]
    );
    const [filteredUnConfigSupportedType, setFilterForUnconfigSupportedTypes] =
        useState([] as SupportedTypes[]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        // to disable enable the config buttons
        let configSelectedTypesCount = 0;
        if (props.isInstanceConfigured) {
            let isDisable = false;
            let btnTextToBeChanged = false;
            const assetInstance =
                props.instanceTableDataById[props.selectedInstances[0]];
            const assetInstanceAmInfo = assetInstance.amInfo;
            Object.keys(props.selectedTypes).forEach((type) => {
                const isPreviouslySelected = isTypePreviouslySelected(
                    assetInstanceAmInfo,
                    type
                );
                if (!isPreviouslySelected) {
                    isDisable = true;
                } else {
                    configSelectedTypesCount++;
                }
                if(filteredUnConfigSupportedType.some(e => e.key === type)) {
                    btnTextToBeChanged = true;
                }
            });

            if (configSelectedTypesCount > 1) {
                updateSubmitBtnDisableStatus(true);
            }

            if (isDisable) {
                updateStopMonitoringDisableStatus(true);
            } else {
                updateStopMonitoringDisableStatus(false);
            }
            if(btnTextToBeChanged && configSelectedTypesCount === 0) {
                setSubmitText(ASSET_MODAL_BUTTON.START_MONITORING)
            }else {
                setSubmitText(props.submitBtnText)
            }
        }
        if (Object.keys(props.selectedTypes).length === 0) {
            updateSubmitBtnDisableStatus(true);
            updateStopMonitoringDisableStatus(true);
            updateViewAndDeployDisableStatus(true);
        } else {
            if (configSelectedTypesCount <= 1) {
                updateSubmitBtnDisableStatus(false);
            }

            if (
                configSelectedTypesCount == 0 &&
                Object.keys(props.selectedTypes).length === 1
            ) {
                updateViewAndDeployDisableStatus(false);
            } else {
                updateViewAndDeployDisableStatus(true);
            }
        }
    }, [props.selectedTypes]);

    useEffect(() => {
        let configuredSupportedType: SupportedTypes[] = [];
        let unConfiguredSupportedType: SupportedTypes[] = [];
        let assetInstanceAmInfo: any[] = [];
        const updatedSupportedTypes = _.cloneDeepWith(props.supportedTypes);

        if (props.isInstanceConfigured) {
            const assetInstance =
                props.instanceTableDataById[props.selectedInstances[0]];
            assetInstanceAmInfo = assetInstance.amInfo;
        }

        updatedSupportedTypes.forEach((supportedType) => {
            const matchedModelType = assetInstanceAmInfo.find(
                (item: any) => item.assetMonitorModel === supportedType.key
            );
            if (matchedModelType) {
                configuredSupportedType.push(supportedType);
            } else {
                unConfiguredSupportedType.push(supportedType);
            }
        });

        updateConfigSupportedType([...configuredSupportedType]);
        updateUnConfigSupportedType([...unConfiguredSupportedType]);
    }, [props.supportedTypes]);

    const fetchValueAndUpdate = useCallback((func, name, value) => {
        if (name === "assetMonitorInstanceName") {
            if (value !== "") {
                updateErrorName(false);
            }
        }

        if (name === "assetMonitorInstanceDescription" && value !== "") {
            updateErrorDescription(false);
        }
        if (name === "assetMonitorInstanceTags" && value !== "") {
            updateErrorTags(false);
        }
        func(value);
    }, []);

    useEffect(() => {
        const assetInstance =
            props.instanceTableDataById[props.selectedInstances[0]];

        if (props.isInstanceConfigured) {
            setAssetName(assetInstance.name);
            setDescription(props.configFormFields.description);
            const tags = props.configFormFields.tags.join();
            setTags(tags);
        } else {
            const selectedAssetName = assetInstance.name;
            setAssetName(selectedAssetName);
        }
    }, [props.modelDetails, props.configFormFields]);
    const handleSubmit = () => {
        // event.preventDefault();
        let returnVal: boolean = true;

        if (!name) {
            returnVal = false;
            updateErrorName(true);
        }

        if ((name && returnVal) || props.isInstanceConfigured) {
            props.hideAssetConfigModal();
            if (!props.isInstanceConfigured) {
                const updateAssetConfigModelData: updateAssetConfigModel = {
                    description: description,
                    name: name,
                    tags: tags ? tags.split(",") : [],
                    typeId: type,
                };
                props.updateModelStatus(true);
                props.handleAssetModelConfig(updateAssetConfigModelData);
                props.saveInstanceComputeModel({
                    objectIds: props.selectedInstances,
                });
            } else if (props.isInstanceConfigured) {
                const assetInstance =
                    props.instanceTableDataById[props.selectedInstances[0]];
                const amInfoData = assetInstance.amInfo;
                const configStatusData = getConfiguredAndUnConfiguredType(
                    amInfoData,
                    props.selectedTypes
                );
                const updateAssetConfigModelData: updateAssetConfigModel = {
                    description: description,
                    name: name,
                    tags: tags ? tags.split(",") : [],
                    typeId: type,
                };

                props.handleAssetModelConfig(updateAssetConfigModelData);
                props.updateModelStatus(false);
                if (configStatusData.configuredTypes.length > 0) {
                    if (!props.configurationMode) {
                        props.handleAssetPageStatus(true);
                    }
                    props.handleAssetToggle();
                } else {
                    props.saveInstanceComputeModel({
                        objectIds: props.selectedInstances,
                    });
                }
            }
        }
        return returnVal;
    };

    const handleViewAndDeploy = () => {
        props.updateModelStatus(false);
        let returnVal: boolean = true;
        if (!name) {
            returnVal = false;
            updateErrorName(true);
        }

        if (name && returnVal) {
            // TODO - Tenant Modification
            const updateAssetConfigModelData: updateAssetConfigModel = {
                description: description,
                name: name,
                tags: tags ? tags.split(",") : [],
                typeId: type,
            };

            props.setToDiagramView();
            props.handleAssetModelConfig(updateAssetConfigModelData);
            props.hideAssetConfigModal();
            props.handleAssetPageStatus(true);
            props.handleAssetToggle();
        }
    };

    const handleDeleteInstanceConfig = () => {
        props.hideAssetConfigModal();

        props.updateInstanceMonitoringList([
            {
                key: props.selectedInstances[0],
                value: Object.keys(props.selectedTypes),
            },
        ]);
        props.deleteComputeModelInstanceConfig();
    };

    useEffect(() => {
        if (unConfigSupportedType.length > 0) {
            const updatedUnConfigSupportedType = getUpdatedSupportedValuesName(
                _.cloneDeepWith(unConfigSupportedType),
                filter
            );
            setFilterForUnconfigSupportedTypes(updatedUnConfigSupportedType);
        }
    }, [filter, unConfigSupportedType]);

    return (
        <div className="wrapper-asset-config-modal">
            <Flex>
                <div className="model-list-title">Models</div>
                <CustomABBInput
                    className="filter-types"
                    dataType="text"
                    type="normal"
                    placeholder="search Modelname"
                    value={filter}
                    onValueChange={(val: any) => setFilter(val)}
                    // label="Search Models"
                />
            </Flex>
            <div className="wrapper-config-modal-body">
                <Condition
                    when={props.loadingTypes || props.segregatedModelsLoading}
                >
                    <Flex fill center>
                        <Loader
                            type={LOADER_TYPE.RADIAL}
                            sizeClass={LOADER_SIZE.MEDIUM}
                        />
                    </Flex>
                </Condition>
                <Condition
                    when={!props.loadingTypes && !props.segregatedModelsLoading}
                >
                    {props.supportedTypes.length === 0 ? (
                        <div>No types available!</div>
                    ) : (
                        <>
                            <div className="wrapper-model-list-pane">
                                <div className="wrapper-type-list">
                                    {props.isInstanceConfigured && (
                                        <div className="model-list-title">
                                            Not Configured
                                        </div>
                                    )}

                                    <div className="model-list-parent">
                                        {filteredUnConfigSupportedType.length ===
                                        0 ? (
                                            <div>No types available!</div>
                                        ) : (
                                            filteredUnConfigSupportedType.length >
                                                0 &&
                                            filteredUnConfigSupportedType.map(
                                                (supportedType, index) => {
                                                    return (
                                                        <AssetConfigModalItem
                                                            key={
                                                                supportedType.key
                                                            }
                                                            supportedType={{
                                                                ...supportedType,
                                                            }}
                                                        />
                                                    );
                                                }
                                            )
                                        )}
                                    </div>
                                </div>
                                {props.isInstanceConfigured &&
                                    !props.segregatedModelsLoading &&
                                    !props.loadingTypes && (
                                        <div className="wrapper-type-list">
                                            <div className="model-list-title">
                                                Configured
                                            </div>
                                            <div className="model-list-parent">
                                                {configSupportedType.length ===
                                                0 ? (
                                                    <div>
                                                        No types available!
                                                    </div>
                                                ) : (
                                                    configSupportedType.length &&
                                                    configSupportedType.map(
                                                        (
                                                            supportedType,
                                                            index
                                                        ) => {
                                                            // console.log(
                                                            //     "supportedType",
                                                            //     supportedType
                                                            // );
                                                            return (
                                                                <AssetConfigModalItem
                                                                    key={
                                                                        supportedType.key
                                                                    }
                                                                    supportedType={{
                                                                        ...supportedType,
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>

                            {!props.isInstanceConfigured && (
                                <div className="wrapper-asset-config-form">
                                    <ToggleSwitch
                                        onChange={() => {
                                            toggleAdvancedOptions(
                                                !showAdvancedOptions
                                            );
                                        }}
                                        value={showAdvancedOptions}
                                        label={"Advanced"}
                                    />
                                    {showAdvancedOptions && (
                                        <div className="asset-config-form">
                                            <Condition
                                                when={
                                                    props.loadingTypes ||
                                                    props.segregatedModelsLoading
                                                }
                                            >
                                                <Flex fill center>
                                                    <Loader
                                                        type={
                                                            LOADER_TYPE.RADIAL
                                                        }
                                                        sizeClass={
                                                            LOADER_SIZE.MEDIUM
                                                        }
                                                    />
                                                </Flex>
                                            </Condition>
                                            <Condition
                                                when={
                                                    !props.loadingTypes &&
                                                    !props.segregatedModelsLoading
                                                }
                                            >
                                                {/* <FormGroup className="custom-form-group asset-form-item">
                                                    <Label
                                                        for="modelName"
                                                        className={"flex-1"}
                                                    >
                                                        <sup>*</sup> Name :
                                                    </Label>
                                                    <CustomABBInput
                                                        className={"flex-2"}
                                                        value={name}
                                                        onValueChange={(
                                                            value
                                                        ) => {
                                                            fetchValueAndUpdate(
                                                                setAssetName,
                                                                "assetMonitorInstanceName",
                                                                value
                                                            );
                                                        }}
                                                        type="normal"
                                                        dataType="text"
                                                        id="assetMonitorInstanceName"
                                                        placeholder="Enter the name of the model instance"
                                                        showValidationBarWhenInvalid={
                                                            true
                                                        }
                                                        showValidationIconWhenInvalid={
                                                            true
                                                        }
                                                        instantValidation={true}
                                                        validator={() =>
                                                            errorName
                                                                ? {
                                                                      valid: false,
                                                                      text: "Please Enter model instance name",
                                                                  }
                                                                : {
                                                                      valid: true,
                                                                      text: "OK!",
                                                                  }
                                                        }
                                                    />
                                                </FormGroup> */}
                                                <FormGroup className="custom-form-group asset-form-item">
                                                    <Label
                                                        for="modelTags"
                                                        className="flex-1"
                                                    >
                                                        Tags :
                                                    </Label>
                                                    <CustomABBInput
                                                        className={"flex-2"}
                                                        value={tags}
                                                        onValueChange={(
                                                            value
                                                        ) =>
                                                            fetchValueAndUpdate(
                                                                setTags,
                                                                "assetMonitorInstanceTags",
                                                                value
                                                            )
                                                        }
                                                        type="normal"
                                                        dataType="text"
                                                        id="assetMonitorInstanceTags"
                                                        placeholder="Enter the tags of the model instance "
                                                        showValidationBarWhenInvalid={
                                                            true
                                                        }
                                                        showValidationIconWhenInvalid={
                                                            true
                                                        }
                                                        instantValidation={
                                                            false
                                                        }
                                                        validator={() =>
                                                            errorTags
                                                                ? {
                                                                      valid: false,
                                                                      text: "Please Enter model instance tags",
                                                                  }
                                                                : {
                                                                      valid: true,
                                                                      text: "OK!",
                                                                  }
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup className="custom-form-group asset-form-item">
                                                    <Label
                                                        for="modelDescription"
                                                        className={"flex-1"}
                                                    >
                                                        Description :
                                                    </Label>
                                                    <CustomABBInput
                                                        className={"flex-2"}
                                                        value={description}
                                                        onValueChange={(
                                                            value
                                                        ) => {
                                                            fetchValueAndUpdate(
                                                                setDescription,
                                                                "assetMonitorInstanceDescription",
                                                                value
                                                            );
                                                        }}
                                                        type="normal"
                                                        dataType="text"
                                                        id="assetMonitorInstanceDescription"
                                                        placeholder="Please Enter model instance description"
                                                        showValidationBarWhenInvalid={
                                                            true
                                                        }
                                                        showValidationIconWhenInvalid={
                                                            true
                                                        }
                                                        instantValidation={true}
                                                        validator={() =>
                                                            errorDescription
                                                                ? {
                                                                      valid: false,
                                                                      text: "Enter model instance description",
                                                                  }
                                                                : {
                                                                      valid: true,
                                                                      text: "OK!",
                                                                  }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Condition>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Condition>
            </div>
            <ModalFooter>
                <Button
                    className="create-btn"
                    type="primary-blue"
                    text="View and Deploy"
                    onClick={handleViewAndDeploy}
                    disabled={viewAndDeployDisableStatus}
                />

                {props.submitBtnText && (
                    <Button
                        className="create-btn"
                        type="primary-blue"
                        text={submitText}
                        onClick={handleSubmit}
                        disabled={submitBtnDisableStatus}
                    />
                )}
                {props.stopMonitoringBtnText && (
                    <Button
                        className="create-btn"
                        type="primary-blue"
                        text={props.stopMonitoringBtnText}
                        onClick={handleDeleteInstanceConfig}
                        disabled={stopMonitoringDisableStatus}
                    />
                )}
            </ModalFooter>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        submitBtnText: state.modal.data && state.modal.data.submitBtnText,
        stopMonitoringBtnText:
            state.modal.data && state.modal.data.stopMonitoringBtnText,
        modelDetails:
            state.instanceConfig.activeModel.modelInstance.modelDetails,
        configurationMode: state.assets.configurationMode,
        assetCriticalityModels: state.instanceConfig.assetCriticalityModels,
        instanceTableDataById:
            state.instanceConfig.instancesTable.tableData.byId,
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances,
        conditionMonitorModels: state.instanceConfig.computeModels.all,
        supportedTypes: state.instanceConfig.supportedTypes,
        isLoading: state.instanceConfig.isLoading,
        isInstanceConfigured: state.instanceConfig.isInstanceConfigured,
        selectedTypes: state.instanceConfig.selectedTypes,
        configFormFields: state.instanceConfig.configFormfields,
        loadingTypes: state.instanceConfig.assetConfigLoading,
        segregatedModelsLoading: state.instanceConfig.segregatedModelsLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleAssetToggle: () => {
            dispatch(handleAssetToggle());
        },
        handleAssetPageStatus: (configurationMode: boolean) => {
            dispatch(updateAssetPageStatus(configurationMode));
        },
        hideAssetConfigModal: () => dispatch(hideModal()),
        handleAssetModelConfig: (
            updateAssetConfigModel: updateAssetConfigModel
        ) => {
            dispatch(handleAssetModelConfig({ updateAssetConfigModel }));
        },
        handleSelectedCriticalityModel: (payload: ComputeModelToJson) => {
            dispatch(updateSelectedCriticalityModel(payload));
        },

        handleCriticalityMonitoring: (payload: ComputeModelToJson) => {
            dispatch(handleCriticalityMonitoring(payload));
        },

        updateModelStatus: (payload: boolean) => {
            dispatch(updateModelStatus(payload));
        },

        saveInstanceComputeModel: (payload: { objectIds: string[] }) => {
            dispatch(saveComputeModelInstanceConfig(payload));
        },
        deleteComputeModelInstanceConfig: () => {
            dispatch(deleteComputeModelInstanceConfig());
        },
        updateInstanceMonitoringList: (payload: InstanceMonitoringItem[]) => {
            dispatch(updateInstanceMonitoringList(payload));
        },
        setToDiagramView: () =>
            dispatch(
                updateConfigurationToolActiveView({
                    activeView: VIEW_MODE.DIAGRAM,
                })
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetConfigModalForm);
