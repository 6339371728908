import React, { useEffect, useState } from 'react';
import CanvasController from '../..';
import Flex from '../../../shared/Flex';
import { Icon, Slider, Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import { StoreState } from '../../../../store';
import { connect } from 'react-redux';
import AppSettings from '../../../../services/AppSettings';
import './style.scss';
import { TOOLTIP_MESSAGES } from '../../../../utils/constants/uiConstants';
import ReactTooltip from 'react-tooltip';
import CustomTooltip from '../../../CustomTooltip';
interface ZoomSliderProps {
    canvas: CanvasController;
    classname: string;
}

const ZoomSlider = (props: ZoomSliderProps & ReturnType<typeof mapStateToProps>) => {
    const [zoomValue, setZoomValue] = useState(100);
    const [key, setKey] = useState(1);

    const { sliderMin, sliderMax, sliderStep } = AppSettings.zoomSliderConfig;

    useEffect(() => {
        if (props.currentZoom) {
            let currentPercentage = Math.trunc(props.currentZoom * 100);
            setZoomValue(currentPercentage);
        }
    }, [props.currentZoom]);

    useEffect(() => {
        setKey(new Date().getTime());
    }, []);

    const changeZoom = (val: number) => {
        let zoomVal = val / 100;
        props.canvas.sliderZoom(zoomVal);
    };

    const calculateZoom = (type: string) => {
        let current_value = zoomValue;
        switch (type) {
            case 'increment':
                current_value += sliderStep;
                break;
            case 'decrement':
                current_value -= sliderStep;
                break;
            default:
                break;
        }
        let new_value = Math.round(current_value / 10) * 10;
        if (new_value < sliderMin || new_value > sliderMax) {
            return;
        }
        changeZoom(new_value);
    };

    return (
        <Flex className={props.classname}>
            <Flex className="zoom-controls" center>
                <div className="zoom-icon-wrapper">
                    <CustomTooltip position="top" text={TOOLTIP_MESSAGES.ZOOM_OUT}>
                        <Icon
                            sizeClass="small"
                            className="zoom-minus"
                            name={'abb/minus'}
                            onClick={() => calculateZoom('decrement')}
                        />
                    </CustomTooltip>
                </div>

                <Slider
                    key={key}
                    min={sliderMin}
                    max={sliderMax}
                    step={sliderStep}
                    type="single"
                    minLabel={'-'}
                    maxLabel={'+'}
                    value={zoomValue}
                    onChange={(val: any) => changeZoom(val)}
                    ticks={[{ value: 100 }]}
                    showTooltip="active"
                ></Slider>

                <div className="zoom-icon-wrapper">
                    <CustomTooltip position="top" text={TOOLTIP_MESSAGES.ZOOM_IN}>
                        <Icon
                            sizeClass="small"
                            className="zoom-minus"
                            name={'abb/plus'}
                            onClick={() => calculateZoom('increment')}
                        />
                    </CustomTooltip>
                </div>
            </Flex>
            <p className="zoom-percentage">{zoomValue}%</p>
        </Flex>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        currentZoom: state.configurationTool.canvasZoom,
    };
};

export default connect(mapStateToProps)(ZoomSlider);
