import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import { ActionTypes } from '../../actionTypes';
import { sce } from 'sce-engg-model-19.09';
import { AssetsType } from '../../../components/Fabric/types';
import {
    ComputeModelFromJson,
    CreateComputeModelFromJson,
} from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';

import {
    getAllComputeModelsStore,
    getAssetTableDataStore,
    handleConfiguredInstanceJson,
    handleLoadingConfiguredInstance,
    handleAssetToggleUnconfiguredInstance,
    saveComputeModelInstanceConfigSuccess,
    deleteComputeModelInstanceConfigSuccess,
    saveComputeModelInstanceConfig,
    saveComputeModelInstanceConfigUpdate,
    clearAssetModelDetailConfig,
    deleteComputeModelInstanceConfigError,
    saveComputeModelInstanceConfigError,
    handleAssetConfigureBtnClickAction,
    getMatchingComputeModelsStore,
    changeActivePageInstanceConfig,
    changeEnteriesPerPageInstanceConfig,
    handleIncludeAllChildElements,
    updateSupportedTypes,
    getConfiguredInstanceJson,
    handleLoading,
    getSelectedTypeComputeModelFromJson,
    updateSelectedTypeJson,
    updateSelectedSupportedType,
    updateAssetConfigModalFields,
    updateAssetConfigLoader,
    updateOriginalTypeJson,
    updateInstanceMonitoringList,
    hanldeInstanceTypeOriginalFunctionsLoading,
    getLibraryReferencesForFunctionTypesRequest,
    handleAssetEnableBtnClickAction,
    updateSelectedMenuItem,
    updateComputeModelInstanceConfig,
    viewandUpdateComputeModelInstanceConfig,
    getLoadedInstace,
} from '../actions';
import {
    cancelUnsavedModelChanges,
    saveComputeModelApi,
    setActiveModalForImport,
} from '../../../store/modelsPage/actions';
import { store } from '../../../containers/AppBoot';
import { ComputeModelToJson } from '../../../transformers/ComputeModel/toJson/ComputeModelToJson';
import {
    SAVE_OPTIONS,
    NOTIFICATION_MODAL_STATUS,
    INSTANCE_SAVE_MODE,
    OVERALL_SEVERITY_FUNCTION,
    ROUTE_PATHNAME,
    TYPE_VALIDATION_ERROR_MSG,
    ASSET_CRITICALITY,
} from '../../../utils/constants/appConstants';
import AssetCriticalityModal from '../../../routes/Assets/AssetCriticalityModal';

import { StoreState } from '../..';
import {
    isAssetInstanceNotConfigured,
    abbSDKGetErrorMessage,
    isTypePreviouslySelected,
    getConfiguredAndUnConfiguredType,
    getobjectToSceModuleIds,
    abbSDKGetInstanceConfigErrorMessage,
    getMultipleSelectedInstances,
    filterSegregatedModelByVersion,
} from '../../../utils/helpers';
import { sce as AbbSDK } from 'sce-engg-model-19.09';
import _, { cloneDeep } from 'lodash';
import {
    getNewAssetInstanceListActionRequest,
    handleAssetTableLoading,
    updateAssetPageStatus,
    updateStructure,
    handleAssetInstanceActionMenuClickFailure,
    handleInstanceMonitoring,
    handleToggleApiForUpdate,
    updateAssetPageStatusUpdate,
} from '../../assets/actions';
import { showModal, hideModal } from '../../modal/action';
import { getAssetInstanceTableDataSelector } from '../selector';
import AssetConfigModal from '../../../routes/Assets/AssetConfigModal';
import { showNotificationModal } from '../../notificationModal/action';
import { getSelectedAssetInstanceSelector } from '../../assets/selector';
import { CREATE_NEW_MODEL, ASSET_MODAL_BUTTON } from '../../../utils/constants/uiConstants';
import configurationTool from './configurationTool';
import { SupportedTypes, InstanceSaveType, SegregatedModelItem, ACTION_MENU_VALUE } from '../types';
import {
    createConfigurationToolJson,
    updateOverAllSeverityFunctionData,
    updateOverAllSeverityFunctionStatus,
    updateOverallSeverityFunctionId,
    getOriginalFunctionDataFromJson,
} from '../../configurationTool/actions';
import AppSettings from '../../../services/AppSettings';
import { AssetType, CreateAsset } from '../../../transformers/AssetType';
import { computeModelJsonValidation } from '../../../transformers/ComputeModel/validation/computeModelJson';
import { Console } from 'console';
import { updateButtonLoader } from '../../../store/instanceConfig/actions';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
/**
 * getSegregratedModels
 */

function* getAllComputeModelsApi() {
    const state = store.getState();
    try {
        yield put(updateAssetConfigLoader(true));
        const segregratedModels: any = yield sce.getModelsAndTypesBySCEModelIdWithTypeInfo();
        console.log(segregratedModels, 'segregratedModels');
        const avaiableFunctionData = yield sce.getTypes([AssetsType.FUNCTION]);
        // yield put (updateSupportedTypesButton(segregratedModels.details))
        let severityFunction = {} as AssetType;
        if (avaiableFunctionData.details && avaiableFunctionData.details.length > 0) {
            avaiableFunctionData.details.forEach((item: any) => {
                if (item.id.value === OVERALL_SEVERITY_FUNCTION.TYPE_ID) {
                    const assetData = CreateAsset(item);
                    if (assetData) {
                        severityFunction = { ...assetData };
                    }
                }
            });
        }
        const filteredSegregatedModels = filterSegregatedModelByVersion(
            segregratedModels.details as SegregatedModelItem
        );

        let supportedTypes: SupportedTypes[] = [];

        Object.keys(filteredSegregatedModels).forEach((item) => {
            supportedTypes.push({
                key: item,
                value: {
                    modelTypeLabel: item,
                    types: filteredSegregatedModels[item],
                },
            });
        });
        yield put(updateOverAllSeverityFunctionData(severityFunction));
        yield put(updateSupportedTypes(supportedTypes));
        yield put(updateAssetConfigLoader(false));
    } catch (error) {
        let computeModels: ComputeModelFromJson[] = [];
        yield put(getAllComputeModelsStore({ computeModels }));
        yield put(updateAssetConfigLoader(false));

        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getMatchingComputeModelsApi() {
    const state = store.getState();
    const selectedInstanceElementId =
        state.instanceConfig.instancesTable.tableData.selectedInstances[0];
    const typeId =
        state.instanceConfig.instancesTable.tableData.byId[selectedInstanceElementId].type;
    const res = yield sce.getModels(typeId, AppSettings.IsCOD);
    let computeMatchingModels: ComputeModelFromJson[] = [];
    res.details &&
        Array.isArray(res.details) &&
        res.details.forEach((json: any) => {
            const model = CreateComputeModelFromJson(json);

            if (model) {
                // @ts-ignore
                computeMatchingModels.push(model);
            }
        });

    yield put(getMatchingComputeModelsStore({ computeMatchingModels }));
}

function* saveInstanceComputeModel(action: ReturnType<typeof saveComputeModelInstanceConfig>) {
    const state: StoreState = yield select();
    const { configurationTool } = state;
    let saveType: InstanceSaveType | undefined;
    if (action.payload) {
        const { saveMode } = action.payload;
        saveType = saveMode;
    }
    console.log(state, 'update456');
    try {
        yield put(handleAssetTableLoading(true));
        const selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;

        const selectedTypes = state.instanceConfig.selectedTypes;

        let configuredTypes: string[] = [];
        let unConfiguredTypes: any[] = [];

        selectedInstances.map((instance,index) => {
            const assetInstance =
            state.instanceConfig.instancesTable.tableData.byId[instance];
            const assetInstanceAmInfo = assetInstance.amInfo;
            const configStatusTypes = getConfiguredAndUnConfiguredType(
                assetInstanceAmInfo,
                selectedTypes
            );
            configuredTypes = [...configuredTypes,...configStatusTypes.configuredTypes];
            unConfiguredTypes = [...unConfiguredTypes,...configStatusTypes.unConfiguredTypes.map((type) => {
                return {
                    instanceId: instance,
                    modelId: type
                };
            })];
        })

        

        const objectToSceModuleIds = getobjectToSceModuleIds(
            [...selectedInstances],
            state.instanceConfig.instancesTable.tableData.byId
        );

        if ((saveType && saveType !== INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY) || !saveType) {
            const response: ComputeModelToJson[] = yield Promise.all(
                 unConfiguredTypes.map(async ({modelId, instanceId}, index:number) => {
                    try {
                        const typeData = selectedTypes[modelId];
                        const resTypeJson = await sce.getType(
                            modelId,
                            typeData.selectedType,
                            typeData.version,
                            typeData.objectId,
                            AppSettings.IsCOD
                        );

                        console.log('resTypeJson :', resTypeJson);
                        const computeModelToJson = resTypeJson.details as ComputeModelToJson;

                        const availableFunctions = computeModelToJson.properties.functions;

                        Object.keys(availableFunctions).forEach((item) => {
                            if (availableFunctions[item].inputs.conditions) {
                                delete availableFunctions[item].inputs.conditions;
                            }
                        });

                        // const computeModel = new ComputeModelFromJson({
                        //     ...typeJson,
                        // });
                        // const computeModelToJson = new ComputeModelToJson({
                        //     ...computeModel,
                        //     saveOptions: { value: SAVE_OPTIONS.PUBLISH },
                        // });

                        delete computeModelToJson.objectId;
                        delete computeModelToJson.version;

                        computeModelToJson.properties['objectIds'] = {
                            value: [instanceId],
                        };
                        computeModelToJson.model = 'abb.ia.sce.engineering.instance';

                        computeModelToJson.properties.model.name.value =
                        state.instanceConfig.instancesTable.tableData.byId[instanceId].name;
                        return computeModelToJson;
                    } catch (error) {
                        // empty array for no data.
                        return Promise.resolve([]);
                    }
                })
                
                
            );

            console.log('bulk response :', response, objectToSceModuleIds);
            debugger;
            if (response.length > 0) {
                const bulkJsonResponse = yield sce.monitorMultipleInstances(
                    [...response],
                    objectToSceModuleIds,
                    AppSettings.IsCOD
                );
                // console.log("bulkJsonResponse :", bulkJsonResponse);
            }
        }

        /** for configured Types */

        let validations: {
            isValid: boolean;
            errorDetails: string[];
        } = { isValid: true, errorDetails: [] };

        if (state.assets.configurationMode) {
            const computeModel = {
                ...state.instanceConfig.activeModel.modelInstance,
                json: configurationTool.json,
            };
            yield put(handleAssetTableLoading(true));
            const isNew = state.instanceConfig.activeModel.isNew;

            validations = computeModelJsonValidation({
                json: computeModel.json,
                validations: { connect_all_inhibit: true },
                overAllSelectedFunctionId: state.configurationTool.overallSeverityFunctionId,
            });

            if (!validations.isValid) {
                yield put(saveComputeModelInstanceConfigError());
                yield put(handleAssetTableLoading(false));
                yield put(
                    showNotificationModal({
                        details: validations.errorDetails,
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        title: TYPE_VALIDATION_ERROR_MSG,
                        type: 'confirmation',
                    })
                );
            } else {
                const computeModelToJson = new ComputeModelToJson({
                    ...computeModel,
                    saveOptions: { value: SAVE_OPTIONS.PUBLISH },
                    overallSelectedFunctionId: state.configurationTool.overallSeverityFunctionId,
                });

                computeModelToJson.type = `abb.ia.sce.engineering.instance@1`;

                if (isNew) {
                    delete computeModelToJson.objectId;
                    delete computeModelToJson.version;
                }

                computeModelToJson.model = 'abb.ia.sce.engineering.instance';

                console.log(
                    'computeModeToJson final for instance---',
                    computeModelToJson,
                    state.assets.configurationMode
                );

                yield put(handleLoading(true));

                try {
                    // remove empty alarm 
                    const modelData = _.get(state, "instanceConfig.activeModel.modelInstance");
                    const functionData: FunctionTypeDetail[] = _.get(modelData, "json.assetData");
                    const alarms = _.get(computeModelToJson, "properties.alarms");
                    if (alarms && functionData && functionData.length > 0) {
                        let functionAlarmData: FunctionTypeDetail[] = [];
                        functionData.forEach(item => {
                            if (item instanceof FunctionTypeDetail && item.assetName !== "OverallSeverity" && !item.alarmPins.isPublished) {
                                functionAlarmData.push(item);
                            }
                        })
                        if (functionAlarmData.length > 0) {
                            functionAlarmData.forEach(data => {
                                if(computeModelToJson.properties.alarms && data.alarms){
                                    if(!(data.nodeId in data.alarms)){
                                      delete computeModelToJson.properties.alarms[data.nodeId];
                                    }
                                } else if (computeModelToJson.properties.alarms && (data.alarms == undefined)){
                                    delete computeModelToJson.properties.alarms[data.nodeId];
                                }
                            });
                        }
                    }
                }
                catch (e) { }

                if(_.isEmpty(computeModelToJson.properties.alarms)){
                    delete computeModelToJson.properties.alarms;
                };
                let finalComputeModelToJson : ComputeModelToJson[] = [];
                selectedInstances.map((instance: string,index:number) => {
                    let coputeModelToJsontemp = _.cloneDeep(computeModelToJson);
                    coputeModelToJsontemp.properties.model.name.value = state.instanceConfig.instancesTable.tableData.byId[selectedInstances[index]].name;
                    coputeModelToJsontemp.properties['objectIds'] = {
                        value: [instance],
                    };
                    finalComputeModelToJson[index] = coputeModelToJsontemp;
                })
                
                const res = yield sce.monitorMultipleInstances(
                    finalComputeModelToJson,
                    objectToSceModuleIds,
                    AppSettings.IsCOD
                );

                yield put(handleLoading(false));
            }
        }
        /** end here */

        if (validations.isValid) {
            yield put(
                showNotificationModal({
                    title: `Configured successfully`,
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                })
            );

            if (state.assets.configurationMode) {
                yield put(updateAssetPageStatus(false));
            }

            yield put(saveComputeModelInstanceConfigSuccess());

            if (state.assets.selectedTreeNode) {
                // New API request
                yield put(
                    getNewAssetInstanceListActionRequest({
                        node: state.assets.selectedTreeNode,
                    })
                );
            } else {
                const selectedStructure = state.assets.assetStructureDetails.selectedStructure;
                const { pagination } = state.instanceConfig.instancesTable;
                let activePage = pagination.activePage;
                if (selectedStructure) {
                    yield put(updateStructure(selectedStructure));
                    yield put(
                        getNewAssetInstanceListActionRequest({
                            node: null as any,
                            activePage,
                        })
                    );
                }
            }
        }
    } catch (error) {
        yield put(saveComputeModelInstanceConfigError());
        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(error),
            })
        );
    }
}
function* saveInstanceComputeModelUpdate(
    action: ReturnType<typeof saveComputeModelInstanceConfigUpdate>
) {
    const state: StoreState = yield select();
    const { configurationTool } = state;
    let saveType: InstanceSaveType | undefined;
    if (action.payload) {
        const { saveMode } = action.payload;
        saveType = saveMode;
    }
    try {
        yield put(handleAssetTableLoading(true));
        const selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;

        const selectedTypes = state.instanceConfig.selectedTypes;

        let configuredTypes: string[] = [];
        let unConfiguredTypes: string[] = [];

        const assetInstance =
            state.instanceConfig.instancesTable.tableData.byId[selectedInstances[0]];
        const assetInstanceAmInfo = assetInstance.amInfo;
        const configStatusTypes = getConfiguredAndUnConfiguredType(
            assetInstanceAmInfo,
            selectedTypes
        );
        configuredTypes = configStatusTypes.configuredTypes;
        unConfiguredTypes = configStatusTypes.unConfiguredTypes;

        const objectToSceModuleIds = getobjectToSceModuleIds(
            [...selectedInstances],
            state.instanceConfig.instancesTable.tableData.byId
        );

        if ((saveType && saveType !== INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY) || !saveType) {
            const response: ComputeModelToJson[] = yield Promise.all(
                unConfiguredTypes.map(async (modelId: string) => {
                    try {
                        const typeData = selectedTypes[modelId];
                        const resTypeJson = await sce.getType(
                            modelId,
                            typeData.selectedType,
                            typeData.version,
                            typeData.objectId,
                            AppSettings.IsCOD
                        );

                        console.log('resTypeJson :', resTypeJson);
                        const computeModelToJson = resTypeJson.details as ComputeModelToJson;

                        const availableFunctions = computeModelToJson.properties.functions;

                        Object.keys(availableFunctions).forEach((item) => {
                            if (availableFunctions[item].inputs.conditions) {
                                delete availableFunctions[item].inputs.conditions;
                            }
                        });

                        delete computeModelToJson.objectId;
                        delete computeModelToJson.version;

                        computeModelToJson.properties['objectIds'] = {
                            value: selectedInstances,
                        };
                        computeModelToJson.model = 'abb.ia.sce.engineering.instance';

                        computeModelToJson.properties.model.name.value =
                            state.instanceConfig.activeModel.modelInstance.modelDetails.name;

                        try {
                            // remove empty alarm 
                            const modelData = _.get(state, "instanceConfig.activeModel.modelInstance");
                            const functionData: FunctionTypeDetail[] = _.get(modelData, "json.assetData");
                            const alarms = _.get(computeModelToJson, "properties.alarms");
                            if (alarms && functionData && functionData.length > 0) {
                                let functionAlarmData: FunctionTypeDetail[] = [];
                                functionData.forEach(item => {
                                    if (item instanceof FunctionTypeDetail && item.assetName !== "OverallSeverity" && !item.alarmPins.isPublished) {
                                        functionAlarmData.push(item);
                                    }
                                })
                                if (functionAlarmData.length > 0) {
                                    functionAlarmData.forEach(data => {
                                        delete computeModelToJson.properties.alarms![data.nodeId];
                                    });
                                }
                            }
                        }
                        catch (e) { }
                        if(_.isEmpty(computeModelToJson.properties.alarms)){
                            delete computeModelToJson.properties.alarms;
                        };
                        return computeModelToJson;
                    } catch (error) {
                        // empty array for no data.
                        return Promise.resolve([]);
                    }
                })
            );

            console.log('bulk response :', response);

            if (response.length > 0) {
                const bulkJsonResponse = yield sce.monitorMultipleInstances(
                    [...response],
                    objectToSceModuleIds,
                    AppSettings.IsCOD
                );
                // console.log("bulkJsonResponse :", bulkJsonResponse);
            }
        }

        /** for configured Types */

        let validations: {
            isValid: boolean;
            errorDetails: string[];
        } = { isValid: true, errorDetails: [] };

        if (state.assets.configurationMode) {
            const computeModel = {
                ...state.instanceConfig.activeModel.modelInstance,
                json: configurationTool.json,
            };
            yield put(handleAssetTableLoading(true));
            const isNew = state.instanceConfig.activeModel.isNew;

            validations = computeModelJsonValidation({
                json: computeModel.json,
                validations: { connect_all_inhibit: true },
                overAllSelectedFunctionId: state.configurationTool.overallSeverityFunctionId,
            });

            if (!validations.isValid) {
                yield put(saveComputeModelInstanceConfigError());
                yield put(handleAssetTableLoading(false));
                yield put(
                    showNotificationModal({
                        details: validations.errorDetails,
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        title: TYPE_VALIDATION_ERROR_MSG,
                        type: 'confirmation',
                    })
                );
            } else {
                const computeModelToJson = new ComputeModelToJson({
                    ...computeModel,
                    saveOptions: { value: SAVE_OPTIONS.PUBLISH },
                    overallSelectedFunctionId: state.configurationTool.overallSeverityFunctionId,
                });

                computeModelToJson.type = `abb.ia.sce.engineering.instance@1`;

                if (isNew) {
                    delete computeModelToJson.objectId;
                    delete computeModelToJson.version;
                }

                computeModelToJson.properties['objectIds'] = {
                    value: selectedInstances,
                };
                computeModelToJson.model = 'abb.ia.sce.engineering.instance';

                yield put(handleLoading(true));

                try {
                    // remove empty alarm 
                    const modelData = _.get(state, "instanceConfig.activeModel.modelInstance");
                    const functionData: FunctionTypeDetail[] = _.get(modelData, "json.assetData");
                    const alarms = _.get(computeModelToJson, "properties.alarms");
                    if (alarms && functionData && functionData.length > 0) {
                        let functionAlarmData: FunctionTypeDetail[] = [];
                        functionData.forEach(item => {
                            if (item instanceof FunctionTypeDetail && item.assetName !== "OverallSeverity" && !item.alarmPins.isPublished) {
                                functionAlarmData.push(item);
                            }
                        })
                        if (functionAlarmData.length > 0) {
                            functionAlarmData.forEach(data => {
                                delete computeModelToJson.properties.alarms![data.nodeId];
                            });
                        }
                    }
                }
                catch (e) { }

                if(_.isEmpty(computeModelToJson.properties.alarms)){
                    delete computeModelToJson.properties.alarms;
                };

                const res = yield sce.updateExistingInstances(
                    [computeModelToJson],
                    objectToSceModuleIds,
                    AppSettings.IsCOD
                );

                yield put(handleLoading(false));
            }
        }
        /** end here */

        if (validations.isValid) {
            yield put(
                showNotificationModal({
                    title: `Update successfully`,
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                })
            );

            if (state.assets.configurationMode) {
                yield put(updateAssetPageStatus(false));
            }

            yield put(saveComputeModelInstanceConfigSuccess());

            if (state.assets.selectedTreeNode) {
                // New API request
                yield put(
                    getNewAssetInstanceListActionRequest({
                        node: state.assets.selectedTreeNode,
                    })
                );
            } else {
                const selectedStructure = state.assets.assetStructureDetails.selectedStructure;
                const { pagination } = state.instanceConfig.instancesTable;
                let activePage = pagination.activePage;
                if (selectedStructure) {
                    yield put(updateStructure(selectedStructure));
                    yield put(
                        getNewAssetInstanceListActionRequest({
                            node: null as any,
                            activePage,
                        })
                    );
                }
            }
        }
    } catch (error) {
        yield put(saveComputeModelInstanceConfigError());
        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(error),
            })
        );
    }
}
function* getNewAssetInstanceListActionRequestUpdate(action: ReturnType<typeof getLoadedInstace>) {
    const state: StoreState = yield select();
    const { pagination } = state.instanceConfig.instancesTable;
    let activePage = pagination.activePage;

    try {
        if (state.assets.selectedTreeNode) {
            yield put(
                getNewAssetInstanceListActionRequest({
                    node: state.assets.selectedTreeNode,
                })
            );
        }
    } catch (error) {
        yield put(saveComputeModelInstanceConfigError());
        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(error),
            })
        );
    }
}
function* getAssetTableDataApi() {
    const systems = yield sce.getSystems();
    console.log('system :', systems);

    const structures = yield sce.getStructures();
    console.log('structures :', structures);

    const systemStructureObjectsDetails = yield sce.systemStructureObjectsDetails(
        systems.details[0].objectId,
        structures.details[0].typeId
    );

    yield put(getAssetTableDataStore(systemStructureObjectsDetails.details));
    console.log('systemStructureObjectsDetails :', systemStructureObjectsDetails);
}

function* updateModelInstance(action: ReturnType<typeof updateComputeModelInstanceConfig>) {
    const state: StoreState = yield select();
    const { configurationTool } = state;
    // yield put(updateAssetConfigLoader(true));

    let saveType: InstanceSaveType | undefined;
    if (action.payload) {
        const { saveMode } = action.payload;
        saveType = saveMode;
    }

    try {
        // yield put(handleAssetTableLoading(true));
        yield put(updateAssetConfigLoader(true));
        const selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;

        const selectedTypes = state.instanceConfig.selectedTypes;

        let configuredTypes: string[] = [];
        let unConfiguredTypes: string[] = [];
        const assetInstance =
            state.instanceConfig.instancesTable.tableData.byId[selectedInstances[0]];
        const assetInstanceAmInfo = assetInstance.amInfo;
        const configStatusTypes = getConfiguredAndUnConfiguredType(
            assetInstanceAmInfo,
            selectedTypes
        );

        configuredTypes = configStatusTypes.configuredTypes;
        unConfiguredTypes = configStatusTypes.unConfiguredTypes;
        console.log(unConfiguredTypes, assetInstanceAmInfo, 'unConfiguredTypes');
        const objectToSceModuleIds = getobjectToSceModuleIds(
            [...selectedInstances],
            state.instanceConfig.instancesTable.tableData.byId
        );

        /** for configured Types */
        const typeData = state.instanceConfig.clickedActiveParams;
        var resTypeTest: any = []
        typeData.map((items: any) => { resTypeTest.push({ key: items.ObjectId, value: items.model }) })

        var resTypeTestJson = yield sce.getEnggSceModels(
            resTypeTest

        );


        console.log(typeData, resTypeTestJson, 'typeData');
        // yield put(updateButtonLoader(true))
        let validations: {
            isValid: boolean;
            errorDetails: string[];
        } = { isValid: true, errorDetails: [] };

        if (validations.isValid) {


            // yield put(handleAssetTableLoading(true));

            const computeModelToJson = resTypeTestJson.details as ComputeModelToJson[];
            const isNew = state.instanceConfig.activeModel.isNew;
            console.log(computeModelToJson, objectToSceModuleIds, AppSettings.IsCOD, "computeModelToJson")


            computeModelToJson.map((element, num) => {
                element.properties['objectIds'] = {
                    value: [typeData[num].selectedInstance]
                };
                element.properties.model.name.value = state.instanceConfig.instancesTable.tableData.byId[typeData[num].selectedInstance].name
            })


            console.log('function123', computeModelToJson);
            // yield put (updateButtonLoader(true))
            yield put(handleLoading(true));
            const res = yield sce.updateExistingInstances(
                computeModelToJson,
                objectToSceModuleIds,
                AppSettings.IsCOD
            );

            if (res.status === 200) {
                yield put(
                    showNotificationModal({
                        title: `Update successfully`,
                        resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    })
                );
                yield put(hideModal());
                // yield put(updateAssetPageStatusUpdate(false));
                yield put(updateAssetConfigLoader(false));
                yield put(handleLoading(false));
                yield put(saveComputeModelInstanceConfigSuccess());
                console.log(state.assets.selectedTreeNode, 'state.assets.selectedTreeNode');
                if (state.assets.selectedTreeNode) {
                    // New API request
                    yield put(
                        getNewAssetInstanceListActionRequest({
                            node: state.assets.selectedTreeNode,
                        })
                    );
                }
            }
        }
    } catch (error) {
        yield put(saveComputeModelInstanceConfigError());
        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(error),
            })
        );
        yield put(hideModal());
        yield put(updateAssetPageStatusUpdate(false));
        yield put(updateAssetConfigLoader(false));
        yield put(handleLoading(false));
    }
}

function* viewandUpdateModelInstance(
    action: ReturnType<typeof viewandUpdateComputeModelInstanceConfig>
) {
    const state: StoreState = yield select();
    const { configurationTool } = state;
    yield put(updateAssetConfigLoader(true));

    let saveType: InstanceSaveType | undefined;
    if (action.payload) {
        const { saveMode } = action.payload;
        saveType = saveMode;
    }

    try {
        // yield put(handleAssetTableLoading(true));
        let selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;
        let selectedInstancesToBeUpdated = state.instanceConfig.selectedTypesUpdate;
        if (selectedInstancesToBeUpdated.length > 0) {
            //@ts-ignore
            selectedInstances = selectedInstancesToBeUpdated[0].selectedInstance;
        }

        const selectedTypes = state.instanceConfig.selectedTypes;

        let configuredTypes: string[] = [];
        let unConfiguredTypes: string[] = [];
        const assetInstance =
            state.instanceConfig.instancesTable.tableData.byId[selectedInstances[0]];
        const assetInstanceAmInfo = assetInstance.amInfo;
        const configStatusTypes = getConfiguredAndUnConfiguredType(
            assetInstanceAmInfo,
            selectedTypes
        );

        configuredTypes = configStatusTypes.configuredTypes;
        unConfiguredTypes = configStatusTypes.unConfiguredTypes;
        console.log(unConfiguredTypes, assetInstanceAmInfo, 'unConfiguredTypes');
        const objectToSceModuleIds = getobjectToSceModuleIds(
            [...selectedInstances],
            state.instanceConfig.instancesTable.tableData.byId
        );

        /** for configured Types */
        yield put(updateButtonLoader(true));
        yield put(handleAssetTableLoading(true));
        const typeData = selectedTypes[state.instanceConfig.clickedActiveParams[0].model];
        const resTypeJson = yield sce.getType(
            state.instanceConfig.clickedActiveParams[0].model,
            typeData.selectedType,
            typeData.version,
            typeData.objectId,
            AppSettings.IsCOD
        );
        let validations: {
            isValid: boolean;
            errorDetails: string[];
        } = { isValid: true, errorDetails: [] };

        if (validations.isValid) {
            // yield put(handleAssetTableLoading(true));
            const isNew = state.instanceConfig.activeModel.isNew;
            const computeModel = {
                ...state.instanceConfig.activeModel.modelInstance,
                json: configurationTool.json,
            };
            const computeModelToJsonData = new ComputeModelToJson({
                ...computeModel,
                saveOptions: { value: SAVE_OPTIONS.PUBLISH },
                overallSelectedFunctionId: state.configurationTool.overallSeverityFunctionId,
            });

            const computeModelToJson = resTypeJson.details as ComputeModelToJson;
            computeModelToJson.type = `abb.ia.sce.engineering.instance@1`;

            computeModelToJsonData.type = `abb.ia.sce.engineering.instance@1`;

            if (isNew) {
                // delete computeModelToJson.objectId;
                // delete computeModelToJson.version;

                delete computeModelToJsonData.objectId;
                delete computeModelToJsonData.version;
            }

            // computeModelToJson.properties['objectIds'] = {
            //     value: selectedInstances,
            // };

            

            console.log('function123', computeModelToJson, objectToSceModuleIds);
            yield put(handleLoading(true));

            try {
                // remove empty alarm 
                const modelData = _.get(state, "instanceConfig.activeModel.modelInstance");
                const functionData: FunctionTypeDetail[] = _.get(modelData, "json.assetData");
                const alarms = _.get(computeModelToJsonData, "properties.alarms");
                if (alarms && functionData && functionData.length > 0) {
                    let functionAlarmData: FunctionTypeDetail[] = [];
                    functionData.forEach(item => {
                        if (item instanceof FunctionTypeDetail && item.assetName !== "OverallSeverity" && !item.alarmPins.isPublished) {
                            functionAlarmData.push(item);
                        }
                    })
                    if (functionAlarmData.length > 0) {
                        functionAlarmData.forEach(data => {
                            delete computeModelToJsonData.properties.alarms![data.nodeId];
                        });
                    }
                }
            }
            catch (e) { }

            if(_.isEmpty(computeModelToJsonData.properties.alarms)){
                delete computeModelToJsonData.properties.alarms;
            };

            let finalComputeModelToJson : ComputeModelToJson[] = [];
                selectedInstances.map((instance: string,index:number) => {
                    let coputeModelToJsontemp = _.cloneDeep(computeModelToJsonData);
                    coputeModelToJsontemp.properties.model.name.value = state.instanceConfig.instancesTable.tableData.byId[selectedInstances[index]].name;
                    coputeModelToJsontemp.properties['objectIds'] = {
                        value: [instance],
                    };
                    finalComputeModelToJson[index] = coputeModelToJsontemp;
                })
            
            const res = yield sce.updateExistingInstances(
                finalComputeModelToJson,
                objectToSceModuleIds,
                AppSettings.IsCOD
            );
            yield put(updateAssetConfigLoader(false));
            yield put(updateAssetPageStatus(false));
            if (res.status === 200) {
                yield put(
                    showNotificationModal({
                        title: `Update successfully`,
                        resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    })
                );
                yield put(updateAssetPageStatus(false));

                yield put(saveComputeModelInstanceConfigSuccess());
                console.log(state.assets.selectedTreeNode, "state.assets.selectedTreeNode")

                if (state.assets.selectedTreeNode) {
                    // New API request
                    yield put(
                        getNewAssetInstanceListActionRequest({
                            node: state.assets.selectedTreeNode,
                        })
                    );
                }
            }
        }
    } catch (error) {
        console.log('here -->', error);
        yield put(saveComputeModelInstanceConfigError());
        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(error),
            })
        );
    }
}

function* getConfiguredInstanceJsonData(action: ReturnType<typeof getConfiguredInstanceJson>) {
    const { assetMonitorModelId, objectId } = action.payload;

    try {
        const res = yield sce.getInstance(objectId, assetMonitorModelId, AppSettings.IsCOD);
        const computeModelFromJson = new ComputeModelFromJson({
            ...res.details,
        });

        yield put(handleConfiguredInstanceJson({ computeModelFromJson }));
    } catch (error) {
        yield put(handleLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* handleAssetToggleApi() {
    const state = store.getState();
    const configurationMode = state.assets.configurationMode;
    const selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;
    const tableData = state.instanceConfig.instancesTable.tableData.byId;
    const instance = tableData[selectedInstances[0]];
    const canvasController = state.configurationTool.canvasController;
    if (canvasController) {
        canvasController.resetzoom();
    }
    if (configurationMode) {
        const activeTypeParams = state.instanceConfig.activeTypeParams;
        const isPreviouslySelected = isTypePreviouslySelected(
            instance.amInfo,
            activeTypeParams.modelId
        );
        yield put(handleLoading(true));
        yield put(hanldeInstanceTypeOriginalFunctionsLoading(true));
        // empty previous canvas data.
        yield put(
            createConfigurationToolJson({
                json: { assetData: [], connectionData: [] },
            })
        );

        if (
            isPreviouslySelected &&
            instance &&
            instance.amInfo &&
            (instance.amInfo[0].configurationStatus === 'Enabled' ||
                instance.amInfo[0].configurationStatus === 'Disabled')
        ) {
            yield put(handleLoadingConfiguredInstance());

            try {
                const res = yield sce.getInstance(
                    instance.objectId,
                    activeTypeParams.modelId,
                    AppSettings.IsCOD
                );
                const currentTypeVersion = res.details.properties.model.version.value;
                const originalTypeResponse = yield sce.getTypeDefinition(
                    activeTypeParams.modelId,
                    activeTypeParams.typeId,
                    currentTypeVersion
                );
                yield put(
                    updateOriginalTypeJson({
                        json: originalTypeResponse.details as ComputeModelToJson,
                    })
                );

                //@ts-ignore
                console.log('instance response :', res);
                // To update the name according to current selected Instance.
                res.details.properties.model.name.value =
                        state.instanceConfig.instancesTable.tableData.byId[state.instanceConfig.instancesTable.tableData.selectedInstances[0]].name;

                // add condition from originalTypeResponse To getInstance response.
                const originalTypeData = originalTypeResponse.details as ComputeModelToJson;
                const availableFunctions = originalTypeData.properties.functions;
                Object.keys(availableFunctions).forEach((item) => {
                    const availableCondition = availableFunctions[item].inputs.conditions;
                    if (availableCondition) {
                        if (res.details.properties.functions[item]) {
                            res.details.properties.functions[item].inputs.conditions = {
                                ...availableCondition,
                            };
                        }
                    }
                });

                const computeModelJson = new ComputeModelFromJson({
                    ...(res.details as ComputeModelToJson),
                });

                yield put(
                    updateInstanceMonitoringList([
                        {
                            key: selectedInstances[0],
                            value: [computeModelJson.modelDetails.model],
                        },
                    ])
                );

                if (
                    computeModelJson.isOverAllSeverityFunctionPresent &&
                    computeModelJson.overAllSeverityFunctionId
                ) {
                    yield put(updateOverAllSeverityFunctionStatus(true));
                    yield put(
                        updateOverallSeverityFunctionId(computeModelJson.overAllSeverityFunctionId)
                    );
                }
                yield put(
                    handleConfiguredInstanceJson({
                        computeModelFromJson: computeModelJson,
                    })
                );
                // to fetch original conditions.

                yield put(
                    getOriginalFunctionDataFromJson({
                        json: computeModelJson,
                        currentRoutePath: ROUTE_PATHNAME.OBJECTS,
                    })
                );
            } catch (error) {
                yield put(
                    showNotificationModal({
                        title: 'API error',
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: [abbSDKGetErrorMessage(error)],
                    })
                );

                yield put(updateAssetPageStatus(false));
            }
        } else {
            if (Object.keys(state.instanceConfig.selectedTypes).length === 1) {
                try {
                    const res = yield sce.getType(
                        activeTypeParams.modelId,
                        activeTypeParams.typeId,
                        activeTypeParams.version,
                        activeTypeParams.objectId,
                        AppSettings.IsCOD
                    );

                    yield put(
                        updateOriginalTypeJson({
                            json: res.details as ComputeModelToJson,
                        })
                    );

                    // To update the name according to current selected Instance.
                    res.details.properties.model.name.value =
                        state.instanceConfig.activeModel.modelInstance.modelDetails.name;
                    const computeModelJson = new ComputeModelFromJson({
                        ...res.details,
                    });

                    if (
                        computeModelJson.isOverAllSeverityFunctionPresent &&
                        computeModelJson.overAllSeverityFunctionId
                    ) {
                        yield put(updateOverAllSeverityFunctionStatus(true));
                        yield put(
                            updateOverallSeverityFunctionId(
                                computeModelJson.overAllSeverityFunctionId
                            )
                        );
                    }

                    yield put(
                        handleConfiguredInstanceJson({
                            computeModelFromJson: computeModelJson,
                            isNew: true,
                        })
                    );
                    // to fetch original conditions.

                    yield put(
                        getOriginalFunctionDataFromJson({
                            json: computeModelJson,
                            currentRoutePath: ROUTE_PATHNAME.OBJECTS,
                        })
                    );
                } catch (error) {
                    yield put(
                        showNotificationModal({
                            title: 'API error',
                            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                            type: 'confirmation',
                            details: [abbSDKGetErrorMessage(error)],
                        })
                    );
                }
            } else {
                yield put(handleAssetToggleUnconfiguredInstance());
            }
        }

        yield put(handleLoading(false));
    } else {
        yield put(clearAssetModelDetailConfig());
    }
}

function* handleAssetToggleApiForUpdate(action: ReturnType<typeof handleToggleApiForUpdate>) {
    yield put(updateAssetConfigLoader(true));
    const state = store.getState();
    const configurationMode = state.assets.configurationMode;
    const selectedInstances = state.instanceConfig.instancesTable.tableData.selectedInstances;
    const tableData = state.instanceConfig.instancesTable.tableData.byId;
    const instance = tableData[state.instanceConfig.clickedActiveParams[0].selectedInstance];
    const canvasController = state.configurationTool.canvasController;
    const selectedTypes = state.instanceConfig.selectedTypes;
    if (canvasController) {
        canvasController.resetzoom();
    }
    if (configurationMode) {

        let activeTypeParams = state.instanceConfig.clickedActiveParams[0]

        const isPreviouslySelected = isTypePreviouslySelected(
            instance.amInfo,
            activeTypeParams.model
        );
        yield put(handleLoading(true));
        yield put(hanldeInstanceTypeOriginalFunctionsLoading(true));
        // empty previous canvas data.
        yield put(
            createConfigurationToolJson({
                json: { assetData: [], connectionData: [] },
            })
        );
        const checkIsUpdateAvailable = (aminfo: any) => {
            let updateAvailable = false;
            aminfo.forEach((element: any) => {
                if (element.isUpdateAvailable) {
                    updateAvailable = true;
                }
            });
            return updateAvailable;
        };
        debugger;
        if (
            isPreviouslySelected &&
            instance &&
            instance.amInfo &&
            checkIsUpdateAvailable(instance.amInfo)
        ) {
            yield put(handleLoadingConfiguredInstance());

            try {
                const res = yield sce.getInstance(
                    instance.objectId,
                    activeTypeParams.model,
                    AppSettings.IsCOD
                );

                const currentTypeVersion = res.details.properties.model.version.value;
                const originalTypeResponse = yield sce.getTypeDefinition(
                    activeTypeParams.model,
                    activeTypeParams.typeId,
                    currentTypeVersion
                );
                const typeData = selectedTypes[activeTypeParams.model];
                const resTypeJson = yield sce.getType(
                    activeTypeParams.model,
                    activeTypeParams.selectedType,
                    activeTypeParams.version,
                    activeTypeParams.ObjectId,
                    AppSettings.IsCOD
                );
                console.log('instance response', res, originalTypeResponse, resTypeJson);
                yield put(
                    updateOriginalTypeJson({
                        json: resTypeJson.details as ComputeModelToJson,
                    })
                );

                const originalTypeData = resTypeJson.details as ComputeModelToJson;
                const availableFunctions = originalTypeData.properties.functions;
                Object.keys(availableFunctions).forEach((item) => {
                    const availableCondition = availableFunctions[item].inputs.conditions;
                    if (availableCondition) {
                        if (res.details.properties.functions[item]) {
                            res.details.properties.functions[item].inputs.conditions = {
                                ...availableCondition,
                            };
                        }
                    }
                });
                const computeModelJson = new ComputeModelFromJson({
                    ...(resTypeJson.details as ComputeModelToJson),
                });

                yield put(
                    updateInstanceMonitoringList([
                        {
                            key: selectedInstances[0],
                            value: [computeModelJson.modelDetails.model],
                        },
                    ])
                );

                if (
                    computeModelJson.isOverAllSeverityFunctionPresent &&
                    computeModelJson.overAllSeverityFunctionId
                ) {
                    yield put(updateOverAllSeverityFunctionStatus(true));
                    yield put(
                        updateOverallSeverityFunctionId(computeModelJson.overAllSeverityFunctionId)
                    );
                }
                yield put(
                    handleConfiguredInstanceJson({
                        computeModelFromJson: computeModelJson,
                    })
                );
                // to fetch original conditions.

                yield put(
                    getOriginalFunctionDataFromJson({
                        json: computeModelJson,
                        currentRoutePath: ROUTE_PATHNAME.OBJECTS,
                    })
                );
            } catch (error) {
                yield put(
                    showNotificationModal({
                        title: 'API error',
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: [abbSDKGetErrorMessage(error)],
                    })
                );

                yield put(updateAssetPageStatus(false));
            }
        } else {
            if (Object.keys(state.instanceConfig.selectedTypes).length === 1) {
                try {
                    const res = yield sce.getType(
                        activeTypeParams.model,
                        activeTypeParams.typeId,
                        activeTypeParams.version,
                        activeTypeParams.ObjectId,
                        AppSettings.IsCOD
                    );

                    yield put(
                        updateOriginalTypeJson({
                            json: res.details as ComputeModelToJson,
                        })
                    );

                    // To update the name according to current selected Instance.
                    res.details.properties.model.name.value =
                        state.instanceConfig.activeModel.modelInstance.modelDetails.name;
                    const computeModelJson = new ComputeModelFromJson({
                        ...res.details,
                    });

                    if (
                        computeModelJson.isOverAllSeverityFunctionPresent &&
                        computeModelJson.overAllSeverityFunctionId
                    ) {
                        yield put(updateOverAllSeverityFunctionStatus(true));
                        yield put(
                            updateOverallSeverityFunctionId(
                                computeModelJson.overAllSeverityFunctionId
                            )
                        );
                    }

                    yield put(
                        handleConfiguredInstanceJson({
                            computeModelFromJson: computeModelJson,
                            isNew: true,
                        })
                    );
                    // to fetch original conditions.

                    yield put(
                        getOriginalFunctionDataFromJson({
                            json: computeModelJson,
                            currentRoutePath: ROUTE_PATHNAME.OBJECTS,
                        })
                    );
                } catch (error) {
                    yield put(
                        showNotificationModal({
                            title: 'API error',
                            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                            type: 'confirmation',
                            details: [abbSDKGetErrorMessage(error)],
                        })
                    );
                    yield put(updateAssetPageStatus(false));
                }
            } else {
                yield put(handleAssetToggleUnconfiguredInstance());
            }
        }

        yield put(handleLoading(false));
    } else {
        yield put(clearAssetModelDetailConfig());
    }
}

function* deleteInstanceComputeModel() {
    const state = store.getState();
    const configurationMode = state.assets.configurationMode;

    try {
        yield put(handleAssetTableLoading(true));
        const selectedInstances = yield select(getSelectedAssetInstanceSelector);
        const instanceMonitoringList = state.instanceConfig.instanceMonitoringList;
        const multipleSelectedInstances = getMultipleSelectedInstances(
            instanceMonitoringList,
            state.instanceConfig.instancesTable.tableData.byId
        );

        const res = yield sce.deleteMultipleInstances(
            [...multipleSelectedInstances],
            AppSettings.IsCOD
        );

        yield put(handleAssetTableLoading(false));
        yield put(
            showNotificationModal({
                title: 'Stop monitor success',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
            })
        );
        yield put(deleteComputeModelInstanceConfigSuccess());
        if (configurationMode) {
            // yield put(handleAssetToggle());
            yield put(updateAssetPageStatus(false));
        }
        // New API request
        yield put(
            getNewAssetInstanceListActionRequest({
                node: state.assets.selectedTreeNode!,
            })
        );
        // }
        console.log(res);
    } catch (error) {
        yield put(handleAssetTableLoading(false));
        yield put(deleteComputeModelInstanceConfigError());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* regenerateTable(
    action: ReturnType<
        | typeof changeActivePageInstanceConfig
        | typeof changeEnteriesPerPageInstanceConfig
        | typeof handleIncludeAllChildElements
    >
) {
    const state: StoreState = yield select();
    const { selectedTreeNode } = state.assets;
    const { pagination } = state.instanceConfig.instancesTable;
    let activePage = pagination.activePage;
    let enteriesPerPage = pagination.enteriesPerPage;
    let includeAllChild = state.instanceConfig.instancesTable.includeChildElements;

    if ('pageNumber' in action.payload) {
        activePage = action.payload.pageNumber;
    }

    if ('enteriesPerPage' in action.payload) {
        enteriesPerPage = action.payload.enteriesPerPage;
    }

    if ('includeAllChild' in action.payload) {
        includeAllChild = action.payload.includeAllChild;
    }

    // if (selectedTreeNode) {
    // yield put(
    //     getAssetInstanceListActionRequest({
    //         node: selectedTreeNode,
    //         activePage,
    //         enteriesPerPage,
    //         includeAllChild
    //     })
    // );
    // New API request
    yield put(
        getNewAssetInstanceListActionRequest({
            node: selectedTreeNode!,
            activePage,
            enteriesPerPage,
            includeAllChild,
        })
    );
    // }
}

function* handleAssetConfigureBtnClick(
    action: ReturnType<typeof handleAssetConfigureBtnClickAction>
) {
    console.log(action.payload);
    const state = store.getState();
    debugger;
    const selectedInstance = yield select(getSelectedAssetInstanceSelector);

    yield put(updateSelectedSupportedType({}));

    if (selectedInstance && selectedInstance.length === 0) {
        return;
    }
    const assetInstanceId = selectedInstance[0];

    const assetInstanceTableData = yield select(getAssetInstanceTableDataSelector);

    const assetInstance = _.get(assetInstanceTableData, `byId.${assetInstanceId}`);
    console.log('assetInstance', assetInstance);
    const tableData = _.get(assetInstanceTableData, `byId`);
    const instance = tableData[selectedInstance[0]];

    if (
        assetInstance &&
        isAssetInstanceNotConfigured(assetInstance.amInfo[0].configurationStatus)
    ) {
        yield put(
            showModal({
                component: AssetConfigModal,
                modalTitle: CREATE_NEW_MODEL.ASSET_CONFIG_MODEL,
                data: {
                    submitBtnText: CREATE_NEW_MODEL.ASSET_DEPLOY_TEXT,
                },
            })
        );
    } else {
        yield put(updateAssetConfigLoader(true));
        yield put(
            showModal({
                component: AssetConfigModal,
                modalTitle: ASSET_MODAL_BUTTON.ASSET_CONFIG_MODEL_MODIFY,
                data: {
                    stopMonitoringBtnText: ASSET_MODAL_BUTTON.STOP_MONITORING,
                    submitBtnText: ASSET_MODAL_BUTTON.MODIFY,
                },
            })
        );

        try {
            const res = yield sce.getInstance(
                instance.objectId,
                instance.amInfo[0].assetMonitorModel,
                AppSettings.IsCOD
            );
            console.log(instance, 'instance');
            if (res.details) {
                const model = res.details.properties.model;
                yield put(
                    updateAssetConfigModalFields({
                        name: model.name.value,
                        tags: model.tags.value,
                        description: model.description.value,
                    })
                );
                yield put(updateAssetConfigLoader(false));
            }
        } catch (error) {
            yield put(updateAssetConfigLoader(false));
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(error)],
                })
            );
        }
    }
}

function* handleAssetClickMenu(
    action: ReturnType<typeof handleAssetEnableBtnClickAction>
) {

    try {
        const actionMenu = action.payload.value;
        yield put(updateSelectedMenuItem(actionMenu));
        yield put(updateInstanceMonitoringList([]));

        let activeActionMenu = '';
        if (actionMenu === ACTION_MENU_VALUE.configure) {
            yield put(handleAssetConfigureBtnClickAction());
            return;
        } else if (actionMenu === ACTION_MENU_VALUE.enableMonitoringStatus) {
            activeActionMenu = ASSET_CRITICALITY.ENABLE;
        } else if (actionMenu === ACTION_MENU_VALUE.disableMonitoringStatus) {
            activeActionMenu = ASSET_CRITICALITY.DISABLE;
        } else if (actionMenu === ACTION_MENU_VALUE.delete) {
            activeActionMenu = ASSET_CRITICALITY.DELETE;
        }
        console.log(activeActionMenu, 'activeActionMenu');
        yield put(
            showModal({
                component: AssetCriticalityModal,
                modalTitle: activeActionMenu,
                data: {
                    submitBtnText: CREATE_NEW_MODEL.ASSET_CREATE_TEXT,
                },
            })
        );
    } catch (err) {
        yield put(handleAssetInstanceActionMenuClickFailure());

        // yield showNotificatio(err);
    }
}
function* handleAssetInstanceMonitoring(action: ReturnType<typeof handleInstanceMonitoring>) {
    debugger;
    try {
        yield put(handleAssetTableLoading(true));
        const store: StoreState = yield select();
        const selectedMenuItem = store.instanceConfig.selectedMenuItem;
        // const selectedInstances = yield select(
        //     getSelectedAssetInstanceSelector
        // );
        const selectedTreeNode = store.assets.selectedTreeNode;
        // Loader missing.

        let successMessage: string = '';
        let response: any;
        const instanceMonitoringList = store.instanceConfig.instanceMonitoringList;
        const multipleSelectedInstances = getMultipleSelectedInstances(
            instanceMonitoringList,
            store.instanceConfig.instancesTable.tableData.byId
        );

        switch (selectedMenuItem) {
            case ACTION_MENU_VALUE.enableMonitoringStatus:
                response = yield AbbSDK.enableSingleOrMultipleMonitoringInstances([
                    ...multipleSelectedInstances,
                ]);
                successMessage = 'Enabled monitoring for all selected instances';
                break;

            case ACTION_MENU_VALUE.disableMonitoringStatus:
                response = yield AbbSDK.disableSingleOrMultipleMonitoringInstances([
                    ...multipleSelectedInstances,
                ]);
                successMessage = 'Disabled monitoring for all selected instances';

                break;

            case ACTION_MENU_VALUE.delete:
                response = yield AbbSDK.deleteMultipleInstances(
                    [...multipleSelectedInstances],
                    AppSettings.IsCOD
                );
                successMessage = 'Deleted all selected instances';
                break;

            default:
                break;
        }
        yield put(
            showNotificationModal({
                title: successMessage,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        if (selectedTreeNode) {
            yield put(
                getNewAssetInstanceListActionRequest({
                    node: selectedTreeNode,
                })
            );
        } else {
            // yield put(handleAssetTableLoading(true));
            const selectedStructure = store.assets.assetStructureDetails.selectedStructure;
            const { pagination } = store.instanceConfig.instancesTable;
            let activePage = pagination.activePage;
            if (selectedStructure) {
                yield put(updateStructure(selectedStructure));
                yield put(
                    getNewAssetInstanceListActionRequest({
                        node: null as any,
                        activePage,
                    })
                );
            }
        }
    } catch (err) {
        yield put(handleAssetTableLoading(false));
        console.log('error --- ', err);
        yield put(handleAssetInstanceActionMenuClickFailure());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: abbSDKGetInstanceConfigErrorMessage(err),
                // details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* resetActivePage() {
    yield put(changeActivePageInstanceConfig({ pageNumber: 1 }));
}

function* getSelectedTypeJson(action: ReturnType<typeof getSelectedTypeComputeModelFromJson>) {
    const { modelId, typeId, version, objectId } = action.payload;
    const state = yield select();
    try {
        yield put(handleLoading(true));
        const res: any = yield sce.getType(modelId, typeId, version, objectId, AppSettings.IsCOD);

        const computeModelJson = new ComputeModelFromJson({
            ...(res.details as ComputeModelToJson),
        });

        yield put(updateSelectedTypeJson({ json: computeModelJson }));
        yield put(handleLoading(false));
    } catch (error) {
        yield put(handleLoading(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getLibraryReferencesForFunctionTypesSaga(
    action: ReturnType<typeof getLibraryReferencesForFunctionTypesRequest>
) {
    try {
        const res = yield sce.getLibraryReferencesForFunctionTypes(action.payload);
    } catch (error) {
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

export default [
    ...configurationTool,
    takeLatest(ActionTypes.GET_ALL_COMPUTE_MODELS_API, getAllComputeModelsApi),
    takeLatest(ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL, saveInstanceComputeModel),
    takeLatest(ActionTypes.GET_ASSET_TABLE_DATA_API, getAssetTableDataApi),
    takeLatest(ActionTypes.TOGGLE_ASSET_PAGE_FOR_UPDATE, handleAssetToggleApiForUpdate),
    takeLatest(ActionTypes.DELETE_INSTANCE_COMPUTE_MODEL, deleteInstanceComputeModel),
    takeLatest(ActionTypes.HANDLE_CONFIGURE_BUTTON_CLICK, handleAssetConfigureBtnClick),
    takeLatest(ActionTypes.HANDLE_ENABLE_BUTTON_CLICK, handleAssetClickMenu),
    takeLatest(ActionTypes.GET_MATCHING_COMPUTE_MODELS_API, getMatchingComputeModelsApi),
    takeLatest(ActionTypes.CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG, regenerateTable),
    takeLatest(ActionTypes.CHANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG, regenerateTable),
    takeLatest(ActionTypes.HANDLE_INCLUDE_ALL_CHILD_ELEMENTS, regenerateTable),
    takeLatest(ActionTypes.TOGGLE_ASSET_PAGE, handleAssetToggleApi),
    takeLatest(ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_UPDATE, updateModelInstance),
    takeLatest(ActionTypes.VIEW_SAVE_INSTANCE_COMPUTE_MODEL_UPDATE, viewandUpdateModelInstance),
    takeLatest(ActionTypes.GET_ALL_COMPUTE_MODELS, getNewAssetInstanceListActionRequestUpdate),
    // takeLatest(ActionTypes.UPDATE_SELECTED_TREE_NODE, resetActivePage)
    takeLatest(
        ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE,
        saveInstanceComputeModelUpdate
    ),

    takeLatest(ActionTypes.HANDLE_INSTANCE_MONITORING, handleAssetInstanceMonitoring),
    takeLatest(ActionTypes.GET_CONFIGURED_INSTANCE_JSON, getConfiguredInstanceJsonData),
    takeLatest(ActionTypes.GET_SELECTED_TYPE_COMPUTE_MODEL_FROM_JSON, getSelectedTypeJson),
    takeLatest(
        ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_REQUEST,
        getLibraryReferencesForFunctionTypesSaga
    ),
];
