import React, { useState, useEffect } from 'react';
import { CollapsibleContainer, Collapsible } from '@abb/abb-common-ux-react';

import Loader from '../../../Loader';
import AssetMenu from '../AssetMenu';
import LeftNavigationDataRow from '..';
import CanvasController from '../../../Fabric';
import { AssetType } from '../../../../transformers/AssetType';
import {
    filterTypesBylatestVersion,
    filterComputeModelsBylatestVersion,
} from '../../../../store/modelsPage/reducer';
import { ModelsPageState, ModelUsedInstanceType } from '../../../../store/modelsPage/types';
import { TypeMenuAction } from '../..';
import _ from 'lodash';

interface DataRowItemProps {
    id: string;
    data: AssetType;
    itemIndex: number;
    editMode: boolean;
    canvas: CanvasController;
    selectedItem: string;
    parentkey: string;
    activeAsset: AssetType | null;
    permissions: {
        hasSave?: boolean;
        hasExport?: boolean;
        hasDelete?: boolean;
        hasEdit?: boolean;
    };
    computeModels: ModelsPageState['computeModels'];
    computeModelsLoading: boolean;
    activeModelId: string;
    isDirty: boolean;
    isNew: boolean;
    usedInstanceList: ModelUsedInstanceType[];
    showMenu: boolean;
    handleSelectedItem: (itemId: string) => void;
    showCreateModel: () => void;
    updateActiveParentKey: (key: string) => void;
    getNodeData: (node: AssetType) => void;
    showConfirmationDialog: (modelId: string) => void;
    handeTypeMenuAction: (actionName: TypeMenuAction) => void;
    handleSelectedType: (
        modelId: string,
        node: AssetType,
        selectedItemId: string,
        computeModels: ModelsPageState['computeModels']
    ) => void;
}

const DataRowItem = (props: DataRowItemProps) => {
    const { data, itemIndex, activeAsset } = props;
    const [currentAsset, setCurrentAsset] = useState({} as AssetType);
    const [models, setModels] = useState({} as ModelsPageState['computeModels']['byId']);
    const [originalModels, setOriginalModels] = useState({} as ModelsPageState['computeModels']);

    const [isCollapseOpen, setCollapse] = useState(false);

    useEffect(() => {
        if (props.selectedItem === props.id) {
            const computeModelsData = Object.values(props.computeModels.byId);
            const { updatedComputeModels } = filterComputeModelsBylatestVersion({
                computeModels: computeModelsData,
            });
            let computeModelParentKey = updatedComputeModels.entities.length
                ? `${props.parentkey}_${
                      updatedComputeModels.byId[updatedComputeModels.entities[0]].associatedObject
                          .typeId
                  }`
                : '';
            if (computeModelParentKey === props.id) {
                setCurrentAsset(props.data);
                setModels(updatedComputeModels.byId);
                setOriginalModels(props.computeModels);
            }
        }
    }, [props.selectedItem, props.computeModels.entities]);

    const handleCollapseItemClick = (clicked: Collapsible, parents: Collapsible[]) => {
        toggleCollapse();
    };

    const toggleCollapse = () => {
        if (props.id === props.selectedItem || isCollapseOpen) {
            if (isCollapseOpen) {
                setCollapse(false);
            }
        } else {
            setCollapse(true);
            props.getNodeData(data);
            props.updateActiveParentKey(props.parentkey);
            props.handleSelectedItem(props.id);
        }
    };

    const title: string = data.assetName + ' ' + data.assetVersion;
    console.log('###render');

    return (
        <CollapsibleContainer
            className="collpase_item"
            onItemClick={handleCollapseItemClick}
            id={`parent${props.id}`}
            collapseAll={isCollapseOpen}
        >
            <Collapsible
                id={props.id}
                itemId={props.id}
                title={title}
                icon="abb/object"
                className={`collapsible_1  ${
                    activeAsset && data.assetRef === activeAsset.assetRef ? 'active' : ''
                }`}
                onClickExpander={(isExpanded) => {
                    toggleCollapse();
                }}
            >
                {Object.keys(models).length > 0 && props.data.assetRef === currentAsset.assetRef ? (
                    <>
                        <div
                            className="wrapper-data-list"
                            style={{
                                maxHeight: '100%',
                                paddingTop: `3px`,
                            }}
                        >
                            <LeftNavigationDataRow
                                key={`${props.id}_types`}
                                item={data}
                                itemId={props.id}
                                id={`${props.id}`}
                                isNew={props.isNew}
                                canvas={props.canvas}
                                isDirty={props.isDirty}
                                permissions={props.permissions}
                                editMode={props.editMode}
                                filteredComputeModels={models}
                                computeModels={originalModels}
                                activeAsset={props.activeAsset}
                                activeModelId={props.activeModelId}
                                usedInstanceList={props.usedInstanceList}
                                handleSelectedType={props.handleSelectedType}
                                handeTypeMenuAction={props.handeTypeMenuAction}
                                showConfirmationDialog={props.showConfirmationDialog}
                            />
                        </div>
                    </>
                ) : props.activeAsset &&
                  props.computeModelsLoading &&
                  props.activeAsset.assetRef === props.data.assetRef ? (
                    <Loader sizeClass="small" type="radial" />
                ) : (
                    <div>No types available!</div>
                )}
                {props.showMenu ? (
                    <AssetMenu
                        data={props.data}
                        activeAsset={props.activeAsset}
                        showCreateModel={props.showCreateModel}
                    />
                ) : (
                    <></>
                )}
            </Collapsible>
        </CollapsibleContainer>
    );
};

export default React.memo(DataRowItem);
