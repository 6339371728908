import React from "react";
import FilterInstanceModal from "./filterInstance";
import { IFilteredItem } from "../../../store/assets/types";
interface FilterConfigModalProps {
    onApplyFilter: (filterItems: IFilteredItem[], filter: boolean) => void;
}

const FilterConfigModal = (props: FilterConfigModalProps) => {
    return (
        <div className="filter-dialog-body-container">
            <FilterInstanceModal onApplyFilter={props.onApplyFilter} />
        </div>
    );
};

export default FilterConfigModal;
