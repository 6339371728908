import React, { useCallback, useMemo, useState, useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { Checkbox } from '@abb/abb-common-ux-react';

interface GlobalConditionsConfigurationProps {
    functionConditionDetails: any;
    selectedProperties: any;
    setSelectedProperties: any;
}

const GlobalConditionsConfiguration = (props: GlobalConditionsConfigurationProps) => {
    const { functionConditionDetails, selectedProperties, setSelectedProperties } = props;

    const [isSelectAll, setIsSelectAll] = useState(false);

    const subConditions: any = useMemo(() => {
        return [
            'subCondition',
            ...Object.keys(
                Object.values(
                    functionConditionDetails[Object.keys(functionConditionDetails)[0]].subConditions
                )[0] as any
            ).filter((item) => item !== 'logic'),
        ];
    }, [functionConditionDetails]);

    const addRemoveSelectedProperties = useCallback(
        (property: string) => {
            let updatedSelectedProperties = _.cloneDeepWith(selectedProperties);
            if (selectedProperties.includes(property)) {
                let index = updatedSelectedProperties.indexOf(property);
                updatedSelectedProperties.splice(index, 1);
            } else {
                updatedSelectedProperties = [...selectedProperties, property];
            }
            setSelectedProperties(updatedSelectedProperties);
        },
        [selectedProperties]
    );

    const handleSelectAll = () => {
        if (selectedProperties.length < subConditions.length) {
            setSelectedProperties([...subConditions]);
        } else if (selectedProperties.length === subConditions.length) {
            setSelectedProperties([]);
        }
    };

    return (
        <React.Fragment>
            <div key={'selectAll'} className="expandable-item">
                <Checkbox
                    sizeClass="small"
                    value={selectedProperties.length === subConditions.length}
                    onChange={() => handleSelectAll()}
                />
                <div className="sub-condition-name">{'Select All'}</div>
            </div>
            {subConditions.map((item: string) => (
                <div key={item} className="expandable-item">
                    <Checkbox
                        sizeClass="small"
                        value={selectedProperties.includes(item)}
                        onChange={() => addRemoveSelectedProperties(item)}
                    />
                    <div className="sub-condition-name">{item}</div>
                </div>
            ))}
        </React.Fragment>
    );
};

export default GlobalConditionsConfiguration;
