import React, { useMemo } from "react";
import _ from "lodash";
import {
    Icon,
    Input,
    Checkbox,
    RadioGroup,
    RadioOption,
} from "@abb/abb-common-ux-react";

// import { MODEL, SUPPORTED_CONDITION_MONITORS_TAG } from '../../../utils/constants/appConstants';
import { ISupportedModelItem } from "../../../store/supportedConditionMonitors/types";

import "../style.scss";
import CustomABBInput from "../../CustomABBInput";
interface SupportedConditionMonitorItemProps {
    index: number;
    conditionMonitor: ISupportedModelItem;
    addedSupportedConditionMonitorModels: ISupportedModelItem[];
    handleCheckboxChange: (index: number) => void;
    handleOnInputNameChange: (value: any, index: any) => void;
    validateConditionMonitorInputsOrIds: (
        index: any,
        value: any,
        key: any
    ) => {
        valid: boolean;
        text: string;
    };
    // handleTagsCheckboxChange: (index: number) => void;
    handleDelete: (modelItem: ISupportedModelItem | undefined) => void;
}

const SupportedConditionMonitorItem = (
    props: SupportedConditionMonitorItemProps
) => {
    const {
        index,
        handleDelete,
        conditionMonitor,
        handleCheckboxChange,
        handleOnInputNameChange,
        // handleTagsCheckboxChange,
        validateConditionMonitorInputsOrIds,
        addedSupportedConditionMonitorModels,
    } = props;

    // const selectedTag = useMemo(() => {
    //     if (conditionMonitor.tenant.toString() === 'true') {
    //         return SUPPORTED_CONDITION_MONITORS_TAG.TENANT_SPECIFIC_TAG;
    //     } else return SUPPORTED_CONDITION_MONITORS_TAG.PUBLIC_MODEL;
    // }, [conditionMonitor]);

    // const isOldSupportedConditionMonitorModel = useMemo(() => {
    //     return index >= addedSupportedConditionMonitorModels.length;
    // }, [index, addedSupportedConditionMonitorModels]);

    // console.log(props);

    return (
        <React.Fragment>
            {index !== 0 && (
                <div className="condition-monitor-table-row-border" />
            )}
            <div className="condition-monitors">
                <div className="condition-monitor-input-id">
                    {conditionMonitor.isUpdatable ? (
                        <Checkbox
                            sizeClass="small"
                            value={conditionMonitor.isSelected}
                            onChange={() => handleCheckboxChange(index)}
                        />
                    ) : (
                        <div className="checkbox-width"></div>
                    )}

                    <CustomABBInput
                        value={conditionMonitor.modelName}
                        type="normal"
                        dataType="text"
                        id="monitorId"
                        disabled={
                            !(
                                index <
                                addedSupportedConditionMonitorModels.length
                            )
                        }
                        onValueChange={(value) =>
                            handleOnInputNameChange(value, index)
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                        instantValidation={true}
                        validator={(val) =>
                            validateConditionMonitorInputsOrIds(
                                index,
                                val,
                                "Id"
                            )
                        }
                        className="condition-monitor-input"
                        placeholder="Enter Supported Condition Monitor Model Id"
                    />
                    {/* <RadioGroup
                        value={selectedTag}
                        orientation={'horizontal'}
                        onChange={() => handleTagsCheckboxChange(index)}
                        disabled={
                            !conditionMonitor.isUpdatable ||
                            conditionMonitor.modelName === MODEL.DEFAULT_MODEL_ID
                        }
                    >
                        <RadioOption
                            value={SUPPORTED_CONDITION_MONITORS_TAG.TENANT_SPECIFIC_TAG}
                            text={SUPPORTED_CONDITION_MONITORS_TAG.TENANT_SPECIFIC_TAG}
                        />
                        <RadioOption
                            value={SUPPORTED_CONDITION_MONITORS_TAG.PUBLIC_MODEL}
                            text={SUPPORTED_CONDITION_MONITORS_TAG.PUBLIC_MODEL}
                        />
                    </RadioGroup> */}
                </div>
                <div className="icon-div">
                    {conditionMonitor.isUpdatable ? (
                        <Icon
                            name="abb/trash"
                            sizeClass="medium"
                            className="add-model-id-icon"
                            onClick={() => {
                                handleDelete(conditionMonitor);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
};
export default SupportedConditionMonitorItem;
