import { ModelsPageState, Actions, IModelPermission, ActiveModelType } from './types';
import { ActionTypes } from '../actionTypes';
import uuid from 'uuid';

import CanvasController from '../../components/Fabric';

import _ from 'lodash';

import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { MODEL_SAVE_TYPE, SAVE_OPTIONS } from '../../utils/constants/appConstants';
import {
    updateJsonWithOriginalFunctionDetails,
    checkIsGreaterVersionAvailable,
} from '../../utils/helpers';
import { ExportAssetReduceState } from '../exportAsset/types';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';

const blankComputeModelFromJson = new ComputeModelFromJson();

export const initialState: ModelsPageState = {
    objectTypeList: {
        masterList: { byId: {} },
        filterList: [],
        filters: { search: '' },
    },
    functionTypeList: {
        masterList: { byId: {} },
        filterList: [],
        filters: { search: '' },
    },
    objectDetailList: [],
    functionDetailList: [],
    computeModels: { byId: {}, entities: [] },
    // filteredComputeModels: { byId: {}, entities: [] },

    activeModel: {
        modelInstance: {
            ...new ComputeModelFromJson(),
            saveOption: '',
        },
        isDirty: false,

        id: '',
    },
    saveAsActiveModel: {
        modelInstance: {
            ...new ComputeModelFromJson(),
            saveOption: '',
        },
        isDirty: false,
        id: '',
    },
    activeAsset: null,
    activeParentKey: '',
    currentRoutePath: '',
    supportedModels: [],
    modelUsedInstances: [],
    isLoading: false,
    sidebarToggleStatus: false,
    modelsPagination: {
        activePage: 1,
        entriesPerPage: 0,
        totalPages: 0,
        totalRecords: 0,
    },
    modelPermission: {
        hasSave: true,
        hasDelete: true,
        hasEdit: true,
        hasImport: true,
        hasExport: true,
    } as IModelPermission,
    // infoModelInstance: undefined,
    // identityModel: [],
    // newDefinedType: undefined,
    showModalProgress: false,
    saveType: '',
    updatingTypeRelatedInstances: false,
    originalComputeModels: [],
    availableTypeIds: [],
    isAuthorized: false,
    isFunctionUpdatesAvailable: false,
    isFunctionsUpdating: false,
    isUniqueTypeIdLoading: false,
    isTypeIdExistError: false,
    uniquetypeId: ' ',
    modelid: ' ',
    message: ' ',
    isExist: false,
    alarmDetails: null,
};

const defaultJson = { assetData: [], connectionData: [] };

export const modelsPageReducer = (state = initialState, action: Actions): ModelsPageState => {
    switch (action.type) {
        case ActionTypes.UPDATE_ASSETS_LIST: {
            const { objectTypeList, functionTypeList } = action.payload;

            state.functionTypeList = {
                masterList: { byId: {} },
                filterList: [],
                filters: { search: '' },
            };
            state.objectTypeList = {
                masterList: { byId: {} },
                filterList: [],
                filters: { search: '' },
            };

            objectTypeList.forEach((object: any) => {
                state.objectTypeList.masterList.byId[object.assetRef] = object;
                state.objectTypeList.filterList.push(object.assetRef);
            });

            functionTypeList.forEach((object: any) => {
                state.functionTypeList.masterList.byId[object.assetRef] = object;
                state.functionTypeList.filterList.push(object.assetRef);
            });

            return { ...state };
        }

        case ActionTypes.FUNCTION_LIST_SEARCH: {
            const { search } = action.payload;
            console.log('search func ', search);
            const masterList = state.functionTypeList.masterList;
            state.functionTypeList.filters = {
                ...state.functionTypeList.filters,
                search,
            };

            state.functionTypeList.filterList = filterTypesList({
                masterList,
                filters: state.functionTypeList.filters,
            });

            return { ...state };
        }

        case ActionTypes.OBJECT_LIST_SEARCH: {
            console.log('object search');
            const { search } = action.payload;
            console.log('search ', search);
            const masterList = state.objectTypeList.masterList;
            state.objectTypeList.filters = {
                ...state.objectTypeList.filters,
                search,
            };
            state.objectTypeList.filterList = filterTypesList({
                masterList,
                filters: state.objectTypeList.filters,
            });
            return { ...state };
        }
        case ActionTypes.SET_ACTIVE_MODAL_IMPORT: {
            let activeModel = {
                id: action.payload.objectId,
                modelInstance: action.payload,
                isDirty: false,
                isNew: true,
            };
            return { ...state, activeModel };
        }
        case ActionTypes.ADD_SAVE_AS_COMPUTE_MODEL: {
            let { associatedObject, modelDetails } = action.payload;

            if (state.activeAsset === null && associatedObject) {
                state.activeAsset = associatedObject;
            }

            let objectId = uuid();

            let json = { ...state.activeModel.modelInstance.json };

            let model = {
                version: '1',
                json,
                modelDetails,
                associatedObject: {
                    model: associatedObject.assetType,
                    typeId: associatedObject.assetRef,
                    version: associatedObject.assetVersion,
                },
                localJSON: _.cloneDeep(json),
                objectId,
                isOverAllSeverityFunctionPresent:
                    state.activeModel.modelInstance.isOverAllSeverityFunctionPresent,
                overAllSeverityFunctionId:
                    state.activeModel.modelInstance.overAllSeverityFunctionId,
            };

            state.saveAsActiveModel = {
                id: objectId,
                modelInstance: model,
                isDirty: false,
                isNew: true,
            };
            return {
                ...state,
                saveType: MODEL_SAVE_TYPE.SAVE_AS,
            };
        }

        case ActionTypes.ADD_NEW_COMPUTE_MODEL: {
            let { associatedObject, modelDetails } = action.payload;

            if (state.activeAsset === null && associatedObject) {
                state.activeAsset = associatedObject;
            }

            let objectId = uuid();
            let json = { assetData: [], connectionData: [] };

            let model = {
                version: '1',
                json,
                modelDetails,
                associatedObject: {
                    model: associatedObject.assetType,
                    typeId: associatedObject.assetRef,
                    version: associatedObject.assetVersion,
                },
                localJSON: _.cloneDeep(json),
                objectId,
            };

            state.computeModels.byId = {
                ...state.computeModels.byId,
                [objectId]: model,
            };
            state.computeModels.entities.push(objectId);

            state.activeModel = {
                id: objectId,
                modelInstance: model,
                isDirty: false,
                isNew: true,
            };
            return { ...state, saveType: '' };
        }

        case ActionTypes.GET_COMPUTE_MODELS_API: {
            return {
                ...state,
                activeAsset: action.payload.asset,
                computeModels: { byId: {}, entities: [] },
                // filteredComputeModels: { byId: {}, entities: [] },
            };
        }

        case ActionTypes.GET_COMPUTE_MODELS_STORE: {
            debugger;
            state.computeModels = { byId: {}, entities: [] };
            const { computeModels, activeAsset, activeModelId } = action.payload;

            computeModels.forEach((computeModel) => {
                state.computeModels.byId[computeModel.objectId] = {
                    ...computeModel,
                };
                state.computeModels.entities.push(computeModel.objectId);
            });

            let json: any = { ...defaultJson };

            state.activeAsset = activeAsset;
            if (activeModelId) {
                const modelInstance = {
                    ...state.computeModels.byId[activeModelId],
                };
                //
                state.activeModel = {
                    isDirty: false,
                    isNew: false,
                    id: activeModelId,
                    modelInstance: { ...modelInstance },
                };
                json = modelInstance.json;
            } else {
                state.activeModel = {
                    isDirty: false,
                    isNew: false,
                    id: '',
                    modelInstance: {
                        ...new ComputeModelFromJson(),
                        saveOption: '',
                    },
                };
            }

            return { ...state };
        }

        case ActionTypes.CHANGE_ACTIVE_MODEL: {
            console.log('change active model');
            const { modelId } = action.payload;
            const newModel = state.computeModels.byId[modelId];
            if (state.activeModel.isNew) {
                delete state.computeModels.byId[state.activeModel.id];
                state.computeModels.entities = state.computeModels.entities.filter(
                    (id) => id !== state.activeModel.id
                );
            }

            if (newModel) {
                return {
                    ...state,
                    activeModel: {
                        id: modelId,
                        modelInstance: { ...newModel },

                        isDirty: false,
                    },
                };
            }
            return { ...state };
        }

        case ActionTypes.UPDATE_TYPE_JSON_CONDITION_DETAILS: {
            const { json, originalFunctionUsed } = action.payload;
            // const udpatedJson = updateJsonWithOriginalFunctionDetails({
            //     json,
            //     originalFunctionUsed,
            // });
            // updated conditon data fetched from original function types.
            state.activeModel.modelInstance.json = { ...json };
            return {
                ...state,
            };
        }

        case ActionTypes.CANCEL_UNSAVED_MODEL_CHANGES: {
            let activeModel = state.activeModel;

            activeModel.isDirty = false;

            if (state.activeModel.isNew) {
                delete state.computeModels.byId[activeModel.modelInstance.objectId];
                state.computeModels.entities = state.computeModels.entities.filter(
                    (id) => id !== activeModel.modelInstance.objectId
                );

                if (state.computeModels.entities.length > 0) {
                    const newModelId = state.computeModels.entities[0];
                    activeModel = {
                        modelInstance: state.computeModels.byId[newModelId],
                        isDirty: false,
                        id: newModelId,
                    };
                } else {
                    activeModel = _.cloneDeep(initialState.activeModel);
                }
            } else {
                const currentModelId = state.activeModel.id;
                const oldModelDetails = state.computeModels.byId[currentModelId].modelDetails;
                state.activeModel.modelInstance.modelDetails = { ...oldModelDetails };
            }

            state = clearTypesListFilter(state);

            state.activeModel = { ...activeModel };
            return { ...state };
        }

        case ActionTypes.SAVE_COMPUTE_MODEL_API: {
            const { isLatestFunctionsUpdating } = action.payload;
            return {
                ...state,
                isFunctionsUpdating: !!isLatestFunctionsUpdating,
            };
        }

        case ActionTypes.SAVE_COMPUTE_MODEL_STORE: {
            const isSaveAsType = state.saveType === MODEL_SAVE_TYPE.SAVE_AS;
            const activeModel = isSaveAsType ? state.saveAsActiveModel : state.activeModel;
            const { computeModel } = action.payload;

            const computeModelRedux = {
                ...computeModel,
                localJSON: computeModel.json,
            };

            if (activeModel) {
                if (activeModel.isNew) {
                    delete state.computeModels.byId[activeModel.id];
                    state.computeModels.entities = state.computeModels.entities.filter(
                        (id) => id !== activeModel.id
                    );
                }
                const index = state.computeModels.entities.findIndex(
                    (item) => item === computeModel.objectId
                );

                state.activeModel = {
                    modelInstance: _.cloneDeep(computeModelRedux),
                    id: computeModel.objectId,

                    isDirty: false,
                    isNew: false,
                };
                state.computeModels.byId = {
                    ...state.computeModels.byId,
                    [computeModel.objectId]: _.cloneDeep(computeModelRedux),
                };
                if (index === -1) {
                    state.computeModels.entities.push(computeModel.objectId);
                }
                const { updatedComputeModels } = filterComputeModelsBylatestVersion({
                    computeModels: Object.values(state.computeModels.byId),
                });
                state.computeModels = { ...updatedComputeModels };
            }

            state = clearTypesListFilter(state);
            if (isSaveAsType) {
                state.saveType = '';
            }
            if (state.isFunctionsUpdating) {
                state.isFunctionsUpdating = false;
            }
            return { ...state, isFunctionUpdatesAvailable: false };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_STORE: {
            const { newModelId, computeModels } = action.payload;
            state.computeModels = { ...computeModels };

            if (newModelId !== '') {
                const modelInstance = state.computeModels.byId[newModelId];
                if (modelInstance) {
                    state.activeModel = {
                        isDirty: false,
                        id: newModelId,
                        isNew: false,
                        modelInstance,
                    };
                }
            } else {
                console.log('deleting no newModelId');
                state.activeModel = {
                    editMode: false,
                    isDirty: false,
                    id: '',
                    isNew: false,
                    //@ts-ignore
                    modelInstance: null,
                };
            }
            return { ...state };
        }

        case ActionTypes.UPDATE_ACTIVE_MODEL_DATA: {
            const { name, description, typeId, version, tags, model } = action.payload;
            state.activeModel.modelInstance.modelDetails = {
                ...state.activeModel.modelInstance.modelDetails,
                name: name,
                model: model,
                description: description,
                typeId: typeId,
                version: version,
                tags: tags,
            };
            return { ...state, saveType: '' };
        }

        case ActionTypes.UPDATE_SUPPORTED_MODEL_DATA: {
            const supportedModels = action.payload;
            state.supportedModels = [...supportedModels];
            return { ...state };
        }
        case ActionTypes.GET_MODEL_USED_BY_INSTANCES: {
            const { pageNumber, pageSize } = action.payload;
            state.modelsPagination.activePage = pageNumber;
            state.modelsPagination.entriesPerPage = pageSize;
            return { ...state };
        }
        case ActionTypes.UPDATE_MODEL_USED_BY_INSTANCES: {
            const modelUsedInstances = action.payload || [];
            state.modelUsedInstances = [...modelUsedInstances];
            return { ...state };
        }
        case ActionTypes.CHANGE_PAGINATION_DETAILS: {
            state.modelsPagination.entriesPerPage = action.payload.entriesPerPage;
            state.modelsPagination.totalPages = action.payload.noOfPages;
            state.modelsPagination.totalRecords = action.payload.totalRecords;
            return { ...state };
        }
        case ActionTypes.HANDLE_SIDEBAR_TOGGLE: {
            const toggleStatus = action.payload;

            return {
                ...state,
                sidebarToggleStatus: toggleStatus,
            };
        }

        case ActionTypes.UPDATE_MODEL_PERMISSION: {
            return {
                ...state,
                modelPermission: { ...action.payload },
            };
        }

        case ActionTypes.UPDATE_ACTIVE_PARENT_KEY: {
            const activeParentKey = action.payload;
            return {
                ...state,
                activeParentKey,
            };
        }

        // case ActionTypes.UPDATE_INFO_MODEL_INSTANCE: {
        //     const infoModelInstance = action.payload.supportedModel;
        //     const identityModel = action.payload.identityModel;
        //     return {
        //         ...state,
        //         infoModelInstance,
        //         identityModel,
        //     };
        // }
        // case ActionTypes.UPDATE_NEW_DEFINITION_TYPE: {
        //     const newDefinedType = action.payload;
        //     return {
        //         ...state,
        //         newDefinedType,
        //     };
        // }

        case ActionTypes.SHOW_PROGRESS: {
            const showModalProgress = action.payload;
            return {
                ...state,
                showModalProgress,
            };
        }

        case ActionTypes.HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE: {
            const updatingTypeRelatedInstances = action.payload;
            return {
                ...state,
                updatingTypeRelatedInstances,
            };
        }

        case ActionTypes.UPDATE_ORIIGNAL_COMPUTE_MODELS: {
            const originalComputeModels = action.payload;
            return {
                ...state,
                originalComputeModels,
            };
        }

        case ActionTypes.UPDATE_AVAILABLE_TYPE_IDS: {
            const availableTypeIds = action.payload;
            return {
                ...state,
                availableTypeIds,
            };
        }

        case ActionTypes.SET_AUTHORIZE: {
            const isAuthorized = action.payload;
            return {
                ...state,
                isAuthorized,
            };
        }

        case ActionTypes.UPDATE_ACTIVEMODEL_JSON_DATA: {
            const { json } = action.payload;
            state.activeModel.modelInstance.json = _.cloneDeepWith(json);
            return {
                ...state,
            };
        }

        case ActionTypes.HANDLE_IS_FUNC_UPDATE_AVAILABLE: {
            const isFunctionUpdatesAvailable = action.payload;
            return {
                ...state,
                isFunctionUpdatesAvailable: isFunctionUpdatesAvailable,
            };
        }

        case ActionTypes.HANDLE_UNIQUE_TYPEID_LOADING: {
            const isUniqueTypeIdLoading = action.payload;
            return {
                ...state,
                isUniqueTypeIdLoading,
            };
        }

        case ActionTypes.HANDLE_UNIQUE_TYPEID: {
            return {
                ...state,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_ISEXIST: {
            const isExist = action.payload;
            console.log('isExist', isExist);
            return {
                ...state,
                isExist,
            };
        }
        case ActionTypes.HANDLE_UNIQUE_TYPEID_MESSAGE: {
            const message = action.payload;
            console.log('premessage', message);
            return {
                ...state,
                message,
            };
        }
        case ActionTypes.HANDLE_EXISTING_TYPEID_ERROR: {
            const hasError = action.payload;
            return { ...state, isTypeIdExistError: hasError };
        }

        case ActionTypes.SET_NEW_ALARM_DETAILS: {
            const alarmDetails = action.payload;
            return {
                ...state,
                alarmDetails,
            };
        }
        default: {
            return state;
        }
    }
};

export function updateCanvasJson(options: { canvasController: CanvasController; json: LocalJson }) {
    const { canvasController, json } = options;
    canvasController.fromJSON({ json: { ...json } });
}

function clearTypesListFilter(state: ModelsPageState) {
    state.objectTypeList.filters = { search: '' };
    state.functionTypeList.filters = { search: '' };

    state.objectTypeList.filterList = filterTypesList({
        masterList: state.objectTypeList.masterList,
        filters: state.objectTypeList.filters,
    });
    state.functionTypeList.filterList = filterTypesList({
        masterList: state.functionTypeList.masterList,
        filters: state.functionTypeList.filters,
    });
    return state;
}

function filterTypesList(options: {
    masterList: ModelsPageState['objectTypeList']['masterList'];
    filters: ModelsPageState['objectTypeList']['filters'];
}) {
    const { masterList, filters } = options;
    const { search } = filters;

    const filterList: string[] = [];
    console.log(typeof search, search === ' ');
    Object.keys(masterList.byId).forEach((objectId) => {
        if (masterList.byId[objectId].assetName.toLowerCase().includes(search.toLowerCase()))
            filterList.push(objectId);
    });

    return filterList;
}

export function filterComputeModelsBylatestVersion(options: {
    computeModels: ComputeModelFromJson[];
}) {
    const { computeModels } = options;
    let updatedComputeModels: ModelsPageState['computeModels'] = {
        byId: {},
        entities: [],
    };
    let filteredModels: ModelsPageState['computeModels']['byId'] = {};
    computeModels.forEach((model) => {
        const typeFromJson = model;
        const modelDetails = typeFromJson.modelDetails;
        const newId = modelDetails.typeId + modelDetails.model;
        if (!filteredModels[newId]) {
            filteredModels[newId] = { ...typeFromJson };
        } else {
            const oldVersion = filteredModels[newId].modelDetails.version;

            const currentVersion = modelDetails.version;
            const isGreaterVersionAvailable = checkIsGreaterVersionAvailable(
                oldVersion,
                currentVersion
            );
            if (isGreaterVersionAvailable) {
                filteredModels[newId] = { ...typeFromJson };
            }
        }
    });
    const filteredComputeModels: ComputeModelFromJson[] = Object.values(filteredModels);
    Object.keys(filteredModels).forEach((item) => {
        const model = filteredModels[item];
        updatedComputeModels.byId[model.objectId] = { ...model };
        updatedComputeModels.entities.push(model.objectId);
    });
    return { updatedComputeModels, filteredComputeModels };
}

export function filterTypesBylatestVersion(options: { models: ComputeModelToJson[] }) {
    const { models } = options;
    let updatedModels: ExportAssetReduceState['multipleModels'][] = [];
    let filteredModels: any = {};
    models.forEach((model) => {
        const typeFromJson = model;
        const modelDetails = typeFromJson.properties.model;
        const newId = modelDetails.typeId.value + model.model;
        if (!filteredModels[newId]) {
            filteredModels[newId] = { ...typeFromJson };
        } else {
            const oldVersion = filteredModels[newId].properties.model.version.value;

            const currentVersion = modelDetails.version.value;
            const isGreaterVersionAvailable = checkIsGreaterVersionAvailable(
                oldVersion,
                currentVersion
            );
            if (isGreaterVersionAvailable) {
                filteredModels[newId] = { ...typeFromJson };
            }
        }
    });
    return Object.values(filteredModels);
}
