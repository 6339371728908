import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import {
    Input,
    Button,
    CollapsibleContainer,
    Collapsible,
    Icon,
} from "@abb/abb-common-ux-react";
import { FormGroup, Label, ModalFooter } from "reactstrap";

import "./style.scss";
import { customReactSelectStyles } from "../../utils/helpers";
import { StoreState } from "../../store";
import {
    CONFIGURATION_TOOL_MODE,
    MODEL,
    MODEL_SAVE_TYPE,
} from "../../utils/constants/appConstants";
import { setNewAlarmDetails } from "../../store/modelsPage/actions";
import { hideModal } from "../../store/modal/action";
import { AlarmEventType, ALARM_TYPE } from "../Fabric/types";
import uuid from "uuid";
import Loader from "../Loader";
import {
    fetchAlarmsForModels,
    addAlarmTriggerToFunction,
    editAlarmTriggerToFunction,
} from "../../store/configurationTool/actions";
import FunctionTypeDetail from "../../transformers/AssetType/FunctionType";
import {
    AddAlarmToFunctionPayload,
    EditAlarmToFunctionPayload,
} from "../../store/configurationTool/types";
import { validateForSpecialCharacters_Including_HyphenAndUnderscore } from "../../routes/Functions/helper";
import Condition from "../shared/Condition";
import ObjectTypeDetail from "../../transformers/AssetType/ObjectType";
import AlarmTypeDetail from "../../transformers/AssetType/AlarmType";
import CustomABBInput from "../CustomABBInput";

type alarmIdDetails = {
    label: string;
    value: string;
    id: string;
};

interface modelandAlarmDetailsForSpecific {
    [model: string]: alarmIdDetails[];
}

interface IDropdownItem {
    label: string;
    value: string;
}

interface AlarmEventTypeDropDownItem extends IDropdownItem {}

interface AddAlarmAppProps {
    mode: "CREATE" | "EDIT" | "VIEW";
    submitBtnText: string;
    functionType: FunctionTypeDetail;
    alarmType?: AlarmTypeDetail;
    objectPinName?: string;
}

const alarmEventTypeDropDownList: AlarmEventTypeDropDownItem[] = [
    {
        label: ALARM_TYPE.GENERIC,
        value: ALARM_TYPE.GENERIC,
    },
    {
        label: ALARM_TYPE.SPECIFIC,
        value: ALARM_TYPE.SPECIFIC,
    },
];

const MODAL_MODE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    VIEW: "VIEW",
};

function AddAlarmForm(
    props: AddAlarmAppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const modelsDropDownList: any[] = props.modelsForAlarms.map(
        (model: any) => {
            return {
                label: model.key,
                value: model.key,
            };
        }
    );
    const [selectedAlarmEventType, setSelectedAlarmEventType] =
        useState<AlarmEventTypeDropDownItem>(
            props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
                ? props.alarmType
                    ? {
                          label: props.alarmType.type,
                          value: props.alarmType.type,
                      }
                    : alarmEventTypeDropDownList[0]
                : alarmEventTypeDropDownList[0]
        );
    const [name, updateName] = useState(
        props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
            ? props.alarmType
                ? props.alarmType.assetName
                : ""
            : ""
    );
    const [model, updateModel] = useState({
        label:
            props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
                ? props.alarmType
                    ? props.alarmType.modelId
                    : ""
                : "",
        value:
            props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
                ? props.alarmType
                    ? props.alarmType.modelId
                    : ""
                : "",
    });
    const [alarmId, updateAlarmId] = useState({
        label:
            props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
                ? props.alarmType
                    ? props.alarmType.assetRef
                    : ""
                : "",
        value:
            props.mode === MODAL_MODE.VIEW || props.mode === MODAL_MODE.EDIT
                ? props.alarmType
                    ? props.alarmType.assetRef
                    : ""
                : "",
        id: "",
    });
    const [objectId, updateObjectId] = useState("");

    // inputField error handling states
    const [errorName, updateErrorName] = useState(false);
    const [errorModel, updateErrorModel] = useState(false);
    const [erroralarmId, updateErroralarmId] = useState(false);
    const [errorObjectId, updateErrorObjectId] = useState(false);

    const [errorNameText, updateErrorNameText] = useState(
        "Please Enter Model Name"
    );
    const [modelListForSpecificAlarms, setModelListForSpecificAlarms] =
        useState({} as modelandAlarmDetailsForSpecific);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        if (
            props.mode !== MODAL_MODE.CREATE &&
            selectedAlarmEventType.value === ALARM_TYPE.SPECIFIC
        ) {
            updateAlarmId({
                ...alarmId,
                label: `${alarmId.value} (${props.objectPinName})`,
            });
        }
    }, [selectedAlarmEventType]);

    useEffect(() => {
        if (!name || !model.value || !alarmId.value || errorName) {
            setIsSubmitDisabled(true);
        } else if (
            props.mode === MODAL_MODE.EDIT &&
            props.alarmType &&
            (!name || name === props.alarmType.assetName)
        ) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [name, alarmId, model, objectId]);

    const handleAddAlarm = () => {
        const alarmDetails = {
            name: name,
            alarmId: alarmId.value,
            alarmLabel: alarmId.label,
            type: selectedAlarmEventType.value as AlarmEventType,
            modelId: model.value,
        };

        if (selectedAlarmEventType.value === ALARM_TYPE.SPECIFIC) {
            let objectType = props.assetData.filter((item) => {
                return (
                    item instanceof ObjectTypeDetail &&
                    item.assetType === model.value &&
                    item.outputs.reduce(
                        (isAlarmPresent: boolean, outputDetails) =>
                            !!(
                                isAlarmPresent ||
                                (outputDetails.alarmId &&
                                    outputDetails.id === alarmId.id)
                            ),
                        false
                    )
                );
            })[0] as ObjectTypeDetail | undefined;
            alarmDetails.alarmId = alarmId.value.split(" (")[0];
            props.addAlarmToFunction({
                alarmDetails,
                functionType: props.functionType,
                objectType,
                objectOutputId: alarmId.id,
            });
        } else {
            props.addAlarmToFunction({
                alarmDetails,
                functionType: props.functionType,
            });
        }

        props.closeModal();
    };

    const handleEditAlarm = () => {
        if (props.alarmType && props.alarmType.assetName !== name) {
            props.editAlarmToFunction({
                alarmType: props.alarmType,
                functionType: props.functionType,
                newName: name,
            });
        }
        props.closeModal();
    };

    const handleModelChange = (value: any) => {
        updateModel(value);
        updateAlarmId({
            label: "",
            value: "",
            id: "",
        });
        if (!props.alarmsFromModel[value.value]) {
            props.fetchAlarmsForModel(value.value);
        }
    };

    const handleAlarmChange = (value: any) => {
        updateAlarmId(value);
    };

    const handleNameChange = (value: string) => {
        updateName(value);
        if (
            !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                value
            ) ||
            !value
        ) {
            updateErrorName(true);
            updateErrorNameText("Enter a Valid Name");
        } else if (checkForDuplicateAlarmNames(value)) {
            updateErrorName(true);
            updateErrorNameText("Alarm with same name already exists");
        } else {
            updateErrorName(false);
            updateErrorNameText("");
        }
    };

    const checkForDuplicateAlarmNames = (name: string) => {
        let isExists = false;
        props.assetData.forEach((asset) => {
            if (asset instanceof AlarmTypeDetail) {
                isExists = isExists || asset.assetName === name;
            }
        });
        return isExists;
    };

    useEffect(() => {
        let modelsListFromObject = {};
        props.assetData.forEach((assetTypeDetail) => {
            if (assetTypeDetail instanceof ObjectTypeDetail) {
                modelsListFromObject[assetTypeDetail.assetType] =
                    modelsListFromObject[assetTypeDetail.assetType]
                        ? modelsListFromObject[assetTypeDetail.assetType]
                        : [];
                assetTypeDetail.outputs.forEach((output) => {
                    if (output.alarmId) {
                        let alarmId = output.alarmId.split("/")[1]
                            ? output.alarmId.split("/")[1]
                            : output.alarmId;
                        modelsListFromObject[assetTypeDetail.assetType].push({
                            label: `${alarmId} (${output.name})`,
                            value: `${alarmId} (${output.name})`,
                            id: output.id,
                        });
                    }
                });
            }
        });
        setModelListForSpecificAlarms(modelsListFromObject);
    }, [props.assetData]);

    return (
        <div className="wrapper-create-alarm">
            <div className="form-wrapper">
                <div className="form-wrapper__child">
                    <FormGroup className="custom-form-group">
                        <Label for="model">
                            <sup>*</sup> Alarm Trigger Type
                        </Label>
                        <ReactSelect
                            styles={customReactSelectStyles}
                            isClearable={false}
                            options={alarmEventTypeDropDownList}
                            placeholder="Select Alarm Type"
                            value={selectedAlarmEventType}
                            getOptionLabel={(optionObj: any) => {
                                return `${optionObj.label}`;
                            }}
                            getOptionValue={(optionObj: any) => {
                                return `${optionObj.value}`;
                            }}
                            onChange={(value) => {
                                if (value) {
                                    setSelectedAlarmEventType(value);
                                    updateModel({
                                        value: "",
                                        label: "",
                                    });
                                    updateAlarmId({
                                        value: "",
                                        label: "",
                                        id: "",
                                    });
                                }
                            }}
                            isDisabled={props.mode !== MODAL_MODE.CREATE}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="modelName">
                            <sup>*</sup> Alarm Trigger Name
                        </Label>

                        <CustomABBInput
                            dataType="text"
                            value={name}
                            type="normal"
                            id="modelName"
                            placeholder="Enter the name of the Model"
                            instantValidation={true}
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                            validator={() =>
                                errorName
                                    ? {
                                          valid: false,
                                          text: errorNameText,
                                      }
                                    : { valid: true, text: "OK!" }
                            }
                            onValueChange={(value) => handleNameChange(value)}
                            disabled={props.mode === MODAL_MODE.VIEW}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="model">
                            <sup>*</sup> Model
                        </Label>
                        <ReactSelect
                            styles={customReactSelectStyles}
                            isClearable={false}
                            options={
                                selectedAlarmEventType.value ===
                                ALARM_TYPE.SPECIFIC
                                    ? Object.keys(
                                          modelListForSpecificAlarms
                                      ).map((modelId) => {
                                          return {
                                              label: modelId,
                                              value: modelId,
                                          };
                                      })
                                    : modelsDropDownList
                            }
                            placeholder="Select Model"
                            value={model}
                            getOptionLabel={(optionObj: any) => {
                                return `${optionObj.label}`;
                            }}
                            getOptionValue={(optionObj: any) => {
                                return `${optionObj.value}`;
                            }}
                            onChange={handleModelChange}
                            isDisabled={props.mode !== MODAL_MODE.CREATE}
                        />
                    </FormGroup>

                    <FormGroup className="custom-form-group">
                        <Label for="modelDescription">
                            <sup>*</sup> Alarm ID
                        </Label>

                        <Condition when={props.isAlarmsLoading}>
                            <Loader sizeClass="small" type="radial" />
                        </Condition>
                        <Condition when={!props.isAlarmsLoading}>
                            <ReactSelect
                                styles={customReactSelectStyles}
                                isClearable={false}
                                isDisabled={
                                    props.mode !== MODAL_MODE.CREATE ||
                                    !model.value ||
                                    !(selectedAlarmEventType.value ===
                                    ALARM_TYPE.SPECIFIC
                                        ? modelListForSpecificAlarms[
                                              model.value
                                          ]
                                        : props.alarmsFromModel[model.value]) ||
                                    props.isAlarmsLoading
                                }
                                options={
                                    model.value
                                        ? selectedAlarmEventType.value ===
                                              ALARM_TYPE.SPECIFIC &&
                                          modelListForSpecificAlarms[
                                              model.value
                                          ]
                                            ? modelListForSpecificAlarms[
                                                  model.value
                                              ]
                                                ? modelListForSpecificAlarms[
                                                      model.value
                                                  ]
                                                : []
                                            : props.alarmsFromModel[model.value]
                                            ? props.alarmsFromModel[
                                                  model.value
                                              ].map((alarm) => ({
                                                  value: alarm,
                                                  label: alarm,
                                              }))
                                            : []
                                        : []
                                }
                                placeholder="Select Alarm"
                                value={alarmId}
                                getOptionLabel={(optionObj: any) => {
                                    return `${optionObj.label}`;
                                }}
                                getOptionValue={(optionObj: any) => {
                                    return `${optionObj.value}`;
                                }}
                                onChange={handleAlarmChange}
                            />
                        </Condition>
                    </FormGroup>
                </div>
            </div>
            <ModalFooter className="popup-footer">
                <Condition when={props.mode !== MODAL_MODE.VIEW}>
                    <div>
                        <Button
                            text={"CANCEL"}
                            type="normal"
                            sizeClass="small"
                            isLoading={props.isUniqueTypeIdLoading}
                            onClick={() => props.closeModal()}
                        />
                    </div>
                    <div>
                        <Button
                            text={
                                props.mode === MODAL_MODE.CREATE ? "ADD" : "SET"
                            }
                            type="primary-blue"
                            sizeClass="small"
                            isLoading={props.isUniqueTypeIdLoading}
                            onClick={() =>
                                props.mode === MODAL_MODE.CREATE
                                    ? handleAddAlarm()
                                    : handleEditAlarm()
                            }
                            disabled={isSubmitDisabled}
                        />
                    </div>
                </Condition>
            </ModalFooter>
        </div>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        assetList: state.modelsPage.objectTypeList,
        activeAsset: state.modelsPage.activeAsset,
        canvasController: state.configurationTool.canvasController,
        modelDetails:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails,
        activeModel: state.modelsPage.activeModel,
        updateActiveModel: state.modelsPage.computeModels,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        configurationToolMode: state.configurationTool.mode,
        updatedJson: state.configurationTool.json,
        overAllSeverityFunctionId:
            state.configurationTool.overallSeverityFunctionId,
        supportedConditionMonitorList:
            state.supportedConditionMonitor.supportedConditionMonitorList,
        typeIdDisabledDuringImport:
            state.exportAsset.typeIdDisabledDuringImport,
        dependantLibraries: state.exportAsset.dependantLibraries,
        passwordDetails: state.exportAsset.passwordDetails,
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
        isUniqueTypeIdLoading: state.modelsPage.isUniqueTypeIdLoading,
        isUniqueTypeId: !state.modelsPage.isTypeIdExistError,
        modelsForAlarms: state.configurationTool.modelsForTrigger,
        alarmsFromModel: state.configurationTool.alarmsForModels,
        isAlarmsLoading: state.loader.getAlarmsForModelsLoading.isLoading,
        assetData: state.configurationTool.json.assetData,
    };
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        closeModal: () => {
            dispatch(hideModal());
        },
        setAlarm: (payload: any) => {
            dispatch(setNewAlarmDetails(payload));
        },
        fetchAlarmsForModel: (modelId: string) => {
            dispatch(fetchAlarmsForModels({ modelId }));
        },
        addAlarmToFunction: (payload: AddAlarmToFunctionPayload) => {
            dispatch(addAlarmTriggerToFunction(payload));
        },
        editAlarmToFunction: (payload: EditAlarmToFunctionPayload) => {
            dispatch(editAlarmTriggerToFunction(payload));
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAlarmForm);
