import {
    getFunctionVersionsType,
    ImportFilePayload,
    ImportModalPayload,
    FunctionsLibraries,
    TypesWithLibraries,
    PayloadForTypeLevelImport,
    functionVersionType,
} from './types';
import { ActionTypes } from '../actionTypes';
import { AssetType } from '../../transformers/AssetType';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { UpdateActiveFunctionType, IGetSelectedFunctionPayload } from '../function/types';
import { UI } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import { CreateFunctionAssetDetails } from '../function/action';

export function getFunctionVersions(payload: getFunctionVersionsType) {
    return <const>{
        type: ActionTypes.GET_FUNCTION_VERSIONS,
        payload: payload,
    };
}

export function storeFunctionsVersions(payload: any) {
    return <const>{
        type: ActionTypes.GET_FUNCTION_VERSIONS_SUCCESS,
        payload: payload,
    };
}

export function updateFunctionListToGetJson(payload: {
    functionDetails: UpdateActiveFunctionType[];
    checkboxValue: boolean;
}) {
    return <const>{
        type: ActionTypes.UPDATE_FUNCTION_LIST_TO_GET_JSON,
        payload: payload,
    };
}

export function getFunctionJson(payload: any) {
    return <const>{
        type: ActionTypes.GET_FUNCTION_JSON,
        payload: payload,
    };
}

export function clearSelectedFunctions() {
    return <const>{
        type: ActionTypes.CLEAR_SELECTED_FUNCTIONS,
    };
}

export function importFunctions(payload: { filesToBeImported: any }) {
    return <const>{
        type: ActionTypes.IMPORT_FUNCTIONS,
        payload: payload,
    };
}

export function saveModelsToExport(payload: any) {
    return <const>{
        type: ActionTypes.SAVE_MODELS_TO_EXPORT,
        payload: payload,
    };
}

export function exportModels(payload: {
    mode: 'MODEL' | 'OBJECT' | 'MULTIPLE_OBJECTS';
    selectedModalDetails: [];
}) {
    return <const>{
        type: ActionTypes.EXPORT_MODELS,
        payload: payload,
    };
}

export function removedFromDropDown(payload: any) {
    return <const>{
        type: ActionTypes.REMOVED_FROM_DROPDOWN,
        payload: payload,
    };
}

export function toggleModelSpinner(payload: boolean) {
    return <const>{
        type: ActionTypes.TOGGLE_SPINNER_MODAL,
        payload: payload,
    };
}

export function showSummaryModal(payload: {
    summaryModalDetails: {
        showSummaryModal: boolean;
        mode: 'IMPORT' | 'EXPORT' | '';
        route: 'FUNCTIONS' | 'TYPES' | '';
    };
}) {
    return <const>{
        type: ActionTypes.SHOW_SUMMARY_MODAL,
        payload: payload,
    };
}

export function closeSummaryModal() {
    return <const>{
        type: ActionTypes.CLOSE_SUMMARY_MODAL,
    };
}

export function computeModelsForExport(payload: {
    objectRef: string;
    asset: AssetType;
    uniqueTypeId: string;
}) {
    return <const>{
        type: ActionTypes.COMPUTE_MODELS_API_FOR_EXPORT,
        payload: payload,
    };
}

export function saveModelsToState(payload: any) {
    return <const>{
        type: ActionTypes.COMPUTE_MODELS_API_FOR_EXPORT_SUCCESS,
        payload: payload,
    };
}

export const setImportModals = (val: boolean) => {
    return <const>{
        type: ActionTypes.SET_IMPORT_MODAL,
        payload: val,
    };
};

export const importModals = (
    val: {
        model: ComputeModelToJson;
        functionTypes: object[];
    }[]
) => {
    return <const>{
        type: ActionTypes.IMPORT_MODALS,
        payload: val,
    };
};

export const importSingleType = (val: {
    zippedModel: { functionTypes: []; model: ComputeModelToJson };
    associatedObjectType: {
        model: string | null;
        typeId: string | null;
        version: string | null;
    };
}) => {
    return <const>{
        type: ActionTypes.IMPORT_FOR_SINGLE_TYPE,
        payload: val,
    };
};

export const updateSingleImportFunctionTypes = (functionTypes: object[]) => {
    return <const>{
        type: ActionTypes.UPDATE_SINGLE_IMPORT_FUNC_TYPES,
        payload: functionTypes,
    };
};

export const handleFilesToBeImported = (payload: { filesToBeImported: any }) => {
    return <const>{
        type: ActionTypes.HANDLE_FILES_TO_BE_IMPORTED,
        payload: payload,
    };
};

export const setImportValidationStatus = (isValid: boolean) => {
    return <const>{
        type: ActionTypes.SET_IMPORT_VALIDATION_STATUS,
        payload: isValid,
    };
};

export const handleFilesImport = (payload: ImportFilePayload) => {
    return <const>{
        type: ActionTypes.HANDLE_FILES_IMPORT,
        payload,
    };
};

export const showImportModal = (payload: ImportModalPayload) => {
    return <const>{
        type: ActionTypes.SHOW_IMPORT_MODAL,
        payload,
    };
};

export const hideImportModal = () => {
    return <const>{
        type: ActionTypes.HIDE_IMPORT_MODAL,
    };
};

export const loadChosenFileData = (targetFileData?: FileList) => {
    return <const>{
        type: ActionTypes.LOAD_CHOSEN_FILE_DATA,
        payload: targetFileData,
    };
};

export const storeFilesToImportInReducer = (payload: {
    filesToBeImported: (FunctionsLibraries | TypesWithLibraries)[];
    dependantLibrariesForTypes: any[];
}) => {
    return <const>{
        type: ActionTypes.LOAD_CHOSEN_FILE_DATA_SUCCESS,
        payload,
    };
};

export const addToSelectedItemForImport = (selectedItem: any) => {
    return <const>{
        type: ActionTypes.ADD_TO_SELECTED_ITEMS_FOR_IMPORT,
        payload: selectedItem,
    };
};

export const clearSelectedItemForImport = () => {
    return <const>{
        type: ActionTypes.CLEAR_SELECTED_ITEMS_FOR_IMPORT,
    };
};

export const removeFromSelectedItemForImport = (selectedItem: any) => {
    return <const>{
        type: ActionTypes.REMOVE_FROM_SELECTED_ITEMS_FOR_IMPORT,
        payload: selectedItem,
    };
};

export const toggleTypeIdDuringImport = (payload: {
    showDisabled: boolean;
    modelDetails: ComputeModelToJson['properties']['model'] | {};
}) => {
    return <const>{
        type: ActionTypes.TOGGLE_TYPE_ID_DURING_IMPORT,
        payload,
    };
};

export const setDetailsForTypeLevelImport = (payload: PayloadForTypeLevelImport) => {
    return <const>{
        type: ActionTypes.SET_DETAILS_FOR_TYPE_LEVEL_IMPORT,
        payload,
    };
};
export const handleSelectAllForGenericFuncExport = (isSelected: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_SELCTALL_FOR_GENERIC_FUNCTION_EXPORT,
        payload: isSelected,
    };
};

export const handleTypesImport = (zippedModels: UI.SceZippedModels) => {
    return <const>{
        type: ActionTypes.HANDLE_TYPES_IMPORT,
        payload: zippedModels,
    };
};

export function getSelectedFunctionTypeForExport(payload: IGetSelectedFunctionPayload) {
    return {
        type: ActionTypes.GET_SELECTED_FUNCTION_TYPE_FOR_EXPORT,
        payload,
    };
}

export function updateSelectedFunctionTypeForExport(payload: {
    typeDetails: CreateFunctionAssetDetails;
    activeLibId?: string;
}) {
    return {
        type: ActionTypes.UPDATE_SELECTED_FUNCTION_TYPE_FOR_EXPORT,
        payload,
    };
}

export function resetSelectedFunctionTypeForExport() {
    return {
        type: ActionTypes.RESET_SELECTED_FUNCTION_TYPE_FOR_EXPORT,
    };
}

export function changeZipFileNameForExport(payload: { fileName: string }) {
    return {
        type: ActionTypes.CHANGE_ZIP_NAME_FOR_MODEL_EXPORT,
        payload,
    };
}

export function updateExportableModelsList(payload: {
    currentItem: ComputeModelToJson;
    type: 'Update' | 'Delete';
}) {
    return {
        type: ActionTypes.UPDATE_EXPORTABLE_MODELS_LIST,
        payload,
    };
}
