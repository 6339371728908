import React, { useState, useEffect, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import Flex from '../../shared/Flex';

import classnames from 'classnames';
import SearchBar from '../../SearchBar';

import './styles.scss';
import { DRAGGABLE_ITEMS } from '../../../utils/constants/appConstants';
import Condition from '../../shared/Condition';
import ToggleButtons from '../../ToggleButtons';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import { FunctionTypeDetailsWithMappings } from '../../../model/TableController/types';
import { IoTableTabsProps } from '..';
import { filterListData } from '../../../utils/helpers';
import _ from 'lodash';
import TabNavList from '../../TabNavList';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import EventTypeDetail from '../../../transformers/AssetType/EventType';
import { IODataTypes, IOTypes } from '../../Fabric/types';

const VARIABLE_TYPES = {
    functionOutputs: {
        value: 'functionOutputs',
        label: 'Function Outputs',
    },
    assetVariables: {
        value: 'assetVariables',
        label: 'Object Variables',
    },
    typeVariables: {
        value: 'typeVariables',
        label: 'Model Variables',
    },
    alarmOutputs: {
        value: 'alarmOutputs',
        label: 'Alarm Outputs',
    },
    eventOutputs: {
        value: 'eventOutputs',
        label: 'Event Outputs',
    },
};

const DragAbleItem = (props: {
    listObj: {
        outputAsset:
            | ObjectTypeDetail
            | FunctionTypeDetailsWithMappings
            | AlarmTypeDetail
            | EventTypeDetail;
        circleData: FunctionTypeDetailsWithMappings['outputs'][0] | ObjectTypeDetail['outputs'][0];
    };
    isEditMode: boolean;
}) => {
    console.log('DragAbleItem :', props.listObj);
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: DRAGGABLE_ITEMS.TABLE_VIEW.ioVariables.src,
            data: props.listObj,
        },
        canDrag: () => {
            return props.isEditMode;
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });
    return (
        <div className="flex-column flex-nowrap d-flex" ref={drag}>
            <Condition when={props.listObj.circleData.name}>
                {`${props.listObj.circleData.path} (${props.listObj.circleData.dataType})` || ''}
            </Condition>
        </div>
    );
};

export const RenderVariableList = (props: {
    list: ObjectTypeDetail['outputs'];
    outputAsset:
        | ObjectTypeDetail
        | FunctionTypeDetailsWithMappings
        | AlarmTypeDetail
        | EventTypeDetail;
    isEditMode: boolean;
}) => {
    return (
        <div className="flex-column flex-nowrap d-flex">
            {props.list.map((circleData, idx) => {
                return (
                    <DragAbleItem
                        listObj={{ circleData, outputAsset: props.outputAsset }}
                        key={idx}
                        isEditMode={props.isEditMode}
                    />
                );
            })}
        </div>
    );
};

const MappingVariables = (
    props: {
        functionList: FunctionTypeDetailsWithMappings[];
        objectList: ObjectTypeDetail[];
        alarmList: AlarmTypeDetail[];
        eventList: EventTypeDetail[];
    } & IoTableTabsProps
) => {
    const [assetList, updateAssetList] = useState(
        [] as
            | FunctionTypeDetailsWithMappings[]
            | ObjectTypeDetail[]
            | AlarmTypeDetail[]
            | EventTypeDetail[]
    );
    const [searchText, updateSearchText] = useState('');
    classnames('active-color');
    const [tabNavList] = useState([
        VARIABLE_TYPES.assetVariables.label,
        VARIABLE_TYPES.functionOutputs.label,
        VARIABLE_TYPES.typeVariables.label,
        VARIABLE_TYPES.alarmOutputs.label,
        // VARIABLE_TYPES.eventOutputs.label,
    ]);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() => {
        if (tabNavList[activeTabIndex] === VARIABLE_TYPES.assetVariables.label) {
            const objectTypeList = props.objectList.filter((item) => !item.isReferencedType);
            updateAssetList([...objectTypeList]);
            handleMappingListSearch(searchText);
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.functionOutputs.label) {
            updateAssetList(props.functionList);
            handleMappingListSearch(searchText);
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.typeVariables.label) {
            const referencedTypList = props.objectList.filter((item) => item.isReferencedType);
            updateAssetList([...referencedTypList]);
            handleMappingListSearch(searchText);
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.alarmOutputs.label) {
            updateAssetList(props.alarmList);
            handleMappingListSearch(searchText);
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.eventOutputs.label) {
            updateAssetList(props.eventList);
            handleMappingListSearch(searchText);
        }
    }, [props.objectList, props.functionList, props.tableData, activeTabIndex]);

    const handleMappingListSearch = (searchText: string) => {
        let filteredList: any[] = [];

        if (tabNavList[activeTabIndex] === VARIABLE_TYPES.assetVariables.label) {
            const objectTypeList = props.objectList.filter((item) => !item.isReferencedType);
            filteredList = objectTypeList.map((asset, index) => {
                const filterOutput = filterListData(asset.outputs, 'path', searchText);
                const finalAsset = { ...asset };
                finalAsset.outputs = filterOutput;
                return finalAsset;
            });
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.typeVariables.label) {
            const typeListData = props.objectList.filter((item) => item.isReferencedType);
            filteredList = typeListData.map((asset, index) => {
                const filterOutput = filterListData(asset.outputs, 'path', searchText);
                const finalAsset = { ...asset };
                finalAsset.outputs = filterOutput;
                return finalAsset;
            });
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.functionOutputs.label) {
            filteredList = props.functionList.map((asset, index) => {
                const filterOutput = filterListData(asset.outputs, 'path', searchText);
                const finalAsset = { ...asset };
                finalAsset.outputs = filterOutput;
                return finalAsset;
            });
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.alarmOutputs.label) {
            filteredList = props.alarmList.map((asset, index) => {
                const filterOutput = filterListData(
                    asset.outputs.filter((output) => output.dataType !== IODataTypes.ALARM),
                    'path',
                    searchText
                );
                const finalAsset = { ...asset };
                finalAsset.outputs = filterOutput;
                return finalAsset;
            });
        } else if (tabNavList[activeTabIndex] === VARIABLE_TYPES.eventOutputs.label) {
            filteredList = props.eventList.map((asset, index) => {
                const filterOutput = filterListData(
                    asset.outputs.filter((output) => output.dataType !== IODataTypes.EVENT),
                    'path',
                    searchText
                );
                const finalAsset = { ...asset };
                finalAsset.outputs = filterOutput;
                return finalAsset;
            });
        }

        updateSearchText(searchText);
        updateAssetList(filteredList);
    };

    const handleVariablesTabChange = (newIndex: number) => {
        setActiveTabIndex(newIndex);
    };

    return (
        <Flex className="wrapper-map-var" column>
            <Flex>
                <div className="map-var-text"> MAPPING VARIABLES </div>
            </Flex>

            <div className="wrapper-variable-tabs">
                <TabNavList
                    activeTab={activeTabIndex}
                    allowRemove={false}
                    errorFlag={false}
                    tabNavList={tabNavList}
                    onTabChange={(oldIndex: number, newIndex: number) =>
                        handleVariablesTabChange(newIndex)
                    }
                />
            </div>

            <Flex className="list-wrapper" column>
                <Flex>
                    <div className="map-var-search">
                        <SearchBar
                            placeholder=" Search variables"
                            value={searchText}
                            onChange={(val) => handleMappingListSearch(val)}
                        />
                    </div>
                </Flex>
                <Flex column className="variables-list">
                    {(assetList as Array<FunctionTypeDetailsWithMappings | ObjectTypeDetail>).map(
                        (asset) => {
                            return (
                                <RenderVariableList
                                    outputAsset={asset}
                                    list={asset.outputs}
                                    isEditMode={!props.inViewMode}
                                />
                            );
                        }
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default MappingVariables;
