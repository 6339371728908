import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Checkbox } from '@abb/abb-common-ux-react';

import '../style.scss';
import {
    addToSelectedItemForImport,
    removeFromSelectedItemForImport,
} from '../../../../store/exportAsset/actions';
import { FunctionsLibraries, TypesWithLibraries } from '../../../../store/exportAsset/types';
import { GENERIC_FUNCTIONS } from '../../../../utils/constants/appConstants';
import { StoreState } from '../../../../store';
import _ from 'lodash';

interface IAvailableItemForImport {
    itemData: FunctionsLibraries | TypesWithLibraries;
    isSelected: boolean;
    isFunctionSelected?: any;
    setSelectedLibraries?: any;
    setSelectedFunctions?: any;
    selectedLibraries?: any;
    selectedFunctions?: any;
    setImportValidationError: React.Dispatch<React.SetStateAction<string>>;
}

function AvailableItemForImport(
    props: IAvailableItemForImport &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const { itemData, setImportValidationError, isFunctionSelected, selectedItemsForImport } =
        props;
    const handleOnChange = () => {
        setImportValidationError('');
        if (
            props.itemData.hasOwnProperty('library') &&
            !(props.itemData as FunctionsLibraries).library
        ) {
            if (props.isSelected) {
                props.setSelectedFunctions([]);
            } else {
                props.setSelectedFunctions(props.itemData.functionTypes);
            }
        } else {
            if (props.isSelected) {
                //@ts-ignore
                props.setSelectedLibraries(
                    //@ts-ignore
                    [...props.selectedLibraries].filter((item) => item !== props.itemData)
                );
            } else {
                //@ts-ignore
                props.setSelectedLibraries([...props.selectedLibraries, props.itemData]);
            }
        }
    };

    const handleOnFunctionChange = (obj: any) => {
        setImportValidationError('');
        if (props.isFunctionSelected(obj)) {
            props.setSelectedFunctions(
                //@ts-ignore
                [...props.selectedFunctions].filter((item) => item !== obj)
            );
        } else {
            //@ts-ignore
            props.setSelectedFunctions([...props.selectedFunctions, obj]);
        }
    };

    return (
        <div className="available-item">
            <Checkbox
                sizeClass="small"
                label={
                    props.itemData.hasOwnProperty('library')
                        ? (itemData as FunctionsLibraries).library
                            ? (itemData as FunctionsLibraries).library.name
                            : GENERIC_FUNCTIONS
                        : (itemData as TypesWithLibraries).model.properties.model.name.value
                }
                value={props.isSelected}
                onChange={handleOnChange}
            />
            {itemData.hasOwnProperty('library') &&
                !(itemData as FunctionsLibraries).library &&
                itemData.functionTypes.length > 0 && (
                    <div className="available-functions">
                        {itemData.functionTypes.map((functionType: any) => (
                            <Checkbox
                                sizeClass="small"
                                label={functionType.name}
                                value={props.isSelected || props.isFunctionSelected(functionType)}
                                onChange={() => handleOnFunctionChange(functionType)}
                            />
                        ))}
                    </div>
                )}
        </div>
    );
}
const mapStateToProps = (state: StoreState) => {
    return {
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addToSelectedImportItems: (item: any) => {
            dispatch(addToSelectedItemForImport(item));
        },
        removeFromSelectedImportItems: (item: any) => {
            dispatch(removeFromSelectedItemForImport(item));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableItemForImport);
