import React, { Fragment } from 'react';
import './style.scss';

const NotFound = () => {
    return (
        <Fragment>
            <div>
                <div className="middle">
                    <h1 className="topText">404 NOT FOUND</h1>
                </div>
                <div className="bottomRight">
                    <p>Ⓒ Copyright 2020 ABB</p>
                </div>
            </div>
        </Fragment>
    );
};

export { NotFound };
