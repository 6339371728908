import React, { ReactNode } from "react";

interface AppProps {
    children: ReactNode;
    className?: string;
}

function Tbody(props: AppProps) {
    return <tbody className={props.className}>{props.children}</tbody>;
}

export default Tbody;
