import { SettingsState, SettingsLeftNavOptions, Actions } from './types';
import { ActionTypes } from '../actionTypes';

const initialState: SettingsState = {
    activeSettingTab: SettingsLeftNavOptions.CONNECT_MODELS,
    infoModelInstance: undefined,
    identityModel: [],
    newDefinedType: undefined,
    showModalProgress: false,
};

const settingReducer = (state = initialState, action: Actions): SettingsState => {
    switch (action.type) {
        case ActionTypes.SET_ACTIVE_SETTING_TAB: {
            return { ...state, activeSettingTab: action.payload };
        }
        case ActionTypes.UPDATE_INFO_MODEL_INSTANCE: {
            const infoModelInstance = action.payload.supportedModel;
            const identityModel = action.payload.identityModel;
            return {
                ...state,
                infoModelInstance,
                identityModel,
            };
        }
        case ActionTypes.UPDATE_NEW_DEFINITION_TYPE: {
            const newDefinedType = action.payload;
            return {
                ...state,
                newDefinedType,
            };
        }
        case ActionTypes.SHOW_PROGRESS: {
            const showModalProgress = action.payload;
            return {
                ...state,
                showModalProgress,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default settingReducer;
