import React, {
    useState,
    useCallback,
    useRef,
    useMemo,
    useEffect,
} from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ModalFooter } from "reactstrap";
import { Input, Button } from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../store";
import { validatePasswordRegex } from "../../utils/helpers";
import { PASSWORD_MSG } from "../../utils/constants/appConstants";
import { handleChangeLibraryPassword } from "../../store/function/action";
import {
    LibraryAndNodePayload,
    IChangeLibPasswordPayload,
} from "../../store/function/types";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../utils/constants/appConstants";
import CustomABBInput from "../CustomABBInput";

interface IChangeLibraryPassword {
    libraryDetail: LibraryAndNodePayload;
    submitBtnText: string;
}
const ChangeLibraryPassword = ({
    libraryDetail,
    submitBtnText,
    handleChangeLibraryPassword,
    isLoading,
    libPasswordError,
}: IChangeLibraryPassword &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>) => {
    const confirmPasswordRef: React.RefObject<Input> = useRef(null);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    useEffect(() => {
        console.log(libPasswordError, "libpass");
        if (libPasswordError.length > 0 && isLoading) {
            setErrorMessage(libPasswordError);
            clearConfirmPasswordFieldOnError();
        }
    }, [libPasswordError, isLoading]);

    const isSubmitBtnDisabled = useMemo(() => {
        return (
            currentPassword.length === 0 ||
            newPassword.length === 0 ||
            confirmPassword.length === 0
        );
    }, [currentPassword, newPassword, confirmPassword]);

    const handleFieldValueUpdate = useCallback(
        (
            value: string,
            fieldName: "currentPassword" | "newPassword" | "confirmPassword"
        ) => {
            if (errorMessage.length > 0) {
                setErrorMessage("");
            }
            switch (fieldName) {
                case "currentPassword": {
                    setCurrentPassword(value.trim());
                    setCurrentPasswordError("");
                    break;
                }
                case "newPassword": {
                    setNewPassword(value.trim());
                    setNewPasswordError("");
                    break;
                }
                case "confirmPassword": {
                    setConfirmPassword(value.trim());
                    setConfirmPasswordError("");
                    break;
                }
                default: {
                    console.log("invalid case");
                }
            }
        },
        [currentPassword, newPassword, confirmPassword]
    );

    const clearConfirmPasswordFieldOnError = useCallback(() => {
        setConfirmPassword("");
        if (confirmPasswordRef && confirmPasswordRef.current) {
            confirmPasswordRef.current.focus();
        }
        // setErrorMessage("")
    }, [confirmPassword, newPassword]);

    const validatePasswords = useCallback(() => {
        let isValid = true;
        if (!currentPassword || !newPassword || !confirmPassword) {
            isValid = false;
        }
        if (!validatePasswordRegex(currentPassword)) {
            isValid = false;
            setCurrentPasswordError(PASSWORD_MSG.MIN_REQUIREMENT);
        }
        if (!validatePasswordRegex(newPassword)) {
            isValid = false;
            setNewPasswordError(PASSWORD_MSG.MIN_REQUIREMENT);
        }
        if (newPassword === currentPassword) {
            isValid = false;
            setNewPasswordError(PASSWORD_MSG.ENTER_NEW_PASSWORD);
        }
        if (newPassword !== confirmPassword) {
            isValid = false;
            setConfirmPasswordError(PASSWORD_MSG.MISMATCH);
            clearConfirmPasswordFieldOnError();
        }
        if (isValid) {
            setErrorMessage("");
        }
        return isValid;
    }, [currentPassword, newPassword, confirmPassword]);

    const handleSubmit = useCallback(
        (
            e:
                | React.FormEvent<HTMLFormElement>
                | React.MouseEvent<
                      HTMLAnchorElement | HTMLButtonElement,
                      MouseEvent
                  >
        ) => {
            e.preventDefault();
            let returnVal = true;
            returnVal = validatePasswords();
            if (returnVal) {
                handleChangeLibraryPassword({
                    libDetails: libraryDetail,
                    password: currentPassword,
                    newPassword: newPassword,
                });
            }
            setErrorMessage("");
        },
        [currentPassword, newPassword, confirmPassword]
    );

    return (
        <div className="wrapper-change-library-password">
            <form
                className="change-library-password-form"
                onSubmit={handleSubmit}
            >
                <CustomABBInput
                    id="libraryName"
                    className="change-password-input-item"
                    label="Library Name"
                    value={libraryDetail.root}
                    dataType="text"
                    disabled={true}
                />
                <CustomABBInput
                    id="currentPassword"
                    className="change-password-input-item"
                    label="Current Password"
                    placeholder="Enter Current Password"
                    value={currentPassword}
                    onValueChange={(value) =>
                        handleFieldValueUpdate(value, "currentPassword")
                    }
                    dataType={showCurrentPassword ? "text" : "password"}
                    icon={!showCurrentPassword ? "abb/view" : "abb/hide"}
                    onIconClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                    }
                    showValidationBarWhenInvalid={true}
                    instantValidation={true}
                    showValidationIconWhenInvalid={true}
                    validator={() =>
                        currentPasswordError.length > 0
                            ? { valid: false, text: currentPasswordError }
                            : {
                                  valid: true,
                                  text: "OK!",
                              }
                    }
                />
                <CustomABBInput
                    id="newPassword"
                    className="change-password-input-item"
                    label="New Password"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onValueChange={(value) =>
                        handleFieldValueUpdate(value, "newPassword")
                    }
                    dataType={showNewPassword ? "text" : "password"}
                    icon={!showNewPassword ? "abb/view" : "abb/hide"}
                    onIconClick={() => setShowNewPassword(!showNewPassword)}
                    showValidationBarWhenInvalid={true}
                    instantValidation={true}
                    showValidationIconWhenInvalid={true}
                    validator={() =>
                        newPasswordError.length > 0
                            ? { valid: false, text: newPasswordError }
                            : {
                                  valid: true,
                                  text: "OK!",
                              }
                    }
                />
                <CustomABBInput
                    ref={confirmPasswordRef}
                    id="confirmPassword"
                    className="change-password-input-item"
                    label="Confirm New Password"
                    placeholder="Enter New Password"
                    value={confirmPassword}
                    onValueChange={(value) =>
                        handleFieldValueUpdate(value, "confirmPassword")
                    }
                    dataType="password"
                    showValidationBarWhenInvalid={true}
                    instantValidation={true}
                    showValidationIconWhenInvalid={true}
                    validator={() =>
                        confirmPasswordError.length > 0
                            ? { valid: false, text: confirmPasswordError }
                            : {
                                  valid: true,
                                  text: "OK!",
                              }
                    }
                />
            </form>

            <ModalFooter>
                <div className="footer-part">
                    {errorMessage.length > 0 && (
                        <div className="error-msg">{errorMessage}</div>
                    )}
                    <Button
                        className="change-btn"
                        text={submitBtnText}
                        type="primary-blue"
                        sizeClass="small"
                        disabled={isSubmitBtnDisabled}
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    />
                </div>
                <div>
                    <p>
                        <span className="note">{NOTE_MESSAGE}</span>
                        <span className="admin_help_message">
                            {ADMIN_HELP_MESSAGE}
                        </span>
                    </p>
                </div>
            </ModalFooter>
        </div>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        libPasswordError: state.functions.libPasswordError,
        isLoading: state.functions.changePasswordLoading,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleChangeLibraryPassword: (payload: IChangeLibPasswordPayload) =>
            dispatch(handleChangeLibraryPassword(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeLibraryPassword);
