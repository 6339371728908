import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { StoreState } from '../../store';
import { hideNotificationModal } from '../../store/notificationModal/action';
import {
    Notification,
    NotificationData,
    NotificationState,
    Button,
    Icon,
} from '@abb/abb-common-ux-react';
import { NOTIFICATION_MODAL_STATUS } from '../../utils/constants/appConstants';

interface NotificationModalProps {}

export const NotificationModal = (
    props: NotificationModalProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleCloseModal = (event: React.SyntheticEvent) => {
        setShowDetails(false);
        props.hideNotificationModal();
        console.log('hideNotification Modal called');
    };

    const toggleShowDetails = () => {
        if (showDetails === false) {
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    };
    const NotificationState: NotificationState = {
        isClosing: false,
    };

    const Data: NotificationData = {
        id: '1',
        type: 'banner',
        discreet: false,
        severity:
            props.notificationModalState.resultStatus === NOTIFICATION_MODAL_STATUS.WARNING
                ? 'warn'
                : 'success',
        timeout: 5000,
        text: props.notificationModalState.title,
        isClosing: NotificationState.isClosing,
    };

    return props.notificationModalState.show ? (
        props.notificationModalState.type === 'confirmation' ? (
            <div
                id="confirmation-modal"
                className={`wrapper-notification-text  wrapper-notification-text--${props.notificationModalState.resultStatus}`}
            >
                <div className="wrapper-notification-data">
                    {props.notificationModalState.resultStatus ===
                    NOTIFICATION_MODAL_STATUS.WARNING ? (
                        <div className="notification-icon">!</div>
                    ) : (
                        <Icon
                            name="abb/error-circle-1"
                            color={'#f03040'}
                            className="circle-error-icon"
                            sizeClass="medium"
                        />
                    )}

                    <div className="notification-modal-text">
                        {props.notificationModalState.title}
                    </div>
                </div>
                <div className="yes-no-btn">
                    <Fragment>
                        <div className="confirmation-btn">
                            {props.notificationModalState.details.length > 0 && (
                                <div onClick={toggleShowDetails}>
                                    {' '}
                                    <span className="details">
                                        {!showDetails ? 'View More Details' : 'Hide More Details'}
                                    </span>
                                </div>
                            )}
                            <div className="ok-button">
                                <Button
                                    type="primary-blue"
                                    text="OK"
                                    sizeClass="small"
                                    onClick={handleCloseModal}
                                />
                            </div>
                        </div>
                        {showDetails ? (
                            <div className="list-details">
                                {props.notificationModalState.details.map((data, index) => {
                                    return <div key={index}>-{data}</div>;
                                })}
                            </div>
                        ) : (
                            <></>
                        )}
                    </Fragment>
                </div>
            </div>
        ) : (
            <div className="wrapper-notification">
                <Notification
                    data={Data}
                    removalHandler={{
                        remove: (id) => {
                            console.log('hideNotificationModal banner');
                            props.hideNotificationModal();
                        },
                    }}
                    className=" wrapper-notification"
                />
            </div>
        )
    ) : (
        <Fragment></Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        notificationModalState: state.notificationModal,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        hideNotificationModal: () => dispatch(hideNotificationModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
