import { canvasPrimaryBorderColor, publishIconSize } from "../../UiConfig";
import { fabric } from "fabric";
import { SelfPositioningChild } from "../../../NodeController/types";

const publishIconPath = "M3,13H6v2H1V1H6V3H3ZM15,8,9,3.5V6H5v4H9v2.5Z";
const scale = publishIconSize / 12;

export class FabricPublishIcon extends fabric.Path
    implements SelfPositioningChild {
    data: SelfPositioningChild["data"];
    constructor(group: fabric.Group) {
        super(publishIconPath, {
            stroke: canvasPrimaryBorderColor,
            fill: canvasPrimaryBorderColor,
            originX: "left",
            originY: "bottom",
            scaleX: scale,
            scaleY: scale,
            selectable: false,
            hoverCursor: "default",
            top: group.getPointByOrigin("right", "top").y || 0,
            left: (group.getPointByOrigin("right", "top").x || 0) + 5,
            name: "publish"
        });

        this.data = {
            rePosition: () => {
                this.left = (group.getPointByOrigin("right", "top").x || 0) + 5;
                this.top = group.getPointByOrigin("right", "top").y || 0;
                this.scaleX = (group.scaleX || 1) * scale;
                this.scaleY = (group.scaleX || 1) * scale;
                this.setCoords();
            },
            tooltip: "Publish"
        };
    }
}
