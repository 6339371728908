import _ from 'lodash';
import { takeLatest, select, put } from 'redux-saga/effects';
import { ActionTypes } from '../../actionTypes';
import { StoreState } from '../..';
import {
    createConfigurationToolJson,
    updateConfigurationToolMode,
    markComputeModelDirty,
    updateOverAllSeverityFunctionStatus,
    updateOverallSeverityFunctionId,
    getOriginalFunctionDataFromJson,
    getLatestFunctionsForType,
    stopBacgroundTaskGetOriginalFunctionTypes,
} from '../../configurationTool/actions';
import {
    getComputeModelsStore,
    changeActiveModel,
    saveComputeModelStore,
    deleteComputeModelStore,
    addNewComputeModel,
    handleIsFunctionUpdatesAvailable,
} from '../actions';
import {
    CONFIGURATION_TOOL_MODE,
    ROUTE_PATHNAME,
    NOTIFICATION_MODAL_STATUS,
    SAVE_OPTIONS,
} from '../../../utils/constants/appConstants';
import {
    updateJsonWithOriginalFunctionDetails,
    abbSDKGetErrorMessage,
} from '../../../utils/helpers';
import { showNotificationModal } from '../../notificationModal/action';
import { checkIsFunctionUpdatesAvailable } from '../../../routes/Functions/helper';

function* addNewModelSaga(action: ReturnType<typeof addNewComputeModel>) {
    // Add new model has a blanks canvas/table initially
    yield put(
        createConfigurationToolJson({
            json: { assetData: [], connectionData: [] },
            mode: CONFIGURATION_TOOL_MODE.CREATE,
        })
    );
    yield put(markComputeModelDirty());
}

function* getComputeModelsStoreSaga(action: ReturnType<typeof getComputeModelsStore>) {
    console.log('inside get computeModels store saga');
    const state: StoreState = yield select();
    try {
        const { activeModelId, computeModels, currentRoutePath } = action.payload;
        const activeModel = computeModels.find((model) => model.objectId === activeModelId);
        if (activeModel) {
            const isOverallSeverityFunctionPresent = activeModel.isOverAllSeverityFunctionPresent;
            if (isOverallSeverityFunctionPresent) {
                yield put(updateOverAllSeverityFunctionStatus(true));
            } else {
                yield put(updateOverAllSeverityFunctionStatus(false));
            }
            if (activeModel.overAllSeverityFunctionId) {
                yield put(updateOverallSeverityFunctionId(activeModel.overAllSeverityFunctionId));
            } else {
                yield put(updateOverallSeverityFunctionId(''));
            }

            // fetch orignal function types to compare conditions.
            yield put(stopBacgroundTaskGetOriginalFunctionTypes());
            yield put(
                getOriginalFunctionDataFromJson({
                    json: activeModel,
                    currentRoutePath: ROUTE_PATHNAME.HOME,
                })
            );
        } else {
            // handle the empty computeModels case.
            if (computeModels.length === 0) {
                if (currentRoutePath && window.location.pathname.includes(currentRoutePath)) {
                    yield put(
                        createConfigurationToolJson({
                            json: { assetData: [], connectionData: [] },
                        })
                    );
                }
            }
        }
    } catch (error) {
        yield put(
            showNotificationModal({
                type: 'confirmation',
                title: 'Api Error',
                details: [abbSDKGetErrorMessage(error)],
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
            })
        );
    }
}

function* changeActiveModelSaga(action: ReturnType<typeof changeActiveModel>) {
    const { modelId } = action.payload;
    const state: StoreState = yield select();
    const { computeModels } = state.modelsPage;

    const newModel = computeModels.byId[modelId];
    const { canvasController } = state.configurationTool;
    if (canvasController) {
        canvasController.resetzoom();
    }
    if (newModel) {
        yield put(stopBacgroundTaskGetOriginalFunctionTypes());
        yield put(
            getOriginalFunctionDataFromJson({
                json: newModel,
                currentRoutePath: ROUTE_PATHNAME.HOME,
            })
        );
    }
}

function* cancelUnsavedModelChangesSaga() {
    const state: StoreState = yield select();
    const { activeModel } = state.modelsPage;
    if (state.configurationTool.isDirty) {
        yield put(
            createConfigurationToolJson({
                json: activeModel.modelInstance.json,
                mode: CONFIGURATION_TOOL_MODE.VIEW,
            })
        );
        const isFunctionUpdateAvailable = checkIsFunctionUpdatesAvailable(
            activeModel.modelInstance.json
        );
        yield put(handleIsFunctionUpdatesAvailable(isFunctionUpdateAvailable));
    } else {
        yield put(updateConfigurationToolMode({ mode: CONFIGURATION_TOOL_MODE.VIEW }));
    }
}

function* saveComputeModelStoreSaga(action: ReturnType<typeof saveComputeModelStore>) {
    const { computeModel } = action.payload;
    const state: StoreState = yield select();
    const originalFunctionUsed = state.configurationTool.originalFunctionTypesUsed;
    const updatedJson = updateJsonWithOriginalFunctionDetails({
        json: computeModel.json,
        addIsDirtyToOldCondtions: false,
        originalFunctionUsed: originalFunctionUsed,
    });
    if (computeModel.saveOption && computeModel.saveOption === SAVE_OPTIONS.PUBLISH) {
        yield put(
            getLatestFunctionsForType({
                activeModel: { ...computeModel, json: updatedJson },
                currentRoutePath: ROUTE_PATHNAME.HOME,
            })
        );
    }
    yield put(updateConfigurationToolMode({ mode: CONFIGURATION_TOOL_MODE.VIEW }));
}

function* deleteComputeModelStoreSaga(action: ReturnType<typeof deleteComputeModelStore>) {
    const { newModelId } = action.payload;
    const { modelsPage }: StoreState = yield select();
    const modelInstance = modelsPage.computeModels.byId[newModelId];

    if (modelInstance) {
        yield put(createConfigurationToolJson({ json: modelInstance.json }));
    } else {
        yield put(
            createConfigurationToolJson({
                json: { assetData: [], connectionData: [] },
            })
        );
    }
}

export default [
    takeLatest(ActionTypes.ADD_NEW_COMPUTE_MODEL, addNewModelSaga),
    takeLatest(ActionTypes.GET_COMPUTE_MODELS_STORE, getComputeModelsStoreSaga),
    takeLatest(ActionTypes.CHANGE_ACTIVE_MODEL, changeActiveModelSaga),
    takeLatest(ActionTypes.CANCEL_UNSAVED_MODEL_CHANGES, cancelUnsavedModelChangesSaga),
    takeLatest(ActionTypes.SAVE_COMPUTE_MODEL_STORE, saveComputeModelStoreSaga),
    takeLatest(ActionTypes.DELETE_COMPUTE_MODEL_STORE, deleteComputeModelStoreSaga),
];
