import * as React from "react";
import {
    AppContainer,
    AppContent,
    AppLeftPane,
    CollapsibleContainer,
    Collapsible,
    AppMainContent,
} from "@abb/abb-common-ux-react";
import RenderHTML from "./RenderHTML";
import { HELP_PAGE } from "./HelpPageConfig";
import { IHelpPage as HelpageModel } from "./IHelpPage";

export class HelpPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visibility: "full",
            collapsed: true,
            theme: "light",
            URL: HELP_PAGE[0].path,
        };
    }

    extractNode(node: HelpageModel[]) {
        let leaf = node.map((leafNode) => {
            return (
                <Collapsible
                    title={leafNode.title}
                    onClick={() => this.setState({ URL: leafNode.path })}
                    itemId={`${leafNode.path }`}
                >
                    {leafNode.leaf && (
                        <div style={{ marginLeft: "10px" }}>
                            {" "}
                            {this.extractNode(leafNode.leaf)}{" "}
                        </div>
                    )}
                </Collapsible>
            );
        });
        return leaf && <CollapsibleContainer>{leaf}</CollapsibleContainer>;
    }

    render() {
        return (
            <React.Fragment>
                <AppContainer
                    theme={this.state.theme}
                    style={{
                        overflow: "auto",
                        height: "1200px",
                        marginTop: "30px",
                        width: "99%",
                    }}
                >
                    <AppContent style={{ width: "100%" }}>
                        <AppLeftPane
                            showSearchBarInFullMode={this.state.searchBar}
                            icon={"abb/menu"}
                            showRightBorder={this.state.border}
                            onSearchValueChange={(value) =>
                                this.setState({ leftPaneFilter: value })
                            }
                            visibility={this.state.visibility}
                            onClickToggleVisibility={() =>
                                this.setState({
                                    collapsed: !this.state.collapsed,
                                    visibility: this.state.collapsed
                                        ? "full"
                                        : "hidden",
                                })
                            }
                        >
                            {this.extractNode(HELP_PAGE)}
                        </AppLeftPane>
                        <AppMainContent
                            style={{
                                backgroundColor:
                                    this.state.theme === "light"
                                        ? "white"
                                        : "black",
                                padding: "24px",
                                margin: "8px",
                            }}
                        >
                            <React.Fragment>
                                <RenderHTML URL={this.state.URL} />
                            </React.Fragment>
                        </AppMainContent>
                    </AppContent>
                </AppContainer>
            </React.Fragment>
        );
    }
}
