import React from "react";
import ReactDOM from "react-dom";
import "./transformers/ComputeModel/toJson/ComputeModelToJson";
import "./transformers/ComputeModel/fromJson/ComputeModelFromJson";

import "./assets/bootstrap/scss/bootstrap.scss";
import "./assets/bootstrap/color.scss";
import "./index.scss";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import App from "../src/containers/AppBoot/index";
import * as serviceWorker from "./serviceWorker";
// import { sce } from "./services/AbbSDK/lib";
import { sce, userServices } from "sce-engg-model-19.09";
import AppSettings from "./services/AppSettings";
import "./custom.css";
import "./styles.css";
// import "../node_modules/@abb/abb-common-ux-react/styles.css";

import { defaultValueValidation } from "./transformers/ComputeModel/validation/defaultValue";
import { IODataTypes } from "./components/Fabric/types";
import { WrapperContainer } from './containers/AppBoot/WrapperContainer';
// userServices.initialize(
//     AppSettings.clientId,
//     AppSettings.apiEndpoint,
//     AppSettings.tenant,
//     AppSettings.cacheLocation
// );

// userServices.handleWindowCallback();

//@ts-ignore
// window.config();

const renderApp = () => {
    userServices.initialize(AppSettings.cacheLocation === "localStorage" ? "localStorage" : "sessionStorage");
    sce.init(AppSettings.sceEngineeringApiUrl, AppSettings.productVariantName, true, AppSettings.permissions, AppSettings.AuthScheme);
    document.title = AppSettings.productName;
    ReactDOM.render(<WrapperContainer />, document.getElementById("root"));
};

// if (AppSettings.skipAuthentication) {
//     renderApp();
// } else {
if (window === window.parent && window === window.top) {
    // if (userServices.signIn()) {
    renderApp();
    // }
}
// }

