import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@abb/abb-common-ux-react';

import { restoreEdgeRequest, getEdgesActionRequest } from '../../../store/assets/actions';
import { StoreState } from '../../../store';
import { IEdgeList } from '../../../store/assets/types';
import Flex from '../../../components/shared/Flex';
import ButtonBar from '../ButtonBar';
import './style.scss';
import ConfigurationHeader from '../Header';
import { SettingsLeftNavOptions } from '../../../store/settings/types';

const RestoreEdgeTab = (
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
    const [edgeList, updateEdgeList] = useState<IEdgeList[]>([]);
    const [selectedEdge, updateSelectedEdge] = useState<IEdgeList>();

    useEffect(() => {
        props.getEdgesList();
    }, []);

    useEffect(() => {
        updateEdgeList(props.edgeList);
        if (props.edgeList && Array.isArray(props.edgeList) && props.edgeList.length > 0) {
            updateSelectedEdge(props.edgeList[0]);
        }
    }, [props.edgeList]);

    return (
        <div className="wrapper-connect-models">
            <ConfigurationHeader headerText={SettingsLeftNavOptions.RESTORE_TYPES} />
            <div className="connect-models-form">
                <div className="parent-asset-config-form">
                    <div className="asset-config-form restore-edge-form ">
                        <Flex fill>Edges</Flex>
                        <Flex fill>
                            <select
                                className="selection"
                                value={
                                    selectedEdge !== null && selectedEdge !== undefined
                                        ? selectedEdge.objectId
                                        : ''
                                }
                                onChange={(e) => {
                                    const selectedObject = edgeList.find(
                                        (x) => x.objectId === e.currentTarget.value
                                    );
                                    if (selectedObject) {
                                        updateSelectedEdge(selectedObject);
                                    }
                                }}
                            >
                                {edgeList.map((item) => (
                                    <option key={item.objectId} value={item.objectId}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Flex>
                    </div>
                </div>
            </div>
            <ButtonBar className="restore-edge-btn-bar">
                <Button
                    text="Restore"
                    sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    className="customDeleteButton"
                    style={{ marginLeft: `12px` }}
                    onClick={() => {
                        if (selectedEdge !== undefined && selectedEdge !== null) {
                            props.restoreEdge(selectedEdge);
                        }
                    }}
                />
            </ButtonBar>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getEdgesList: () => {
            dispatch(getEdgesActionRequest());
        },
        restoreEdge: (edge: IEdgeList) => {
            dispatch(restoreEdgeRequest(edge));
        },
    };
};
const mapStateToProps = (store: StoreState) => {
    return {
        edgeList: store.assets.edgeList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreEdgeTab);
