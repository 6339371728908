import React, { ReactNode } from 'react';
import './style.scss';
interface AppProps {
    children: ReactNode;
    className?: string;
}

function Th(props: AppProps) {
    return (
        <th className={`${props.className} wrapper-table-header-data`}>
            {props.children}
        </th>
    );
}

export default Th;
