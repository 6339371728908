import { takeLatest, select, put, take } from "redux-saga/effects";
import { ActionTypes } from "../../actionTypes";
import {
    changeActiveModelInstanceConfig,
    handleConfiguredInstanceJson,
    handleAssetToggleUnconfiguredInstance,
} from "../actions";
import { StoreState } from "../..";
import {
    createConfigurationToolJson,
    updateOverallSeverityFunctionId,
} from "../../configurationTool/actions";

function* changeActiveModelInstanceConfigSaga(
    action: ReturnType<typeof changeActiveModelInstanceConfig>
) {
    const { modelId } = action.payload;
    const { instanceConfig }: StoreState = yield select();
    const { computeModels } = instanceConfig;

    let modelInstance =
        computeModels.all.find((model) => model.objectId === modelId) ||
        computeModels.matching.find((model) => model.objectId === modelId);

    if (modelInstance) {
        if (modelInstance.overAllSeverityFunctionId) {
            yield put(
                updateOverallSeverityFunctionId(
                    modelInstance.overAllSeverityFunctionId
                )
            );
        } else {
            yield put(updateOverallSeverityFunctionId(""));
        }
        yield put(createConfigurationToolJson({ json: modelInstance.json }));
    }
}

function* handleConfiguredInstanceJsonSaga(
    action: ReturnType<typeof handleConfiguredInstanceJson>
) {
    const computeModel = action.payload.computeModelFromJson;

    // yield put(createConfigurationToolJson({ json: computeModel.json }));
}

function* clearConfigurationTool(
    action: ReturnType<typeof handleConfiguredInstanceJson>
) {
    yield put(
        createConfigurationToolJson({
            json: { assetData: [], connectionData: [] },
        })
    );
}

export default [
    takeLatest(
        ActionTypes.CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG,
        changeActiveModelInstanceConfigSaga
    ),
    takeLatest(
        ActionTypes.HANDLE_CONFIGURED_INSTANCE_JSON,
        handleConfiguredInstanceJsonSaga
    ),
    takeLatest(
        ActionTypes.HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE,
        clearConfigurationTool
    ),
    takeLatest(
        ActionTypes.HANDLE_LOADING_CONFIGURED_INSTANCE,
        clearConfigurationTool
    ),
    takeLatest(
        ActionTypes.DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS,
        clearConfigurationTool
    ),
];
