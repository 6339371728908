import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import CanvasTopBar from './CanvasTopBar';
import ModelsActionHeader from './CanvasHeader';
import IoTableTabs from '../../../components/IoTableTabs';
import ModelTabNavigation from '../../../components/TabNavigation';
import {
    DRAGGABLE_ITEMS,
    VIEW_MODE,
    CANVAS_DIMENSION,
    CONFIGURATION_TOOL_MODE,
    LOADER_TEXT,
} from '../../../utils/constants/appConstants';
import { StoreState } from '../../../store';
import CanvasController from '../../../components/Fabric';
import './style.scss';
import { Dispatch } from 'redux';
import { AssetType } from '../../../transformers/AssetType';
import { MovePosition } from '../../../components/Fabric/ConnectionController/types';
import classnames from 'classnames';
import { ActiveModelType } from '../../../store/modelsPage/types';
import { LOADER_SIZE, LOADER_TYPE } from '../../../utils/constants/appConstants';
import { handleAssetDropAction } from '../../../store/configurationTool/actions';
import { AddDraggableOptions, Asset } from '../../../components/Fabric/types';
import SpinnerModal from '../../../components/SpinnerModal';
import { showNotificationModal } from '../../../store/notificationModal/action';
import CanvasBottomBar from './CanvasBottomBar';
interface CanvasContainerProps {
    canvas: CanvasController;
    toggleImportExportDialog?: () => void;
}

export function CanvasEditorContainer(
    props: CanvasContainerProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEMS.DIAGRAM_VIEW.canvas.target,
        drop: (data: any, monitor) => {
            const position = monitor.getClientOffset() as AddDraggableOptions['position'];
            if (position) {
                props.handleAssetDrop(
                    data.asset,
                    props.currentActiveView,
                    props.activeModel,
                    props.json.assetData,
                    position
                );
            }
        },
    });

    useEffect(() => {
        props.canvas && props.canvas.addGridLines && props.canvas.addGridLines(props.showGrid);
    }, [props.showGrid, props.canvas]);

    const hiddenClass = classnames({
        hidden: !props.activeAssetName || props.computeModelLoading,
    });

    return (
        <div className={'wrapper-canvas-container'}>
            {!props.isEditMode && (
                <ModelsActionHeader
                    currentModels={`${props.activeAssetName}`}
                    checkboxLabel="Enable Grid"
                    showContainer={!!props.activeAssetName}
                    toggleImportExportDialog={props.toggleImportExportDialog}
                    canvasDataLoading={
                        props.computeModelLoading || props.activeAssetModelCount === 0
                    }
                    importExportDisable={props.computeModelLoading}
                />
            )}

            <div className={`canvas-container`} ref={drop}>
                {props.isEditMode && (
                    <CanvasTopBar
                        showContainer={props.showContainer || props.isEditMode}
                        currentActiveView={props.currentActiveView}
                        canvasDataLoading={
                            props.computeModelLoading || props.activeAssetModelCount === 0
                        }
                    />
                )}

                {props.computeModelLoading || props.isRelatedInstancesUpdating ? (
                    <div className="feedback-text">
                        <SpinnerModal
                            mode={props.isRelatedInstancesUpdating ? 'Modal' : 'Spinner'}
                            showModal
                            type={LOADER_TYPE.RADIAL}
                            sizeClass={LOADER_SIZE.LARGE}
                        >
                            {props.isRelatedInstancesUpdating && (
                                <div className="instances-heading">
                                    {LOADER_TEXT.UPDATE_RELATED_INSTANCES}
                                </div>
                            )}
                        </SpinnerModal>
                    </div>
                ) : (
                    props.activeAssetModelCount === 0 &&
                    !props.isEditMode &&
                    props.canavsNotEmptyInViewMode === 0 && (
                        <div className="feedback-text">
                            THERE ARE NO MODELS FOR THE SELECTED OBJECT TYPES, TO CREATE ONE CLICK
                            "CREATE NEW MODEL" BUTTON
                        </div>
                    )
                )}

                <div
                    id="canvas-parent"
                    className={` ${hiddenClass} ${
                        props.currentActiveView === VIEW_MODE.DIAGRAM.value ? '' : 'hidden'
                    }`}
                    style={{
                        height: 'calc((100% ) - 0px)',
                        backgroundRepeat: 'repeat',
                        overflow: 'scroll',
                        // backgroundImage: `url(${dottenImage})`,
                    }}
                >
                    <canvas id="canvas"></canvas>
                </div>
                {(!(props.computeModelLoading || props.activeAssetModelCount === 0) ||
                    props.configurationToolMode === CONFIGURATION_TOOL_MODE.IMPORT) &&
                    props.currentActiveView === VIEW_MODE.TABLE.value && (
                        <div
                            className={
                                props.currentActiveView === VIEW_MODE.TABLE.value
                                    ? 'active-view-container'
                                    : 'hidden'
                            }
                        >
                            <IoTableTabs {...props} />
                        </div>
                    )}

                {props.currentActiveView === VIEW_MODE.DIAGRAM.value && (
                    <CanvasBottomBar
                        canvas={props.canvas}
                        className={hiddenClass}
                        isEditMode={props.isEditMode}
                    ></CanvasBottomBar>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        alarmDetails: state.modelsPage.alarmDetails,
        currentActiveView: state.configurationTool.activeView.value,
        activeModel: state.modelsPage.activeModel,
        showContainer: state.modelsPage.computeModels.entities.length !== 0,
        activeAssetName: state.modelsPage.activeAsset ? state.modelsPage.activeAsset.assetName : '',
        activeAssetModelCount: state.modelsPage.activeAsset
            ? state.modelsPage.computeModels.entities.length
            : '',
        computeModelLoading:
            state.loader.computeModelLoading.isLoading ||
            state.loader.computeModelSaving.isSaving ||
            state.configurationTool.isLatestFuncLoading,
        isRelatedInstancesUpdating: state.modelsPage.updatingTypeRelatedInstances,
        isEditMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        configurationToolMode: state.configurationTool.mode,
        json: state.configurationTool.json,
        canavsNotEmptyInViewMode: state.configurationTool.json.assetData.length,
        showGrid: state.grid.show,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleAssetDrop: (
            asset: AssetType,
            currentActiveView: string,
            activeModel: ActiveModelType,
            canvasAssets: Asset[],
            position?: MovePosition
        ) => {
            dispatch(
                handleAssetDropAction({
                    asset,
                    currentActiveView,
                    activeModel,
                    canvasAssets,
                    position,
                })
            );
        },
        showNotificationModal: (val: any) => dispatch(showNotificationModal(val)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasEditorContainer);
