import React, { useCallback } from "react";
import { Input } from "@abb/abb-common-ux-react";
import Flex from "../shared/Flex";
import "./style.scss";
import CustomABBInput from "../CustomABBInput";

interface AppProps {
    placeholder: string;
    borderColor?: string;
    onChange?: (search: string) => void;
    value?: string;
}

function SearchBar(props: AppProps) {
    const onChange = useCallback(
        (value: string) => {
            if (props.onChange) {
                props.onChange(value);
            }
        },
        [props.onChange]
    );
    return (
        <Flex fill className={`wrapper-search-bar`}>
            <CustomABBInput
                className="search-bar"
                dataType="search"
                placeholder={props.placeholder}
                onValueChange={onChange}
                value={props.value}
                icon="abb/search"
                type="discreet"
                onIconClick={(text) => onChange(text || "")}
            />
        </Flex>
    );
}

export default SearchBar;
