import _ from "lodash";
import { Dispatch } from "redux";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {
    Dialog,
    Button,
    Dropdown,
    DropdownOption,
    Input,
} from "@abb/abb-common-ux-react";
import "./style.scss";
import {
    DropdownInterface,
    ALARM_ATTRIBUTES,
    CONDITION_ATTRIBUTES,
    ALARM_TABLE_DRAGGABLE_ITEMS,
    UNMAPPED_CONDITION_ATTRIBUTES,
    AVAILABLE_CONDITION_ATTRIBUTES,
} from "./dropdownConstants";
import { StoreState } from "../../store";
import {
    updateAlarmDragDropTables,
    updateAlarmMappingDetails,
    updateSelectedAlarmType,
} from "../../store/function/action";
import DraggableTableRow, { DraggableTableRowProps } from "./DraggableTableRow";
import DroppableTableRow, { DroppableTableRowProps } from "./DroppableTableRow";
import { hideAlarmModal } from "../../store/AlarmModal/action";
import {
    DropTableData,
    AlarmMapping,
    FunctionAlarmType,
} from "../../store/function/types";
import { getAlarmMapping } from "../../routes/Functions/helper";
import CustomABBInput from "../CustomABBInput";

const AlarmConfigurationModal = (
    props: ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        hideAlarmModal,
        title,
        dragTableData,
        dropTableData,
        updateAlarmDragDropTables,
        isOpen,
    } = props;
    const [selectedValue, updateSelectedValue] = useState([] as any);
    const [dragTableEmpty, updateDragTableEmpty] = useState(false);
    const [dropDownList, updateDropDownList] = useState([] as any[]);

    const handleDropDownSelectedValue = (selectedObj: DropdownInterface[]) => {
        updateSelectedValue(selectedObj);
        props.updateSelectedAlarmType(props.alarmTypeList[0]);
    };

    useEffect(() => {
        if (selectedValue.length === 0) {
            if (dropDownList.length > 0) {
                selectedValue.push(dropDownList[0]);
                props.updateSelectedAlarmType(props.alarmTypeList[0]);
            }
        }
    }, [dropDownList]);

    useEffect(() => {
        if (props.alarmTypeList.length > 0) {
            const dropDownList = props.alarmTypeList.map((alarmtype) => {
                return {
                    value: alarmtype.name,
                    label: alarmtype.name,
                    isNew: false,
                };
            });

            updateDropDownList(dropDownList);
        }
    }, [props.alarmTypeList]);

    useEffect(() => {
        let count = 0;
        dragTableData.forEach(
            (dragItem: DraggableTableRowProps["dragItem"]) => {
                if (dragItem.label === "") {
                    count++;
                }
            }
        );
        if (count === dragTableData.length) {
            updateDragTableEmpty(true);
        } else {
            updateDragTableEmpty(false);
        }
    }, [dragTableData]);

    useEffect(() => {}, [props.alarmMapping]);

    const [, dropUnmappedAlarm] = useDrop({
        accept: ALARM_TABLE_DRAGGABLE_ITEMS.ALARM_DROPPABLE,
        drop: (data: any, monitor) => {
            let updatedDragItemData: DraggableTableRowProps["dragItem"][] =
                _.cloneDeep(dragTableData);
            updatedDragItemData[0].label = data.data.mappedAlarm;
            const updatedDataForDropTable: DroppableTableRowProps["dropItem"][] =
                _.cloneDeep(dropTableData).map(
                    (dropTableItem: DroppableTableRowProps["dropItem"]) => {
                        if (dropTableItem.value === data.data.value) {
                            dropTableItem.mappedAlarm = "";
                        }
                        return dropTableItem;
                    }
                );
            updateAlarmDragDropTables({
                dropTableData: updatedDataForDropTable,
                dragTableData: updatedDragItemData,
            });
        },
    });

    const handleSubmit = () => {
        if (props.selectedAlarmType) {
            const alarmMapping = getAlarmMapping(
                props.selectedAlarmType,
                props.dropTableData
            );
            props.updateAlarmMappingDetails(alarmMapping);
            props.hideAlarmModal();
            if (props.data && props.data.handleCreateAlarm) {
                props.data.handleCreateAlarm();
            }
        }
    };
    return (
        <Dialog
            title={title}
            dimBackground={true}
            closeOnEscape={false}
            showCloseButton={true}
            closeOnLostFocus={false}
            standardButtonsOnBottom={[
                {
                    text: props.data && props.data.submitBtnText,
                    type: "primary-blue",
                    handler: () => handleSubmit(),
                },
            ]}
            isOpen={isOpen}
            onClose={hideAlarmModal}
            contentClassName="alarm-configuration-dialog-class"
        >
            <div className="wrapper-config-form">
                <div className="wrapper-config-form-body">
                    <div className="details-div">
                        <div className="details-heading">Details</div>
                        <div className="dropdown-textfield">
                            <div className="dropdown">
                                <Dropdown
                                    label={"Alarm Type"}
                                    value={selectedValue}
                                    onChange={(val: DropdownInterface[]) =>
                                        handleDropDownSelectedValue(val)
                                    }
                                >
                                    {dropDownList.map(
                                        (option: DropdownInterface) => {
                                            return (
                                                <DropdownOption
                                                    key={option.value}
                                                    label={option.label}
                                                    value={option.value}
                                                />
                                            );
                                        }
                                    )}
                                </Dropdown>
                            </div>
                            <div className="description-alarm">
                                <div>Description</div>
                                <div className="input-field">
                                    <CustomABBInput
                                        disabled={true}
                                        dataType="textarea"
                                        value={
                                            (props.selectedAlarmType &&
                                                props.selectedAlarmType
                                                    .description) ||
                                            ""
                                        }
                                        className="input-field-text"
                                        placeholder={
                                            "This alarm has a description which is entered manually here by the person creating the alarm"
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-mapping">Attribute mapping</div>
                    <div className="drag-drop-tables">
                        <div className="drop">
                            <table className="customEditTableOverflow">
                                <thead>
                                    <tr>
                                        <th>{ALARM_ATTRIBUTES}</th>
                                        <th>{CONDITION_ATTRIBUTES}</th>
                                    </tr>
                                </thead>
                                <tbody className="mapping-table">
                                    {dropTableData.map(
                                        (dropItem: DropTableData) => {
                                            return (
                                                <DroppableTableRow
                                                    dropItem={dropItem}
                                                    key={dropItem.value}
                                                    updateAlarmDragDropTables={
                                                        updateAlarmDragDropTables
                                                    }
                                                    dropTableData={
                                                        dropTableData
                                                    }
                                                    dragTableData={
                                                        dragTableData
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="drag">
                            <table className="customEditTableOverflow">
                                <thead>
                                    <tr>
                                        <th>
                                            {dragTableEmpty === true
                                                ? UNMAPPED_CONDITION_ATTRIBUTES
                                                : AVAILABLE_CONDITION_ATTRIBUTES}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="mapping-table">
                                    {dragTableEmpty === true ? (
                                        <div
                                            className="drag-data-empty"
                                            ref={dropUnmappedAlarm}
                                        >
                                            All attributes mapped. Drag and drop
                                            attributes here to unmap.
                                        </div>
                                    ) : (
                                        dragTableData.map(
                                            (
                                                dragItem: DraggableTableRowProps["dragItem"]
                                            ) => {
                                                return (
                                                    <DraggableTableRow
                                                        dragItem={dragItem}
                                                        updateAlarmDragDropTables={
                                                            updateAlarmDragDropTables
                                                        }
                                                        dropTableData={
                                                            dropTableData
                                                        }
                                                        dragTableData={
                                                            dragTableData
                                                        }
                                                    />
                                                );
                                            }
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <div className="bottom-button">
                    <Button
                        text={props.data && props.data.submitBtnText}
                        sizeClass={'small'}
                        type={'primary-blue'}
                        onClick={handleSubmit}
                    />
                </div> */}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        dragTableData: state.functions.dragTableData,
        dropTableData: state.functions.dropTableData,
        title: state.alarmModal.modalTitle,
        data: state.alarmModal.data,
        isOpen: state.alarmModal.show,
        alarmTypeList: state.functions.alarmType,
        selectedAlarmType: state.functions.selectedAlarmType,
        alarmMapping: state.functions.alarmMapping,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateAlarmDragDropTables: (payload: {
            dropTableData: DropTableData[];
            dragTableData: DraggableTableRowProps["dragItem"][];
        }) => dispatch(updateAlarmDragDropTables(payload)),
        hideAlarmModal: () => {
            dispatch(hideAlarmModal());
        },
        updateAlarmMappingDetails: (payload: AlarmMapping) => {
            dispatch(updateAlarmMappingDetails(payload));
        },
        updateSelectedAlarmType: (payload: FunctionAlarmType) => {
            dispatch(updateSelectedAlarmType(payload));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlarmConfigurationModal);
