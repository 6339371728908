function calcLineCurve(path: any[], isSame: boolean, startOpen: boolean = false, endOpen: boolean = false) {
    // starting position
    const startX = path[0][1];
    const startY = path[0][2];

    const endX = path[1][3];
    const endY = path[1][4];

    path[1][1] = (startX + endX) / 2;
    isSame == true ? (path[1][2] = endY + 150) : (path[1][2] = startOpen && endOpen ? endY + 60 : endY);

    if (startOpen && endOpen) {
        path[0][3] = (startX + endX) / 2;
        path[0][4] = startY + startOpen ? 60 : 0;
    }

    return path;
}

export default calcLineCurve;
