import React, { useState, useCallback } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Input, Button } from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../../store";
import { FUNCTION_MODAL } from "../constants";
import { validatePasswordRegex } from "../../../utils/helpers";
import { PASSWORD_MSG } from "../../../utils/constants/appConstants";
import { encryptLibraryRequest } from "../../../store/function/action";
import {
    LibraryAndNodePayload,
    EncryptLibraryRequestPayload,
} from "../../../store/function/types";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface EncryptGenericFunctionsProps {
    libraryDetail: LibraryAndNodePayload;
    resetGlobalLibraryError: () => void;
}

const EncryptLibraries = (
    props: EncryptGenericFunctionsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        libraryDetail,
        encryptingLibrary,
        libraryGlobalError,
        encryptLibraryRequest,
        resetGlobalLibraryError,
    } = props;

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordType, setPasswordType] = useState(true);
    const [passwordErrors, setPasswordErrors] = useState({
        password: "",
        confirmPassword: "",
    });

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const handleButtonClick = useCallback(() => {
        if (!validatePasswordRegex(password)) {
            setPasswordErrors({
                ...passwordErrors,
                password: PASSWORD_MSG.MIN_REQUIREMENT,
            });
        } else if (confirmPassword !== password) {
            setPasswordErrors({
                ...passwordErrors,
                confirmPassword: PASSWORD_MSG.MISMATCH,
            });
        } else {
            encryptLibraryRequest({
                libraryId: libraryDetail.id,
                libraryVersion: libraryDetail.libraryVersion,
                password,
            });
        }
    }, [
        libraryDetail,
        password,
        confirmPassword,
        passwordErrors,
        setPasswordErrors,
        encryptLibraryRequest,
    ]);

    return (
        <div className="encrypt-generic-functions-container">
            <div className="select-library-to-add">
                <label>Enter password</label>
                <CustomABBInput
                    dataType={passwordType ? "password" : "text"}
                    placeholder={
                        FUNCTION_MODAL.LIBRARY_PASSWORD_PLACEHOLDER_TEXT
                    }
                    value={password}
                    onValueChange={(val) => {
                        if (libraryGlobalError.length > 0) {
                            resetGlobalLibraryError();
                        }
                        if (
                            passwordErrors.password.length ||
                            passwordErrors.confirmPassword.length
                        ) {
                            setPasswordErrors({
                                password: "",
                                confirmPassword: "",
                            });
                        }
                        setPassword(val);
                    }}
                    icon={passwordType ? "abb/view" : "abb/hide"}
                    onIconClick={() => {
                        if (password.length > 0 || passwordType === false) {
                            togglePasswordType();
                        }
                    }}
                    validator={() =>
                        passwordErrors.password.length > 0
                            ? {
                                  valid: false,
                                  text: passwordErrors.password,
                              }
                            : {
                                  valid: true,
                                  text: "Ok",
                              }
                    }
                    showValidationBarWhenInvalid
                    showValidationIconWhenInvalid
                    instantValidation
                />
            </div>

            <div className="select-library-to-add">
                <label>Confirm password</label>
                <CustomABBInput
                    dataType="password"
                    placeholder={
                        FUNCTION_MODAL.LIBRARY_PASSWORD_PLACEHOLDER_TEXT
                    }
                    value={confirmPassword}
                    onValueChange={(val) => {
                        if (libraryGlobalError.length > 0) {
                            resetGlobalLibraryError();
                        }
                        if (
                            passwordErrors.password.length ||
                            passwordErrors.confirmPassword.length
                        ) {
                            setPasswordErrors({
                                password: "",
                                confirmPassword: "",
                            });
                        }
                        setConfirmPassword(val);
                    }}
                    validator={() =>
                        passwordErrors.confirmPassword.length > 0
                            ? {
                                  valid: false,
                                  text: passwordErrors.confirmPassword,
                              }
                            : {
                                  valid: true,
                                  text: "Ok",
                              }
                    }
                    showValidationBarWhenInvalid
                    showValidationIconWhenInvalid
                    instantValidation
                />
            </div>

            {libraryGlobalError.length > 0 && (
                <div className="import-error">{libraryGlobalError}</div>
            )}

            <div className="select-library-to-add-controls-sections">
                <Button
                    text={FUNCTION_MODAL.LOCK_LIB_BTN}
                    type="primary-blue"
                    sizeClass="small"
                    disabled={
                        encryptingLibrary ||
                        password.length === 0 ||
                        confirmPassword.length === 0
                    }
                    isLoading={encryptingLibrary}
                    onClick={handleButtonClick}
                />
            </div>
            {libraryDetail.isIPProtected && (
                <div>
                    <p>
                        <span className="note">{NOTE_MESSAGE}</span>
                        <span className="admin_help_message">
                            {ADMIN_HELP_MESSAGE}
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        encryptingLibrary: state.functions.encryptingLibrary,
        libraryGlobalError: state.functions.libraryGlobalError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        encryptLibraryRequest: (payload: EncryptLibraryRequestPayload) =>
            dispatch(encryptLibraryRequest(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EncryptLibraries);
