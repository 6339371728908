import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import './style.scss';
interface CustomTooltipProps {
    children: JSX.Element;
    text: string;
    position: 'top' | 'left' | 'right' | 'bottom';
}

// Takes only one child
const CustomTooltip = (props: CustomTooltipProps) => {
    const id = _.uniqueId('custom-tooltip-');
    return (
        <>
            <span data-tip="" data-for={id}>
                {props.children}
            </span>
            <ReactTooltip
                className="custom-tooltip"
                id={id}
                place={props.position}
                type="light"
                effect="solid"
            >
                <div>
                    <div className="tooltip-arrow"></div>
                    <div className="ABB_CommonUX_Tooltip__content ABB_CommonUX_Tooltip__light tooltip-message">
                        {props.text}
                    </div>
                </div>
            </ReactTooltip>
        </>
    );
};

export default CustomTooltip;
