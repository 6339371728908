import React, { useState, useEffect, useMemo } from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Input, Button } from "@abb/abb-common-ux-react";
import IF from "../../../components/shared/Condition";

import "./style.scss";
import { StoreState } from "../../../store";
import Loader from "../../../components/Loader";
import Flex from "../../../components/shared/Flex";
import {
    LOADER_SIZE,
    LOADER_TYPE,
} from "../../../utils/constants/appConstants";
import {
    LibraryAndNodePayload,
    RemoveAndAddFunctionsToLibrariesPayload,
    LibraryAndNodePayloadWithoutNodes,
    UpdateActiveFunctionType,
    IGetSelectedFunctionPayload,
    LibraryDraggedDetails,
} from "../../../store/function/types";
import {
    findActiveIndexForFunctionsLibrary,
    convertFuncDetailsToFuncType,
} from "../helper";
import {
    deleteLibraryRequest,
    getLibraryAndNodesRequest,
    getMutuallyExclusiveFunctionTypes,
    addFunctionsToExistingLibraryRequest,
    removeFunctionsFromExistingLibraryRequest,
    CreateFunctionAssetDetails,
    setGlobalLibraryError,
    unlockLibrary,
    getExportableFunctionsRequest,
    handlePopoverOpen,
    resetLibPasswordError,
    getDecryptedFunctionTypeRequest,
} from "../../../store/function/action";
import EncryptLibraries from "../EncryptLibraries";
import DeleteLibraryModal from "../DeleteLibraryModal";
import { showModal } from "../../../store/modal/action";
import Condition from "../../../components/shared/Condition";
import FuncLeftNavCollapsibleContainer from "../LeftNavCollapsibleContainer";
import AddFunctionsToExistingLibraryModal from "../AddFunctionsToExistingLibraryModal";
import RemoveFunctionsFromExistingLibrary from "../RemoveFunctionsFromExistingLibraryModal";
import GetPasswordForLibraries from "../../../components/GetPasswordForLibraries";
import SingleLibraryExport from "../../../components/ExportDialog/SingleLibraryExport";
import EditFunctionInfo from "../EditFunction/EditFunctionInfo";
import {
    removedFromDropDown,
    showImportModal,
    clearSelectedFunctions,
    changeZipFileNameForExport,
} from "../../../store/exportAsset/actions";
import ChangeLibraryPassword from "../../../components/ChangeLibraryPassword";
import PasswordModal from "../PasswordModal";
import { FUNCTION_MODAL } from "../constants";
import {
    payloadToExportFunctionWithLibraries,
    ImportModalPayload,
} from "../../../store/exportAsset/types";
import { sce } from "sce-engg-model-19.09";
import CreateLibraryPopup from "../CreateLibraryPopup";
import ExportDialog from "../../../components/ExportDialog";
import SummaryModal from "../../../components/ExportDialog/SummaryModal";
import SpinnerModal from "../../../components/SpinnerModal";
import RenameLibraryModal from "../../../components/RenameLibraryModal";
import { AssetsInfo } from "../type";
import GetExportZipName from "../../../components/GetExportZipName";
import CustomABBInput from "../../../components/CustomABBInput";
import { isGlobalTenantType } from "../../../utils/helpers";

interface AppProps {
    getSelectedFunctionType: (options: IGetSelectedFunctionPayload) => void;
    handleEmptyResponse: any;
    assetsDetails?: CreateFunctionAssetDetails;
    showMessageModal: (payload: any) => any;
}

function LeftNavigation(
    props: AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [searchValue, updateSearchValue] = useState("" as string);
    const [selectedFunctionId, updateSelectedFunctionId] = useState("");
    const [selectedFunctionVersion, updateSelectedFunctionVersion] =
        useState("");

    const {
        activeLibId,
        showDeleteModal,
        unlockLibraryModal,
        encryptLibrariesModal,
        gettingLibraryAndNodes,
        libraryAndFunctionsList,
        getLibraryAndNodesRequest,
        selectedFunctionTypeDetails,
        currentLibraryToDragAndDrop,
        getExportableFunctionsRequest,
        showModalToExportSingleLibrary,
        showModalToRemoveSingleFunction,
        showAddFunctionsToExistingLibrary,
        showRemoveFunctionsFromExistingLibrary,
        showGetPasswordForLibrariesToAddFunction,
        handlePopoverOpen,
        showCreateLibraryModal,
        showImportModal,
        summaryModalDetails,
        showSpinnerModal,
        closeModalForLibraryExport,
    } = props;

    
    const [hasDefaultElement, setDefaultElement] = useState(false);
    const [importMode, updateImportMode] = useState(false);
    const [showExportDialog, updateShowExportDialog] = useState(false);
    const [multiple, updateMultiple] = useState(false);

    useEffect(() => {
        if (libraryAndFunctionsList.length > 0) {
            if (!gettingLibraryAndNodes) {
                const defaultModel = document.getElementById(
                    props.activeLibId !== ""
                        ? props.activeLibId
                        : libraryAndFunctionsList[0].id
                );
                if (defaultModel) {
                    setDefaultElement(true);
                    defaultModel.click();
                }
            }
        }
    }, [libraryAndFunctionsList, gettingLibraryAndNodes]);

    useEffect(() => {
        if (libraryAndFunctionsList.length > 0) {
            updateSearchValue("");
        }
    }, [libraryAndFunctionsList]);

    useEffect(() => {
        if (libraryAndFunctionsList.length && !selectedFunctionTypeDetails) {
            const index = findActiveIndexForFunctionsLibrary(
                libraryAndFunctionsList
            );
            if (index >= 0 && !selectedFunctionTypeDetails) {
                props.getSelectedFunctionType({
                    selectedFunction: convertFuncDetailsToFuncType(
                        libraryAndFunctionsList[index].nodes[0]
                    ),
                    activeLibId:
                        props.activeLibId === ""
                            ? libraryAndFunctionsList[0].id
                            : props.activeLibId,
                });
            }
        }
    }, [libraryAndFunctionsList, selectedFunctionTypeDetails]);

    useEffect(() => {
        if (libraryAndFunctionsList.length > 0) {
            if (selectedFunctionTypeDetails) {
                updateSelectedFunctionId(selectedFunctionTypeDetails.typeId);
                updateSelectedFunctionVersion(
                    selectedFunctionTypeDetails.version
                );
            } else {
                if (selectedFunctionId === "") {
                    const index = findActiveIndexForFunctionsLibrary(
                        libraryAndFunctionsList
                    );
                    if (index >= 0) {
                        updateSelectedFunctionId(
                            libraryAndFunctionsList[index].nodes[0].typeId
                        );
                        updateSelectedFunctionVersion(
                            libraryAndFunctionsList[index].nodes[0].version
                        );
                    }
                }
            }
        }
    }, [selectedFunctionTypeDetails, libraryAndFunctionsList]);

    const handleSearchValue = (value: string) => {
        updateSearchValue(value);
    };

    const handleTypeList = (
        typeList: UpdateActiveFunctionType,
        libId: string
    ) => {
        updateSelectedFunctionId(typeList.typeId);
        updateSelectedFunctionVersion(typeList.version);
        props.getSelectedFunctionType({
            activeLibId: libId,
            selectedFunction: convertFuncDetailsToFuncType(typeList),
        });
    };

    const updatedLibraryAndFunctionsList = useMemo(() => {
        let updatedList: LibraryAndNodePayload[] = _.cloneDeepWith(
            libraryAndFunctionsList
        );

        if (searchValue.length > 0) {
            updatedList = [];
            libraryAndFunctionsList.forEach((libraryDetail) => {
                const updatedLibraryDetail = _.cloneDeepWith(libraryDetail);
                const filteredNodes = updatedLibraryDetail.nodes.filter(
                    (node) =>
                        node.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                );

                if (filteredNodes.length > 0) {
                    updatedLibraryDetail.nodes = filteredNodes;
                    updatedList.push(updatedLibraryDetail);
                }
            });
        }

        return updatedList;
    }, [searchValue, libraryAndFunctionsList]);

    const handleCreate = () => {
        props.showCreateModel();
        handlePopoverOpen(false);
    };

    return (
        <>
            <Flex fill className="left-navigation-panel-container">
                <div className="left-navigation-data-function functionList">
                    <div className="func-leftnav-top">
                        <IF when={sce.isDeveloper()}>
                            <Flex row>
                                <Button
                                    text="Import"
                                    sizeClass="small"
                                    className="import-export-btn"
                                    icon="abb/import"
                                    disabled={!props.hasImport}
                                    onClick={() => {
                                        let payload: ImportModalPayload = {
                                            currentRoute: "Functions",
                                        };
                                        showImportModal(payload);
                                        updateImportMode(true);
                                    }}
                                />
                                <Button
                                    text="Export"
                                    icon="abb/export"
                                    sizeClass="small"
                                    className="import-export-btn"
                                    disabled={
                                        !props.hasExport ||
                                        !props.assetsDetails ||
                                        !props.assetsDetails.name
                                    }
                                    onClick={() => {
                                        clearSelectedFunctions();
                                        updateShowExportDialog(true);
                                        updateMultiple(true);
                                        updateImportMode(false);
                                    }}
                                />
                            </Flex>
                        </IF>
                        <IF when={sce.isDeveloper()}>
                            <Button
                                text="Create Library"
                                sizeClass="small"
                                className="create-new-btn"
                                icon="abb/plus"
                                onClick={showCreateLibraryModal}
                                disabled={!props.hasSave}
                            />
                            <Button
                                text="Create Function"
                                sizeClass="small"
                                icon="abb/plus"
                                className="create-new-btn"
                                onClick={handleCreate}
                                disabled={
                                    !props.hasSave ||
                                    props.gettingLibraryAndNodes
                                }
                            />
                        </IF>
                        <CustomABBInput
                            type="discreet"
                            dataType="search"
                            placeholder="Search Function"
                            icon="abb/search"
                            value={searchValue}
                            onValueChange={(value: string) =>
                                handleSearchValue(value)
                            }
                            className="searchFunction"
                        />

                        {updatedLibraryAndFunctionsList.length === 0 &&
                            !gettingLibraryAndNodes && (
                                <li style={{ pointerEvents: "none" }}>
                                    {" "}
                                    <b> Functions Not Found </b>{" "}
                                </li>
                            )}
                    </div>
                    <Condition when={gettingLibraryAndNodes}>
                        <div className="left-navigation-loader">
                            <Loader
                                sizeClass={LOADER_SIZE.SMALL}
                                type={LOADER_TYPE.RADIAL}
                            />
                        </div>
                    </Condition>
                    <Condition
                        when={
                            !gettingLibraryAndNodes &&
                            updatedLibraryAndFunctionsList.length > 0
                        }
                    >
                        <FuncLeftNavCollapsibleContainer
                            activeLibId={activeLibId}
                            currentLibraryToDragAndDrop={
                                currentLibraryToDragAndDrop
                            }
                            handleTypeList={handleTypeList}
                            selectedFunctionTypeId={selectedFunctionId}
                            selectedFunctionVersion={selectedFunctionVersion}
                            libraryAndFunctionsList={
                                updatedLibraryAndFunctionsList
                            }
                            showDeleteModal={showDeleteModal}
                            showAddFunctionsToExistingLibrary={
                                showAddFunctionsToExistingLibrary
                            }
                            showRemoveFunctionsFromExistingLibrary={
                                showRemoveFunctionsFromExistingLibrary
                            }
                            showModalToRemoveSingleFunction={
                                showModalToRemoveSingleFunction
                            }
                            showGetPasswordForLibraries={
                                showGetPasswordForLibrariesToAddFunction
                            }
                            encryptLibrariesModal={encryptLibrariesModal}
                            getExportableFunctionsRequest={
                                getExportableFunctionsRequest
                            }
                            showModalToExportSingleLibrary={
                                showModalToExportSingleLibrary
                            }
                            selectedFunctionTypeDetails={
                                selectedFunctionTypeDetails
                            }
                            showChangeLibraryPasswordModal={
                                props.showChangeLibraryPasswordModal
                            }
                            unlockLibraryModal={unlockLibraryModal}
                            showGetPasswordForRename={
                                props.showGetPasswordForRename
                            }
                            showGetFileNameModal={props.showGetFileNameModal}
                        />
                    </Condition>
                </div>
            </Flex>

            {showExportDialog && !importMode && (
                <ExportDialog
                    showExportDialog={showExportDialog}
                    onExportClose={(val: boolean) => {
                        updateShowExportDialog(val);
                    }}
                    multipleFunction={multiple}
                    importMode={importMode}
                />
            )}

            {summaryModalDetails.showSummaryModal && (
                <SummaryModal
                    showSummaryDialog={summaryModalDetails.showSummaryModal}
                    mode={summaryModalDetails.mode}
                    route={summaryModalDetails.route}
                    data={summaryModalDetails.data}
                />
            )}
            {!showExportDialog &&
                showSpinnerModal &&
                !closeModalForLibraryExport && (
                    <SpinnerModal mode={"Modal"} showModal={showSpinnerModal} />
                )}
        </>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        selectedFunctionTypeDetails: state.functions.typeDetails,
        libraryAndFunctionsList: state.functions.libraryAndFunctionsList,
        gettingLibraryAndNodes: state.functions.gettingLibraryAndNodes,
        activeLibId: state.functions.activeLibId,
        currentLibraryToDragAndDrop:
            state.functions.currentLibraryToDragAndDrop,
        hasSave: state.functions.functionPermission.hasSave,
        hasEdit: state.functions.functionPermission.hasEdit,
        hasDelete: state.functions.functionPermission.hasDelete,
        hasExport: state.functions.functionPermission.hasExport,
        hasImport: state.functions.functionPermission.hasImport,
        activeFunction: state.functions.currentActiveView.assetsDetails,
        summaryModalDetails: state.exportAsset.summaryModalDetails,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        closeModalForLibraryExport: state.modal.closeModal,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getLibraryAndNodesRequest: () => {
            dispatch(getLibraryAndNodesRequest());
        },
        showCreateLibraryModal: () => {
            dispatch(
                showModal({
                    component: CreateLibraryPopup,
                    modalTitle: "Create Library",
                    data: {
                        primaryButtonAction: (payload: any) => {},
                    },
                    customClassName:
                        "create-library-container change-password-modal",
                })
            );
        },
        showCreateModel: () => {
            dispatch(
                showModal({
                    //@ts-ignore
                    component: EditFunctionInfo,
                    modalTitle: "Create Function",
                })
            );
        },
        handlePopoverOpen: (isOpen: boolean) => {
            dispatch(handlePopoverOpen(isOpen));
        },
        showGetPasswordForRename: (payload: {
            libraryDetails: LibraryAndNodePayloadWithoutNodes;
        }) => {
            dispatch(
                showModal({
                    component: RenameLibraryModal,
                    modalTitle: "Rename Library",
                    data: {
                        submitBtnText: "Rename",
                        libraryDetails: payload.libraryDetails,
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "change-password-modal",
                })
            );
        },
        showImportModal: (payload: ImportModalPayload) =>
            dispatch(showImportModal(payload)),
        showDeleteModal: (libraryDetail: LibraryAndNodePayload) => {
            dispatch(
                showModal({
                    component: DeleteLibraryModal,
                    modalTitle: "Delete Library",
                    data: {
                        submitBtnText: "Delete",
                        libraryDetails: libraryDetail,
                        type: "Delete",
                        onDelete: (
                            libraryId: string,
                            libraryVersion: string,
                            password: string,
                            isIPProtected: boolean,
                            deleteFunctions: boolean
                        ) =>
                            dispatch(
                                deleteLibraryRequest(
                                    libraryId,
                                    libraryVersion,
                                    password,
                                    isIPProtected,
                                    deleteFunctions
                                )
                            ),
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "delete-library-modal",
                })
            );
        },
        showModalToRemoveSingleFunction: (
            libraryDetail: LibraryAndNodePayload,
            currentFunction: UpdateActiveFunctionType
        ) => {
            dispatch(
                showModal({
                    component: DeleteLibraryModal,
                    modalTitle: "Remove Function",
                    data: {
                        submitBtnText: "Remove",
                        currentFunction: currentFunction,
                        libraryDetails: libraryDetail,
                        type: "Remove",
                        onDelete: (
                            payload: RemoveAndAddFunctionsToLibrariesPayload
                        ) =>
                            dispatch(
                                removeFunctionsFromExistingLibraryRequest(
                                    payload
                                )
                            ),
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "delete-library-modal",
                })
            );
        },
        showRemoveFunctionsFromExistingLibrary: (
            libraryDetail: LibraryAndNodePayload
        ) => {
            dispatch(
                showModal({
                    component: RemoveFunctionsFromExistingLibrary,
                    modalTitle: "Remove Functions",
                    data: {
                        libraryDetails: libraryDetail,
                        onRemove: (
                            payload: RemoveAndAddFunctionsToLibrariesPayload
                        ) =>
                            dispatch(
                                removeFunctionsFromExistingLibraryRequest(
                                    payload
                                )
                            ),
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "change-password-modal",
                })
            );
        },
        showAddFunctionsToExistingLibrary: (
            libraryDetail: LibraryAndNodePayload
        ) => {
            dispatch(
                showModal({
                    component: AddFunctionsToExistingLibraryModal,
                    modalTitle: "Add Functions",
                    data: {
                        libraryDetails: libraryDetail,
                        getMutuallyExclusiveFunctions: () =>
                            dispatch(getMutuallyExclusiveFunctionTypes()),
                        onAdd: (
                            payload: RemoveAndAddFunctionsToLibrariesPayload
                        ) =>
                            dispatch(
                                addFunctionsToExistingLibraryRequest(payload)
                            ),
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "add-password-modal",
                })
            );
        },
        showGetPasswordForLibrariesToAddFunction: (payload: {
            libraryDetails: LibraryAndNodePayloadWithoutNodes;
            requiredActionAfterSubmit?: (
                typeDetails: CreateFunctionAssetDetails
            ) => void;
            functions: CreateFunctionAssetDetails[];
            libraryInWhichFunctionIsDroppedRecently: LibraryDraggedDetails[];
        }) => {
            dispatch(
                showModal({
                    component: GetPasswordForLibraries,
                    modalTitle:
                        payload.libraryDetails &&
                        payload.libraryDetails.isIPProtected &&
                        ((payload.libraryInWhichFunctionIsDroppedRecently[0] &&
                            payload.libraryDetails.id !==
                                payload
                                    .libraryInWhichFunctionIsDroppedRecently[0]
                                    .libraryId) ||
                            !payload.libraryInWhichFunctionIsDroppedRecently[0])
                            ? "Enter Library Password"
                            : "Add Function",
                    data: {
                        submitBtnText: "Add",
                        requiredActionAfterSubmit:
                            payload.requiredActionAfterSubmit,
                        submitBtnAction: (
                            assetsInfo: AssetsInfo,
                            functionsDetailsList: CreateFunctionAssetDetails[],
                            libraryDetails: LibraryAndNodePayloadWithoutNodes[]
                        ) => {
                            dispatch(
                                addFunctionsToExistingLibraryRequest({
                                    password: libraryDetails[0].password,
                                    libraryId: libraryDetails[0].id,
                                    libraryVersion:
                                        libraryDetails[0].libraryVersion,
                                    functions: functionsDetailsList,
                                    withIPProtection:
                                        libraryDetails[0].isIPProtected,
                                    dragAndDrop: true,
                                })
                            );
                        },
                        functionsDetailsList: payload.functions,
                        libraryDetails: [payload.libraryDetails],
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () =>
                        dispatch(setGlobalLibraryError({ error: "" })),
                    customClassName: "change-password-modal",
                })
            );
        },
        encryptLibrariesModal: (payload: {
            libraryDetail: LibraryAndNodePayload;
        }) =>
            dispatch(
                showModal({
                    modalTitle: FUNCTION_MODAL.LOCK_LIBRARY_TITLE,
                    component: EncryptLibraries,
                    data: {
                        libraryDetail: payload.libraryDetail,
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    customClassName: "change-password-modal",
                })
            ),
        showModalToExportSingleLibrary: (payload: {
            libraryDetail: LibraryAndNodePayload;
        }) => {
            let libPayLoadWithGlobalNodesFiltered: LibraryAndNodePayload = {
                ...payload.libraryDetail,
                nodes: payload.libraryDetail.nodes.filter((node) => {
                    return !isGlobalTenantType(node.tags, node.typeId);
                }),
            };
            dispatch(
                showModal({
                    modalTitle: FUNCTION_MODAL.EXPORT_LIBRARY_TITLE,
                    component: SingleLibraryExport,
                    data: {
                        libraryDetail: libPayLoadWithGlobalNodesFiltered,
                        resetGlobalLibraryError: () =>
                            dispatch(setGlobalLibraryError({ error: "" })),
                    },
                    closeModal: () => {
                        dispatch(
                            removedFromDropDown({
                                functionVersionDetails: [],
                                selectedFunctionDetails: [],
                            })
                        );
                        dispatch(setGlobalLibraryError({ error: "" }));
                    },
                })
            );
        },
        getExportableFunctionsRequest: (
            payload: payloadToExportFunctionWithLibraries[]
        ) => {
            dispatch(getExportableFunctionsRequest(payload));
        },
        showChangeLibraryPasswordModal: (payload: {
            libraryDetail: LibraryAndNodePayload;
        }) => {
            dispatch(
                showModal({
                    modalTitle: FUNCTION_MODAL.CHANGE_PASSWORD_TITLE,
                    component: ChangeLibraryPassword,
                    data: {
                        libraryDetail: payload.libraryDetail,
                        submitBtnText: FUNCTION_MODAL.CHANGE_PASSWORD_BTN,
                    },
                    customClassName: "change-password-modal",
                    closeModal: () => {
                        dispatch(setGlobalLibraryError({ error: "" }));
                        dispatch(resetLibPasswordError());
                    },
                })
            );
        },
        unlockLibraryModal: (payload: {
            libraryDetail: LibraryAndNodePayload;
        }) => {
            const { libraryDetail } = payload;
            dispatch(
                showModal({
                    modalTitle: FUNCTION_MODAL.UNLOCK_TITLE,
                    component: PasswordModal,
                    data: {
                        libraryDetail: { ...libraryDetail },
                        submitBtnText: FUNCTION_MODAL.UNLOCK_LIB_BTN,
                        handleSubmitBtn: (password: string) =>
                            dispatch(
                                unlockLibrary({
                                    libraryDetail: payload.libraryDetail,
                                    password: password,
                                })
                            ),
                    },
                    closeModal: () => {
                        dispatch(setGlobalLibraryError({ error: "" }));
                    },
                    customClassName: "change-password-modal",
                })
            );
        },
        showGetFileNameModal: (
            exportMethod: () => any,
            defaultFileName: string
        ) => {
            dispatch(
                showModal({
                    component: GetExportZipName,
                    modalTitle: "Add Export File Name",
                    data: {
                        exportMethod,
                        defaultFileName,
                    },
                    closeModal: () =>
                        dispatch(changeZipFileNameForExport({ fileName: "" })),
                    customClassName: "delete-library-modal",
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
