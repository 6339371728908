import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import './style.scss';
import { Button } from '@abb/abb-common-ux-react';
import GlobalConditionsConfiguration from './GlobalConditionsConfiguration';

export interface ConditionConfigurationProps {
    functionConditionDetails: any;
    primaryButtonAction: any;
    handleClose: any;
    selectedConditionConfigurationVariables: string[];
    isPublished: boolean;
    originalConditionConfigVariables: string[] | undefined;
    isOriginalPublished: boolean;
}

const ConditionConfiguration = (props: ConditionConfigurationProps) => {
    const {
        functionConditionDetails,
        primaryButtonAction,
        handleClose,
        selectedConditionConfigurationVariables,
        originalConditionConfigVariables,
        isOriginalPublished,
        isPublished,
    } = props;

    const [selectedProperties, setSelectedProperties] = useState(
        selectedConditionConfigurationVariables
    );

    const subConditionsData: any[] = useMemo(() => {
        return [
            'subCondition',
            ...Object.keys(
                Object.values(
                    functionConditionDetails[Object.keys(functionConditionDetails)[0]].subConditions
                )[0] as any
            ).filter((item) => item !== 'logic'),
        ];
    }, [functionConditionDetails]);

    useEffect(() => {
        // to handle the old enabled severity pin. before configuration modal implementation.
        if (
            isPublished &&
            originalConditionConfigVariables &&
            originalConditionConfigVariables.length === 0 &&
            selectedConditionConfigurationVariables.length === 0
        ) {
            setSelectedProperties(subConditionsData);
        }
    }, []);

    const handleConditionConfigVariables = () => {
        const originalConditionConfigVariables = props.originalConditionConfigVariables;
        let isAllPropertiesSelected = false;
        const isSelectedPropertiesChanged = selectedProperties.length !== subConditionsData.length;
        if (originalConditionConfigVariables && originalConditionConfigVariables.length === 0) {
            isAllPropertiesSelected =
                isOriginalPublished && selectedProperties.length === subConditionsData.length;
        }

        primaryButtonAction(
            isAllPropertiesSelected ? [] : selectedProperties,
            isSelectedPropertiesChanged,
            selectedProperties
        );
        handleClose();
    };

    return (
        <React.Fragment>
            <div className="condition-configuration-modal-container">
                {Object.keys(functionConditionDetails).length > 0 && (
                    <React.Fragment>
                        <GlobalConditionsConfiguration
                            functionConditionDetails={functionConditionDetails}
                            selectedProperties={selectedProperties}
                            setSelectedProperties={setSelectedProperties}
                        />
                    </React.Fragment>
                )}
            </div>
            <div className="condition-configuration-button">
                <Button
                    type="primary-blue"
                    onClick={handleConditionConfigVariables}
                    text="Set"
                    sizeClass="small"
                />
            </div>
        </React.Fragment>
    );
};

export default ConditionConfiguration;
