import { AssetTypeDetailsConstructorOptions, AssetTypeConstructorOptions } from './types';
import uuid from 'uuid';
import { store } from '../../containers/AppBoot';
import { ReferenceTypeValueItem } from '../ComputeModel/fromJson/types';

export abstract class AssetTypeDetail {
    assetRef: string;
    assetVersion: string;
    assetName: string;
    assetDescription?: string;
    nodeId: string;
    position: { x: number; y: number };
    scale: number;
    isOpen: boolean;

    constructor(options: AssetTypeDetailsConstructorOptions) {
        const { name, description, version, typeId, position, scale, nodeId, creatingFromJson } =
            options;

        this.assetName = name || typeId.slice(24, 50);
        this.assetVersion = version;
        this.assetDescription = description || '';
        this.assetRef = creatingFromJson ? typeId : `${typeId}@${version.split('.')[0] || ''}`;
        this.nodeId = nodeId || uuid();
        this.position = { ...position };
        this.scale = scale || 1;
        this.isOpen = false;
    }
}

export class AssetType {
    assetRef: string;
    assetVersion: string;
    assetName: string;
    assetDescription: string;
    assetTags: string[];
    assetType: string;
    childs?: AssetType[];
    isReferencedType?: boolean;
    referencedTypes?: ReferenceTypeValueItem[];
    objectId?: string;
    constructor(options: AssetTypeConstructorOptions) {
        const { id, description, version, tags, name, model, childs } = options;
        this.assetRef = id.value;
        this.assetVersion = version.value;
        this.assetName = name.value || id.value;
        this.assetDescription = description.value;
        this.assetTags = tags;
        this.assetType = model.value;

        if (childs && childs.length > 0) {
            this.childs = [];
            childs.forEach((child) => {
                const asset = CreateAsset(child);
                if (asset && this.childs) {
                    this.childs.push(asset);
                }
            });
        } else {
            delete this.childs;
        }
    }
}

export function CreateAsset(options: AssetTypeConstructorOptions) {
    const { id, description, version, tags, name, model } = options;
    if (!(id.value || description.value || version.value || tags || name.value || model.value)) {
        return null;
    } else {
        return new AssetType(options);
    }
}
