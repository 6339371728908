import _ from 'lodash';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';
import {
    AlarmTypeDetailsWithMappings,
    EventTypeDetailsWithMappings,
    FunctionTypeDetailsWithMappings,
} from './types';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import AlarmTypeDetail from '../../transformers/AssetType/AlarmType';
import EventTypeDetail from '../../transformers/AssetType/EventType';

export interface TableData {
    objectTypeDetails: ObjectTypeDetail[];
    functionTypeDetails: FunctionTypeDetailsWithMappings[];
    alarmTypeDetails: AlarmTypeDetailsWithMappings[];
    eventTypeDetails: EventTypeDetailsWithMappings[];
}

class TableController {
    public objectTypeDetails: ObjectTypeDetail[];
    public functionTypeDetails: FunctionTypeDetailsWithMappings[];
    public alarmTypeDetails: AlarmTypeDetailsWithMappings[];
    public eventTypeDetails: EventTypeDetailsWithMappings[];
    constructor() {
        this.objectTypeDetails = [];
        this.functionTypeDetails = [];
        this.alarmTypeDetails = [];
        this.eventTypeDetails = [];
    }

    static fromJSON(options: { canvasJson: ComputeModelFromJson['json'] }) {
        const { canvasJson } = _.cloneDeepWith(options);

        const objectTypeDetails: ObjectTypeDetail[] = [];
        const functionTypeDetails: FunctionTypeDetailsWithMappings[] = [];
        const alarmTypeDetails: AlarmTypeDetailsWithMappings[] = [];
        const eventTypeDetails: EventTypeDetailsWithMappings[] = [];
        canvasJson.assetData.forEach((asset) => {
            if (asset instanceof ObjectTypeDetail) {
                objectTypeDetails.push(asset);
            } else if (asset instanceof FunctionTypeDetail) {
                functionTypeDetails.push(
                    TableController.convertFunctionTypeDetail({
                        functionAsset: asset,
                    })
                );
            } else if (asset instanceof AlarmTypeDetail) {
                alarmTypeDetails.push(
                    TableController.convertAlarmTypeDetail({
                        alarmAsset: asset,
                    })
                );
            } else {
                eventTypeDetails.push(
                    TableController.convertEventTypeDetail({
                        eventAsset: asset,
                    })
                );
            }
        });

        canvasJson.connectionData.forEach((connection) => {
            const nodeId = connection.input.asset.nodeId;
            const inputId = connection.input.circleData.id;

            const functionAsset = functionTypeDetails.find((asset) => asset.nodeId === nodeId);
            if (functionAsset) {
                const input =
                    functionAsset.inputs.find((input) => input.id === inputId) ||
                    functionAsset.inhibits.find((input) => input.id === inputId) ||
                    functionAsset.alarmInputs.find((input) => input.id === inputId);
                if (input) {
                    input.mappingDetails.push(connection);
                    input.trigger = !!connection.trigger;
                }
            }

            const alarmAsset = alarmTypeDetails.find((asset) => asset.nodeId === nodeId);
            if (alarmAsset) {
                const input = alarmAsset.inputs.find((input) => input.id === inputId);
                if (input) {
                    input.mappingDetails.push(connection);
                    input.trigger = !!connection.trigger;
                }
            }

            const eventAsset = eventTypeDetails.find((asset) => asset.nodeId === nodeId);
            if (eventAsset) {
                const input = eventAsset.inputs.find((input) => input.id === inputId);
                if (input) {
                    input.mappingDetails.push(connection);
                    input.trigger = !!connection.trigger;
                }
            }
        });

        return { functionTypeDetails, objectTypeDetails, alarmTypeDetails, eventTypeDetails };
    }

    static convertFunctionTypeDetail(options: {
        functionAsset: FunctionTypeDetail;
    }): FunctionTypeDetailsWithMappings {
        const { functionAsset } = options;
        functionAsset.inputs = functionAsset.inputs.map((input: any) => {
            input.mappingDetails = [];
            return input;
        });

        functionAsset.inhibits = functionAsset.inhibits.map((input: any) => {
            input.mappingDetails = [];
            return input;
        });

        functionAsset.alarmInputs = functionAsset.alarmInputs.map((input: any) => {
            input.mappingDetails = [];
            return input;
        });

        return functionAsset as FunctionTypeDetailsWithMappings;
    }

    static convertAlarmTypeDetail(options: {
        alarmAsset: AlarmTypeDetail;
    }): AlarmTypeDetailsWithMappings {
        const { alarmAsset } = options;
        alarmAsset.inputs = alarmAsset.inputs.map((input: any) => {
            input.mappingDetails = [];
            return input;
        });
        return alarmAsset as AlarmTypeDetailsWithMappings;
    }

    static convertEventTypeDetail(options: {
        eventAsset: EventTypeDetail;
    }): EventTypeDetailsWithMappings {
        const { eventAsset } = options;
        eventAsset.inputs = eventAsset.inputs.map((input: any) => {
            input.mappingDetails = [];
            return input;
        });
        return eventAsset as EventTypeDetailsWithMappings;
    }

    static toJSON = (options: { tableData: TableData }) => {
        const assetData: LocalJson['assetData'] = [];
        const connectionData: LocalJson['connectionData'] = [];
        const { tableData } = options;

        tableData.objectTypeDetails.forEach((asset) => {
            assetData.push(asset);
        });

        tableData.functionTypeDetails.forEach((asset) => {
            asset.inputs.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0 || !!input.defaultValue) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });

            asset.inhibits.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0 || !!input.defaultValue) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });

            asset.alarmInputs.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0 || !!input.defaultValue) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });
            asset.eventInputs.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0 || !!input.defaultValue) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });
            assetData.push(asset);
        });

        tableData.alarmTypeDetails.forEach((asset) => {
            asset.inputs.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });

            assetData.push(asset);
        });

        tableData.eventTypeDetails.forEach((asset) => {
            asset.inputs.forEach((input) => {
                input.mappingDetails.forEach((connection) => {
                    connectionData.push({
                        ...connection,
                        trigger: input.trigger,
                    });
                });
                if (input.mappingDetails.length > 0) {
                    input.connected = true;
                } else {
                    input.connected = false;
                }
            });

            assetData.push(asset);
        });

        return { assetData, connectionData };
    };

    static getConnectionById = (options: {
        inputIoId: string;
        nodeId: string;
        tableData: TableData;
    }) => {
        const { inputIoId, nodeId, tableData } = options;
        const asset = tableData.functionTypeDetails.find(
            (functionAsset) => functionAsset.nodeId === nodeId
        );

        if (asset) {
            const input =
                asset.inputs.find((input) => input.id === inputIoId) ||
                asset.inhibits.find((input) => input.id === inputIoId) ||
                asset.alarmInputs.find((input) => input.id === inputIoId) ||
                asset.eventInputs.find((input) => input.id === inputIoId);
            if (input) {
                return input.mappingDetails.length > 0 ? input.mappingDetails : false;
            }
        }
        return false;
    };

    static checkConnectionBetweenIO = (options: {
        inputIoId: string;
        outputIoId: string;
        inputNodeId: string;
        outputNodeId: string;
        tableData: TableData;
    }) => {
        const { inputIoId, inputNodeId, outputIoId, outputNodeId, tableData } = options;
        const asset = tableData.functionTypeDetails.find(
            (functionAsset) => functionAsset.nodeId === inputNodeId
        );

        if (asset) {
            const input =
                asset.inputs.find((input) => input.id === inputIoId) ||
                asset.inhibits.find((input) => input.id === inputIoId) ||
                asset.alarmInputs.find((input) => input.id === inputIoId) ||
                asset.eventInputs.find((input) => input.id === inputIoId);
            if (input) {
                return input.mappingDetails.find(
                    (connection) => connection.output.circleData.id === outputIoId
                );
            }
        }
        return false;
    };
}

export default TableController;
