import { Dispatch } from 'redux';
import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import EditToolBox from './EditToolBox';
import {
    getAssetsList,
    objectListSearch,
    functionListSearch,
    getComputeModelsApi,
    getComputeModelsStore,
    changeActiveModel,
    getModelUsedbyInstances,
    updateActiveParentKey,
} from '../../../store/modelsPage/actions';
import { StoreState } from '../../../store';
import {
    MODEL_TYPES,
    CONFIGURATION_TOOL_MODE,
    DELETE_MODEL_TYPE,
    BUTTON_TITLE,
    MODEL_SAVE_TYPE,
} from '../../../utils/constants/appConstants';
import CanvasController from '../../../components/Fabric';
import Flex from '../../../components/shared/Flex';

import LeftNavigationData, { TypeMenuAction } from '../../../components/LeftNavigationData';
import CreateModel from '../CreateModel';
import { showModal } from '../../../store/modal/action';
import {
    CREATE_NEW_MODEL,
    DELETE_MODEL_MSG,
    CONFIRMATION_BUTTON,
    UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
} from '../../../utils/constants/uiConstants';
import { AssetType } from '../../../transformers/AssetType';
import { ComputeModelFromJson } from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import AppSettings from '../../../services/AppSettings';
import { AbilityService } from 'sce-engg-model-19.09';
import { convertModelDetailsToAssetType } from '../../../utils/helpers';
import { ModelsPageState } from '../../../store/modelsPage/types';
import ConfirmationMessage from '../../../components/Fabric/utils/ConfirmationMessage';
import { showDialog, hideDialog } from '../../../store/dialog/action';
import MessageModal from '../../../components/MessageModal';
import { updateConfigurationToolMode } from '../../../store/configurationTool/actions';
import { ImportModalPayload } from '../../../store/exportAsset/types';
import {
    showImportModal,
    computeModelsForExport,
    saveModelsToExport,
    exportModels,
} from '../../../store/exportAsset/actions';
import TypesLeftNav from '../TypesLeftNav';
import { IgnorePlugin } from 'webpack';
import { commonAuthHelper } from '../../../utils/types/commonInterface';

interface AppProps {
    canvas: CanvasController;
    toggleImportExportDialog: () => void;
}

export function LeftNavigation(
    props: AppProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
    const [availableTypes, setAvailableTypes] = useState([] as AssetType[]);
    const [selectedItem, setSelectedItemId] = useState('');

    useEffect(() => {
        const token = commonAuthHelper.getToken();
        if (!!token) AbilityService.decodeToken(token);
        props.getAssetsList();
    }, []);

    useEffect(() => {
        const typesList = props.computeModels.entities.map((item) => {
            const assetType = convertModelDetailsToAssetType(
                props.computeModels.byId[item].modelDetails
            );
            assetType.objectId = props.computeModels.byId[item].objectId;
            assetType.referencedTypes = props.computeModels.byId[item].referencedTypes;
            return assetType;
        });
        setAvailableTypes([...typesList]);
    }, [props.computeModels.entities]);

    const functionList = props.functionTypeList.map(
        (objectId) => props.functionMasterList.byId[objectId]
    );

    const objectList = props.objectTypeList.map(
        (objectId) => props.objectMasterList.byId[objectId]
    );

    const handeTypeMenuAction = useCallback((actionName: TypeMenuAction) => {
        switch (actionName) {
            case TypeMenuAction.OPEN_EDIT_MODE: {
                props.updateConfigurationToolMode();
                break;
            }
            case TypeMenuAction.SHOW_SAVEAS_MODAL: {
                props.showUpdateModalToSave();
                break;
            }
            case TypeMenuAction.SHOW_UPDATE_MODAL: {
                props.showUpdateModal();
                break;
            }
            case TypeMenuAction.SHOW_USED_INSTANCE_MODAL: {
                props.showUsedInstanceModel();
                break;
            }
            case TypeMenuAction.SHOW_DELETE_MODAL: {
                props.delteModelPopup();
                break;
            }
            default: {
                console.log('invalid action');
            }
        }
    }, []);
    const handleSelectedItem = (itemId: string) => {
        setSelectedItemId(itemId);
    };
    const handleSelectedType = useCallback(
        (
            modelId: string,
            node: AssetType,
            selectedItemId: string,
            computeModels: ModelsPageState['computeModels']
        ) => {
            const currentRoutePath = window.location.pathname;
            if (props.activeAsset && props.activeAsset.assetRef !== node.assetRef) {
                const computeModelData: ComputeModelFromJson[] = Object.values({
                    ...computeModels.byId,
                });
                handleSelectedItem(selectedItemId);
                props.changeActiveAsset({
                    computeModels: computeModelData,
                    activeAsset: node,
                    activeModelId: modelId,
                    currentRoutePath: currentRoutePath,
                    addIsDirtyToConditions: true,
                });
            } else {
                props.changeActiveModel(modelId);
            }
        },
        [selectedItem, props.activeAsset]
    );

    return props.editMode === true ? (
        <EditToolBox
            isAssetLoading={props.isAssetLoading}
            canvas={props.canvas}
            functionTypeList={functionList}
            objectTypeList={objectList}
            objectListSearch={props.objectListSearch}
            functionListSearch={props.functionListSearch}
            computeModels={availableTypes}
        />
    ) : (
        <div id="left-navigation">
            <Flex fill className="left-navigation-panel-container">
                <div className="left-navigation-data">
                    <TypesLeftNav
                        showMenu={true}
                        getComputeModelsApi={props.getComputeModelsApi}
                        canvas={props.canvas}
                        isNew={!!props.isNew}
                        isDirty={props.isDirty}
                        permissions={props.permissions}
                        editMode={props.editMode}
                        searchText={props.searchText}
                        selectedItemId={selectedItem}
                        getNodeData={props.getNodeData}
                        activeAsset={props.activeAsset}
                        onSearch={props.objectListSearch}
                        activeModelId={props.activeModelId}
                        computeModels={props.computeModels}
                        searchValue={props.objectSearchValue}
                        showCreateModel={props.showCreateModel}
                        supportedModels={props.supportedModels}
                        activeParentKey={props.activeParentKey}
                        handleSelectedType={handleSelectedType}
                        handleSelectedItem={handleSelectedItem}
                        usedInstanceList={props.usedInstanceList}
                        handeTypeMenuAction={handeTypeMenuAction}
                        computeModelsLoading={props.computeModelsLoading}
                        updateActiveParentKey={props.updateActiveParentKey}
                        title={`${MODEL_TYPES.OBJECTS.label.toUpperCase()}`}
                        showConfirmationDialog={props.showConfirmationDialog}
                        showImportModal={props.showImportModal}
                        toggleImportExportDialog={props.toggleImportExportDialog}
                        multipleModals={props.multipleModals}
                        computeModelsForExport={props.computeModelsForExport}
                        saveModelsToExport={props.saveModelsToExport}
                        selectedModalDetails={props.selectedModalDetails}
                        exportModelsArray={(val: any) => props.exportModelsArray(val)}
                        computeModelDeleting={props.computeModelDeleting}
                    />
                </div>
            </Flex>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        objectMasterList: state.modelsPage.objectTypeList.masterList,
        functionMasterList: state.modelsPage.functionTypeList.masterList,
        functionTypeList: state.modelsPage.functionTypeList.filterList,
        objectTypeList: state.modelsPage.objectTypeList.filterList,
        objectSearchValue: state.modelsPage.objectTypeList.filters.search,
        functionSearchValue: state.modelsPage.functionTypeList.filters.search,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        computeModels: state.modelsPage.computeModels,
        activeModelId: state.modelsPage.activeModel.id,
        isAssetLoading: state.loader.supportedTypes.isLoading,
        computeModelDeleting: state.loader.computeModelDeleting,
        activeAsset: state.modelsPage.activeAsset,
        activeParentKey: state.modelsPage.activeParentKey,
        computeModelsLoading: state.loader.computeModelLoading.isLoading,
        permissions: {
            hasDelete: state.modelsPage.modelPermission.hasDelete,
            hasEdit: state.modelsPage.modelPermission.hasEdit,
            hasExport: state.modelsPage.modelPermission.hasExport,
            hasSave: state.modelsPage.modelPermission.hasSave,
            hasImport: state.modelsPage.modelPermission.hasImport,
        },
        isDirty: state.configurationTool.isDirty,
        isNew: state.modelsPage.activeModel.isNew,
        searchText: state.modelsPage.objectTypeList.filters.search,
        supportedModels: state.modelsPage.supportedModels,
        usedInstanceList: state.modelsPage.modelUsedInstances,
        multipleModals: state.exportAsset.multipleModels,
        selectedModalDetails: state.exportAsset.selectedModalDetails,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        objectListSearch: (search: string) => {
            dispatch(objectListSearch({ search }));
        },
        functionListSearch: (search: string) => {
            dispatch(functionListSearch({ search }));
        },
        getAssetsList: () => {
            dispatch(getAssetsList());
        },
        getComputeModelsApi: (data: any) => {
            dispatch(getComputeModelsApi(data));
        },
        showCreateModel: () => {
            dispatch(
                showModal({
                    component: CreateModel,
                    modalTitle: CREATE_NEW_MODEL.CREATE_MODEL,
                    data: {
                        submitBtnText: CREATE_NEW_MODEL.CREATE_BTN_TEXT,
                        mode: 'CREATE',
                    },
                })
            );
        },
        showConfirmationDialog: (modelId: string) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    dispatch(changeActiveModel({ modelId }));
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
        showUpdateModal: () => {
            dispatch(
                showModal({
                    component: CreateModel,
                    modalTitle: CREATE_NEW_MODEL.MODEL_DETAILS,
                    data: {
                        submitBtnText: CREATE_NEW_MODEL.UPDATE_BTN_TEXT,
                    },
                })
            );
        },
        showUpdateModalToSave: () => {
            dispatch(
                showModal({
                    component: CreateModel,
                    modalTitle: CREATE_NEW_MODEL.SAVE_AS_TITLE,
                    data: {
                        submitBtnText: BUTTON_TITLE.SAVE,
                        mode: MODEL_SAVE_TYPE.SAVE_AS,
                    },
                })
            );
        },
        updateConfigurationToolMode: () => {
            dispatch(
                updateConfigurationToolMode({
                    mode: CONFIGURATION_TOOL_MODE.EDIT,
                })
            );
        },
        showUsedInstanceModel: () => {
            dispatch(getModelUsedbyInstances({ pageNumber: 1, pageSize: 100 }));
        },
        changeActiveAsset: (payload: {
            computeModels: ComputeModelFromJson[];
            activeAsset: AssetType;
            activeModelId: string;
            currentRoutePath?: string | undefined;
            addIsDirtyToConditions: boolean;
        }) => {
            dispatch(getComputeModelsStore(payload));
        },
        changeActiveModel: (modelId: string) => {
            dispatch(changeActiveModel({ modelId }));
        },
        delteModelPopup: () => {
            ConfirmationMessage({
                warningMessage: DELETE_MODEL_MSG.DELETE_MODEL_MSG,
                deleteType: DELETE_MODEL_TYPE.MODEL,
                messageModalTitle: 'Delete',
                acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            });
        },
        getNodeData: (node: AssetType) => {
            const currentRoutePath = window.location.pathname;
            dispatch(
                getComputeModelsApi({
                    objectRef: node.assetRef,
                    version: node.assetVersion,
                    asset: node,
                    currentRoutePath: currentRoutePath,
                })
            );
        },
        updateActiveParentKey: (key: string) => {
            dispatch(updateActiveParentKey(key));
        },
        showImportModal: (payload: ImportModalPayload) => {
            dispatch(showImportModal(payload));
        },
        computeModelsForExport: (payload: {
            objectRef: string;
            asset: AssetType;
            uniqueTypeId: string;
        }) => {
            dispatch(computeModelsForExport(payload));
        },
        saveModelsToExport: (val: any) => {
            dispatch(saveModelsToExport(val));
        },
        exportModelsArray: (val: {
            mode: 'MODEL' | 'OBJECT' | 'MULTIPLE_OBJECTS';
            selectedModalDetails: [];
        }) => dispatch(exportModels(val)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
