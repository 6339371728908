import React from "react";
import AssetCriticalityModalForm from "./AssetCriticalityModalForm";
interface AssetConfigModalProps {}

const AssetCriticalityModal = (props: AssetConfigModalProps) => {
    return (
        <div>
            <AssetCriticalityModalForm />
        </div>
    );
};

export default AssetCriticalityModal;
