import React from 'react';
import { StoreState } from '../../../../store';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import AlarmTableBody from './AlarmTableBody';
import AlarmTableHeader from './AlarmTableHeader';
import { FUNCTION_MODE } from '../../../../utils/constants/appConstants';
import { Button } from '@abb/abb-common-ux-react';
import { Dispatch } from 'redux';
import { showAlarmModal } from '../../../../store/AlarmModal/action';
import { CREATE_ALARM } from '../../../../utils/constants/uiConstants';
import AlarmConfigurationModal from '../../../AlarmConfigurationModal';
import {
    getDragTableData,
    updateAlarmMappingTableData,
    getAlarmMapping,
    getUpdatedDropTableData,
} from '../../../../routes/Functions/helper';
import { DragDropTableData, FunctionAlarmType } from '../../../../store/function/types';
import { updateAlarmDragDropTables } from '../../../../store/function/action';

interface AlarmFunctionTypeProps {
    showMessageModal?: any;
}

export function AlarmFunctionType(
    props: AlarmFunctionTypeProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    return props.selectedAlarmType ? (
        <div className="CustomFunctionView conditionFunctionOverview">
            <div>
                <Table>
                    <AlarmTableHeader />
                    <AlarmTableBody
                        selectedAlarmType={props.selectedAlarmType}
                        functionMode={'VIEW'}
                    />
                </Table>
            </div>
        </div>
    ) : (
        <></>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedAlarmType: state.functions.selectedAlarmType,
        functionHeaderDetails: state.functions.functionHeaderDetails,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showConfigurationModal: () => {
            dispatch(
                showAlarmModal({
                    component: AlarmConfigurationModal,
                    modalTitle: CREATE_ALARM.NEW_ALARM,
                    data: {
                        submitBtnText: CREATE_ALARM.CREATE,
                        mode: 'CREATE',
                    },
                    customClassName: 'alarm-configuration-dialog-class',
                })
            );
        },

        updateAlarmDragDropTables: (payload: DragDropTableData) => {
            dispatch(updateAlarmDragDropTables(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlarmFunctionType);
