import React, { Fragment, useState, useEffect } from 'react';

import { Input, Icon, Checkbox, Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import { FunctionMode, FunctionAlarmType } from '../../../../store/function/types';
import { FUNCTION_MODE } from '../../../../utils/constants/appConstants';

interface AlarmTableBodyProps {
    functionMode: FunctionMode;
    selectedAlarmType: FunctionAlarmType;
}

function AlarmTableBody(props: AlarmTableBodyProps) {
    return (
        <tbody>
            <tr>
                <td className="width-15">{props.selectedAlarmType.name}</td>

                <td className="width-13.5">{props.selectedAlarmType.description}</td>
            </tr>
        </tbody>
    );
}

export default AlarmTableBody;
