import { ObjectTypeConstructorOptions } from './types';
import { IODataTypes, AssetsType, PartialBy } from '../../../components/Fabric/types';

import { AssetTypeDetail } from '..';
import { ioConverter } from '../utils/ioConverter';
import { outputFinder } from '../../utils/outputFinder';
import { ReferenceTypeValueItem } from '../../ComputeModel/fromJson/types';

const convertObjectIdToPath = (id: string) => {};

class ObjectTypeDetail extends AssetTypeDetail {
    outputs: {
        name: string;
        path: string;
        id: string;
        dataType: IODataTypes;
        connected: boolean;
        alarmId?: string;
        eventId?: string;
    }[];
    assetType: AssetsType | string;
    objectInstance: boolean;
    isReferencedType: boolean;
    referencedTypes?: ReferenceTypeValueItem[];
    objectId?: string;
    constructor(options: ObjectTypeConstructorOptions) {
        super(options);
        const { outputs, creatingFromJson, assetType } = options;
        this.assetType = assetType || AssetsType.OBJECT;
        this.outputs = creatingFromJson
            ? outputs
            : outputs.map((output) => {
                  output.id = `${this.nodeId}` + output.id;

                  return output;
              });

        this.objectInstance = !!options.objectInstance;
        this.isReferencedType = !!options.referencedType;
    }
}

export function CreateObjectTypeDetail(
    options: PartialBy<ObjectTypeConstructorOptions, 'outputs'> & {
        variables: {};
        alarms: {};
        events: {};
    }
) {
    const { description, name, typeId, variables, alarms, events } = options;
    let outputs = outputFinder(variables);
    const alarmOutputs = outputFinder(alarms);
    const eventOutputs = outputFinder(events);

    outputs = [...outputs, ...alarmOutputs, ...eventOutputs];
    if (!(typeId || (outputs && outputs.length > 0))) {
        return null;
    }

    //@ts-ignore
    return new ObjectTypeDetail({ ...options, outputs });
}

export default ObjectTypeDetail;
