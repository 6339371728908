import React, { useCallback } from 'react';
import { Input } from '@abb/abb-common-ux-react';
import Flex from '../shared/Flex';
import './style.scss';

interface AppProps {
    text: string;
    onClick?: () => any;
    onDelete?: () => any;
    isDeletable?: boolean;
}

function RemovableChip(props: AppProps) {
    const onDelete = (e: any) => {
        //e.preventPropagation();
        props.onDelete && props.onDelete();
    };

    const onClick = (e: any) => {
        //e.preventPropagation();
        props.onClick && props.onClick();
    };

    return (
        <>
            <div className="removable-chip">
                <span onClick={onClick}>{props.text}</span>
                {props.isDeletable && (
                    <span className="closebtn" onClick={onDelete}>
                        &times;
                    </span>
                )}
            </div>
        </>
    );
}

export default RemovableChip;
