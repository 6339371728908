import _ from 'lodash';

import { ExportAssetReduceState, ExportAssetFunctionType, functionVersionType } from './types';
import { ActionTypes } from '../actionTypes';
import { findPassWordDetails } from '../../utils/helpers';
import { UpdateActiveFunctionType } from '../function/types';
import { FUNCTION_MODE } from '../../utils/constants/appConstants';
import { getOptionsForUpdatingFunctions } from '../../routes/Functions/helper';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { any } from 'prop-types';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { filterTypesBylatestVersion } from '../modelsPage/reducer';

const initialState: ExportAssetReduceState = {
    functionVersionDetails: [],
    // selectedFunctionToExport: [],
    // availableGenericFunctionDetails: [],
    selectedFunctionDetails: [],
    selectedModalDetails: [],
    summaryModalDetails: {
        showSummaryModal: false,
        mode: '',
        route: '',
        data: undefined,
    },
    multipleModels: [],
    selectedMultipleModels: [],
    importMode: false,
    singleImportFunctionTypes: [],
    isImportValid: true,
    filesTobeImported: {},
    isImportModalOpen: false,
    filesDataForImport: {},
    availableItemsForImport: [],
    selectedItemsForImport: [],
    passwordDetails: [],
    dependantLibraries: [],
    typeIdDisabledDuringImport: true,
    importedModelDetails: {},
    selectedFunctionToView: undefined,
    functionMode: FUNCTION_MODE.VIEW,
    isLoading: false,
    functionHeaderDetails: {
        inputDetails: undefined,
        outputDetails: undefined,
        conditionDetails: undefined,
        calculationDetails: undefined,
    },
    zipFileName: '',
};
export const exportAssetReducer = (
    state: ExportAssetReduceState = initialState,
    action: ExportAssetFunctionType
): ExportAssetReduceState => {
    switch (action.type) {
        case ActionTypes.GET_FUNCTION_VERSIONS_SUCCESS: {
            return {
                ...state,
                functionVersionDetails: [...state.functionVersionDetails, ...action.payload],
            };
        }

        case ActionTypes.REMOVED_FROM_DROPDOWN: {
            const { functionVersionDetails, selectedFunctionDetails } = action.payload;
            return {
                ...state,
                functionVersionDetails,
                selectedFunctionDetails,
            };
        }

        case ActionTypes.UPDATE_FUNCTION_LIST_TO_GET_JSON: {
            const { checkboxValue, functionDetails } = action.payload;
            let updatedSelectedFunctionDetails: UpdateActiveFunctionType[] = [];
            if (!checkboxValue) {
                updatedSelectedFunctionDetails = [
                    ...state.selectedFunctionDetails,
                    ...functionDetails,
                ];
            } else {
                if (state.functionVersionDetails.length === 1) {
                    state.selectedFunctionDetails.forEach((item) => {
                        functionDetails.forEach((payloadItem) => {
                            if (
                                item.typeId !== payloadItem.typeId ||
                                item.version !== payloadItem.version
                            ) {
                                updatedSelectedFunctionDetails.push(item);
                            }
                        });
                    });
                } else {
                    if (functionDetails.length > 0) {
                        state.selectedFunctionDetails.forEach((item) => {
                            functionDetails.forEach((payloadItem) => {
                                if (
                                    item.typeId !== payloadItem.typeId ||
                                    item.version !== payloadItem.version
                                ) {
                                    updatedSelectedFunctionDetails.push(item);
                                }
                            });
                        });
                    }
                }
            }

            return {
                ...state,
                selectedFunctionDetails: [...updatedSelectedFunctionDetails],
            };
        }
        case ActionTypes.CLEAR_SELECTED_FUNCTIONS: {
            return {
                ...state,
                selectedFunctionDetails: [],
                functionVersionDetails: [],
            };
        }

        case ActionTypes.SAVE_MODELS_TO_EXPORT: {
            return { ...state, selectedModalDetails: action.payload };
        }

        case ActionTypes.SHOW_SUMMARY_MODAL: {
            return {
                ...state,
                summaryModalDetails: {
                    ...state.summaryModalDetails,
                    ...action.payload.summaryModalDetails,
                },
            };
        }
        case ActionTypes.CLOSE_SUMMARY_MODAL: {
            return {
                ...state,
                summaryModalDetails: {
                    showSummaryModal: false,
                    mode: '',
                    route: '',
                    data: undefined,
                },
            };
        }
        case ActionTypes.COMPUTE_MODELS_API_FOR_EXPORT_SUCCESS: {
            const { fetchedModals, isDelete } = action.payload;
            let updatedModals = [];
            if (isDelete) {
                updatedModals = [...fetchedModals];
            } else {
                updatedModals = [...state.multipleModels];
                let modalsAvailable = updatedModals.map((item: any) => item.uniqueTypeId);
                fetchedModals.forEach((fetchedModal: any) => {
                    let oldModalIndex = modalsAvailable.indexOf(fetchedModal.uniqueTypeId);
                    if (oldModalIndex > -1) {
                        updatedModals[oldModalIndex] = fetchedModal;
                    } else {
                        updatedModals.push(fetchedModal);
                    }
                });
            }
            return {
                ...state,
                multipleModels: updatedModals,
            };
        }
        case ActionTypes.SET_IMPORT_MODAL: {
            return { ...state, importMode: action.payload };
        }

        case ActionTypes.UPDATE_SINGLE_IMPORT_FUNC_TYPES: {
            const functionTypes = action.payload;

            return {
                ...state,
                singleImportFunctionTypes: functionTypes,
            };
        }

        case ActionTypes.HANDLE_FILES_TO_BE_IMPORTED: {
            const { filesToBeImported } = action.payload;
            return {
                ...state,
                filesTobeImported: filesToBeImported,
            };
        }
        case ActionTypes.SET_IMPORT_VALIDATION_STATUS: {
            const isImportValid = action.payload;
            return {
                ...state,
                isImportValid,
            };
        }
        case ActionTypes.SHOW_IMPORT_MODAL: {
            const { currentRoute } = action.payload;
            return {
                ...state,
                isImportModalOpen: true,
                importContentRoute: currentRoute,
                selectedItemsForImport: [],
            };
        }

        case ActionTypes.HIDE_IMPORT_MODAL: {
            return {
                ...state,
                isImportModalOpen: false,
                selectedItemsForImport: [],
                passwordDetails: [],
                availableItemsForImport: [],
                isImportValid: true,
            };
        }
        case ActionTypes.REMOVE_FROM_SELECTED_ITEMS_FOR_IMPORT: {
            const currentItem = action.payload;
            const selectedItems = state.selectedItemsForImport.filter(
                (item) => item !== currentItem
            );
            const passwordDetails = findPassWordDetails([...selectedItems]);
            return {
                ...state,
                selectedItemsForImport: selectedItems,
                passwordDetails,
            };
        }
        case ActionTypes.ADD_TO_SELECTED_ITEMS_FOR_IMPORT: {
            const currentItem = action.payload;

            const passwordDetails = findPassWordDetails(
                Array.isArray(currentItem)
                    ? currentItem
                    : [...state.selectedItemsForImport, currentItem]
            );
            return {
                ...state,
                selectedItemsForImport: currentItem,
                passwordDetails,
            };
        }
        case ActionTypes.CLEAR_SELECTED_ITEMS_FOR_IMPORT: {
            return {
                ...state,
                selectedItemsForImport: [],
            };
        }
        case ActionTypes.LOAD_CHOSEN_FILE_DATA_SUCCESS: {
            return {
                ...state,
                availableItemsForImport: action.payload.filesToBeImported,
                dependantLibraries: action.payload.dependantLibrariesForTypes,
                selectedItemsForImport:
                    action.payload.filesToBeImported.length === 0
                        ? []
                        : state.selectedItemsForImport,
            };
        }
        case ActionTypes.TOGGLE_TYPE_ID_DURING_IMPORT: {
            const { showDisabled, modelDetails } = action.payload;
            return {
                ...state,
                typeIdDisabledDuringImport: showDisabled,
                importedModelDetails: showDisabled ? {} : modelDetails,
            };
        }
        case ActionTypes.SET_DETAILS_FOR_TYPE_LEVEL_IMPORT: {
            const { dependantLibraries, passwordDetails, selectedItemsForImport } = action.payload;

            return {
                ...state,
                dependantLibraries,
                passwordDetails,
                selectedItemsForImport,
            };
        }
        case ActionTypes.GET_EXPORTABLE_FUNCTIONS_SUCCESS: {
            return {
                ...state,
                selectedFunctionDetails: [],
                functionVersionDetails: [],
                selectedFunctionToView: undefined,
                functionHeaderDetails: {
                    inputDetails: undefined,
                    outputDetails: undefined,
                    conditionDetails: undefined,
                    calculationDetails: undefined,
                },
            };
        }

        case ActionTypes.HANDLE_SELCTALL_FOR_GENERIC_FUNCTION_EXPORT: {
            const isSelected = action.payload;

            let selectedFunctionsForExport: UpdateActiveFunctionType[] = [];
            debugger;
            if (isSelected) {
                state.functionVersionDetails.forEach((item) => {
                    item.versions.forEach((version) => {
                        const functionItem: UpdateActiveFunctionType = { ...item, version };
                        selectedFunctionsForExport.push(functionItem);
                    });
                });
                state.selectedFunctionDetails = [...selectedFunctionsForExport];
            } else {
                state.selectedFunctionDetails = [];
            }
            return {
                ...state,
            };
        }

        case ActionTypes.GET_SELECTED_FUNCTION_TYPE_FOR_EXPORT: {
            const { activeLibId } = action.payload;
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.UPDATE_SELECTED_FUNCTION_TYPE_FOR_EXPORT: {
            const typeDetails = { ...action.payload.typeDetails };

            let updatedTypeDetails = _.cloneDeepWith(typeDetails);
            if (
                !typeDetails.properties.settings &&
                typeDetails.properties.hasOwnProperty('endpoint')
            ) {
                delete updatedTypeDetails.properties['endpoint'];
                updatedTypeDetails.properties['settings'] = {
                    endpoint: (typeDetails.properties as any).endpoint,
                };
            }
            const { functionHeaderDetails, currentActiveView } = getOptionsForUpdatingFunctions(
                updatedTypeDetails,
                []
            );

            return {
                ...state,
                isLoading: false,
                functionHeaderDetails,
                selectedFunctionToView: currentActiveView,
            };
        }

        case ActionTypes.RESET_SELECTED_FUNCTION_TYPE_FOR_EXPORT: {
            return {
                ...state,
                selectedFunctionToView: undefined,
            };
        }

        case ActionTypes.CHANGE_ZIP_NAME_FOR_MODEL_EXPORT: {
            return {
                ...state,
                zipFileName: action.payload.fileName,
            };
        }

        case ActionTypes.UPDATE_EXPORTABLE_MODELS_LIST: {
            const { type, currentItem } = action.payload;
            const currentList = _.cloneDeepWith(state.multipleModels);

            const itemIndex = currentList.findIndex(
                (item) =>
                    item.uniqueTypeId === currentItem.properties.associatedObjectType.typeId.value
            );
            if (itemIndex !== -1) {
                const assetName = Object.keys(currentList[itemIndex]).filter(
                    (key) => key !== 'uniqueTypeId'
                )[0];
                let availableModels: ComputeModelToJson[] = currentList[itemIndex][assetName];

                const currentModelIndex = availableModels.findIndex(
                    (item) => item.objectId === currentItem.objectId
                );
                if (type === 'Update') {
                    if (currentModelIndex !== -1) {
                        availableModels[currentModelIndex] = { ...currentItem };
                    } else {
                        availableModels.push(currentItem);
                    }
                } else {
                    if (currentModelIndex !== -1) {
                        availableModels = availableModels.filter(
                            (item) => item.objectId !== currentItem.objectId
                        );
                    }
                }
                currentList[itemIndex][assetName] = filterTypesBylatestVersion({
                    models: availableModels,
                });

                state.multipleModels = [...currentList];
            }

            return {
                ...state,
            };
        }

        default: {
            return state;
        }
    }
};
