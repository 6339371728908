import { SceFunctionType } from 'sce-engg-model-19.09/lib/types/SceFunctionType';
import { ROUTE_PATHNAME } from '../constants/appConstants';

export const validateImportedJson = (
    importedJson: Object,
    currentRoute: typeof ROUTE_PATHNAME.FUNCTIONS | typeof ROUTE_PATHNAME.HOME
) => {
    let isValid = true;
    let files: any[] = [];
    let dependentLibraries: any[] = [];
    let isOldFunctionJson = false;
    if (Array.isArray(importedJson)) {
        importedJson.forEach((item: Object) => {
            if (currentRoute === ROUTE_PATHNAME.FUNCTIONS) {
                if (
                    item.hasOwnProperty('hash') &&
                    item.hasOwnProperty('isProtected') &&
                    item.hasOwnProperty('library') &&
                    item.hasOwnProperty('functionTypes')
                ) {
                    // validate function json.
                    const functionList = item['functionTypes'];
                    if (Array.isArray(functionList)) {
                        functionList.forEach((item) => {
                            const isValidFunction = validateFunctionTypeJson(item);
                            if (!isValidFunction) {
                                isValid = false;
                            }
                        });
                    }
                    if (isValid) {
                        files.push(item);
                    }
                } else {
                    const isFunctionJsonValid = validateFunctionTypeJson(item as any);
                    if (!isFunctionJsonValid) {
                        isValid = false;
                    } else {
                        isOldFunctionJson = true;
                    }
                }
            } else {
                /** handle the types import */

                if (!Array.isArray(item)) {
                    if (
                        item.hasOwnProperty('functionTypes') &&
                        item.hasOwnProperty('model') &&
                        !item.hasOwnProperty('libraryReferences')
                    ) {
                        item['libraryReferences'] = [];
                    }
                    if (
                        item.hasOwnProperty('functionTypes') &&
                        item.hasOwnProperty('model') &&
                        item.hasOwnProperty('libraryReferences')
                    ) {
                        files.push(item);
                    }
                } else {
                    //check for dependent library.
                    const dependantLibraryList = item;
                    dependantLibraryList.forEach((listItem) => {
                        dependentLibraries.push(listItem);
                    });
                }
                // TODO: add validation logic for types json over here.
            }
        });

        // update file data for generic function backward compatibility.
        if (isOldFunctionJson) {
            const genericFunctionFile = {
                hash: null,
                isProtected: false,
                library: null,
                functionTypes: [...importedJson],
            };
            files.push(genericFunctionFile);
        }
    }
    return { isValid: isValid, data: { files: files, dependentLibraries: dependentLibraries } };
};

export const validateFunctionTypeJson = (functionJson: SceFunctionType) => {
    let isValid = true;
    const { description, isExtensible, model, name, properties, tags, typeId, version } =
        functionJson;
    if ((!description && description !== "") || !model || !name || !properties || !tags || !typeId || !version) {
        isValid = false;
    }
    return isValid;
};
