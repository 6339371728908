import React, { useEffect, useState } from 'react';

import CodeMirror from 'codemirror';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/mode/javascript/javascript.js';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/solarized.css';
import 'codemirror/addon/hint/show-hint.css';
import './style.scss';

interface IEditorComponent {
    logicValue: string;
    dataSet: { text: string; displayText: string }[];
    handleInputChange: (val: string) => void;
    updateCodeMirrorInstance: (cmInstance: CodeMirror.EditorFromTextArea) => void;
}

const EditorComponent = (props: IEditorComponent) => {
    const [codemirroInstance, setCodemirrorInstance] = useState(
        undefined as CodeMirror.EditorFromTextArea | undefined
    );
    useEffect(() => {
        const textAreaElement = document.querySelector(
            '.wrapper-logic-codemirror'
        ) as HTMLTextAreaElement;
        if (textAreaElement) {
            try {
                const codemirror = CodeMirror.fromTextArea(textAreaElement, {
                    lineNumbers: true,
                    value: 'var b = 3;',
                    extraKeys: { "'$'": 'autocomplete' },
                    mode: 'text/javascript',
                    smartIndent: true,
                    autoCloseBrackets: true,
                    matchBrackets: true,
                });
                codemirror.setValue(props.logicValue);

                CodeMirror.registerHelper('hint', 'dictionaryHint', (cm: CodeMirror.Editor) => {
                    const cursor = cm.getCursor();
                    const token = cm.getTokenAt(cursor);
                    const start: number = token.start;
                    const end: number = cursor.ch;
                    const line: number = cursor.line;
                    const currentWord: string = token.string;
                    const list: any[] = props.dataSet.filter((item) => {
                        return item.text.indexOf(currentWord) >= 0;
                    });
                    return {
                        list: (list.length ? list : props.dataSet).sort(),
                        from: CodeMirror.Pos(line, start + currentWord.length),
                        to: CodeMirror.Pos(line, end),
                    };
                });
                CodeMirror.commands.autocomplete = function (cm) {
                    //@ts-ignore
                    CodeMirror.showHint(cm, CodeMirror.hint.dictionaryHint);
                };
                setCodemirrorInstance(codemirror);
                props.updateCodeMirrorInstance(codemirror);
            } catch (error) {
                console.log(error);
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (codemirroInstance) {
                codemirroInstance.toTextArea();
            }
        };
    }, []);

    return (
        <>
            <form>
                <textarea className="wrapper-logic-codemirror"></textarea>
            </form>
        </>
    );
};

export default EditorComponent;
