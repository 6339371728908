import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Input, Button } from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../store";
import { DEL_FUNCTION_MSG } from "../../utils/constants/appConstants";
import { CreateFunctionAssetDetails } from "../../store/function/action";
import { LibraryAndNodePayloadWithoutNodes } from "../../store/function/types";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../utils/constants/appConstants";
import { assert } from "console";
import { AssetsInfo } from "../../routes/Functions/type";
import CustomABBInput from "../CustomABBInput";

interface GetPasswordForLibrariesProps {
    submitBtnText: string;
    requiredActionAfterSubmit?: (
        typeDetails: CreateFunctionAssetDetails
    ) => void;
    submitBtnAction: (
        assetsInfo: AssetsInfo,
        functionsDetailsList: CreateFunctionAssetDetails[],
        libraryDetails: LibraryAndNodePayloadWithoutNodes[]
    ) => void;
    functionsDetailsList: CreateFunctionAssetDetails[];
    libraryDetails: LibraryAndNodePayloadWithoutNodes[];
    resetGlobalLibraryError: () => void;
    editOrView?: boolean;
    deletingFunction?: boolean;
}
const GetPasswordForLibraries = (
    props: GetPasswordForLibrariesProps & ReturnType<typeof mapStateToProps>
) => {
    const {
        assetsInfo,
        editOrView,
        typeDetails,
        submitBtnText,
        deleteLoading,
        libraryDetails,
        submitBtnAction,
        deletingFunction,
        libraryGlobalError,
        functionsDetailsList,
        decryptedLibraryDetails,
        resetGlobalLibraryError,
        requiredActionAfterSubmit,
        currentLibraryToDragAndDrop,
        addingFunctionsToExistingLibrary,
        loadingStateForDecryptedFunction,
    } = props;

    useEffect(() => {
        if (
            decryptedLibraryDetails.password &&
            typeDetails &&
            requiredActionAfterSubmit
        ) {
            requiredActionAfterSubmit(typeDetails);
        }
    }, [decryptedLibraryDetails, typeDetails, requiredActionAfterSubmit]);

    const [updatedLibraryDetails, setUpdatedLibraryDetails] = useState(
        _.cloneDeepWith(libraryDetails).filter(
            (libDetail) => libDetail.isIPProtected === true
        )
    );
    const [passwordType, setPasswordType] = useState(true);
    const [add, setadd] = useState(false);

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const handleOnChange = useCallback(
        (password, id) => {
            const newUpdatedLibraryDetails: LibraryAndNodePayloadWithoutNodes[] =
                _.cloneDeepWith(updatedLibraryDetails).map((item) => {
                    let updatedItem = item;
                    if (item.id === id) {
                        updatedItem.password = password;
                    }
                    return updatedItem;
                });
            setUpdatedLibraryDetails(newUpdatedLibraryDetails);
        },
        [updatedLibraryDetails]
    );

    const isNoPasswordMode = useMemo(() => {
        return (
            updatedLibraryDetails.length == 0 ||
            (!editOrView &&
                currentLibraryToDragAndDrop.length > 0 &&
                currentLibraryToDragAndDrop[0].libraryId ===
                    libraryDetails[0].id)
        );
    }, [
        editOrView,
        updatedLibraryDetails,
        currentLibraryToDragAndDrop,
        libraryDetails,
    ]);

    return (
        <React.Fragment>
            {deletingFunction && (
                <div className="delete-ip-protected-function-msg">
                    {DEL_FUNCTION_MSG}
                </div>
            )}
            {isNoPasswordMode && !libraryDetails[0].isIPProtected && (
                <div>
                    {` Are you sure you want to add
                    ${
                        (functionsDetailsList[0] as CreateFunctionAssetDetails)
                            .name
                    } to
                    ${libraryDetails[0].root}?`}
                </div>
            )}
            {updatedLibraryDetails.map(
                (item: LibraryAndNodePayloadWithoutNodes) => (
                    <React.Fragment key={item.id}>
                        <div>Library : {libraryDetails[0].root}</div>
                        <div>
                            Function :
                            {
                                (
                                    functionsDetailsList[0] as CreateFunctionAssetDetails
                                ).name
                            }
                        </div>
                        <CustomABBInput
                            dataType={passwordType ? "password" : "text"}
                            value={item.password}
                            onValueChange={(value) => {
                                if (libraryGlobalError.length > 0) {
                                    resetGlobalLibraryError();
                                }
                                handleOnChange(value, item.id);
                            }}
                            icon={passwordType ? "abb/view" : "abb/hide"}
                            onIconClick={() => {
                                if (
                                    item.password.length > 0 ||
                                    passwordType === false
                                ) {
                                    togglePasswordType();
                                }
                            }}
                            validator={() =>
                                libraryGlobalError.length > 0
                                    ? {
                                          valid: false,
                                          text: libraryGlobalError,
                                      }
                                    : { valid: true, text: "OK!" }
                            }
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                        />
                    </React.Fragment>
                )
            )}

            {isNoPasswordMode && libraryGlobalError.length > 0 && (
                <div className="password-error">{libraryGlobalError}</div>
            )}

            <div className="submit-button">
                {libraryDetails[0].isIPProtected && (
                    <div className="help-msg-wrapper">
                        <p>
                            <span className="note">{NOTE_MESSAGE}</span>
                            <span className="admin_help_message">
                                {ADMIN_HELP_MESSAGE}
                            </span>
                        </p>
                    </div>
                )}
                <Button
                    type="primary-blue"
                    text={
                        loadingStateForDecryptedFunction ||
                        addingFunctionsToExistingLibrary
                            ? "Loading..."
                            : submitBtnText
                    }
                    disabled={
                        deleteLoading ||
                        addingFunctionsToExistingLibrary ||
                        loadingStateForDecryptedFunction ||
                        (updatedLibraryDetails.length > 0 &&
                            updatedLibraryDetails[0].password.length === 0 &&
                            currentLibraryToDragAndDrop.length === 0) ||
                        (editOrView &&
                            updatedLibraryDetails[0].password.length === 0) ||
                        (currentLibraryToDragAndDrop.length > 0 &&
                            updatedLibraryDetails.length > 0 &&
                            currentLibraryToDragAndDrop[0].libraryId !==
                                updatedLibraryDetails[0].id &&
                            updatedLibraryDetails[0].password.length === 0) ||
                        (updatedLibraryDetails.length > 0 &&
                            updatedLibraryDetails[0].password.length === 0) ||
                        libraryGlobalError.length > 0
                    }
                    onClick={() => {
                        submitBtnAction(
                            assetsInfo ? assetsInfo : ({} as AssetsInfo),
                            functionsDetailsList,
                            !editOrView
                                ? currentLibraryToDragAndDrop.length > 0 &&
                                  updatedLibraryDetails.length > 0 &&
                                  currentLibraryToDragAndDrop[0].libraryId ===
                                      updatedLibraryDetails[0].id
                                    ? [
                                          {
                                              ...libraryDetails[0],
                                              password:
                                                  currentLibraryToDragAndDrop[0]
                                                      .password,
                                          },
                                      ]
                                    : updatedLibraryDetails.length > 0
                                    ? updatedLibraryDetails
                                    : libraryDetails
                                : updatedLibraryDetails
                        );
                    }}
                    isLoading={
                        loadingStateForDecryptedFunction ||
                        addingFunctionsToExistingLibrary ||
                        deleteLoading
                    }
                />
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        loadingStateForDecryptedFunction:
            state.functions.loadingStateForDecryptedFunction,
        typeDetails: state.functions.typeDetails,
        decryptedLibraryDetails: state.functions.decryptedLibraryDetails,
        addingFunctionsToExistingLibrary:
            state.functions.addingFunctionsToExistingLibrary,
        libraryGlobalError: state.functions.libraryGlobalError,
        currentLibraryToDragAndDrop:
            state.functions.currentLibraryToDragAndDrop,
        deleteLoading: state.functions.isLoading,
        assetsInfo: state.functions.assetsInfo,
    };
};

export default connect(mapStateToProps)(GetPasswordForLibraries);
