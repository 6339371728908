import { fabric } from 'fabric';
import {
    alramIconSize,
    alarmIconColor,
    triggerColor,
    noTriggerAlarmStrokeWidth,
    GRID_PIXEL,
} from './UiConfig';
import { FabricDropDownTitle } from './titleRectangle';
import { SelfPositioningChild } from '../NodeController/types';

const alarmIconPath = 'M12 9.45L18 15.45L16.95 16.5L12 11.565L7.05 16.5L6 15.45L12 9.45Z';

const scale = alramIconSize / 18;
export interface FabricArrowIconParams {
    isOpen: boolean;
    group: FabricDropDownTitle;
    left: number;
    top: number;
}
export class FabricArrowIcon extends fabric.Path {
    open: () => void;
    close: () => void;
    data: SelfPositioningChild['data'] & { parent: FabricDropDownTitle };
    constructor(options: FabricArrowIconParams) {
        const { isOpen, group } = options;
        super(alarmIconPath, {
            stroke: options.isOpen ? triggerColor : alarmIconColor,
            strokeWidth: noTriggerAlarmStrokeWidth,
            fill: 'black',
            left:
                group.getPointByOrigin('center', 'center').x +
                    group.getScaledWidth() / 2 -
                    GRID_PIXEL * 1.5 || 0,
            originX: 'center',
            originY: 'center',
            scaleX: group.scaleX || 1,
            scaleY: group.scaleY || 1,
            top: group.getPointByOrigin('center', 'center').y || 0,
            angle: isOpen ? 0 : 180,
            hoverCursor: 'pointer',
            selectable: false,
        });

        this.open = () => {
            this.set('angle', 0);
        };

        this.close = () => {
            this.set('angle', 180);
        };
        this.data = {
            parent: group,
            rePosition: () => {
                this.left =
                    group.getPointByOrigin('center', 'center').x +
                    group.getScaledWidth() / 2 -
                    GRID_PIXEL * 1.5;
                this.top = group.getPointByOrigin('center', 'center').y || 0;
                this.scaleX = (group.scaleX || 1) * scale;
                this.scaleY = (group.scaleX || 1) * scale;
                this.bringForward();
                this.setCoords();
            },
        };
    }
}
