import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import { Asset, IODataTypes } from '../types';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import { FabricIOCircle } from '../UIHelpers/FabricIOCircle';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import EventTypeDetail from '../../../transformers/AssetType/EventType';

export const isFunctionType = (assetObj: Asset) => {
    if (assetObj instanceof FunctionTypeDetail) {
        return true;
    }
    return false;
};
export const isObjectType = (assetObj: Asset) => {
    if (assetObj instanceof ObjectTypeDetail) {
        return true;
    }
    return false;
};

export const isArrayDatatype = (dataType: IODataTypes) => {
    if (dataType.includes('array')) return true;
    return false;
};

export const convertMajorVersionToFullVersion = (majorVersion: string) => `${majorVersion}.0.0`;

export const calculateDropDownTitlePosition = (options: {
    position: { x: number; y: number };
    nodeWidth: number;
}) => {
    const { position, nodeWidth } = options;
    if (position.x - nodeWidth > 0) return { x: position.x - nodeWidth, y: position.y + 10 };
    return { x: position.x, y: position.y + 10 };
};

export const isManualConnectionAllowed = (pinId: string, asset: Asset) => {
    if (
        pinId.includes('alarmInputs') ||
        pinId.includes('eventInputs') ||
        !(asset instanceof FunctionTypeDetail)
    ) {
        return false;
    } else return true;
};

export const isConnectionDeleteAllowed = (
    inputPinId: string,
    inputAsset: Asset,
    outputAsset: Asset,
    mappingChangeAllowed: boolean
) => {
    if (
        inputPinId.includes('alarmInputs') ||
        inputPinId.includes('eventInputs') ||
        inputAsset instanceof AlarmTypeDetail ||
        inputAsset instanceof EventTypeDetail ||
        (outputAsset instanceof FunctionTypeDetail && !mappingChangeAllowed)
    ) {
        return false;
    } else return true;
};
