import React from 'react';
import Flex from '../../../shared/Flex';
import './style.scss';
import { connect } from 'react-redux';
import AddEventForm from '../../../CreateEventForm';
import { showModal } from '../../../../store/modal/action';
import {
    hideConfigurationPopup,
    markComputeModelDirty,
} from '../../../../store/configurationTool/actions';
import { Dispatch } from 'redux';
import { StoreState } from '../../../../store';
import AddAlarmForm from '../../../CreateAlarmForm';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';
import { CONFIG_POPUP } from '../../../../utils/constants/uiConstants';
import { ROUTE_PATHNAME } from '../../../../utils/constants/appConstants';

const ConfigurationPopup = (
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
    const handleHideConfigurationPopup = () => {
        props.hideConfigPopup();
    };

    return (
        <Flex column id="configurationPopup" className="wrapper-configuration-popup">
            {!window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) && (
                <div
                    className="popup-option"
                    onClick={() =>
                        props.showCreateAlarmModal(props.data!.asset as FunctionTypeDetail)
                    }
                >
                    {CONFIG_POPUP.ADD_ALARM_TRIGGER_LABEL}
                </div>
            )}
            {/* <div
                className="popup-option"
                onClick={() => props.showCreateEventModal(props.data!.asset as FunctionTypeDetail)}
            >
                {CONFIG_POPUP.ADD_EVENT_TRIGGER_LABEL}
            </div> */}
            <div className="popup-option" onClick={() => props.data!.handleFunctionSettings()}>
                {CONFIG_POPUP.SETTINGS_LABEL}
            </div>
        </Flex>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        show: state.configurationTool.showConfigPopup,
        data: state.configurationTool.configPopupDetails,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        showCreateAlarmModal: (functionType: FunctionTypeDetail) => {
            dispatch(
                showModal({
                    component: AddAlarmForm,
                    modalTitle: 'ADD ALARM TRIGGER',
                    data: {
                        mode: 'CREATE',
                        functionType,
                    },
                })
            );
        },
        showCreateEventModal: (functionType: FunctionTypeDetail) => {
            dispatch(
                showModal({
                    component: AddEventForm,
                    modalTitle: 'ADD EVENT TRIGGER',
                    data: {
                        functionType,
                    },
                })
            );
        },
        hideConfigPopup: () => {
            dispatch(hideConfigurationPopup());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPopup);
