import React from 'react';
import { AuthProvider, AuthConfiguration, AuthModel, AuthEvents } from '@iaedge/auth';
import AppSettings from '../../services/AppSettings';
import AppBootUp from '.';
import { AbilityService } from 'sce-engg-model-19.09';
import { checkPermissionRoles } from '../../utils/helpers';
import { commonAuthHelper } from '../../utils/types/commonInterface';

const WrapperContainer = () => {
    const authModel: AuthModel =
        AppSettings.connectivityMode === 'connected'
            ? {
                b2cName: AppSettings.b2cName,
                // solution: AppSettings.solution,
                clientId: AppSettings.clientId,
                redirectUrl: AppSettings.redirectUrl,
                // tokenUrl: `${AppSettings.sceEngineeringApiUrl}/api/Token`,
                // scopeId: `https://${AppSettings.b2cName}.onmicrosoft.com/${AppSettings.solution}/instance`,
                scope: `https://${AppSettings.b2cName}.onmicrosoft.com/${AppSettings.solution}/instance`,
                postLogoutRedirectUrl: AppSettings.redirectUrl,
                // removeSolutionFromAuthority: AppSettings.removeSolutionFromAuthority == "true" ? true : false,
                tokenRenewalOffsetSeconds: AppSettings.tokenRenewalOffsetSeconds,
            }
            : {
                authority: AppSettings.authority,
                clientId: AppSettings.clientId,
                redirectUrl: AppSettings.redirectUrl,
                scope: 'openid profile offline_access',
                postLogoutRedirectUrl: AppSettings.redirectUrl,
                // automaticSilentRenew: AppSettings.automaticSilentRenew == "true" ? true : false,
                tokenRenewalOffsetSeconds: AppSettings.tokenRenewalOffsetSeconds,
            };
    const config: AuthConfiguration = {
        authType: AppSettings.connectivityMode === 'connected' ? 'AbilityB2C' : 'OIDC',
        applicationName: AppSettings.productName,
        storage: AppSettings.cacheLocation === 'sessionStorage' ? 'sessionStorage' : 'localStorage',
        authModel: {
            ...authModel,
        },
        keepLiveSession: false,
        timeOutSeconds: 3600,
        onEventRegister: (methods: AuthEvents) => {
            commonAuthHelper.getToken = methods.getToken;
            commonAuthHelper.getTenantName = methods.getTenantName;
            commonAuthHelper.logout = methods.logout;
            commonAuthHelper.keepLoginSession = methods.keepLoginSession;
        },
        onUpdateToken: (token: string) => {
            if (!!token) {
                const canAccess = checkPermissionRoles(token);
                if (canAccess) AbilityService.decodeToken(token);
            }
        },
    };
    return (
        <AuthProvider {...config}>
            <AppBootUp />
        </AuthProvider>
    );
};

export { WrapperContainer };
