import React, { useCallback, useState, Fragment, useEffect } from 'react';
import './styles.scss';
import { Table } from 'reactstrap';
import * as _ from 'lodash';
import ConditionListView from './ConditionsList';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import { columns } from '../../../../routes/Functions/type';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';
import { getDataForStatusKey } from '../../../../routes/Functions/helper';

function ConditionConfiguration(props: any) {
    const [selectedValue, updateSelectedValue] = useState([] as any);
    const [conditionType, updateConditionValue] = useState({} as any);
    const [functionTerminals, updateFunctionTerminals] = useState([] as string[]);

    useEffect(() => {
        const functionDetails = props.functionDetails as FunctionTypeDetail;
        const inputs = functionDetails.inputs;
        let terminals: string[] = [];
        inputs.forEach((input) => {
            terminals.push(`$input.${input.name}`);
        });
        updateFunctionTerminals(terminals);
    }, []);

    let DefaultDropDownOptions: any = [];

    if (props.assetsDetails) {
        Array.from(Object.keys(props.assetsDetails)).map((val: string) => {
            DefaultDropDownOptions.push({ label: val, value: val });
        });

        let conditionsList: any = {};

        if (selectedValue.length === 0) {
            selectedValue.push(DefaultDropDownOptions[0]);
            conditionsList = _.get(props.assetsDetails, [
                DefaultDropDownOptions[0]['value'],
                'subConditions',
            ]);
            updateConditionValue(conditionsList);
        }
    }

    const handleLogicDialog = (name: string, dataProps: string) => {
        props.handleLogicDialog(selectedValue, name, dataProps);
    };

    const handleDropDownSelectedValue = (selectedObj: any) => {
        updateSelectedValue(selectedObj);
        let conditionsList: any = _.get(props.assetsDetails, [
            selectedObj[0]['value'],
            'subConditions',
        ]);
        updateConditionValue(conditionsList);
    };

    let handleInputChange = (
        val: string,
        condition: string,
        type: string,
        error: boolean,
        message?: string
    ) => {
        let assetsDetails: any = props.assetsDetails;
        console.log(' condition props: ', props);
        let conditionsList: any = _.get(conditionType, [condition]);
        if (error) {
            conditionsList[type]['severityError'] = {
                valid: false,
                text: message ? message : 'Negative values not allowed',
            };
        } else {
            delete conditionsList[type]['severityError'];
        }
        conditionsList[type]['value'] = type === 'severity' && val !== '' ? Number(val) : val;
        assetsDetails[selectedValue[0]['value']] = {
            subConditions: conditionType,
        };
        props.handleConditionChange(assetsDetails);
    };

    const getHeader = () => {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th className="text-uppercase width-15">SubConditions</th>
                        <th className="text-uppercase width-14">{columns.description}</th>
                        <th className="text-uppercase width-14">{columns.severity}</th>
                        <th className="text-uppercase width-14">{columns.possibleCause}</th>
                        <th className="text-uppercase width-14">{columns.suggestedAction}</th>
                        <th className="text-uppercase width-14">{columns.correctiveAction}</th>
                        <th className="text-uppercase width-15">{columns.logic}</th>
                        <th className="text-uppercase width-15">{columns.status}</th>
                    </tr>
                </thead>
            </Fragment>
        );
    };

    const getBody = (props: any) => {
        return (
            <tbody>
                {Array.from(Object.keys(conditionType)).map(
                    (condition: string, conditionIndex: number) => {
                        let conditionsList: any = _.get(conditionType, [condition]);
                        return (
                            <ConditionListView
                                key={conditionIndex}
                                handleLogicDialog={handleLogicDialog}
                                handleInputChange={handleInputChange}
                                condition={condition}
                                conditionIndex={conditionIndex}
                                conditions={conditionsList}
                                isDisabled={props.isDisabled}
                                disabledFields={'logic'}
                                terminalsData={getDataForStatusKey([...functionTerminals])}
                                isEncrypted={!!props.functionDetails.isEncrypted}
                            />
                        );
                    }
                )}
            </tbody>
        );
    };

    return (
        <div className="condition-function-configuration">
            <div className="DropDownList">
                <span>Conditions</span>
                <Dropdown
                    placeholder="Select DataType"
                    value={selectedValue}
                    onChange={(val) => handleDropDownSelectedValue(val)}
                >
                    {DefaultDropDownOptions.length > 0 &&
                        DefaultDropDownOptions.map((o: any) => {
                            return <DropdownOption key={o.value} label={o.label} value={o.value} />;
                        })}
                </Dropdown>
            </div>

            <div className="ConditionContent">
                <Table className="customTableOverflow">
                    {getHeader()}
                    {getBody(props)}
                </Table>
            </div>
        </div>
    );
}

export default ConditionConfiguration;
