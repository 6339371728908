import React from 'react';
import _ from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { Checkbox, Icon } from '@abb/abb-common-ux-react';
import './style.scss';

export interface DraggableListItemProps {
    subConditionIndex: number;
    subConditionData: any;
    dropItem: (dragIndex: number, dropIndex: number) => any;
    errorFlag: boolean;
    handleSubConditionCheckbox: (index: number, value: boolean) => any;
    handleDeleteFunctionItems: (callback: () => any) => any;
    handleSingleSubConditionDelete: (index: number) => any;
    setSelectedSubConditionIndex: (index: number) => any;
    selectedSubConditionIndex: number;
}
const DraggableListItem = (props: DraggableListItemProps) => {
    const {
        subConditionIndex,
        subConditionData,
        dropItem,
        handleSubConditionCheckbox,
        handleDeleteFunctionItems,
        handleSingleSubConditionDelete,
        selectedSubConditionIndex,
        setSelectedSubConditionIndex,
    } = props;
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: 'SUB_CONDITION_DRAGGABLE_ITEM',
            data: {
                index: subConditionIndex,
            },
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    const [, dropUnmappedAlarm] = useDrop({
        accept: 'SUB_CONDITION_DRAGGABLE_ITEM',
        drop: (data: any, monitor) => {
            debugger;
            if (data.data.index !== subConditionIndex) {
                dropItem(data.data.index, subConditionIndex);
            }
        },
    });

    return (
        <div key={subConditionData.name} style={{ display: 'inline' }} ref={dropUnmappedAlarm}>
            <div
                className={`condition-menu-element ${
                    selectedSubConditionIndex === subConditionIndex ? 'selected' : ''
                }`}
                onClick={(e) => setSelectedSubConditionIndex(subConditionIndex)}
                ref={drag}
            >
                <Checkbox
                    sizeClass="small"
                    className="checkbox"
                    disabled={props.errorFlag}
                    value={subConditionData['deleteFlag']}
                    onChange={() =>
                        handleSubConditionCheckbox(
                            subConditionIndex,
                            !subConditionData['deleteFlag']
                        )
                    }
                />
                <div className={'text'}>{subConditionData.name}</div>

                <Icon
                    sizeClass="small"
                    name="abb/trash"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        handleDeleteFunctionItems(() =>
                            handleSingleSubConditionDelete(subConditionIndex)

                        )
                    }
                    }
                    className={`right-icon ${props.errorFlag ? 'disabled' : ''}`}
                />
            </div>
        </div>
    );
};
export default DraggableListItem;
