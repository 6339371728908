import React, { Fragment, useState, useEffect } from 'react';
import { Input, Icon, Checkbox } from '@abb/abb-common-ux-react';
import ReactSelect from 'react-select';
import {
    CalculationFields,
    CALCULATION_FIELD,
    dropDownOptions,
} from '../../../../../routes/Functions/type';
import { customReactSelectStyles, reactSelectMenuStyle } from '../../../../../utils/helpers';
import { DropDownOptions } from '../../../../../routes/Functions/constants';
import { FunctionMode } from '../../../../../store/function/types';
import { If, validateForSwedishAndFinishCharacters } from '../../../../../routes/Functions/helper';
import { FUNCTION_MODE } from '../../../../../utils/constants/appConstants';
import { VALIDATION_MESSAGE, HIDDEN_LOGIC_TEXT } from '../../../../../utils/constants/uiConstants';

interface CalculationTableBodyProps {
    tableData: any[];
    handleCalcFieldChange?: (fieldName: CalculationFields, val: string) => void;
    functionMode: FunctionMode;
    handleLogicDialog?: (val: string) => void;
    isCalcNameValid?: boolean;
    handleCalculationError?: (value: boolean) => void;
    selectedValue?: any[];
    handleSelectedValue?: (selectedObj: any) => void;
    isEncrypted?: boolean;
    isConfigurationMode?: boolean;
}

function CalculationTableBody(props: CalculationTableBodyProps) {
    const { selectedValue, handleSelectedValue } = props;
    const [errorCalculationName, updateCalculationNameError] = useState('');

    useEffect(() => {
        props.tableData.forEach((item: any) => {
            if (!validateForSwedishAndFinishCharacters(item.name)) {
                let errorText = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
                if (item.name.trim() === '') {
                    errorText = VALIDATION_MESSAGE.EMPTY_NAME;
                }
                props.handleCalculationError && props.handleCalculationError(true);
                updateCalculationNameError(errorText);
            } else {
                updateCalculationNameError('');
            }
        });
    }, [props.tableData]);

    return (
        <tbody>
            {props.tableData.map((item: any, itemIndex: number) => {
                let type: string = item['outputType']['dataType'];
                let arrayType: string | undefined = item.outputType.items;
                if (arrayType) {
                    if (arrayType === 'integer') {
                        type = dropDownOptions.integerArr;
                    }

                    if (arrayType === 'number') {
                        type = dropDownOptions.numberArr;
                    }

                    if (arrayType === 'string') {
                        type = dropDownOptions.stringArr;
                    }

                    if (arrayType === 'boolean') {
                        type = dropDownOptions.booleanArr;
                    }
                }
                return (
                    <tr key={itemIndex}>
                        <td className="width-15">{item.name}</td>

                        <td className="width-20">
                            <If cond={item.hasOwnProperty('outputType')}>{type}</If>
                        </td>
                        <Fragment>
                            <If cond={item.hasOwnProperty('description')}>
                                <td className="width-13.5">{item.description.value}</td>
                            </If>

                            <If cond={item.hasOwnProperty('logic')}>
                                <td className="width-13.5">
                                    <div className={''} onClick={() => {}}>
                                        {props.isEncrypted && props.isConfigurationMode
                                            ? HIDDEN_LOGIC_TEXT
                                            : item.logic.value}
                                    </div>
                                </td>
                            </If>
                        </Fragment>
                    </tr>
                );
            })}
        </tbody>
    );
}

export default CalculationTableBody;
