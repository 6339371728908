import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import ReactSelect from "react-select";
import Flex from "../../../../../components/shared/Flex";
import "./styles.scss";

// import {
//     ASSET_INSTANCE_TABLE_VIEW,
//     ASSET_INSTANCE_GRID_VIEW,
// } from "../../../../../utils/constants/assets";
import Checkbox from "../../../../../components/CustomCheckbox";
import {
    PERFORM_DROP_DOWN_ACTION,
    SORT_BY_DROP_DOWN_ACTION,
    PAGE_ENTRY_DROP_DOWN_ACTION,
} from "./fakeData";
import { connect } from "react-redux";
import {
    changeEnteriesPerPageInstanceConfig,
    handleIncludeAllChildElements,
    changeActivePageInstanceConfig,
} from "../../../../../store/instanceConfig/actions";
import { StoreState } from "../../../../../store";
import { Dispatch } from "redux";
import { TreeNodeType, IFilteredItem } from "../../../../../store/assets/types";
import {
    getAssetInstanceListActionRequest,
    getFilterAssetModelTypeRequest,
    updateFilterAssetQuery,
} from "../../../../../store/assets/actions";

import {
    Dropdown,
    DropdownOption,
    Input,
    Dialog,
    Icon,
    Button,
} from "@abb/abb-common-ux-react";
import { onlyNumber } from "../../../../Functions/helper";
import { placeholder } from "@babel/types";
import FilterConfigModal from "../../../FilterInstanceModal";
import CustomABBInput from "../../../../../components/CustomABBInput";

const ToggleButton = (props: any) => {
    return (
        <div
            onClick={props.onClick}
            className={classnames({
                "active-button": props.isActive,
                "toggle-button-container": true,
            })}
        >
            {props.children}
        </div>
    );
};

// const ViewActionContainer = () => {
//     return (
//         <Flex fill className="justify-content-end view-action-container">
//             <Flex>
//                 <div className="wrapper-toggle-btn">
//                     <ToggleButton isActive>
//                         <img src={ASSET_INSTANCE_TABLE_VIEW} />
//                     </ToggleButton>
//                     <ToggleButton>
//                         <img src={ASSET_INSTANCE_GRID_VIEW} />
//                     </ToggleButton>
//                 </div>
//             </Flex>
//         </Flex>
//     );
// };

export const DropDownComponent = connect((state: StoreState) => {
    return {
        enteriesPerPage:
            state.instanceConfig.instancesTable.pagination.enteriesPerPage,
        activePage: state.instanceConfig.instancesTable.pagination.activePage,
    };
})((props: any) => {
    const [selectedEntries, setEntries] =
        useState<{ value: string; label: string; isNew: boolean }[]>();
    useEffect(() => {
        if (props.activePage && props.enteriesPerPage) {
            props.dispatch(
                changeActivePageInstanceConfig({
                    pageNumber: parseInt(props.activePage),
                })
            );
            const option = props.options.filter(
                (x: any) => x.value == props.enteriesPerPage
            );
            setEntries(option[0]);
        } else if (props.options[0]) {
            props.dispatch(
                changeEnteriesPerPageInstanceConfig({
                    enteriesPerPage: parseInt(props.options[0].value),
                })
            );
            setEntries(props.options[0]);
        }
    }, [props.options]);
    return (
        <Flex column fill>
            <div className="dropdown-title"> {props.title} </div>

            {/* <ReactSelect
                id="basicSingle"
                className="basic-single"
                classNamePrefix="select"
                name="color"
                options={props.options}
                defaultValue={props.options.find(
                    (option: any) => option.value === props.enteriesPerPage
                )}
                onChange={(e: any) => {
                    props.dispatch(
                        changeEnteriesPerPageInstanceConfig({
                            enteriesPerPage: e.value
                        })
                    );
                }}
                // styles={{
                //     container: () => {
                //         return {
                //             width: '100%'
                //         };
                //     }
                // }}
            /> */}

            <Dropdown
                // options={props.categoryArr}
                // getOptionLabel={optionObj => optionObj.name}
                searchable={true}
                onChange={(
                    selectedOptions: {
                        value: string;
                        label: string;
                        isNew: boolean;
                    }[]
                ) => {
                    if (selectedOptions[0]) {
                        props.dispatch(
                            changeEnteriesPerPageInstanceConfig({
                                enteriesPerPage: parseInt(
                                    selectedOptions[0].value
                                ),
                            })
                        );
                    }
                    setEntries(selectedOptions);
                }}
                placeholder="Entries per page"
                value={selectedEntries}
                required={true}
            >
                {props.options.map((option: any) => (
                    <DropdownOption
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        disabled={false}
                    ></DropdownOption>
                ))}
            </Dropdown>
        </Flex>
    );
});

// const ActionSelectorContainer = (
//     props: ReturnType<typeof mapStateToProps> &
//         ReturnType<typeof mapDispatchToProps>
// ) => {
//     const handleChildElements = () => {
//         if (props.selectedTreeNode != null) {
//             props.handleIncludeChildElements(
//                 props.selectedTreeNode,
//                 props.includeChildElements
//             );
//         }
//     };
//     return (
//         <Flex fill className="action-selector-container">
//             {/* <Flex fill className='drop-down-wrapper'>
//                 <DropDownComponent
//                     options={PERFORM_DROP_DOWN_ACTION}
//                     title="Perform"
//                 />
//             </Flex>
//             <Flex fill className="drop-down-wrapper">
//                 <DropDownComponent
//                     options={SORT_BY_DROP_DOWN_ACTION}
//                     title="Sort By"
//                 />
//             </Flex> */}
//             <Flex fill className="drop-down-wrapper">
//                 <DropDownComponent
//                     options={PAGE_ENTRY_DROP_DOWN_ACTION}
//                     title="Show"
//                 />
//             </Flex>
//             <Flex className="action-selector-checkbox-container">
//                 <Checkbox
//                     onChange={handleChildElements}
//                     checked={props.includeChildElements}
//                     // isDisable={props.selectedTreeNode ? false : true}
//                 />
//                 <div className="checkbox-label">Include all Child Elements</div>
//             </Flex>
//             <Flex fill className="justify-content-end">
//                 {/* <ViewActionContainer /> */}
//             </Flex>
//         </Flex>
//     );
// };

const mapStateToProps = (state: StoreState) => {
    return {
        selectedTreeNode: state.assets.selectedTreeNode,
        totalPage: state.instanceConfig.instancesTable.pagination.totalRecords,
        includeChildElements:
            state.instanceConfig.instancesTable.includeChildElements,
        selectedCategory: state.assets.selectedCategory,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleIncludeChildElements: (
            selectedNode: TreeNodeType,
            includeAllChild: boolean
        ) => {
            dispatch(handleIncludeAllChildElements({ includeAllChild }));
        },
        changeActivePage: (payload: { pageNumber: number }) => {
            dispatch(changeActivePageInstanceConfig(payload));
        },
    };
};

export const ActionBar = () => {
    return (
        <Flex className="action-bar-container" fill column>
            <Flex fill className="action-bar-wrapper">
                <ActionSelectorContainer />
            </Flex>
        </Flex>
    );
};

export const ActionSelectorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionSelector);

export function ActionSelector(
    props: ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [pageNumber, updatePageNumber] = useState("");
    const [errorNumber, updateErrorNumber] = useState(false);
    const [showFilterDialog, setFilterDialog] = useState(false);

    const handleChildElements = () => {
        if (props.selectedTreeNode != null) {
            props.handleIncludeChildElements(
                props.selectedTreeNode,
                !props.includeChildElements
            );
        }
    };

    const handleActivePageChange = useCallback(
        (pageNumber: number | string) => {
            if (pageNumber) {
                pageNumber = Number(pageNumber);
                props.changeActivePage({ pageNumber });
            }
        },
        []
    );

    return (
        <Flex fill className="action-selector-container">
            {/* <Flex fill className='drop-down-wrapper'>
                <DropDownComponent
                    options={PERFORM_DROP_DOWN_ACTION}
                    title="Perform"
                />
            </Flex>
            <Flex fill className="drop-down-wrapper">
                <DropDownComponent
                    options={SORT_BY_DROP_DOWN_ACTION}
                    title="Sort By"
                />
            </Flex> */}
            <Flex fill className="drop-down-wrapper">
                <DropDownComponent
                    options={PAGE_ENTRY_DROP_DOWN_ACTION}
                    title="Show"
                />
            </Flex>
            <Flex className="action-selector-checkbox-container">
                <Checkbox
                    id="includeAllChild"
                    onChange={handleChildElements}
                    checked={
                        props.selectedCategory &&
                        props.selectedCategory.value === "Edge"
                            ? false
                            : props.includeChildElements
                    }
                    isDisable={
                        props.selectedCategory &&
                        props.selectedCategory.value === "Edge"
                            ? true
                            : props.selectedTreeNode
                            ? false
                            : true
                    }
                />
                <div className="checkbox-label padding-left-5">
                    Include all Child Elements
                </div>
            </Flex>

            <Flex fill className="margin-top-10 justify-content-end">
                <CustomABBInput
                    type="discreet"
                    dataType="number"
                    icon="abb/right-arrow"
                    className="paginationFunction"
                    value={pageNumber}
                    onIconClick={(text: any) => {
                        if (
                            onlyNumber(text) &&
                            props.totalPage >= Number(text) &&
                            parseInt(text, 10) > 0
                        ) {
                            handleActivePageChange(text);
                            updateErrorNumber(false);
                        } else {
                            updateErrorNumber(true);
                        }
                    }}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    instantValidation={errorNumber}
                    onValueChange={(val) => {
                        if (
                            (onlyNumber(val) &&
                                val !== "0" &&
                                parseInt(val, 10) > 0) ||
                            val.length === 0
                        ) {
                            updatePageNumber(val);
                            updateErrorNumber(false);
                        } else {
                            updatePageNumber(val);
                            updateErrorNumber(true);
                        }
                    }}
                    validator={() =>
                        errorNumber
                            ? {
                                  valid: false,
                                  text: "Please Enter Valid Number",
                              }
                            : { valid: true, text: "OK!" }
                    }
                    placeholder={`Enter Page Number`}
                />
            </Flex>
        </Flex>
    );
}
