import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Dropdown, DropdownOption, Input } from "@abb/abb-common-ux-react";
import { historicalInputsValidator } from "../../UIHelpers/historyHelpers";
import { DurationType, HISTORY_CONFIG } from "./types";
import CustomABBInput from "../../../CustomABBInput";

interface TimeWindowInputProps {
    timeValue: { value: string; type: DurationType };
    handleDurationChange: (val: string, durationType: DurationType) => void;
    errorText: string;
    id: string;
}

const timeWindowDropDownValues = [
    { label: DurationType.Seconds, value: DurationType.Seconds },
    { label: DurationType.Minutes, value: DurationType.Minutes },
    { label: DurationType.Hours, value: DurationType.Hours },
    { label: DurationType.Days, value: DurationType.Days },
    { label: DurationType.Weeks, value: DurationType.Weeks },
];
const samplingWindowDropDownValues = timeWindowDropDownValues.filter(
    (item) => item.label !== DurationType.Seconds
);

const TimeWindowInput = (props: TimeWindowInputProps) => {
    const { timeValue, handleDurationChange } = props;
    const [selectedDropDownValues, setDropDownValues] = useState(
        props.id === HISTORY_CONFIG.TIME_WINDOW_ID
            ? timeWindowDropDownValues
            : samplingWindowDropDownValues
    );

    const [duration, setDuration] = useState(props.timeValue.value || "");

    const [selectedValue, updateSelectedValue] = useState([
        // (props.timeValue.type &&
        //     timeWindowDropDownValues.find(
        //         (item) => item.value === props.timeValue.type
        //     )) ||
        props.id === HISTORY_CONFIG.SAMPLING_WINDOW_ID
            ? samplingWindowDropDownValues[0]
            : timeWindowDropDownValues[0],
    ] as any);

    useEffect(() => {
        setDuration(props.timeValue.value);
        const selectedItem = selectedDropDownValues.find(
            (item) => item.value === props.timeValue.type
        );
        if (selectedItem) {
            updateSelectedValue([selectedItem]);
        }
    }, [props.timeValue]);

    const handleDropDownSelectedValue = (selectedObj: any) => {
        updateSelectedValue(selectedObj);
        handleDurationChange(duration, selectedObj[0].value);
    };

    const handleduration = (value: string) => {
        setDuration(value);
        handleDurationChange(value, selectedValue[0].value);
    };

    return (
        <div className="historical-inputs-div">
            <div className="wrapper-duration">
                <CustomABBInput
                    placeholder="Enter duration"
                    value={duration}
                    dataType="number"
                    onValueChange={(value: any) => {
                        handleduration(value);
                    }}
                    type="normal"
                    id={`${props.id}Value`}
                    instantValidation={true}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    validator={() =>
                        props.errorText
                            ? {
                                  valid: false,
                                  text: props.errorText,
                              }
                            : { valid: true, text: "OK!" }
                    }
                />
            </div>
            <div className="wrapper-dropDown">
                <Dropdown
                    id={`${props.id}Type`}
                    placeholder="Select duration type"
                    value={selectedValue}
                    onChange={(val) => handleDropDownSelectedValue(val)}
                >
                    {selectedDropDownValues.map((o: any) => {
                        return (
                            <DropdownOption
                                key={o.value}
                                label={o.label}
                                value={o.value}
                            />
                        );
                    })}
                </Dropdown>
            </div>
        </div>
    );
};

export default TimeWindowInput;
