import { settingsIconSize, settingsIconRightMargin, menuColor } from '../../UiConfig';
import { SelfPositioningChild } from '../../../NodeController/types';
import { fabric } from 'fabric';
import { FabricFunctionRectangle } from '..';

const settingsIconPath = 'M10 12H6V16H10V12Z,M10 6H6V10H10V6Z,M10 0H6V4H10V0Z';
// 'M23,13.38V10.63l-3.75-.75a7.52,7.52,0,0,0-.62-1.51l2.12-3.17L18.81,3.25,15.63,5.37a7.52,7.52,0,0,0-1.51-.62L13.37,1H10.62L9.88,4.75a7.52,7.52,0,0,0-1.51.62L5.19,3.25,3.25,5.19,5.37,8.37a7.52,7.52,0,0,0-.62,1.51L1,10.63v2.75l3.75.75a7.51,7.51,0,0,0,.62,1.51L3.25,18.81l1.94,1.94,3.17-2.12a7.52,7.52,0,0,0,1.51.62L10.62,23h2.75l.75-3.75a7.52,7.52,0,0,0,1.51-.62l3.17,2.12,1.94-1.94-2.12-3.17a7.51,7.51,0,0,0,.62-1.51ZM16,12a4,4,0,1,1-4-4A4,4,0,0,1,16,12Z';
const scale = settingsIconSize / 18;

export class FabricSettingsIcon extends fabric.Path implements SelfPositioningChild {
    data: SelfPositioningChild['data'] & { parent: FabricFunctionRectangle };
    constructor(group: FabricFunctionRectangle, topBorderY: number) {
        super(settingsIconPath, {
            stroke: menuColor,
            fill: menuColor,
            left: (group.getPointByOrigin('right', 'top').x || 0) - settingsIconRightMargin,
            originX: 'right',
            originY: 'center',
            scaleX: (group.scaleX || 1) * scale,
            scaleY: (group.scaleX || 1) * scale,
            top:
                (group.getPointByOrigin('right', 'top').y || 0) +
                (topBorderY * (group.scaleX || 1)) / 2,
            hoverCursor: 'pointer',
            selectable: false,
        });

        this.data = {
            parent: group,
            rePosition: () => {
                this.left =
                    (group.getPointByOrigin('right', 'top').x || 0) -
                    settingsIconRightMargin * (group.scaleX || 1);
                this.top =
                    (group.getPointByOrigin('right', 'top').y || 0) +
                    (topBorderY * (group.scaleX || 1)) / 2;
                this.scaleX = (group.scaleX || 1) * scale;
                this.scaleY = (group.scaleX || 1) * scale;
                this.bringForward();
                this.setCoords();
            },
        };
    }
}
