import React from 'react';
import { columns } from '../../../../../routes/Functions/type';

interface CalculationTableHeaderProps {}

function CalculationTableHeader(props: CalculationTableHeaderProps) {
    return (
        <thead>
            <tr>
                <th className="width-3" />
                <th className={`text-uppercase width-15 `} onClick={() => {}}>
                    NAME
                </th>
                <th className="width-20">VARIABLE TYPE</th>

                <th className="text-uppercase width-13.5">{columns.description}</th>
                <th className="text-uppercase width-13.5">{columns.logic}</th>
            </tr>
        </thead>
    );
}

export default CalculationTableHeader;
