import React from "react";
import AppSettings from "../../services/AppSettings";
interface ReleaseLicenseProps {
    handleCloseDialog: () => void;
}
const ReleaseLicense = (props: ReleaseLicenseProps) => {

    const releaseNotesLimitation = AppSettings.releaseLimitation ? AppSettings.releaseLimitation.split("\n") : [AppSettings.releaseLimitation == "" ? "None" : AppSettings.releaseLimitation];
    const releaseIssue = AppSettings.releaseIssue ? AppSettings.releaseIssue.split("\n") : [AppSettings.releaseIssue == "" ? "None" : AppSettings.releaseIssue];

    return (
        <>
            {/* Release Note */}
            <div className="Card__card___tRKnc Card__small-space___2tFFs">
                <div className="Card__card-header___37-zb">
                    <div className="Card__header-content___1mUWO">
                        <div className="Card__header-right___1WGnV">
                            <h3 className="Heading__root___3LTN7 Heading__font-medium___14zeN Heading__upperCase___10Rbg">
                                VERSION {AppSettings.releaseVersion}
                            </h3>
                            <p></p>
                        </div>
                        <div className="Card__header-left___28gFO">
                            <h4 className="Heading__root___3LTN7 Heading__font-secondary-color___80XDz">
                                {AppSettings.releaseDate}{" "}
                                {AppSettings.releaseTime}
                            </h4>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="Card__card-body___3z1OA">
                    <div className="MarkDown__markdown___2ZjF7">
                        <h4><b>{AppSettings.releaseInfoAbout}</b></h4>
                        <h2 id="limitations">Limitations</h2>
                        <ul>
                            {releaseNotesLimitation.map((note: string) => {
                                return (
                                    <li>
                                        {note}
                                    </li>
                                )
                            })}
                        </ul>
                        <div style={{fontSize:'2rem'}} id="known-issues">Known Issues</div>
                        <ul>
                            {releaseIssue.map((note: string) => {
                                return (
                                    <li>
                                        {note}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            {/*License Note */}
        </>
    );
};

export default ReleaseLicense;
