import React, { useMemo } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dialog, Table, Icon } from '@abb/abb-common-ux-react';
import { closeSummaryModal } from '../../../store/exportAsset/actions';
import './style.scss';
import Condition from '../../shared/Condition';
import DependentLibraryTable from './DependentLibrary';

interface SummaryModalProps {
    showSummaryDialog: boolean;
    mode: 'IMPORT' | 'EXPORT' | '';
    route: 'FUNCTIONS' | 'TYPES' | '';
    data: any;
}

const SummaryModal = (props: SummaryModalProps & ReturnType<typeof mapDispatchToProps>) => {
    const { showSummaryDialog, mode, route, data, closeSummaryModal } = props;
    return (
        <Dialog
            isOpen={showSummaryDialog}
            showCloseButton
            title={`${mode}ED ${route === 'TYPES' ? 'MODELS' : route} SUMMARY`}
            dimBackground={true}
            onClose={() => closeSummaryModal()}
        >
            <div className="summary-layout-modal">
                {(data && data.successDetails.length) || (data && data.failureDetails.length) ? (
                    <>
                        {route === 'TYPES' && mode === 'IMPORT' && (
                            <h4 className="title">Type Details</h4>
                        )}
                        <Table>
                            <thead>
                                <tr>
                                    <th className="width-5 status-icon"></th>
                                    <th className="width-25">Name</th>
                                    <th className="width-15">Version</th>
                                    <th className="width-15">Status</th>
                                    <th className="width-40">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.successDetails.length ? (
                                    <>
                                        {data.successDetails.map((tableRow: any) => {
                                            const status = (
                                                tableRow.status as string
                                            ).toLowerCase();
                                            return (
                                                <tr>
                                                    <td className="width-5 status-icon">
                                                        <Icon
                                                            name={
                                                                status === 'success' ||
                                                                    status === 'imported'
                                                                    ? 'abb/check-mark-circle-1'
                                                                    : 'abb/information-circle-1'
                                                            }
                                                            color={
                                                                status === 'success' ||
                                                                    status === 'imported'
                                                                    ? 'green'
                                                                    : 'blue'
                                                            }
                                                        />
                                                    </td>
                                                    <td className="width-25">
                                                        {tableRow.dataIn.name ||
                                                            (tableRow.dataIn.libraryName === 'None'
                                                                ? tableRow.dataIn.functionName
                                                                : tableRow.dataIn.libraryName) ||
                                                            (mode === 'EXPORT' &&
                                                                route === 'TYPES' &&
                                                                tableRow.dataOut.model.properties
                                                                    .model.name.value)}
                                                    </td>
                                                    <td className="width-15">
                                                        {tableRow.dataIn.version ||
                                                            (tableRow.dataIn.libraryVersion
                                                                ? tableRow.dataIn.libraryVersion
                                                                : tableRow.dataIn.functionVersion)}
                                                    </td>
                                                    <td className="width-15">{tableRow.status}</td>
                                                    {tableRow.reason && (
                                                        <td className="width-40">
                                                            {tableRow.reason.join('.  ')}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <></>
                                )}

                                {data && data.failureDetails.length ? (
                                    <>
                                        {data.failureDetails.map((tableRow: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Icon
                                                            name={
                                                                tableRow.status == 'Failure'
                                                                    ? 'abb/error-circle-1'
                                                                    : 'abb/information-circle-1'
                                                            }
                                                            color={
                                                                tableRow.status == 'Failure'
                                                                    ? 'red'
                                                                    : 'blue'
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {tableRow.dataIn.name ||
                                                            (tableRow.dataIn.libraryName === 'None'
                                                                ? tableRow.dataIn.functionName
                                                                : tableRow.dataIn.libraryName) ||
                                                            (mode === 'EXPORT' &&
                                                                route === 'TYPES' &&
                                                                tableRow.dataOut.model.properties
                                                                    .model.name.value)}
                                                    </td>
                                                    <td>
                                                        {tableRow.dataIn.version ||
                                                        tableRow.dataIn.libraryVersion
                                                            ? tableRow.dataIn.libraryVersion
                                                            : tableRow.dataIn.functionVersion}
                                                    </td>
                                                    <td>{tableRow.status}</td>
                                                    <td>
                                                        {tableRow.reason.length
                                                            ? tableRow.reason
                                                            : tableRow.dataOut}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <></>
                )}
                <Condition
                    when={
                        mode === 'IMPORT' &&
                        route === 'TYPES' &&
                        data &&
                        data.libraryDetails.length > 0
                    }
                >
                    <DependentLibraryTable libraryDetail={data && data.libraryDetails} />
                </Condition>
            </div>
        </Dialog>
    );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return { closeSummaryModal: () => dispatch(closeSummaryModal()) };
};
export default connect(null, mapDispatchToProps)(SummaryModal);
