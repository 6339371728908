export const supportedConditionMonitors = <const>{
    RESET_GLOBAL_MONITOR_MODELS_ERROR: 'RESET_GLOBAL_MONITOR_MODELS_ERROR',
    GET_SUPPORTED_CONDITION_MONITORS_REQUEST: 'GET_SUPPORTED_CONDITION_MONITORS_REQUEST',
    GET_SUPPORTED_CONDITION_MONITORS_SUCCESS: 'GET_SUPPORTED_CONDITION_MONITORS_SUCCESS',
    GET_SUPPORTED_CONDITION_MONITORS_FAILURE: 'GET_SUPPORTED_CONDITION_MONITORS_FAILURE',
    ADD_NEW_CONDITION_MONITOR_MODELS_REQUEST: 'ADD_NEW_CONDITION_MONITOR_MODELS_REQUEST',
    ADD_NEW_CONDITION_MONITOR_MODELS_SUCCESS: 'ADD_NEW_CONDITION_MONITOR_MODELS_SUCCESS',
    ADD_NEW_CONDITION_MONITOR_MODELS_FAILURE: 'ADD_NEW_CONDITION_MONITOR_MODELS_FAILURE',
    DELETE_CONDITION_MONITOR_MODELS_REQUEST: 'DELETE_CONDITION_MONITOR_MODELS_REQUEST',
    DELETE_CONDITION_MONITOR_MODELS_SUCCESS: 'DELETE_CONDITION_MONITOR_MODELS_SUCCESS',
    DELETE_CONDITION_MONITOR_MODELS_FAILURE: 'DELETE_CONDITION_MONITOR_MODELS_FAILURE',
    UPDATE_CONDITION_MONITOR_MODELS_REQUEST: 'UPDATE_CONDITION_MONITOR_MODELS_REQUEST',
    UPDATE_CONDITION_MONITOR_MODELS_SUCCESS: 'UPDATE_CONDITION_MONITOR_MODELS_SUCCESS',
    UPDATE_CONDITION_MONITOR_MODELS_FAILURE: 'UPDATE_CONDITION_MONITOR_MODELS_FAILURE',
};
