import React from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import { LibraryAndNodePayload, UpdateActiveFunctionType } from '../../../../store/function/types';
import { AbilityService } from 'sce-engg-model-19.09/lib/apiServices/auth/AbilityService';

interface FunctionItemMenuProps {
    libraryDetail: LibraryAndNodePayload;
    currentFunction: UpdateActiveFunctionType;
    showModalToRemoveSingleFunction: (
        libraryDetail: LibraryAndNodePayload,
        currentFunction: UpdateActiveFunctionType
    ) => void;
}

function FunctionItemMenu(props: FunctionItemMenuProps) {
    const { libraryDetail, currentFunction, showModalToRemoveSingleFunction } = props;

    const isDisabled = !libraryDetail.id && !libraryDetail.root;
    const hasSameOrigin = libraryDetail.origin === AbilityService.tenantId;
    return !isDisabled && hasSameOrigin ? (
        <Menu mode="horizontal" className="library-menu">
            <SubMenu
                popupOffset={[-10, 0]}
                title={<Icon name="abb/more" />}
                className={false ? 'item-active' : 'item-unactive'}
            >
                <MenuItem key="funcRemove" className="model-menu-item" disabled={isDisabled}>
                    <div
                        className="menu-item-wrapper"
                        onClick={() => {
                            !isDisabled &&
                                showModalToRemoveSingleFunction(libraryDetail, currentFunction);
                        }}
                    >
                        <Icon name="abb/minus" />
                        <div className="menu-item-name">Remove</div>
                    </div>
                </MenuItem>
            </SubMenu>
        </Menu>
    ) : (
        <></>
    );
}

export default FunctionItemMenu;
