import { fabric } from "fabric";
import {
    canvasIOTriggerColor,
    fontSize,
    canvasPrimaryTextColor,
    canvasPrimaryBorderColor,
} from "./UiConfig";

export class FabricTooltip extends fabric.Group {
    rect: fabric.Rect = new fabric.Rect({
        width: 0,
        height: 28,
        left: 0,
        top: 0,
        fill: "white",
        shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
        stroke: canvasIOTriggerColor,
        strokeWidth: 1,
        originX: "center",
        originY: "top",
    });

    text: fabric.Text = new fabric.Text("Trigger", {
        fontSize: fontSize,
        borderColor: canvasPrimaryTextColor,
        top: 28 / 2,
        originX: "center",
        originY: "center",
    });

    triangale = new fabric.Triangle({
        width: 18,
        height: 10,
        top: 26,
        originY: "top",
        originX: "right",

        fill: "transparent",
        stroke: canvasPrimaryBorderColor,
        shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
        strokeWidth: 0.5,
    });

    constructor() {
        super(undefined, {
            originX: "center",
            originY: "bottom",
            hasControls: false,
            selectable: false,
        });
        this.triangale.rotate(180);
        super.addWithUpdate(this.triangale);
        super.addWithUpdate(this.rect);
        super.addWithUpdate(this.text);
    }
}
