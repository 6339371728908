import React, { useCallback, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
    Dialog,
    Button,
    Input,
    Checkbox,
    Collapsible,
    CollapsibleContainer,
    Icon,
} from "@abb/abb-common-ux-react";

import "./style.scss";
import Loader from "../Loader";
import {
    getFunctionVersions,
    handleFilesToBeImported,
    importFunctions,
    removedFromDropDown,
    storeFunctionsVersions,
    getSelectedFunctionTypeForExport,
    resetSelectedFunctionTypeForExport,
    changeZipFileNameForExport,
} from "../../store/exportAsset/actions";
import { StoreState } from "../../store";
import SpinnerModal from "../SpinnerModal";
import {
    getExportableFunctionsRequest,
    getLibraryAndNodesRequest,
} from "../../store/function/action";
import {
    getFunctionVersionsType,
    payloadToExportFunctionWithLibraries,
} from "../../store/exportAsset/types";
import {
    IMPORT,
    EXPORT,
    GENERIC_FUNCTIONS,
    LIBRARIES,
} from "../../utils/constants/appConstants";
import {
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
} from "../../store/function/types";
import { CONFIRMATION_BUTTON } from "../../utils/constants/uiConstants";
import { showNotificationModal } from "../../store/notificationModal/action";
import { AbilityService } from "sce-engg-model-19.09";
import CustomLeftNav from "../CustomLeftNavigation";
import { librariesList } from "../../utils/mockData/funcLibraries";
import FunctionsView from "./FunctionsView";
import Condition from "../shared/Condition";
import { menuProps } from "../CustomLeftNavigation/types";
import { showModal } from "../../store/modal/action";
import GetExportZipName from "../GetExportZipName";
import CustomABBInput from "../CustomABBInput";
import { isGlobalTenantType } from "../../utils/helpers";

interface ExportDialogProps {
    importMode?: boolean;
    showExportDialog: boolean;
    multipleFunction?: boolean;
    onExportClose: (val: boolean) => void;
}

const ExportDialog = (
    props: ExportDialogProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        importMode,
        showExportDialog,
        multipleFunction,
        showSpinnerModal,
        selectedFunctionDetails,
        libraryAndFunctionsList,
        gettingLibraryAndNodes,
        onExportClose,
        importFunctions,
        getLibraryAndNodesRequest,
        getExportableFunctionsRequest,
        getSelectedFunctionTypes,
        selectedFunctionToView,
        resetSelectedFunctionTypeForView,
    } = props;
    const [] = useState([] as UpdateActiveFunctionType[]);
    const [searchValue, updateSearchValue] = useState("" as string);
    const [validImportFile, setValidImportFile] = useState(false);
    const [importErrorMsg, setImportErrorMsg] = useState("");
    const [libraryToExport, setLibraryToExport] = useState(
        [] as LibraryAndNodePayload[]
    );
    const [selectedGenericFunctions, setSelectedGenericFunctions] = useState(
        [] as LibraryAndNodePayload["nodes"]
    );
    const [isLibrariesPresent, setIsLibrariesPresent] = useState(true);
    const [isGenericPresent, setIsGenericPresent] = useState(true);
    const [selectedFunctionKey, updateSelectedFunctionKey] = useState("");
    const [isFunctionEncrypted, setIsFunctionEncrypted] = useState(true);

    useEffect(() => {
        getLibraryAndNodesRequest(true);
    }, []);

    const handleSearchValue = (value: string) => {
        updateSearchValue(value);
    };

    const checkValidityForImportFile = (val: any) => {
        if (val) {
            if (/.zip$/i.test(val.name) && val.name !== "") {
                setImportErrorMsg("");
                setValidImportFile(true);
            } else {
                setImportErrorMsg("Can Only Import zip files");
            }
        } else {
            props.updateFilesToBeImported({ filesToBeImported: {} });
            setValidImportFile(false);
            setImportErrorMsg("");
        }
    };

    const closeImportExportDialog = () => {
        if (importMode) {
            props.updateFilesToBeImported({ filesToBeImported: {} });
            setValidImportFile(false);
            setImportErrorMsg("");
            onExportClose(false);
        }
        if (!importMode && !showSpinnerModal) {
            onExportClose(false);
            resetSelectedFunctionTypeForView();
        }
    };

    const handleImportExport = useCallback(() => {
        if (importMode) {
            importFunctions({ filesToBeImported: props.filesToBeImported });
        } else {
            let fileName = "";
            let librariesToBeExported: payloadToExportFunctionWithLibraries[] =
                [];
            if (libraryToExport.length) {
                libraryToExport.forEach((item) => {
                    let libraryObj: payloadToExportFunctionWithLibraries = {
                        isProtected: false,
                        libraryId: "",
                        libraryVersion: "",
                        functionTypes: [],
                    };
                    if (!fileName) {
                        fileName = item.root;
                    } else if (fileName !== LIBRARIES) {
                        fileName = LIBRARIES;
                    }
                    libraryObj.isProtected = !!item.isIPProtected;
                    libraryObj.libraryId = item.id;
                    libraryObj.libraryVersion = item.libraryVersion;
                    libraryObj.functionTypes = [...item.nodes];
                    librariesToBeExported.push(libraryObj);
                });
            } else {
                if (selectedFunctionDetails.length > 0) {
                    librariesToBeExported = [
                        {
                            isProtected: false,
                            libraryId: "",
                            libraryVersion: "",
                            functionTypes: selectedFunctionDetails,
                        },
                    ];
                }
            }
            if (selectedGenericFunctions.length > 0) {
                if (!fileName) {
                    fileName = GENERIC_FUNCTIONS;
                } else if (fileName !== LIBRARIES) {
                    fileName = LIBRARIES;
                }
                if (
                    fileName === GENERIC_FUNCTIONS &&
                    selectedGenericFunctions.length === 1
                ) {
                    fileName = selectedGenericFunctions[0].name;
                }
                let libraryObj: payloadToExportFunctionWithLibraries = {
                    isProtected: false,
                    libraryId: "",
                    libraryVersion: "",
                    functionTypes: [...selectedGenericFunctions],
                };
                librariesToBeExported.push(libraryObj);
            }
            props.showGetFileNameModal(
                () => getExportableFunctionsRequest(librariesToBeExported),
                fileName
            );
        }
        onExportClose(false);
    }, [
        importMode,
        importFunctions,
        props.filesToBeImported,
        libraryToExport,
        selectedFunctionDetails,
        selectedGenericFunctions,
        getExportableFunctionsRequest,
    ]);

    // library and functionList without different tenant protected library.
    const filteredLibraryAndFunctionList = useMemo(() => {
        return libraryAndFunctionsList.filter((item) => {
            if (item.id === null) {
                item.nodes = item.nodes.filter((node) => {
                    return !isGlobalTenantType(node.tags, node.typeId);
                });
            }
            return (
                (item.nodes.length > 0 &&
                    item.origin === AbilityService.tenantId) ||
                item.id === null
            );
        });
    }, [libraryAndFunctionsList]);

    //generating data with respect to left navigation component
    const leftNavLibrariesArray = useMemo(() => {
        return [
            {
                key: "Libraries.list",
                name: "Libraries",
                libraries: filteredLibraryAndFunctionList.filter(
                    (item) => item.id !== null && item.nodes.length > 0
                ),
            },
        ];
    }, [filteredLibraryAndFunctionList]);

    const leftNavLibrariesConfig = [
        {
            keyIdentifier: "key",
            label: "name",
            showCheckbox: true,
            nextLevelKey: "libraries",
            filter: true,
            filterMethod: (data: any) => {
                let isPresent =
                    data.libraries &&
                    data.libraries.filter((library: any) =>
                        library.nodes.some((node: any) =>
                            node.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        )
                    ).length > 0;
                isPresent
                    ? setIsLibrariesPresent(true)
                    : setIsLibrariesPresent(false);
                return isPresent;
            },
            onCheckBoxClick: (data: { libraries: LibraryAndNodePayload[] }) => {
                if (libraryToExport.length !== data.libraries.length) {
                    setLibraryToExport([...data.libraries]);
                } else {
                    setLibraryToExport([]);
                }
            },
        },
        {
            keyIdentifier: "id",
            label: "root",
            showCheckbox: true,
            icon: "abb/folder",
            nextLevelKey: "nodes",
            filter: true,
            filterMethod: (data: any) => {
                let isPresent = data.nodes.some((node: any) =>
                    node.name.toLowerCase().includes(searchValue.toLowerCase())
                );

                return isPresent;
            },
            onCheckBoxClick: (data: LibraryAndNodePayload) => {
                let selectedIndex = libraryToExport.indexOf(data);
                if (selectedIndex < 0) {
                    setLibraryToExport([...libraryToExport, data]);
                } else {
                    setLibraryToExport(
                        libraryToExport.filter((item) => item.id !== data.id)
                    );
                }
            },
        },
        {
            keyIdentifier: "typeId",
            label: "name",
            filter: true,
            filterMethod: (data: any) => {
                let isPresent = data.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                return isPresent;
            },
            onClick: (data: any) => {
                let currentLibrary = leftNavLibrariesArray[0].libraries.filter(
                    (item) => {
                        return item.nodes.indexOf(data) !== -1;
                    }
                );
                setIsFunctionEncrypted(
                    currentLibrary[0] ? currentLibrary[0].isIPProtected : false
                );
                getSelectedFunctionTypes({
                    selectedFunction: {
                        assetDescription: data.description,
                        assetName: data.name,
                        assetRef: data.typeId,
                        assetTags: data.tags,
                        assetType: data.model,
                        assetVersion: data.version,
                    },
                });
            },
        },
    ];

    const leftNavGenericFunctionsArray = useMemo(() => {
        let genericLibrary = filteredLibraryAndFunctionList.filter(
            (item) => item.id === null
        )[0];
        return [
            {
                key: "GenericFunctions.list",
                name: GENERIC_FUNCTIONS,
                nodes: genericLibrary ? genericLibrary.nodes : [],
            },
        ];
    }, [filteredLibraryAndFunctionList]);

    const leftNavGenericFunctionsConfig = [
        {
            keyIdentifier: "key",
            label: "name",
            showCheckbox: true,
            icon: "abb/folder",
            nextLevelKey: "nodes",
            filter: true,
            filterMethod: (data: any) => {
                let isChildFiltered =
                    data.nodes &&
                    data.nodes.filter((asset: any) =>
                        asset.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                    ).length > 0;
                isChildFiltered
                    ? setIsGenericPresent(true)
                    : setIsGenericPresent(false);

                return isChildFiltered;
            },
            onCheckBoxClick: (data: { nodes: UpdateActiveFunctionType[] }) => {
                if (selectedGenericFunctions.length !== data.nodes.length) {
                    setSelectedGenericFunctions([...data.nodes]);
                } else {
                    setSelectedGenericFunctions([]);
                }
            },
        },
        {
            keyIdentifier: "typeId",
            label: "name",
            showCheckbox: true,
            filter: true,
            filterMethod: (data: any) => {
                let isPresent = data.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                return isPresent;
            },
            onCheckBoxClick: (data: UpdateActiveFunctionType) => {
                let selectedIndex = selectedGenericFunctions.indexOf(data);
                if (selectedIndex >= 0) {
                    setSelectedGenericFunctions(
                        selectedGenericFunctions.filter(
                            (item) => item.typeId !== data.typeId
                        )
                    );
                } else {
                    setSelectedGenericFunctions([
                        ...selectedGenericFunctions,
                        data,
                    ]);
                }
            },
            onClick: (data: any) => {
                setIsFunctionEncrypted(false);
                getSelectedFunctionTypes({
                    selectedFunction: {
                        assetDescription: data.description,
                        assetName: data.name,
                        assetRef: data.typeId,
                        assetTags: data.tags,
                        assetType: data.model,
                        assetVersion: data.version,
                    },
                });
            },
        },
    ];

    return (
        <Dialog
            isOpen={showExportDialog}
            showCloseButton
            title={importMode ? IMPORT : EXPORT}
            dimBackground={true}
            onClose={() => closeImportExportDialog()}
            className={`export-dialog-container ${
                !importMode ? "export-libraries-dialog" : ""
            }`}
        >
            <div className="export-dialog-body">
                {importMode && (
                    <>
                        <input
                            type="file"
                            accept=".zip"
                            className="import-input"
                            onChange={(event: any) => {
                                checkValidityForImportFile(
                                    event.target.files[0]
                                );
                                props.updateFilesToBeImported({
                                    filesToBeImported: event.target.files[0],
                                });
                            }}
                        ></input>
                        <div className="import-error">{importErrorMsg}</div>

                        <div className="password-input-div">
                            <div>Enter password</div>
                            <CustomABBInput
                                dataType="password"
                                placeholder="Enter password"
                                className="password-input"
                            />
                        </div>
                    </>
                )}
                {!importMode && multipleFunction && multipleFunction === true && (
                    <React.Fragment>
                        {gettingLibraryAndNodes ? (
                            <Loader sizeClass="small" type="radial" />
                        ) : (
                            <div className="multiple-libraries-export">
                                <div className="library-list">
                                    <CustomABBInput
                                        type="discreet"
                                        dataType="search"
                                        placeholder="Search Function"
                                        icon="abb/search"
                                        value={searchValue}
                                        onValueChange={(value: string) =>
                                            handleSearchValue(value)
                                        }
                                        className="searchFunction"
                                    />
                                    {!isGenericPresent &&
                                    !isLibrariesPresent ? (
                                        <b> Functions Not Found </b>
                                    ) : (
                                        ""
                                    )}
                                    <CustomLeftNav
                                        navData={leftNavLibrariesArray}
                                        nLevelMenuProps={leftNavLibrariesConfig}
                                        key={"export.libraries.left_nav"}
                                        parentSelected={selectedFunctionKey}
                                        updateParentSelected={
                                            updateSelectedFunctionKey
                                        }
                                        filterText={searchValue}
                                    />
                                    <CustomLeftNav
                                        navData={leftNavGenericFunctionsArray}
                                        nLevelMenuProps={
                                            leftNavGenericFunctionsConfig
                                        }
                                        key={
                                            "export.generic_functions.left_nav"
                                        }
                                        parentSelected={selectedFunctionKey}
                                        updateParentSelected={
                                            updateSelectedFunctionKey
                                        }
                                        filterText={searchValue}
                                    />
                                </div>
                                <div className="function-view-container">
                                    <Condition when={selectedFunctionToView}>
                                        <FunctionsView
                                            isEncrypted={isFunctionEncrypted}
                                        />
                                    </Condition>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className="spinner-div">
                    <SpinnerModal
                        mode={"Spinner"}
                        showModal={showSpinnerModal}
                    />
                </div>
            </div>
            <div className="button-section">
                <Button
                    sizeClass="medium"
                    text={CONFIRMATION_BUTTON.CANCEL}
                    type="ghost"
                    onClick={() => closeImportExportDialog()}
                />
                <Button
                    sizeClass="medium"
                    text={importMode ? "Import" : "Export"}
                    type="primary-blue"
                    onClick={handleImportExport}
                    disabled={
                        importMode
                            ? !validImportFile
                            : selectedGenericFunctions.length ||
                              libraryToExport.length
                            ? false
                            : true
                    }
                />
            </div>
        </Dialog>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        selectedFunctionDetails: state.exportAsset.selectedFunctionDetails,
        functionVersionDetails: state.exportAsset.functionVersionDetails,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        filesToBeImported: state.exportAsset.filesTobeImported,
        libraryAndFunctionsList: state.functions.libraryAndFunctionsList,
        gettingLibraryAndNodes: state.functions.gettingLibrariesForExport,
        selectedFunctionToView: state.exportAsset.selectedFunctionToView,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getFunctionVersions: (val: getFunctionVersionsType) => {
            dispatch(getFunctionVersions(val));
        },
        storeFunctionsVersions: (val: any) => {
            dispatch(storeFunctionsVersions(val));
        },
        importFunctions: (payload: { filesToBeImported: any }) => {
            dispatch(importFunctions(payload));
        },
        removedFromDropDown: (val: any) => {
            dispatch(removedFromDropDown(val));
        },
        showNotificationModal: (val: any) =>
            dispatch(showNotificationModal(val)),
        updateFilesToBeImported: (payload: { filesToBeImported: any }) => {
            dispatch(handleFilesToBeImported(payload));
        },
        getLibraryAndNodesRequest: (exportMode?: boolean) => {
            dispatch(getLibraryAndNodesRequest(exportMode));
        },
        getExportableFunctionsRequest: (
            payload: payloadToExportFunctionWithLibraries[]
        ) => {
            dispatch(getExportableFunctionsRequest(payload));
        },
        getSelectedFunctionTypes: (payload: any) => {
            dispatch(getSelectedFunctionTypeForExport(payload));
        },
        resetSelectedFunctionTypeForView: () => {
            dispatch(resetSelectedFunctionTypeForExport());
        },
        showGetFileNameModal: (
            exportMethod: () => any,
            defaultFileName: string
        ) => {
            dispatch(
                showModal({
                    component: GetExportZipName,
                    modalTitle: "Add Export File Name",
                    data: {
                        exportMethod,
                        defaultFileName,
                    },
                    closeModal: () =>
                        dispatch(changeZipFileNameForExport({ fileName: "" })),
                    customClassName: "delete-library-modal",
                })
            );
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportDialog);
