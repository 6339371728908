import React from 'react';
import _ from 'lodash';
import Table from '../../../Table';
import Tr from '../../../Table/TableRow';
import Thead from '../../../Table/TableHead';
import Th from '../../../Table/TableHeaderData';
import Tbody from '../../../Table/TableBody';
import './style.scss';

import {
    ACTIVE_INPUT_TRIGGER_TAB,
    ACTIVE_EVENT_TRIGGER_TAB,
    ACTIVE_TIME_TRIGGER_TAB,
    ACTIVE_ALARM_TRIGGER_TAB,
} from '../../constant';

import { IoTableTabsProps } from '../..';
import ExpandableInputRows from './ExpandTableRow/InputRow';
import ExpandableTimeTriggerRows from './ExpandTableRow/TimeTriggerRow';
import ExpandableAlarmRows from './ExpandTableRow/AlarmRow';
import ExpandableEventRows from './ExpandTableRow/EventRow';

export interface AppProps {
    activeTab: string;
    tableHeaderData: {
        label: string;
        value: string;
    }[];
}

function TriggerTables(props: AppProps & IoTableTabsProps) {
    return (
        <div className="trigger-table-view">
            <div className="table1">
                <Table className={`table-ioTableTabs`}>
                    <Thead>
                        <Tr>
                            {/* {props.activeTab === ACTIVE_INPUT_TRIGGER_TAB && <Th> </Th>} */}
                            {props.tableHeaderData.map((header, index) => {
                                return (
                                    <Th
                                        key={index}
                                        className={
                                            props.activeTab !== ACTIVE_INPUT_TRIGGER_TAB &&
                                                index === 1
                                                ? 'w-22-column'
                                                : ''
                                        }
                                    >
                                        {header.label}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>

                    <Tbody className="table-body">
                        {props.activeTab === ACTIVE_INPUT_TRIGGER_TAB
                            ? props.tableData.functionTypeDetails.map((functionAsset) => {
                                  return (
                                      <ExpandableInputRows
                                          key={functionAsset.nodeId + 'input triggers'}
                                          {...props}
                                          asset={functionAsset}
                                      />
                                  );
                              })
                            : props.activeTab === ACTIVE_TIME_TRIGGER_TAB
                            ? props.tableData.functionTypeDetails.map((functionAsset) => {
                                  return (
                                      <ExpandableTimeTriggerRows
                                          key={functionAsset.nodeId + 'time triggers'}
                                          {...props}
                                          asset={functionAsset}
                                      />
                                  );
                              })
                            : props.activeTab === ACTIVE_ALARM_TRIGGER_TAB
                            ? props.tableData.functionTypeDetails.map((functionAsset) => {
                                  return (
                                      <ExpandableAlarmRows
                                          key={functionAsset.nodeId + 'alarm triggers'}
                                          {...props}
                                          asset={functionAsset}
                                          functionType={props.tableData.functionTypeDetails[0]}
                                      />
                                  );
                              })
                            : props.tableData.functionTypeDetails.map((functionAsset) => {
                                  return (
                                      <ExpandableEventRows
                                          key={functionAsset.nodeId + 'event triggers'}
                                          {...props}
                                          asset={functionAsset}
                                          objectTypeList={props.tableData.objectTypeDetails}
                                      />
                                  );
                              })}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
}

export default TriggerTables;
