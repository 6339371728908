import React, { useState } from "react";
import { Input, Icon } from "@abb/abb-common-ux-react";
import "../style.scss";
import CustomABBInput from "../../CustomABBInput";

interface IAddModelIdInput {
    invalidModelIdError: string;
    areAllAddedConditionMonitorsValid: boolean;
    handleAddModelId: (modelId: string) => void;
    clearInvalidInputError: () => void;
}

const AddModelIdInput = (props: IAddModelIdInput) => {
    const {
        invalidModelIdError,
        areAllAddedConditionMonitorsValid,
        handleAddModelId,
        clearInvalidInputError,
    } = props;
    const [modelId, setModelId] = useState("");
    return (
        <div className="condition-monitors-add-input">
            <CustomABBInput
                value={modelId}
                type="normal"
                dataType="text"
                id="monitorId"
                instantValidation={true}
                className="condition-monitor-input"
                showValidationBarWhenInvalid={true}
                showValidationIconWhenInvalid={true}
                validator={() =>
                    invalidModelIdError.length > 0
                        ? { valid: false, text: invalidModelIdError }
                        : {
                              valid: true,
                              text: "OK!",
                          }
                }
                disabled={!areAllAddedConditionMonitorsValid}
                onValueChange={(value) => {
                    if (value !== " ") {
                        setModelId(value.trim());
                        if (invalidModelIdError.length > 0) {
                            clearInvalidInputError();
                        }
                    }
                }}
                placeholder="Enter Model Id"
            />
            <Icon
                name="abb/plus-in-circle"
                sizeClass="medium"
                className="add-model-id-icon"
                onClick={() => {
                    handleAddModelId(modelId);
                    setModelId("");
                }}
            />
        </div>
    );
};

export default AddModelIdInput;
