import React, { ReactNode } from "react";
import { Table } from "reactstrap";
import "./style.scss";
// import "../../routes/Functions/style.scss";

interface AppProps {
    children: ReactNode;
    className?: string;
}

function CustomTable(props: AppProps) {
    return (
        <div className="wrapper-table">
            <Table className={props.className}>{props.children}</Table>
        </div>
    );
}

export default CustomTable;
