import { CreateObjectTypeDetail } from '../../../transformers/AssetType/ObjectType';

import { sce } from 'sce-engg-model-19.09';
import { pathFinder } from '../../../transformers/utils/outputFinder';
import _ from 'lodash';

interface Options {
    instance: any;
    position: { x: number; y: number };
}

export function getInstanceDetails(options: Options) {
    const { instance, position } = options;

    return sce.getObjectVariables(instance.objectId).then((res) => {
        console.log(res);

        const variables = {};
        const result = res.details;
        result.forEach((item, index) => {
            const splittedName = item.name.split('.');
            const variableName = splittedName[splittedName.length - 1];
            const extractedVariableNamePath = splittedName
                .slice(0, splittedName.length - 1)
                .join('.');
            const pathWithVariableName =
                splittedName.length > 1
                    ? extractedVariableNamePath + '.' + variableName
                    : variableName;
            if (!variables[pathWithVariableName]) {
                variables[pathWithVariableName] = {};
            }

            variables[pathWithVariableName]['path'] = `#${
                instance.modelId
            }/variables/${extractedVariableNamePath.split('.').join('/')}${
                splittedName.length > 1 ? '/' : ''
            }${variableName}`;
            variables[pathWithVariableName]['dataType'] = item.dataType;
        });
        console.log('variables :', variables);
        const paths = pathFinder(variables, 'path');
        console.log(paths);

        console.log(variables, instance.objectId);
        //@ts-ignore
        const obj = CreateObjectTypeDetail({
            name: instance.name || '',
            scale: 1,
            variables: variables,
            position,
            version: '',
            typeId: `${instance.modelId}.${instance.objectId}.${instance.name}`,
            nodeId: instance.objectId,
        });
        if (obj) {
            obj.objectInstance = true;
        }
        return obj || Promise.reject();
    });
}
