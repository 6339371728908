import React, { useState, Fragment, useEffect } from "react";
import { Checkbox, Input, Icon } from "@abb/abb-common-ux-react";
import { Table, Collapse, Card, CardBody } from "reactstrap";
import CalculationTableHeader from "../CalculationTableHeader";
import CalculationTableBody from "../CalculationTableBody";
import { Dispatch } from "redux";
import {
    FunctionHeaderTypeDetails,
    FunctionMode,
} from "../../../../store/function/types";
import {
    updateFunctionHeaderTypeDetails,
    setEmptyValidationErrors,
} from "../../../../store/function/action";
import { connect } from "react-redux";
import { StoreState } from "../../../../store";
import {
    convertedToJSONObj,
    validateName,
    hasWhiteSpace,
    getSelectedDataType,
    validateOutputForCondition,
    validateForSpecialCharacters_Including_HyphenAndUnderscore,
} from "../../helper";
import {
    CalculationFields,
    CALCULATION_FIELD,
    dropDownOptions,
} from "../../type";
import { FUNCTION_MODE } from "../../../../utils/constants/appConstants";
import "./style.scss";
import FunctionLogic from "../../FunctionLogic";
import _ from "lodash";
import { VALIDATION_MESSAGE } from "../../../../utils/constants/uiConstants";
import CustomABBInput from "../../../../components/CustomABBInput";
interface CalculationLogicTitleProps {
    calculationData: any;
    handleTitleDelete?: any;
    calculationDetails: any[];
    functionMode: FunctionMode;
    calculationDataIndex: number;
    handleCalculationError?: (value: boolean) => void;
    isEncrypted: boolean;
}

function CalculationLogicTitle(
    props: CalculationLogicTitleProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const { calculationDataIndex, calculationDetails, handleTitleDelete } =
        props;
    const [collapse, setCollapse] = useState(false);
    const [isOpenLogicDialog, setIsOpenLogicDialog] = useState(false);
    const [calculationNameErrorText, updateCalculationErrorText] = useState("");
    const [selectedValue, updateSelectedValue] = useState([] as any[]);

    const toggleCollapseStatus = () => {
        setCollapse(!collapse);
    };

    const handleUpdateDetails = (updatedDetails: any[]) => {
        const details = convertedToJSONObj([...updatedDetails]);
        props.handleFunctionHeaderDetails({
            ...props.functionTypeHeaderDetails,
            calculationDetails: { ...details },
        });
    };

    const handleCalcFieldChange = (
        fieldName: CalculationFields,
        value: string
    ) => {
        const previousValue = props.calculationDetails[calculationDataIndex];
        switch (fieldName) {
            case CALCULATION_FIELD.NAME:
                const isAvailable = validateName(
                    value,
                    props.calculationDetails
                );

                const outputData = Object.keys(
                    props.functionTypeHeaderDetails.outputDetails
                );

                const newData = outputData.filter(
                    (item: any, index) =>
                        item !==
                        props.calculationDetails[calculationDataIndex].name
                );

                const isOutputAvailable = validateOutputForCondition(
                    value,
                    newData
                );
                if (props.handleCalculationError) {
                    if (
                        !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                            value
                        ) ||
                        value.trim() === ""
                    ) {
                        let errorText = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
                        props.handleCalculationError(true);
                        if (hasWhiteSpace(value)) {
                            errorText = VALIDATION_MESSAGE.SPACE;
                        }
                        if (value.trim() === "") {
                            errorText = VALIDATION_MESSAGE.EMPTY_NAME;
                        }
                        updateCalculationErrorText(errorText);
                    } else {
                        if (!isAvailable || !isOutputAvailable) {
                            props.handleCalculationError(true);
                            updateCalculationErrorText("Name already exists");
                        } else {
                            props.handleCalculationError(false);
                            updateCalculationErrorText("");
                        }
                    }
                }

                props.calculationDetails[calculationDataIndex] = {
                    ...previousValue,
                    name: value,
                };

                break;

            case CALCULATION_FIELD.DESCRIPTION:
                props.calculationDetails[calculationDataIndex] = {
                    ...previousValue,
                    description: { dataType: "string", value: value },
                };
                break;
            case CALCULATION_FIELD.LOGIC:
                props.calculationDetails[calculationDataIndex] = {
                    ...previousValue,
                    logic: { dataType: "string", value: value.trim() },
                };
            default:
                break;
        }

        handleUpdateDetails([...calculationDetails]);

        calculationDetails.find((item: any) => {
            if (hasWhiteSpace(item.name)) {
                props.handleCalculationError &&
                    props.handleCalculationError(true);
            }
        });
    };

    const handleSelectedValue = (selectedObj: any) => {
        const updatedCalculationDetails = calculationDetails;
        const previousValue = updatedCalculationDetails[calculationDataIndex];

        updateSelectedValue([...selectedObj]);

        const dataTypeValue = getSelectedDataType(selectedObj[0]["value"]);

        if (
            selectedObj[0]["value"] === dropDownOptions.integerArr ||
            selectedObj[0]["value"] === dropDownOptions.numberArr ||
            selectedObj[0]["value"] === dropDownOptions.stringArr ||
            selectedObj[0]["value"] === dropDownOptions.booleanArr
        ) {
            updatedCalculationDetails[calculationDataIndex] = {
                ...previousValue,
                outputType: {
                    dataType: dataTypeValue["dataType"],
                    items: dataTypeValue["items"],
                },
            };
        } else {
            updatedCalculationDetails[calculationDataIndex] = {
                ...previousValue,
                outputType: {
                    dataType: dataTypeValue["dataType"],
                },
            };
        }

        handleUpdateDetails([...updatedCalculationDetails]);
    };
    useEffect(() => {
        const outputType =
            calculationDetails[calculationDataIndex]["outputType"];
        let selectedDataType = outputType["dataType"];
        let arrayType: string | undefined = outputType.items;
        if (arrayType) {
            if (arrayType === "integer") {
                selectedDataType = dropDownOptions.integerArr;
            }

            if (arrayType === "number") {
                selectedDataType = dropDownOptions.numberArr;
            }

            if (arrayType === "string") {
                selectedDataType = dropDownOptions.stringArr;
            }

            if (arrayType === "boolean") {
                selectedDataType = dropDownOptions.booleanArr;
            }
        }

        selectedValue[0] = { label: selectedDataType, value: selectedDataType };
        updateSelectedValue([...selectedValue]);

        const outputDetails = props.functionTypeHeaderDetails.outputDetails;
        Object.keys(outputDetails).forEach((item) => {
            if (props.functionTypeHeaderDetails.calculationDetails[item]) {
                delete props.functionTypeHeaderDetails.outputDetails[item];
                updateFunctionHeaderTypeDetails({
                    ...props.functionTypeHeaderDetails,
                    outputDetails: outputDetails,
                });
            }
        });
        props.setEmptyValidationsError([]);
    }, []);

    useEffect(() => {
        const details = [...props.calculationDetails];
        const calculationName =
            props.calculationDetails[calculationDataIndex].name;

        details.splice(calculationDataIndex, 1);
        if (!props.functionErrorFlag) {
            const isNameValid = validateName(calculationName, details);
            if (!isNameValid) {
                props.handleCalculationError &&
                    props.handleCalculationError(true);
                updateCalculationErrorText("Name already exists");
            } else {
                updateCalculationErrorText("");
            }
        }
    }, [props.functionErrorFlag, { ...props.functionTypeHeaderDetails }]);
    useEffect(() => {}, [{ ...props.functionTypeHeaderDetails }]);

    const handleLogicDialog = (logicValue: string) => {
        setIsOpenLogicDialog(true);
        handleCalcFieldChange(CALCULATION_FIELD.LOGIC, logicValue);
    };
    const handleCloseLogicDialog = () => {
        setIsOpenLogicDialog(false);
    };
    const handleSubmitLogicDialog = () => {
        setIsOpenLogicDialog(false);
    };
    return (
        <Fragment>
            <FunctionLogic
                logicValue={props.calculationData.logic}
                showLogicDialog={isOpenLogicDialog}
                handleCloseLogicDialog={handleCloseLogicDialog}
                handleSubmitLogicDialog={handleSubmitLogicDialog}
                handleInputChange={(val) =>
                    handleCalcFieldChange(CALCULATION_FIELD.LOGIC, val)
                }
                dataSet={[
                    ...Object.keys(
                        props.functionTypeHeaderDetails.inputDetails
                    ),
                ]}
            />
            <div className="function-calculation-collapse">
                <h2>
                    {props.functionMode === FUNCTION_MODE.VIEW ? (
                        <div className="view-mode-header">
                            {props.calculationData.name}
                        </div>
                    ) : (
                        <>
                            <Checkbox
                                sizeClass={"small"}
                                value={
                                    props.calculationData.isDelete
                                        ? true
                                        : false
                                }
                                onChange={() =>
                                    handleTitleDelete(
                                        props.calculationData.name
                                    )
                                }
                            />
                            <CustomABBInput
                                type="normal"
                                dataType="text"
                                value={props.calculationData.name}
                                onValueChange={(val: string) =>
                                    props.functionMode !== FUNCTION_MODE.VIEW &&
                                    handleCalcFieldChange(
                                        CALCULATION_FIELD.NAME,
                                        val
                                    )
                                }
                                className="functionClass"
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={true}
                                validator={() =>
                                    calculationNameErrorText.length > 0
                                        ? {
                                              valid: false,
                                              text: calculationNameErrorText,
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </>
                    )}
                </h2>
                {collapse ? (
                    <Icon
                        name="abb/minus"
                        sizeClass="small"
                        className="toggle-icon"
                        onClick={(e: any) => toggleCollapseStatus()}
                    />
                ) : (
                    <Icon
                        name="abb/plus"
                        sizeClass="small"
                        className="toggle-icon"
                        onClick={(e: any) => toggleCollapseStatus()}
                    />
                )}
            </div>

            <Collapse isOpen={collapse} timeout={{ enter: 0, exit: 0.3 }}>
                <Card>
                    <CardBody>
                        <Table className="customEditTableOverflow conditionEdit">
                            <CalculationTableHeader
                                functionMode={props.functionMode}
                            />
                            <CalculationTableBody
                                tableData={[props.calculationData]}
                                handleCalcFieldChange={handleCalcFieldChange}
                                functionMode={props.functionMode}
                                handleLogicDialog={handleLogicDialog}
                                handleCalculationError={
                                    props.handleCalculationError
                                }
                                selectedValue={[...selectedValue]}
                                handleSelectedValue={handleSelectedValue}
                                isConfigurationMode
                                isEncrypted={props.isEncrypted}
                                emptyErrors={props.emptyErrors}
                            />
                        </Table>
                    </CardBody>
                </Card>
            </Collapse>
        </Fragment>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        functionTypeHeaderDetails: state.functions.functionHeaderDetails,
        functionErrorFlag: state.functions.functionErrorFlag,
        emptyErrors: state.functions.emptyValidationsError,
    };
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        handleFunctionHeaderDetails: (payload: FunctionHeaderTypeDetails) => {
            dispatch(updateFunctionHeaderTypeDetails(payload));
        },
        setEmptyValidationsError: (payload: string[]) => {
            dispatch(setEmptyValidationErrors(payload));
        },
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalculationLogicTitle);
