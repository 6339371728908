import { all } from 'redux-saga/effects';
import modelsPageSaga from './modelsPage/saga/index';
import loginSaga from './login/saga';
import assetsSaga from './assets/saga';
import instanceConfigSaga from './instanceConfig/saga';
import configurationToolSaga from './configurationTool/saga';
import functionsSaga from './function/saga';
import exportAssetsSaga from './exportAsset/saga';
import supportConditionMonitorsSaga from './supportedConditionMonitors/saga';
import settingsSaga from './settings/saga';
const task = [
    ...loginSaga,
    ...modelsPageSaga,
    ...assetsSaga,
    ...instanceConfigSaga,
    ...configurationToolSaga,
    ...functionsSaga,
    ...exportAssetsSaga,
    ...supportConditionMonitorsSaga,
    ...settingsSaga,
];

// const task = []

const rootSaga = function* rootSaga() {
    yield all(task);
};

export default rootSaga;
