import React, { useState, useCallback, useEffect } from 'react';
import { FormGroup, Label, ModalFooter } from 'reactstrap';

import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import { Dispatch } from 'redux';
import { hideModal } from '../../../../store/modal/action';
import {
    handleAssetToggle,
    handleCriticalityMonitoring,
    updateAssetPageStatus,
    handleToggleApiForUpdate,
    updateModelStatus,
    updateAssetPageStatusUpdate,
} from '../../../../store/assets/actions';
import {
    handleAssetModelConfig,
    updateSelectedCriticalityModel,
    saveComputeModelInstanceConfig,
    deleteComputeModelInstanceConfig,
    updateInstanceMonitoringList,
    updateComputeModelInstanceConfig,
    handleLoading,
    updateButtonLoader,
    checkedListofItems,
    checkedListofItemupdate,
} from '../../../../store/instanceConfig/actions';
import './style.scss';
import { updateAssetConfigModel } from '../types';
import { Input, ToggleSwitch, Button } from '@abb/abb-common-ux-react';
import { ComputeModelToJson } from '../../../../transformers/ComputeModel/toJson/ComputeModelToJson';
import {
    isTypePreviouslySelected,
    getConfiguredAndUnConfiguredType,
    getUpdatedSupportedValues,
    getUpdatedSupportedValuesName,
    getMaskedName,
} from '../../../../utils/helpers';
import AssetUpdateModalItem from './AssetUpdateModelItem';
import Condition from '../../../../components/shared/Condition';
import Flex from '../../../../components/shared/Flex';
import Loader from '../../../../components/Loader';
import { LOADER_TYPE, LOADER_SIZE } from '../../../../utils/constants/appConstants';
import {
    SupportedTypes,
    InstanceMonitoringItem,
    AmInfo,
} from '../../../../store/instanceConfig/types';
import { store } from '../../../../containers/AppBoot/index';
import _ from 'lodash';
import { handleCanvasZoomUpdate } from '../../../../store/configurationTool/actions';
interface AssetConfigModalProps {}

export interface SupportedTypesForUpdate {
    key: string;
    value: {
        modelTypeLabel: string;
        types: { typeId: string; typeName: string; version: string; objectId: string }[];
    };
    amInfo: any;
}

export const AssetUpdateModalForm = (
    props: AssetConfigModalProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    debugger;
    const [description, setDescription] = useState('');
    const [type, setType] = useState((props.modelDetails && props.modelDetails.typeId) || '');
    const [tags, setTags] = useState('');
    const [showAdvancedOptions, toggleAdvancedOptions] = useState(false);
    const [errorName, updateErrorName] = useState(false);
    const [errorDescription, updateErrorDescription] = useState(false);
    const [errorTags, updateErrorTags] = useState(false);
    const [stopMonitoringDisableStatus, updateStopMonitoringDisableStatus] = useState(true);
    const [viewAndDeployDisableStatus, updateViewAndDeployDisableStatus] = useState(true);
    const [submitBtnDisableStatus, updateSubmitBtnDisableStatus] = useState(true);
    const [configSupportedType, updateConfigSupportedType] = useState(
        [] as SupportedTypesForUpdate[]
    );
    const [filteredConfigSupportedType, setFilterForConfigSupportedTypes] = useState(
        [] as SupportedTypesForUpdate[]
    );
    const [filter, setFilter] = useState('');
    const [saveInstanceComputeModelUpdate, setsaveInstanceComputeModelUpdate] = useState();
    const [name, setAssetName] = useState('');
    const [listofItems, setlistofItems] = useState(false);
    const [version, setVersion] = useState([] as any);

    useEffect(() => {
        // to disable enable the config buttons
        updateViewAndDeployDisableStatus(true);
        props.checkedListofItemupdate(true);
        props.checkedListofItems(true);
        if (props.selectedInstances) {
            setlistofItems(true);
        }
        let configSelectedTypesCount = 0;
        if (props.isInstanceConfigured) {
            let isDisable = false;
            props.selectedInstances.forEach((element: any) => {
                const assetInstance = props.instanceTableDataById[element];
                const assetInstanceAmInfo = assetInstance.amInfo;
                Object.keys(props.selectedTypes).forEach((type) => {
                    const isPreviouslySelected = isTypePreviouslySelected(
                        assetInstanceAmInfo,
                        type
                    );
                    if (!isPreviouslySelected) {
                        isDisable = true;
                    } else {
                        configSelectedTypesCount++;
                    }
                });

                if (configSelectedTypesCount === 0) {
                    updateSubmitBtnDisableStatus(true);
                    props.checkedListofItemupdate(true);
                    props.checkedListofItems(true);
                } else {
                    updateSubmitBtnDisableStatus(false);
                    props.checkedListofItemupdate(false);
                    props.checkedListofItems(false);
                }

                if (configSelectedTypesCount === 1) {
                    updateViewAndDeployDisableStatus(false);
                    props.checkedListofItemupdate(false);
                    props.checkedListofItems(false);
                } else {
                    updateViewAndDeployDisableStatus(true);
                    props.checkedListofItemupdate(true);
                    props.checkedListofItems(true);
                }
            });
        }
    }, [props.selectedTypes]);

    useEffect(() => {
        updateViewAndDeployDisableStatus(true);
        updateSubmitBtnDisableStatus(true);
        let configuredSupportedType: SupportedTypesForUpdate[] = [];
        let unConfiguredSupportedType: SupportedTypes[] = [];
        let assetInstanceAmInfo: any[] = [];
        const updatedSupportedTypes = _.cloneDeepWith(props.supportedTypes);

        if (props.isInstanceConfigured && props.selectedInstances.length > 0) {
            props.selectedInstances.forEach((instance, index) => {
                const assetInstance = props.instanceTableDataById[instance];
                assetInstanceAmInfo = assetInstance.amInfo;
                console.log('assetaminfo', assetInstanceAmInfo);
                updatedSupportedTypes.forEach((supportedType) => {
                    const matchedModelType = assetInstanceAmInfo.find(
                        (item: any) => item.assetMonitorModel === supportedType.key
                    );

                    if (matchedModelType) {
                        let matchedIndex = configuredSupportedType.find(
                            (item: any) => item.key === matchedModelType.assetMonitorModel
                        );
                        console.log(matchedModelType);
                        if (!matchedIndex && matchedModelType.isUpdateAvailable) {
                            configuredSupportedType.push({
                                ...supportedType,
                                amInfo: matchedModelType,
                            });
                        }
                    } else {
                        unConfiguredSupportedType.push(supportedType);
                    }
                });
            });
        }
        updateConfigSupportedType([...configuredSupportedType]);
    }, [props.supportedTypes]);

    useEffect(() => {
        const assetInstance = props.instanceTableDataById[props.selectedInstances[0]];
        if (props.isInstanceConfigured) {
            setAssetName(props.configFormFields.name);
            setDescription(props.configFormFields.description);
            const tags = props.configFormFields.tags.join();
            setTags(tags);
        } else {
            const selectedAssetName = assetInstance.name;
            setAssetName(selectedAssetName);
        }
        // if (Object.keys(props.selectedTypes).length === 0) {
        //     updateSubmitBtnDisableStatus(true);
        //     updateStopMonitoringDisableStatus(true);
        //     updateViewAndDeployDisableStatus(true);
        // } else {
        //     if (configSelectedTypesCount <= 1) {
        //         updateSubmitBtnDisableStatus(false);
        //     }

        //     if (configSelectedTypesCount == 0 && Object.keys(props.selectedTypes).length === 1) {
        //         updateViewAndDeployDisableStatus(false);
        //     } else {
        //         updateViewAndDeployDisableStatus(true);
        //     }
        // }
    }, [props.modelDetails, props.configFormFields]);

    const handleSubmit = () => {
        // event.preventDefault();
        props.updateButtonLoader(true);
        let returnVal: boolean = true;
        let assetInstanceAmInfo: any[] = [];

        props.clickedActiveParams.map((item: any) => {
            var allVersion = version;
            allVersion.push(item.version);
            setVersion(allVersion);
        });
        props.updateComputeModelInstanceConfig(version);
        const state = store.getState();
        return returnVal;
    };

    const handleViewAndDeploy = () => {
        props.loadingTypes;
        props.updateModelStatus(true);
        let assetInstanceAmInfo: any[] = [];
        props.clickedActiveParams.map((item: any) => {
            setVersion(item.version);
            const updateAssetConfigModelData: updateAssetConfigModel = {
                description: description,
                name: '',
                tags: tags ? tags.split(',') : [],
                typeId: type,
                //version: ,
            };
            props.handleAssetModelConfig(updateAssetConfigModelData);
            props.hideAssetConfigModal();
            props.handleAssetPageStatus(true);
            props.handleToggleApiForUpdate(item.version);
        });
    };

    const handleDeleteInstanceConfig = () => {
        props.hideAssetConfigModal();
        props.updateInstanceMonitoringList([
            {
                key: props.selectedInstances[0],
                value: Object.keys(props.selectedTypes),
            },
        ]);
        props.deleteComputeModelInstanceConfig();
    };

    const getUpdatedConfigTypes = (supportedType: SupportedTypesForUpdate[], filter: string) => {
        let updatedSupportedType = supportedType;
        if (filter.length > 0 && supportedType.length > 0) {
            updatedSupportedType = supportedType.map((unConfigType) => {
                unConfigType.value.types = unConfigType.value.types.filter((item) =>
                    getMaskedName(item).maskName.toLowerCase().includes(filter.toLowerCase())
                );
                return unConfigType;
            });
        }
        return updatedSupportedType;
    };

    useEffect(() => {
        if (configSupportedType.length > 0) {
            const updatedConfigSupportedType = getUpdatedConfigTypes(
                _.cloneDeepWith(configSupportedType),
                filter
            );
            setFilterForConfigSupportedTypes(updatedConfigSupportedType);
        }
    }, [filter, configSupportedType]);
    console.log('ALL types -->', filteredConfigSupportedType);

    return (
        <div className="wrapper-asset-config-modal">
            <div className="wrapper-config-modal-body">
                <Condition when={props.loadingTypes || props.segregatedModelsLoading}>
                    <Flex fill center>
                        <Loader type={LOADER_TYPE.RADIAL} sizeClass={LOADER_SIZE.MEDIUM} />
                    </Flex>
                </Condition>
                <Condition when={!props.loadingTypes && !props.segregatedModelsLoading}>
                    {props.supportedTypes.length === 0 ? (
                        <div>No types available!</div>
                    ) : (
                        <>
                            <div className="wrapper-model-list-pane">
                                {/* <div className="wrapper-type-list"></div> */}
                                {props.isInstanceConfigured &&
                                    !props.segregatedModelsLoading &&
                                    !props.loadingTypes && (
                                        <div className="wrapper-type-list">
                                            <div className="model-list-title">
                                                New Updates Available
                                            </div>
                                            <div className="model-list-parent">
                                                {filteredConfigSupportedType.length === 0 ? (
                                                    <div>No types available!</div>
                                                ) : (
                                                    filteredConfigSupportedType.length &&
                                                    filteredConfigSupportedType.map(
                                                        (supportedType, index) => {
                                                            if (
                                                                supportedType.amInfo
                                                                    .isUpdateAvailable
                                                            ) {
                                                                return (
                                                                    <AssetUpdateModalItem
                                                                        key={supportedType.key}
                                                                        supportedType={{
                                                                            ...supportedType,
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return <></>;
                                                        }
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </>
                    )}
                </Condition>
            </div>
            <ModalFooter>
                <Button
                    className="create-btn"
                    type="primary-blue"
                    text="View and Update"
                    onClick={handleViewAndDeploy}
                    disabled={
                        //@ts-ignore
                        (props.selectedTypesUpdate && props.selectedTypesUpdate.length !== 1) ||
                        props.loadingTypes ||
                        props.segregatedModelsLoading
                    }
                />

                {props.submitBtnText && (
                    <Button
                        className="create-btn"
                        type="primary-blue"
                        text={props.submitBtnText}
                        onClick={handleSubmit}
                        disabled={
                            //@ts-ignore
                            (props.selectedTypesUpdate && props.selectedTypesUpdate.length === 0) ||
                            props.loadingTypes ||
                            props.segregatedModelsLoading
                        }
                    />
                )}
            </ModalFooter>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        submitBtnText: state.modal.data && state.modal.data.submitBtnText,
        stopMonitoringBtnText: state.modal.data && state.modal.data.stopMonitoringBtnText,
        modelDetails: state.instanceConfig.activeModel.modelInstance.modelDetails,
        assetConfigurationMode: state.assets.configurationMode,
        assetCriticalityModels: state.instanceConfig.assetCriticalityModels,
        instanceTableDataById: state.instanceConfig.instancesTable.tableData.byId,
        selectedInstances: state.instanceConfig.instancesTable.tableData.selectedInstances,
        conditionMonitorModels: state.instanceConfig.computeModels.all,
        supportedTypes: state.instanceConfig.supportedTypes,
        isLoading: state.instanceConfig.isLoading,
        isInstanceConfigured: state.instanceConfig.isInstanceConfigured,
        selectedTypes: state.instanceConfig.selectedTypes,
        configFormFields: state.instanceConfig.configFormfields,
        loadingTypes: state.instanceConfig.assetConfigLoading,
        segregatedModelsLoading: state.instanceConfig.segregatedModelsLoading,
        selectedTreeNode: state.assets.selectedTreeNode,
        configurationMode: state.assets.configurationMode,
        isChekeditems: state.instanceConfig.isCheckeditems,
        isCheckeditemupdate: state.instanceConfig.isCheckeditemupdate,
        clickedActiveParams: state.instanceConfig.clickedActiveParams,
        selectedTypesUpdate: state.instanceConfig.selectedTypesUpdate,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleToggleApiForUpdate: (latestVersion: string) => {
            dispatch(handleToggleApiForUpdate(latestVersion));
        },
        updateModelStatus: (payload: boolean) => {
            dispatch(updateModelStatus(payload));
        },
        handleLoading: (payload: boolean) => {
            dispatch(handleLoading(payload));
        },
        handleAssetPageStatus: (configurationMode: boolean) => {
            dispatch(updateAssetPageStatus(configurationMode));
        },
        // handleAssetPageStatusUpdate: (configurationMode: boolean) => {
        //     dispatch(updateAssetPageStatusUpdate(configurationMode));
        // },
        hideAssetConfigModal: () => dispatch(hideModal()),
        handleAssetModelConfig: (updateAssetConfigModel: updateAssetConfigModel) => {
            dispatch(handleAssetModelConfig({ updateAssetConfigModel }));
        },
        handleSelectedCriticalityModel: (payload: ComputeModelToJson) => {
            dispatch(updateSelectedCriticalityModel(payload));
        },
        updateButtonLoader: (payload: boolean) => {
            dispatch(updateButtonLoader(payload));
        },
        handleCriticalityMonitoring: (payload: ComputeModelToJson) => {
            dispatch(handleCriticalityMonitoring(payload));
        },

        saveInstanceComputeModel: (payload: { objectIds: string[] }) => {
            dispatch(saveComputeModelInstanceConfig(payload));
        },
        // saveInstanceComputeModelUpdate: (payload: { objectIds: string[] }) => {
        //     dispatch(updateComputeModelInstanceConfig(payload));
        // },
        deleteComputeModelInstanceConfig: () => {
            dispatch(deleteComputeModelInstanceConfig());
        },
        updateInstanceMonitoringList: (payload: InstanceMonitoringItem[]) => {
            dispatch(updateInstanceMonitoringList(payload));
        },
        updateComputeModelInstanceConfig: (payload: { objectIds: string[] }) => {
            dispatch(updateComputeModelInstanceConfig(payload));
        },
        checkedListofItemupdate: (payload: boolean) => {
            dispatch(checkedListofItemupdate(payload));
        },
        checkedListofItems: (payload: boolean) => {
            dispatch(checkedListofItems(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetUpdateModalForm);
