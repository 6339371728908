import React from 'react';
import AssetUpdateModalForm from './AssetUpdateModalForm';
interface AssetConfigModalProps {}

const AssetConfigModal = (props: AssetConfigModalProps) => {
    return (
        <div>
            <AssetUpdateModalForm />
        </div>
    );
};

export default AssetConfigModal;
