import { FabricFunctionRectangle } from './../UIHelpers/functionRectangle/index';
import { IOTypes, Asset, IODataTypes } from '../types';

import { FabricIOCircle, FabricOutputCircle } from '../UIHelpers/FabricIOCircle';
import { FabricOLWN } from '../UIHelpers/ioGroup/FabricLineWithName';
import { fabric } from 'fabric';

import { FabricONWN } from '../UIHelpers/ioGroup/FabricLineWithCircle';
import {
    outputCircleRadius,
    PINS_DISTANCE,
    fullFunctionPinLength,
    objectOutputPinLength,
    GRID_PIXEL,
    BLOCK_BOTTOM_PADDING,
    rectOffset,
} from '../UIHelpers/UiConfig';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import {
    FunctionInputCircle,
    FunctionOutputCircle,
    ObjectOutputCircle,
    FunctionInputTrigger,
    FunctionOutputPublish,
    FunctionAlarmPinData,
} from './types';
import { FabricILWV, FabricILI } from '../UIHelpers/ioGroup/FabricLineWithValue';
import { FabricOLWCA } from '../UIHelpers/ioGroup/FabricLineWithAlarm';
import { FabricAlarmIcon } from '../UIHelpers/FabricAlarmIcon';
import { FabricHistoryIcon } from '../UIHelpers/FabricHistoryIcon';
import { FabricDropDownTitle } from '../UIHelpers/titleRectangle';
import uuid from 'uuid';
import { FabricIOLine } from '../UIHelpers/FabricIOLine';
import { FabricILA, FabricILE, FabricLineDeleteIcon } from '../UIHelpers/ioGroup/FabricILA';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import EventTypeDetail from '../../../transformers/AssetType/EventType';
import { FabricAlarmTriggerIcon } from '../UIHelpers/ioGroup/FabricAlarmTriggerIcon';
import { FabricEventTriggerIcon } from '../UIHelpers/ioGroup/FabricEventTriggerIcon';
import { FabricOutputAlarmIcon } from '../UIHelpers/ioGroup/AlarmIcon';
import { ROUTE_PATHNAME } from '../../../utils/constants/appConstants';

abstract class IO {
    nodeId: string;
    abstract ioLines: fabric.Group[];
    abstract ioCircles: FabricIOCircle[];
    abstract type: IOTypes;
    abstract completeGroup: fabric.Group;

    constructor(options: { asset: Asset }) {
        this.nodeId = options.asset.nodeId;
    }
}

abstract class AEPin {
    nodeId: string;
    abstract ioLine: fabric.Group;
    abstract ioCircle: FabricIOCircle;
    abstract type: IOTypes.ALARM;
    abstract ioTrigger: FunctionInputTrigger;
    abstract alarmIcon: FabricAlarmTriggerIcon;
    abstract crossIcon: FabricLineDeleteIcon;
    abstract completeGroup: fabric.Group;

    constructor(options: { asset: Asset }) {
        this.nodeId = options.asset.nodeId;
    }
}

abstract class EAPin {
    nodeId: string;
    abstract ioLine: fabric.Group;
    abstract ioCircle: FabricIOCircle;
    abstract type: IOTypes.EVENT;
    abstract ioTrigger: FunctionInputTrigger;
    abstract eventIcon: FabricEventTriggerIcon;
    abstract crossIcon: FabricLineDeleteIcon;
    abstract completeGroup: fabric.Group;

    constructor(options: { asset: Asset }) {
        this.nodeId = options.asset.nodeId;
    }
}

export class FunctionAlarmInputPin extends AEPin {
    ioLine: FabricIOLine;
    ioCircle: FabricIOCircle;
    type: IOTypes.ALARM;
    ioTrigger: FunctionInputTrigger;
    alarmIcon: FabricAlarmTriggerIcon;
    crossIcon: FabricLineDeleteIcon;
    completeGroup: fabric.Group;

    constructor(options: {
        asset: FunctionTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricFunctionRectangle;
        circleData: FunctionTypeDetail['inputs'][0];
    }) {
        super(options);
        this.type = IOTypes.ALARM;
        const { alarmIcon, crossIcon, ioLine, ioCircle, ioTrigger } = new FabricILA({
            lineText: IODataTypes.STRING,
            id: options.circleData.id,
        });
        this.ioLine = ioLine;
        this.ioCircle = ioCircle;
        this.ioCircle.data = {
            asset: options.asset,
            circleData: options.circleData,
            tooltip: options.circleData.path,
        };
        this.ioTrigger = ioTrigger as FunctionInputTrigger;
        this.alarmIcon = alarmIcon;
        this.crossIcon = crossIcon;
        const { inhibits, inputs, scale: scaleX, scale: scaleY } = options.asset;
        const pinLength = inputs.length + inhibits.length;
        const pinsY = pinLength * PINS_DISTANCE;
        this.ioCircle.top = pinsY;
        this.ioLine.top = pinsY;
        this.ioTrigger.top = pinsY;
        this.alarmIcon.top = pinsY - 2;
        this.crossIcon.top = pinsY;
        this.alarmIcon.left = ioLine.getScaledWidth();
        this.alarmIcon.originX = 'left';
        this.crossIcon.left = ioLine.getScaledWidth() + alarmIcon.getScaledWidth() + 3;
        this.crossIcon.originX = 'left';
        this.crossIcon.name = 'deleteAlarm';
        this.crossIcon.data = {
            parent: options.parent,
            asset: options.asset,
            circleData: options.circleData,
        };
        this.alarmIcon.data = {
            parent: options.parent,
            asset: options.asset,
            circleData: options.circleData,
        };

        this.ioTrigger.data = {
            asset: options.asset,
            circleData: options.circleData,
        };
        this.completeGroup = new fabric.Group(
            !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ? [
                    this.ioLine,
                    this.ioTrigger,
                    this.ioCircle,
                    this.alarmIcon,
                    this.crossIcon,
                ] : [
                    this.ioLine,
                    this.ioTrigger,
                    this.ioCircle,
                    this.alarmIcon,
                ]
            );

        const leftMargin = fullFunctionPinLength * scaleX;
        const topMargin = 3 * scaleX;
        this.completeGroup.set('originX', 'left');
        this.completeGroup.set('originY', 'center');
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set('left', x - options.nodeWidth / 2 - leftMargin);
        this.completeGroup.set('top', y + options.nodeHeight / 2 + topMargin);
        console.log(y + options.nodeHeight / 2 + topMargin);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);

        options.parent.forEachObject((object) => {
            if (object.type === 'rect') {
                object.set('height', object.getScaledHeight() + GRID_PIXEL * 2);
                return;
            }
        });

        options.parent.addWithUpdate();
    }
}


export class FunctionEventInputPin extends EAPin {
    ioLine: FabricIOLine;
    ioCircle: FabricIOCircle;
    type: IOTypes.EVENT;
    ioTrigger: FunctionInputTrigger;
    eventIcon: FabricEventTriggerIcon;
    crossIcon: FabricLineDeleteIcon;
    completeGroup: fabric.Group;

    constructor(options: {
        asset: FunctionTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricFunctionRectangle;
        circleData: FunctionTypeDetail['inputs'][0];
    }) {
        super(options);
        this.type = IOTypes.EVENT;
        const { eventIcon, crossIcon, ioLine, ioCircle, ioTrigger } = new FabricILE({
            lineText: IODataTypes.EVENT,
            id: options.circleData.id,
        });
        this.ioLine = ioLine;
        this.ioCircle = ioCircle;
        this.ioCircle.data = {
            asset: options.asset,
            circleData: options.circleData,
            tooltip: options.circleData.path,
        };
        this.ioTrigger = ioTrigger as FunctionInputTrigger;
        this.eventIcon = eventIcon;
        this.crossIcon = crossIcon;
        const { inhibits, inputs, scale: scaleX, scale: scaleY } = options.asset;
        const pinLength = inputs.length + inhibits.length;
        const pinsY = pinLength * PINS_DISTANCE;
        this.ioCircle.top = pinsY;
        this.ioLine.top = pinsY;
        this.ioTrigger.top = pinsY;
        this.eventIcon.top = pinsY - 2;
        this.crossIcon.top = pinsY;
        this.eventIcon.left = ioLine.getScaledWidth();
        this.eventIcon.originX = 'left';
        this.crossIcon.left = ioLine.getScaledWidth() + eventIcon.getScaledWidth() + 3;
        this.crossIcon.originX = 'left';
        this.crossIcon.name = 'deleteAlarm';
        this.crossIcon.data = {
            parent: options.parent,
            asset: options.asset,
            circleData: options.circleData,
        };
        this.eventIcon.data = {
            parent: options.parent,
            asset: options.asset,
            circleData: options.circleData,
        };

        this.ioTrigger.data = {
            asset: options.asset,
            circleData: options.circleData,
        };
        this.completeGroup = new fabric.Group([
            this.ioLine,
            this.ioTrigger,
            this.ioCircle,
            this.eventIcon,
            this.crossIcon,
        ]);

        const leftMargin = fullFunctionPinLength * scaleX;
        const topMargin = 3 * scaleX;
        this.completeGroup.set('originX', 'left');
        this.completeGroup.set('originY', 'center');
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set('left', x - options.nodeWidth / 2 - leftMargin);
        this.completeGroup.set('top', y + options.nodeHeight / 2 + topMargin);
        console.log(y + options.nodeHeight / 2 + topMargin);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);

        options.parent.forEachObject((object) => {
            if (object.type === 'rect') {
                object.set('height', object.getScaledHeight() + GRID_PIXEL * 2);
                return;
            }
        });

        options.parent.addWithUpdate();
    }
}

export class FunctionInput extends IO {
    ioLines: fabric.Group[] = [];
    ioCircles: FunctionInputCircle[] = [];
    type: IOTypes;
    completeGroup: fabric.Group;
    ioTriggers: FunctionInputTrigger[] = [];
    historyIcons: FabricHistoryIcon[] = [];

    constructor(options: {
        asset: FunctionTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricDropDownTitle;
    }) {
        super(options);
        this.type = IOTypes.INPUT;
        const { asset } = options;
        const { inputs, position, inhibits, scale: scaleX, scale: scaleY } = asset;
        const { nodeHeight, nodeWidth } = options;
        const lineTexts: fabric.Text[] = [];
        const defaultValues: fabric.Text[] = [];
        inhibits.forEach((data, index) => {
            const { dataType } = data;
            let y: number;

            y = index * PINS_DISTANCE;

            const result = new FabricILI({
                lineText: dataType,
                text: data.name,
                value: data.defaultValue || '',
            });

            let { ioLine, lineText, value } = result;

            let ioCircle = result.ioCircle as FunctionInputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };

            let ioTrigger = result.ioTrigger as FunctionInputTrigger;
            ioTrigger.data = { asset, circleData: data };

            Object.keys(result).forEach((key) => {
                if (result[key]) {
                    result[key].top = y;
                }
            });

            this.ioTriggers.push(ioTrigger);
            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(lineText);
            defaultValues.push(value);
        });

        inputs.forEach((data, index) => {
            const { id, dataType, connected } = data;
            let y: number;

            // y = ((index + inhibits.length) * (nodeHeight / (scaleY || 1))) / dataLength;
            y = (index + inhibits.length) * PINS_DISTANCE;
            const result = new FabricILWV({
                lineText: dataType,
                connected: connected,
                text: data.name,
                value: data.defaultValue ? data.defaultValue + '' : '',
            });

            let { ioLine, lineText, value } = result;

            let ioCircle = result.ioCircle as FunctionInputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };

            let ioTrigger = result.ioTrigger as FunctionInputTrigger;
            ioTrigger.data = { asset, circleData: data };

            let historyIcon = result.historyIcon as FabricHistoryIcon | undefined;

            Object.keys(result).forEach((key) => {
                if (result[key]) {
                    result[key].top = y;
                }
            });
            if (historyIcon) {
                historyIcon.data = { asset, circleData: data };
                historyIcon.top = y + 8;
            }

            historyIcon && this.historyIcons.push(historyIcon);
            this.ioTriggers.push(ioTrigger);
            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(lineText);
            defaultValues.push(value);
        });

        this.completeGroup = new fabric.Group([
            ...lineTexts,
            ...this.historyIcons,
            ...this.ioLines,
            ...this.ioTriggers,
            ...this.ioCircles,
            ...defaultValues,
        ]);
        const leftMargin = fullFunctionPinLength * scaleX;
        this.completeGroup.set('originX', 'left');
        this.completeGroup.set('originY', 'top');
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set('left', x - options.nodeWidth / 2 - leftMargin);
        this.completeGroup.set('top', y + parentHeight);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);
        if (this.historyIcons.length > 0) {
        }
    }
}

export class FunctionOutput extends IO {
    conditionLine: fabric.Group[] = [];
    conditionAlarm: FabricAlarmIcon[] = [];
    conditionAlarmCircle: FabricOutputCircle[] = [];
    ioLines: fabric.Group[] = [];
    ioCircles: FunctionOutputCircle[] = [];
    ioPublish: FunctionOutputPublish[] = [];
    type: IOTypes;
    completeGroup: fabric.Group;

    constructor(options: {
        asset: FunctionTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricDropDownTitle;
    }) {
        super(options);
        this.type = IOTypes.OUTPUT;

        const lineTexts: fabric.Text[] = [];
        const { asset } = options;
        const { outputs, scale: scaleX, scale: scaleY, conditions } = asset;
        const { nodeHeight, nodeWidth } = options;
        const conditionLength = conditions ? 1 : 0;
        let hasAlarmPin = false;

        if (conditions && asset.alarmMapping) {
            hasAlarmPin = true;
            let y: number;
            y = 0 * PINS_DISTANCE;
            const result = new FabricOLWCA({
                // conditionNameText: data,
            });

            const { alarmLine, alarmIcon, alarmCircle } = result;
            // add data to alarm icon
            alarmIcon.data = {
                asset,
                alarmPins: asset.alarmPins,
            };

            Object.keys(result).forEach((key) => {
                result[key].top = y;
            });

            this.conditionAlarm.push(alarmIcon);
            this.conditionLine.push(alarmLine);
            this.conditionAlarmCircle.push(alarmCircle);
        }

        // TODO: handle is published

        outputs.forEach((data, index) => {
            const { id, dataType } = data;
            let y: number;
            y = hasAlarmPin ? (index + 1) * PINS_DISTANCE : index * PINS_DISTANCE;
            const result = new FabricOLWN({
                lineText: dataType,
                text: data.name,
                triggered: data.isPublished,
            });
            const { ioLine, lineText } = result;
            let ioCircle = result.ioCircle as FunctionOutputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };
            const ioPublish = result.ioPublish as FunctionOutputPublish;
            ioPublish.data = { asset, circleData: data };

            Object.keys(result).forEach((key) => {
                result[key].top = y;
            });

            this.ioPublish.push(ioPublish);
            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(lineText);
        });

        this.completeGroup = new fabric.Group([
            ...this.ioLines,
            ...this.ioPublish,
            ...this.ioCircles,
            ...lineTexts,
            ...this.conditionAlarm,
            ...this.conditionLine,
            ...this.conditionAlarmCircle,
        ]);

        // output pins group
        this.completeGroup.set('originX', 'right');
        this.completeGroup.set('originY', 'center');

        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();

        this.completeGroup.set('left', x + nodeWidth / 2 + fullFunctionPinLength * scaleX);
        this.completeGroup.set('top', y + nodeHeight / 2 + parentHeight / 2);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);
    }
}
export class ObjectOutput extends IO {
    ioLines: fabric.Group[] = [];
    ioCircles: ObjectOutputCircle[] = [];
    alarmIcons: FabricOutputAlarmIcon[] = [];
    type: IOTypes;
    completeGroup: fabric.Group;

    constructor(options: {
        asset: ObjectTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricDropDownTitle;
    }) {
        super(options);
        this.type = IOTypes.OUTPUT;

        const { asset } = options;
        const { outputs, scale: scaleX, scale: scaleY } = asset;
        const { nodeHeight, nodeWidth } = options;
        const lineTexts: fabric.Text[] = [];
        outputs.forEach((data, index) => {
            const { id, dataType, name, path, alarmId } = data;
            let y: number;

            y = index * PINS_DISTANCE;

            const result = new FabricONWN({
                lineText: dataType,
                title: name,
                isAlarmPin: !!alarmId,
            });

            const { ioLine, pinTitle, alarmIcon } = result;
            let ioCircle = result.ioCircle as ObjectOutputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };
            let pinTitleData = result.pinTitle;
            pinTitle.data = { tooltip: data.path };

            Object.keys(result).forEach((key) => {
                if (result[key]) {
                    result[key].top = y;
                }
            });

            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(pinTitleData);
            if (alarmIcon) {
                alarmIcon.data = { asset: asset };
                this.alarmIcons.push(alarmIcon);
            }
        });

        this.completeGroup = new fabric.Group([
            ...this.alarmIcons,
            ...lineTexts,
            ...this.ioLines,
            ...this.ioCircles,
        ]);
        this.completeGroup.set('originX', 'right');
        this.completeGroup.set('originY', 'center');
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set(
            'left',
            x + nodeWidth / 2 + (objectOutputPinLength + outputCircleRadius) * scaleX
        );
        this.completeGroup.set('top', y + nodeHeight / 2 + parentHeight / 2);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);
    }
}

export class AlarmOutput extends IO {
    ioLines: fabric.Group[] = [];
    ioCircles: FunctionOutputCircle[] = [];
    ioPublish: FunctionOutputPublish[] = [];
    type: IOTypes;
    completeGroup: fabric.Group;

    constructor(options: {
        asset: AlarmTypeDetail | EventTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricDropDownTitle;
    }) {
        super(options);
        this.type = IOTypes.OUTPUT;

        const lineTexts: fabric.Text[] = [];
        const { asset } = options;
        const {
            nodeId,
            assetRef,
            assetType,
            outputs,
            // staticOutputs,
            position,
            scale: scaleX,
            scale: scaleY,
        } = asset;
        const { nodeWidth, nodeHeight } = options;

        // staticOutputs.forEach((data: any, index: number) => {
        //     const { id, dataType, value } = data;
        //     let y: number;
        //     y = index * PINS_DISTANCE;
        //     const ioCircleData = {
        //         nodeId,
        //         dataType,
        //         id: id || uuid(),
        //         assetRef,
        //         name: data.name,
        //         assetType,
        //         path: data.path,
        //     };

        //     const result = new FabricOLWN({
        //         lineText: dataType,
        //         text: data.name,
        //         triggered: data.isPublished,
        //     });

        //     const { ioLine, lineText } = result;
        //     let ioCircle = result.ioCircle as FunctionOutputCircle;
        //     ioCircle.data = { asset, circleData: data, tooltip: data.path };
        //     const ioPublish = result.ioPublish as FunctionOutputPublish;
        //     ioPublish.data = { asset, circleData: data };

        //     Object.keys(result).forEach((key) => {
        //         result[key].top = y;
        //     });

        //     this.ioPublish.push(ioPublish);
        //     this.ioLines.push(ioLine);
        //     this.ioCircles.push(ioCircle);
        //     lineTexts.push(lineText);
        // });

        outputs.forEach((data: any, index: number) => {
            const { id, dataType, value } = data;
            let y: number;
            y = index * PINS_DISTANCE;
            const ioCircleData = {
                nodeId,
                dataType,
                id: id || uuid(),
                assetRef,
                name: data.name,
                assetType,
                path: data.path,
            };

            const result = new FabricONWN({
                lineText: dataType,
                title: data.name,
                isAlarmPin: false
            });

            const { ioLine, pinTitle } = result;
            let ioCircle = result.ioCircle as FunctionOutputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };
            let pinTitleData = result.pinTitle;
            pinTitle.data = { tooltip: data.path };

            Object.keys(result).forEach((key) => {
                if (result[key]) {
                    result[key].top = y;
                }
            });

            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(pinTitle);
        });

        this.completeGroup = new fabric.Group([
            ...this.ioLines,
            ...this.ioPublish,
            ...this.ioCircles,
            ...lineTexts,
        ]);

        // output pins group
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set('originX', 'right');
        this.completeGroup.set('originY', 'center');
        this.completeGroup.set('left', x + nodeWidth / 2 + fullFunctionPinLength * scaleX);
        this.completeGroup.set('top', y + nodeHeight / 2 + parentHeight / 2);
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);
    }
}

export class AlarmEventInput extends IO {
    ioLines: fabric.Group[] = [];
    ioCircles: FunctionInputCircle[] = [];
    type: IOTypes;
    completeGroup: fabric.Group;
    ioTriggers: FunctionInputTrigger[] = [];
    historyIcons: FabricHistoryIcon[] = [];

    constructor(options: {
        asset: AlarmTypeDetail | EventTypeDetail;
        nodeHeight: number;
        nodeWidth: number;
        parent: FabricDropDownTitle;
    }) {
        super(options);
        this.type = IOTypes.INPUT;
        const { asset } = options;
        const { inputs, scale: scaleX, scale: scaleY } = asset;
        const { nodeHeight, nodeWidth } = options;
        const dataLength = inputs.length;
        const lineTexts: fabric.Text[] = [];
        const defaultValues: fabric.Text[] = [];

        inputs.forEach((data, index) => {
            const { id, dataType, connected } = data;
            let y: number;
            y = index * PINS_DISTANCE;
            const result = new FabricILWV({
                lineText: dataType,
                connected: connected,
                text: data.name,
                value: data.defaultValue ? data.defaultValue + '' : '',
            });

            let { ioLine, lineText, value } = result;
            let ioCircle = result.ioCircle as FunctionInputCircle;
            ioCircle.data = { asset, circleData: data, tooltip: data.path };
            console.log(ioCircle.data);
            Object.keys(result).forEach((key) => {
                if (result[key]) {
                    result[key].top = y;
                }
            });

            //this.ioTriggers.push(ioTrigger);
            this.ioLines.push(ioLine);
            this.ioCircles.push(ioCircle);
            lineTexts.push(lineText);
            defaultValues.push(value);
        });

        this.completeGroup = new fabric.Group([
            ...lineTexts,
            ...this.historyIcons,
            ...this.ioLines,
            ...this.ioTriggers,
            ...this.ioCircles,
            ...defaultValues,
        ]);

        const leftMargin = fullFunctionPinLength * scaleX;

        this.completeGroup.set('originX', 'left');
        this.completeGroup.set('originY', 'top');
        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        const parentHeight = options.parent.getScaledHeight();
        this.completeGroup.set('left', x - nodeWidth / 2 - leftMargin);
        this.completeGroup.set(
            'top',
            y - outputCircleRadius * 2 - 1 + nodeHeight / 2 + parentHeight / 2
        );
        this.completeGroup.set('scaleX', scaleX);
        this.completeGroup.set('scaleY', scaleY);
    }
}
