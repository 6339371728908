import React, { Fragment, useState, useEffect, useMemo } from "react";
import "../style.scss";
import * as _ from "lodash";
import { Table, Collapse, Card, CardBody } from "reactstrap";
import { columns } from "../type";
import {
    Icon,
    Button,
    Input,
    Checkbox,
    Dropdown,
} from "@abb/abb-common-ux-react";
import { convertedConditionObj, If } from "../helper";
import { HIDDEN_LOGIC_TEXT } from "../../../utils/constants/uiConstants";
import Flex from "../../../components/shared/Flex";
import CustomTooltip from "../../../components/CustomTooltip";
import { SidebarContainer } from "../../../containers/EditorLayout";
import SubconditionsView from "../FunctionConditionType/SectionComponents/SubconditionsView";

interface AppProps {
    inputs: any;
    isEncrypted: boolean;
}

function ConditionType(props: AppProps) {
    const [sortOrder, setSortOrder] = useState([] as any);
    const [activeTableRow, setActiveTableRow] = useState("name");
    const [conditionNameTestList, updateConditionNameTestList] = useState(
        [] as any
    );
    const [values1, updateData] = useState("" as any);
    const [collapse, setCollapse] = useState([] as any);
    const [selectedConditionIndex, setSelectedConditionIndex] = useState(0);
    const [selectedConditionName, setSelectedConditionName] = useState("");
    const [selectedSubConditionIndex, setSelectedSubConditionIndex] =
        useState(0);

    useEffect(() => {
        Array.from(Object.keys(props.inputs)).map(
            (name: string, index: number) => {
                sortOrder.push("asc");
            }
        );
        setSortOrder(sortOrder);
    }, []);

    useEffect(() => {
        let inputOrder: any = convertedConditionObj(props.inputs);
        updateConditionNameTestList(inputOrder);
    }, [props.inputs]);

    useEffect(() => {
        if (conditionNameTestList.length > 0 && selectedConditionName === "") {
            setSelectedConditionName(getConditionName(0));
        }
    }, [conditionNameTestList]);

    console.log(conditionNameTestList);

    const getConditionName = (index: number) => {
        return Object.keys(conditionNameTestList[index] || {}).filter(
            (key) => key !== "error" && key !== "deleteFlag"
        )[0];
    };

    const handleConditionSelected = (index: number) => {
        setSelectedConditionIndex(index);
        setSelectedConditionName(getConditionName(index));
        setSelectedSubConditionIndex(0);
    };

    const selectedSubCondition = useMemo(() => {
        return (
            conditionNameTestList[selectedConditionIndex] &&
            conditionNameTestList[selectedConditionIndex][
                selectedConditionName
            ] &&
            conditionNameTestList[selectedConditionIndex][
                selectedConditionName
            ][selectedSubConditionIndex]
        );
    }, [
        selectedConditionIndex,
        selectedConditionName,
        selectedSubConditionIndex,
        conditionNameTestList,
    ]);

    return (
        <div className="CustomFunctionView conditionFunctionOverview">
            <Flex column fill className="wrapper-condition-tab-data">
                <Flex fill row className="conditions-data">
                    <SidebarContainer
                        useDraggable={true}
                        dragId={"conditionDrag"}
                    >
                        <Flex fill className="condition-left-nav" column>
                            <div className="condition-list-title">
                                Conditions
                            </div>
                            <Flex column className="list-wrapper">
                                <div
                                    style={{
                                        overflow: "auto",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    {conditionNameTestList.map(
                                        (item: any, index: number) => {
                                            // TODO: Update with the original data here.
                                            return (
                                                <CustomTooltip
                                                    key={index}
                                                    position="top"
                                                    text={getConditionName(
                                                        index
                                                    )}
                                                >
                                                    <div
                                                        className={`condition-menu-element ${
                                                            selectedConditionIndex ===
                                                            index
                                                                ? "selected"
                                                                : ""
                                                        } no-checkbox`}
                                                        key={`condition.${index}`}
                                                        onClick={(e) =>
                                                            handleConditionSelected(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <span className="text">
                                                            {getConditionName(
                                                                index
                                                            )}
                                                        </span>
                                                    </div>
                                                </CustomTooltip>
                                            );
                                        }
                                    )}
                                </div>
                            </Flex>
                        </Flex>
                    </SidebarContainer>
                    <SidebarContainer
                        useDraggable={true}
                        dragId={"subConditionDrag"}
                    >
                        <Flex fill className="sub-condition-left-nav" column>
                            <div className="condition-list-title">
                                Sub Conditions
                            </div>
                            <Flex column className="list-wrapper">
                                <div style={{ overflow: "auto" }}>
                                    {
                                        // TODO: Update with the original data here.
                                        selectedConditionName &&
                                            conditionNameTestList[
                                                selectedConditionIndex
                                            ] &&
                                            conditionNameTestList[
                                                selectedConditionIndex
                                            ][selectedConditionName].map(
                                                (item: any, index: number) => (
                                                    <CustomTooltip
                                                        key={index}
                                                        position="top"
                                                        text={item.name}
                                                    >
                                                        <div
                                                            className={`condition-menu-element ${
                                                                selectedSubConditionIndex ===
                                                                index
                                                                    ? "selected"
                                                                    : ""
                                                            } no-checkbox`}
                                                            key={`subCondition_${item.name}`}
                                                            onClick={(e) =>
                                                                setSelectedSubConditionIndex(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <span className="text">
                                                                {item.name}
                                                            </span>
                                                        </div>
                                                    </CustomTooltip>
                                                )
                                            )
                                    }
                                </div>
                            </Flex>
                        </Flex>
                    </SidebarContainer>
                    <Flex fill className="condition-right-part view" column>
                        <SubconditionsView
                            selectedSubCondition={selectedSubCondition}
                            selectedConditionIndex={selectedConditionIndex}
                            selectedConditionName={selectedConditionName}
                            selectedSubConditionIndex={
                                selectedSubConditionIndex
                            }
                            setSelectedSubConditionIndex={
                                setSelectedSubConditionIndex
                            }
                            getConditionName={getConditionName}
                            conditionFunctionType={conditionNameTestList}
                            functionMode={"VIEW"}
                            isEncrypted={props.isEncrypted}
                        />
                        {/* <div className="title">
                            {selectedSubCondition && selectedSubCondition.name}
                        </div>
                        <div style={{ overflow: "auto" }}>
                            <Flex fill column justifyContentBetween>
                                <CustomABBInput
                                    className="condition-fields"
                                    dataType="text"
                                    value={
                                        selectedSubCondition &&
                                        selectedSubCondition.name
                                    }
                                    label="Subcondition Name"
                                    placeholder="Enter Condition Name"
                                    disabled={true}
                                />
                                <ABBInputTextMock
                                    label={"Subcondition Description"}
                                    content={
                                        (selectedSubCondition &&
                                            selectedSubCondition.description
                                                .value) ||
                                        "-"
                                    }
                                />
                                <CustomABBInput
                                    className="condition-fields"
                                    dataType="number"
                                    label="Severity"
                                    placeholder="Enter Severity"
                                    value={
                                        (selectedSubCondition &&
                                            selectedSubCondition.severity
                                                .value) ||
                                        0
                                    }
                                    disabled={true}
                                />
                            </Flex>
                            <Flex fill column justifyContentBetween>
                                <ABBInputTextMock
                                    label={"Possible Cause"}
                                    content={
                                        (selectedSubCondition &&
                                            selectedSubCondition.possibleCause
                                                .value) ||
                                        "-"
                                    }
                                />
                                <ABBInputTextMock
                                    label={"Suggested Action"}
                                    content={
                                        (selectedSubCondition &&
                                            selectedSubCondition.suggestedAction
                                                .value) ||
                                        "-"
                                    }
                                />
                                <ABBInputTextMock
                                    label={"Corrective Action"}
                                    content={
                                        (selectedSubCondition &&
                                            selectedSubCondition
                                                .correctiveAction.value) ||
                                        "-"
                                    }
                                />
                            </Flex>
                            <Flex fill column justifyContentBetween>
                                <div className="logic-text-container">
                                    <Flex fill row>
                                        <ABBInputTextMock
                                            label={"Logic"}
                                            content={
                                                props.isEncrypted
                                                    ? HIDDEN_LOGIC_TEXT
                                                    : selectedSubCondition &&
                                                      selectedSubCondition.logic
                                                          .value
                                            }
                                        />
                                    </Flex>
                                </div>
                            </Flex>
                            <Flex fill row justifyContentBetween>
                                <Dropdown
                                    className="condition-active"
                                    label="Status"
                                    value={[
                                        {
                                            value:
                                                (selectedSubCondition &&
                                                    selectedSubCondition.status
                                                        .value) ||
                                                ("-" as string),
                                            label:
                                                (selectedSubCondition &&
                                                    selectedSubCondition.status
                                                        .value) ||
                                                ("-" as string),
                                        },
                                    ]}
                                    placeholder={
                                        (selectedSubCondition &&
                                            selectedSubCondition.status
                                                .value) ||
                                        "-"
                                    }
                                    disabled={true}
                                />
                            </Flex>
                        </div> */}
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}

const ABBInputTextMock = (props: { label: string; content: string }) => {
    const { label, content } = props;
    return (
        <span className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__isTextarea ABB_CommonUX_Input__normal condition-fields ABB_CommonUX_Input__disabled ABB_CommonUX_Input__isValid">
            <div className="ABB_CommonUX_Input__labelContainer">
                <span className="ABB_CommonUX_Input__label">{label}</span>
            </div>
            <span className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__disabled">
                <span
                    style={{
                        padding: "3px",
                        whiteSpace: "pre-line",
                        lineBreak: "anywhere",
                    }}
                >
                    {content}
                </span>
            </span>
        </span>
    );
};

export default ConditionType;
