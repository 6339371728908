import { ActionTypes } from '../actionTypes';
import {} from './types';

export function showGrid(payload: boolean) {
    return <const>{
        type: ActionTypes.SHOW_GRID_DISPLAY,
        payload,
    };
}

export function hideGrid() {
    return <const>{
        type: ActionTypes.HIDE_GRID_DISPLAY,
    };
}
