import { dropDownOptions, FunctionComponents } from './type';

export const Inputs = 'Input';
export const Endpoint = 'Endpoint';
export const Output = 'Output';
export const Conditions = 'Conditions';
export const Condition = 'Condition';
export const Calculation = 'Calculation';
export const ConditionState = 'State';
export const Alarm = 'Alarm';

export const GLOBAL_TENANT_ID = '67e82acb-8071-4b9b-993f-ab71fb6eee2d';
export const TENANT_ID_KEY = 'TenantId';
export const LOCAL_TYPE_ID_PREFIX = 'abb.local';

export const ModelId = 'abb.ability.conditioningMonitor.function';
export const dataProviderUrl = 'https://sceengineeringapi-iabu-release.azurewebsites.net';
export const abilityURL = 'https://abitydefregasob01eundev.azurewebsites.net';
export const DeploymentType = 'AM';

export const DropDownOptions = [
    { label: dropDownOptions.integer, value: dropDownOptions.integer },
    { label: dropDownOptions.string, value: dropDownOptions.string },
    { label: dropDownOptions.number, value: dropDownOptions.number },
    { label: dropDownOptions.boolean, value: dropDownOptions.boolean },
    { label: dropDownOptions.stringArr, value: dropDownOptions.stringArr },
    { label: dropDownOptions.numberArr, value: dropDownOptions.numberArr },
    { label: dropDownOptions.integerArr, value: dropDownOptions.integerArr },
    { label: dropDownOptions.booleanArr, value: dropDownOptions.booleanArr },
];
export const availableTabsOption = [
    FunctionComponents.INPUT,
    FunctionComponents.OUTPUT,
    FunctionComponents.CONDITION,
    FunctionComponents.CALCULATION,
    FunctionComponents.ALARM,
];
export const DRAG_DROP_FUNCTIONS_FROM_LIBRARIES = {
    DRAG_FUNCTIONS: 'DRAG_FUNCTIONS',
    DROP_FUNCTIONS: 'DROP_FUNCTIONS',
};

export const EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER = 'Click + To Add Function Components';

export const FUNCTION_MODAL = <const>{
    CHANGE_PASSWORD_TITLE: 'Change Password',
    CHANGE_PASSWORD_BTN: 'Change',
    UNLOCK_TITLE: 'Unlock Library',
    UNLOCK_LIB_BTN: 'Unlock',
    LOCK_LIBRARY_TITLE: 'Lock Library',
    LOCK_LIB_BTN: 'Lock',
    EXPORT_LIBRARY_TITLE: 'Export Library',
    LIBRARY_PASSWORD_PLACEHOLDER_TEXT: 'Enter Library Password',
    EXPORT_FUNCTION: 'Export Function',
};
export const SEVERITY_PIN = 'severity';
