import React, { Fragment, useMemo } from "react";
import { FunctionComponents } from "../../type";

interface ITabOptionsMenu {
    tabNavList: FunctionComponents[];
    availableTabs: FunctionComponents[];
    onAddNewTab: (newTab: FunctionComponents) => void;
    close: () => void;
}
export function TabOptionsMenu(props: ITabOptionsMenu) {
    const tabOptions = useMemo(() => {
        const tabNavList: string[] = props.tabNavList;
        const availableTabData = props.availableTabs.filter(
            (item) => !tabNavList.includes(item)
        );
        return availableTabData;
    }, []);
    return (
        <Fragment>
            {tabOptions.map((item: FunctionComponents) => (
                <div className="model-menu-item" key={item}>
                    <div
                        className="menu-item-wrapper"
                        onClick={() => {
                            props.onAddNewTab(item);
                            props.close();
                        }}
                    >
                        <div className="menu-item-name">{item}</div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
}
