import AppSettings from '../../services/AppSettings';
import { IFilterQuery } from '../../store/assets/types';
import { Privileges } from '../../components/Fabric/types';

// RoutePath Constants

export const ROUTE_PATHNAME = <const>{
    HOME: '/models',
    OBJECTS: '/assets',
    FUNCTIONS: '/functions',
    SETTINGS: '/settings',
    IMPORT: '/import',
    EXPORT: '/export',
};
// ! ASSETS PAGE

export enum INSTANCE_CONFIG_MODELS_TAB {
    ALL = 'All',
    MATCHING = 'Matching',
}

export const ASSET_CATEGORY_TYPES = {
    SYSTEM: {
        value: 'Systems',
        name: 'Systems',
    },
    EDGE: {
        value: 'Edge',
        name: 'Edge',
    },
};

// ! MODELS PAGE

export const MODEL_TYPES = {
    OBJECTS: {
        value: 'objects',
        label: 'Object Types',
        camelCaseLabel: 'Objects',
    },
    FUNCTIONS: {
        value: 'functions',
        label: 'Functions',
        camelCaseLabel: 'Functions',
    },
    TYPES: {
        value: 'types',
        label: 'Models',
        camelCaseLabel: 'Models',
    },
};

// ! FUNCTIONS PAGE

// ! CONFIGURATION TOOL
export const NOTE_MESSAGE = 'Note:';
export const ADMIN_HELP_MESSAGE =
    ' Please contact your System Administrator, in case you forgot password';
export const INSTANCE_NAV_BAR = {
    AVAILABLE_INSTANCES: 'Available',
    SELECTED_INSTANCES: 'Selected',
};

export const CANVAS_DIMENSION = {
    WIDTH: window.screen.width - 235 - 20, //- window.screen.width * .13,
    HEIGHT: window.innerHeight - 188, // window.screen.height * .46
};

export const VIEW_MODE = {
    TABLE: {
        value: 'tableView',
        label: 'Table View',
    },
    DIAGRAM: {
        value: 'diagramView',
        label: 'Diagram View',
    },
};

export const DRAGGABLE_ITEMS = {
    TABLE_VIEW: {
        asset: {
            src: 'TABLE_ASSET_DRAG_SOURCE',
            target: 'TABLE_ASSET_DRAG_TARGET',
        },
        ioVariables: {
            src: 'TABLE_IO_VARIABLE_DRAG_SOURCE',
            target: 'TABLE_IO_VARIABLE_DRAG_SOURCE',
        },
    },
    DIAGRAM_VIEW: {
        canvas: {
            src: 'CANVAS_ASSET_DRAG_SOURCE',
            target: 'CANVAS_ASSET_DRAG_SOURCE',
        },
    },
};

export const ROOT_LEVEL_ID = 1;
export const DEFAULT_TREE_LIST_NODE = {
    name: 'ROOT',
    level: ROOT_LEVEL_ID,
    children: [],
    ancestorLevel: '',
    loading: false,
};

export const DEFAULT_TREE_VIRTUAL_LIST_NODE = {
    id: ROOT_LEVEL_ID,
    name: 'ROOT',
    state: {},
    children: [],
};

export const GROUP_BY_LIST: string[] = ['Structure', 'Connect'];

export const DEFAULT_ASSET_STRUCTURE_DETAILS = {
    structureList: [],
    systemList: [],
    groupByList: GROUP_BY_LIST,
    selectedStructure: undefined,
    selectedSystem: undefined,
    selectedGroupBy: '',
    searchText: '',
};

export const DEFAULT_FILTER_QUERY: IFilterQuery = {
    applyFilter: false,
    filteredItems: [],
    filterOptions: [],
    assetModelTypes: [],
    assetTypes: [],
    isFilterLoading: false,
};

export const CONFIGURATION_TOOL_PRIVILEGES: {
    MODELS_PAGE: Privileges;
    ASSETS_PAGE: Privileges;
} = {
    MODELS_PAGE: {
        FUNCTION_DELETE_ALLOWED: true,
        OBJECT_DELETE_ALLOWED: true,
        FUNCTION_PUBLISH_ALLOWED: true,
        FUNCTION_TRIGGER_ALLOWED: true,
        FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED: true,
        FUNCTION_CONDITIONS_LOGIC_CHANGE_ALLOWED: true,
        FUNCTION_NAME_CHANGE_ALLOWED: true,
    },
    ASSETS_PAGE: {
        FUNCTION_DELETE_ALLOWED: false,
        OBJECT_DELETE_ALLOWED: true,
        FUNCTION_PUBLISH_ALLOWED: true,
        FUNCTION_TRIGGER_ALLOWED: true,
        FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED: false,
        FUNCTION_CONDITIONS_LOGIC_CHANGE_ALLOWED: false,
        FUNCTION_NAME_CHANGE_ALLOWED: false,
    },
};

export const OBJECT_SCALE_LIMIT = {
    MAX_VALUE: 1.5,
    MIN_VALUE: 1,
};

export const FUNCTION_TIME_TRIGGER_RANGE = {
    MIN_VALUE: 0,
    MAX_VALUE: 28800,
};

export const DELETE_MODEL_TYPE = {
    MODEL: 'MODEL',
    // OBJECT_FUNC: "OBJECT_FUNC",
    OBJECT: 'OBJECT',
    FUNCTION: 'FUNCTION',
    CONNECTION_LINE: 'CONNECTION_LINE',
};

export const CONFIRMATION_MSG = {
    UPDATE_FUNC_TITLE: 'Update',
    UPDATE_FUNCTION: 'UPDATE_FUNCTION',
    UPDATE_ALL_FUNCTION: 'UPDATE_ALL_FUNCTION',
    UPDATE_FUNCTION_WARNING: `A function update will overwrite conditions from the latest version of the function.
    Please review conditions before saving the model.`,
    INPUT_TRIGGER: 'INPUT_TRIGGER',
    INPUT_TRIGGER_TITLE: 'Trigger',
    DELETE_FUNCTION_ALARM_EVENT_INPUT_PIN: 'DELETE_FUNCTION_ALARM_EVENT_INPUT_PIN',
    DELETE_FUNCTION_ALARM_EVENT_INPUT_PIN_TITLE: 'Delete',
};
export const deleteFunctionAlarmEventPinWarningMessage = (options: {
    pinName: string;
    type: 'Alarm' | 'Event';
}) => {
    if (options.type === 'Alarm') {
        return `Are you sure you want to delete the alarm trigger ${options.pinName}`;
    } else {
        return `Are you sure you want to delete the event trigger ${options.pinName}`;
    }
};

export const CONFIGURATION_TOOL_MODE = <const>{
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
    IMPORT: 'IMPORT',
};

export type CONFIGURATION_TOOL_MODE_CHILDREN =
    | typeof CONFIGURATION_TOOL_MODE.VIEW
    | typeof CONFIGURATION_TOOL_MODE.EDIT
    | typeof CONFIGURATION_TOOL_MODE.CREATE
    | typeof CONFIGURATION_TOOL_MODE.IMPORT;

// INSTANCE CONFIG

export const CONFIGURATION_STATUS = <const>{
    YET_TO_BE_CONFIGURED: 'Not configured',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
};

// ! OTHERS
export enum SAVE_OPTIONS {
    PUBLISH = 'Published',
    DRAFT = 'Draft',
}

export const LOADER_TYPE = <const>{
    RADIAL: 'radial',
    BAR: 'bar',
};

export const LOADER_SIZE = <const>{
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

export const NOTIFICATION_MODAL_STATUS = {
    ERROR: 'error',

    WARNING: 'warning',

    SUCCESS: 'success',
};

export const SUPPORTED_MODEL = <const>{
    MODEL_ID: 'abb.ability.model.supportedModels',
    TYPE_ID: 'abb.ability.scemodule.supportedModelsType',
    VERSION: '1.0.0',
};

export const FUNCTION_MODE = <const>{
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
};

export const TABLE_IO_TYPE = <const>{
    INPUT: 'INPUT',
    OUTPUT: 'OUTPUT',
    CONDITION: 'CONDITION',
};
export const FUNCTION_SAVE_TYPE = <const>{
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    SAVE_AS: 'SAVE_AS',
};

export const BUTTON_TITLE = <const>{ SAVE: 'Save', SET: 'Set' };

export const MODEL = <const>{
    DEFAULT_MODEL_ID: 'abb.ability.conditionMonitor',
};

export const TABLE_ROW_CONSTANTS = <const>{
    INPUTOUTPUT: 'INPUTOUTPUT',
    CALCULATIONOUTPUT: 'CALCULATIONOUTPUT',
};

/**Export Models*/
export const EXPORT_MODELS_MODES = <const>{
    MODEL: 'MODEL',
    OBJECT: 'OBJECT',
    MULTPLE_OBJECTS: 'MULTIPLE_OBJECTS',
};

export const IMPORT = 'IMPORT';
export const EXPORT = 'EXPORT';

export const GROUP_BY_SYSTEM = 'systems';
export const GROUP_BY_STRUCTURE = 'systemStructures';
export const GROUPING_IDENTIFIER = 'systemId';
export const NAME_LOOKUP_MODEL = 'abb.controlSystem.800xA.aspectObject';
export const MIN_RECORDS = 1;
export const MAX_RECORDS = 50000;
export const IS_LOGIN = 'isLoggedIn';
export const DEFAULT_CONTROL_STRUCTURE = 'abb.controlSystem.800xA.structure.control';

export const ASSET_CRITICALITY = <const>{
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
    DELETE: 'REMOVE',
    CONFIGURE: 'CONFIGURE',
};

export const ASSET_CONFIG_MODELS = <const>{
    CONDITION_MONITOR_MODELS: 'abb.ability.conditionmonitoring',
    ASSET_CRITICALITY_MODELS: 'abb.ability.am.assetCriticality',
};

export const INSTANCE_SAVE_MODE = <const>{
    SAVE: 'SAVE',
    DRAFT: 'DRAFT',
    VIEW_AND_DEPLOY: 'VIEW_AND_DEPLOY',
    UPDATE: 'UPDATE',
    VIEW_AND_UPDATE: 'VIEW_AND_UPDATE',
};

export const OVERALL_SEVERITY_FUNCTION = <const>{
    TYPE_ID: 'abb.ability.function.overallSeverity',
    VERSION: '1.0.0',
    NAME: 'OverallSeverity',
    ID: 'OverallSeverity_0',
};
export const GLOBAL_LIST = <const>{
    SUPPORTED_MODEL: 'Connect Models',
    IDENTITY: 'Identity',
    SUPPORTED_CONDITION_MONITORS: 'Supported ConditionMonitor Models',
};

export const STRUCTURE_IDENTITY = <const>{
    MODEL: 'abb.ability.structure.identity',
    TYPE: 'abb.ability.Structure.identity.type',
    VERSION: '1.0.0',
};

export const MODEL_SAVE_TYPE = <const>{
    SAVE_AS: 'Save As',
};

export enum SamplingAggregateValues {
    Avg = 'Avg',
    Sum = 'Sum',
    Min = 'Min',
    Max = 'Max',
    Count = 'Count',
    Raw = 'Raw',
    Empty = '',
}

export const LOGIN = <const>{
    INVALID_TENANT: 'Tenant Name does not exist',
};

export const LOADER_TEXT = <const>{
    UPDATE_RELATED_INSTANCES: 'Updating Related Instances...',
};

export const AuthScheme = {
    ability: 'AbilityB2C',
    aad: 'AbilityAAD',
    oidc: 'OIDC',
};
export const ROUTE_MODE = <const>{
    TYPES: 'models',
    FUNCTIONS: 'functions',
};

export const FUNC_BTNS = <const>{
    ALL_LIBRARIES: 'All Libraries',
};
export const MODAL_TITLE = <const>{
    PUBLISH_OUTPUT_CONFIGURATION_TITLE: 'SELECT ATTRIBUTES TO BE PUBLISHED',
    OVERLAP_CANVAS_BLOCKS_TITLE: 'Warning',
};

export const FUNCTION_SEVERITY_PIN = 'severity';
export const TYPE_VALIDATION_ERROR_MSG = 'Model validation Error';
export const TYPE_VALIDATION_WARNING_MSG = 'Model validation Warning';

export const DELETE_MODAL_TABS = <const>{
    OBJECTS: 'Objects',
    FUNCTIONS: 'Functions',
    TYPES: 'Models',
};

export type DeleteModalTabsType =
    | typeof DELETE_MODAL_TABS['FUNCTIONS']
    | typeof DELETE_MODAL_TABS['OBJECTS']
    | typeof DELETE_MODAL_TABS['TYPES'];

export const DEL_SELECTED_ITEM = 'Do you want to delete selected items?';
export const DEL_SELECTED_ITEM_WITH_INVALID_INPUT = (inValidInputs: string[]) =>
    `The inputs ${inValidInputs} are already present in logic for some conditions or calculations. Do you still want to delete selected items?`;

export const DEL_FUNCTION_MSG =
    'This function might be present in instances or models. Are you sure, you want to delete it?';

export const TYPES_TOTAL_IMPORT_STEPS = 2;
export const IMPORT_MODAL_BTN = <const>{
    CANCEL: 'Cancel',
    BACK: 'Back',
    NEXT: 'Next',
    IMPORT: 'Import',
};
export const CONDITION_MODAL = <const>{
    CANCEL_BTN: 'Cancel',
    ADD_BTN: 'Add',
    TITLE: 'Add Condition',
};

export const GENERIC_FUNCTIONS = 'Generic Functions';
export const LIBRARIES = 'Libraries';
export const OVERLAP_MODAL = {
    ALLOW_OVERLAP_MSG: 'Canvas reached maximum dimension limit further items may overlap.',
    HIDE_OVERLAP_MSG_LABEL: 'Do not show this message again',
};
export const OPCUA_MODEL = 'abb.ua.device';
export const LIBRARY = <const>{
    CREATE_SUCCESS_MSG: 'Created Library Successfully',
    DELETE_SUCCESS_MSG: 'Deleted Library Successfully',
    UNLOCK_SUCCESS_MSG: 'Unlocked Library Successfully',
    RENAME_SUCCESS_MSG: 'Renamed Library Successfully',
};

export const TYPE_MSG = <const>{
    TYPEID_CONFLICT_TITLE: 'Type Id Conflict',
    TYPEID_CONFLICT_DESC: 'Change the Type Id for imported model to proceed',
    WRONG_TYPE_IMPORT: 'Import Under Wrong Model',
    TYPEID_ALREADY_EXIST: 'Type ID Already Exists',
};

export const PASSWORD_MSG = <const>{
    MIN_REQUIREMENT: `The password must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character,must not contain space and must be ${AppSettings.IpPasswordLength.min} to ${AppSettings.IpPasswordLength.max} characters in length`,
    MISMATCH: 'Password Mismatch',
    ENTER_NEW_PASSWORD: 'Please Enter New Password',
};

export const FUNCTION_MSG = <const>{
    NO_SEVERITY_PIN_OUTPUT: 'severity pin cannot be added as normal output',
    NO_SEVERITY_PIN_INPUT: 'severity pin cannot be added as normal input',
};

export const SUPPORTED_CONDITION_MONITORS_INFO =
    'Upon selecting the Tenant Specific option the model will be marked specific to the tenant and it will not be visible on other tenants. Public models are visible on all the tenants.';
export const SUPPORTED_CONDITION_MONITORS_TAG = <const>{
    TENANT_SPECIFIC_TAG: 'Tenant Specific',
    PUBLIC_MODEL: 'Public',
};
export const IMPORT_FILE = <const>{
    INVALID_IMPORT_MSG: 'Trying to import some corrupted file or file with wrong format',
};

export const DEFAULT_CONECT_MODELS = ["abb.generic.assetCriticalityObject","abb.ability.device","abb.ua.device","abb.controlSystem.800xA.aspectObject"];