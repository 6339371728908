import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

interface Props {
    row?: boolean;
    wrap?: boolean;
    inline?: boolean;
    center?: boolean;
    column?: boolean;
    fill?: boolean;
    children?: React.ReactNode;
    className?: string;
    justifyContentBetween?: boolean;
    id?: string;
}

const Flex = (props: Props) => {
    return (
        <div
            id={props.id && props.id}
            className={classnames(
                {
                    "d-flex": !props.inline,
                    "d-inline-flex": props.inline,

                    "justify-content-center": props.center,
                    "align-items-center": props.center,
                    "justify-content-between": props.justifyContentBetween,

                    "flex-wrap": props.wrap,
                    "flex-nowrap": !props.wrap,

                    "flex-row": props.row,
                    "flex-column": props.column,

                    "flex-even": props.fill
                },
                props.className
            )}
        >
            {props.children}
        </div>
    );
};

Flex.propTypes = {
    /** Use for set `flex-wrap` as `'wrap'` */
    wrap: PropTypes.bool,
    /** Use for set css`display` as `'inline'` */
    inline: PropTypes.bool,
    /** Use for set `justify-content` and `align-items` as `'center'` */
    center: PropTypes.bool,
    /** Use for set `flex-direction` as `'column'` */
    column: PropTypes.bool,
    fill: PropTypes.bool,
    className: PropTypes.string
};

Flex.defaultProps = {
    row: true,
    wrap: false,
    inline: false,
    center: false,
    column: false,
    fill: false,
    className: ""
};

export default Flex;
