import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CollapsibleContainer, Collapsible, Checkbox, Icon } from '@abb/abb-common-ux-react';

import './style.scss';
import { floatingMenuProps } from './types';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import { StoreState } from '../../store';

function FloatingMenu(props: {
    floatingMenuProps: floatingMenuProps[];
    isChild: boolean;
    keyId: string;
    data: any;
}) {
    const { floatingMenuProps, isChild, keyId, data } = props;

    const floatingMenuElement = (
        <>
            <div className={`${isChild ? 'icons-right' : 'collapsible_2'}`}>
                <Menu mode="horizontal" className="custom-rc-menu">
                    <SubMenu
                        popupOffset={[-10, 0]}
                        title={<Icon name="abb/more" />}
                        className={false ? 'item-active' : 'item-unactive'}
                    >
                        {floatingMenuProps &&
                            floatingMenuProps.map((menuItemProps, index) => {
                                return (
                                    <MenuItem
                                        key={`${keyId}_floating_menu_${index}`}
                                        className="model-menu-item"
                                    >
                                        <div
                                            className="menu-item-wrapper"
                                            onClick={() => menuItemProps.onMenuClick(data)}
                                        >
                                            {menuItemProps.icon && (
                                                <Icon name={menuItemProps.icon} />
                                            )}
                                            <div className="menu-item-name">
                                                {menuItemProps.label}
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                    </SubMenu>
                </Menu>
            </div>
        </>
    );

    return floatingMenuElement;
}

export default FloatingMenu;
