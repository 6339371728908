import { ActionTypes } from '../actionTypes';
import { NotificationModalState, NotificationModalAction } from './types';

const initialState: NotificationModalState = {
    show: false,
    title: 'Error in mapping data values',
    details: [],
    resultStatus: '', //for different types of result eg. ERROR , WARNING ,SUCCESS.
    type: 'banner'
};

const modalReducer = (
    state: NotificationModalState = initialState,
    action: NotificationModalAction
): NotificationModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_NOTIFICATION_MODAL:
            return {
                type: 'banner',
                show: true,
                details: [],
                ...action.payload
            };

        case ActionTypes.HIDE_NOTIFICATIOM_MODAL:
            return { ...initialState, show: false };
        default:
            return state;
    }
};

export default modalReducer;
