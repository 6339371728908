import React, { useState, useEffect } from "react";
import Interweave from "interweave";

import "./css/AboutThisBook.css";
import "./css/BookTOC.css";
import "./css/FrontCover - New.css";
import "./css/Section_1.css";
import "./css/Section_5.css";
import "./css/Section_Modes.css";
import "./css/webworks.css";

export default class RenderHTML extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            conetnet: ""
        };
    }
    componentDidMount() {
        if (this.props.URL) {
            fetch(this.props.URL, {
                method: "GET"
            }).then(res => {
                res.text().then(val => {
                    val = this.formatingHTML(val);
                    this.setState({ conetnet: val });
                });
            });
        }
    }
    componentWillReceiveProps(newProps: any) {
        if (newProps.URL !== this.props.URL) {
            fetch(newProps.URL, {
                method: "GET"
            }).then(res => {
                res.text().then(val => {
                    val = this.formatingHTML(val);
                    this.setState({ conetnet: val });
                });
            });
        }
    }
    formatingHTML(HTMLString: string) {
        try {
            if (HTMLString.indexOf("<body") === -1) {
                return HTMLString;
            }
            HTMLString = HTMLString.slice(
                HTMLString.indexOf("<body"),
                HTMLString.length
            );
            HTMLString = HTMLString.slice(0, HTMLString.indexOf("</body>"));
            let dom = document.createElement("div");
            dom.innerHTML = HTMLString;
            let scripts = dom.getElementsByTagName("script");
            let scriptLength = scripts.length;
            while (scriptLength--) {
                let script = scripts[scriptLength].parentNode;
                if (script) {
                    script.removeChild(scripts[scriptLength]);
                }
            }
            let images = dom.getElementsByTagName("img");
            for (let index = 0; index < images.length; index++) {
                images[index].src = "help/" + images[index].getAttribute("src");
            }
            return dom.innerHTML;
        } catch (err) {
            return HTMLString;
        }
    }
    render() {
        return <Interweave content={this.state.conetnet}></Interweave>;
    }
}
