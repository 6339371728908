export interface FunctionTypeList {
    assetDescription: string;
    assetName: string;
    assetRef: string;
    assetTags: string[];
    assetType: string;
    isActive?: boolean;
    assetVersion: string;
}

export enum functionType {
    Input = "Input",
    Condition = "Condition",
    Output = "Output",
    Endpoint = "Endpoint",
    Calculation = "Calculation",
    Alarm = "Alarm",
}

export enum columns {
    dataType = "dataType",
    isMandatory = "isMandatory",
    hasValue = "hasValue",
    conditions = "conditions",
    calculations = "calculations",
    description = "description",
    value = "value",
    severity = "severity",
    possibleCause = "possibleCause",
    suggestedAction = "suggestedAction",
    correctiveAction = "correctiveAction",
    logic = "logic",
    alarmMapping = "alarmMapping",
    status = "status",
}

export enum dropDownOptions {
    string = "string",
    number = "number",
    integer = "integer",
    boolean = "boolean",
    stringArr = "string (arr)",
    numberArr = "number (arr)",
    integerArr = "integer (arr)",
    booleanArr = "boolean (arr)",
}

export interface AssetsInfo {
    typeId: string;
    version: string;
    name: string;
    description: string;
    tags: string[];
    endpoint: string;
}

export type EndPoint = {
    value: string;
    dataType: string;
    description: string;
    isMandatory: boolean;
};

export type CalculationFields = "name" | "description" | "logic";

export const CALCULATION_FIELD = <const>{
    NAME: "name",
    DESCRIPTION: "description",
    LOGIC: "logic",
};

export interface DropDownItem {
    label: string;
    value: string;
}

export enum FunctionComponents {
    INPUT = "Input",
    OUTPUT = "Output",
    CALCULATION = "Calculation",
    CONDITION = "Condition",
    ALARM = "Alarm",
    ENDPOINT = "Endpoint",
}
