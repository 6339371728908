import { fabric } from 'fabric';
import { alramIconSize, noTriggerAlarmStrokeWidth, alarmThemeColor } from '../UiConfig';

const alarmIconPath =
    'M10.2525 9.00359C9.96598 8.74264 9.78685 8.38438 9.75 7.99859V5.62859C9.76218 4.7619 9.48584 3.91576 8.96447 3.22331C8.4431 2.53087 7.7063 2.03143 6.87 1.80359C6.87 1.57286 6.77834 1.35157 6.61518 1.18841C6.45203 1.02525 6.23074 0.933594 6 0.933594C5.76926 0.933594 5.54797 1.02525 5.38482 1.18841C5.22166 1.35157 5.13 1.57286 5.13 1.80359C4.2937 2.03143 3.5569 2.53087 3.03553 3.22331C2.51416 3.91576 2.23782 4.7619 2.25 5.62859V7.99859C2.21315 8.38438 2.03402 8.74264 1.7475 9.00359L0.75 10.0011V11.5011H4.5C4.5 11.8989 4.65804 12.2804 4.93934 12.5618C5.22064 12.8431 5.60218 13.0011 6 13.0011C6.39782 13.0011 6.77936 12.8431 7.06066 12.5618C7.34196 12.2804 7.5 11.8989 7.5 11.5011H11.25V10.0011L10.2525 9.00359Z';

const scale = alramIconSize / 18;
interface AlarmIconParams {
    srokeColor: string;
    strokeWidth: number;
    fill: string;
}
export class AlarmIcon extends fabric.Path {
    constructor(options: AlarmIconParams) {
        super(alarmIconPath, {
            stroke: options.srokeColor || alarmThemeColor,
            strokeWidth: options.strokeWidth || noTriggerAlarmStrokeWidth,
            fill: options.fill || alarmThemeColor,
            left: 0,
            originX: 'right',
            originY: 'center',
            scaleX: scale,
            scaleY: scale,
            top: 0,
            hoverCursor: 'pointer',
            selectable: false,
        });
    }
}

export class FabricOutputAlarmIcon extends AlarmIcon {
    constructor() {
        super({
            fill: alarmThemeColor,
            srokeColor: alarmThemeColor,
            strokeWidth: noTriggerAlarmStrokeWidth,
        });
    }
}
