import React, { ReactNode } from "react";
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react";
import "./style.scss";
import ReactTooltip from "react-tooltip";
interface AppProps {
    children?: ReactNode;
    className?: string;
    drop?: any;
    tdBorderTop?: string;
    contentLength?: number;
    tdTextData?: string;
    toolTipPosition?: "left" | "right" | "top" | "bottom";
}

function Td(props: AppProps) {
    return (
        <td
            className={`wrapper-table-data ${
                props.className && props.className
            }  ${
                props.tdBorderTop
                    ? "wrapper-table-data--" + props.tdBorderTop
                    : ""
            }`}
            ref={props.drop}
        >
            {props.contentLength &&
            props.children &&
            typeof props.children === "string" ? (
                props.contentLength < props.children.length ? (
                    <span
                        data-tip={props.children}
                        data-place={
                            props.toolTipPosition
                                ? props.toolTipPosition
                                : "right"
                        }
                        onMouseDown={() => {
                            ReactTooltip.hide();
                        }}
                    >
                        {props.children.substring(0, props.contentLength) +
                            "..."}
                    </span>
                ) : (
                    /*  <WithTooltip>
                              <div>
                                  {props.children.substring(0, props.contentLength) +
                                      "..."}
                              </div>
                              <Tooltip>{props.children}</Tooltip>
                          </WithTooltip>
                          */
                    props.children
                )
            ) : (
                props.children && props.children
            )}
            {
                <ReactTooltip
                    place={
                        props.toolTipPosition ? props.toolTipPosition : "right"
                    }
                    effect="solid"
                    type="light"
                    border={true}
                    className="function react-tooltip-custom"
                />
            }
        </td>
    );
}

export default Td;
