import { takeLatest, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { sce } from 'sce-engg-model-19.09';

import { ActionTypes } from '../../actionTypes';
import {
    getSelectedFunctionType,
    updateSelectedFunctionType,
    handleFunctionDelete,
    errorFunctionDelete,
    successFunctionDelete,
    getfunctionTypes,
    setFunctionTypes,
    errorGetFunctionTypes,
    handleFunctionSave,
    handlePopoverOpen,
    updateAssetInfo,
    errorLoadingData,
    handleFunctionMode,
    successFunctionSave,
    CreateFunctionAssetDetails,
    FunctionProperties,
    getAllPermisssionForFunctionAction,
    updateFunctionPermission,
    getAlarmTypes,
    setAlarmTypes,
    toggleSpinnerText,
    getDecryptedFunctionTypeRequest,
    getDecryptedFunctionTypeSuccess,
    getDecryptedFunctionTypeFailure,
    setGlobalLibraryError,
    getLibraryAndNodesRequest,
    changeFunctionVersionUpdate,
    setDeletingLibraryDetails,
    updateOriginalFunctionDetail,
    handleSaveAsMessage,
    handleUniqueTypeIdFunction,
    handleUniqueTypeIdFailed,
    handleUniqueTypeIdMessage,
    createNewFunction,
    clearDropTableMapping,
    updateFunctionTabNavList,
    updateEndPointList,
    handleLibPasswordError,
    getLibraryAndNodesSuccess
} from '../action';
import { showNotificationModal } from '../../notificationModal/action';
import { handleUniqueTypeIdLoading } from '../../../store/modelsPage/actions';
import {
    AssetsInfo,
    EndPoint,
    FunctionTypeList,
    FunctionComponents,
} from '../../../routes/Functions/type';
import {
    FUNCTION_MODE,
    GENERIC_FUNCTIONS,
    FUNCTION_SAVE_TYPE,
    NOTIFICATION_MODAL_STATUS,
} from '../../../utils/constants/appConstants';
import { ModelId } from '../../../routes/Functions/constants';
import {
    finalInputJSON,
    computefinalConditionJSON,
    customValuesObj,
    findActiveIndexForFunctionsLibrary,
    convertFuncDetailsToFuncType,
} from '../../../routes/Functions/helper';
import { StoreState } from '../..';
import librarySaga from './librarySaga';
import {
    abbSDKGetErrorMessage,
    objectsToSave,
    finalOutputConditionJSON,
    functionDetailRemodelForUpdateRelatedInstances,
    abbSDKGetLibraryError,
    getSelectedLibraryWithNodes,
    getUpdatedLibraryWithNodes,
} from '../../../utils/helpers';
import { FunctionAlarmType, LibraryAndNodePayload } from '../types';
import { hideModal } from '../../modal/action';
import { VERSION_UPDATE_TYPE } from '../../configurationTool/types';
import { pathFinder } from '../../../transformers/utils/outputFinder';
import { PartialFunctionType } from 'sce-engg-model-19.09/lib/types/Type';
import { showModal } from '../../../store/modal/action';
import EditFunctionInfo from '../../../routes/Functions/EditFunction/EditFunctionInfo';
import { Dispatch } from 'redux';

function* getFuncTypes(action: ReturnType<typeof getfunctionTypes>) {
    console.log('getFuncTypes called');
    try {
        const state: StoreState = yield select();
        const modelIds = action.payload;
        const hasPermission = yield sce.hasPermission(sce.getTypes);
        if (hasPermission) {
            const response = yield sce.getTypes(modelIds);
            if (response.status === 200 || response.status === 201) {
                console.log('response :', response);
                yield put(
                    setFunctionTypes({
                        functionsList: [...response.details],
                    })
                );
                if (response.details.length > 0 && !state.functions.typeDetails) {
                    let type: any = customValuesObj([...response.details])[0];
                    yield put(getSelectedFunctionType(type));
                }
            }
        }
    } catch (error) {
        yield put(errorGetFunctionTypes());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getFunctionAlarmType(action: ReturnType<typeof getAlarmTypes>) {
    try {
        const alarmType = yield sce.getAlarmType();

        const alarmTypeDetails: FunctionAlarmType[] = alarmType.details;
        yield put(setAlarmTypes(alarmTypeDetails));
    } catch (error) {
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* selectedFunctionType(action: ReturnType<typeof getSelectedFunctionType>) {
    try {
        const {
            selectedFunction: { assetType, assetRef, assetVersion },
        } = action.payload;
        const response = yield sce.getTypeDetails(assetType, assetRef, assetVersion);

        if (response.status === 200) {
            yield put(updateSelectedFunctionType({ typeDetails: response.details }));
            yield put(updateOriginalFunctionDetail(response.details));
        }
        
    } catch (err) {
        console.log(err.message);
    }
}

function* deleteSelectedFunction(action: ReturnType<typeof handleFunctionDelete>) {
    try {
        const state: StoreState = yield select();
        const { modelId, typeId, functionName, tags } = action.payload;
        const { libraryId, libraryVersion, password } = state.functions.deletingLibraryDetails;
        const functionWithNodesList = state.functions.libraryAndFunctionsList;
        const selectedfunction = state.functions.typeDetails;
        const modelsUsed = yield sce.getAssetMonitorTypesInfoForFunctionType(typeId, selectedfunction!.version);
        if(modelsUsed.details.length) {
            let updatedFunction = _.cloneDeepWith(selectedfunction);
            updatedFunction!.modelsUsed = modelsUsed.details;
            yield put(updateSelectedFunctionType({ typeDetails: updatedFunction! }));
            yield put(errorFunctionDelete());
            return;
        }
        let response;
        if (
            libraryId &&
            password &&
            libraryVersion &&
            libraryId.length > 0 &&
            password.length > 0 &&
            libraryVersion.length > 0
        ) {
            yield put(
                setDeletingLibraryDetails({ libraryId: '', password: '', libraryVersion: '' })
            );
            response = yield sce.deleteEncryptedFunctionType(
                libraryId,
                libraryVersion,
                password,
                modelId,
                typeId,
                tags
            );
        const libraryDetails = yield sce.getLibraryDetails(libraryId);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetails.details[0],functionWithNodesList)));
            yield put(
                setDeletingLibraryDetails({ libraryId: '', password: '', libraryVersion: '' })
            );
        } else {
            response = yield sce.deleteFunction(modelId, typeId, tags);
            let updatedFunctionsList = _.cloneDeepWith(state.functions.libraryAndFunctionsList);
            updatedFunctionsList[updatedFunctionsList.length - 1].nodes = updatedFunctionsList[updatedFunctionsList.length - 1].nodes.filter(fn => fn.typeId !== typeId);
                yield put(getLibraryAndNodesSuccess(updatedFunctionsList))
        }
        console.log('deleteSelectedFunction response :', [response, typeId]);
        const successMessage = `Function ${functionName} Deleted Successfully`;
        yield put(
            showNotificationModal({
                title: successMessage,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(hideModal());
        yield put(successFunctionDelete(typeId));
        const libraryAndFunctionsList: LibraryAndNodePayload[] =
            state.functions.libraryAndFunctionsList;
        const activeLibraryId =
            getSelectedLibraryWithNodes(libraryAndFunctionsList, typeId).id || null;

        // const library = libraryAndFunctionsList.filter((item) => item.id === activeLibraryId)[0];
        const libraryIndex = libraryAndFunctionsList.findIndex(
            (item) => item.id === activeLibraryId
        );
        const library = libraryAndFunctionsList[libraryIndex];
        const updatedNodes = library.nodes.filter((item) => item.typeId !== typeId);
        libraryAndFunctionsList[libraryIndex].nodes = updatedNodes;
        if (updatedNodes.length > 0) {
            yield put(
                getSelectedFunctionType({
                    activeLibId: activeLibraryId ? activeLibraryId : GENERIC_FUNCTIONS,
                    selectedFunction: convertFuncDetailsToFuncType(updatedNodes[0]),
                })
            );
        } else {
            const index = findActiveIndexForFunctionsLibrary(libraryAndFunctionsList);
            if (index >= 0) {
                yield put(
                    getSelectedFunctionType({
                        activeLibId: libraryAndFunctionsList[index].id,
                        selectedFunction: convertFuncDetailsToFuncType(
                            libraryAndFunctionsList[index].nodes[0]
                        ),
                    })
                );
            }
        }
        yield put(handleFunctionMode(FUNCTION_MODE.VIEW));
    } catch (error) {
        yield put(errorFunctionDelete());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(hideModal());
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(error)],
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* handleUniqueTypeIdSagaFunction(action: ReturnType<typeof handleUniqueTypeIdFunction>) {
    const { mode, details, saveType } = action.payload;
    const state: StoreState = yield select();
    const FunctionSaveType = state.functions.FunctionSaveType;
    let newFunctionDetails = {} as any;
    const functionComponents = [] as FunctionComponents[];
    let assetsInfo = {} as AssetsInfo;
    let modelId = ModelId;
    let typeId = '';
    let name = '';
    if (mode === FUNCTION_MODE.EDIT) {
        assetsInfo = details as AssetsInfo;
        typeId = assetsInfo.typeId;
        name = assetsInfo.name;
    } else {
        newFunctionDetails = details as any;
        typeId = newFunctionDetails.modelDetails.typeId;
        name = newFunctionDetails.modelDetails.name;
    }

    yield put(handleUniqueTypeIdLoading(true));
    
    try {
        //const result = yield sce.verifyTypeExists(modelId, typeId);
        const result = yield sce.verifyFunctionExists(typeId,name);
        
        if (result.details.isNameExist === true || result.details.isTypeIdExist === true) {
            yield put(handleUniqueTypeIdFailed(result.details.isNameExist ? 'name' : 'typeId'));
            yield put(handleUniqueTypeIdMessage('error'));
            yield put(handleUniqueTypeIdLoading(false));
        }
        if (result.details.isNameExist === false && result.details.isTypeIdExist === false) {
            yield put(handleUniqueTypeIdFailed(''));
            yield put(handleUniqueTypeIdMessage(''));
            yield put(handleUniqueTypeIdLoading(false));
            if (mode === FUNCTION_MODE.EDIT) {
                yield put(updateAssetInfo({ assetsInfo: { ...assetsInfo } }));
                yield put(handleFunctionSave(FunctionSaveType));
            }
            if (mode === FUNCTION_MODE.CREATE) {
                yield put(createNewFunction(newFunctionDetails));

                yield put(handleFunctionMode(FUNCTION_MODE.CREATE));
            }
            yield put(hideModal());
        }

        yield put(handleUniqueTypeIdMessage(''));
    } catch (error) {
        yield put(handleUniqueTypeIdFailed(''));
        yield put(handleUniqueTypeIdMessage(' '));
        yield put(handleUniqueTypeIdLoading(false));
        yield put(hideModal());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}
function* saveCurrentFunction(action: ReturnType<typeof handleFunctionSave>) {
    const saveType = action.payload;
    const state: StoreState = yield select();
    const isPopoverOpen = state.functions.isPopoverOpen;
    const { conditionDetails, inputDetails, outputDetails, calculationDetails } =
        state.functions.functionHeaderDetails;
    const alarmMapping = state.functions.alarmMapping;
    const assetsInfo = _.cloneDeepWith(state.functions.assetsInfo);
    const currentActiveView = state.functions.currentActiveView;
    yield put(handlePopoverOpen(!isPopoverOpen));
    objectsToSave(inputDetails, conditionDetails, calculationDetails, outputDetails);
    let inputFinalJSON = finalInputJSON(inputDetails);
    let outputFinalJSON = finalInputJSON(outputDetails);
    if (Object.keys(conditionDetails).length > 0) {
        inputFinalJSON['conditions'] = computefinalConditionJSON(conditionDetails);
    }
    if (Object.keys(calculationDetails).length > 0) {
        inputFinalJSON['calculations'] = computeFinalCalculationJSON(calculationDetails);
        Object.keys(calculationDetails).forEach((item: string) => {
            outputFinalJSON[item] = calculationDetails[item]['outputType'];
        });
    }

    if (alarmMapping && Object.keys(alarmMapping).length > 0) {
        inputFinalJSON['alarmMapping'] = alarmMapping;
    }
    if (alarmMapping && Object.keys(alarmMapping).length > 0) {
        const objectLinkPath = pathFinder(inputFinalJSON, 'attributes')[0].split('.').join('/');
        const objectLink = `#properties/inputs${objectLinkPath}/attributes`;
        outputFinalJSON['conditions'] = finalOutputConditionJSON(conditionDetails, objectLink);
    }

    if (Object.keys(conditionDetails).length === 0) {
        delete inputFinalJSON['alarmMapping'];
        delete outputFinalJSON['conditions'];
    }
    let endPoint: EndPoint = {
        value: assetsInfo && assetsInfo['endpoint'] ? assetsInfo['endpoint'] : '',
        dataType: 'string',
        description: '',
        isMandatory: true,
    };

    if (assetsInfo && assetsInfo.endpoint) {
        delete assetsInfo['endpoint'];
    }

    let isExtensible = false;
    if (saveType === FUNCTION_SAVE_TYPE.CREATE || saveType === FUNCTION_SAVE_TYPE.SAVE_AS) {
        if (currentActiveView.modelDetails && currentActiveView.modelDetails.isExtensible) {
            isExtensible = currentActiveView.modelDetails.isExtensible;
        }
    } else if (currentActiveView.assetsDetails && currentActiveView.assetsDetails.isExtensible) {
        isExtensible = currentActiveView.assetsDetails.isExtensible;
    } else {
        isExtensible = false;
    }
    const functionTags = assetsInfo ? assetsInfo.tags : [];
    const inputDataValue = JSON.parse(JSON.stringify(inputFinalJSON));
    Object.keys(inputDataValue).forEach((data, index) => {
        if (inputDataValue[data].dataType === 'array' && inputDataValue[data].items === 'boolean') {
            console.log(inputDataValue[data])
            if (inputDataValue[data].value && inputDataValue[data].value.length > 0) {
                inputDataValue[data].value.forEach((inputVal: any, index: number) => {
                    inputDataValue[data].value[index] = JSON.parse(inputVal);
                })
            }
        }
    });
    let val = {
        model: ModelId,
        ...assetsInfo,
        isExtensible: isExtensible,
        properties: {
            endpoint: endPoint,
            inputs: inputDataValue,
            outputs: finalInputJSON(outputDetails),
        },
        attributes: {
            link: {
                dataType: 'string',
            },
            objectLink: {
                dataType: 'string',
            },
        },
    };

    const functionMode = state.functions.functionMode;
    const originalFunctionDetails = state.functions.originalFunctionDetails;
    if (
        originalFunctionDetails &&
        functionMode === FUNCTION_MODE.EDIT &&
        assetsInfo!.typeId === originalFunctionDetails.typeId
    ) {
        const libraryDetails = (originalFunctionDetails.properties as any).library;
        if (libraryDetails) {
            val = {
                ...val,
                properties: { ...(val.properties as any), library: { ...libraryDetails } },
            };
        }
    }
    const functionWithNodesList = state.functions.libraryAndFunctionsList;

    try {
        console.log('val :', val);
        debugger;
        const libraryDetail = state.functions.libraryAndFunctionsList.find(
            (item) => item.id === state.functions.activeLibId
        );
        if (libraryDetail && libraryDetail.id !== null && functionMode !== FUNCTION_MODE.CREATE) {
            if (state.functions.functionMode === FUNCTION_MODE.EDIT) {
                // This will do update for functions which are part of a library.
                const response = yield sce.updateIPProtectedFunction(
                    libraryDetail.id,
                    libraryDetail.libraryVersion,
                    libraryDetail.isIPProtected
                        ? state.functions.decryptedLibraryDetails.password
                        : '',
                    val
                );
            } else {
                // This will do save as for functions which are part of a library.
                const originalFunctionProperties: any = originalFunctionDetails!.properties;
                const saveAsFunctionDetail = {
                    ...val,
                    properties: {
                        ...val.properties,
                        inputs: originalFunctionProperties.inputs,
                        outputs: originalFunctionProperties.outputs,
                    },
                };
                const response = yield sce.CreateAndAssociateFunctionToLib(
                    state.functions.decryptedLibraryDetails.id,
                    state.functions.decryptedLibraryDetails.libraryVersion,
                    libraryDetail.isIPProtected
                        ? state.functions.decryptedLibraryDetails.password
                        : '',
                    saveAsFunctionDetail
                );
            }
        const libraryDetails = yield sce.getLibraryDetails(libraryDetail.id);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetails.details[0],functionWithNodesList)));
        } else {
            const response = yield sce.saveFunction(ModelId, val, functionTags);
            let updatedFunctionsList = _.cloneDeepWith(state.functions.libraryAndFunctionsList);
            if(response.details && response.details.content) {
                let functionCreated = JSON.parse(response.details.content);
                delete functionCreated.attributes;
                delete functionCreated.isExtensible;
                delete functionCreated.isDeleted;
                delete functionCreated.properties;
                delete functionCreated.tenantId;
                let genericFunctionLib = updatedFunctionsList[updatedFunctionsList.length - 1].nodes;
                let functionDetailIndex =  genericFunctionLib.findIndex(fn => fn.typeId === functionCreated.typeId);
                if(functionDetailIndex >= 0) {
                    updatedFunctionsList[updatedFunctionsList.length - 1].nodes[functionDetailIndex] = functionCreated;
                } else {
                    updatedFunctionsList[updatedFunctionsList.length - 1].nodes.push(functionCreated);
                    updatedFunctionsList[updatedFunctionsList.length - 1].nodes.sort((a:any, b:any) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      });
                }
                yield put(getLibraryAndNodesSuccess(updatedFunctionsList))
            }
            
            debugger;
        }
        // handle updateRelatedInstance for minor version update.
        const versionUpdateType = state.functions.versionUpdate;
        if (versionUpdateType === VERSION_UPDATE_TYPE.MINOR) {
            yield put(toggleSpinnerText(true));
            let typeDetails = _.cloneDeepWith(state.functions.typeDetails);

            if (
                typeDetails!.version.split('.')[0] ===
                state.functions.assetsInfo!.version.split('.')[0]
            ) {
                typeDetails = functionDetailRemodelForUpdateRelatedInstances(
                    typeDetails,
                    state,
                    val.isExtensible,
                    state.functions.assetsInfo!
                );
                yield sce.updateRelatedInstances(typeDetails as PartialFunctionType);
                yield put(toggleSpinnerText(false));
            }
            yield put(changeFunctionVersionUpdate('DEFAULT'));
        }

        const { model, isExtensible, properties } = val;

        let functionProperties: FunctionProperties = {
            inputs: properties.inputs,
            outputs: properties.outputs,
            settings: {
                endpoint: properties.endpoint as FunctionProperties['settings']['endpoint'],
            },
        };
        if (functionMode === FUNCTION_MODE.EDIT) {
            const libraryData = (properties as any).library;
            if (libraryData) {
                functionProperties = { ...functionProperties, library: { ...libraryData } };
            }
        }
        if (functionMode === FUNCTION_MODE.VIEW) {
            // Update function properties field during saveAs
            const originalFunctionProperties: any = originalFunctionDetails!.properties;
            functionProperties = {
                ...functionProperties,
                inputs: originalFunctionProperties.inputs,
                outputs: originalFunctionProperties.outputs,
            };
        }

        const functionTypeDetails = {
            model: model,
            typeId: assetsInfo!.typeId,
            version: assetsInfo!.version,
            name: assetsInfo!.name,
            description: assetsInfo!.description,
            tags: [...assetsInfo!.tags],
            properties: { ...functionProperties },
            isExtensible: isExtensible,
        } as CreateFunctionAssetDetails;

        //yield put(getLibraryAndNodesRequest());
        const successMessage = `Function ${assetsInfo && assetsInfo.name} ${saveType === FUNCTION_SAVE_TYPE.CREATE || saveType === FUNCTION_SAVE_TYPE.SAVE_AS
            ? 'created'
            : 'updated'
            } successfully`;

        yield put(
            showNotificationModal({
                title: successMessage,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(
            updateSelectedFunctionType({
                typeDetails: functionTypeDetails,
                activeLibId:
                    functionMode === FUNCTION_MODE.CREATE
                        ? GENERIC_FUNCTIONS
                        : state.functions.activeLibId || GENERIC_FUNCTIONS,
            })
        );
        yield put(handleFunctionMode(FUNCTION_MODE.VIEW));
    } catch (error) {
        yield put(errorLoadingData());
        yield put(toggleSpinnerText(false));
        if (assetsInfo) {
            assetsInfo['endpoint'] = endPoint['value'];
            yield put(updateAssetInfo({ assetsInfo: { ...assetsInfo } }));
        }
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function computeFinalCalculationJSON(calculationDetails: any) {
    let finalObj = _.cloneDeep(calculationDetails);
    Object.keys(finalObj).forEach((key) => {
        console.log(finalObj[key]);
        delete finalObj[key]['name'];
    });
    console.log('finalObj :', finalObj);
    return finalObj;
}

// get All Permissions for Function
function* getAllPermisssionForFunction(
    action: ReturnType<typeof getAllPermisssionForFunctionAction>
) {
    try {
        const hasSave = yield sce.hasPermission(sce.saveFunction);
        const hasDelete = yield sce.hasPermission(sce.deleteFunction);
        const hasImport = yield sce.hasPermission(sce.importFunctionTypes);
        const hasExport = yield sce.hasPermission(sce.getFunctionTypes);
        yield put(
            updateFunctionPermission({
                hasSave,
                hasDelete,
                hasEdit: hasSave,
                hasImport,
                hasExport,
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}
function* getDecryptedFunctionTypeSaga(action: ReturnType<typeof getDecryptedFunctionTypeRequest>) {
    try {
        const res = yield sce.getDecryptedFunctionType(
            action.payload.libraryDetails[0].id,
            action.payload.libraryDetails[0].libraryVersion,
            action.payload.libraryDetails[0].password,
            action.payload.functionsDetailsList[0] as CreateFunctionAssetDetails
        );
        if (res.status === 200) {
            yield put(updateSelectedFunctionType({ typeDetails: res.details }));
            yield put(
                getDecryptedFunctionTypeSuccess({
                    decryptedLibraryDetails: {
                        root: action.payload.libraryDetails[0].root,
                        id: action.payload.libraryDetails[0].id,
                        libraryVersion: action.payload.libraryDetails[0].libraryVersion,
                        isIPProtected: action.payload.libraryDetails[0].isIPProtected,
                        password: action.payload.libraryDetails[0].password,
                    },
                })
            );
            yield put(hideModal());
        }
    } catch (error) {
        yield put(getDecryptedFunctionTypeFailure());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(error)],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}
function* getDecryptedSaveAsFunctionTypeSaga(action: ReturnType<typeof handleSaveAsMessage>) {
    const payload = action.payload;
    try {
        const res = yield sce.getDecryptedFunctionType(
            action.payload.libraryDetails[0].id,
            action.payload.libraryDetails[0].libraryVersion,
            action.payload.libraryDetails[0].password,
            action.payload.functionsDetailsList[0] as CreateFunctionAssetDetails
        );
        if (res.status === 200) {
            yield put(updateSelectedFunctionType({ typeDetails: res.details }));
            yield put(
                getDecryptedFunctionTypeSuccess({
                    decryptedLibraryDetails: {
                        root: action.payload.libraryDetails[0].root,
                        id: action.payload.libraryDetails[0].id,
                        libraryVersion: action.payload.libraryDetails[0].libraryVersion,
                        isIPProtected: action.payload.libraryDetails[0].isIPProtected,
                        password: action.payload.libraryDetails[0].password,
                    },
                })
            );
            yield put(
                showModal({
                    component: EditFunctionInfo,
                    modalTitle: 'Save As Function',
                    data: {
                        functionSaveType: FUNCTION_SAVE_TYPE.SAVE_AS,
                        assetsDetails: {
                            ...payload.assetsInfo,
                        },
                    },
                })
            );
        }
    } catch (error) {
        yield put(getDecryptedFunctionTypeFailure());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(error)],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* getEndpointUrlLists() {
    try {
        const res: any = yield sce.getFunctionEndpointList();
        if (res.status === 200) {
            yield put(updateEndPointList({ endPointlist: res.details }));
        }
    } catch (err) {
        const { error, statusCode } = abbSDKGetLibraryError(err);

        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [error],
            })
        );
    }
}

function* deleteEndpointUrl(action: any) {

    try {
        const { endpoint } = action.payload;
        const res: any = yield sce.deleteFunctionEndpoint(JSON.stringify(endpoint));
    } catch (err) {
        const { error, statusCode } = abbSDKGetLibraryError(err);

        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [error],
            })
        );
    }
}

function* addFunctionEndPointUrl(action: any) {

    try {
        const { endpoint } = action.payload;
        const res: any = yield sce.addFunctionEndpoint(JSON.stringify(endpoint));

    } catch (err) {
        const { error, statusCode } = abbSDKGetLibraryError(err);

        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [error],
            })
        );
    }
}

export default [
    ...librarySaga,
    takeLatest(ActionTypes.GET_SELECTED_FUNCTION_TYPE, selectedFunctionType),
    takeLatest(ActionTypes.HANDLE_FUNCTION_DELETE, deleteSelectedFunction),
    takeLatest(ActionTypes.GET_FUNCTION_TYPES, getFuncTypes),
    takeLatest(ActionTypes.HANDLE_FUNCTION_SAVE, saveCurrentFunction),
    takeLatest(ActionTypes.GET_ALL_PERMISSION_FUNCTION, getAllPermisssionForFunction),
    takeLatest(ActionTypes.GET_ALARM_TYPE, getFunctionAlarmType),
    takeLatest(ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_REQUEST, getDecryptedFunctionTypeSaga),
    takeLatest(ActionTypes.GET_DECRYPTED_PASSWORD_TYPE_REQUEST, getDecryptedSaveAsFunctionTypeSaga),
    takeLatest(ActionTypes.HANDLE_UNIQUE_TYPEID_FUNCTION, handleUniqueTypeIdSagaFunction),
    takeLatest(ActionTypes.GET_FUNCTION_ENDPOINT_URL, getEndpointUrlLists),
    takeLatest(ActionTypes.DELETE_FUNCTION_ENDPOINT_URL, deleteEndpointUrl),
    takeLatest(ActionTypes.ADD_FUNCTION_ENDPOINT_URL, addFunctionEndPointUrl)
];
