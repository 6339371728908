import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { TabControl, TabItem, WithPopup, Icon, Popup } from '@abb/abb-common-ux-react';
import { TabOptionsMenu } from '../../Popups/TabOptionsMenu';
import {
    If,
    getDataForStatusKey,
    getDragTableData,
    removeDropTableDataMapping,
    arrangeTabNavList,
    removeSeverityFromOutputs,
} from '../../helper';
import IOFunctionType from '../../IOFunctionType';
import {
    Inputs,
    Condition,
    Alarm,
    Calculation,
    Output,
    Endpoint,
    availableTabsOption,
    EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER,
} from '../../constants';
import { FUNCTION_MODE, TABLE_IO_TYPE } from '../../../../utils/constants/appConstants';
import FunctionConditionType from '../../FunctionConditionType';
import AlarmFunctionType from '../../AlarmFunctionType';
import CalculationLogicFunctionType from '../../CalculationLogicFunctionType';
import _ from 'lodash';
import { functionType, EndPoint, FunctionComponents } from '../../type';
import {
    FunctionHeaderTypeDetails,
    DragDropTableData,
    AlarmMapping,
} from '../../../../store/function/types';
import {
    CONFIRMATION_BUTTON,
    CREATE_ALARM,
    TOOLTIP_MESSAGES,
} from '../../../../utils/constants/uiConstants';
import { StoreState } from '../../../../store';
import { Dispatch } from 'redux';
import { showAlarmModal } from '../../../../store/AlarmModal/action';
import AlarmConfigurationModal from '../../../../components/AlarmConfigurationModal';
import { connect } from 'react-redux';
import {
    updateFunctionTabNavList,
    updateFunctionHeaderTypeDetails,
    updateAlarmDragDropTables,
    updateAlarmMappingDetails,
    updateFunctionEndPoint,
} from '../../../../store/function/action';
import CustomTooltip from '../../../../components/CustomTooltip';
interface ITabsComponentProps {
    showMessageModal: any;
    handleLogicDialog: any;
    handleError?: (value: boolean) => void;
}
const TabsComponent = (
    props: ITabsComponentProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        tabNavList,
        errorFlag,
        functionMode,
        functionHeaderDetails,
        handleLogicDialog,
        handleFunctionDetails,
        handleError,
        updateTabNavList,
        createFunctionData,
        handleFunctionEndpoint,
    } = props;

    const { inputDetails, outputDetails, calculationDetails, conditionDetails } =
        props.functionHeaderDetails;

    const [tabSelected, updateTabSelected] = useState(
        functionMode === FUNCTION_MODE.EDIT ? functionType.Input : ''
    ) as any;
    const [deleteModal, setDeleteModal] = useState(false);
    const [availableTabs, setAvailableTabs] = useState([...tabNavList]);
    const functionProperties: any =
        createFunctionData.assetsDetails && createFunctionData.assetsDetails.properties;

    useEffect(() => {
        if (tabNavList.length > 0) {
            let activeIndex = _.indexOf(tabNavList, tabSelected);
            if (activeIndex === -1) {
                activeIndex = 0;
            }
            updateTabSelected(tabNavList[activeIndex]);
        } else {
            updateTabSelected('');
        }
    }, [tabNavList]);
    useEffect(() => {
        if (functionMode === FUNCTION_MODE.EDIT) {
            const tabList: FunctionComponents[] = [];
            if (_.has(functionProperties, 'inputs')) {
                tabList.push(FunctionComponents.INPUT);
            }

            if (_.has(functionProperties, 'inputs.conditions')) {
                tabList.push(FunctionComponents.CONDITION);
                if (_.has(functionProperties, 'inputs.alarmMapping')) {
                    tabList.push(FunctionComponents.ALARM);
                }
            }

            if (_.has(functionProperties, 'inputs.calculations')) {
                tabList.push(FunctionComponents.CALCULATION);
            }

            if (_.has(functionProperties, 'outputs')) {
                tabList.push(FunctionComponents.OUTPUT);
            }

            updateTabNavList([...tabList]);
        }
    }, []);

    useEffect(() => {
        let filteredTabs: FunctionComponents[] = [...availableTabsOption];
        if (tabNavList.includes(FunctionComponents.CONDITION)) {
            filteredTabs = availableTabsOption.filter(
                (tab) => tab !== FunctionComponents.CALCULATION
            );
        } else if (tabNavList.includes(FunctionComponents.CALCULATION)) {
            filteredTabs = availableTabsOption.filter(
                (tab) => tab !== FunctionComponents.CONDITION && tab !== FunctionComponents.ALARM
            );
        }
        setAvailableTabs(filteredTabs);
    }, [tabNavList]);

    const commonProps = {
        allowRemove: true,
        activeTab: _.indexOf(tabNavList, tabSelected),
        onTabChange: (oldIndex: any, newIndex: number) => updateTabSelected(tabNavList[newIndex]),
        onRemoveTab: (oldIndex: any, newIndex: number) => {
            if (!errorFlag) {
                props.showMessageModal!({
                    title: 'Delete',
                    acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                    rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                    handleStandardButton: handleMessageClose,
                    selectedTab: tabNavList[newIndex],
                    warningText: `Do you want to delete ${tabNavList[newIndex]}?`,
                });
            }
        },
    };
    const handleInputs = (data: any) => {
        let inputs = { ...data };
        if (Object.keys(inputs).length === 0) {
            tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.INPUT), 1);
            updateTabSelected(tabNavList[0]);
        }
        handleFunctionDetails({
            ...functionHeaderDetails,
            inputDetails: inputs,
        });
    };

    const handleOutputs = (data: any) => {
        let outputs = { ...data };
        if (
            Object.keys(outputs).length === 0 &&
            Object.keys(functionHeaderDetails.calculationDetails).length === 0 &&
            Object.keys(functionHeaderDetails.conditionDetails).length === 0
        ) {
            tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.OUTPUT), 1);
            updateTabSelected(tabNavList[0]);
        }
        handleFunctionDetails({
            ...functionHeaderDetails,
            outputDetails: outputs,
        });
    };

    const handleConditions = (data: any) => {
        if (Object.keys(data).length === 0) {
            tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.CONDITION), 1);
            if (_.indexOf(tabNavList, FunctionComponents.ALARM) !== -1) {
                tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.ALARM), 1);
            }
            setAvailableTabs([...availableTabs, FunctionComponents.CALCULATION]);
            updateTabSelected(tabNavList[0]);
        }
        handleFunctionDetails({
            ...functionHeaderDetails,
            conditionDetails: data,
        });
    };

    const handleCalculationLogic = (data: any, delFlag?: boolean) => {
        if (Object.keys(data).length === 0) {
            tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.CALCULATION), 1);
            if (Object.keys(outputDetails).length === 0) {
                tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.OUTPUT), 1);
            }
            setAvailableTabs([
                ...availableTabs,
                FunctionComponents.CONDITION,
                FunctionComponents.ALARM,
            ]);
            updateTabSelected(tabNavList[0]);
        }

        handleFunctionDetails({
            ...functionHeaderDetails,
            calculationDetails: data,
        });
    };

    const handleInputError = (value: boolean) => {
        if (functionMode === FUNCTION_MODE.EDIT || functionMode === FUNCTION_MODE.CREATE) {
            handleError && handleError(value);
        }
    };

    const handleOutputError = (value: boolean) => {
        if (functionMode === FUNCTION_MODE.EDIT || functionMode === FUNCTION_MODE.CREATE) {
            handleError && handleError(value);
        }
    };

    const handleConditionError = (value: boolean) => {
        if (functionMode === FUNCTION_MODE.EDIT || functionMode === FUNCTION_MODE.CREATE) {
            handleError && handleError(value);
        }
    };

    const handleCalculationLogicError = (value: boolean) => {
        if (functionMode === FUNCTION_MODE.EDIT || functionMode === FUNCTION_MODE.CREATE) {
            handleError && handleError(value);
        }
    };

    const handleSeverity = () => {
        if (Array.from(Object.keys(outputDetails)).findIndex((v) => v === 'severity') === -1) {
            outputDetails['severity'] = { dataType: 'integer' };
        }
    };
    const handleCreateAlarm = useCallback(() => {
        if (!tabNavList.includes(FunctionComponents.ALARM)) {
            updateTabSelected(Alarm);
        }
    }, [tabNavList]);

    const onAddNewTab = useCallback(
        (newTab: FunctionComponents) => {
            if (tabNavList.indexOf(newTab) === -1) {
                if (
                    newTab === FunctionComponents.ALARM &&
                    tabNavList.includes(FunctionComponents.CONDITION)
                ) {
                    let dragTableData = getDragTableData({
                        sourceData: props.functionHeaderDetails.conditionDetails,
                    });
                    const updatedDropTableData = removeDropTableDataMapping(props.dropTableData);
                    props.updateAlarmDragDropTables({
                        dropTableData: updatedDropTableData,
                        dragTableData: dragTableData,
                    });
                    props.showAlarmConfigurationModal(handleCreateAlarm);
                }
                if (
                    newTab === FunctionComponents.CALCULATION &&
                    !tabNavList.includes(FunctionComponents.CONDITION)
                ) {
                    tabNavList.push(FunctionComponents.CALCULATION);
                    tabNavList.push(FunctionComponents.OUTPUT);
                    updateTabSelected(newTab);
                } else if (
                    newTab === FunctionComponents.CONDITION &&
                    !tabNavList.includes(FunctionComponents.CALCULATION)
                ) {
                    tabNavList.push(FunctionComponents.CONDITION);
                    tabNavList.push(FunctionComponents.OUTPUT);
                    updateTabSelected(newTab);
                } else {
                    if (
                        newTab !== FunctionComponents.CALCULATION &&
                        newTab !== FunctionComponents.CONDITION &&
                        newTab !== FunctionComponents.ALARM
                    ) {
                        tabNavList.push(newTab);
                        updateTabSelected(newTab);
                    } else {
                        return;
                    }
                }
                const updatedTabNavList = arrangeTabNavList([...tabNavList]);
                updateTabNavList(updatedTabNavList);
            }
        },
        [tabNavList, props.functionHeaderDetails]
    );

    const handleMessageClose = (data: string, tabSel: any) => {
        console.log('tabSel :', tabSel);
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            tabNavList.splice(tabNavList.indexOf(tabSel), 1);
            updateTabNavList([...tabNavList]);

            if (tabSel === FunctionComponents.INPUT) {
                handleFunctionDetails({
                    ...functionHeaderDetails,
                    inputDetails: {},
                });
            }

            if (tabSel === FunctionComponents.CONDITION) {
                if (_.indexOf(tabNavList, FunctionComponents.ALARM) !== -1) {
                    tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.ALARM), 1);
                    props.updateAlarmMappingDetails({});

                    updateTabNavList([...tabNavList]);
                }

                const updatedOutputs = removeSeverityFromOutputs({
                    ...functionHeaderDetails.outputDetails,
                });
                if (
                    Object.keys(updatedOutputs).length === 0 &&
                    _.indexOf(tabNavList, FunctionComponents.OUTPUT) !== -1
                ) {
                    tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.OUTPUT), 1);
                    updateTabNavList([...tabNavList]);
                }

                handleFunctionDetails({
                    ...functionHeaderDetails,
                    conditionDetails: {},
                    outputDetails: { ...updatedOutputs },
                });
            }

            if (tabSel === FunctionComponents.ALARM) {
                props.updateAlarmMappingDetails({});
            }

            if (tabSel === FunctionComponents.CALCULATION) {
                if (
                    _.indexOf(tabNavList, FunctionComponents.OUTPUT) !== -1 &&
                    Object.keys(functionHeaderDetails.outputDetails).length === 0
                ) {
                    tabNavList.splice(_.indexOf(tabNavList, FunctionComponents.OUTPUT), 1);
                    props.updateAlarmMappingDetails({});

                    updateTabNavList([...tabNavList]);
                }
                handleFunctionDetails({
                    ...functionHeaderDetails,
                    calculationDetails: [],
                });
            }

            if (tabSel === Output) {
                handleFunctionDetails({
                    ...functionHeaderDetails,
                    outputDetails: {},
                });
            }

            if (tabSel === Endpoint) {
                handleFunctionEndpoint({} as EndPoint);
            }
        }
        setDeleteModal(!deleteModal);
    };

    return (
        <Fragment>
            <div className="headerNavLinkFunction">
                <div className="wrapper-func-create-edit-tabs">
                    <TabControl
                        className="func-create-edit-tabs"
                        id="funcCreateEditTabs"
                        type="primary"
                        {...commonProps}
                        showContentBorderOnPanelTab={true}
                    >
                        {tabNavList.map((list: string, listIndex: number) => {
                            return (
                                <TabItem
                                    disabled={errorFlag ? true : false}
                                    key={listIndex}
                                    title={list}
                                ></TabItem>
                            );
                        })}
                    </TabControl>

                    {tabNavList.length != availableTabs.length && (
                        <WithPopup>
                            <span>
                                <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.ADD_MORE}>
                                    <div className="add-tabs-btn">
                                        <Icon name="abb/plus" />
                                    </div>
                                </CustomTooltip>
                            </span>

                            <Popup
                                disabled={errorFlag}
                                trigger="click"
                                position={tabNavList.length <= 1 ? 'bottom left' : 'bottom center'}
                                className={'tabs-popup'}
                                closeOnLostFocus={true}
                                closeOnEscape={true}
                                onOpen={() => console.log('show popup')}
                                onClose={() => console.log('hide popup')}
                                render={(nextProps: any) => (
                                    <TabOptionsMenu
                                        {...nextProps}
                                        tabNavList={[...props.tabNavList]}
                                        availableTabs={[...availableTabs]}
                                        onAddNewTab={onAddNewTab}
                                    />
                                )}
                            />
                        </WithPopup>
                    )}
                </div>

                <div className="propertiesView">
                    {
                        <Fragment>
                            <If cond={tabSelected === ''}>
                                <div className="center-div">
                                    {EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER}
                                </div>
                            </If>
                            <If cond={tabSelected === Inputs}>
                                <IOFunctionType
                                    ioData={inputDetails}
                                    handleIOError={handleInputError}
                                    handleData={handleInputs}
                                    droppedItem={false}
                                    componentMode={
                                        functionMode === FUNCTION_MODE.EDIT
                                            ? FUNCTION_MODE.EDIT
                                            : FUNCTION_MODE.CREATE
                                    }
                                    componentType={TABLE_IO_TYPE.INPUT}
                                    showMessageModal={props.showMessageModal}
                                />
                            </If>
                            <If cond={tabSelected === Condition}>
                                <FunctionConditionType
                                    handleConditions={handleConditions}
                                    handleLogicDialog={handleLogicDialog}
                                    inputs={conditionDetails}
                                    handleSeverity={handleSeverity}
                                    droppedItem={false}
                                    componentMode={
                                        functionMode === FUNCTION_MODE.EDIT
                                            ? FUNCTION_MODE.EDIT
                                            : FUNCTION_MODE.CREATE
                                    }
                                    showMessageModal={props.showMessageModal}
                                    dataSetForStatusKey={getDataForStatusKey([
                                        ...Object.keys(functionHeaderDetails.inputDetails).map(
                                            (item) => `$input.${item}`
                                        ),
                                    ])}
                                />
                            </If>

                            <If cond={tabSelected === Alarm}>
                                <AlarmFunctionType showMessageModal={props.showMessageModal} />
                            </If>

                            <If cond={tabSelected === Calculation}>
                                <CalculationLogicFunctionType
                                    calculationData={calculationDetails}
                                    functionMode={functionMode}
                                    handleCalculation={handleCalculationLogic}
                                    handleCalculationError={handleCalculationLogicError}
                                    droppedItem={false}
                                    showMessageModal={props.showMessageModal}
                                    isEncrypted={false}
                                />
                            </If>
                            <If cond={tabSelected === Output}>
                                <IOFunctionType
                                    handleData={handleOutputs}
                                    severityStatus={_.indexOf(
                                        tabNavList,
                                        FunctionComponents.CONDITION
                                    )}
                                    handleIOError={handleOutputError}
                                    ioData={outputDetails}
                                    droppedItem={false}
                                    componentMode={
                                        functionMode === FUNCTION_MODE.EDIT
                                            ? FUNCTION_MODE.EDIT
                                            : FUNCTION_MODE.CREATE
                                    }
                                    componentType={TABLE_IO_TYPE.OUTPUT}
                                    showMessageModal={props.showMessageModal}
                                />
                            </If>
                        </Fragment>
                    }
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        functionMode: state.functions.functionMode,
        functionHeaderDetails: state.functions.functionHeaderDetails,
        tabNavList: state.functions.tabNavList,
        dropTableData: state.functions.dropTableData,
        alarmMapping: state.functions.alarmMapping,
        createFunctionData: state.functions.currentActiveView,
        errorFlag: state.functions.functionErrorFlag,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showAlarmConfigurationModal: (handleCreateAlarm: () => void) => {
            dispatch(
                showAlarmModal({
                    component: AlarmConfigurationModal,
                    modalTitle: CREATE_ALARM.NEW_ALARM,
                    data: {
                        submitBtnText: CREATE_ALARM.CREATE,
                        mode: 'CREATE',
                        handleCreateAlarm: handleCreateAlarm,
                    },
                    customClassName: 'alarm-configuration-dialog-class',
                })
            );
        },
        updateTabNavList: (tabNavList: FunctionComponents[]) => {
            dispatch(updateFunctionTabNavList(tabNavList));
        },
        handleFunctionDetails: (payload: FunctionHeaderTypeDetails) => {
            dispatch(updateFunctionHeaderTypeDetails(payload));
        },
        updateAlarmDragDropTables: (payload: DragDropTableData) => {
            dispatch(updateAlarmDragDropTables(payload));
        },
        updateAlarmMappingDetails: (payload: AlarmMapping) => {
            dispatch(updateAlarmMappingDetails(payload));
        },
        handleFunctionEndpoint: (endpoint: EndPoint) => {
            dispatch(updateFunctionEndPoint(endpoint));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TabsComponent);
