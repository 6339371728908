import React from 'react';
import "./style.scss";
import { Icon } from '@abb/abb-common-ux-react';

interface CollapseTitleProps {
    toggleCollapse: () => void;
    isCollapse: boolean;
    title: string;
}

const CollapseTitle = (
    props: CollapseTitleProps
) => {
    const { toggleCollapse, isCollapse, title } = props;
    return (
        <div className="title-collapse-handle" onClick={toggleCollapse}>
            {isCollapse ? (
                <Icon
                    name="abb/minus"
                    sizeClass="small"
                    className="toggle-icon"
                />
            ) : (
                <Icon
                    name="abb/plus"
                    sizeClass="small"
                    className="toggle-icon"
                />
            )}
            <h2>
                <div className="view-mode-header">{title}</div>
            </h2>
        </div>
    )
}

export default CollapseTitle;