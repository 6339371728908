import { ActionTypes } from "../actionTypes";
import { MessageModalState, MessageModalAction } from "./types";

const initialState: MessageModalState = {
    show: false,
    component: undefined,
    data: undefined
};

const modalReducer = (
    state: MessageModalState = initialState,
    action: MessageModalAction
): MessageModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_MESSAGE_MODAL:
            const { component } = action.payload;

            if (component) {
                return {
                    show: true,
                    ...action.payload
                };
            } else {
                return state;
            }

        case ActionTypes.HIDE_MESSAGE_MODAL:
            return { ...initialState };
        default:
            return state;
    }
};

export default modalReducer;
