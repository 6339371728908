import React from "react";
import AssetConfigModalForm from "./AssetConfigModalForm";
interface AssetConfigModalProps {}

const AssetConfigModal = (props: AssetConfigModalProps) => {
    return (
        <div>
            <AssetConfigModalForm />
        </div>
    );
};

export default AssetConfigModal;
