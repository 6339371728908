import React from 'react';
import { useDrag } from 'react-dnd';
// import { Collapsible, CollapsibleContainer } from "@abb/abb-common-ux-react";

import { UpdateActiveFunctionType, LibraryAndNodePayload } from '../../../../store/function/types';
import FunctionItemMenu from '../Menu/FunctionItemMenu';
import { DRAG_DROP_FUNCTIONS_FROM_LIBRARIES } from '../../constants';
import { GENERIC_FUNCTIONS } from '../../../../utils/constants/appConstants';
import Collapsible from '../../Collapsible';

interface CollapsibleNodeProps {
    data: UpdateActiveFunctionType;
    item: LibraryAndNodePayload;
    showModalToRemoveSingleFunction: (
        libraryDetail: LibraryAndNodePayload,
        currentFunction: UpdateActiveFunctionType
    ) => void;
    selectedFunctionTypeId: string;
    selectedFunctionVersion: string;
    handleTypeList: (typeList: UpdateActiveFunctionType, libId: string) => void;
}

const CollapsibleNode = (props: CollapsibleNodeProps) => {
    const {
        data,
        item,
        showModalToRemoveSingleFunction,
        selectedFunctionTypeId,
        selectedFunctionVersion,
        handleTypeList,
    } = props;

    const [, drag] = useDrag({
        item: {
            type: DRAG_DROP_FUNCTIONS_FROM_LIBRARIES.DRAG_FUNCTIONS,
            data: {
                function: data,
                fromLibrary: item,
            },
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    });

    return (
        <div ref={!item.root && !item.id ? drag : null}>
            {/* <CollapsibleContainer> */}
            <Collapsible
                title={`${data.name} ${data.version}`}
                key={data.typeId}
                className={`func-list-item ${
                    selectedFunctionTypeId === data.typeId &&
                    selectedFunctionVersion === data.version
                        ? 'isActive'
                        : ''
                }`}
                onClick={() => {
                    !(
                        selectedFunctionTypeId === data.typeId &&
                        selectedFunctionVersion === data.version
                    ) && handleTypeList(data, item.id || GENERIC_FUNCTIONS);
                }}
                isLeaf={true}
                iconsRight={
                    <FunctionItemMenu
                        libraryDetail={item}
                        currentFunction={data}
                        showModalToRemoveSingleFunction={showModalToRemoveSingleFunction}
                    />
                }
            >
                {/* <FunctionItemMenu
                    libraryDetail={item}
                    currentFunction={data}
                    showModalToRemoveSingleFunction={showModalToRemoveSingleFunction}
                /> */}
            </Collapsible>
            {/* </CollapsibleContainer> */}
        </div>
    );
};

export default CollapsibleNode;
