import React, { Fragment, useState, useEffect } from "react";

import {
    Input,
    Icon,
    Checkbox,
    Dropdown,
    DropdownOption,
} from "@abb/abb-common-ux-react";
import { FunctionMode, FunctionAlarmType } from "../../../store/function/types";
import { FUNCTION_MODE } from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface AlarmTableBodyProps {
    functionMode: FunctionMode;
    selectedAlarmType: FunctionAlarmType;
}

function AlarmTableBody(props: AlarmTableBodyProps) {
    return (
        <tbody>
            <tr>
                <td className="width-15">
                    {props.functionMode === "VIEW" ? (
                        props.selectedAlarmType.name
                    ) : (
                        <CustomABBInput
                            type="normal"
                            dataType="text"
                            value={props.selectedAlarmType.name}
                            onValueChange={(val) => {}}
                            className="functionClass"
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                            instantValidation={true}
                        />
                    )}
                </td>

                <td className="width-13.5">
                    {props.functionMode === FUNCTION_MODE.VIEW ? (
                        props.selectedAlarmType.description
                    ) : (
                        <CustomABBInput
                            type="normal"
                            dataType="textarea"
                            value={props.selectedAlarmType.description}
                            onValueChange={(val) =>
                                console.log("description change")
                            }
                            className="functionClass"
                        />
                    )}
                </td>
            </tr>
        </tbody>
    );
}

export default AlarmTableBody;
