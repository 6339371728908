import { fabric } from 'fabric';
import ConnectionController from '../ConnectionController';
import {
    MoveOptions,
    ConstructorOptions,
    AddInputOptions,
    AddOutputOptions,
    FunctionOutputPublish,
    FunctionInputTrigger,
    AddAlarmToFunctionInputOptions,
    FunctionOutputCircle,
    ObjectOutputCircle,
    AddEventToFunctionInputOptions,
} from './types';

import {
    FunctionOutput,
    ObjectOutput,
    FunctionInput,
    AlarmOutput,
    AlarmEventInput,
    FunctionAlarmInputPin,
    FunctionEventInputPin,
} from './helperClasses';

import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';
import { FabricAlarmIcon } from '../UIHelpers/FabricAlarmIcon';
import { FabricHistoryIcon } from '../UIHelpers/FabricHistoryIcon';
import {
    FUNCTION_SEVERITY_PIN,
    SamplingAggregateValues,
} from '../../../utils/constants/appConstants';
import {
    outputCircleRadius,
    fullFunctionPinLength,
    objectOutputPinLength,
    GRID_PIXEL,
    BLOCK_BOTTOM_PADDING,
} from '../UIHelpers/UiConfig';
import NodeController from '../NodeController';
import { FabricFunctionRectangle } from '../UIHelpers/functionRectangle';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import EventTypeDetail from '../../../transformers/AssetType/EventType';

class IOController {
    private _canvas: fabric.Canvas;
    private _outputs: (FunctionOutput | ObjectOutput | AlarmOutput)[];
    private _inputs: FunctionInput[];
    private _ConnectionController: ConnectionController;
    private _alarmInputs: FunctionAlarmInputPin[];
    private _eventInputs: FunctionEventInputPin[];
    private _NodeController: NodeController;

    constructor(options: ConstructorOptions) {
        this._canvas = options.canvas;
        this._ConnectionController = options.connectionController;
        this._outputs = [];
        this._inputs = [];
        this._alarmInputs = [];
        this._eventInputs = [];
        this._NodeController = options.nodeController;
    }

    addGroupItemsToCanvas(group: fabric.Group) {
        const items = group.getObjects();
        items.forEach((item) => this._canvas.add(item));
    }

    addInput(options: AddInputOptions) {
        const { asset, nodeDetails, parent } = options;
        let input;
        if (asset instanceof FunctionTypeDetail) {
            const { inputs } = asset;
            input = new FunctionInput({ asset, ...nodeDetails, parent });
        } else {
            input = new AlarmEventInput({ asset, ...nodeDetails, parent });
        }
        this._inputs.push(input);
        this.addGroupItemsToCanvas(input.completeGroup);
        input.completeGroup.setObjectsCoords();
        return input;
    }

    addAlarmToFunctionInput(options: AddAlarmToFunctionInputOptions) {
        const { asset, nodeDetails, parent, circleData } = options;
        let alarmInput;

        alarmInput = new FunctionAlarmInputPin({
            asset,
            ...nodeDetails,
            parent,
            circleData,
        });
        this._alarmInputs.push(alarmInput);
        this.addGroupItemsToCanvas(alarmInput.completeGroup);
        alarmInput.completeGroup.setObjectsCoords();
        return alarmInput;
    }

    addEventToFunctionInput(options: AddEventToFunctionInputOptions) {
        const { asset, nodeDetails, parent, circleData } = options;
        let eventInput;

        eventInput = new FunctionEventInputPin({
            asset,
            ...nodeDetails,
            parent,
            circleData,
        });
        this._eventInputs.push(eventInput);
        this.addGroupItemsToCanvas(eventInput.completeGroup);
        eventInput.completeGroup.setObjectsCoords();
        return eventInput;
    }

    addOutput(options: AddOutputOptions) {
        const { asset, nodeDetails, parent } = options;

        let output;
        if (asset) {
            if (asset instanceof AlarmTypeDetail || asset instanceof EventTypeDetail) {
                output = new AlarmOutput({ asset, ...nodeDetails, parent });
            } else if (asset instanceof ObjectTypeDetail) {
                output = new ObjectOutput({ asset, ...nodeDetails, parent });
            } else {
                output = new FunctionOutput({ asset, ...nodeDetails, parent });
            }
        }
        if (output) {
            this._outputs.push(output);
            // output.completeGroup.setCoords();
            this.addGroupItemsToCanvas(output.completeGroup);
            output.completeGroup.setObjectsCoords();
        }

        return output;
    }

    handleNodeMove = (options: MoveOptions) => {
        const outputData = this._outputs.find((output) => output.nodeId === options.nodeId);
        const inputData = this._inputs.find((input) => input.nodeId === options.nodeId);
        const alarmData = this._alarmInputs.filter((input) => input.nodeId === options.nodeId);

        if (outputData) {
            const group = outputData.completeGroup;
            group.left = options.position.x + options.nodeWidth / 2;
            group.top = options.position.y;
            group.scaleX = options.scaleX;
            group.scaleY = options.scaleY;

            if (outputData instanceof FunctionOutput) {
                // output group positioning.
                group.top = options.position.y;
                group.left =
                    options.position.x +
                    options.nodeWidth / 2 +
                    fullFunctionPinLength * options.scaleX;
            } else {
                group.top = options.position.y;
                group.left =
                    options.position.x +
                    options.nodeWidth / 2 +
                    (objectOutputPinLength + outputCircleRadius) * options.scaleX;
            }

            outputData.ioCircles.forEach((ioCircle: FunctionOutputCircle | ObjectOutputCircle) => {
                this._ConnectionController.handleIOMove({
                    nodeHeight: options.nodeHeight,
                    nodeWidth: options.nodeWidth,
                    ioId: ioCircle.data.circleData.id,
                    nodeId: ioCircle.data.asset.nodeId,
                    position: {
                        x: ioCircle.calcTransformMatrix()[4],
                        y: ioCircle.calcTransformMatrix()[5],
                    },
                });
            });
        }

        if (inputData) {
            const group = inputData.completeGroup;
            const leftMargin = fullFunctionPinLength * options.scaleX;
            group.left = options.position.x - options.nodeWidth / 2 - leftMargin;
            if (inputData instanceof AlarmEventInput) {
                group.top = options.position.y - outputCircleRadius * 2 - 1;
            } else {
                group.top =
                    options.position.y -
                    options.nodeHeight / 2 +
                    GRID_PIXEL * 2 -
                    outputCircleRadius * 2 -
                    1;
            }

            group.scaleX = options.scaleX;
            group.scaleY = options.scaleY;
            if (inputData.historyIcons.length > 0) {
                // group.left = options.position.x - options.nodeWidth / 2 + 25;
            }
            inputData.ioCircles.forEach((ioCircle) => {
                this._ConnectionController.handleIOMove({
                    nodeHeight: options.nodeHeight,
                    nodeWidth: options.nodeWidth,
                    ioId: ioCircle.data.circleData.id,
                    nodeId: ioCircle.data.asset.nodeId,
                    position: {
                        x: ioCircle.calcTransformMatrix()[4],
                        y: ioCircle.calcTransformMatrix()[5],
                    },
                });
            });
        }

        if (alarmData.length > 0) {
            alarmData.forEach((alarm, index) => {
                const group = alarm.completeGroup;
                const leftMargin = fullFunctionPinLength * options.scaleX;
                group.left = options.position.x - options.nodeWidth / 2 - leftMargin;
                group.top =
                    options.position.y +
                    options.nodeHeight / 2 -
                    (alarmData.length - index) * BLOCK_BOTTOM_PADDING;

                group.scaleX = options.scaleX;
                group.scaleY = options.scaleY;

                const ioCircle = alarm.ioCircle;
                this._ConnectionController.handleIOMove({
                    nodeHeight: options.nodeHeight,
                    nodeWidth: options.nodeWidth,
                    ioId: ioCircle.data.circleData.id,
                    nodeId: ioCircle.data.asset.nodeId,
                    position: {
                        x: ioCircle.calcTransformMatrix()[4],
                        y: ioCircle.calcTransformMatrix()[5],
                    },
                });
            });
        }
    };

    handleFunctionNameChange(options: { oldFunctionName: string; newFunctionName: string }) {
        const { oldFunctionName, newFunctionName } = options;

        const input = this._inputs.find((input) => input.nodeId === oldFunctionName);

        if (input) {
            input.nodeId = newFunctionName;
            input.ioCircles.forEach((ioCircle) => {
                ioCircle.data.asset.nodeId = newFunctionName;
                ioCircle.data.circleData.path = ioCircle.data.circleData.path.replace(
                    oldFunctionName,
                    newFunctionName
                );
                ioCircle.data.circleData.id = ioCircle.data.circleData.id.replace(
                    oldFunctionName,
                    newFunctionName
                );
                if (ioCircle.data.tooltip) {
                    ioCircle.data.tooltip = ioCircle.data.tooltip.replace(
                        oldFunctionName,
                        newFunctionName
                    );
                }
            });
        }

        const alarmInputs = this._alarmInputs.filter((alarmInput) => alarmInput.nodeId === oldFunctionName);

        this._alarmInputs.forEach((alarmInput) => {
            if (alarmInput.nodeId === oldFunctionName) {
                alarmInput.nodeId = newFunctionName;
                alarmInput.ioCircle.data.asset.nodeId = newFunctionName;
                alarmInput.ioCircle.data.circleData.path = alarmInput.ioCircle.data.circleData.path.replace(
                    oldFunctionName,
                    newFunctionName
                );
                alarmInput.ioCircle.data.circleData.id = alarmInput.ioCircle.data.circleData.id.replace(
                    oldFunctionName,
                    newFunctionName
                );
                if (alarmInput.ioCircle.data.tooltip) {
                    alarmInput.ioCircle.data.tooltip = alarmInput.ioCircle.data.tooltip.replace(
                        oldFunctionName,
                        newFunctionName
                    );
                }
            }
        });

        const output = this._outputs.find((output) => output.nodeId === oldFunctionName);

        if (output) {
            output.nodeId = newFunctionName;
            output.ioCircles.forEach((ioCircle: FunctionOutputCircle | ObjectOutputCircle) => {
                ioCircle.data.asset.nodeId = newFunctionName;
                ioCircle.data.circleData.path = ioCircle.data.circleData.path.replace(
                    oldFunctionName,
                    newFunctionName
                );
                ioCircle.data.circleData.id = ioCircle.data.circleData.id.replace(
                    oldFunctionName,
                    newFunctionName
                );
                if (ioCircle.data.tooltip) {
                    ioCircle.data.tooltip = ioCircle.data.tooltip.replace(
                        oldFunctionName,
                        newFunctionName
                    );
                }
            });
        }
    }

    handleHistoricalData(options: {
        nodeId: string;
        timeWindow: string;
        samplingAggregate?: SamplingAggregateValues;
        samplingWindow?: string;
        limit?: string;
        ioId: string;
    }) {
        const { nodeId, timeWindow, samplingAggregate, samplingWindow, limit, ioId } = options;
        const input = this._inputs.find((input) => input.nodeId === nodeId);

        if (input) {
            input.ioCircles.forEach((ioCircle) => {
                if (ioCircle.data.circleData.id === ioId) {
                    if (timeWindow) {
                        ioCircle.data.circleData.timeWindow = timeWindow;
                    } else {
                        delete ioCircle.data.circleData['timeWindow'];
                    }
                    if (samplingAggregate) {
                        ioCircle.data.circleData.samplingAggregate = samplingAggregate;
                    } else {
                        delete ioCircle.data.circleData['samplingAggregate'];
                    }
                    if (samplingWindow) {
                        ioCircle.data.circleData.samplingWindow = samplingWindow;
                    } else {
                        delete ioCircle.data.circleData['samplingWindow'];
                    }
                    if (limit) {
                        ioCircle.data.circleData.limit = limit;
                    } else {
                        delete ioCircle.data.circleData['limit'];
                    }
                }
            });
        }
    }

    clearHistoricalData(nodeId: string, ioId: string) {
        const input = this._inputs.find((input) => input.nodeId === nodeId);
        if (input) {
            input.ioCircles.forEach((ioCircle) => {
                if (ioCircle.data.circleData.id === ioId) {
                    delete ioCircle.data.circleData['timeWindow'];
                    delete ioCircle.data.circleData['samplingAggregate'];
                    delete ioCircle.data.circleData['samplingWindow'];
                    delete ioCircle.data.circleData['limit'];
                }
            });
        }
    }

    handleOutputPublish(target: FunctionOutputPublish, reverify = false) {
        target.data.circleData.isPublished = reverify
            ? target.data.circleData.isPublished
            : !target.data.circleData.isPublished;

        if (
            target.data.asset instanceof FunctionTypeDetail &&
            target.data.asset.conditions &&
            target.data.circleData.name === FUNCTION_SEVERITY_PIN &&
            !reverify
        ) {
            if (target.data.asset.conditionsConfigurationVariables.length > 0) {
                target.triggerOn();
                target.data.circleData.isPublished = true;
            } else {
                target.triggerOff();
                target.data.circleData.isPublished = false;
            }
        }
        if (
            target.data.asset instanceof FunctionTypeDetail &&
            target.data.asset.conditions &&
            target.data.circleData.name === FUNCTION_SEVERITY_PIN &&
            reverify
        ) {
            target.triggerOn();
            target.data.circleData.isPublished = true;
        } else {
            if (!target.data.circleData.isPublished) {
                target.triggerOff();
            } else {
                target.triggerOn();
            }
        }

        this._canvas.renderAll();
    }

    handleFunctionAlarmPublish(target: FabricAlarmIcon, reverify = false) {
        target.data.alarmPins.isPublished = reverify
            ? target.data.alarmPins.isPublished
            : !target.data.alarmPins.isPublished;
        if (!target.data.alarmPins.isPublished) {
            target.triggerOff();
        } else {
            target.triggerOn();
        }
        this._canvas.renderAll();
    }

    handleDeleteNode(nodeId: string) {
        this._inputs = this._inputs.filter((input) => {
            if (input.nodeId === nodeId) {
                this._canvas.remove(...input.completeGroup.getObjects());
                return false;
            }

            return true;
        });

        this._outputs = this._outputs.filter((output) => {
            if (output.nodeId === nodeId) {
                this._canvas.remove(...output.completeGroup.getObjects());
                return false;
            }

            return true;
        });

        this._alarmInputs = this._alarmInputs.filter((input) => {
            if (input.nodeId === nodeId) {
                this._canvas.remove(...input.completeGroup.getObjects());
                return false;
            }

            return true;
        });
        this._eventInputs = this._eventInputs.filter((input) => {
            if (input.nodeId === nodeId) {
                this._canvas.remove(...input.completeGroup.getObjects());
                return false;
            }

            return true;
        });
    }
    handleToggleNode(nodeId: string) {
        this.handleDeleteNode(nodeId);
    }

    handleResetTrigger(options: { ioId: string; nodeId: string }[]) {
        options.forEach((option) => {
            this._inputs.find((input) => {
                if (input.nodeId === option.nodeId) {
                    return input.ioTriggers.forEach((trigger) => {
                        if (trigger.data.circleData.id === option.ioId) {
                            trigger.triggerOff();
                            return true;
                        }
                        return false;
                    });
                }
                return false;
            });
            this._alarmInputs.find((alarmInput) => {
                if (alarmInput.nodeId === option.nodeId) {
                    if (alarmInput.ioTrigger.data.circleData.id === option.ioId) {
                        alarmInput.ioTrigger.triggerOff();
                        return true;
                    } else {
                        return false;
                    }
                }
                return false;
            });
        });
    }

    resetHistoricalDataTrigger(options: { ioId: string; nodeId: string }[]) {
        options.forEach((option) => {
            this._inputs.find((input) => {
                if (input.nodeId === option.nodeId) {
                    return input.historyIcons.forEach((historyIcon) => {
                        if (historyIcon.data.circleData.id === option.ioId) {
                            historyIcon.triggerOff();
                            this.clearHistoricalData(option.nodeId, option.ioId);
                            return true;
                        }
                        return false;
                    });
                }
                return false;
            });
        });
    }

    getHistoricalDataTrigger(options: { ioId: string; nodeId: string }[]) {
        let historicalDataTrigger: FabricHistoryIcon | undefined;
        options.forEach((option) => {
            const input = this._inputs.find((item) => item.nodeId === option.nodeId);
            if (input) {
                historicalDataTrigger = input.historyIcons.find(
                    (historyIcon) => historyIcon.data.circleData.id === option.ioId
                );
            }
        });
        return historicalDataTrigger;
    }

    getInputTrigger(options: { ioId: string; nodeId: string }[]) {
        let inputTrigger: FunctionInputTrigger | undefined;
        options.forEach((option) => {
            const input = this._inputs.find((item) => item.nodeId === option.nodeId);
            if (input) {
                inputTrigger = input.ioTriggers.find(
                    (trigger) => trigger.data.circleData.id === option.ioId
                );
            }
        });
        return inputTrigger;
    }

    getObjectOutputPinById(options: { ioId: string; nodeId: string }) {
        let outputPin: ObjectOutputCircle | undefined;
        const output = this._outputs.find((item) => item.nodeId === options.nodeId);
        if (output) {
            outputPin = (output.ioCircles as ObjectOutputCircle[]).find(
                (ioCircle: ObjectOutputCircle) => ioCircle.data.circleData.id === options.ioId
            );
        }
        return outputPin!;
    }

    handleAlarmPinDelete(options: { ioId: string; asset: FunctionTypeDetail; parent: any }) {
        const { ioId, asset, parent } = options;
        const currentAssetAlarmInputPins = this.getAssetAlarmInputs(asset.nodeId);
        const currentPinIndex = currentAssetAlarmInputPins.findIndex(
            (pin) => pin.crossIcon.data.circleData.id === ioId
        );
        const assetAlarmInputPins = currentAssetAlarmInputPins.filter((input) => {
            if (input.crossIcon.data.circleData.id === ioId) {
                this._canvas.remove(...input.completeGroup.getObjects());
                return false;
            }
            return true;
        });
        this._alarmInputs = this._alarmInputs.filter((input) => {
            if (input.crossIcon.data.circleData.id === ioId) {
                this._canvas.remove(...input.completeGroup.getObjects());
                return false;
            }
            return true;
        });
        if (asset instanceof FunctionTypeDetail) {
            asset.alarmInputs = asset.alarmInputs.filter((pin) => pin.id !== ioId);
        }
        let nodeDetails = {
            nodeHeight: parent.getScaledHeight(),
            nodeWidth: parent.getScaledWidth(),
        };

        if (assetAlarmInputPins.length > 0) {
            assetAlarmInputPins.slice(currentPinIndex).forEach((input) => {
                if (input.completeGroup.top) {
                    input.completeGroup.set('top', input.completeGroup.top - BLOCK_BOTTOM_PADDING);
                }
                this._ConnectionController.handleIOMove({
                    nodeHeight: nodeDetails.nodeHeight,
                    nodeWidth: nodeDetails.nodeWidth,
                    ioId: input.ioCircle.data.circleData.id,
                    nodeId: asset.nodeId,
                    position: {
                        x: input.ioCircle.calcTransformMatrix()[4],
                        y: input.ioCircle.calcTransformMatrix()[5],
                    },
                });
            });
        }

        let connection = this._ConnectionController.getConnectionById({
            ioId: ioId,
            nodeId: asset.nodeId,
        });
        if (connection) {
            this._ConnectionController.handleDeleteConnection(connection);
            this._canvas.remove(connection);

            let alarmNodeConnection = this._ConnectionController.getConnectionByNodeId(
                connection.data.output.asset.nodeId
            );

            let connections = this._ConnectionController.getAllConnectionsByNodeId(
                connection.data.output.asset.nodeId
            );
            let hasSpecificObjectConnection = false;
            if (connections.length === 1) {
                if (connections[0].data.output.asset instanceof ObjectTypeDetail) {
                    hasSpecificObjectConnection = true;
                    this._ConnectionController.handleDeleteConnection(connections[0]);
                    this._canvas.remove(connections[0]);
                }
            }

            if (!alarmNodeConnection || (hasSpecificObjectConnection && connections.length == 1)) {
                let alarmNode = this._NodeController.getDropDownTitleNodesById(
                    connection.data.output.asset.nodeId
                );
                if (alarmNode) {
                    this._canvas.remove(alarmNode);
                    if ('childs' in alarmNode.data) {
                        alarmNode.data.childs.forEach((child: fabric.Object) =>
                            this._canvas.remove(child)
                        );
                    }
                    if (alarmNode.data.dropDownNode) {
                        this._canvas.remove(alarmNode.data.dropDownNode);
                    }

                    this._NodeController.handleDeleteNode(alarmNode.data.asset.nodeId);
                    this.handleDeleteNode(alarmNode.data.asset.nodeId);
                }
            }
        }

        parent.forEachObject((object: fabric.Object) => {
            if (object.type === 'rect') {
                object.set('height', object.getScaledHeight() - GRID_PIXEL * 2);
                return;
            }
        });

        parent.addWithUpdate();
    }

    getAssetAlarmInputs(nodeId: string) {
        return this._alarmInputs.filter((item) => item.ioCircle.data.asset.nodeId === nodeId);
    }

    getEventInputsByNodeId(nodeId: string) {
        return this._eventInputs.filter((item) => item.ioCircle.data.asset.nodeId === nodeId);
    }

    getEventInputs() {
        return this._eventInputs;
    }

    getAlarmConnections() {
        return this._alarmInputs;
    }

    clear() {
        this._inputs = [];
        this._outputs = [];
        this._alarmInputs = [];
        this._eventInputs = [];
    }

    clearAlarmInputById(nodeId: string) {
        this._alarmInputs = this._alarmInputs.filter((item) => {
            if (item.nodeId === nodeId) {
                this._canvas.remove(...item.completeGroup.getObjects());
                return false;
            }
            return true;
        });
    }

    clearEventInputs() {
        this._eventInputs = [];
    }

    clearAlarmInputs() {
        this._alarmInputs = [];
    }
}

export default IOController;
