import React, { useState, useCallback, useEffect } from "react";
import { FormGroup, Label, CustomInput, ModalFooter, Form } from "reactstrap";
import "./style.scss";
import _ from "lodash";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    Input,
    Button,
    Checkbox,
    Dropdown,
    DropdownOption,
    ToggleButtonGroup,
    ToggleButton,
} from "@abb/abb-common-ux-react";
import { StoreState } from "../../../store";
import "./style.scss";
import { getFilterAssetModelTypeRequest } from "../../../store/assets/actions";
import Condition from "../../../components/shared/Condition";
import Loader from "../../../components/Loader";
import Flex from "../../../components/shared/Flex";
import {
    LOADER_SIZE,
    LOADER_TYPE,
} from "../../../utils/constants/appConstants";
import { IFilteredItem } from "../../../store/assets/types";
import CustomABBInput from "../../../components/CustomABBInput";

interface FilterInstanceModalProps {
    onApplyFilter: (filterItems: IFilteredItem[], filter: boolean) => void;
}
export const FilterInstanceModal = (
    props: FilterInstanceModalProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    // State
    const [isName, updateName] = useState<boolean>(false);
    const [isType, updateType] = useState<boolean>(false);
    const [isStatus, updateStatus] = useState<boolean>(false);
    const [isConfigStatus, updateConfigStatus] = useState<boolean>(false);
    const [isAssetModelType, updateAssetModelType] = useState<boolean>(false);

    // Name
    const [name, setName] = useState("");
    const [filterName, setFilterName] = useState({
        key: "name",
        values: [""],
    } as IFilteredItem);
    const updateNameField = (value: any) => {
        updateErrorName(false);
        setName(value);
        setFilterName({ key: "name", values: [value] });
    };
    // Type
    const [selectedType, setType] =
        useState<{ value: string; label: string; isNew: boolean }[]>();
    const [filterType, setFilterType] = useState({} as IFilteredItem);
    const handleTypeChange = useCallback(
        (options: { value: string; label: string; isNew: boolean }[]) => {
            updateErrorType(false);
            setType(options);
            setFilterType({ key: "type", values: options.map((i) => i.value) });
        },
        []
    );

    // Status
    const STATUS = ["OK", "Error", "Inhibited", "No Data"];
    const [selectStatus, setStatusBtn] = useState([0]);
    const [filterStatus, setFilterStatus] = useState({
        key: "monitorStatus",
        values: [STATUS[0]],
    } as IFilteredItem);
    const handleActiveStatus = (status: number[]) => {
        setStatusBtn(status);
        setFilterStatus({ key: "monitorStatus", values: [STATUS[status[0]]] });
    };

    // Configuration Status
    const CONFIG_STATUS = ["Enabled", "Disabled", "Not configured"];
    const [selectConfigStatus, setConfigStatusBtn] = useState([0]);
    const [filterConfigStatus, setFilterConfigStatus] = useState({
        key: "monitoringEnabled",
        values: [CONFIG_STATUS[0]],
    } as IFilteredItem);
    const handleActiveConfigStatus = (configStatus: number[]) => {
        setConfigStatusBtn(configStatus);
        setFilterConfigStatus({
            key: "monitoringEnabled",
            values: [CONFIG_STATUS[configStatus[0]]],
        });
        if (CONFIG_STATUS[configStatus[0]] === "Not configured") {
            updateStatus(false);
            updateAssetModelType(false);
        }
    };

    const getMaskedType = (data: string) => {
        let value = "";
        if (data && data.toLowerCase().includes(".tenantid.")) {
            value = data.split(".").reverse().splice(2).reverse().join(".");
            const version = data
                .split(".")
                [data.split(".").length - 1].split("@");
            if (version.length > 1) {
                value = `${value}@${version[version.length - 1]}`;
            }
        } else {
            value = data;
        }

        const maskedTypeId =
            value && value.toLowerCase().includes("abb.local.")
                ? value.split(".").splice(2).join(".")
                : value;

        return maskedTypeId;
    };
    // Model Type
    const [selectedAssetModelType, setAssetModelType] =
        useState<{ value: string; label: string; isNew: boolean }[]>();
    const [filterAssetModelType, setFilterAssetModelType] = useState(
        {} as IFilteredItem
    );
    const handleAssetModelChange = useCallback(
        (options: { value: string; label: string; isNew: boolean }[]) => {
            debugger;
            updateErrorModelType(false);
            setAssetModelType(options);
            setFilterAssetModelType({
                key: "monitorType",
                values: options.map((i) => i.value),
            });
        },
        []
    );

    // inputField error handling states
    const [errorName, updateErrorName] = useState(false);
    const [errorType, updateErrorType] = useState(false);
    const [errorModelType, updateErrorModelType] = useState(false);

    const applyFilter = () => {
        // Validation
        if (
            name == "" &&
            (_.isEmpty(filterType) ||
                (filterType &&
                    Array.isArray(filterType.values) &&
                    filterType.values.length === 0)) &&
            isAssetModelType &&
            (_.isEmpty(filterAssetModelType) ||
                (filterAssetModelType &&
                    Array.isArray(filterAssetModelType.values) &&
                    filterAssetModelType.values.length === 0))
        ) {
            return;
        }

        // Apply
        let isFilter = false;
        let filterItems: IFilteredItem[] = [];
        if (name !== "") {
            isFilter = true;
            filterItems.push(filterName);
        }
        if (!_.isEmpty(selectedAssetModelType)) {
            isFilter = true;
            filterItems.push(filterAssetModelType);
        }
        if (isStatus) {
            isFilter = true;
            filterItems.push(filterStatus);
        }
        if (isConfigStatus) {
            isFilter = true;
            filterItems.push(filterConfigStatus);
        }
        if (!_.isEmpty(selectedType)) {
            isFilter = true;
            filterItems.push(filterType);
        }
        props.onApplyFilter(filterItems, isFilter);
    };

    const clearFilter = () => {
        // Clear Field Data
        updateNameField("");
        handleTypeChange([{ value: "", label: "", isNew: false }]);
        handleActiveStatus([0]);
        handleActiveConfigStatus([0]);
        handleAssetModelChange([{ value: "", label: "", isNew: false }]);
        setAssetModelType([]);
        setType([]);
        // Clear Checkboxes
        updateName(false);
        updateType(false);
        updateStatus(false);
        updateConfigStatus(false);
        updateAssetModelType(false);
    };

    useEffect(() => {
        // const isNodeExist = props.selctedNodeParentList.filter(i => i === props.selectedParentTreeNodeId);
        props.getFilterAssetModel();
    }, []);

    useEffect(() => {
        if (props.filteredItems.length > 0) {
            console.log(props.filteredItems);

            props.filteredItems.forEach((filteredItem) => {
                if (filteredItem.key == "name") {
                    updateNameField(
                        Array.isArray(filteredItem.values) &&
                            filteredItem.values.length > 0
                            ? filteredItem.values[0]
                            : ""
                    );
                    updateName(true);
                }
                if (filteredItem.key == "type") {
                    let options: {
                        value: string;
                        label: string;
                        isNew: boolean;
                    }[] = filteredItem.values.map((item) => {
                        return {
                            value: item,
                            label: getMaskedType(item),
                            isNew: false,
                        };
                    });
                    updateErrorType(false);
                    setType(options);
                    setFilterType({ key: "type", values: filteredItem.values });
                    updateAssetModelType(true);
                }
                if (filteredItem.key == "monitorStatus") {
                    let item = STATUS.findIndex(
                        (i) => i == filteredItem.values[0]
                    );
                    handleActiveStatus([item]);
                    updateStatus(true);
                }
                if (filteredItem.key == "monitoringEnabled") {
                    let item = CONFIG_STATUS.findIndex(
                        (i) => i == filteredItem.values[0]
                    );
                    handleActiveConfigStatus([item]);
                    updateConfigStatus(true);
                }
                if (filteredItem.key == "monitorType") {
                    let options: {
                        value: string;
                        label: string;
                        isNew: boolean;
                    }[] = filteredItem.values.map((item) => {
                        return {
                            value: item,
                            label: getMaskedType(item),
                            isNew: false,
                        };
                    });
                    updateErrorType(false);
                    setAssetModelType(options);
                    setFilterAssetModelType({
                        key: "monitorType",
                        values: filteredItem.values,
                    });
                    updateType(true);
                }
            });
        }
    }, [props.filteredItems]);

    return (
        <div className="wrapper-create-model">
            <Condition when={props.isFilterLoading}>
                <Flex center>
                    <Loader
                        sizeClass={LOADER_SIZE.SMALL}
                        type={LOADER_TYPE.RADIAL}
                    />
                </Flex>
            </Condition>
            <Condition when={!props.isFilterLoading}>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="form-wrapper">
                    <div className="filter-form">
                        <div className="parent-asset-config-form">
                            <div className="form-section">
                                <FormGroup className="form-lable-input">
                                    <div className="check-form">
                                        <Label></Label>
                                        {/* <Checkbox
                                            id="cbName"
                                            sizeClass="large"
                                            value={isName}
                                            onChange={() => {
                                                updateName(!isName);
                                                updateErrorName(false);
                                            }}
                                        /> */}
                                    </div>
                                    <div className="input-in-form">
                                        <Label for=" Name">Instance Name</Label>
                                        <CustomABBInput
                                            value={name}
                                            onValueChange={(value) => {
                                                updateNameField(value);
                                            }}
                                            type="normal"
                                            dataType="text"
                                            id="filterName"
                                            placeholder="Enter the name of the Asset"
                                            showValidationBarWhenInvalid={true}
                                            showValidationIconWhenInvalid={true}
                                            instantValidation={true}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-lable-input">
                                    <div className="check-form">
                                        <Label></Label>
                                        {/* <Checkbox
                                            id="cbType"
                                            sizeClass="large"
                                            value={isType}
                                            onChange={() => {
                                                updateType(!isType);
                                                updateErrorType(false);
                                            }}
                                        /> */}
                                    </div>
                                    <div className="input-in-form">
                                        <Label for="Model">Model Name</Label>
                                        <Dropdown
                                            // multiselect={true}
                                            searchable={true}
                                            value={selectedAssetModelType}
                                            onChange={handleAssetModelChange}
                                            placeholder="Select Model Type"
                                            required={errorModelType}
                                            disabled={
                                                filterConfigStatus.values
                                                    .length > 0 &&
                                                filterConfigStatus.values.includes(
                                                    "Not configured"
                                                )
                                            }
                                        >
                                            {props.assetModelTypes.map(
                                                (
                                                    value: any,
                                                    strIndex: number
                                                ) => (
                                                    <DropdownOption
                                                        key={"@" + strIndex}
                                                        label={getMaskedType(
                                                            value
                                                        )}
                                                        value={value}
                                                        disabled={false}
                                                    ></DropdownOption>
                                                )
                                            )}
                                        </Dropdown>
                                    </div>
                                </FormGroup>

                                {/* <FormGroup className="custom-form-group">
                                    <Label>
                                    </Label>
                                    <Checkbox
                                        id="cbConfigStatus"
                                        sizeClass="large"
                                        value={isConfigStatus}
                                        onChange={() => updateConfigStatus(!isConfigStatus)}
                                    />
                                </FormGroup>

                                <FormGroup className="custom-form-group">
                                    <Label>
                                    </Label>
                                    <Checkbox
                                        id="sbStatus"
                                        sizeClass="large"
                                        value={isStatus}
                                        disabled={filterConfigStatus.values.length > 0 && filterConfigStatus.values.includes("Not configured")}
                                        onChange={() => updateStatus(!isStatus)}
                                    />
                                </FormGroup> */}

                                <FormGroup className="form-lable-input">
                                    <div className="check-form">
                                        <Label></Label>
                                        {/* <Checkbox
                                            id="cbAssetModelType"
                                            sizeClass="large"
                                            value={isAssetModelType}
                                            disabled={
                                                filterConfigStatus.values.length > 0 &&
                                                filterConfigStatus.values.includes('Not configured')
                                            }
                                            onChange={() => {
                                                updateAssetModelType(!isAssetModelType);
                                                updateErrorModelType(false);
                                            }}
                                        /> */}
                                    </div>
                                    <div className="input-in-form">
                                        <Label for="assetModelType">
                                            Object Type
                                        </Label>
                                        <Dropdown
                                            // multiselect={true}
                                            searchable={true}
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            placeholder="Select Object Type"
                                            required={errorType}
                                        >
                                            {props.assetTypes.map(
                                                (
                                                    value: any,
                                                    strIndex: number
                                                ) => (
                                                    <DropdownOption
                                                        key={"@" + strIndex}
                                                        label={getMaskedType(
                                                            value
                                                        )}
                                                        value={value}
                                                        disabled={false}
                                                    ></DropdownOption>
                                                )
                                            )}
                                            {/* {props.baseModelId.map(
                                                (value: any, strIndex: number) => (
                                                    <DropdownOption
                                                        key={'@' + strIndex}
                                                        label={value.modelName}
                                                        value={value.modelName}
                                                        disabled={false}
                                                    ></DropdownOption>
                                                )
                                            )} */}
                                        </Dropdown>
                                    </div>
                                </FormGroup>

                                {/* <FormGroup className="custom-form-group">
                                    <Label for="configStatus">
                                        Configuration Status
                                    </Label>
                                    <ToggleButtonGroup
                                        className="view-toggle-btn"
                                        size="small"
                                        selected={selectConfigStatus}
                                        onChange={(selectedButton) => {
                                            handleActiveConfigStatus(selectedButton);
                                        }}
                                    >
                                        <ToggleButton id="enabledBtn" text="Enabled" />
                                        <ToggleButton id="disabledBtn" text="Disabled" />
                                        <ToggleButton id="configuredBtn" text="Not configured" />
                                    </ToggleButtonGroup>
                                </FormGroup>

                                <FormGroup className="custom-form-group">
                                    <Label for="Status">
                                        Status
                                    </Label>
                                    <ToggleButtonGroup
                                        className="view-toggle-btn"
                                        size="small"
                                        selected={selectStatus}
                                        onChange={(selectedButton) => {
                                            handleActiveStatus(selectedButton);
                                            // if (selectedBtn[0] !== selectedButton[0]) {
                                            //     props.toggleActiveView();
                                            // }
                                        }}

                                    >
                                        <ToggleButton id="okBtn" text="OK" disabled={filterConfigStatus.values.length > 0 && filterConfigStatus.values.includes("Not configured")} />
                                        <ToggleButton id="errorBtn" text="Error" disabled={filterConfigStatus.values.length > 0 && filterConfigStatus.values.includes("Not configured")} />
                                        <ToggleButton id="inhibitedBtn" text="Inhibited" disabled={filterConfigStatus.values.length > 0 && filterConfigStatus.values.includes("Not configured")} />
                                        <ToggleButton id="noDataBtn" text="No Data" disabled={filterConfigStatus.values.length > 0 && filterConfigStatus.values.includes("Not configured")} />
                                    </ToggleButtonGroup>
                                </FormGroup> */}

                                <ModalFooter>
                                    <div>
                                        {/* <input
                            className="create-btn"
                            onClick={applyFilter}
                            value="Apply"
                        /> */}
                                        <Button
                                            text="Apply"
                                            sizeClass="medium"
                                            shape="rounded"
                                            type="primary-blue"
                                            className="customDeleteButton"
                                            disabled={
                                                name === "" &&
                                                _.isEmpty(
                                                    selectedAssetModelType
                                                ) &&
                                                _.isEmpty(selectedType)
                                            }
                                            style={{ marginLeft: `12px` }}
                                            onClick={(e: any) => applyFilter()}
                                        />
                                        <Button
                                            text="Clear"
                                            sizeClass="medium"
                                            shape="rounded"
                                            type="normal"
                                            className="customDeleteButton"
                                            onClick={() => clearFilter()}
                                        />
                                    </div>
                                </ModalFooter>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Condition>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        assetModelTypes: state.assets.filterQuery.assetModelTypes,
        assetTypes: state.assets.filterQuery.assetTypes,
        isFilterLoading: state.assets.filterQuery.isFilterLoading,
        filteredItems: state.assets.filterQuery.filteredItems,
        applyFilter: state.assets.filterQuery.applyFilter,
        selctedNodeParentList: state.assets.selectedNodeParentList,
        selectedParentTreeNodeId: state.assets.selectedParentTreeNodeId,
        baseModelId:
            state.supportedConditionMonitor.supportedConditionMonitorList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getFilterAssetModel: () => {
            dispatch(getFilterAssetModelTypeRequest());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterInstanceModal);
