import _ from 'lodash';
import { Dispatch } from 'redux';
import classnames from 'classnames';
import { connect } from 'react-redux';

import React, { useState, Fragment } from 'react';

import { StoreState } from '../../../store';
import FunctionLogic from '../FunctionLogic';

import EditHeaderPopup from '../Popups/EditHeaderPopup';
import { AssetsInfo } from '../type';

import { FunctionHeaderTypeDetails, FunctionSaveType } from '../../../store/function/types';
import { FUNCTION_MODE, FUNCTION_SAVE_TYPE } from '../../../utils/constants/appConstants';
import {
    handlePopoverInfo,
    handlePopoverOpen,
    updateFunctionHeaderTypeDetails,
    handleFunctionSave,
    updateAssetInfo,
    handleFunctionDelete,
} from '../../../store/function/action';
import { ShowMessageDispatchProps } from '..';
import TabsComponent from './TabsComponent';
import './style.scss';
import { EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER } from '../constants';

interface CreateEditFunctionProps {
    draggedItem: string;
    handleAPIStatus: (data: boolean) => void;
    handleError?: (value: boolean) => void;
    errorFlag: boolean;
}

const CreateEditFunction = (
    props: CreateEditFunctionProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps> &
        ShowMessageDispatchProps
) => {
    const {
        functionMode,
        createFunctionData,
        handleFunctionDetails,
        functionHeaderDetails,
        errorFlag,
    } = props;
    const { conditionDetails } = functionHeaderDetails;

    const [logicValue, updateLogicValue] = useState({
        condition: '',
        name: '',
        value: '',
    } as any);
    const [showLogicDialog, updateShowLogicDialog] = useState(false);

    classnames('nav-btn', 'nav-btn-active');

    const handleLogicDialog = (condition: any, name: string, value: string) => {
        updateShowLogicDialog(!showLogicDialog);
        updateLogicValue({ condition: condition, name: name, value: value });
    };
    const handleCloseLogicDialog = () => {
        updateShowLogicDialog(!showLogicDialog);
    };

    const handleInputChange = (val: string) => {
        updateLogicValue({
            condition: logicValue.condition,
            name: logicValue.name,
            value: val,
        });
        const subConditionName: string = Object.keys(logicValue.condition)[0];
        let conditionInfo = _.cloneDeepWith(conditionDetails);
        let getObj = conditionInfo[subConditionName]['subConditions'][logicValue.name];
        getObj['logic']['value'] = val.trim();
        handleFunctionDetails({
            ...functionHeaderDetails,
            conditionDetails: conditionInfo,
        });
    };

    const handleSubmitLogicDialog = () => {
        updateShowLogicDialog(!showLogicDialog);
    };

    return (
        <div className="wrapper-function-configuration1">
            <div className="editHeaderPopUp ">
                {functionMode === FUNCTION_MODE.CREATE
                    ? createFunctionData.modelDetails &&
                      props.createFunctionData.modelDetails.name && (
                          <Fragment>
                              <div className="align-center-baseline func-top-bar">
                                  <EditHeaderPopup
                                      errorFlag={errorFlag}
                                      functionSaveType={FUNCTION_SAVE_TYPE.CREATE}
                                      showMessageModal={props.showMessageModal}
                                  />
                              </div>
                          </Fragment>
                      )
                    : createFunctionData.assetsDetails &&
                      createFunctionData.assetsDetails.name && (
                          <Fragment>
                              <div className="align-center-baseline func-top-bar">
                                  <EditHeaderPopup
                                      errorFlag={errorFlag}
                                      functionSaveType={FUNCTION_SAVE_TYPE.UPDATE}
                                      showMessageModal={props.showMessageModal}
                                  />
                              </div>
                          </Fragment>
                      )}
            </div>

            <div className="createNewFunctionSection">
                {functionMode === FUNCTION_MODE.EDIT ? (
                    <div style={{ height: '100%' }} onClick={() => handlePopoverOpen(false)}>
                        {!_.has(props.createFunctionData, 'assetsDetails.properties') ? (
                            <div className="center-div">
                                {EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER}
                            </div>
                        ) : (
                            <TabsComponent
                                showMessageModal={props.showMessageModal}
                                handleError={props.handleError}
                                handleLogicDialog={handleLogicDialog}
                            />
                        )}
                    </div>
                ) : (
                    <TabsComponent
                        showMessageModal={props.showMessageModal}
                        handleError={props.handleError}
                        handleLogicDialog={handleLogicDialog}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        isloading: state.functions.isLoading,
        functionTypeList: state.modelsPage.functionTypeList,
        createFunctionData: state.functions.currentActiveView,
        isPopoverInfoOpen: state.functions.isPopoverInfoOpen,
        isPopoverOpen: state.functions.isPopoverOpen,
        functionHeaderDetails: state.functions.functionHeaderDetails,
        assetsInfo: state.functions.assetsInfo,
        functionMode: state.functions.functionMode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleFunctionDelete: (payload: {
            modelId: string;
            typeId: string;
            functionName: string;
            tags: string[];
        }) => {
            dispatch(handleFunctionDelete(payload));
        },
        handlePopoverInfo: (isOpen: boolean) => {
            dispatch(handlePopoverInfo(isOpen));
        },
        handlePopoverOpen: (isOpen: boolean) => {
            dispatch(handlePopoverOpen(isOpen));
        },
        handleFunctionDetails: (payload: FunctionHeaderTypeDetails) => {
            dispatch(updateFunctionHeaderTypeDetails(payload));
        },
        handleFunctionSave: (saveType: FunctionSaveType) => {
            dispatch(handleFunctionSave(saveType));
        },
        handleAssetsInfo: (payload: { assetsInfo: AssetsInfo }) => {
            dispatch(updateAssetInfo(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEditFunction);
