import {
    Actions,
    InstanceConfigState,
    ACTION_MENU_LIST,
    SelectedTypes,
    ConfigFormFields,
    ActiveTypeParams,
    TableDataById,
} from './types';
import { ActionTypes } from '../actionTypes';
import { updateCanvasJson } from '../modelsPage/reducer';
import _ from 'lodash';

import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { PAGE_ENTRY_DROP_DOWN_ACTION } from '../../routes/Assets/PageBody/AssetInstanceBody/ActionBar/fakeData';
import { VIEW_MODE, CONFIGURATION_STATUS } from '../../utils/constants/appConstants';
import { pathFinder } from '../../transformers/utils/outputFinder';
import {
    isAssetInstanceNotConfigured,
    updateJsonWithOriginalFunctionDetails,
} from '../../utils/helpers';
import { getMonitorType } from '../../routes/Assets/PageBody/AssetInstanceBody/AssetTableView';
import { CheckedAllItems } from './actions';

const blankComputeModelFromJson = new ComputeModelFromJson();

const initialState: InstanceConfigState = {
    computeModels: { matching: [], all: [], activeTab: 'All' },
    isLoading: false,
    isComputeModelLoading: false,
    isSaving: false,
    isupdateing: false,

    activeModel: {
        modelInstance: {
            ...blankComputeModelFromJson,
        },
        id: '',
        isDirty: false,
        isNew: true,
    },

    instancesTable: {
        includeChildElements: false,
        tableData: {
            byId: {} as TableDataById,
            entities: [],
            selectedInstances: [],
        },
        filterData: { entities: [] },
        pagination: {
            activePage: 1,
            enteriesPerPage: PAGE_ENTRY_DROP_DOWN_ACTION[0].value,
            totalRecords: 0,
        },
        filters: {},
        sorting: { sortBy: '', order: 'ASC' },
        actionMenuList: getMenuList({
            byId: {} as TableDataById,
            entities: [],
            selectedInstances: [],
        }),
    },
    selectedMenuItem: undefined,
    assetCriticalityModels: [],
    selectedCriticalityModel: undefined,
    instanceMonitoringList: [],
    supportedTypes: [],
    selectedTypes: {},
    isInstanceConfigured: false,
    configFormfields: { name: '', tags: [], description: '' },
    activeTypeParams: { modelId: '', typeId: '', version: '', objectId: '' },
    assetConfigLoading: false,
    segregatedModelsLoading: false,
    originalTypeData: undefined,
    originalFunctionLoading: false,
    gettingLibraryReferences: false,
    buttonLodaing: false,
    newModelSaveAvailable: '',
    isCheckeditems: true,
    isCheckeditemupdate: true,
    selectedTypesUpdate: [],
    checkedAllItems: [],
    clickedActiveParams: [],

    // CheckBoxAdd:[],
};

export const instanceConfigReducer = (
    state = initialState,
    action: Actions
): InstanceConfigState => {
    switch (action.type) {
        case ActionTypes.GET_ALL_COMPUTE_MODELS_STORE: {
            const { computeModels } = action.payload;
            // computeModels.forEach((computeModel: ComputeModelFromJson) => {
            //     state.computeModels.byId[
            //         computeModel.objectId
            //     ] = computeModel as ComputeModelRedux;

            //     state.computeModels.entities.push(computeModel.objectId);
            // });

            state.computeModels.all = computeModels;
            state.isComputeModelLoading = false;
            return { ...state };
        }

        case ActionTypes.GET_ALL_COMPUTE_MODELS_API: {
            state.isComputeModelLoading = true;
            return { ...state, segregatedModelsLoading: true };
        }

        case ActionTypes.GET_ALL_COMPUTE_MODELS: {
            state.isLoading = true;
            return { ...state };
        }

        case ActionTypes.GET_MATCHING_COMPUTE_MODELS_STORE: {
            const { computeMatchingModels } = action.payload;
            state.computeModels.matching = computeMatchingModels;
            state.isComputeModelLoading = false;
            return { ...state };
        }

        case ActionTypes.GET_MATCHING_COMPUTE_MODELS_API: {
            state.isComputeModelLoading = true;
            return { ...state };
        }

        case ActionTypes.CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG: {
            const { modelId } = action.payload;
            let modelInstance =
                state.computeModels.all.find((model) => model.objectId === modelId) ||
                state.computeModels.matching.find((model) => model.objectId === modelId);

            // console.log("modelInstance :", modelInstance);
            //

            if (modelInstance) {
                modelInstance = {
                    ...modelInstance,
                    modelDetails: {
                        ...state.activeModel.modelInstance.modelDetails,
                        model: modelInstance.modelDetails.model,
                        typeId: modelInstance.modelDetails.typeId,
                        version: modelInstance.modelDetails.version,
                    },
                };

                state.activeModel = {
                    id: modelId,
                    modelInstance: { ...modelInstance },
                    isDirty: false,
                    isNew: true,
                };

                state.isLoading = false;
                state.isSaving = false;
            }

            return { ...state };
        }

        // case ActionTypes.TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG: {
        //     const activeModel = state.activeModel;
        //     // activeModel.modelInstance is reference to computeModels byId object
        //     // activeModel.modelInstance.json = _.cloneDeep(action.payload.canvasJson);
        //     activeModel.modelInstance.json = _.cloneDeep(
        //         action.payload.canvasJson
        //     );
        //     activeModel.modelInstance.localJSON = _.cloneDeep(
        //         action.payload.canvasJson
        //     );
        //     //@ts-ignore
        //     activeModel.modelInstance.tableData =
        //         action.payload.transformInstance;
        //     state.activeModel = { ...activeModel };

        //     return { ...state };
        // }

        case ActionTypes.ADD_TO_SELECTED_INSTANCE: {
            const { objectIds } = action.payload;

            objectIds.forEach((objectId: any) => {
                if (!state.instancesTable.tableData.selectedInstances.includes(objectId)) {
                    state.instancesTable.tableData.selectedInstances.push(objectId);
                }
            });

            state.instancesTable.tableData.selectedInstances = [
                ...state.instancesTable.tableData.selectedInstances,
            ];

            const menuList: typeof ACTION_MENU_LIST = getMenuList(state.instancesTable.tableData);
            state.instancesTable.actionMenuList = [...menuList];

            const firstSelectedInstance = state.instancesTable.tableData.selectedInstances[0];
            const assetInstance = state.instancesTable.tableData.byId[firstSelectedInstance];

            const isInstanceConfigured = !isAssetInstanceNotConfigured(
                assetInstance.amInfo[0].configurationStatus
            );

            return { ...state, isInstanceConfigured };
        }

        case ActionTypes.REMOVE_FROM_SELECTED_INSTANCE: {
            state.instancesTable.tableData.selectedInstances =
                state.instancesTable.tableData.selectedInstances.filter(
                    (id) => !action.payload.objectIds.includes(id)
                );

            let isInstanceConfigured: boolean = false;
            const menuList: typeof ACTION_MENU_LIST = getMenuList(state.instancesTable.tableData);

            const firstSelectedInstance = state.instancesTable.tableData.selectedInstances[0];
            state.instancesTable.actionMenuList = [...menuList];
            const assetInstance = state.instancesTable.tableData.byId[firstSelectedInstance];

            if (assetInstance) {
                isInstanceConfigured = !isAssetInstanceNotConfigured(
                    assetInstance.amInfo[0].configurationStatus
                );
            }
            return { ...state, isInstanceConfigured };
        }

        case ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST: {
            const activePage = action.payload.activePage && action.payload.activePage > 1;
            const tableData: typeof initialState['instancesTable']['tableData'] = {
                byId: {} as TableDataById,
                entities: [],
                selectedInstances: [],
            };

            const filterData = { entities: [] };

            state.instancesTable = {
                ...state.instancesTable,
                tableData,
                filterData,
                pagination: activePage
                    ? state.instancesTable.pagination
                    : {
                          ...state.instancesTable.pagination,
                          activePage: 1,
                          totalRecords: 0,
                      },
            };

            return { ...state };
        }

        case ActionTypes.GET_ASSET_TABLE_DATA_STORE:
        case ActionTypes.GET_ASSET_INSTANCE_LIST_SUCCESS: {
            const tableData: typeof initialState['instancesTable']['tableData'] = {
                byId: {} as TableDataById,
                entities: [],
                selectedInstances: [],
            };

            if (action.payload && action.payload.data) {
                console.log(action.payload);
                action.payload.data.forEach((data: any) => {
                    if (data.objectId) {
                        tableData.byId[data.objectId] = data;
                        tableData.entities.push(data.objectId);
                    }
                });
            }

            const instancesTable = {
                ...state.instancesTable,
                tableData,
                filterData: {
                    entities: [...tableData.entities],
                },
                sorting: { sortBy: '', order: 'ASC' as 'ASC' },
                filters: {},
                pagination: {
                    ...state.instancesTable.pagination,
                    activePage:
                        action.payload && action.payload.activePage
                            ? action.payload.activePage
                            : state.instancesTable.pagination.activePage,
                    totalRecords:
                        action.payload && action.payload.count
                            ? Math.ceil(
                                  action.payload.count /
                                      state.instancesTable.pagination.enteriesPerPage
                              )
                            : 0,
                },
                actionMenuList: getMenuList({
                    byId: {} as TableDataById,
                    entities: [],
                    selectedInstances: [],
                }),
            };

            return { ...state, instancesTable };
        }

        case ActionTypes.UPDATE_ACTIVE_TAB_INSTANCE_CONFIG: {
            const activeTab = action.payload.tab;
            const computeModels = { ...state.computeModels, activeTab };

            return { ...state, computeModels };
        }

        case ActionTypes.CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG: {
            const activePage = action.payload.pageNumber;
            state.instancesTable.pagination = {
                ...state.instancesTable.pagination,
                activePage,
            };
            return { ...state };
        }
        case ActionTypes.CHANGE_ACTIVE_PAGE_INSTANCE_UPDATE: {
            state.isLoading = true;
            return {
                ...state,
            };
        }
        case ActionTypes.CHANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG: {
            state.instancesTable.pagination = {
                ...state.instancesTable.pagination,
                activePage: 1,
                enteriesPerPage: action.payload.enteriesPerPage,
            };

            return { ...state };
        }

        case ActionTypes.HANLDE_FILTER_ASSET_TABLE_DATA: {
            const { field, value } = action.payload;
            let filters = { ...state.instancesTable.filters };

            const filter = filters[field];
            if (filter) {
                if (value) {
                    filter.value = value;
                } else {
                    delete filters[field];
                }
            } else {
                filters[field] = { value };
            }
            const filterData: string[] = [];
            state.instancesTable.tableData.entities.map((id) => {
                const obj = state.instancesTable.tableData.byId[id];
                let flag = true;

                Object.keys(filters).forEach((path) => {
                    if(path.includes('amInfo')) {
                        const filterToCheck = path.split('.')[1];
                        let infoFlag = false;
                        obj.amInfo && obj.amInfo.forEach((info,index) => {
                            const objectValue = _.get(obj, `amInfo[${index}].${filterToCheck}`, '') || '';
                            const custom_type = getMonitorType(objectValue);
                            if (custom_type.toLowerCase().includes(filters[path].value.toLowerCase())) {
                                infoFlag = true;
                            }
                        })
                        if(!infoFlag) {
                            flag = false;
                        }
                    } else {const objectValue = _.get(obj, path, '') || '';
                    const filterValue = filters[path].value.toLowerCase();
                    const custom_type = getMonitorType(objectValue);
                    if (flag && !custom_type.toLowerCase().includes(filterValue)) {
                        flag = false;
                    }
                }
                    
                     
                });
                if (flag) {
                    filterData.push(id);
                }
            });

            // Object.keys(obj).forEach(key => {
            //     console.log(obj[key], key);

            // This is done for configurationStatus, status and assetModelType as they are nested inside
            // if (obj[key] && typeof obj[key] === "object") {
            //     Object.keys(obj[key]).forEach(secondLevelKey => {
            //         if (
            //             flag &&
            //             !(obj[key][secondLevelKey] + "")
            //                 .toLowerCase()
            //                 .includes(
            //                     filters[secondLevelKey]
            //                         ? filters[
            //                               secondLevelKey
            //                           ].value.toLowerCase()
            //                         : ""
            //                 )
            //         ) {
            //             flag = false;
            //         }
            //     });
            // } else {
            //             if (
            //                 flag &&
            //                 !(obj[key] + "")
            //                     .toLowerCase()
            //                     .includes(
            //                         filters[key]
            //                             ? filters[key].value.toLowerCase()
            //                             : ""
            //                     )
            //             ) {
            //                 flag = false;
            //             }
            //         // }
            //     });

            //     if (flag) {
            //         filterData.push(id);
            //     }
            // });

            // state.instancesTable.pagination.activePage = 1;
            state.instancesTable.filterData.entities = [...filterData];
            state.instancesTable.filters = filters;

            if (state.instancesTable.sorting.sortBy) {
                state.instancesTable.filterData.entities = sortData({
                    tableData: state.instancesTable.tableData.byId,
                    filterEntities: filterData,
                    sorting: state.instancesTable.sorting,
                });
            }
            return { ...state };
        }

        case ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_UPDATE: {
            debugger;
            state.isupdateing = true;
            var modelId: any;
            let modelInstance =
                state.computeModels.all.find((model) => model.objectId === modelId) ||
                state.computeModels.matching.find((model) => model.objectId === modelId);
            if (modelInstance) {
                modelInstance = {
                    ...modelInstance,
                    modelDetails: {
                        ...state.activeModel.modelInstance.modelDetails,
                        model: modelInstance.modelDetails.model,
                        typeId: modelInstance.modelDetails.typeId,
                        version: modelInstance.modelDetails.version,
                    },
                };

                state.activeModel = {
                    id: modelId,
                    modelInstance: { ...modelInstance },
                    isDirty: false,
                    isNew: true,
                };

                state.isLoading = false;
                state.isSaving = false;
            }
            return { ...state };
        }

        case ActionTypes.VIEW_SAVE_INSTANCE_COMPUTE_MODEL_UPDATE: {
            state.isupdateing = true;
            var modelId: any;
            let modelInstance =
                state.computeModels.all.find((model) => model.objectId === modelId) ||
                state.computeModels.matching.find((model) => model.objectId === modelId);
            if (modelInstance) {
                modelInstance = {
                    ...modelInstance,
                    modelDetails: {
                        ...state.activeModel.modelInstance.modelDetails,
                        model: modelInstance.modelDetails.model,
                        typeId: modelInstance.modelDetails.typeId,
                        version: modelInstance.modelDetails.version,
                    },
                };

                state.activeModel = {
                    id: modelId,
                    modelInstance: { ...modelInstance },
                    isDirty: false,
                    isNew: true,
                };

                state.isLoading = false;
                state.isSaving = false;
            }
            return { ...state };
        }
        case ActionTypes.HANLDE_SORT_ASSET_TABLE_DATA: {
            const { sortBy } = action.payload;
            console.log('sortBy :', sortBy);
            const currentSorting = state.instancesTable.sorting;

            if (currentSorting.sortBy === sortBy) {
                if (currentSorting.order === 'ASC') {
                    state.instancesTable.sorting = { sortBy, order: 'DESC' };
                } else {
                    state.instancesTable.sorting = { sortBy: '', order: 'ASC' };
                }
            } else {
                state.instancesTable.sorting = { sortBy, order: 'ASC' };
            }

            if (state.instancesTable.sorting.sortBy) {
                state.instancesTable.filterData.entities = sortData({
                    tableData: state.instancesTable.tableData.byId,
                    filterEntities: state.instancesTable.filterData.entities,
                    sorting: state.instancesTable.sorting,
                });
            }

            state.instancesTable.pagination.activePage = 1;
            return { ...state };
        }

        case ActionTypes.HANDLE_CONFIGURED_INSTANCE_JSON: {
            const computeModel = action.payload.computeModelFromJson;
            const isNew = action.payload.isNew;
            state.isLoading = false;
            state.isSaving = false;
            state.activeModel = {
                id: computeModel.objectId,
                isDirty: false,
                isNew: isNew ? isNew : state.isInstanceConfigured ? false : true,

                modelInstance: {
                    ...computeModel,
                },
            };

            return { ...state };
        }
        case ActionTypes.UPDATE_SELECTED_SUPPORTED_TYPE_MODEl: {
            debugger;
            const selectedTypesUpdate = action.payload;
            state.clickedActiveParams = [];
            // const selectedTypesKeys = Object.keys(selectedTypes);
            selectedTypesUpdate.map((element: any) => {
                if (element.modelId) {
                    element.selectedInstance.forEach((instance: string) => {
                        state.clickedActiveParams.push({
                            model: element.modelId,
                            typeId: element.typeId,
                            version: element.version,
                            ObjectId: element.objectId,
                            selectedInstance: instance,

                            //})
                        });
                    });
                    // if (state.isInstanceConfigured || selectedTypesUpdate.modelId.length === 1) {
                }
            });

            return {
                ...state,
                selectedTypesUpdate,
            };
        }

        case ActionTypes.HANDLE_INSTANCE_TYPE_ORIGINAL_FUNC_LOADING: {
            const isLoading = action.payload;
            return {
                ...state,
                originalFunctionLoading: isLoading,
            };
        }

        case ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL: {
            state.isSaving = true;
            return { ...state };
        }
        case ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE: {
            state.isSaving = true;
            return { ...state };
        }

        case ActionTypes.HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE: {
            const activeModel = { ...state.activeModel };
            activeModel.id = '';
            state.isLoading = false;
            state.isSaving = false;
            activeModel.isNew = true;
            activeModel.modelInstance = {
                ...blankComputeModelFromJson,
            };

            return { ...state, activeModel };
        }

        case ActionTypes.HANDLE_LOADING_CONFIGURED_INSTANCE: {
            state.isLoading = true;
            const activeModel = state.activeModel;
            activeModel.id = '';
            activeModel.isNew = false;
            //@ts-ignore
            activeModel.modelInstance = { ...blankComputeModelFromJson };

            return {
                ...state,
                activeModel,
            };
        }

        case ActionTypes.SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS: {
            state.isSaving = false;
            const activeModel = { ...state.activeModel };
            activeModel.isNew = false;
            return { ...state, activeModel };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS: {
            state.isLoading = false;
            state.activeModel = {
                id: '',
                isDirty: false,
                isNew: true,
                modelInstance: { ...blankComputeModelFromJson },
            };

            return { ...state };
        }

        case ActionTypes.SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR: {
            state.isSaving = false;
            return { ...state, isLoading: false };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR: {
            state.isLoading = false;
            return { ...state };
        }

        case ActionTypes.DELETE_INSTANCE_COMPUTE_MODEL: {
            state.isLoading = true;
            return { ...state };
        }

        case ActionTypes.UPDATE_ASSET_CONFIG_MODEL: {
            const modelDetail = action.payload.updateAssetConfigModel;
            const activeModel = state.activeModel;

            delete modelDetail.typeId;

            const activeModelDetails = activeModel.modelInstance.modelDetails;

            activeModel.modelInstance.modelDetails = {
                ...activeModelDetails,
                ...modelDetail,
            };

            return { ...state };
        }

        case ActionTypes.CLEAR_ASSET_CONFIG_MODEL_DETAILS: {
            const modelDetail = state.activeModel.modelInstance;
            state.activeModel.modelInstance.modelDetails = {
                ...blankComputeModelFromJson.modelDetails,
            };

            return { ...state };
        }

        case ActionTypes.HANDLE_INCLUDE_ALL_CHILD_ELEMENTS: {
            const { includeAllChild } = action.payload;
            state.instancesTable.includeChildElements = !!includeAllChild;
            return { ...state };
        }

        case ActionTypes.HANDLE_TABLE_TOTAL_RECORDS: {
            const { totalRecords } = action.payload;
            state.instancesTable.pagination.totalRecords = totalRecords;

            return { ...state };
        }

        case ActionTypes.UPDATE_SELECTED_MENU_ITEM: {
            const selectedMenuItem = action.payload;

            return {
                ...state,
                selectedMenuItem,
            };
        }
        case ActionTypes.CHECKED_LISTOF_ITEMS: {
            const isCheckeditems = action.payload;
            debugger;
            return {
                ...state,
                isCheckeditems,
            };
        }
        case ActionTypes.CHECKED_LISTOF_ITEM_UPDATE: {
            const isCheckeditemupdate = action.payload;
            return {
                ...state,
                isCheckeditemupdate,
            };
        }

        case ActionTypes.CHECKED_ALL_ITEMS: {
            const checkedAllItems = action.payload;
            return {
                ...state,
                checkedAllItems,
            };
        }
        case ActionTypes.UPDATE_ASSET_CRITICALITY_MODELS: {
            const assetCriticalityModels = action.payload;
            return {
                ...state,
                assetCriticalityModels,
            };
        }

        case ActionTypes.UPDATE_SELECTED_CRITICALITY_MODEL: {
            const selectedCriticalityModel = action.payload;
            return {
                ...state,
                selectedCriticalityModel,
            };
        }

        case ActionTypes.UPDATE_INSTANCE_MONITORING_LIST: {
            const instanceMonitoringList = _.cloneDeepWith(action.payload);
            return {
                ...state,
                instanceMonitoringList,
            };
        }

        case ActionTypes.HANDLE_CONFIGURE_BUTTON_CLICK: {
            return {
                ...state,
                selectedCriticalityModel: undefined,
            };
        }

        case ActionTypes.UPDATE_SUPPORTED_TYPES: {
            const supportedTypes = action.payload;

            return {
                ...state,
                supportedTypes,
                segregatedModelsLoading: false,
            };
        }

        case ActionTypes.UPDATE_SELECTED_SUPPORTED_TYPE: {
            debugger;
            const selectedTypes = action.payload;
            const selectedTypesKeys = Object.keys(selectedTypes);
            if (selectedTypesKeys.length > 0) {
                if (state.isInstanceConfigured || selectedTypesKeys.length === 1) {
                    state.activeTypeParams = {
                        modelId: selectedTypesKeys[0],
                        typeId: selectedTypes[selectedTypesKeys[0]].selectedType,
                        version: selectedTypes[selectedTypesKeys[0]].version,
                        objectId: selectedTypes[selectedTypesKeys[0]].objectId,
                    };
                }
            }

            return {
                ...state,
                selectedTypes,
            };
        }

        case ActionTypes.GET_CONFIGURED_INSTANCE_JSON: {
            const { assetMonitorModelId, objectId } = action.payload;
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.HANDLE_INSTANCE_CONFIG_LOADING: {
            const isLoading = action.payload;
            return {
                ...state,
                isLoading,
            };
        }
        case ActionTypes.NEW_MODEL_SAVE_AVAILABLE: {
            const newModelSaveAvailable = action.payload;
            debugger;
            return { ...state, newModelSaveAvailable };
        }

        case ActionTypes.UPDATE_SELECTED_TYPE_JSON: {
            const { json } = action.payload;
            let modelInstance: ComputeModelFromJson = {
                ...json,
                modelDetails: {
                    ...state.activeModel.modelInstance.modelDetails,
                    model: json.modelDetails.model,
                    typeId: json.modelDetails.typeId,
                    version: json.modelDetails.version,
                },
            };

            state.activeModel = {
                id: json.objectId,
                modelInstance: { ...modelInstance },
                isDirty: false,
                isNew: true,
            };

            state.isLoading = false;
            state.isSaving = false;
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_ASSET_CONFIG_MODAL_FIELDS: {
            const { name, tags, description } = action.payload;
            const configFormfields: ConfigFormFields = {
                name,
                tags,
                description,
            };
            return {
                ...state,
                configFormfields,
            };
        }

        case ActionTypes.UPDATE_ACTIVE_MODEL_TYPE_PARAMS: {
            const { modelId, typeId, version, objectId } = action.payload;
            const activeTypeParams: ActiveTypeParams = {
                modelId,
                typeId,
                version,
                objectId,
            };
            return {
                ...state,
                activeTypeParams,
            };
        }

        case ActionTypes.UPDATE_ASSET_CONFIG_LOADING: {
            const assetConfigLoading = action.payload;
            return {
                ...state,
                assetConfigLoading,
                isLoading: true,
            };
        }
        case ActionTypes.UPDATE_ASSET_BUTTON_LOADING: {
            const buttonLodaing = action.payload;
            return {
                ...state,
                buttonLodaing,
            };
        }

        case ActionTypes.UPDATE_ORIGINAL_TYPE_JSON: {
            const { json } = action.payload;
            return {
                ...state,
                originalTypeData: json,
            };
        }

        case ActionTypes.UPDATE_INSTANCE_TYPE_JSON_CONDITION_DETAILS: {
            const { json, originalFunctionUsed } = action.payload;
            const udpatedJson = updateJsonWithOriginalFunctionDetails({
                json,
                originalFunctionUsed,
            });
            // updated conditon data fetched from original function types.
            state.activeModel.modelInstance.json = { ...udpatedJson };
            return {
                ...state,
                originalFunctionLoading: false,
            };
        }
        case ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_REQUEST: {
            return { ...state, gettingLibraryReferences: true };
        }
        case ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_SUCCESS: {
            return { ...state, gettingLibraryReferences: false };
        }
        case ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_FAILURE: {
            return { ...state, gettingLibraryReferences: false };
        }

        default: {
            return state;
        }
    }
};

function sortData(options: {
    tableData: InstanceConfigState['instancesTable']['tableData']['byId'];
    filterEntities: string[];
    sorting: InstanceConfigState['instancesTable']['sorting'];
}) {
    const { tableData, filterEntities, sorting } = options;
    console.log(' sort tableData :', tableData);
    console.log('sortBy :', sorting);
    console.log('data :', Object.keys(tableData));

    if (!sorting.sortBy) {
        return Object.keys(tableData);
    }
    let tempObjectKey: string;
    const tableDataKeys = Object.keys(tableData);
    let path: string[] = [];
    let finalPath: string = '';

    if (tableDataKeys.length > 0) {
        tempObjectKey = tableDataKeys[0];
        path = pathFinder(tableData[tempObjectKey], sorting.sortBy);
        finalPath = path.join();
        finalPath = finalPath.slice(1, finalPath.length);
    }
    console.log('finalPath :', [finalPath, finalPath.length]);
    return filterEntities.sort((a, b) => {
        console.log('[a,b] =', [a, b]);
        console.log('table[a]', tableData[a]);

        console.log(path);
        const valueA = _.get(
            tableData[a],
            finalPath + '.' + sorting.sortBy,
            tableData[a][sorting.sortBy]
        );
        const valueB = _.get(
            tableData[b],
            finalPath + '.' + sorting.sortBy,
            tableData[b][sorting.sortBy]
        );
        console.log('sort values :', [valueA, valueB]);
        if (sorting.order === 'ASC') {
            if (isNaN(valueA)) {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA - valueB;
            }
        } else {
            if (isNaN(valueA)) {
                return valueB > valueA ? 1 : -1;
            } else {
                return valueB - valueA;
            }
        }
    });
}

// for setting menuList according to configuration status .

function getMenuList(tableData: typeof initialState.instancesTable.tableData) {
    let menuList: typeof ACTION_MENU_LIST = _.cloneDeep(ACTION_MENU_LIST);
    const { selectedInstances } = tableData;
    let showConfigure = true;
    let hasConfigured = false;

    if (selectedInstances.length === 0) {
        console.log(selectedInstances.length);
        return menuList.map((item) => {
            item.isDisabled = true;
            return item;
        });
    }
    let configurationStatus: string[] = [];
    selectedInstances.forEach((instanceId) => {
        tableData.byId[instanceId].amInfo.forEach((item) =>
            configurationStatus.push(item.configurationStatus)
        );
    });

    if (configurationStatus.indexOf(CONFIGURATION_STATUS.ENABLED) === -1) {
        menuList[2].isDisabled = true;
    }
    if (configurationStatus.indexOf(CONFIGURATION_STATUS.DISABLED) === -1) {
        menuList[1].isDisabled = true;
    }
    if (
        configurationStatus.indexOf(CONFIGURATION_STATUS.ENABLED) === -1 &&
        configurationStatus.indexOf(CONFIGURATION_STATUS.DISABLED) === -1
    ) {
        menuList[3].isDisabled = true;
    }
    if (selectedInstances.length > 1) {
        if (configurationStatus.indexOf(CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED) === -1) {
            menuList[0].isDisabled = true;
        }
    }
    if (
        configurationStatus.indexOf(CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED) !== -1 &&
        (configurationStatus.indexOf(CONFIGURATION_STATUS.ENABLED) !== -1 ||
            configurationStatus.indexOf(CONFIGURATION_STATUS.DISABLED) !== -1)
    ) {
        menuList[0].isDisabled = true;
        menuList[1].isDisabled = true;
        menuList[2].isDisabled = true;
        menuList[3].isDisabled = true;
    }
    // selectedInstances.forEach((instanceId) => {
    //     const configurationStatus =
    //         tableData.byId[instanceId].amInfo[0].configurationStatus;

    //     if (configurationStatus === CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED) {
    //         // it can be done with find also .
    //         menuList = menuList.map((item, index) => {
    //             if (item.id !== 1) item.isDisabled = true;

    //             return item;
    //         });
    //     } else if (configurationStatus === CONFIGURATION_STATUS.ENABLED) {
    //         hasConfigured = true;
    //         menuList = menuList.map((item, index) => {
    //             if (item.id === 3) item.isDisabled = true;
    //             return item;
    //         });
    //     } else if (configurationStatus === CONFIGURATION_STATUS.DISABLED) {
    //         hasConfigured = true;
    //         menuList = menuList.map((item, index) => {
    //             if (item.id === 2) item.isDisabled = true;
    //             return item;
    //         });
    //     }

    //     if (selectedInstances.length > 1 && showConfigure && hasConfigured) {
    //         showConfigure = false;
    //         menuList = menuList.map((item, index) => {
    //             if (item.id === 1) item.isDisabled = true;
    //             return item;
    //         });
    //     }
    // });

    return menuList;
}
