import * as React from "react";

let logOutTimeOut: any;
interface timeoutHandlerProps {
    onLogout: () => void;
}
const TimeOutHandler = (props: timeoutHandlerProps) => {
    console.log("timeout handler");
    const signOutTime = 1000 * 60 * 60;
    React.useEffect(() => {
        const eventsList = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress",
            "keydown",
        ];
        eventsList.map((event) => {
            document.addEventListener(event, resetTimeout);
        });
        startTimeOut();
        return () => {
            eventsList.map((event) => {
                document.removeEventListener(event, resetTimeout);
                clearTimeout(logOutTimeOut);
            });
        };
    }, []);
    const startTimeOut = () => {
        logOutTimeOut = setTimeout(logOut, signOutTime);
    };
    const resetTimeout = () => {
        clearTimeout(logOutTimeOut);
        startTimeOut();
    };
    const logOut = () => {
        console.log("timeout execute");
        props.onLogout();
    };
    return <div />;
};

export default TimeOutHandler;
