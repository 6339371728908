import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import ReactSelect from "react-select";
import { Button, Input } from "@abb/abb-common-ux-react";

import TimeWindowInput from "./TimeWindowInput";
import { customReactSelectStyles } from "../../../../utils/helpers";
import {
    // checkForValidDurationValue,
    compareDurationForSamplingAndTimeWindow,
    convertTimeToDHMS,
    convertToDurationTypeFormat,
    // getValidTimeInputs,
    // isEquivalent,
    // isValidHistoryTimeInput,
    // updateTimeWindow,
} from "../../UIHelpers/historyHelpers";
import { SamplingAggregateValues } from "../../../../utils/constants/appConstants";
import { DurationType, HISTORY_CONFIG } from "./types";
import CustomABBInput from "../../../CustomABBInput";

interface HistoryConfigurationProps {
    primaryButtonAction: (historyData: HistoryDataFormValues) => void;
    handleClose: () => void;
    timeWindow?: string;
    samplingAggregate?: any;
    samplingWindow?: string;
    limit?: string;
}
export interface HistoryDataFormValues {
    timeWindowState: string;
    sampleWindowState?: string;
    sampleAggregate?: SamplingAggregateValues;
    limit?: string;
}

export interface sampleAggregateDropDown {
    label: SamplingAggregateValues;
    value: SamplingAggregateValues;
}
const HistoryConfiguration = (props: HistoryConfigurationProps) => {
    const { handleClose } = props;
    const [formValues, setFormValues] = useState({
        timeWindowState: {
            value: "",
            type: DurationType.Seconds,
        },
        sampleWindowState: {
            value: "",
            type: DurationType.Seconds,
        },
        sampleAggregate: props.samplingAggregate
            ? ({
                  label: props.samplingAggregate,
                  value: props.samplingAggregate,
              } as sampleAggregateDropDown)
            : ({
                  label: SamplingAggregateValues.Raw,
                  value: SamplingAggregateValues.Raw,
              } as sampleAggregateDropDown),
        limit: props.limit || "",
    });
    const [submitErrors, setSubmitErrors] = useState({
        timeWindow: "",
        sampleWindow: "",
        sampleAggregate: "",
        limit: "",
    });
    const [timeWindowError, setTimeWindowError] = useState(false);
    const [samplingWindowError, setSamplingWindowError] = useState(false);

    const resetSubmitErrors = () => {
        setSubmitErrors({
            ...submitErrors,
            timeWindow: "",
            sampleWindow: "",
            sampleAggregate: "",
        });
    };

    useEffect(() => {
        if (props.timeWindow) {
            setFormValues({
                ...formValues,
                timeWindowState: convertToDurationTypeFormat(props.timeWindow),
            });
        }
    }, [props.timeWindow]);

    useEffect(() => {
        if (props.samplingWindow && props.timeWindow) {
            setFormValues({
                ...formValues,
                timeWindowState: convertToDurationTypeFormat(props.timeWindow),
                sampleWindowState: convertToDurationTypeFormat(
                    props.samplingWindow
                ),
            });
        }
    }, [props.samplingWindow, props.timeWindow]);

    const handleHistorySubmit = () => {
        let finalValues = {
            timeWindowState: convertTimeToDHMS(
                formValues.timeWindowState.value,
                formValues.timeWindowState.type
            ),
            sampleWindowState: convertTimeToDHMS(
                formValues.sampleWindowState.value,
                formValues.sampleWindowState.type
            ),
            sampleAggregate:
                formValues.sampleAggregate.label === SamplingAggregateValues.Raw
                    ? ("" as SamplingAggregateValues)
                    : (formValues.sampleAggregate
                          .label as SamplingAggregateValues),
            limit: formValues.limit,
        };
        const { timeWindowState, sampleWindowState, sampleAggregate, limit } =
            finalValues;

        if (!timeWindowError && !samplingWindowError) {
            if (
                (sampleWindowState || sampleAggregate || limit) &&
                !timeWindowState
            ) {
                setSubmitErrors({
                    ...submitErrors,
                    timeWindow: "Time Window is Required",
                    sampleWindow: "",
                    sampleAggregate: "",
                });
            } else if (sampleWindowState && !sampleAggregate) {
                setSubmitErrors({
                    ...submitErrors,
                    sampleAggregate:
                        "Need to fill Treatment with Sampling Window",
                    timeWindow: "",
                    sampleWindow: "",
                });
            } else if (sampleAggregate && !sampleWindowState) {
                setSubmitErrors({
                    ...submitErrors,
                    sampleWindow: "Need to fill Sampling Window with Treatment",
                    timeWindow: "",
                    sampleAggregate: "",
                });
            } else if (
                !compareDurationForSamplingAndTimeWindow(
                    sampleWindowState,
                    timeWindowState
                )
            ) {
                setSubmitErrors({
                    ...submitErrors,
                    sampleWindow:
                        "Sampling Window should be less than Time Window",
                });
            } else {
                resetSubmitErrors();
                props.primaryButtonAction(finalValues);
                handleClose();
            }
        }
    };

    const handleSampleTimeWindowChange = (
        val: string,
        durationType: DurationType
    ) => {
        const value = parseInt(val);
        if (value <= 0) {
            setSamplingWindowError(true);
            setSubmitErrors({
                ...submitErrors,
                sampleWindow: "Sampling Window should be greater than 0",
            });
        } else {
            setSamplingWindowError(false);
            setSubmitErrors({
                ...submitErrors,
                sampleWindow: "",
            });
        }
        setFormValues({
            ...formValues,
            sampleWindowState: { value: val, type: durationType },
        });
    };
    const handleTimeWindowChange = (
        val: string,
        durationType: DurationType
    ) => {
        const value = parseInt(val);
        if (value <= 0) {
            setTimeWindowError(true);
            setSubmitErrors({
                ...submitErrors,
                timeWindow: "Time Window should be greater than 0",
            });
        } else {
            setTimeWindowError(false);
            setSubmitErrors({
                ...submitErrors,
                timeWindow: "",
            });
        }
        setFormValues({
            ...formValues,
            timeWindowState: { value: val, type: durationType },
        });
    };

    console.log("formValues :", formValues);
    return (
        <div className="history-popup-container">
            <div className="time-sampling">
                <div className="input-area">
                    <label>Time Window</label>
                    <TimeWindowInput
                        id={HISTORY_CONFIG.TIME_WINDOW_ID}
                        timeValue={formValues.timeWindowState}
                        handleDurationChange={handleTimeWindowChange}
                        errorText={submitErrors.timeWindow || ""}
                    />
                </div>
            </div>
            <div className="time-sampling">
                <div className="input-area">
                    <label>Sampling Window</label>
                    <TimeWindowInput
                        id={HISTORY_CONFIG.SAMPLING_WINDOW_ID}
                        timeValue={formValues.sampleWindowState}
                        handleDurationChange={handleSampleTimeWindowChange}
                        errorText={submitErrors.sampleWindow || ""}
                    />
                </div>
            </div>
            <div className="sampling-aggregate">
                <div className="input-area">
                    <label>Treatment</label>
                    <ReactSelect
                        styles={customReactSelectStyles}
                        // isDisabled={!checkFormValues.sampleAggregateBox}
                        options={[
                            {
                                label: SamplingAggregateValues.Avg,
                                value: SamplingAggregateValues.Avg,
                            },
                            {
                                label: SamplingAggregateValues.Sum,
                                value: SamplingAggregateValues.Sum,
                            },
                            {
                                label: SamplingAggregateValues.Min,
                                value: SamplingAggregateValues.Min,
                            },
                            {
                                label: SamplingAggregateValues.Max,
                                value: SamplingAggregateValues.Max,
                            },
                            {
                                label: SamplingAggregateValues.Count,
                                value: SamplingAggregateValues.Count,
                            },
                            {
                                label: SamplingAggregateValues.Raw,
                                value: SamplingAggregateValues.Raw,
                            },
                        ]}
                        value={formValues.sampleAggregate}
                        isMulti={false}
                        onChange={(value) => {
                            setFormValues({
                                ...formValues,
                                sampleAggregate:
                                    value as sampleAggregateDropDown,
                            });
                            resetSubmitErrors();
                        }}
                    />
                    <div className="history-submit-errors">
                        {submitErrors.sampleAggregate}
                    </div>
                </div>
            </div>
            <div className="limit">
                <div className="input-area">
                    <label>Limit</label>
                    <CustomABBInput
                        dataType="number"
                        value={formValues.limit}
                        onValueChange={(value: any) => {
                            setFormValues({ ...formValues, limit: value });
                            if (Number(value) <= 0 && value !== "") {
                                setSubmitErrors({
                                    ...submitErrors,
                                    limit: "Limit must be greater than 0",
                                });
                            } else {
                                setSubmitErrors({
                                    ...submitErrors,
                                    limit: "",
                                });
                            }
                        }}
                        validator={() =>
                            submitErrors.limit.length
                                ? {
                                      valid: false,
                                      text: submitErrors.limit,
                                  }
                                : { valid: true, text: "OK!" }
                        }
                        type="normal"
                        id="history-limit"
                        instantValidation={true}
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                </div>
            </div>

            <div className="history-submit-bt">
                <Button
                    type="primary-blue"
                    onClick={handleHistorySubmit}
                    text="Submit"
                />
            </div>
        </div>
    );
};
export default HistoryConfiguration;
