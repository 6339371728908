import { AlarmDetailsProps, AssetsType, ALARM_TYPE } from './../../../types';
import { fabric } from 'fabric';
import { outputCircleRadius, canvasIOCircleColor } from '../../UiConfig';
import { SelfPositioningChild } from '../../../NodeController/types';
import { Asset } from '../../../types';
import FunctionTypeDetail from '../../../../../transformers/AssetType/FunctionType';
import { thisExpression } from '@babel/types';
import ObjectTypeDetail from '../../../../../transformers/AssetType/ObjectType';
import AlarmTypeDetail from '../../../../../transformers/AssetType/AlarmType';
/**
 * It will add the circles on both the sides of a dropdownRectangle to add connections.
 *
 * @export
 * @class DropDownConnectionHooks
 * @extends {fabric.Group}
 * @implements {SelfPositioningChild}
 */
export class DropDownConnectionHooks extends fabric.Group implements SelfPositioningChild {
    data: SelfPositioningChild['data'];
    constructor(options: { group: fabric.Group; asset: Asset }) {
        const { group, asset } = options;
        const hookRight = new fabric.Circle({
            radius: outputCircleRadius,
            fill: 'transparent',
            stroke: '#FF7300',
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
            hoverCursor: 'pointer',
            moveCursor: 'pointer',
            left:
                group.getPointByOrigin('center', 'center').x +
                group.getScaledWidth() / 2 +
                outputCircleRadius,
            selectable: false,
        });

        const hookGroup = new fabric.Group(undefined, {});
        hookGroup.addWithUpdate(hookRight);
        if (asset instanceof FunctionTypeDetail) {
            const hookLeft = new fabric.Circle({
                radius: outputCircleRadius,
                fill: 'transparent',
                stroke: '#FF7300',
                strokeWidth: 2,
                originX: 'center',
                originY: 'center',
                left:
                    group.getPointByOrigin('center', 'center').x -
                    group.getScaledWidth() / 2 +
                    -outputCircleRadius,
                hoverCursor: 'pointer',
                moveCursor: 'pointer',
                selectable: false,
            });
            hookGroup.addWithUpdate(hookLeft);
        } else if (asset instanceof AlarmTypeDetail && asset.type === ALARM_TYPE.SPECIFIC) {
            const hookLeft = new fabric.Circle({
                radius: outputCircleRadius,
                fill: 'transparent',
                stroke: '#FF7300',
                strokeWidth: 2,
                originX: 'center',
                originY: 'center',
                left:
                    group.getPointByOrigin('center', 'center').x -
                    group.getScaledWidth() / 2 +
                    -outputCircleRadius,
                hoverCursor: 'pointer',
                moveCursor: 'pointer',
                selectable: false,
            });
            hookGroup.addWithUpdate(hookLeft);
        }

        super([hookGroup], {
            originX: 'center',
            originY: 'center',
            left:
                asset instanceof ObjectTypeDetail || (asset instanceof AlarmTypeDetail && asset.type === ALARM_TYPE.GENERIC )
                    ? group.getPointByOrigin('center', 'center').x +
                      group.getScaledWidth() / 2 +
                      outputCircleRadius * (group.scaleX || 1)
                    : group.getPointByOrigin('center', 'center').x,
            top: group.getPointByOrigin('center', 'center').y,
            selectable: false,
            visible: asset.isOpen ? false : true,
        });

        this.data = {
            rePosition: () => {
                this.left =
                    asset instanceof ObjectTypeDetail || (asset instanceof AlarmTypeDetail && asset.type === ALARM_TYPE.GENERIC )
                        ? group.getPointByOrigin('center', 'center').x +
                          group.getScaledWidth() / 2 +
                          outputCircleRadius * (group.scaleX || 1)
                        : group.getPointByOrigin('center', 'center').x;
                this.top = group.getPointByOrigin('center', 'center').y || 0;
                this.scaleX = group.scaleX || 1;
                this.scaleY = group.scaleX || 1;
                this.sendBackwards();
                this.setCoords();
            },
        };
    }
}
