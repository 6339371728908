import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { AbilityService } from "sce-engg-model-19.09";
import { Input, Button } from "@abb/abb-common-ux-react";
import { FormGroup, Label, ModalFooter } from "reactstrap";

import "./style.scss";
import {
    customReactSelectStyles,
    checkVersionUpdateStatus,
    compareTypesFormDetails,
    checkTypesForDuplicateTypeIdAndName,
} from "../../../../utils/helpers";
import { PayloadForTypeLevelImport } from "../../../../store/exportAsset/types";

import store, { StoreState } from "../../../../store";
import {
    CONFIGURATION_TOOL_MODE,
    MODEL,
    MODEL_SAVE_TYPE,
    SAVE_OPTIONS,
    TYPE_MSG,
} from "../../../../utils/constants/appConstants";
import {
    VersionUpdateType,
    VERSION_UPDATE_TYPE,
    IConfigurationToolMode,
} from "../../../../store/configurationTool/types";
import {
    addNewComputeModel,
    AddNewModelComputeModelPayload,
    updateActiveModel,
    saveComputeModelApi,
    addSaveAsComputeModel,
    updateActiveModelDetailsForImport,
} from "../../../../store/modelsPage/actions";
import { handleUniqueTypeId } from "../../../../store/modelsPage/actions";
import {
    handleUniqueTypeIdFailed,
    handleUniqueTypeIdMessage,
} from "../../../../store/modelsPage/actions";
import { UpdateActiveModelType } from "./types";
import {
    updateOverallSeverityFunctionId,
    updateOverAllSeverityFunctionStatus,
} from "../../../../store/configurationTool/actions";
import { hideModal } from "../../../../store/modal/action";
import { AssetType } from "../../../../transformers/AssetType";
import { checkDuplicateTypeId } from "../../../Functions/helper";
import { setDetailsForTypeLevelImport } from "../../../../store/exportAsset/actions";
import { compareTypesJson } from "../../../../transformers/ComputeModel/validation/versionUpdate";
import CustomABBInput from "../../../../components/CustomABBInput";

interface ModelIdDropdownItem {
    label: string;
    value: string;
}
interface AppProps {
    mode?: String;
}

export function CreateModelForm(
    props: AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    console.log("CreateModelForm props :", props);
    const [name, updateName] = useState(
        (props.modalMode && props.modalMode === "CREATE") ||
            (props.mode && props.mode === MODEL_SAVE_TYPE.SAVE_AS)
            ? ""
            : (props.modelDetails && props.modelDetails.name) || ""
    );
    const [description, updateDescription] = useState(
        props.modalMode && props.modalMode === "CREATE"
            ? ""
            : (props.modelDetails && props.modelDetails.description) || ""
    );
    const [model, updateModel] = useState(
        props.modalMode && props.modalMode === "CREATE"
            ? MODEL.DEFAULT_MODEL_ID
            : (props.modelDetails && props.modelDetails.model) ||
                  MODEL.DEFAULT_MODEL_ID
    );

    const [selectedModelId, setSelectedModelIdDropDownValue] = useState(
        [] as ModelIdDropdownItem[]
    );
    const [modelIdDropDownList, setModelIdDropdownList] = useState([
        {
            label: MODEL.DEFAULT_MODEL_ID,
            value: MODEL.DEFAULT_MODEL_ID,
        },
    ] as ModelIdDropdownItem[]);

    const [typeId, updateTypeId] = useState(
        (props.modalMode && props.modalMode === "CREATE") ||
            (props.mode && props.mode === MODEL_SAVE_TYPE.SAVE_AS)
            ? ""
            : (props.modelDetails && props.modelDetails.typeId) || ""
    );
    const [version, updateVersion] = useState(
        (props.modalMode && props.modalMode === "CREATE") ||
            (props.mode && props.mode === MODEL_SAVE_TYPE.SAVE_AS)
            ? "1.0.0"
            : (props.modelDetails && props.modelDetails.version) || ""
    );
    const [tags, updateTags] = useState(
        props.modalMode && props.modalMode === "CREATE"
            ? ""
            : (props.modelDetails && props.modelDetails.tags.join()) || ""
    );
    const [template, updateTemplate] = useState("");
    // inputField error handling states
    const [errorName, updateErrorName] = useState(false);
    const [errorDescription, updateErrorDescription] = useState(false);
    const [errorModel, updateErrorModel] = useState(false);
    const [errorTypeId, updateErrorTypeId] = useState(false);
    const [errorVersion, updateErrorVersion] = useState(false);
    const [versionUpdateType, setVersionUpdateType] = useState(
        VERSION_UPDATE_TYPE.MAJOR as VersionUpdateType
    );
    const [errorTypeIdText, updateErrorTypeIdText] = useState(
        "Please Enter Type ID"
    );
    const [errorExist, seterrorExist] = useState(false);
    const [errorMessage, updateErrorMessage] = useState(" ");
    const [errorVersionText, updateErrorVersionText] = useState(
        "Please Enter Version"
    );
    const [errorNameText, updateErrorNameText] = useState(
        "Please Enter Model Name"
    );

    useEffect(() => {
        const defaultSelectedItem = [
            {
                label: MODEL.DEFAULT_MODEL_ID,
                value: MODEL.DEFAULT_MODEL_ID,
            },
        ];
        if (props.modalMode && props.modalMode === "CREATE") {
            setSelectedModelIdDropDownValue([...defaultSelectedItem]);
        } else {
            const selectedModelIdValue =
                (props.modelDetails && props.modelDetails.model) ||
                MODEL.DEFAULT_MODEL_ID;

            setSelectedModelIdDropDownValue([
                { label: selectedModelIdValue, value: selectedModelIdValue },
            ]);
        }
    }, []);

    useEffect(() => {
        if (props.supportedConditionMonitorList.length) {
            const dropDownList: ModelIdDropdownItem[] =
                props.supportedConditionMonitorList.map((item) => {
                    return { label: item.modelName, value: item.modelName };
                });
            setModelIdDropdownList([...dropDownList]);
        }
    }, [props.supportedConditionMonitorList]);

    useEffect(() => {
        if (props.configurationToolMode === CONFIGURATION_TOOL_MODE.EDIT) {
            const versionUpdateType = compareTypesJson({
                originalJson: props.activeModel.modelInstance.json,
                updatedJson: props.updatedJson,
                computeModel: props.activeModel.modelInstance,
                overallSeverityFunctionId: props.overAllSeverityFunctionId,
            });
            setVersionUpdateType(versionUpdateType);
        }
    }, []);

    const fetchValueAndUpdate = useCallback((func, name, value) => {
        if (name === "modelName" && value !== "") {
            updateErrorName(false);
            value = value.trim();
        }
        if (name === "modelDescription" && value !== "") {
            updateErrorDescription(false);
        }
        if (name === "model" && value !== "") {
            updateErrorModel(false);
            value = value.trim();
        }
        if (name === "modelTypeId" && value !== "") {
            updateErrorTypeId(false);
            value = value.trim();
        }
        if (name === "modelVersion" && value !== "") {
            updateErrorVersion(false);
            value = value.trim();
        }
        func(value);
    }, []);

    const {
        mode,
        modalMode,
        configurationToolMode,
        typeIdDisabledDuringImport,
    } = props;

    const isTypeIdInputFiledDisabled = useMemo(() => {
        let isDisabled =
            modalMode !== "CREATE" &&
            !mode &&
            configurationToolMode !== CONFIGURATION_TOOL_MODE.CREATE;
        if (
            (configurationToolMode === CONFIGURATION_TOOL_MODE.IMPORT &&
                !typeIdDisabledDuringImport) ||
            configurationToolMode === CONFIGURATION_TOOL_MODE.IMPORT
        ) {
            isDisabled = false;
        }
        return isDisabled;
    }, []);

    useEffect(() => {
        if (
            props.modalMode !== "CREATE" &&
            props.mode !== MODEL_SAVE_TYPE.SAVE_AS &&
            props.modelDetails.typeId !== ""
        ) {
            const itemOne = props.modelDetails.typeId
                .toLowerCase()
                .includes(".tenantid.")
                ? props.modelDetails.typeId
                      .split(".")
                      .reverse()
                      .splice(2)
                      .reverse()
                      .join(".")
                : props.modelDetails.typeId;
            const itemTwo = itemOne.toLowerCase().includes("abb.local.")
                ? itemOne.split(".").splice(2).join(".")
                : itemOne;
            updateTypeId(itemTwo);
        }
        if (
            props.modalMode !== "CREATE" &&
            props.modelDetails.tags.length > 0
        ) {
            const temptTag = [...props.modelDetails.tags];
            const nameType =
                props.modelDetails.tags[
                    props.modelDetails.tags.length - 1
                ].split("=")[0];
            const item =
                nameType == "TenantId" || nameType == "Source"
                    ? temptTag.reverse().slice(2).reverse().join()
                    : props.modelDetails.tags.join();
            updateTags(item);
        }
    }, [props.modelDetails]);

    const handleSelectedModelId = (selectedObj: any) => {
        setSelectedModelIdDropDownValue([selectedObj]);
    };
    useEffect(() => {
        if (
            !props.isUniqueTypeId &&
            props.configurationToolMode === CONFIGURATION_TOOL_MODE.IMPORT &&
            !props.isUniqueTypeIdLoading
        ) {
            updateErrorTypeId(true);
            updateErrorTypeIdText(TYPE_MSG.TYPEID_ALREADY_EXIST);
        }
    }, [props.isUniqueTypeId, props.isUniqueTypeIdLoading]);

    useEffect(() => {
        debugger;
        if (props.isExist && props.message) {
            seterrorExist(true);
            updateErrorMessage("TypeId Already Exists");
        } else if (props.isExist === false && props.message == "") {
            seterrorExist(false);
            updateErrorMessage(" ");
        }
    }, [props.isExist, props.message, props.isUniqueTypeIdLoading]);
    const handleSubmit = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLAnchorElement | HTMLButtonElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        let returnVal: boolean = true;
        if (!props.isUniqueTypeIdLoading) {
            let { isTypeIdExist, isNameExist } =
                checkTypesForDuplicateTypeIdAndName({
                    typeId: `abb.local.${typeId}.TenantId.${AbilityService.tenantId}`,
                    name,
                    computeModels: Object.values(props.updateActiveModel.byId),
                });

            if (
                props.editMode &&
                props.configurationToolMode !== CONFIGURATION_TOOL_MODE.IMPORT
            ) {
                const originalTypeId =
                    props.updateActiveModel.byId[props.activeModel.id]
                        .modelDetails.typeId;
                const originalName =
                    props.updateActiveModel.byId[props.activeModel.id]
                        .modelDetails.name;
                if (
                    checkDuplicateTypeId({
                        typeId: typeId,
                        originalTypeId: originalTypeId,
                    })
                ) {
                    isTypeIdExist = false;
                    if (name === originalName) {
                        isNameExist = false;
                    }
                }
                if (!isTypeIdInputFiledDisabled) {
                    if (name === originalName) {
                        isNameExist = false;
                    }
                }
            }

            if (!name) {
                returnVal = false;
                updateErrorName(true);
            }

            // if (!description) {
            //     returnVal = false;
            //     updateErrorDescription(true);
            // }
            if (!model) {
                returnVal = false;
                updateErrorModel(true);
            }
            if (!typeId) {
                returnVal = false;
                updateErrorTypeId(true);
            }
            if (typeId) {
                var patt = new RegExp(
                    "^(?=.{1,150}$)[\\w\\-]([\\w\\-]|[\\.][\\w\\-])*$"
                );
                var res = patt.test(typeId);

                if (!res) {
                    returnVal = false;
                    updateErrorTypeIdText("Invalid Type name");
                    updateErrorTypeId(true);
                }
            }

            if (!version) {
                returnVal = false;
                updateErrorVersion(true);
            }

            if (version) {
                var patt = new RegExp("^[0-9]+[\\.]+[0-9]+[\\.]+[0-9]+$");
                var res = patt.test(version);
                if (!res) {
                    returnVal = false;
                    updateErrorVersionText("Invalid Version");
                    updateErrorVersion(true);
                }
            }

            if (isNameExist) {
                returnVal = false;
                updateErrorName(true);
                updateErrorNameText("Model Name Already Exists");
            }
            if (
                props.configurationToolMode !== CONFIGURATION_TOOL_MODE.IMPORT
            ) {
                if (isTypeIdExist) {
                    returnVal = false;
                    updateErrorTypeId(true);
                    updateErrorTypeIdText("TypeId Already Exists");
                }
            }

            const typeDetails = {
                description: description.replace(/^\s+|\s+$/g, ""),
                model: selectedModelId[0].value,
                name,
                tags: tags
                    ? tags
                          .split(",")
                          .concat(`Source=${AbilityService.tenantId}`)
                          .concat(`TenantId=${AbilityService.tenantId}`)
                    : [],
                typeId: `abb.local.${typeId}.TenantId.${AbilityService.tenantId}`,
                version,
            };
            if (props.configurationToolMode === CONFIGURATION_TOOL_MODE.EDIT) {
                const isDetailsChanged = compareTypesFormDetails({
                    originalDetails:
                        props.updateActiveModel.byId[props.activeModel.id]
                            .modelDetails,
                    updatedDetails: typeDetails,
                });
                const { errorVersionUpdateText, successVersionUpdate } =
                    checkVersionUpdateStatus({
                        oldVersion:
                            props.updateActiveModel.byId[props.activeModel.id]
                                .modelDetails.version,
                        currentVersion: version,
                        versionUpdateType: isDetailsChanged
                            ? VERSION_UPDATE_TYPE.MAJOR
                            : versionUpdateType,
                    });
                if (!successVersionUpdate) {
                    returnVal = false;
                    updateErrorVersionText(errorVersionUpdateText);
                    updateErrorVersion(true);
                }
            }
            if (name && model && typeId && version && returnVal) {
                const payload = {
                    modelDetails: {
                        ...typeDetails,
                        template,
                    },
                    associatedObject: { ...props.activeAsset } as AssetType,
                };

                const updateActiveModelPayload = {
                    ...typeDetails,
                };

                if (props.editMode === false) {
                    if (props.modalMode === MODEL_SAVE_TYPE.SAVE_AS) {
                        if (
                            props.activeModel.modelInstance
                                .isOverAllSeverityFunctionPresent
                        ) {
                            props.updateOverallSeverityDetails(
                                props.activeModel.modelInstance
                                    .overAllSeverityFunctionId!
                            );
                        }

                        props.handleUniqueTypeId({
                            details: payload,
                            mode: CONFIGURATION_TOOL_MODE.VIEW,
                        });
                        // props.addToSaveAsComputeModel({
                        //     ...payload,
                        // });
                        // props.saveComputeModel({
                        //     saveOption: SAVE_OPTIONS.PUBLISH,
                        // });
                    } else {
                        // props.addToComputeModels(payload);
                        debugger;
                        props.handleUniqueTypeId({
                            details: payload,
                            mode: CONFIGURATION_TOOL_MODE.CREATE,
                        });
                    }
                } else if (props.editMode === true) {
                    if (
                        props.configurationToolMode ===
                        CONFIGURATION_TOOL_MODE.IMPORT
                    ) {
                        props.handleActiveModelDetailsForImport(
                            updateActiveModelPayload
                        );
                    } else {
                        props.updateComputeModels(updateActiveModelPayload);
                    }
                }
            }
        }
        return returnVal;
    };

    return (
        <div className="wrapper-create-model">
            <form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                    <div className="form-wrapper__child">
                        <FormGroup className="custom-form-group">
                            {/* <Label for="modelName">
                                <sup>*</sup> Name of the Model
                            </Label> */}

                            <CustomABBInput
                                className="required"
                                dataType="text"
                                label="Name"
                                value={name}
                                onValueChange={(value) => {
                                    fetchValueAndUpdate(
                                        updateName,
                                        "modelName",
                                        value
                                    );
                                }}
                                disabled={
                                    !props.editMode &&
                                    !props.mode &&
                                    props.modalMode !== "CREATE"
                                }
                                type="normal"
                                id="modelName"
                                placeholder="Enter the name"
                                instantValidation={false}
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                validator={() =>
                                    errorName
                                        ? {
                                              valid: false,
                                              text: errorNameText,
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </FormGroup>

                        <FormGroup className="custom-form-group">
                            {/* <Label for="modelDescription">
                                <sup>*</sup> Description of the Model
                            </Label> */}
                            <CustomABBInput
                                // className="required"
                                dataType="text"
                                label="Description"
                                value={description}
                                onValueChange={(value) =>
                                    fetchValueAndUpdate(
                                        updateDescription,
                                        "modelDescription",
                                        value
                                    )
                                }
                                disabled={
                                    !props.editMode &&
                                    !props.mode &&
                                    props.modalMode !== "CREATE"
                                }
                                type="normal"
                                id="modelDescription"
                                placeholder="Enter the description"
                                instantValidation={false}
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                validator={() =>
                                    errorDescription
                                        ? {
                                              valid: false,
                                              text: "Please Enter Model Description",
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </FormGroup>

                        <FormGroup className="custom-form-group">
                            <Label className="requiredField" for="model">
                                Base Model ID
                            </Label>
                            <ReactSelect
                                styles={customReactSelectStyles}
                                isClearable={false}
                                options={modelIdDropDownList}
                                placeholder="Select a function"
                                isDisabled={
                                    props.modalMode !== "CREATE" &&
                                    !props.mode &&
                                    props.configurationToolMode !==
                                        CONFIGURATION_TOOL_MODE.CREATE &&
                                    props.configurationToolMode !==
                                        CONFIGURATION_TOOL_MODE.IMPORT
                                }
                                value={selectedModelId[0]}
                                getOptionLabel={(optionObj: any) => {
                                    return `${optionObj.label}`;
                                }}
                                getOptionValue={(optionObj: any) => {
                                    return `${optionObj.value}`;
                                }}
                                onChange={(value) =>
                                    handleSelectedModelId(value)
                                }
                            />
                        </FormGroup>

                        <FormGroup className="custom-form-group">
                            {/* <Label for="modelTypeId">
                                <sup>*</sup> Type ID of the Model
                            </Label> */}
                            <CustomABBInput
                                className="required"
                                dataType="text"
                                label="Type ID"
                                value={typeId}
                                onValueChange={(value) => {
                                    fetchValueAndUpdate(
                                        updateTypeId,
                                        "modelTypeId",
                                        value
                                    );
                                    if (errorMessage) {
                                        seterrorExist(false);
                                        updateErrorMessage("");
                                    }
                                }}
                                disabled={isTypeIdInputFiledDisabled}
                                type="normal"
                                id="modelTypeId"
                                placeholder="Enter the Type Id"
                                instantValidation={false}
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                validator={() => {
                                    if (errorTypeId) {
                                        return {
                                            valid: false,
                                            text: errorTypeIdText,
                                        };
                                    } else if (errorExist) {
                                        return {
                                            valid: false,
                                            text: errorMessage,
                                        };
                                    } else {
                                        return { valid: true, text: "OK!" };
                                    }
                                }}
                            />
                        </FormGroup>

                        <FormGroup className="custom-form-group">
                            {/* <Label for="modelVersion">
                                <sup>*</sup> Version of the Model
                            </Label> */}
                            <CustomABBInput
                                className="required"
                                dataType="text"
                                label="Version"
                                value={version}
                                onValueChange={(value) =>
                                    fetchValueAndUpdate(
                                        updateVersion,
                                        "modelVersion",
                                        value
                                    )
                                }
                                disabled={
                                    !props.editMode &&
                                    !props.mode &&
                                    props.modalMode !== "CREATE"
                                }
                                type="normal"
                                id="modelVersion"
                                placeholder="Enter the version"
                                instantValidation={false}
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                validator={() =>
                                    errorVersion
                                        ? {
                                              valid: false,
                                              text: errorVersionText,
                                          }
                                        : { valid: true, text: "OK!" }
                                }
                            />
                        </FormGroup>

                        <FormGroup className="custom-form-group">
                            {/* <Label for="modelTags">Tags of the Model</Label> */}
                            <CustomABBInput
                                dataType="text"
                                label="Tags"
                                value={tags}
                                onValueChange={(value) =>
                                    fetchValueAndUpdate(
                                        updateTags,
                                        "modelTags",
                                        value
                                    )
                                }
                                disabled={
                                    !props.editMode &&
                                    !props.mode &&
                                    props.modalMode !== "CREATE"
                                }
                                type="normal"
                                id="modelTags"
                                placeholder="Enter the tags"
                            />
                        </FormGroup>
                    </div>
                </div>
                <ModalFooter>
                    <div>
                        <Button
                            text={props.submitBtnText}
                            type="primary-blue"
                            sizeClass="small"
                            disabled={
                                (!props.editMode &&
                                    props.modalMode !== "CREATE" &&
                                    props.mode !== MODEL_SAVE_TYPE.SAVE_AS) ||
                                props.isUniqueTypeIdLoading
                            }
                            isLoading={props.isUniqueTypeIdLoading}
                            onClick={handleSubmit}
                        />
                    </div>
                </ModalFooter>
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // addToComputeModels: (payload: AddNewModelComputeModelPayload) => {
        //     dispatch(addNewComputeModel(payload));
        //     dispatch(hideModal());
        // },
        updateComputeModels: (payload: UpdateActiveModelType) => {
            dispatch(updateActiveModel(payload));
            dispatch(hideModal());
        },
        // addToSaveAsComputeModel: (paylod: AddNewModelComputeModelPayload) => {
        //     dispatch(addSaveAsComputeModel(paylod));
        //     dispatch(hideModal());
        // },
        saveComputeModel: (payload: { saveOption: SAVE_OPTIONS }) => {
            dispatch(saveComputeModelApi(payload));
        },
        updateOverallSeverityDetails: (nodeId: string) => {
            dispatch(updateOverAllSeverityFunctionStatus(true));
            dispatch(updateOverallSeverityFunctionId(nodeId));
        },
        setDetailsForTypeLevelImport: (payload: PayloadForTypeLevelImport) =>
            dispatch(setDetailsForTypeLevelImport(payload)),
        handleActiveModelDetailsForImport: (payload: UpdateActiveModelType) => {
            dispatch(updateActiveModelDetailsForImport(payload));
        },
        handleUniqueTypeId: (payload: {
            details: AddNewModelComputeModelPayload | UpdateActiveModelType;
            mode: IConfigurationToolMode;
        }) => {
            dispatch(handleUniqueTypeId(payload));
        },
    };
};

const mapStateToProps = (state: StoreState) => {
    return {
        assetList: state.modelsPage.objectTypeList,
        activeAsset: state.modelsPage.activeAsset,
        submitBtnText: state.modal.data && state.modal.data.submitBtnText,
        modalMode: state.modal.data && state.modal.data.mode,

        modelDetails:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails,
        activeModel: state.modelsPage.activeModel,
        updateActiveModel: state.modelsPage.computeModels,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        configurationToolMode: state.configurationTool.mode,
        updatedJson: state.configurationTool.json,
        overAllSeverityFunctionId:
            state.configurationTool.overallSeverityFunctionId,
        supportedConditionMonitorList:
            state.supportedConditionMonitor.supportedConditionMonitorList,
        typeIdDisabledDuringImport:
            state.exportAsset.typeIdDisabledDuringImport,
        dependantLibraries: state.exportAsset.dependantLibraries,
        passwordDetails: state.exportAsset.passwordDetails,
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
        isUniqueTypeIdLoading: state.modelsPage.isUniqueTypeIdLoading,
        isUniqueTypeId: !state.modelsPage.isTypeIdExistError,
        isExist: state.modelsPage.isExist,
        message: state.modelsPage.message,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateModelForm);
