
import { ActionTypes } from "../actionTypes";
import { ShowDialogPayload } from "./types";

export function showDialog(payload: ShowDialogPayload) {
    return <const>{
        type: ActionTypes.SHOW_DIALOG,
        payload
    };
}

export function hideDialog() {
    return <const>{
        type: ActionTypes.HIDE_DIALOG
    };
}
