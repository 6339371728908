import { ActionTypes } from '../actionTypes';
import { SettingsLeftNavOptions, IInfoModelInstance } from './types';

export const setActiveSettingTab = (tabName: SettingsLeftNavOptions) => {
    return <const>{
        type: ActionTypes.SET_ACTIVE_SETTING_TAB,
        payload: tabName,
    };
};

export const getInfoModelInstancesRequest = () => {
    return <const>{
        type: ActionTypes.GET_INFO_MODEL_INSTANCES,
    };
};

export const updateInfoModelInstanceRequest = (
    supportedModel: IInfoModelInstance,
    identityModel: IInfoModelInstance[]
) => {
    return <const>{
        type: ActionTypes.UPDATE_INFO_MODEL_INSTANCE,
        payload: {
            supportedModel: supportedModel,
            identityModel: identityModel,
        },
    };
};
export const saveInfoModelInstanceRequest = (val: IInfoModelInstance, type: string) => {
    return <const>{
        type: ActionTypes.SAVE_INFO_MODEL_INSTANCE,
        payload: {
            infoModel: val,
            type: type,
        },
    };
};

export const getTypeDefinitionRequest = () => {
    return <const>{
        type: ActionTypes.GET_TYPE_DEFINITION_REQUEST,
    };
};

export const updateTypeDefinitionRequest = (val: IInfoModelInstance) => {
    return <const>{
        type: ActionTypes.UPDATE_NEW_DEFINITION_TYPE,
        payload: val,
    };
};

export const deleteInfoModelInstanceRequest = (objectId: string, modelId: string) => {
    return <const>{
        type: ActionTypes.DELETE_INFO_MODEL_INSTANCE,
        payload: {
            objectId: objectId,
            modelId: modelId,
        },
    };
};

export const showProgresRequest = (val: boolean) => {
    return <const>{
        type: ActionTypes.SHOW_PROGRESS,
        payload: val,
    };
};
