import React, { ReactNode, useEffect } from 'react';
import Home from './Home';
import { NotFound } from './Login/NotFound';
import Assets from './Assets';
import Function from './Functions';
import history from '../store/history';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Flex from '../components/shared/Flex';
import { Footer } from '../components/Footer';
import { HelpPage } from '../components/HelpLayout/HelpPage';
import { Route, Switch, Router, Redirect, RouteComponentProps } from 'react-router-dom';
import AppSettings from '../services/AppSettings';
import { LOADER_SIZE, LOADER_TYPE } from '../utils/constants/appConstants';
import { StoreState } from '../store';
import { Dispatch } from 'redux';
import { initialiseLoginProvider } from '../store/login/action';
import { connect } from 'react-redux';
import Settings from './Settings';
import { hideImportModal } from '../store/exportAsset/actions';

const routesConfig = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        exact: true,
        private: false,
        abbHeader: false,
    },
    {
        path: '/login',
        component: Home,
        name: 'Home',
        exact: true,
        private: false,
        abbHeader: false,
    },
    {
        path: '/models',
        component: Home,
        name: 'Home',
        exact: true,
        private: true,
        abbHeader: true,
    },
    {
        path: '/assets',
        component: Assets,
        name: 'asset',
        exact: true,
        private: true,
        abbHeader: true,
    },
    {
        path: '/functions',
        component: Function,
        name: 'functions',
        exact: true,
        private: true,
        abbHeader: true,
    },
    {
        path: '/settings',
        component: Settings,
        name: 'functions',
        exact: true,
        private: true,
        abbHeader: true,
    },
    {
        path: '/help/portal',
        component: HelpPage,
        name: 'Help',
        exact: true,
        private: true,
        abbHeader: false,
    },
    {
        path: '*',
        name: 'NotFound',
        private: true,
        abbHeader: false,
        component: NotFound,
    },
];
const PrivateDashboardLayoutRoute = (props: { children: ReactNode }) => {
    return (
        <Flex column className="dashboard-layout-container">
            <Flex>
                <Header />
            </Flex>
            <Flex className="dashboard-layout-body-container">{props.children}</Flex>
            <Footer />
        </Flex>
    );
};

interface RouteProtectionProps {
    component: any;
    privateRoute: boolean;
    abbHeader: boolean;
}

const RoutesProtectionMapStateToProps = (state: StoreState) => {
    return {
        loadingStatus: state.login.loadingStatus,
        importModalOpened: state.exportAsset.isImportModalOpen,
    };
};
const RoutesProtectionMapDispatchToProps = (dispatch: Dispatch) => {
    return {
        initialiseLoginProvider: () => {
            dispatch(initialiseLoginProvider());
        },
        hideImportModal: () => {
            dispatch(hideImportModal());
        },
    };
};

const RoutesProtection = connect(
    RoutesProtectionMapStateToProps,
    RoutesProtectionMapDispatchToProps
)(
    (
        props: RouteComponentProps &
            RouteProtectionProps &
            ReturnType<typeof RoutesProtectionMapStateToProps> &
            ReturnType<typeof RoutesProtectionMapDispatchToProps>
    ) => {
        const {
            component: Component,
            privateRoute,
            abbHeader,
            loadingStatus,
            initialiseLoginProvider,
            importModalOpened,
            hideImportModal,
            ...rest
        } = props;

        useEffect(() => {
            const isLoggedIn =
                AppSettings.cacheLocation === 'localStorage'
                    ? localStorage.getItem('showLanding')
                    : sessionStorage.getItem('showLanding');

            if (importModalOpened) {
                hideImportModal();
            }
        }, []);

        return (
            <Route
                {...rest}
                render={(renderProps) => {
                    const isLoggedIn =
                        AppSettings.cacheLocation === 'localStorage'
                            ? localStorage.getItem('showLanding')
                            : sessionStorage.getItem('showLanding');
                    const ComponentToRender = Component;
                    if (loadingStatus) {
                        return (
                            <Flex center>
                                <Loader sizeClass={LOADER_SIZE.LARGE} type={LOADER_TYPE.RADIAL} />
                            </Flex>
                        );
                    }
                    if (privateRoute) {
                        if (isLoggedIn === 'true') {
                            return abbHeader ? (
                                <PrivateDashboardLayoutRoute>
                                    <ComponentToRender {...renderProps} />
                                </PrivateDashboardLayoutRoute>
                            ) : (
                                <ComponentToRender {...renderProps} />
                            );
                        } else {
                            return (
                                <Flex center>
                                    <Loader
                                        sizeClass={LOADER_SIZE.LARGE}
                                        type={LOADER_TYPE.RADIAL}
                                    />
                                </Flex>
                            );
                        }
                    } else {
                        if (isLoggedIn == 'true') {
                            return <Redirect to={'/models'} />;
                        } else {
                            return <ComponentToRender {...renderProps} />;
                        }
                    }
                }}
            />
        );
    }
);

const AppRoutes = () => {
    return (
        <Router history={history as any}>
            <Switch>
                {routesConfig.map((config) => {
                    return (
                        <Route
                            exact={config.exact || true}
                            key={`${config.name}`}
                            path={config.path}
                            render={(props: RouteComponentProps) => {
                                return (
                                    <RoutesProtection
                                        {...props}
                                        component={config.component}
                                        privateRoute={config.private}
                                        abbHeader={config.abbHeader}
                                    />
                                );
                            }}
                        />
                    );
                })}
            </Switch>
        </Router>
    );
};

export default AppRoutes;
