import { takeLatest, put } from 'redux-saga/effects';
import { ActionTypes } from '../actionTypes';
import { sce } from 'sce-engg-model-19.09';
import {
    STRUCTURE_IDENTITY,
    NOTIFICATION_MODAL_STATUS,
    SUPPORTED_MODEL,
} from '../../utils/constants/appConstants';
import {
    updateTypeDefinitionRequest,
    showProgresRequest,
    updateInfoModelInstanceRequest,
    getInfoModelInstancesRequest,
    deleteInfoModelInstanceRequest,
    getTypeDefinitionRequest,
    saveInfoModelInstanceRequest,
} from './action';
import { showNotificationModal } from '../notificationModal/action';
import { abbSDKGetErrorMessage } from '../../utils/helpers';

function* getInfoModelInstances(action: ReturnType<typeof getInfoModelInstancesRequest>) {
    try {
        yield put(showProgresRequest(true));
        const response = yield sce.getInfoModelInstances(SUPPORTED_MODEL.MODEL_ID);
        const responseIdentity = yield sce.getInfoModelInstances(STRUCTURE_IDENTITY.MODEL);
        yield put(
            updateInfoModelInstanceRequest(
                response && response.details ? response.details[0] : null,
                responseIdentity && responseIdentity.details ? responseIdentity.details : []
            )
        );
        yield put(showProgresRequest(false));
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* saveInfoModelInstance(action: ReturnType<typeof saveInfoModelInstanceRequest>) {
    try {
        yield put(showProgresRequest(true));
        const { infoModel, type } = action.payload;
        if (infoModel) {
            const response =
                type == 'UPDATE'
                    ? yield sce.updateInfoModelInstance(
                          infoModel.objectId,
                          infoModel.model,
                          infoModel
                      )
                    : yield sce.saveInfoModelInstance(infoModel);
        }
        yield put(showProgresRequest(false));
        yield put(getInfoModelInstancesRequest());
        yield put(
            showNotificationModal({
                title: 'Successfully Updated',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}
function* deleteInfoModelInstance(action: ReturnType<typeof deleteInfoModelInstanceRequest>) {
    try {
        yield put(showProgresRequest(true));
        const { objectId, modelId } = action.payload;
        if (objectId && modelId) {
            const response = yield sce.deleteInfoModelInstance(objectId, modelId);
        }
        yield put(showProgresRequest(false));
        yield put(getInfoModelInstancesRequest());
        yield put(
            showNotificationModal({
                title: 'Successfully Deleted',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getTypeDefinition(action: ReturnType<typeof getTypeDefinitionRequest>) {
    try {
        const response = yield sce.getTypeDefinition(
            STRUCTURE_IDENTITY.MODEL,
            STRUCTURE_IDENTITY.TYPE,
            STRUCTURE_IDENTITY.VERSION
        );
        yield put(
            updateTypeDefinitionRequest(response && response.details ? response.details : undefined)
        );
    } catch (error) {
        // yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

export default [
    takeLatest(ActionTypes.GET_INFO_MODEL_INSTANCES, getInfoModelInstances),
    takeLatest(ActionTypes.SAVE_INFO_MODEL_INSTANCE, saveInfoModelInstance),
    takeLatest(ActionTypes.GET_TYPE_DEFINITION_REQUEST, getTypeDefinition),
    takeLatest(ActionTypes.DELETE_INFO_MODEL_INSTANCE, deleteInfoModelInstance),
];
