import { IODataTypes } from '../../components/Fabric/types';

export function dataTypeConverterFromJson(options: { dataType: string; items?: string }) {
    const { dataType, items } = options;

    if (items) {
        switch (items) {
            case 'string': {
                return IODataTypes.ARRAY_STRING;
            }
            case 'number': {
                return IODataTypes.ARRAY_NUMBER;
            }
            case 'integer': {
                return IODataTypes.ARRAY_INTEGER;
            }
            case 'boolean': {
                return IODataTypes.ARRAY_BOOLEAN;
            }
        }
    } else {
        switch (dataType) {
            case 'string': {
                return IODataTypes.STRING;
            }
            case 'number': {
                return IODataTypes.NUMBER;
            }
            case 'integer': {
                return IODataTypes.INTEGER;
            }
            case 'boolean': {
                return IODataTypes.BOOLEAN;
            }
        }
    }

    return IODataTypes.UNDEFINED;
}

export function dataTypeConverterToJson(options: { dataType: IODataTypes }) {
    const { dataType } = options;
    const splited = dataType.split('_');
    if (splited.length === 2) {
        return {
            dataType: splited[0],
            items: splited[1],
        };
    } else {
        return {
            dataType: splited[0],
        };
    }
}
