import React, { useState } from 'react';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dialog } from '@abb/abb-common-ux-react';

import './style.scss';
import {
    hideImportModal,
    loadChosenFileData,
    handleFilesImport,
    addToSelectedItemForImport,
} from '../../../store/exportAsset/actions';
import { StoreState } from '../../../store';
import {
    ImportFilePayload,
    FunctionsLibraries,
    TypesWithLibraries,
} from '../../../store/exportAsset/types';
import {
    IMPORT,
    IMPORT_MODAL_BTN,
    GENERIC_FUNCTIONS,
    IMPORT_FILE,
} from '../../../utils/constants/appConstants';
import { isValidToImport } from '../../../utils/helpers';
import AvailableItemForImport from './AvailableItemForImport';


interface ImportTypeProps {}

const ImportModal = (
    props: ImportTypeProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { isImportValid, selectedItemsForImport, availableItemsForImport } = props;
    const [selectedLibraries, setSelectedLibraries] = useState([] as any);
    const [selectedFunctions, setSelectedFunctions] = useState([] as any);
    

    const [importValidationError, setImportValidationError] = useState('');
    const handleFileImport = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            props.loadChosenFileData(event.target.files);
        } else {
            props.loadChosenFileData();
        }
    };

    const handleStandardButtonAction = (btnText: string) => {
        if (btnText === 'Cancel') {
            setImportValidationError('');
            setSelectedFunctions([]);
            setSelectedLibraries([]);
            props.hideDialog();
            setImportValidationError('');
        } else if (btnText === 'Import' && isImportValid) {
            let importedItems: any = [];
                    
            if (selectedFunctions.length > 0) {
                if (selectedLibraries.length > 0) {
                    //@ts-ignore
                    importedItems = [...importedItems, ...selectedLibraries];
                    let genericFunctions =
                        props.availableItemsForImport[props.availableItemsForImport.length - 1];
                    genericFunctions.functionTypes = selectedFunctions;
                    importedItems = [...importedItems, genericFunctions];
                } else {
                    let availableItems = _.cloneDeepWith(props.availableItemsForImport);
                    let genericFunctions = availableItems[availableItems.length - 1];
                    genericFunctions.functionTypes = selectedFunctions;
                    importedItems = [...importedItems, genericFunctions];
                }
            } else {
                importedItems = [...importedItems, ...selectedLibraries];
            }
            props.addToSelectedImportItems(importedItems);
            const error = isValidToImport(importedItems);
            if (error.length === 0) {
                props.handleImportData({
                    contentRoute: `/${
                        window.location.pathname.split('/')[
                            window.location.pathname.split('/').length - 1
                        ]
                    }`,
                    type: 'MULTIPLE',
                });
                setImportValidationError('');
                setSelectedFunctions([]);
                setSelectedLibraries([]);
            } else {
                console.log(error);
                setImportValidationError(error);
            }
        }
    };

    const isItemSelected = (itemData: any) => {
        const isSelected = _.indexOf(selectedLibraries, itemData);
        if (
            itemData.hasOwnProperty('library') &&
            !(itemData as FunctionsLibraries).library &&
            itemData.functionTypes.length > 0
        ) {
            
            return selectedFunctions.length === itemData.functionTypes.length;
        }
        return isSelected !== -1;
    };
    const isFunctionSelected = (itemData: any) => {
        let isSelected = false;
        if (selectedFunctions.length > 0) {
            selectedFunctions.forEach((item: any) => {
                if (item.typeId === itemData.typeId) {
                
                    isSelected = true;
                }
            });
        }
        return isSelected;
    };
    console.log('###importType Props :', props, selectedLibraries, selectedFunctions);
    return (
        <Dialog
            isOpen={props.isOpen}
            showCloseButton
            title={IMPORT}
            dimBackground={true}
            containerClassName={selectedLibraries.length === 0 && selectedFunctions.length === 0 ?"disabled-import":""}
            onClose={() => {
                props.hideDialog();
                setImportValidationError('');
                setSelectedFunctions([]);
                setSelectedLibraries([]);
            }}
            standardButtonsOnBottom={[
                {
                    text: IMPORT_MODAL_BTN.CANCEL,
                    type: 'discreet-black',
                    handler: () => handleStandardButtonAction(IMPORT_MODAL_BTN.CANCEL),
                },
                {
                    text: IMPORT_MODAL_BTN.IMPORT,
                    type: 'primary-blue' ,
                
                     
                    handler: () => {
                        if(selectedLibraries.length > 0 || selectedFunctions.length > 0)
                        handleStandardButtonAction(IMPORT_MODAL_BTN.IMPORT)},
                },
            ]}
        >
            <div className="wrapper-import-type">
                <>
                    <input type="file" accept=".zip" onChange={handleFileImport}></input>

                    {isImportValid ? (
                        <div className="wrapper-item-list">
                            {availableItemsForImport.map(
                                (item: FunctionsLibraries | TypesWithLibraries) =>
                                    item.hasOwnProperty('library') ? (
                                        <AvailableItemForImport
                                            key={
                                                (item as FunctionsLibraries).library
                                                    ? (item as FunctionsLibraries).library.name
                                                    : GENERIC_FUNCTIONS
                                            }
                                            itemData={item}
                                            isSelected={isItemSelected(item)}
                                            setImportValidationError={setImportValidationError}
                                            isFunctionSelected={isFunctionSelected}
                                            setSelectedLibraries={setSelectedLibraries}
                                            setSelectedFunctions={setSelectedFunctions}
                                            selectedLibraries={selectedLibraries}
                                            selectedFunctions={selectedFunctions}
                                        />
                                    ) : (
                                        <AvailableItemForImport
                                            key={
                                                (item as TypesWithLibraries).model.properties.model
                                                    .typeId.value
                                            }
                                            itemData={item}
                                            isSelected={isItemSelected(item)}
                                            setImportValidationError={setImportValidationError}
                                            setSelectedLibraries={setSelectedLibraries}
                                            selectedLibraries={selectedLibraries}
                                        />
                                    )
                            )}
                        </div>
                    ) : (
                        <div className="import-error">{IMPORT_FILE.INVALID_IMPORT_MSG}</div>
                    )}
                </>
                {importValidationError.length > 0 && (
                    <div className="import-error">{importValidationError}</div>
                )}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        isOpen: state.exportAsset.isImportModalOpen,
        isImportValid: state.exportAsset.isImportValid,
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
        availableItemsForImport: state.exportAsset.availableItemsForImport,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        hideDialog: () => {
            dispatch(loadChosenFileData());
            dispatch(hideImportModal());
        },
        addToSelectedImportItems: (item: any) => {
            dispatch(addToSelectedItemForImport(item));
        },
        loadChosenFileData: (fileList?: FileList) => {
            dispatch(loadChosenFileData(fileList));
        },
        handleImportData: (payload: ImportFilePayload) => {
            dispatch(handleFilesImport(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
