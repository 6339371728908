import { Asset } from '../types';

import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import {
    fontSize,
    FUNC_BLOCK_TITLE_HEIGHT,
    PINS_DISTANCE,
    BLOCK_BOTTOM_PADDING,
    GRID_PIXEL,
    OBJECT_BLOCK_TITLE_HEIGHT,
} from '../UIHelpers/UiConfig';
import { fabric } from 'fabric';

export const calculateAssetDimension = (asset: Asset) => {
    let height = 0;
    let width = 0;
    const { assetName, assetVersion } = asset;
    if (asset instanceof FunctionTypeDetail) {
        const { conditions, inputs, outputs, inhibits } = asset;
        let length = 1;

        const conditionsLength = conditions ? 1 : 0;

        if (inputs) {
            const inputLength = inputs.length + inhibits.length;
            length = inputLength;
        }

        if (outputs) {
            const outputLength = outputs.length;
            if (outputLength + conditionsLength > length) {
                length = outputLength + conditionsLength;
            }
        }
        height = FUNC_BLOCK_TITLE_HEIGHT + (length - 1) * PINS_DISTANCE + BLOCK_BOTTOM_PADDING;
    } else {
        const outputs = asset.outputs;
        width = GRID_PIXEL * 15;

        const titleText = new fabric.Text(`${assetName} ${assetVersion}`, {
            fontSize: fontSize,
        });
        height =
            OBJECT_BLOCK_TITLE_HEIGHT + (outputs.length - 1) * PINS_DISTANCE + BLOCK_BOTTOM_PADDING;
        const textWidth = titleText.getScaledWidth() + 60;
        width = textWidth > width ? textWidth : width;
    }

    return { width, height };
};
