import React, { useCallback, useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input, ModalFooter } from "reactstrap";
import "./styles.scss";
import {
    LOADER_SIZE,
    LOADER_TYPE,
    ROUTE_PATHNAME,
    OVERALL_SEVERITY_FUNCTION,
} from "../../../../utils/constants/appConstants";
import { StoreState } from "../../../../store";
import * as _ from "lodash";
import classnames from "classnames";
import ConditionConfiguration from "./ConditionsTab";
import {
    Dialog,
    TabControl,
    TabItem,
    Input as AbbInput,
    Button,
    Icon,
} from "@abb/abb-common-ux-react";
import { sce } from "sce-engg-model-19.09";
import { VALIDATION_MESSAGE } from "../../../../utils/constants/uiConstants";
import {
    checkFunctionIdDuplication,
    markConditionDataDirty,
    validURL,
} from "../../../../utils/helpers";
import CalculationConfiguration from "../CalculationConfiguration";
import { validateForSpecialCharacters_Including_HyphenAndUnderscore } from "../../../../routes/Functions/helper";
import FunctionTypeDetail from "../../../../transformers/AssetType/FunctionType";
import { GetFunctionTypePayload } from "../../../../store/configurationTool/types";
import { Dispatch } from "redux";
import { getSelectedFunctionDetails } from "../../../../store/configurationTool/actions";
import Condition from "../../../shared/Condition";
import Loader from "../../../Loader";
import EndPointUrlList from "../../../EndPointUrl/EndPointUrl";
import {
    handleSaveFunctionEndPoint,
    getfunctionEndPointList,
} from "../../../../store/function/action";
import { IEndPointList } from "../../../../store/function/types";
import { isHttpExists } from "../../../../utils/helpers";
import CustomABBInput from "../../../CustomABBInput";

interface AppProps {
    primaryButtonAction?: ({
        functionName,
        timeTrigger,
        endpoint,
        conditions,
        calculations,
        isConditionOverRidden,
        isEndpointDirty,
    }: any) => void;
    handleClose?: any;
    functionName?: string;
    hasOverRiddenCondition: boolean;
    asset: FunctionTypeDetail;
    timeTrigger?: number;
    endpoint?: string;
    conditions?: any;
    calculations?: any;
    disabledFields?: string[];
}

enum tabList {
    General = "General",
    Conditions = "Conditions",
    Caculations = "Calculations",
}

function FunctionConfiguration(
    props: AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    console.log("props FucntionConfig :", props);
    const [timeTrigger, updateTimeTrigger] = useState(props.timeTrigger || "");
    const [functionName, updateFunctionName] = useState(
        props.functionName || ""
    );
    const [endpoint, updateEndPoint] = useState(props.endpoint || "");
    const [conditions, updateConditions] = useState({ ...props.conditions });
    const [calculations, updateCalculations] = useState(props.calculations);
    const [tabSelected, updateTabSelected] = useState(tabList.General as any);
    const [isFunctionValid, updateIsFunctionValid] = useState(true);
    const [conditionFlag, updateConditionFlag] = useState(false);
    const [logicValue, updateLogicValue] = useState({
        condition: "",
        label: "",
        value: "",
    } as any);

    const [showLogicDialog, updateShowLogicDialog] = useState(false);
    const [errorTimeTrigger, updateErrorTimeTriggerText] = useState(false);
    const [errorEndpoint, updateErrorEndpoint] = useState(false);
    const [errorEndpointText, updateErrorEndpointText] = useState("");
    const [buttondisabled, updateButtonDisabled] = useState(false);
    const [errorFuncName, updateErrorFuncName] = useState("");
    const [typeFunctionData, setTypeFunctionData] = useState(
        props.modelInstance.json.assetData.find(
            (node) =>
                node instanceof FunctionTypeDetail &&
                node.nodeId === props.functionName!
        ) as FunctionTypeDetail | undefined
    );

    const [endPointOptionArray, setEndPointOption] = useState(
        [] as IEndPointList[]
    );

    useEffect(() => {
        handleConditionFlag();
        updateConditions({ ...props.conditions });
        updateCalculations({ ...props.calculations });
        props.getFunctionEndPointLists();
    }, []);

    useEffect(() => {
        if (props.endpointUrlLists && props.endpointUrlLists.length > 0) {
            let functionEndPointList: IEndPointList[] = [];
            props.endpointUrlLists.forEach((item: string) => {
                functionEndPointList.push({
                    value: item,
                    label: item,
                });
            });

            setEndPointOption(functionEndPointList);
        }
    }, [props.endpointUrlLists]);
    useEffect(() => {
        const { assetRef, assetType, assetVersion, assetName } = props.asset;
        const lastIndex = assetRef.lastIndexOf("@");
        let typeId = assetRef;

        if (lastIndex !== -1) {
            typeId = typeId.slice(0, lastIndex);
        }

        const funcTypeIdWithVersion = `${typeId}#${assetVersion}`;
        const originalFunctionType = {
            ...props.originalFuncTypesDetail.byIdWithVersion[
                funcTypeIdWithVersion
            ],
        };

        if (originalFunctionType && originalFunctionType.conditions) {
            setTypeFunctionData(originalFunctionType);
        }
    }, []);

    const handleConditionFlag = () => {
        updateConditionFlag(sce.isConditionSupported());
    };

    const handleSubmit = useCallback(() => {
        validateFunction();

        if (
            isFunctionValid &&
            validateForSpecialCharacters_Including_HyphenAndUnderscore(
                functionName
            ) &&
            errorTimeTrigger === false &&
            !errorEndpoint
        ) {
            let overRiddenConditionData = conditions;
            let isConditionOverRidden = false;
            const originalConditionData =
                typeFunctionData && typeFunctionData.conditions;
            const isFunctionAlreadyAvailable =
                props.modelInstance.json.assetData.find(
                    (node) =>
                        node instanceof FunctionTypeDetail &&
                        node.nodeId === props.functionName!
                ) as FunctionTypeDetail | undefined;

            let savedConditions;
            if (isFunctionAlreadyAvailable) {
                savedConditions = _.cloneDeepWith(
                    isFunctionAlreadyAvailable.conditions
                );
            }

            if (originalConditionData) {
                const { overRiddenCondition, isDirty } = markConditionDataDirty(
                    {
                        originalConditons: originalConditionData,
                        changedConditions: conditions,
                        savedConditions,
                    }
                );
                overRiddenConditionData = overRiddenCondition;
                isConditionOverRidden = isDirty;
            }

            let isEndpointDirty = !!(
                props.asset && props.asset.isEndPointDirty
            );

            if (!isEndpointDirty) {
                isEndpointDirty = !!(
                    typeFunctionData && typeFunctionData.endpoint !== endpoint
                );
            }

            if (props.primaryButtonAction) {
                props.primaryButtonAction({
                    functionName,
                    timeTrigger,
                    endpoint,
                    conditions: overRiddenConditionData,
                    calculations,
                    isConditionOverRidden,
                    isEndpointDirty: isEndpointDirty,
                });
                if (endPointOptionArray && endPointOptionArray.length > 0) {
                    const endPointExists = endPointOptionArray.find(
                        (endPointUrl: IEndPointList) => {
                            return endPointUrl.value == isHttpExists(endpoint);
                        }
                    );
                    if (!endPointExists) {
                        props.handleFunctionEndPointSave(endpoint);
                    }
                }
            }

            if (props.handleClose) {
                props.handleClose();
            }
        } else {
        }
    }, [
        timeTrigger,
        functionName,
        endpoint,
        isFunctionValid,
        typeFunctionData,
    ]);

    const handleEndpointChange = (value: string) => {
        updateEndPoint(value);
        // const isValidEndpointURL = validURL(value);
        // if (!isValidEndpointURL) {
        //     updateErrorEndpointText('Invalid Endpoint URL');
        //     updateErrorEndpoint(true);
        // } else {
        //     updateErrorEndpointText('');
        //     updateErrorEndpoint(false);
        // }
    };

    const handleChange = (value: string, name: string) => {
        let targetName = name;
        switch (targetName) {
            case "function": {
                if (
                    !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                        value
                    )
                ) {
                    updateIsFunctionValid(false);
                    updateErrorFuncName(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
                } else if (value === props.asset.assetName) {
                    updateIsFunctionValid(false);
                    updateErrorFuncName(
                        VALIDATION_MESSAGE.ORIGINAL_FUNCTION_NAME
                    );
                } else {
                    updateIsFunctionValid(true);
                    updateErrorFuncName("");
                }
                updateFunctionName(value);
                break;
            }
            case "endPoint": {
                updateEndPoint(value);
                break;
            }
            case "timeTrigger": {
                console.log("timeTrigger value :", value);
                var patt = new RegExp("^[0-9]*$");
                var res = patt.test(value);
                console.log("res-regex", res);
                if (!res) {
                    console.log("error time Trigger ");
                    updateErrorTimeTriggerText(true);
                } else {
                    updateErrorTimeTriggerText(false);
                    if (value === "") {
                        updateTimeTrigger("");
                    } else updateTimeTrigger(parseInt(value));
                }
                break;
            }
            default:
                console.log("input field not exists with given name");
        }
    };
    const handleConditionChange = useCallback((data) => {
        let res: any = Object.values(data)[0];
        let valid = true;
        Object.values(res.subConditions).forEach((subCondition: any) => {
            if (
                subCondition.severity &&
                (subCondition.severity.severityError ||
                    subCondition.severity.value === " ")
            ) {
                valid = false;
            }
        });
        if (valid === true) {
            updateButtonDisabled(false);
        } else {
            updateButtonDisabled(true);
        }
    }, []);

    const tabNavList =
        conditions &&
        Array.from(Object.keys(conditions)).length > 0 &&
        conditionFlag
            ? calculations && Array.from(Object.keys(calculations)).length > 0
                ? [tabList.General, tabList.Conditions, tabList.Caculations]
                : [tabList.General, tabList.Conditions]
            : calculations && Array.from(Object.keys(calculations)).length > 0
            ? [tabList.General, tabList.Caculations]
            : [tabList.General];

    const validateFunction = useCallback(() => {
        const oldFunctionList = props.modelInstance.json.assetData.filter(
            (item) => item instanceof FunctionTypeDetail
        );
        const isValid =
            functionName === props.functionName ||
            (checkFunctionIdDuplication({
                id: functionName,
                assetData: props.functionList,
            }) &&
                checkFunctionIdDuplication({
                    id: functionName,
                    assetData: oldFunctionList,
                }) &&
                functionName !== props.asset.assetName);

        if (isValid) {
            if (
                !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                    functionName
                )
            ) {
                updateIsFunctionValid(false);
                updateErrorFuncName(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
            } else {
                updateIsFunctionValid(true);
                updateErrorFuncName("");
            }
        } else if (functionName === props.asset.assetName) {
            updateIsFunctionValid(false);
            updateErrorFuncName(VALIDATION_MESSAGE.ORIGINAL_FUNCTION_NAME);
        } else {
            updateIsFunctionValid(isValid);
            updateErrorFuncName(VALIDATION_MESSAGE.FUNCTION_NAME_ERROR);
        }
    }, [functionName]);

    classnames("nav-btn", "nav-btn-active");

    console.log(props);

    const handleLogicDialog = (
        selectedValue?: string,
        name?: string,
        dataProps?: string
    ) => {
        updateShowLogicDialog(!showLogicDialog);
        updateLogicValue({
            condition: selectedValue,
            label: name,
            value: dataProps,
        });
    };

    const handleCloseLogicDialog = () => {
        updateShowLogicDialog(!showLogicDialog);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateLogicValue({
            condition: logicValue.condition,
            label: e.target.name,
            value: e.target.value,
        });
    };

    const commonProps = {
        activeTab: _.indexOf(tabNavList, tabSelected),
        onTabChange: (oldIndex: any, newIndex: number) =>
            updateTabSelected(tabNavList[newIndex]),
    };

    const getLogic = (props: any) => {
        return (
            <Dialog
                containerClassName="logic-add-dialog new-layout"
                dimBackground={true}
                title="Logic Expression Builder"
                isOpen={showLogicDialog}
                showCloseButton={true}
                onClose={() => handleCloseLogicDialog()}
            >
                <div className="customClassInput padding-bottom-30">
                    <Input
                        type="textarea"
                        name={logicValue.label}
                        value={logicValue.value}
                        disabled={true}
                        className="generalInput"
                        onChange={(e) => handleInputChange(e)}
                        placeholder={`Enter Logic`}
                    />
                </div>
            </Dialog>
        );
    };

    return (
        <div className="wrapper-function-configuration">
            <Condition when={props.isFunctionDetailsLoading}>
                <Loader
                    sizeClass={LOADER_SIZE.MEDIUM}
                    type={LOADER_TYPE.RADIAL}
                />
            </Condition>

            {!props.isFunctionDetailsLoading && (
                <>
                    {getLogic(props)}
                    <TabControl type="primary" {...commonProps}>
                        {tabNavList.map((list: string, listIndex: number) => {
                            return <TabItem key={listIndex} title={list} />;
                        })}
                    </TabControl>

                    {tabSelected === tabList.General && (
                        <Fragment>
                            <div className="general-form-tab">
                                <FormGroup>
                                    <Label for='"functionName :", functionName'>
                                        Function Name
                                    </Label>
                                    <CustomABBInput
                                        dataType="text"
                                        id="functionName"
                                        disabled={
                                            (props.disabledFields &&
                                                props.disabledFields.includes(
                                                    "functionName"
                                                )) ||
                                            props.asset.assetRef.split(
                                                "@"
                                            )[0] ===
                                                OVERALL_SEVERITY_FUNCTION.TYPE_ID
                                        }
                                        placeholder="Enter funtion name"
                                        value={functionName}
                                        onLostFocus={validateFunction}
                                        onValueChange={(value) =>
                                            handleChange(value, "function")
                                        }
                                        validator={() =>
                                            !isFunctionValid
                                                ? functionName === ""
                                                    ? {
                                                          valid: false,
                                                          text: VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE,
                                                      }
                                                    : {
                                                          valid: false,
                                                          text: errorFuncName,
                                                      }
                                                : { valid: true, text: "OK!" }
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='Version :", functionName'>
                                        Function Version
                                    </Label>
                                    <CustomABBInput
                                        dataType="text"
                                        id="Version"
                                        disabled={true}
                                        value={props.asset.assetVersion}
                                    />
                                </FormGroup>
                                <FormGroup className="wrapper-time-trigger">
                                    <div>
                                        <Label for="exampleCustomRange">
                                            Time Trigger (in seconds)
                                        </Label>
                                        <CustomABBInput
                                            dataType="text"
                                            id="timeTrigger"
                                            placeholder="Enter Time(in seconds)"
                                            value={timeTrigger.toString()}
                                            onValueChange={(value) =>
                                                handleChange(
                                                    value,
                                                    "timeTrigger"
                                                )
                                            }
                                            showValidationBarWhenInvalid={true}
                                            showValidationIconWhenInvalid={true}
                                            disabled={
                                                props.disabledFields &&
                                                props.disabledFields.includes(
                                                    "timeTrigger"
                                                )
                                            }
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    {/* <Label for="exampleEmail">Endpoint</Label> */}
                                    <EndPointUrlList
                                        endpointValue={endpoint}
                                        isViewReadOnly={
                                            props.disabledFields &&
                                            props.disabledFields.includes(
                                                "endPoint"
                                            )
                                                ? true
                                                : false
                                        }
                                        endpointUrlLists={
                                            props.endpointUrlLists
                                        }
                                        handleEndpointChange={
                                            handleEndpointChange
                                        }
                                    />
                                    {/* <CustomABBInput
                                        // disabled
                                        dataType="text"
                                        id="endPoint"
                                        indicateChanged={!!props.asset.isEndPointDirty}
                                        disabled={
                                            props.disabledFields &&
                                            props.disabledFields.includes('endPoint')
                                        }
                                        placeholder="Enter the endpoint details"
                                        value={endpoint}
                                        onValueChange={handleEndpointChange}
                                        validator={() =>
                                            errorEndpoint
                                                ? {
                                                      valid: false,
                                                      text: errorEndpointText,
                                                  }
                                                : { valid: true, text: 'OK!' }
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                    /> */}
                                </FormGroup>
                            </div>
                        </Fragment>
                    )}

                    {tabSelected === tabList.Conditions &&
                        Array.from(Object.keys(conditions)).length > 0 && (
                            <div className="general-form-tab">
                                <ConditionConfiguration
                                    handleLogicDialog={handleLogicDialog}
                                    disabledFields={props.disabledFields}
                                    functionDetails={props.asset}
                                    assetsDetails={conditions}
                                    handleConditionChange={
                                        handleConditionChange
                                    }
                                    isDisabled={
                                        props.disabledFields &&
                                        props.disabledFields.includes(
                                            "conditions"
                                        )
                                    }
                                />
                            </div>
                        )}

                    {tabSelected === tabList.Caculations &&
                        Array.from(Object.keys(calculations)).length > 0 && (
                            <div className="general-form-tab">
                                <CalculationConfiguration
                                    calculationDetails={calculations}
                                    isEncrypted={!!props.asset.isEncrypted}
                                />
                            </div>
                        )}
                </>
            )}

            <ModalFooter>
                <Button
                    type="primary-blue"
                    text="Set"
                    sizeClass="small"
                    onClick={handleSubmit}
                    disabled={buttondisabled}
                />
            </ModalFooter>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    const modelInstance = window.location.pathname.includes(
        ROUTE_PATHNAME.OBJECTS
    )
        ? state.instanceConfig.activeModel.modelInstance
        : state.modelsPage.activeModel.modelInstance;
    return {
        functionList: state.configurationTool.json.assetData,
        modelInstance: modelInstance,
        isFunctionDetailsLoading: state.configurationTool.showFunctionLoading,
        selectedFunctionDetails:
            state.configurationTool.selectedFunctionDetails,
        originalFuncTypesDetail:
            state.configurationTool.originalFunctionTypesUsed,
        canvasController: state.configurationTool.canvasController,
        endpointUrlLists: state.functions.endPointUrlLists,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getSelectedFunctionDetails: (payload: GetFunctionTypePayload) => {
            dispatch(getSelectedFunctionDetails(payload));
        },
        getFunctionEndPointLists: () => dispatch(getfunctionEndPointList()),
        handleFunctionEndPointSave: (endpoint: string) => {
            let endPointHttp: string = endpoint;
            const isHttpExists: boolean = endpoint.startsWith("http");
            if (!isHttpExists) {
                endPointHttp = "http://" + endpoint;
            }
            dispatch(handleSaveFunctionEndPoint({ endpoint: endPointHttp }));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionConfiguration);

// export default FunctionConfiguration;
