import { IOApiOptions } from '../types';
import { isNullOrUndefined } from 'util';
import FunctionTypeDetail from '../FunctionType';
import { generateFunctionId, generateFunctionPath } from './functionIoIdGenerator';
import { dataTypeConverterFromJson } from '../../utils/dataTypeConverter';
import { defaultValueFromJson } from '../../defaultValue';

export function ioConverter({
    keys,
    object,
    refPath,
    nodeId,
}: {
    keys: string[];
    object: IOApiOptions;
    refPath: 'inputs' | 'outputs' | 'inhibit' | 'alarmInputs' | 'eventInputs';
    nodeId: string;
}) {
    const data: FunctionTypeDetail['inputs'] | FunctionTypeDetail['outputs'] = [];
    if (Array.isArray(keys) && typeof object === 'object') {
        keys.forEach((key) => {
            const dataType = object[key].dataType;
            const items = object[key].items;
            const timeWindow = object[key].timeWindow;
            const samplingWindow = object[key].samplingWindow;
            const samplingAggregate = object[key].samplingAggregate;
            const limit = object[key].limit;

            const isPublished = !!object[key].link;
            const alarmId = object[key].alarmId;
            const eventId = object[key].eventId;

            if (dataType) {
                const path = generateFunctionPath({
                    nodeId,
                    ioType: refPath,
                    ioName: key,
                });

                const temp: FunctionTypeDetail['inputs'][0] & FunctionTypeDetail['outputs'][0] = {
                    name: key,
                    id: generateFunctionId({
                        nodeId,
                        ioType: refPath,
                        ioName: key,
                    }),
                    path,
                    dataType: dataTypeConverterFromJson({ dataType, items }),
                    connected: false,
                };

                if (refPath === 'inputs') {
                    if (timeWindow) {
                        temp.timeWindow = timeWindow;
                    }
                    if (samplingAggregate) {
                        temp.samplingAggregate = samplingAggregate;
                    }
                    if (samplingWindow) {
                        temp.samplingWindow = samplingWindow;
                    }
                    if (limit) {
                        temp.limit = limit;
                    }
                }

                if (refPath === 'outputs') {
                    temp.isPublished = isPublished;
                    if (alarmId) {
                        temp.alarmId = alarmId;
                    }
                    if (eventId) {
                        temp.eventId = eventId;
                    }
                } else {
                    if (!isNullOrUndefined(object[key].value)) {
                        temp.defaultValue =
                            defaultValueFromJson({
                                value: object[key].value as string,
                            }) || '';
                        temp.connected = true;
                    }
                }

                data.push(temp);
            }
        });
    }

    return data;
}
