import { normalize } from "normalizr";
import { Node } from "react-virtualized-tree";
import { ActionTypes } from "../actionTypes";
import {
    CategoryObjTypes,
    SystemObjTypes,
    TreeNodeType,
    NormalizeTreeNode,
    IStructure,
    ISystem,
    IAssetPermission,
    IFilteredItem,
    InstanceMonitoringType,
    IEdgeList,
} from "./types";
import {
    ACTION_MENU_LIST,
    ACTION_MENU_VALUE,
    ActionMenuValue,
} from "../instanceConfig/types";
import { ComputeModelToJson } from "../../transformers/ComputeModel/toJson/ComputeModelToJson";

export function getAssetCategoryListActionRequest() {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_REQUEST,
        payload: {},
    };
}
export function getAssetCategoryListActionSuccess(
    categoryList: CategoryObjTypes[]
) {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_SUCCESS,
        payload: {
            dataArr: categoryList,
        },
    };
}
export function getAssetCategoryListActionFailure() {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_FAILURE,
        payload: {
            dataArr: [],
        },
    };
}
export function handleCategorySelectionAction(
    selectedCategory: CategoryObjTypes | undefined
) {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_SELECTION,
        payload: {
            categoryObj: selectedCategory,
        },
    };
}

export function setTreeListNodesAction({
    node,
    normalizeNode,
    treeVirtualNodes,
}: {
    node: TreeNodeType;
    normalizeNode: NormalizeTreeNode;
    treeVirtualNodes: Node;
}) {
    return <const>{
        type: ActionTypes.UPDATE_TREE_LIST_NODES,
        payload: {
            data: {
                node,
                normalizeNode,
                treeVirtualNodes,
            },
        },
    };
}

export function getSystemsActionRequest() {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_REQUEST,
        payload: {},
    };
}
export function getSystemsActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function getSystemsActionFailure() {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getEdgesActionRequest() {
    return <const>{
        type: ActionTypes.GET_EDGES_REQUEST,
        payload: {},
    };
}
export function getEdgesActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_EDGES_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function getEdgesActionFailure() {
    return <const>{
        type: ActionTypes.GET_EDGES_FAILURE,
        payload: {
            data: [],
        },
    };
}

export function getConnectivityEdgesActionRequest() {
    return <const>{
        type: ActionTypes.GET_CONNECTIVITY_EDGES_REQUEST,
        payload: {},
    };
}
export function getConnectivityEdgesActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_CONNECTIVITY_EDGES_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function getConnectivityEdgesActionFailure() {
    return <const>{
        type: ActionTypes.GET_CONNECTIVITY_EDGES_FAILURE,
        payload: {
            data: [],
        },
    };
}

export function getCleanUpInstancesRequest(payload: string) {
    return <const>{
        type: ActionTypes.GET_CLEANUP_INSTANCES_REQUEST,
        payload,
    };
}
export function getCleanUpInstancesSuccess(data: any[]) {
    return <const>{
        type: ActionTypes.GET_CLEANUP_INSTANCES_SUCCESS,
        payload: {
            data: data,
        },
    };
}
export function getCleanUpInstancesFailure() {
    return <const>{
        type: ActionTypes.GET_CLEANUP_INSTANCES_FAILURE,
        payload: {
            data: [],
        },
    };
}

export function removeCleanUpInstances(payload: any) {
    return <const>{
        type: ActionTypes.REMOVE_CLEANUP_INSTANCES,
        payload,
    }
}

export function removeCleanUpInstancesLoading(payload: boolean) {
    return <const>{
        type: ActionTypes.REMOVE_CLEANUP_INSTANCES_LOADING,
        payload,
    }
}


export function geObjectBytEdgesActionRequest(objectId: string) {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_REQUEST,
        payload: {
            objectId,
        },
    };
}
export function geObjectBytEdgesActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function geObjectBytEdgesActionFailure() {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function setSelectedTreeNodeAction(selectedNode: TreeNodeType | null) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_TREE_NODE,
        payload: {
            data: selectedNode,
        },
    };
}

export function getStructuresActionRequest(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_REQUEST,
        payload: {
            data: node,
        },
    };
}
// TODO
// fix the
export function getStructuresActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_SUCCESS,
        payload: {
            data: data,
        },
    };
}
export function getStructuresActionFailure() {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getSystemStructuresActionRequest(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_REQUEST,
        payload: {
            data: node,
        },
    };
}
export function getloadedModel(payload:boolean){
    
return{
    type:ActionTypes.GET_LOADED_MODEL,
    payload
    
}
}
// TODO
// fix the
export function getSystemStructuresActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_SUCCESS,
        payload: {},
    };
}
export function getSystemStructuresActionFailure() {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getAssetInstanceListActionRequest(payload: {
    node: TreeNodeType;
    enteriesPerPage?: number;
    activePage?: number;
    includeAllChild?: boolean;
}) {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST,
        payload,
    };
}

// TODO
// fix the
export function getAssetInstanceListActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_SUCCESS,
        payload: data,
    };
}
export function getAssetInstanceListActionFailure() {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function handleTreeListNodeToggleAction(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.HANDLE_TREE_LIST_NODE_TOGGLE,
        payload: {
            data: node,
        },
    };
}
export function handleAssetToggle() {
    return <const>{
        type: ActionTypes.TOGGLE_ASSET_PAGE,
        payload: {},
    };
}
export function updateNormalizedTreeListNode(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.UPDATE_NORMALIZED_TREE_LIST_NODE,
        payload: {
            data: node,
        },
    };
}
export function handleAssetInstanceActionMenuClick(
    selectedActionMenuObj: typeof ACTION_MENU_LIST[0] 
) {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST,
        payload: {
            data: selectedActionMenuObj,
        },
    };
}
export function handleAssetInstanceActionMenuClickSuccess() {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS,
        payload: {},
    };
}
export function handleAssetInstanceActionMenuClickFailure() {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE,
        payload: {},
    };
}

export function handleInstanceMonitoring() {
    return <const>{
        type: ActionTypes.HANDLE_INSTANCE_MONITORING,
    };
}

export function handleToggleApiForUpdate(latestVersion: string) {
    return <const>{
        type: ActionTypes.TOGGLE_ASSET_PAGE_FOR_UPDATE,
        payload: {
            latestVersion
        }
    };
}

// Asset structure details
export function updateGroupBy(groupBy: string) {
    return <const>{
        type: ActionTypes.UPDATE_GROUP_BY,
        payload: {
            data: groupBy,
        },
    };
}
export function updateStructure(structure: IStructure) {
    return <const>{
        type: ActionTypes.UPDATE_STRUCTURE,
        payload: {
            data: structure,
        },
    };
}
export function updateSystem(system: ISystem) {
    return <const>{
        type: ActionTypes.UPDATE_SYSTEM,
        payload: {
            data: system,
        },
    };
}
export function getSystemStructureModel() {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURE_MODELS,
        payload: {},
    };
}
export function updateSystemStructureModels(systemStructure: IStructure[]) {
    return <const>{
        type: ActionTypes.UPDATE_SYSTEM_STRUCTURE_DETAILS,
        payload: {
            data: systemStructure,
        },
    };
}

export function getConnectDetailsFromIdentityModel() {
    return <const>{
        type: ActionTypes.GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        payload: {},
    };
}
export function updateConnectDetailsFromIdentityModels(system: ISystem[]) {
    return <const>{
        type: ActionTypes.UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        payload: {
            data: system,
        },
    };
}
// TO DO - Get Systems TreeLIst
export function getSystemsBasedOnConnectModelRequest(details: any) {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_BASED_ON_CONNECT_MODEL,
        payload: {
            data: details,
        },
    };
}
export function getNewSystemStructuresActionRequest(details: any) {
    return <const>{
        type: ActionTypes.GET_NEW_SYSTEM_STRUCTURES_REQUEST,
        payload: {
            data: details,
        },
    };
}

export function handleNewTreeListNodeToggleAction(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.HANDLE_NEW_TREE_LIST_NODE_TOGGLE,
        payload: {
            data: node,
        },
    };
}

export function getNewAssetInstanceListActionRequest(payload: {
    node: TreeNodeType;
    enteriesPerPage?: number;
    activePage?: number;
    includeAllChild?: boolean;
}) {
    return <const>{
        type: ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST,
        payload,
    };
}
export function getNewAssetInstanceListActionRequestUpdate(payload: {
    node: TreeNodeType;
}) {
    return <const>{
        type: ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST_UPDATE,
        payload,
    };
}

export function searchAsset(text: string) {
    return <const>{
        type: ActionTypes.SEARCH_ASSET,
        payload: {
            data: text,
        },
    };
}

export function getAllPermisssionForAssetAction() {
    return {
        type: ActionTypes.GET_ALL_PERMISSION_ASSET,
    };
}

export function updateAssetPermission(payload: IAssetPermission) {
    return {
        type: ActionTypes.UPDATE_ASSET_PERMISSION,
        payload,
    };
}
// Filter Query Action
export function setSelectedParentTreeNodeAction(selectedNodeId: any) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_PARENT_TREE_NODE,
        payload: {
            data: selectedNodeId,
        },
    };
}

export function selectedNodeParentListAction(nodes: string[]) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_NODE_PARENT_LIST,
        payload: {
            data: nodes,
        },
    };
}

export function getFilterAssetModelTypeRequest() {
    return <const>{
        type: ActionTypes.GET_FILTER_ASSET_MODEL_TYPE_REQUEST,
    };
}

export function updateFilterAssetModelType(values: {
    assetModelTypes: string[];
    assetTypes: string[];
}) {
    return <const>{
        type: ActionTypes.UPDATE_FILTER_ASSET_MODEL_TYPE,
        payload: {
            data: values,
        },
    };
}

export function updateFilterAssetQuery(
    filteredItems: IFilteredItem[],
    applyFilter: boolean
) {
    return <const>{
        type: ActionTypes.UPDATE_FILTER_ASSET_QUERY,
        payload: {
            filteredItems: filteredItems,
            applyFilter: applyFilter,
        },
    };
}

export const handleCriticalityMonitoring = (payload: ComputeModelToJson) => {
    return <const>{
        type: ActionTypes.START_CRITICALITY_MONITORING,
        payload,
    };
};

export const deleteCustomVariableRequest = (payload: any) => {
    return <const>{
        type: ActionTypes.CUSTOM_VARIABLE_DELETE,
        payload,
    };
};
export function handleAssetTableLoading(isLoading: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_LOADING,
        payload: isLoading,
    };
}
export function handleAssetTableLoadingUpdate(isLoading: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_LOADING_UPDATE,
        payload: isLoading,
    };
}

export function updateAssetPageStatus(configurationMode: boolean) {
    return <const>{
        type: ActionTypes.UPDATE_ASSET_PAGE_STATUS,
        payload: configurationMode,
    };
}
export function updateAssetPageStatusUpdate(configurationMode: boolean) {
    return <const>{
        type: ActionTypes.UPDATE_ASSET_PAGE_STATUS_UPDATE,
        payload: configurationMode,
    };
}
export function updateModelStatus(isUpdateButton:boolean){
    debugger
    return <const>{
        type: ActionTypes.UPDATE_MODEL_STATUS,
       payload:isUpdateButton,
    };
}

export const resetAssetsFilter = () => {
    return <const>{
        type: ActionTypes.RESET_ASSETS_FILTER,
    };
};

export const updateEdgeList = (values: IEdgeList[]) => {
    return <const>{
        type: ActionTypes.UPDATE_EDGE_LIST,
        payload: {
            data: values,
        },
    };
};

export function restoreEdgeRequest(value: IEdgeList) {
    return <const>{
        type: ActionTypes.RESTORE_EDGE,
        payload: {
            data: value,
        },
    };
}
