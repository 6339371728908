export enum DurationType {
    Seconds = "Seconds",
    Minutes = "Minutes",
    Hours = "Hours",
    Days = "Days",
    Weeks = "Weeks",
}

export const HISTORY_CONFIG = <const>{
    TIME_WINDOW_ID: "timeWindow",
    SAMPLING_WINDOW_ID: "samplingWindow",
};
