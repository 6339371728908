import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';
import { AssetType } from '../../transformers/AssetType';
import AlarmTypeDetail from '../../transformers/AssetType/AlarmType';
import { AddAlarmToFunctionPayload, AddEventToFunctionPayload } from '../../store/configurationTool/types';
import EventTypeDetail from '../../transformers/AssetType/EventType';

export enum AssetsType {
    OBJECT = 'abb.controlSystem.800xA.aspectObject',
    FUNCTION = 'abb.ability.conditioningMonitor.function',
    Alarm = 'abb.alarm',
    Event = 'abb.event',
}

export const ASSET_ICON_PATH = {
    FUNCTION:
        'M15 8.39997L13.6197 7.3714L11.2068 10.5711L9.05171 7.71421H5.46291L6.71981 1.7812C6.73612 1.704 6.89645 1.70572 6.89645 1.8V2.1428H8.62064V1.8C8.62064 0.807427 7.80857 0 6.80335 0C5.95171 0 5.20689 0.600795 5.03184 1.4289L3.70164 7.7142H1.72405V9.42853H3.33869L1.90083 16.2189C1.88351 16.296 1.72404 16.2943 1.72404 16.2008V15.8572H0V16.2008C0 17.1934 0.811218 18 1.8164 18C2.66804 18 3.41372 17.3992 3.58792 16.5712L5.09996 9.42852H8.18955L9.99904 11.8285L10.1292 11.9999L7.41285 15.5999L8.79311 16.6294L11.2069 13.4287L13.6197 16.6294L15 15.5999L12.2845 11.9999L15 8.39997Z',
    OBJECT: 'M8 0L1 3.1V12.2L8 16L15 12.2V3.1L8 0ZM12.75 3.74L8 6.16L3.25 3.74L8 1.64L12.75 3.74ZM2.5 5.05L7.25 7.46V13.89L2.5 11.31V5.05ZM8.75 13.89V7.46L13.5 5.05V11.31L8.75 13.89Z',
    ALARM: 'M13.67 10.6702C13.288 10.3222 13.0491 9.84454 13 9.33016V6.17016C13.0162 5.01457 12.6478 3.88637 11.9526 2.96312C11.2575 2.03986 10.2751 1.37394 9.16 1.07016C9.16 0.762505 9.03779 0.467455 8.82024 0.249912C8.6027 0.0323702 8.30765 -0.0898437 8 -0.0898438C7.69235 -0.0898437 7.3973 0.0323702 7.17976 0.249912C6.96221 0.467455 6.84 0.762505 6.84 1.07016C5.72494 1.37394 4.74253 2.03986 4.04737 2.96312C3.35221 3.88637 2.98376 5.01457 3 6.17016V9.33016C2.95086 9.84454 2.71203 10.3222 2.33 10.6702L1 12.0002V14.0002H6C6 14.5306 6.21071 15.0393 6.58579 15.4144C6.96086 15.7894 7.46957 16.0002 8 16.0002C8.53043 16.0002 9.03914 15.7894 9.41421 15.4144C9.78929 15.0393 10 14.5306 10 14.0002H15V12.0002L13.67 10.6702Z',
    EVENT: 'M4.96632 7.47582H7.21857L5.62954 15.8509H5.62966C5.61565 15.9245 5.65536 15.9978 5.72472 16.0263C5.79407 16.0547 5.87385 16.0304 5.91565 15.9682L12.07 6.78955C12.1024 6.74115 12.1056 6.67886 12.0782 6.62745C12.0508 6.57604 11.9973 6.54397 11.9389 6.54397H9.97117L12.085 1.31648C12.1047 1.26785 12.0989 1.21262 12.0696 1.1692C12.0402 1.12566 11.9913 1.09961 11.9388 1.09961H6.76378C6.69396 1.09961 6.63236 1.14558 6.61256 1.2125L4.8151 7.2732C4.80097 7.32102 4.81012 7.37254 4.83988 7.41249C4.86975 7.45232 4.91653 7.47582 4.96632 7.47582L4.96632 7.47582Z',
};

export enum IOTypes {
    INPUT = 'input',
    OUTPUT = 'output',
    ALARM = 'alarm',
    EVENT = 'event',
}

export enum IODataTypes {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    INTEGER = 'integer',
    ARRAY_STRING = 'array_string',
    ARRAY_NUMBER = 'array_number',
    ARRAY_BOOLEAN = 'array_boolean',
    ARRAY_INTEGER = 'array_integer',
    UNDEFINED = 'undefined',
    ARRAY = 'array',
    ALARM = 'alarm',
    ARRAY_ALARM = 'array_alarm',
    EVENT = 'event',
    ARRAY_EVENT = 'array_event',
}

export interface AddDraggableOptions {
    asset: AssetType;

    position: { x: number; y: number };
    isOverallSeverityFunction?: boolean;
}

export interface AddToCanvasOptions {
    asset: Asset;
}

export type Asset = ObjectTypeDetail | FunctionTypeDetail | AlarmTypeDetail | EventTypeDetail;

export interface AlarmDetailsProps {
    nodeId: string;
    alarmName: string;
    outputs: {
        name: string;
        value?: number;
        path?: string;
        id: string;
        dataType?: IODataTypes;
        connected?: boolean;
        isPublished?: boolean;
    }[];
    inputs: { name: string; value: number; dataType: IODataTypes; id: string }[];
    inhibits: any[];
    position: {
        x: number;
        y: number;
    };
    scale: number;
    assetType: string;
    isOpen: boolean;
}
export interface Privileges {
    FUNCTION_DELETE_ALLOWED: boolean;
    OBJECT_DELETE_ALLOWED: boolean;
    FUNCTION_TRIGGER_ALLOWED: boolean;
    FUNCTION_PUBLISH_ALLOWED: boolean;
    FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED: boolean;
    FUNCTION_NAME_CHANGE_ALLOWED: boolean;
    FUNCTION_CONDITIONS_LOGIC_CHANGE_ALLOWED: boolean;
}
export const ALARM_TYPE = <const>{ GENERIC: 'Generic', SPECIFIC: 'Specific' };
export const EVENT_TYPE = <const>{ GENERIC: 'Generic', SPECIFIC: 'Specific' };
export type AlarmEventType = typeof ALARM_TYPE['GENERIC'] | typeof ALARM_TYPE['SPECIFIC'];
export interface AddAlarmPayload extends AddAlarmToFunctionPayload {
    alarmProperties: {
        name: string;
        dataType: IODataTypes;
        itemType: any;
    }[];
}

export interface AddEventPayload extends AddEventToFunctionPayload {
    eventProperties: {
        name: string;
        dataType: IODataTypes;
        itemType: any;
    }[];
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
