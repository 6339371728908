import { IODataTypes } from '../types';

export function dataTypeSymbolConverter(type: string): string | undefined {
    switch (type) {
        case IODataTypes.STRING:
            return 'ABC';

        case IODataTypes.INTEGER: {
            return 'INT';
        }
        case IODataTypes.NUMBER:
            return '123';
        case IODataTypes.BOOLEAN:
            return '01';
        case IODataTypes.ARRAY_STRING:
            return '[ABC]';
        case IODataTypes.ARRAY_INTEGER:
            return '[INT]';
        case IODataTypes.ARRAY_NUMBER:
            return '[123]';
        case IODataTypes.ARRAY_BOOLEAN:
            return '[01]';
        case IODataTypes.ALARM:
            return type;
        default:
            return 'ERR';
    }
}
