import { AssetsType } from '../components/Fabric/types';
import _ from 'lodash';

interface IFromJsonOutputVaribales {
    description: string;
    model: string;
    name: string;
    typeId: string;
    version: string;
    alarms: { name: string; type: string }[];
    events: { name: string; type: string }[];
    variables: { dataType: string; itemType: string | null; name: string }[];
}
export class OutputVariablesFromJson {
    description: string;
    model: string;
    name: string;
    typeId: string;
    version: string;
    alarms: object;
    events: object;
    variables: object;
    constructor(options: IFromJsonOutputVaribales) {
        this.model = options.model;
        this.name = options.name;
        this.version = options.version;
        this.description = options.description || '';
        this.typeId = options.typeId;
        this.alarms = {};
        this.events = {};
        this.variables = {};

        options.variables.forEach((item) => {
            _.set(this.variables, `${item.name}`, {
                dataType: item.dataType,
                path: `#${options.model}/variables/${item.name.split('.').join('/')}`,
                value: item.itemType,
            });
        });
        options.alarms.forEach((item) => {
            _.set(this.alarms, `${item.name}`, {
                dataType: 'string',
                path: `#${options.model}/alarms/${item.name.split('.').join('/')}`,
                value: null,
                type: AssetsType.Alarm,
                alarmId: `${item.name}/${item.type}`,
            });
        });
        options.events.forEach((item) => {
            _.set(this.events, `${item.name}`, {
                dataType: 'string',
                path: `#${options.model}/events/${item.name.split('.').join('/')}`,
                value: null,
                type: AssetsType.Event,
                eventId: `${item.name}/${item.type}`,
            });
        });
    }
}
