import _ from 'lodash';
import { StoreState } from '../index';

export const getSelectedCategoryTypeInAssetSelector = (store: StoreState) => {
    return _.get(store, 'assets.systemCategorySelected', false);
};
export const getNormalizedTreeNodeSelector = (store: StoreState) => {
    return _.get(store, 'assets.normalizedTreeList', {
        byId: {},
        entities: []
    });
};

export const selectedTreeNodeSelector = (store: StoreState) => {
    return _.get(store, 'assets.selectedTreeNode', null);
};
export const getSelectedAssetInstanceSelector = (store: StoreState) => {
    return _.get(
        store,
        'instanceConfig.instancesTable.tableData.selectedInstances',
        []
    );
};
export const getVirtualTreeSelector = (store: StoreState) => {
    return _.get(store, 'assets.treeVirtualList', null);
};
