import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { List } from "react-virtualized";
import { Input, Button, Checkbox } from "@abb/abb-common-ux-react";

import "./style.scss";
import {
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
    RemoveAndAddFunctionsToLibrariesPayload,
} from "../../../store/function/types";
import _ from "lodash";
import { StoreState } from "../../../store";
import { checkForEquality } from "../helper";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

interface RemoveFunctionsFromExistingLibraryProps {
    resetGlobalLibraryError: () => void;
    libraryDetails: LibraryAndNodePayload;
    onRemove: (payload: RemoveAndAddFunctionsToLibrariesPayload) => void;
}

const RemoveFunctionsFromExistingLibrary = (
    props: RemoveFunctionsFromExistingLibraryProps &
        ReturnType<typeof mapStateToProps>
) => {
    const {
        onRemove,
        libraryDetails,
        libraryGlobalError,
        resetGlobalLibraryError,
        removingFunctionsFromExistingLibrary,
    } = props;

    const [password, setPassword] = useState("" as string);
    const [functionsToRemove, setFunctionsToRemove] = useState(
        [] as UpdateActiveFunctionType[]
    );
    const [searchFilter, setSearchFilter] = useState("");
    const [passwordType, setPasswordType] = useState(true);

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const getCheckBoxValue = useCallback(
        (id: string) => {
            let checked = false;
            functionsToRemove.forEach(
                (functionDetail: UpdateActiveFunctionType) => {
                    if (functionDetail.typeId === id) {
                        checked = true;
                    }
                }
            );
            return checked;
        },
        [functionsToRemove]
    );

    const handleCheckBoxChange = useCallback(
        (functionDetail: UpdateActiveFunctionType) => {
            if (libraryGlobalError.length > 0) {
                resetGlobalLibraryError();
            }
            if (getCheckBoxValue(functionDetail.typeId)) {
                const updatedFunctionsToRemove = _.cloneDeepWith(
                    functionsToRemove
                ).filter(
                    (item: UpdateActiveFunctionType) =>
                        item.typeId !== functionDetail.typeId
                );
                setFunctionsToRemove(updatedFunctionsToRemove);
            } else {
                setFunctionsToRemove([...functionsToRemove, functionDetail]);
            }
        },
        [
            functionsToRemove,
            libraryGlobalError,
            setFunctionsToRemove,
            resetGlobalLibraryError,
        ]
    );

    const handleButtonClick = useCallback(() => {
        const payload: RemoveAndAddFunctionsToLibrariesPayload = {
            password,
            functions: functionsToRemove,
            libraryId: libraryDetails.id,
            libraryVersion: libraryDetails.libraryVersion,
            withIPProtection: libraryDetails.isIPProtected,
        };
        onRemove(payload);
    }, [onRemove, password, functionsToRemove, libraryDetails.id]);

    const updatedLibraryNodes = useMemo(
        () =>
            libraryDetails.nodes.filter((functionItem) =>
                functionItem.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            ),
        [searchFilter, libraryDetails.nodes]
    );

    const handleAllFunctionsChecked = useCallback(() => {
        if (checkForEquality(updatedLibraryNodes, functionsToRemove)) {
            setFunctionsToRemove([]);
        } else {
            setFunctionsToRemove(updatedLibraryNodes);
        }
    }, [functionsToRemove, updatedLibraryNodes, setFunctionsToRemove]);

    const functionsRenderer = ({
        key,
        index,
        isScrolling,
        isVisible,
        style,
    }: {
        key: string;
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: any;
    }) => {
        return (
            <div
                className="functions-list-item"
                key={updatedLibraryNodes[index].typeId}
            >
                <Checkbox
                    sizeClass="small"
                    value={getCheckBoxValue(updatedLibraryNodes[index].typeId)}
                    onChange={() =>
                        handleCheckBoxChange(updatedLibraryNodes[index])
                    }
                />
                <div className="function-name">
                    {updatedLibraryNodes[index].name}
                </div>
            </div>
        );
    };

    return (
        <div className="remove-functions-from-library">
            {libraryDetails.nodes.length === 0 && (
                <div>There are no functions in the library</div>
            )}

            {updatedLibraryNodes.length > 0 && (
                <Checkbox
                    sizeClass="small"
                    label="Select all"
                    value={checkForEquality(
                        updatedLibraryNodes,
                        functionsToRemove
                    )}
                    onChange={handleAllFunctionsChecked}
                />
            )}
            {libraryDetails.nodes.length > 0 && (
                <CustomABBInput
                    dataType="text"
                    value={searchFilter}
                    onValueChange={(value) => setSearchFilter(value)}
                    label="Search for Functions"
                    className="filter-ip-input"
                />
            )}

            <div className="functions-in-library-list">
                {updatedLibraryNodes && (
                    <List
                        width={318}
                        rowHeight={37}
                        rowRenderer={functionsRenderer}
                        rowCount={updatedLibraryNodes.length}
                        height={updatedLibraryNodes.length * 37}
                    />
                )}
            </div>
            {functionsToRemove.length !== 0 && libraryDetails.isIPProtected && (
                <div className="input-for-remove-lib-password">
                    <CustomABBInput
                        dataType={passwordType ? "password" : "text"}
                        label={`Enter password for ${libraryDetails.root}`}
                        value={password}
                        onValueChange={(value: string) => {
                            if (libraryGlobalError.length > 0) {
                                resetGlobalLibraryError();
                            }
                            setPassword(value);
                        }}
                        icon={passwordType ? "abb/view" : "abb/hide"}
                        onIconClick={() => {
                            if (password.length > 0 || passwordType === false) {
                                togglePasswordType();
                            }
                        }}
                        validator={() =>
                            libraryGlobalError.length > 0
                                ? { valid: false, text: libraryGlobalError }
                                : { valid: true, text: "OK!" }
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                </div>
            )}

            <div className="remove-function-from-lib-button">
                {libraryDetails.isIPProtected && (
                    <div>
                        <p>
                            <span className="note">{NOTE_MESSAGE}</span>
                            <span className="admin_help_message">
                                {ADMIN_HELP_MESSAGE}
                            </span>
                        </p>
                    </div>
                )}
                <Button
                    text={
                        removingFunctionsFromExistingLibrary
                            ? "Removing..."
                            : "Remove"
                    }
                    type="primary-blue"
                    sizeClass="small"
                    disabled={
                        !functionsToRemove.length ||
                        (!password.length && libraryDetails.isIPProtected)
                    }
                    onClick={handleButtonClick}
                    isLoading={removingFunctionsFromExistingLibrary}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        removingFunctionsFromExistingLibrary:
            state.functions.removingFunctionsFromExistingLibrary,
        libraryGlobalError: state.functions.libraryGlobalError,
    };
};

export default connect(mapStateToProps)(RemoveFunctionsFromExistingLibrary);
