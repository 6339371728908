import React from "react";
import { StoreState } from "../../../store";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import AlarmTableBody from "./AlarmTableBody";
import AlarmTableHeader from "./AlarmTableHeader";
import { FUNCTION_MODE } from "../../../utils/constants/appConstants";
import { Button } from "@abb/abb-common-ux-react";
import { Dispatch } from "redux";
import { showAlarmModal } from "../../../store/AlarmModal/action";
import { CREATE_ALARM } from "../../../utils/constants/uiConstants";
import AlarmConfigurationModal from "../../../components/AlarmConfigurationModal";
import {
    getDragTableData,
    updateAlarmMappingTableData,
    getAlarmMapping,
    getUpdatedDropTableData,
} from "../helper";
import {
    DragDropTableData,
    FunctionAlarmType,
} from "../../../store/function/types";
import { updateAlarmDragDropTables } from "../../../store/function/action";

interface AlarmFunctionTypeProps {
    showMessageModal?: any;
}

export function AlarmFunctionType(
    props: AlarmFunctionTypeProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    return props.selectedAlarmType ? (
        <div className="CustomFunctionView conditionFunctionOverview">
            {props.functionMode !== FUNCTION_MODE.VIEW && (
                <Button
                    text="Edit"
                    sizeClass="small"
                    shape="rounded"
                    type="primary-blue"
                    className="add-delete-btns"
                    onClick={() => {
                        let dragTableData = getDragTableData({
                            sourceData:
                                props.functionHeaderDetails.conditionDetails,
                        });
                        let dropTableData = props.dropTableData;
                        const dragTableMappingData =
                            updateAlarmMappingTableData({
                                dragDropTableData: {
                                    dragTableData,
                                    dropTableData: props.dropTableData,
                                },
                                alarmMapping:
                                    props.alarmMapping &&
                                    Object.keys(props.alarmMapping).length > 0
                                        ? props.alarmMapping
                                        : getAlarmMapping(
                                              props.selectedAlarmType as FunctionAlarmType,
                                              getUpdatedDropTableData(
                                                  props.alarmType
                                              )
                                          ),
                            });
                        dragTableData = dragTableMappingData.dragTableData;
                        dropTableData = dragTableMappingData.dropTableData;
                        props.updateAlarmDragDropTables({
                            dropTableData: dropTableData,
                            dragTableData: dragTableData,
                        });
                        props.showConfigurationModal();
                    }}
                />
            )}
            <div>
                <Table>
                    <AlarmTableHeader />
                    <AlarmTableBody
                        selectedAlarmType={props.selectedAlarmType}
                        functionMode={props.functionMode}
                    />
                </Table>
            </div>
        </div>
    ) : (
        <></>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedAlarmType: state.functions.selectedAlarmType,
        functionMode: state.functions.functionMode,
        functionHeaderDetails: state.functions.functionHeaderDetails,
        dragTableData: state.functions.dragTableData,
        dropTableData: state.functions.dropTableData,
        alarmMapping: state.functions.alarmMapping,
        alarmType: state.functions.alarmType,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showConfigurationModal: () => {
            dispatch(
                showAlarmModal({
                    component: AlarmConfigurationModal,
                    modalTitle: CREATE_ALARM.NEW_ALARM,
                    data: {
                        submitBtnText: CREATE_ALARM.CREATE,
                        mode: "CREATE",
                    },
                    customClassName: "alarm-configuration-dialog-class",
                })
            );
        },

        updateAlarmDragDropTables: (payload: DragDropTableData) => {
            dispatch(updateAlarmDragDropTables(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlarmFunctionType);
