import { AlarmEventType, ALARM_TYPE } from '../../../components/Fabric/types';

interface Params {
    nodeId: string;
    modelId: string;
    eventId: string;
    ioName: string;
    isEventProperty: boolean;
    eventType: AlarmEventType;
}

export function generateEventId(options: Params) {
    const { nodeId, ioName, eventId, modelId, isEventProperty, eventType } = options;
    return `${nodeId}${
        eventType === ALARM_TYPE.GENERIC ? '*' : ''
    }#/${modelId}/events/eventDefinitionId/${eventId}/${isEventProperty ? 'fields/' : ''}${ioName}`;
}
// same format is used by output finder
export function generateEventInputPath(options: {
    nodeId: string;
    ioType: string;
    ioName: string;
}) {
    const { nodeId, ioType, ioName } = options;
    return `${nodeId}.${ioType}.${ioName}`;
}

export function generateEventInputId(options: { nodeId: string; ioType: string; ioName: string }) {
    const { nodeId, ioName, ioType } = options;
    return `#properties/eventTypes/${nodeId}/${ioType}/${ioName}`;
}
