import {
    setActiveSettingTab,
    updateInfoModelInstanceRequest,
    updateTypeDefinitionRequest,
    getTypeDefinitionRequest,
    deleteInfoModelInstanceRequest,
    showProgresRequest,
    getInfoModelInstancesRequest,
    saveInfoModelInstanceRequest,
} from './action';

export interface SettingsState {
    activeSettingTab: SettingsLeftNavOptions;
    infoModelInstance: IInfoModelInstance | undefined;
    identityModel: IInfoModelInstance[];
    newDefinedType: IInfoModelInstance | undefined;
    showModalProgress: boolean;
}
export enum SettingsLeftNavOptions {
    CONNECT_MODELS = 'Connect Models',
    IDENTITY = 'Identity',
    SUPPORTED_CONDITION_MONITOR_MODELS = 'Supported ConditionMonitor Model',
    DELETE_INSTANCE = 'Delete Instance',
    RESTORE_TYPES = 'Restore Types to SCE Engine',
    SCE_CLEAN_UP = 'SCE Clean Up'
}

export interface IInfoModelInstance {
    description?: string;
    typeId?: string;
    model: string;
    name: string;
    objectId: any;
    isDeleted?: string;
    properties: {
        supportedModels?: any;
    };
    tenantId: any;
    type: string;
    version: string;
}

export type Actions = ReturnType<
    | typeof setActiveSettingTab
    | typeof getInfoModelInstancesRequest
    | typeof saveInfoModelInstanceRequest
    | typeof getTypeDefinitionRequest
    | typeof updateInfoModelInstanceRequest
    | typeof updateTypeDefinitionRequest
    | typeof deleteInfoModelInstanceRequest
    | typeof showProgresRequest
>;
