import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { List } from "react-virtualized";
import { Input, Button, Checkbox } from "@abb/abb-common-ux-react";

import { StoreState } from "../../../store";
import {
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
    RemoveAndAddFunctionsToLibrariesPayload,
} from "../../../store/function/types";
import { checkForEquality } from "../helper";
import Loader from "../../../components/Loader";
import Condition from "../../../components/shared/Condition";
import { FunctionDetailsRequiredByLibrary } from "../CreateLibraryPopup";
import { checkWhetherFunctionIsSelected } from "../../../utils/helpers";
import {
    ADMIN_HELP_MESSAGE,
    NOTE_MESSAGE,
} from "../../../utils/constants/appConstants";
import "../EncryptLibraries/style.scss";
import CustomABBInput from "../../../components/CustomABBInput";

interface AddFunctionsToExistingLibraryModalProps {
    libraryDetails: LibraryAndNodePayload;
    getMutuallyExclusiveFunctions: () => void;
    onAdd: (payload: RemoveAndAddFunctionsToLibrariesPayload) => void;
    resetGlobalLibraryError: () => void;
}

const AddFunctionsToExistingLibraryModal = (
    props: AddFunctionsToExistingLibraryModalProps &
        ReturnType<typeof mapStateToProps>
) => {
    const {
        onAdd,
        libraryDetails,
        libraryGlobalError,
        resetGlobalLibraryError,
        getMutuallyExclusiveFunctions,
        mutuallyExclusiveFunctionTypes,
        addingFunctionsToExistingLibrary,
        mutuallyExclusiveFunctionTypesLoader,
    } = props;

    const [functionList, setSelectedFunctionList] = useState(
        [] as FunctionDetailsRequiredByLibrary[]
    );
    const [password, setPassword] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [passwordType, setPasswordType] = useState(true);
    const [passwordError, setPasswordError] = useState("");

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const handleCheckboxChange = useCallback(
        (item: UpdateActiveFunctionType) => {
            const { name, model, typeId, version, description, tags } = item;
            const requiredItem = {
                name,
                model,
                typeId,
                version,
                description,
                tags,
            };
            if (libraryGlobalError.length > 0) {
                setPasswordError("");
            }
            if (checkWhetherFunctionIsSelected(item, functionList)) {
                const updatedFunctionList = functionList.filter(
                    (functionDetail) =>
                        !(
                            functionDetail.model === model &&
                            functionDetail.name === name &&
                            functionDetail.typeId === typeId &&
                            functionDetail.version === version
                        )
                );
                setSelectedFunctionList(updatedFunctionList);
            } else {
                setSelectedFunctionList([...functionList, requiredItem]);
            }
        },
        [functionList, libraryGlobalError, resetGlobalLibraryError]
    );

    useEffect(() => {
        if (
            libraryGlobalError.length > 0 &&
            !addingFunctionsToExistingLibrary
        ) {
            setPasswordError(libraryGlobalError);
        }
    }, [libraryGlobalError, addingFunctionsToExistingLibrary]);

    const handleButtonClick = useCallback(() => {
        const payload: RemoveAndAddFunctionsToLibrariesPayload = {
            password,
            functions: functionList,
            libraryId: libraryDetails.id,
            libraryVersion: libraryDetails.libraryVersion,
            withIPProtection: libraryDetails.isIPProtected,
            dragAndDrop: true,
        };
        onAdd(payload);
    }, [onAdd, password, functionList, libraryDetails.id]);

    const updatedMutuallyExclusiveFunctionTypes = useMemo(
        () =>
            mutuallyExclusiveFunctionTypes.filter((functionItem) =>
                functionItem.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
            ),
        [searchFilter, mutuallyExclusiveFunctionTypes]
    );

    const handleAllFunctionsChecked = useCallback(() => {
        if (
            checkForEquality(
                updatedMutuallyExclusiveFunctionTypes,
                functionList
            )
        ) {
            if (searchFilter.length) {
                setSelectedFunctionList(
                    functionList.filter(
                        (item) =>
                            !updatedMutuallyExclusiveFunctionTypes.find(
                                (ele) => ele.typeId === item.typeId
                            )
                    )
                );
            } else {
                setSelectedFunctionList([]);
            }
        } else {
            if (searchFilter.length) {
                setSelectedFunctionList([
                    ...functionList,
                    ...updatedMutuallyExclusiveFunctionTypes,
                ]);
            } else {
                setSelectedFunctionList(updatedMutuallyExclusiveFunctionTypes);
            }
        }
    }, [
        functionList,
        setSelectedFunctionList,
        updatedMutuallyExclusiveFunctionTypes,
    ]);

    useEffect(() => {
        getMutuallyExclusiveFunctions();
    }, []);

    const functionsRenderer = ({
        key,
        index,
        isScrolling,
        isVisible,
        style,
    }: {
        key: string;
        index: number;
        isScrolling: boolean;
        isVisible: boolean;
        style: any;
    }) => {
        return (
            <div
                key={
                    updatedMutuallyExclusiveFunctionTypes[index].typeId +
                    updatedMutuallyExclusiveFunctionTypes[index].model +
                    updatedMutuallyExclusiveFunctionTypes[index].name +
                    updatedMutuallyExclusiveFunctionTypes[index].version
                }
                className="function-label"
            >
                <Checkbox
                    sizeClass="small"
                    value={checkWhetherFunctionIsSelected(
                        updatedMutuallyExclusiveFunctionTypes[index],
                        functionList
                    )}
                    onChange={() =>
                        handleCheckboxChange(
                            updatedMutuallyExclusiveFunctionTypes[index]
                        )
                    }
                />
                {updatedMutuallyExclusiveFunctionTypes[index].name}
            </div>
        );
    };

    return (
        <div>
            <h4 className="function-select">Select Functions</h4>
            {updatedMutuallyExclusiveFunctionTypes.length > 0 && (
                <Checkbox
                    sizeClass="small"
                    label="Select all"
                    value={checkForEquality(
                        updatedMutuallyExclusiveFunctionTypes,
                        functionList
                    )}
                    onChange={handleAllFunctionsChecked}
                    disabled={mutuallyExclusiveFunctionTypesLoader}
                />
            )}

            {mutuallyExclusiveFunctionTypes.length > 0 && (
                <CustomABBInput
                    dataType="text"
                    value={searchFilter}
                    onValueChange={(value) => setSearchFilter(value)}
                    disabled={mutuallyExclusiveFunctionTypesLoader}
                    label="Search for Functions"
                    className="filter-ip-input"
                />
            )}

            <div className="functions-list">
                <Condition when={mutuallyExclusiveFunctionTypesLoader}>
                    <Loader type="radial" sizeClass="small" />
                </Condition>
                <Condition when={!mutuallyExclusiveFunctionTypesLoader}>
                    <List
                        width={318}
                        height={
                            updatedMutuallyExclusiveFunctionTypes.length * 37
                        }
                        rowHeight={37}
                        rowRenderer={functionsRenderer}
                        rowCount={updatedMutuallyExclusiveFunctionTypes.length}
                    />
                </Condition>
            </div>

            {functionList.length !== 0 && libraryDetails.isIPProtected && (
                <React.Fragment>
                    <div className="password">
                        Enter password for {libraryDetails.root}
                    </div>
                    <CustomABBInput
                        placeholder="Please enter password"
                        dataType={passwordType ? "password" : "text"}
                        value={password}
                        onValueChange={(value: string) => {
                            setPasswordError("");
                            setPassword(value.trim());
                        }}
                        icon={passwordType ? "abb/view" : "abb/hide"}
                        onIconClick={() => {
                            if (password.length > 0 || passwordType === false) {
                                togglePasswordType();
                            }
                        }}
                        validator={() =>
                            passwordError.length > 0
                                ? { valid: false, text: passwordError }
                                : { valid: true, text: "OK!" }
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                </React.Fragment>
            )}

            {!(functionList.length !== 0 && libraryDetails.isIPProtected) &&
                passwordError.length > 0 && (
                    <div className="import-error ">{passwordError}</div>
                )}

            <div className="button-section">
                {libraryDetails.isIPProtected && (
                    <div>
                        <p>
                            <span className="note">{NOTE_MESSAGE}</span>
                            <span className="admin_help_message">
                                {ADMIN_HELP_MESSAGE}
                            </span>
                        </p>
                    </div>
                )}
                <Button
                    text={
                        addingFunctionsToExistingLibrary ? "Adding..." : "Add"
                    }
                    sizeClass="small"
                    type="primary-blue"
                    disabled={
                        functionList.length === 0 ||
                        (password.length === 0 && libraryDetails.isIPProtected)
                    }
                    isLoading={addingFunctionsToExistingLibrary}
                    onClick={handleButtonClick}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (store: StoreState) => {
    return {
        mutuallyExclusiveFunctionTypes:
            store.functions.mutuallyExclusiveFunctionTypes,
        mutuallyExclusiveFunctionTypesLoader:
            store.functions.mutuallyExclusiveFunctionTypesLoader,
        addingFunctionsToExistingLibrary:
            store.functions.addingFunctionsToExistingLibrary,
        libraryGlobalError: store.functions.libraryGlobalError,
    };
};

export default connect(mapStateToProps)(AddFunctionsToExistingLibraryModal);
