let errorMessages = {
    ENTER_NAME: "Please Enter Function Name",
    LONG: "too long",
    SHORT: "too short",
    SPACE_ERROR: "Space not allowed",
};

const validateLength = (val: string, maxLen: number, minLength?: number) => {
    if (val.length > maxLen) {
        return errorMessages.LONG;
    }
    if (minLength && val.length < minLength) {
        return errorMessages.SHORT;
    } else {
        return "";
    }
};

export const getNameError = (
    val: string,
    maXlength: number,
    minLength?: number
) => {
    let err = "";
    let error = validateLength(val, maXlength);
    if (!val) {
        err = errorMessages.ENTER_NAME;
    } else if (error !== "") {
        err =
            error === errorMessages.LONG
                ? `Function Name should be less than ${maXlength} characters`
                : `Function Name should be more than ${
                      minLength && minLength
                  } characters`;
    } else if (/\s/.test(val)) {
        return errorMessages.SPACE_ERROR;
    }
    return err;
};
