import { sce } from 'sce-engg-model-19.09';
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { put, takeLatest, all, select } from 'redux-saga/effects';
import {
    exportModels,
    getFunctionJson,
    importFunctions,
    showSummaryModal,
    saveModelsToState,
    toggleModelSpinner,
    getFunctionVersions,
    storeFunctionsVersions,
    computeModelsForExport,
    importSingleType,
    updateSingleImportFunctionTypes,
    handleFilesToBeImported,
    loadChosenFileData,
    storeFilesToImportInReducer,
    setImportValidationStatus,
    toggleTypeIdDuringImport,
    handleTypesImport,
    setDetailsForTypeLevelImport,
    getSelectedFunctionTypeForExport,
    updateSelectedFunctionTypeForExport,
    saveModelsToExport,
    updateExportableModelsList,
} from '../actions';
import { ZippedModel, functionTypeForJsonVersion } from '../types';
import { ActionTypes } from '../../actionTypes';
import {
    isNilOrEmpty,
    getJsonArrayFromZip,
    abbSDKGetErrorMessage,
    isImportedZipFileValid,
    overRideConditionForTypeJson,
    getUpdatedConditionsForExport,
    checkValidityForZipFolder,
} from '../../../utils/helpers';
import AppSettings from '../../../services/AppSettings';
import {
    SAVE_OPTIONS,
    EXPORT_MODELS_MODES,
    CONFIGURATION_TOOL_MODE,
    NOTIFICATION_MODAL_STATUS,
    ROUTE_PATHNAME,
} from '../../../utils/constants/appConstants';
import {
    updateConfigurationToolMode,
    createConfigurationToolJson,
    updateOverallSeverityFunctionId,
    updateOverAllSeverityFunctionStatus,
} from '../../configurationTool/actions';
import { setActiveModalForImport, saveComputeModelStore } from '../../modelsPage/actions';
import { StoreState } from '../..';
import {
    CreateFunctionAssetDetails,
    updateSelectedFunctionType,
    FunctionProperties,
    successFunctionSave,
} from '../../function/action';
import { showNotificationModal } from '../../notificationModal/action';
import { ComputeModelToJson } from '../../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { ComputeModelToJsonConstructorOptions } from '../../../transformers/ComputeModel/toJson/types';
import { ComputeModelFromJson } from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { validateImportedJson } from '../../../utils/validation/validateImportedJson';
import _ from 'lodash';

function* getFunctionTypeVersions(action: ReturnType<typeof getFunctionVersions>) {
    yield put(toggleModelSpinner(true));
    const { model, typeId, name, description, tags } = action.payload;
    try {
        const res = yield sce.getTypeVersions({
            modelId: model,
            typeId,
        });
        if (res) {
            const functionWithVersion = {
                name,
                model,
                typeId,
                versions: res.details,
                description,
                tags,
            };
            yield put(storeFunctionsVersions([functionWithVersion]));
            yield put(toggleModelSpinner(false));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

const handleMParallelCalls = (payloadToGetJson: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await sce.getFunctionTypes(payloadToGetJson);
            resolve(result);
        } catch (error) {
            resolve({ success: false, error: error });
        }
    });
};

function* getJsonForFunctions(action: ReturnType<typeof getFunctionJson>) {
    yield put(toggleModelSpinner(true));
    const res = yield all(
        action.payload.map((functionDetailsForJson: functionTypeForJsonVersion, index: number) => {
            const payloadToGetJson: any = {
                typeInformation: functionDetailsForJson,
            };
            return handleMParallelCalls(payloadToGetJson);
        })
    );
    var zip = new JSZip();
    let functionJson: any = {};
    res.forEach((res: any) => {
        if (res.status && res.status === 200) {
            functionJson = Object.values(res.details)[0];
            const fname = functionJson.name + '_' + functionJson.version + '.json';
            zip.file(fname, JSON.stringify(functionJson));
        }
    });
    let zipFolderName = functionJson.name;
    if (res.length > 1) {
        zipFolderName = 'multipleFunctions';
    }
    yield put(toggleModelSpinner(false));
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, `${zipFolderName}.zip`);
    });
}

function* importFunctionJson(action: ReturnType<typeof importFunctions>) {
    const { filesToBeImported } = action.payload;

    try {
        const files = yield getJsonArrayFromZip(filesToBeImported);

        if (files) {
            yield put(toggleModelSpinner(true));
            const res = yield sce.importFunctionTypes(files);
            if (res) {
                const payload: any = {
                    summaryModalDetails: {
                        showSummaryModal: true,
                        mode: 'IMPORT',
                        route: 'FUNCTIONS',
                        data: res,
                    },
                };

                if (res.successDetails.length > 0) {
                    const functionData: any = JSON.parse(res.successDetails[0].dataOut.content);
                    const {
                        model,
                        typeId,
                        version,
                        name,
                        description,
                        tags,
                        isExtensible,
                        properties,
                    } = functionData;
                    const functionProperties: FunctionProperties = {
                        inputs: properties.inputs,
                        outputs: properties.outputs,
                        settings: { endpoint: properties.endpoint },
                    };
                    const functionTypeDetails = {
                        model: model,
                        typeId: typeId,
                        version: version,
                        name: name,
                        description: description,
                        tags: [...tags],
                        properties: { ...functionProperties },
                        isExtensible: isExtensible,
                    } as CreateFunctionAssetDetails;

                    yield put(successFunctionSave(functionData));
                    yield put(
                        updateSelectedFunctionType({
                            typeDetails: functionTypeDetails,
                        })
                    );
                }
                yield put(toggleModelSpinner(false));
                yield put(showSummaryModal(payload));
                yield put(handleFilesToBeImported({ filesToBeImported: {} }));
            }
        } else {
            yield put(
                showNotificationModal({
                    title: 'IMPORT FILE ERROR',
                    details: ['Trying to import some corrupted file or file with wrong format'],
                    type: 'confirmation',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                })
            );
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [JSON.stringify(error)],
            })
        );
    }
}

function* exportModelsArray(action: ReturnType<typeof exportModels>) {
    yield put(toggleModelSpinner(true));
    const state: StoreState = yield select();

    const { mode, selectedModalDetails } = action.payload;

    let updatedModelJson = [];
    if (mode === EXPORT_MODELS_MODES.MODEL) {
        updatedModelJson = selectedModalDetails.map((model: ComputeModelFromJson) => {
            const modelToSend: ComputeModelToJsonConstructorOptions = {
                ...model,
                json: model.json,
                saveOptions: { value: SAVE_OPTIONS.PUBLISH },
                overallSelectedFunctionId:
                    state.modelsPage.activeModel.modelInstance.overAllSeverityFunctionId || '',
            };
            const computeModelJson = new ComputeModelToJson(modelToSend);
            return computeModelJson;
        });
    } else {
        updatedModelJson = selectedModalDetails;
    }
    updatedModelJson = getUpdatedConditionsForExport(updatedModelJson);

    try {
        const response = yield sce.getExportableTypes(updatedModelJson);
        const res = response.details;
        debugger;
        if (res) {
            yield put(toggleModelSpinner(false));
            var zip = new JSZip();
            let zipFolderName = 'models';
            res.successDetails.forEach((modelExportable: any) => {
                const fileName =
                    modelExportable.dataOut.model.properties.model.name.value +
                    '_' +
                    modelExportable.dataOut.model.properties.model.version.value +
                    '.json';
                zipFolderName = modelExportable.dataOut.model.properties.model.name.value;
                zip.file(fileName, JSON.stringify(modelExportable.dataOut));
            });
            if (res.dependentLibraries.length > 0) {
                const fileName = 'dependentLibraries.json';
                zip.file(fileName, JSON.stringify(res.dependentLibraries));
            }
            if (res.successDetails.length > 1) {
                zipFolderName = 'multipleModels';
            }
            if (state.exportAsset.zipFileName.length > 0) {
                zipFolderName = state.exportAsset.zipFileName;
            }
            const payload: any = {
                summaryModalDetails: {
                    showSummaryModal: true,
                    mode: 'EXPORT',
                    route: 'TYPES',
                    data: res,
                },
            };
            yield put(showSummaryModal(payload));
            if (
                res.successDetails.length > 0 &&
                (res.failureDetails.length === 0 || res.failureDetails.length > 0)
            ) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    FileSaver.saveAs(content, `${zipFolderName}.zip`);
                });
            }
            // if (res.failureDetails.length === 0) {
            //     const hidden: any = {
            //         summaryModalDetails: {
            //             showSummaryModal: false,
            //             mode: 'EXPORT',
            //             route: 'TYPES',
            //             data: res,
            //         },
            //     };
            //     yield put(showSummaryModal(hidden));
            // }
            // if (res.failureDetails.length && res.successDetails.length) {
            //     const hidden: any = {
            //         summaryModalDetails: {
            //             showSummaryModal: false,
            //             mode: 'EXPORT',
            //             route: 'TYPES',
            //             data: res,
            //         },
            //     };
            //     yield put(showSummaryModal(hidden));
            //     res.successDetails = [];
            //     const successHidden: any = {
            //         summaryModalDetails: {
            //             showSummaryModal: true,
            //             mode: 'EXPORT',
            //             route: 'TYPES',
            //             data: res,
            //         },
            //     };
            //     yield put(showSummaryModal(successHidden));
            // }
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    } finally {
        yield put(saveModelsToExport([]));
    }
}

function* getModels(action: ReturnType<typeof computeModelsForExport>) {
    //yield put(toggleModelSpinner(true));
    const { objectRef, asset, uniqueTypeId } = action.payload;
    try {
        const res = yield sce.getModels(objectRef, AppSettings.IsCOD);
        if (res) {
            yield put(toggleModelSpinner(false));
            let payload = {};
            payload[asset.assetName] = res.details;
            payload['uniqueTypeId'] = uniqueTypeId;
            const actionPayload = {
                fetchedModals: [payload],
            };
            yield put(saveModelsToState(actionPayload));
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* importForSingleType(action: ReturnType<typeof importSingleType>) {
    yield put(toggleModelSpinner(true));
    const { zippedModel, associatedObjectType } = action.payload;
    try {
        const updatedAssociatedObjectType =
            {} as ComputeModelToJson['properties']['associatedObjectType'];
        Object.keys(associatedObjectType).forEach((item: string) => {
            updatedAssociatedObjectType[item] = {
                value: associatedObjectType[item],
            };
        });
        try {
            const { model, typeId, version } = action.payload.associatedObjectType;

            const tenantResponse = yield sce.getTenantSpecificType(
                action.payload.zippedModel.model.properties.associatedObjectType.model.value || '',
                action.payload.zippedModel.model.properties.associatedObjectType.typeId.value || '',
                action.payload.zippedModel.model.properties.associatedObjectType.version.value || ''
            );
            if (tenantResponse.status === 200) {
                const isAssociatedObjectTypeMatched =
                    model ===
                        action.payload.zippedModel.model.properties.associatedObjectType.model
                            .value &&
                    typeId ===
                        action.payload.zippedModel.model.properties.associatedObjectType.typeId
                            .value &&
                    version ===
                        action.payload.zippedModel.model.properties.associatedObjectType.version
                            .value;

                yield put(
                    toggleTypeIdDuringImport({
                        showDisabled: isAssociatedObjectTypeMatched,
                        modelDetails: action.payload.zippedModel.model.properties.model,
                    })
                );

                if (!isAssociatedObjectTypeMatched) {
                    yield put(
                        showNotificationModal({
                            title: 'Type ID Conflict',
                            resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                            type: 'confirmation',
                            details: [
                                'TypeId of associated object type does not matches with the model to be imported. Please change the type ID of model to be imported',
                            ],
                        })
                    );
                }
            }
        } catch (error) {
        } finally {
            const res: ZippedModel = yield sce.createImportableModel(
                zippedModel,
                updatedAssociatedObjectType
            );
            yield put(updateSingleImportFunctionTypes(res.functionTypes));
            yield put(toggleModelSpinner(false));
            yield put(
                updateConfigurationToolMode({
                    mode: CONFIGURATION_TOOL_MODE.IMPORT,
                })
            );
            yield put(
                createConfigurationToolJson({
                    json: { assetData: [], connectionData: [] },
                })
            );
            const typeJson = { ...res.model } as ComputeModelToJson;
            const functionTypes = res.functionTypes;
            let overRiddenTypeJson = overRideConditionForTypeJson({
                functionTypes: functionTypes,
                typeJson: typeJson,
            });

            Object.keys(overRiddenTypeJson.properties.functions).forEach((key) => {
                //@ts-ignore
                overRiddenTypeJson.properties.functions[key].inhibit = {
                    ...{
                        referenceValue: {
                            dataType: 'boolean',
                        },
                        actualValue: {
                            dataType: 'boolean',
                        },
                    },
                    ...overRiddenTypeJson.properties.functions[key].inhibit,
                };
            });
            const computeModelJson = new ComputeModelFromJson(overRiddenTypeJson);
            if (computeModelJson.overAllSeverityFunctionId) {
                yield put(
                    updateOverallSeverityFunctionId(computeModelJson.overAllSeverityFunctionId)
                );
                yield put(updateOverAllSeverityFunctionStatus(true));
            }
            yield put(
                createConfigurationToolJson({
                    json: computeModelJson.json,
                    mode: CONFIGURATION_TOOL_MODE.IMPORT,
                })
            );
            yield put(setActiveModalForImport(computeModelJson));
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* loadFileDataToBeImported(action: ReturnType<typeof loadChosenFileData>) {
    const targetFileData: FileList | undefined = action.payload;

    if (targetFileData && !isNilOrEmpty(targetFileData)) {
        const isValid = isImportedZipFileValid(targetFileData[0]);
        if (isValid) {
            try {
                const files = yield getJsonArrayFromZip(targetFileData[0]);
                const currentRoute = window.location.pathname.includes(ROUTE_PATHNAME.FUNCTIONS)
                    ? ROUTE_PATHNAME.FUNCTIONS
                    : ROUTE_PATHNAME.HOME;
                const {
                    isValid: isJsonValid,
                    data: { files: payloadFiles, dependentLibraries },
                } = validateImportedJson(files, currentRoute);
                if (payloadFiles.length > 0 && checkValidityForZipFolder(files) && isJsonValid) {
                    yield put(setImportValidationStatus(true));
                    yield put(
                        storeFilesToImportInReducer({
                            filesToBeImported: payloadFiles,
                            dependantLibrariesForTypes: dependentLibraries,
                        })
                    );
                } else {
                    yield put(setImportValidationStatus(false));
                    yield put(
                        storeFilesToImportInReducer({
                            filesToBeImported: [],
                            dependantLibrariesForTypes: [],
                        })
                    );
                }
            } catch (error) {
                console.log('load file error from zip:', error);
                yield put(setImportValidationStatus(false));
            }
        }
    } else {
        yield put(setImportValidationStatus(true));
        yield put(
            storeFilesToImportInReducer({
                filesToBeImported: [],
                dependantLibrariesForTypes: [],
            })
        );
    }
}

function* handleImportTypeSaga(action: ReturnType<typeof handleTypesImport>) {
    const zippedModels = action.payload;
    try {
        const state: StoreState = yield select();
        yield put(toggleModelSpinner(true));
        const res = yield sce.importTypes(zippedModels);
        yield put(toggleModelSpinner(false));
        const responseDetail = _.cloneDeepWith(res.details);
        const libraryDetails: {
            libraryId: string;
            libraryName: string;
            libraryVersion: string;
            status: string;
            reason: string;
        }[] = [];
        const typeDetails: any[] = [];
        if (
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT &&
            responseDetail.successDetails.length
        ) {
            let modelIndex = 0;
            responseDetail.successDetails.forEach((item: any, index: number) => {
                if (item.dataOut && item.dataOut.model && typeof item.dataOut.model !== 'string') {
                    modelIndex = index;
                    typeDetails.push(item);
                } else {
                    if (item.dataOut) {
                        libraryDetails.push({
                            libraryId: item.dataIn.libraryId,
                            libraryName: item.dataIn.libraryName,
                            libraryVersion: item.dataIn.libraryVersion,
                            status: item.status,
                            reason: (item.reason as any[]).join(),
                        });
                    }
                }
            });
            if (!responseDetail.successDetails[modelIndex].dataOut.model.version) {
                let model = responseDetail.successDetails[modelIndex].dataOut.model;
                model.version = Number((model.properties.model.version.value).split('.')[0]);
            }
            Object.keys(responseDetail.successDetails[modelIndex].dataOut
                .model.properties.functions).forEach((key) => {
                //@ts-ignore
                responseDetail.successDetails[modelIndex].dataOut
                .model.properties.functions[key].inhibit = {
                    ...{
                        referenceValue: {
                            dataType: 'boolean',
                        },
                        actualValue: {
                            dataType: 'boolean',
                        },
                    },
                    ...responseDetail.successDetails[modelIndex].dataOut
                .model.properties.functions[key].inhibit,
                };
            });
            const updatedJson = new ComputeModelFromJson(
                responseDetail.successDetails[modelIndex].dataOut.model
            );
            yield put(
                updateExportableModelsList({
                    currentItem: responseDetail.successDetails[modelIndex].dataOut
                        .model as ComputeModelToJson,
                    type: 'Update',
                })
            );
            yield put(
                updateConfigurationToolMode({
                    mode: CONFIGURATION_TOOL_MODE.VIEW,
                })
            );
            yield put(createConfigurationToolJson({ json: updatedJson.json }));
            yield put(
                setDetailsForTypeLevelImport({
                    dependantLibraries: [],
                    passwordDetails: [],
                    selectedItemsForImport: [],
                })
            );
            yield put(saveComputeModelStore({ computeModel: updatedJson }));
        }

        if (responseDetail.successDetails) {
            responseDetail.successDetails = [...typeDetails];
        }
        responseDetail.libraryDetails = [...libraryDetails];

        if (responseDetail) {
            const payload: any = {
                summaryModalDetails: {
                    showSummaryModal: true,
                    mode: 'IMPORT',
                    route: 'TYPES',
                    data: responseDetail,
                },
            };
            yield put(showSummaryModal(payload));
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* selectedFunctionTypeForExport(
    action: ReturnType<typeof getSelectedFunctionTypeForExport>
) {
    try {
        const {
            selectedFunction: { assetType, assetRef, assetVersion },
        } = action.payload;
        const response = yield sce.getTypeDetails(assetType, assetRef, assetVersion);

        if (response.status === 200) {
            yield put(updateSelectedFunctionTypeForExport({ typeDetails: response.details }));
        }
    } catch (err) {
        console.log(err.message);
    }
}

export default [
    takeLatest(ActionTypes.GET_FUNCTION_VERSIONS, getFunctionTypeVersions),
    takeLatest(ActionTypes.GET_FUNCTION_JSON, getJsonForFunctions),
    takeLatest(ActionTypes.IMPORT_FUNCTIONS, importFunctionJson),
    takeLatest(ActionTypes.EXPORT_MODELS, exportModelsArray),
    takeLatest(ActionTypes.COMPUTE_MODELS_API_FOR_EXPORT, getModels),
    takeLatest(ActionTypes.IMPORT_FOR_SINGLE_TYPE, importForSingleType),
    takeLatest(ActionTypes.LOAD_CHOSEN_FILE_DATA, loadFileDataToBeImported),
    takeLatest(ActionTypes.HANDLE_TYPES_IMPORT, handleImportTypeSaga),
    takeLatest(ActionTypes.GET_SELECTED_FUNCTION_TYPE_FOR_EXPORT, selectedFunctionTypeForExport),
];
