import React, { useState, useEffect } from "react";
import { Input, Icon, Checkbox } from "@abb/abb-common-ux-react";

import { showDialog, hideDialog } from "../../../store/dialog/action";
import MessageModal from "../../../components/MessageModal";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import _ from "lodash";
import { IConnectModelTableRow } from "../../../routes/Settings/SupportedTabs/ConnectModelsTab";
import {
    VALIDATION_MESSAGE,
    CONFIRMATION_BUTTON,
    CONFIRMATION_TITLE,
    CONFIRMATION_ENDPOINT_DELETE,
} from "../../../utils/constants/uiConstants";
import { DEFAULT_CONECT_MODELS } from "../../../utils/constants/appConstants";
import Condition from "../../shared/Condition";
import { validateForSwedishAndFinishCharacters } from "../../../routes/Functions/helper";
import CustomABBInput from "../../CustomABBInput";

interface SupportedModelTableProps {
    selectAll: boolean;
    handleSelectAll: (isSelected: boolean) => void;
    handleSelectedItem: (isSelected: boolean, index: any) => void;
    tableData: IConnectModelTableRow[];
    isValid: boolean;
    onUpdate: (tableData: any[]) => void;
    showConfirmationDialog: (
        updateTableData: any,
        tableData: IConnectModelTableRow[],
        onUpdate: any,
        hasError: boolean,
        itemIndex: number,
        errorIndex: number | undefined,
        setHasError: any,
        setErrorIndex: any
    ) => void;
}

function SupportedModelTable(props: SupportedModelTableProps) {
    const [tableData, updateTableData] = useState<IConnectModelTableRow[]>([]);
    const [hasError, setHasError] = useState(false);
    const [showCheckbox, updateShowCheckbox] = useState(true);
    const [errorIndex, setErrorIndex] = useState<undefined | number>(undefined);

    useEffect(() => {
        updateTableData(props.tableData);
        const isExist =
            props.tableData.filter(
                (x) => !DEFAULT_CONECT_MODELS.find((item) => x.modelId == item)
            ).length > 0;
        updateShowCheckbox(isExist);
    }, [props.tableData]);

    const validateCurrentPropertyValue = (
        itemIndex: number,
        currentItem: IConnectModelTableRow
    ) => {
        let isModelIdValid = true;
        let isModelNameValid = true;
        if (currentItem.modelId.length > 150) {
            currentItem["errorModelId"] =
                "Model Id can not have more than 150 characters.";
            isModelIdValid = false;
        }
        if (currentItem.modelName.length === 0) {
            currentItem["errorModelName"] =
                VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE;
            isModelNameValid = false;
        }
        if (currentItem.modelId.length === 0) {
            currentItem["errorModelId"] =
                VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE;
            isModelIdValid = false;
        }

        //et noSplCharPatternforModelName = new RegExp(/'^(?!\\s*$)[\\w\\-]{1,150}$]'/);
        if (!validateForSwedishAndFinishCharacters(currentItem.modelName)) {
            if (currentItem.modelName.length > 150) {
                currentItem["errorModelName"] =
                    "Model Name can not have more than 150 characters.";
            } else {
                currentItem["errorModelName"] =
                    VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
            }

            isModelNameValid = false;
        }
        let noSplCharPatternforModelId = new RegExp(
            /[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/
        );
        if (noSplCharPatternforModelId.test(currentItem.modelId)) {
            currentItem["errorModelId"] = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
            isModelIdValid = false;
        }

        tableData.forEach((item, index) => {
            if (index !== itemIndex) {
                if (item.modelId === currentItem.modelId && isModelIdValid) {
                    currentItem["errorModelId"] = "ModelId should be unique";
                    isModelIdValid = false;
                }
                if (
                    item.modelName === currentItem.modelName &&
                    isModelNameValid
                ) {
                    currentItem["errorModelName"] =
                        "Model name shoud be unique";
                    isModelNameValid = false;
                }
            }
        });

        if (isModelIdValid) {
            currentItem["errorModelId"] = "";
        }
        if (isModelNameValid) {
            currentItem["errorModelName"] = "";
        }
        if (!isModelIdValid || !isModelNameValid) {
            setHasError(true);
            setErrorIndex(itemIndex);
        } else {
            setHasError(false);
            setErrorIndex(undefined);
        }
    };

    const updateTableProperty = (
        modelType: string,
        value: string,
        itemIndex: number
    ) => {
        const clonedTableData = _.cloneDeep(tableData);
        clonedTableData[itemIndex][modelType] = value;
        validateCurrentPropertyValue(itemIndex, clonedTableData[itemIndex]);
        updateTableData(clonedTableData);
        props.onUpdate(clonedTableData);
    };

    return (
        <>
            <thead>
                <tr>
                    <th className="width-3">
                        <Condition when={showCheckbox}>
                            <Checkbox
                                id="connectModelsSelectAll"
                                sizeClass="small"
                                value={props.selectAll}
                                onChange={() =>
                                    props.handleSelectAll(!props.selectAll)
                                }
                            />
                        </Condition>
                    </th>
                    <th
                        className={`text-uppercase width-15 `}
                        onClick={() => {}}
                    >
                        MODEL NAME
                    </th>
                    <th className="width-20">MODEL ID</th>
                    <th className="width-5" />
                </tr>
            </thead>
            <tbody className="connect-model-tbody">
                {tableData.map((item, itemIndex: number) => {
                    const showItem =
                        itemIndex < DEFAULT_CONECT_MODELS.length &&
                        DEFAULT_CONECT_MODELS.includes(item.modelId);

                    return (
                        <tr key={itemIndex}>
                            <td className="width-3">
                                <Condition when={!showItem}>
                                    <Checkbox
                                        sizeClass="small"
                                        disabled={showItem}
                                        value={item.isSelected}
                                        onChange={() =>
                                            props.handleSelectedItem(
                                                !item.isSelected,
                                                itemIndex
                                            )
                                        }
                                    />
                                </Condition>
                            </td>
                            <td className="width-15">
                                <CustomABBInput
                                    value={item.modelName}
                                    onValueChange={(value) => {
                                        updateTableProperty(
                                            "modelName",
                                            value,
                                            itemIndex
                                        );
                                    }}
                                    type="normal"
                                    dataType="text"
                                    id="modelName"
                                    disabled={
                                        (!item.isNew && !item.isSelected) ||
                                        (hasError &&
                                            itemIndex !== errorIndex) ||
                                        showItem
                                    }
                                    placeholder="Enter Model Name"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    validator={() => {
                                        return item.errorModelName &&
                                            item.errorModelName.length > 0
                                            ? {
                                                  valid: false,
                                                  text: item.errorModelName,
                                              }
                                            : {
                                                  valid: true,
                                                  text: "OK!",
                                              };
                                    }}
                                />
                            </td>
                            <td className="width-20">
                                <CustomABBInput
                                    value={item.modelId}
                                    onValueChange={(value) => {
                                        updateTableProperty(
                                            "modelId",
                                            value,
                                            itemIndex
                                        );
                                    }}
                                    type="normal"
                                    dataType="text"
                                    id="modelId"
                                    disabled={
                                        (!item.isNew && !item.isSelected) ||
                                        (hasError &&
                                            itemIndex !== errorIndex) ||
                                        showItem
                                    }
                                    placeholder="Enter Model Id"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    validator={() => {
                                        return item.errorModelId &&
                                            item.errorModelId.length > 0
                                            ? {
                                                  valid: false,
                                                  text: item.errorModelId,
                                              }
                                            : {
                                                  valid: true,
                                                  text: "OK!",
                                              };
                                    }}
                                />
                            </td>
                            <td className="width-5">
                                <Condition when={!showItem}>
                                    <Icon
                                        name="abb/trash"
                                        sizeClass="medium"
                                        onClick={() => {
                                            props.showConfirmationDialog(
                                                updateTableData,
                                                tableData,
                                                props.onUpdate,
                                                hasError,
                                                itemIndex,
                                                errorIndex,
                                                setHasError,
                                                setErrorIndex
                                            );
                                        }}
                                    />
                                </Condition>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showConfirmationDialog: (
            updateTableData: any,
            tableData: IConnectModelTableRow[],
            onUpdate: any,
            hasError: boolean,
            itemIndex: number,
            errorIndex: number | undefined,
            setHasError: any,
            setErrorIndex: any
        ) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_TITLE.DELETE_MODEL,
                    customClassName: "wrapper-message-modal",
                    data: {
                        warningText: CONFIRMATION_ENDPOINT_DELETE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: "discreet-black",
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.CONFIRM,
                                type: "primary-blue",
                                handler: (dlg: any) => {
                                    updateTableData(
                                        tableData.filter(
                                            (value: any, index: any) =>
                                                index !== itemIndex
                                        )
                                    );
                                    onUpdate(
                                        tableData.filter(
                                            (value: any, index: any) =>
                                                index !== itemIndex
                                        )
                                    );
                                    if (hasError && itemIndex === errorIndex) {
                                        setHasError(false);
                                        setErrorIndex(undefined);
                                    }
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};

export default connect(null, mapDispatchToProps)(SupportedModelTable);
