import React, { useState } from "react";
import { columns } from "../type";

interface AlarmTableHeaderProps { }

function AlarmTableHeader(props: AlarmTableHeaderProps) {
    return (
        <thead>
            <tr className="no-top-border-header">
                <th
                    className={`text-uppercase width-15`}
                    onClick={() => {
                        // handleSortOrder("name", subCondition, conditionIndex);
                        // setActiveTableRow("name");
                    }}
                >
                    NAME
                </th>

                <th className="text-uppercase width-13.5 ">
                    {columns.description}
                </th>
            </tr>
        </thead>
    );
}

export default AlarmTableHeader;
