import React, { Fragment, useState, useCallback, useEffect } from 'react';
import TR from '../../../../../Table/TableRow';
import { Collapse } from 'reactstrap';
import { IoTableTabsProps } from '../../../..';
import {
    FunctionTypeDetailsWithMappings,
    TableDropDownData,
} from '../../../../../../model/TableController/types';
import Td from '../../../../../Table/TableData';
import Condition from '../../../../../shared/Condition';
import _ from 'lodash';
import { ToggleSwitch, Icon, Input } from '@abb/abb-common-ux-react';
import Checkbox from '../../../../../CustomCheckbox';
import ConnectionDropdown from '../../ConnectionDropdown';
import { defaultValueValidation } from '../../../../../../transformers/ComputeModel/validation/defaultValue';
import { IODataTypes } from '../../../../../Fabric/types';
import { useDrop } from 'react-dnd';
import { DRAGGABLE_ITEMS } from '../../../../../../utils/constants/appConstants';
import '../style.scss';
import { handletableDropDownData } from '../../../../../../utils/helpers';
import CollapseTitle from '../../../../../CollapseTitle';

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

const ExpandableInputRows = (props: ExpandTableRowProps & IoTableTabsProps) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    const rows = [...props.asset.inputs, ...props.asset.inhibits];

    const rowCount = rows.length;

    const rowArray = isExpanded ? rows : [rows[0]];

    const defaultValueValidator = useCallback((defaultValue: string, dataType: string) => {
        if (defaultValue) {
            return defaultValueValidation({
                dataType: dataType as IODataTypes,
                value: defaultValue,
            });
        } else {
            return { isInvalid: false, errorMessage: '' };
        }
    }, []);

    return (
        <Fragment>
            {rowArray.length === 0 ? <TR>Empty</TR> : (
                <>
                    <CollapseTitle title={props.asset.nodeId} isCollapse={isExpanded} toggleCollapse={toggleExpand} />
                    <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                        {rowArray.map((input, index) => {
                const defaultValueValidations = defaultValueValidator(
                    input.defaultValue || '',
                    input.dataType
                );
                return (
                    <InputRow
                        key={input.id}
                        {...props}
                        asset={props.asset}
                        defaultValueValidations={defaultValueValidations}
                        index={index}
                        input={input}
                        isExpanded={isExpanded}
                        rowCount={rowCount}
                        toggleExpand={toggleExpand}
                    />
                );
            })}
                    </Collapse>
                </>
            )}

        </Fragment>
    );
};

const InputRow = (
    props: {
        index: number;
        rowCount: number;
        isExpanded: boolean;
        input: FunctionTypeDetailsWithMappings['inputs'][0];
        asset: FunctionTypeDetailsWithMappings;
        toggleExpand: () => void;
        defaultValueValidations: { isInvalid: boolean; errorMessage: string };
    } & IoTableTabsProps
) => {
    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEMS.TABLE_VIEW.ioVariables.src,
        drop: (data: any, monitor) => {
            props.addConnectionConfigurationTool({
                outputAsset: data.data.outputAsset,
                outputCircleData: data.data.circleData,
                inputAsset: props.asset,
                inputCircleData: props.input,
            });
        },
    });

    const [switchState, updateSwitchState] = useState(props.input.defaultValue ? true : undefined);
    const [dropDownOptionsData, setDropDownOptionsData] = useState([] as TableDropDownData[]);
    const [selectedDropDownOptionsIndex, setSelectedDropDownOptionsIndex] = useState(
        [] as number[]
    );

    useEffect(() => {
        if (props.input.mappingDetails.length > 0) {
            updateSwitchState(undefined);
        }
        if (
            props.tableData.objectTypeDetails &&
            props.input &&
            props.tableData.functionTypeDetails
        ) {
            const { dropDownOptions, selectedDropDownItemIndex } = handletableDropDownData({
                tableData: props.tableData,
                input: props.input,
            });

            setDropDownOptionsData(dropDownOptions);
            setSelectedDropDownOptionsIndex(selectedDropDownItemIndex);
        }
    }, [props.input.mappingDetails.length, props.tableData]);

    const { index, rowCount, isExpanded, input, asset, toggleExpand, inViewMode } = props;

    const handleTriggerChange = useCallback(() => {
        if (
            asset.nodeId !== props.overallSeverityFunctionId ||
            (asset.nodeId === props.overallSeverityFunctionId && asset.inputs[0].id !== input.id)
        ) {
            props.triggerConnectionConfigurationTool({
                asset,
                circleData: input,
                trigger: !input.trigger,
            });
        }
    }, [input.trigger]);

    const hasDefaultValue = !!input.defaultValue;

    return (
        <TR>
            <Fragment>
                {/* <Td tdBorderTop={index !== 0 ? 'remove-inner-row-border-top' : ''}>
                    <Condition when={index === 0 && rowCount > 1}>
                        <Icon name={isExpanded ? 'abb/minus' : 'abb/plus'} onClick={toggleExpand} />
                    </Condition>
                </Td> */}
                <Td tdBorderTop={index !== rowCount - 1 ? 'remove-inner-row-border-bottom' : ''}>
                    {index === 0 ? asset.nodeId : ''}
                </Td>
                <Td tdBorderTop={index !== rowCount - 1 ? 'remove-inner-row-border-bottom' : ''}>
                    {index === 0 ? asset.assetName : ''}
                </Td>
                <Td> {input.name}</Td>
                <Td> {input.dataType}</Td>
                <Fragment>
                    <Td>
                        <Checkbox
                            checked={input.trigger}
                            isDisable={
                                !!hasDefaultValue || input.mappingDetails.length === 0 || inViewMode
                            }
                            onClick={handleTriggerChange}
                        />
                    </Td>
                </Fragment>
            </Fragment>
        </TR>
    );
};

export default ExpandableInputRows;
