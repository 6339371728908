import {
    canvasPrimaryBorderColor,
    FUNC_BLOCK_TITLE_HEIGHT,
    PINS_DISTANCE,
    BLOCK_BOTTOM_PADDING,
    GRID_PIXEL,
} from '../UiConfig';
import { fabric } from 'fabric';
import { FabricDropDownTitle } from '../titleRectangle';
import AlarmTypeDetail from '../../../../transformers/AssetType/AlarmType';

interface Params {
    asset: AlarmTypeDetail;
    parent: FabricDropDownTitle;
}

export class FabricAlarmRectangle extends fabric.Group {
    constructor(options: Params) {
        const { outputs, scale: scaleX, scale: scaleY, position } = options.asset;

        let length = outputs.length;

        const height =
            FUNC_BLOCK_TITLE_HEIGHT + (length - 1) * PINS_DISTANCE + BLOCK_BOTTOM_PADDING;

        let width = GRID_PIXEL * 15;

        const rectLeft = -width / 2;

        const rect = new fabric.Rect({
            width: width,
            height: height,
            fill: 'white',
            originY: 'top',
            originX: 'left',
            left: rectLeft,
            top: 0,
            name: 'rect',
            stroke: canvasPrimaryBorderColor,
        });

        const { x, y } = options.parent.getPointByOrigin('center', 'center');
        super([rect], {
            left: x,
            top: y + (rect.getScaledHeight() * scaleY) / 2 + options.parent.getScaledHeight() / 2,
            subTargetCheck: true,
            originX: 'center',
            originY: 'center',
            name: 'group-object',
            scaleX: scaleX || 1,
            scaleY: scaleY || 1,
            borderColor: 'transparent',
            hoverCursor: 'move',
            hasControls: false,
            selectable: false,
        });

        this.data = {
            parent: options.parent,
            asset: options.asset,
        };
    }
}
