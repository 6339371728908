import React from "react";
import Flex from "../../../../components/shared/Flex";
import AssetTableView from "./AssetTableView";
import AssetInstanceHeader from "./AssetInstanceHeader";
import { ActionBar } from "./ActionBar";
import "./style.scss";

const AssetInstanceBody = () => {
    return (
        <Flex className="wrapper-asset-instance-body" column>
            <Flex>
                <AssetInstanceHeader />
            </Flex>
            <Flex>
                <ActionBar />
            </Flex>
            <AssetTableView />
        </Flex>
    );
};

export default AssetInstanceBody;
