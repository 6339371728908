import React, { useState, useCallback } from 'react';

import _ from 'lodash';

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SimpleTableView from './SimpleTableView';
import MappingVariables from './MappingVariables';
import {
    ACTIVE_INPUT_TAB,
    ACTIVE_OUTPUT_TAB,
    sampleTableDataHeader,
    tableOutputDataHeader,
    ACTIVE_ALARM_TAB,
    tableAlarmDataHeader,
    ACTIVE_TRIGGER_TAB,
} from './constant';

import { StoreState } from '../../store/index';
import './style.scss';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    removeConnectionConfigurationTool,
    addConnectionConfigurationTool,
    triggerConnectionConfigurationTool,
    addDefaultValueConfigurationTool,
    publishOutputConfigurationTool,
    publishAlarmConfigurationTool,
    markComputeModelDirty,
    editNodeIdOfFunction,
} from '../../store/configurationTool/actions';
import { RemoveConnectionOptions, AddConnectionOptions } from '../../store/configurationTool/types';

import {
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    MODAL_TITLE,
    ROUTE_PATHNAME,
} from '../../utils/constants/appConstants';
import { showModal } from '../../store/modal/action';
import ConditionConfiguration from '../Fabric/UIComponents/ConditionConfiguration';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';

export type IoTableTabsProps = {} & ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function IoTableTabs(props: IoTableTabsProps) {
    // console.log('tableData :', props.tableData);
    const [activeTab, setActiveTab] = useState(ACTIVE_INPUT_TAB);

    const toggleTab = useCallback(
        (event: React.SyntheticEvent) => {
            const target = event.target as HTMLAnchorElement;
            const name = target.name;
            console.log(event.target);
            setActiveTab((activeTab) => {
                activeTab = name;
                return activeTab;
            });
        },
        // },

        []
    );
    console.log('%c asdadasd ', 'background: lime; color: black', props);
    console.log('IOTableTabs rendering');
    return (
        <TabPane className="wrapper-io-tabs">
            {/*  Input Output buttons */}
            <Nav tabs>
                {[ACTIVE_INPUT_TAB, ACTIVE_OUTPUT_TAB, ACTIVE_ALARM_TAB, ACTIVE_TRIGGER_TAB].map(
                    (tab) => (
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === tab,
                                })}
                                onClick={toggleTab}
                                name={tab}
                                id={`m${tab}IOTabs`}
                            >
                                {tab}
                            </NavLink>
                        </NavItem>
                    )
                )}
            </Nav>

            <TabContent activeTab={activeTab}>
                {/* Inputs/outputs table */}
                {[ACTIVE_INPUT_TAB, ACTIVE_OUTPUT_TAB, ACTIVE_ALARM_TAB, ACTIVE_TRIGGER_TAB].map(
                    (tab) => {
                        return (
                            <TabPane tabId={tab}>
                                <div className="tab-content-parent">
                                    <Row className="tab-content-data">
                                        <Col
                                            sm={
                                                tab === ACTIVE_INPUT_TAB && props.isEditMode
                                                    ? 9
                                                    : 12
                                            }
                                            className="table-parent"
                                        >
                                            <SimpleTableView
                                                {...props}
                                                activeTab={tab}
                                                tableHeaderData={
                                                    tab === ACTIVE_INPUT_TAB
                                                        ? sampleTableDataHeader
                                                        : tab === ACTIVE_OUTPUT_TAB
                                                        ? tableOutputDataHeader
                                                        : tab === ACTIVE_ALARM_TAB
                                                        ? tableAlarmDataHeader
                                                        : []
                                                }
                                            />
                                        </Col>

                                        {tab === ACTIVE_INPUT_TAB && props.isEditMode && (
                                            <Col sm={3} className="mapping-variables">
                                                <MappingVariables
                                                    {...props}
                                                    functionList={
                                                        props.tableData.functionTypeDetails
                                                    }
                                                    objectList={props.tableData.objectTypeDetails}
                                                    alarmList={props.tableData.alarmTypeDetails}
                                                    eventList={props.tableData.eventTypeDetails}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </TabPane>
                        );
                    }
                )}
            </TabContent>
        </TabPane>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        tableData: state.configurationTool.tableData,
        inViewMode: state.configurationTool.mode === CONFIGURATION_TOOL_MODE.VIEW,
        isEditMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        overallSeverityFunctionId: state.configurationTool.overallSeverityFunctionId,
        originalTypeData: state.instanceConfig.originalTypeData,
        activeModelJson:
            state.modelsPage.activeModel && state.modelsPage.activeModel.modelInstance.json,
        modelInstance: window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)
            ? state.instanceConfig.activeModel.modelInstance
            : state.modelsPage.activeModel.modelInstance,
        currentJson: state.configurationTool.json,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        removeConnectionConfigurationTool: (options: RemoveConnectionOptions) => {
            dispatch(removeConnectionConfigurationTool(options));
        },
        addConnectionConfigurationTool: (options: AddConnectionOptions) => {
            dispatch(addConnectionConfigurationTool(options));
        },
        triggerConnectionConfigurationTool: (
            options: ReturnType<typeof triggerConnectionConfigurationTool>['payload']
        ) => {
            dispatch(triggerConnectionConfigurationTool(options));
        },
        addDefaultValueConfigurationTool: (
            options: ReturnType<typeof addDefaultValueConfigurationTool>['payload']
        ) => {
            dispatch(addDefaultValueConfigurationTool(options));
        },
        publishOutputConfigurationTool(
            options: ReturnType<typeof publishOutputConfigurationTool>['payload']
        ) {
            dispatch(publishOutputConfigurationTool(options));
        },
        publishAlarmConfigurationTool: (
            options: ReturnType<typeof publishAlarmConfigurationTool>['payload']
        ) => {
            dispatch(publishAlarmConfigurationTool(options));
        },
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        showConditionModal: (data: any) => {
            dispatch(
                showModal({
                    component: ConditionConfiguration,
                    modalTitle: MODAL_TITLE.PUBLISH_OUTPUT_CONFIGURATION_TITLE,
                    data: data,
                })
            );
        },
        editNodeIdOfFunction: (payload: { newName: string; functionType: FunctionTypeDetail }) => {
            dispatch(editNodeIdOfFunction(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IoTableTabs);
