import _ from 'lodash';
import React from 'react';
import { CollapsibleContainer } from '@abb/abb-common-ux-react';

import './style.scss';
import {
    LibraryAndNodePayload,
    UpdateActiveFunctionType,
    LibraryAndNodePayloadWithoutNodes,
    LibraryDraggedDetails,
} from '../../../store/function/types';
import LibraryCollapsible from './Collapsible';
import { CreateFunctionAssetDetails } from '../../../store/function/action';
import { payloadToExportFunctionWithLibraries } from '../../../store/exportAsset/types';

export interface FuncLeftNavCollapsibleContainerProps {
    libraryAndFunctionsList: LibraryAndNodePayload[];
    currentLibraryToDragAndDrop: LibraryDraggedDetails[];
    selectedFunctionTypeDetails: CreateFunctionAssetDetails | undefined;
    selectedFunctionTypeId: string;
    selectedFunctionVersion: string;
    activeLibId: string;
    handleTypeList: (typeList: UpdateActiveFunctionType, libId: string) => void;
    showDeleteModal: (libraryDetail: LibraryAndNodePayload) => void;
    showAddFunctionsToExistingLibrary: (libraryDetail: LibraryAndNodePayload) => void;
    showRemoveFunctionsFromExistingLibrary: (libraryDetail: LibraryAndNodePayload) => void;
    showModalToRemoveSingleFunction: (
        libraryDetail: LibraryAndNodePayload,
        currentFunction: UpdateActiveFunctionType
    ) => void;
    showGetPasswordForLibraries: (payload: {
        libraryDetails: LibraryAndNodePayloadWithoutNodes;
        requiredActionAfterSubmit?: ((typeDetails: CreateFunctionAssetDetails) => void) | undefined;
        functions: CreateFunctionAssetDetails[];
        libraryInWhichFunctionIsDroppedRecently: LibraryDraggedDetails[];
    }) => void;
    encryptLibrariesModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    unlockLibraryModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    getExportableFunctionsRequest: (payload: payloadToExportFunctionWithLibraries[]) => void;
    showModalToExportSingleLibrary: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    showChangeLibraryPasswordModal: (payload: { libraryDetail: LibraryAndNodePayload }) => void;
    showGetPasswordForRename: (payload: {
        libraryDetails: LibraryAndNodePayloadWithoutNodes;
    }) => void;
    showGetFileNameModal: (exportMethod: () => void, fileName: string) => void;
}

function FuncLeftNavCollapsibleContainer(props: FuncLeftNavCollapsibleContainerProps) {
    const {
        activeLibId,
        handleTypeList,
        showDeleteModal,
        unlockLibraryModal,
        encryptLibrariesModal,
        libraryAndFunctionsList,
        currentLibraryToDragAndDrop,
        showGetPasswordForLibraries,
        showChangeLibraryPasswordModal,
        getExportableFunctionsRequest,
        showModalToExportSingleLibrary,
        showModalToRemoveSingleFunction,
        showAddFunctionsToExistingLibrary,
        showRemoveFunctionsFromExistingLibrary,
    } = props;

    return (
        <div className="wrapper-func-collapsible-container">
            {libraryAndFunctionsList &&
                libraryAndFunctionsList.length &&
                libraryAndFunctionsList.map((item: LibraryAndNodePayload) => {
                    return (
                        <LibraryCollapsible
                            key={item.id}
                            item={item}
                            handleTypeList={handleTypeList}
                            showDeleteModal={showDeleteModal}
                            selectedFunctionTypeId={props.selectedFunctionTypeId}
                            selectedFunctionVersion={props.selectedFunctionVersion}
                            activeLibId={activeLibId}
                            encryptLibrariesModal={encryptLibrariesModal}
                            showModalToRemoveSingleFunction={showModalToRemoveSingleFunction}
                            showAddFunctionsToExistingLibrary={showAddFunctionsToExistingLibrary}
                            showRemoveFunctionsFromExistingLibrary={
                                showRemoveFunctionsFromExistingLibrary
                            }
                            currentLibraryToDragAndDrop={currentLibraryToDragAndDrop}
                            showGetPasswordForLibraries={showGetPasswordForLibraries}
                            showModalToExportSingleLibrary={showModalToExportSingleLibrary}
                            getExportableFunctionsRequest={getExportableFunctionsRequest}
                            showChangeLibraryPasswordModal={showChangeLibraryPasswordModal}
                            unlockLibraryModal={unlockLibraryModal}
                            showGetPasswordForRename={props.showGetPasswordForRename}
                            showGetFileNameModal={props.showGetFileNameModal}
                        />
                    );
                })}
        </div>
    );
}

export default FuncLeftNavCollapsibleContainer;
