import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import "./styles.scss";
import {
    setSelectedTreeNodeAction,
    updateGroupBy,
    getSystemStructureModel,
    getConnectDetailsFromIdentityModel,
    getNewSystemStructuresActionRequest,
    handleNewTreeListNodeToggleAction,
    getSystemsBasedOnConnectModelRequest,
    updateStructure,
    updateSystem,
    getNewAssetInstanceListActionRequest,
    searchAsset,
    setSelectedParentTreeNodeAction,
    selectedNodeParentListAction,
} from "../../../../store/assets/actions";
import { StoreState } from "../../../../store";
import {
    TreeNodeType,
    IStructure,
    ISystem,
} from "../../../../store/assets/types";

import { CommonHierarchyTree } from "common-hierarchy-tree";
// import "common-hierarchy-tree/styles.css";
import { filterListData } from "../../../../utils/helpers";

export const Sidebar = (
    props: ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [treeVirtualList, updateTreeVirtualList] = useState<any[]>([]);
    const [nodeId, updateNodeId] = useState<any>(null);

    useEffect(() => {
        if (props.selectedGroupBy) {
            if (props.selectedGroupBy === 'Structure') {
                props.getSystemStructureModel();
            } else {
                props.getConnectDetailsFromIdentityModel();
            }

            props.setSelectedParentTreeNode(null);
        }
    }, []);

    useEffect(() => {
        checkForSearchText();
    }, [
        props.treeVirtualList,
        props.searchAssetText,
        props.isConfigurationMode,
    ]);

    const checkForSearchText = () => {
        if (props.searchAssetText === "") {
            updateTreeVirtualList(props.treeVirtualList!);
        } else {
            const filteredList = filterListData(
                props.treeVirtualList!,
                "name",
                props.searchAssetText!
            );
            updateTreeVirtualList(filteredList);
        }
        setLoading(false);
    };

    return (
        <div
            className="wrapper-asset-instance-sidebar"
            onDragStart={(e) => {
                e.stopPropagation();
            }}
        >
            <CommonHierarchyTree
                showFilter={true}
                showSearchBox={true}
                isLoading={isLoading}
                structureList={props.structureList}
                systemList={props.systemList}
                groupByList={props.groupByList}
                selectedGroupBy={props.selectedGroupBy}
                selectedStructure={props.selectedStructure}
                selectedSystem={props.selectedSystem}
                fullNodeDrag={props.isConfigurationMode}
                onStructureChange={(structure) => {
                    if (
                        (props.selectedStructure &&
                            props.selectedStructure.name !== structure.name) ||
                        !props.selectedStructure
                    ) {
                        setLoading(true);
                        props.updateStructure(structure);
                        props.setSelectedTreeNodeAction(null);
                        props.getNewSystemStructures();
                        props.setSelectedParentTreeNode(null);
                        props.getNewAssetInstanceList(null, false);
                    }
                }}
                onSystemChange={(system) => {
                    if (
                        (props.selectedSystem &&
                            props.selectedSystem.connect !== system.connect) ||
                        !props.selectedSystem
                    ) {
                        setLoading(true);

                        props.updateSystem(system);
                        props.getNewSystems();
                        props.setSelectedParentTreeNode(null);
                    }
                }}
                onGroupByChange={(groupBy) => {
                    if (props.selectedGroupBy !== groupBy) {
                        setLoading(true);

                        props.updateGroupBy(groupBy);
                        groupBy === "Structure"
                            ? props.getSystemStructureModel()
                            : props.getConnectDetailsFromIdentityModel();
                        props.setSelectedParentTreeNode(null);
                    }
                }}
                searchText={props.searchAssetText}
                onSearchTextChange={(text) => {
                    setLoading(true);
                    // in future need to do actual API call
                    props.searchAssetValue(text!);
                }}
                leafNodeDrag={false}
                treeList={[...treeVirtualList!]}
                onToggleTreeListNode={(node) => {
                    let tempNode: any = { ...node };
                    if (props.selectedGroupBy == "Structure") {
                        if (
                            Array.isArray(tempNode.parents) &&
                            tempNode.parents.length == 0
                        ) {
                            if (nodeId !== tempNode.id) {
                                updateNodeId(tempNode.id);
                                props.setSelectedParentTreeNode(tempNode.id);
                            }
                        } else if (props.selectedParentTreeNodeId == null) {
                            const tempId =
                                Array.isArray(tempNode.parents) &&
                                tempNode.parents.length == 0
                                    ? tempNode.id
                                    : tempNode.parents[0];
                            updateNodeId(tempId);
                            props.setSelectedParentTreeNode(tempId);
                        }
                    } else if (props.selectedGroupBy == "Connect") {
                        updateNodeId(tempNode.id);
                        props.setSelectedParentTreeNode(tempNode.id);
                    }
                    props.selectedNodeParentList(
                        Array.isArray(tempNode.parents) ? tempNode.parents : []
                    );
                    props.handleTreeNodeSelection(node.state!);
                    props.getNewAssetInstanceList(node.state!, false);
                }}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateGroupBy: (groupBy: string) => {
            dispatch(updateGroupBy(groupBy));
        },
        updateStructure: (structure: IStructure) => {
            dispatch(updateStructure(structure));
        },
        updateSystem: (system: ISystem) => {
            dispatch(updateSystem(system));
        },
        getSystemStructureModel: () => {
            dispatch(getSystemStructureModel());
        },
        getConnectDetailsFromIdentityModel: () => {
            dispatch(getConnectDetailsFromIdentityModel());
        },
        getNewSystemStructures: () => {
            dispatch(getNewSystemStructuresActionRequest(null));
        },
        getNewSystems: () => {
            dispatch(getSystemsBasedOnConnectModelRequest(null));
        },
        handleTreeNodeSelection: (node: any) => {
            dispatch(handleNewTreeListNodeToggleAction(node));
        },
        searchAssetValue: (text: string) => {
            dispatch(searchAsset(text));
        },
        setSelectedParentTreeNode: (nodeId: any) => {
            dispatch(setSelectedParentTreeNodeAction(nodeId));
        },
        selectedNodeParentList: (nodes: string[]) => {
            dispatch(selectedNodeParentListAction(nodes));
        },
        setSelectedTreeNodeAction: (node: TreeNodeType | null) => {
            dispatch(setSelectedTreeNodeAction(node));
        },
        getNewAssetInstanceList: (
            selectedNode: any,
            includeAllChild: boolean
        ) => {
            dispatch(
                getNewAssetInstanceListActionRequest({
                    node: selectedNode,
                    activePage: 1,
                })
            );
        },
    };
};
const mapStateToProps = (state: StoreState) => {
    return {
        sysEdgeLoading: state.assets.sysEdgeLoading,
        categoryArr: state.assets.categoryArr,
        selectedCategory: state.assets.selectedCategory,
        treeListData: state.assets.treeList,
        structureList: state.assets.assetStructureDetails.structureList,
        systemList: state.assets.assetStructureDetails.systemList,
        treeVirtualList: state.assets.treeVirtualList.children,
        searchAssetText: state.assets.searchAsset,
        selectedParentTreeNodeId: state.assets.selectedParentTreeNodeId,
        groupByList: state.assets.groupByList,
        selectedGroupBy: state.assets.assetStructureDetails.selectedGroupBy,
        selectedStructure: state.assets.assetStructureDetails.selectedStructure,
        selectedSystem: state.assets.assetStructureDetails.selectedSystem,
        isConfigurationMode: state.assets.configurationMode,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
