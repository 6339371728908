import _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Button } from '@abb/abb-common-ux-react';

import {
    addNewConditionMonitorModelsSuccess,
    addNewConditionMonitorModelsRequest,
    getSupportedConditionMonitorsRequest,
    resetGlobalMonitorModelsError,
    deleteConditionMonitorModelsRequest,
    updateConditionMonitorModelsRequest,
} from '../../../store/supportedConditionMonitors/action';
import ButtonBar from '../ButtonBar';
import { checkValidityForMonitorsInput, monitorsList } from '../../../utils/helpers';
import Condition from '../../../components/shared/Condition';
import Flex from '../../../components/shared/Flex';
import Loader from '../../../components/Loader';
import { LOADER_SIZE, LOADER_TYPE } from '../../../utils/constants/appConstants';
import {
    SupportedNewModel,
    ISupportedModelItem,
} from '../../../store/supportedConditionMonitors/types';
import SupportedConditionMonitor from '../../../components/SupportedModel/SupportedCondtionMonitor';
import { CONFIRMATION_BUTTON } from '../../../utils/constants/uiConstants';
import { showDialog, hideDialog } from '../../../store/dialog/action';
import MessageModal from '../../../components/MessageModal';
import ConfigurationHeader from '../Header';
import { SettingsLeftNavOptions } from '../../../store/settings/types';

const SupportedConditionMonitorTab = (
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
    const {
        showDeleteModal,
        globalErrorForMonitorModels,
        resetGlobalMonitorModelsError,
        supportedConditionMonitorListData,
        successFullyAddedMonitorModel,
        updatingConditionMonitorModels,
        addingNewConditionMonitorModels,
        loadingSupportedConditionMonitors,
        deletingNewConditionMonitorModels,
        addNewConditionMonitorModelsRequest,
        addNewConditionMonitorModelsSuccess,
        // updateConditionMonitorModelsRequest,
        getSupportedConditionMonitorsRequestFunction,
    } = props;

    const [addedSupportedConditionMonitorModels, setAddedSupportedConditionMonitorModels] =
        useState<ISupportedModelItem[]>([]);

    const [supportedConditionMonitorList, setSupportedConditionMonitorList] = useState<
        ISupportedModelItem[]
    >([]);

    useEffect(() => {
        if (props.supportedConditionMonitorListData.length === 0) {
            getSupportedConditionMonitorsRequestFunction();
        }
    }, []);
    useEffect(() => {
        const supportedConditionMonitorListObj = supportedConditionMonitorList.reduce(
            (obj: { [key: string]: ISupportedModelItem }, curr) => ({
                ...obj,
                [curr.modelName]: curr,
            }),
            {}
        );
        let listData: ISupportedModelItem[] = [];
        supportedConditionMonitorListData.forEach((item) => {
            const oldItem = supportedConditionMonitorListObj[item.modelName];

            listData.push({
                modelName: item.modelName,
                tenant: oldItem && item.tenant ? oldItem.tenant : item.tenant,
                isNew: false,
                isSelected: oldItem && item.tenant ? oldItem.isSelected : false,
                isUpdatable: item.tenant,
                isUpdated: oldItem ? item.tenant !== oldItem.tenant : false,
            });
        });
        if (addedSupportedConditionMonitorModels.length > 0) {
            listData = [...addedSupportedConditionMonitorModels, ...listData];
        }
        setSupportedConditionMonitorList([...listData]);
    }, [supportedConditionMonitorListData]);

    useEffect(() => {
        if (
            successFullyAddedMonitorModel.length &&
            !addingNewConditionMonitorModels &&
            !updatingConditionMonitorModels
        ) {
            addNewConditionMonitorModelsSuccess({
                message: '',
                monitorsAddedSuccessFully: [],
            });
            setAddedSupportedConditionMonitorModels([]);
        }
    }, [
        successFullyAddedMonitorModel,
        addingNewConditionMonitorModels,
        updatingConditionMonitorModels,
    ]);

    const checkWhetherAnyItemIsEmpty = useMemo(() => {
        let isEmpty = false;
        addedSupportedConditionMonitorModels.forEach((monitor: ISupportedModelItem) => {
            if (monitor.modelName.length === 0) {
                isEmpty = true;
            }
        });
        return isEmpty;
    }, [addedSupportedConditionMonitorModels]);

    const areAllAddedConditionMonitorsValid = useMemo(() => {
        let isValid = true;
        addedSupportedConditionMonitorModels.forEach(
            (monitor: SupportedNewModel, index: number) => {
                const { valid } = checkValidityForMonitorsInput(
                    index,
                    monitor.modelName,
                    'Id',
                    supportedConditionMonitorList
                );
                if (!valid) {
                    isValid = false;
                }
            }
        );
        return isValid;
    }, [addedSupportedConditionMonitorModels]);
    const handleCheckboxChange = useCallback(
        (index: number) => {
            if (globalErrorForMonitorModels.length > 0) {
                resetGlobalMonitorModelsError();
            }
            const updatedList = [...supportedConditionMonitorList];
            const item = updatedList[index];
            updatedList[index] = { ...item, isSelected: !item.isSelected };
            setSupportedConditionMonitorList([...updatedList]);
            if (item.isNew) {
                const updatedNewItemList = [...addedSupportedConditionMonitorModels];
                updatedNewItemList[index] = { ...item, isSelected: !item.isSelected };
                setAddedSupportedConditionMonitorModels([...updatedNewItemList]);
            }
        },
        [
            addedSupportedConditionMonitorModels,
            globalErrorForMonitorModels,
            resetGlobalMonitorModelsError,
            supportedConditionMonitorList,
            setSupportedConditionMonitorList,
            setAddedSupportedConditionMonitorModels,
        ]
    );

    const isAddSupportedConditionModalsDisabled = useMemo(() => {
        return (
            addedSupportedConditionMonitorModels.length === 0 ||
            !areAllAddedConditionMonitorsValid ||
            checkWhetherAnyItemIsEmpty
        );
    }, [
        addedSupportedConditionMonitorModels,
        areAllAddedConditionMonitorsValid,
        checkWhetherAnyItemIsEmpty,
    ]);

    // const onCloseDialog = useCallback(() => {
    //     setAddedSupportedConditionMonitorModels([]);
    //     if (globalErrorForMonitorModels.length > 0) {
    //         resetGlobalMonitorModelsError();
    //     }
    // }, [
    //     globalErrorForMonitorModels,
    //     resetGlobalMonitorModelsError,
    //     setAddedSupportedConditionMonitorModels,
    // ]);

    const deleteSuccess = useCallback(
        (modelItem?: ISupportedModelItem) => {
            if (modelItem) {
                const updatedList = supportedConditionMonitorList.filter(
                    (item) => !(item.modelName === modelItem.modelName && item.isNew)
                );
                const updatedNewItemList = addedSupportedConditionMonitorModels.filter(
                    (item) => !(item.modelName === modelItem.modelName && item.isNew)
                );
                setAddedSupportedConditionMonitorModels([...updatedNewItemList]);
                setSupportedConditionMonitorList([...updatedList]);
            } else {
                const updatedList = supportedConditionMonitorList.filter(
                    (item) => !item.isSelected
                );
                const updatedNewItemList = addedSupportedConditionMonitorModels.filter(
                    (item) => !item.isSelected
                );
                setAddedSupportedConditionMonitorModels([...updatedNewItemList]);
                setSupportedConditionMonitorList([...updatedList]);
            }
        },
        [
            supportedConditionMonitorList,
            addedSupportedConditionMonitorModels,
            setSupportedConditionMonitorList,
            setAddedSupportedConditionMonitorModels,
        ]
    );

    const deleteLocalModels = useCallback(() => {
        const updatedList = supportedConditionMonitorList.filter(
            (item) => !(item.isSelected && item.isNew)
        );
        const updatedNewItemList = addedSupportedConditionMonitorModels.filter(
            (item) => !item.isSelected
        );
        setAddedSupportedConditionMonitorModels([...updatedNewItemList]);
        setSupportedConditionMonitorList([...updatedList]);
    }, [
        supportedConditionMonitorList,
        addedSupportedConditionMonitorModels,
        setAddedSupportedConditionMonitorModels,
        setAddedSupportedConditionMonitorModels,
    ]);

    const handleDelete = useCallback(
        (modelItem?: ISupportedModelItem) => {
            const monitorsToDeleteLocally: SupportedNewModel[] = [];
            const monitorsToDeleteRemotely: SupportedNewModel[] = [];
            if (modelItem) {
                if (modelItem.isNew) {
                    monitorsToDeleteLocally.push({
                        modelName: modelItem.modelName,
                        tenant: modelItem.tenant,
                    });
                } else {
                    monitorsToDeleteRemotely.push({
                        modelName: modelItem.modelName,
                        tenant: modelItem.tenant,
                    });
                }
            } else {
                supportedConditionMonitorList.forEach((item) => {
                    if (item.isSelected && item.isNew) {
                        monitorsToDeleteLocally.push({
                            modelName: item.modelName,
                            tenant: item.tenant,
                        });
                    }
                    if (item.isSelected && !item.isNew) {
                        monitorsToDeleteRemotely.push({
                            modelName: item.modelName,
                            tenant: item.tenant,
                        });
                    }
                });
            }
            if (monitorsToDeleteLocally.length > 0 || monitorsToDeleteRemotely.length > 0) {
                showDeleteModal(
                    monitorsToDeleteLocally,
                    monitorsToDeleteRemotely,
                    () => deleteSuccess(modelItem),
                    () => deleteLocalModels()
                );
            }
        },
        [
            deleteSuccess,
            showDeleteModal,
            addedSupportedConditionMonitorModels,
            supportedConditionMonitorList,
        ]
    );

    const isAllSelectedCheckbox = useMemo(() => {
        let isAllSelected = true;
        let selectableElementCount = 0;
        supportedConditionMonitorList.forEach((item) => {
            if (item.isNew || item.isUpdatable) {
                selectableElementCount++;
                if (!item.isSelected) {
                    isAllSelected = false;
                }
            }
        });
        if (selectableElementCount === 0) {
            isAllSelected = false;
        }
        return isAllSelected;
    }, [supportedConditionMonitorList]);

    const handleCheckAll = useCallback(() => {
        if (globalErrorForMonitorModels.length > 0) {
            resetGlobalMonitorModelsError();
        }
        const updatedList = [...supportedConditionMonitorList];
        updatedList.forEach((item) => {
            if (item.isNew || item.isUpdatable) {
                item.isSelected = !isAllSelectedCheckbox;
            }
        });
        const newModelList = [...addedSupportedConditionMonitorModels];
        newModelList.forEach((item) => {
            item.isSelected = !isAllSelectedCheckbox;
        });
        setAddedSupportedConditionMonitorModels([...newModelList]);
        setSupportedConditionMonitorList([...updatedList]);
    }, [
        globalErrorForMonitorModels,
        resetGlobalMonitorModelsError,
        isAllSelectedCheckbox,
        supportedConditionMonitorList,
        addedSupportedConditionMonitorModels,
        setAddedSupportedConditionMonitorModels,
        setSupportedConditionMonitorList,
    ]);
    // const handleTagsCheckboxChange = useCallback(
    //     (index: number) => {
    //         if (globalErrorForMonitorModels.length > 0) {
    //             resetGlobalMonitorModelsError();
    //         }
    //         const updatedList = [...supportedConditionMonitorList];
    //         const item = { ...updatedList[index] };
    //         updatedList[index] = {
    //             ...item,
    //             isUpdated: item.isNew ? false : !item.isUpdated,
    //             tenant: !item.tenant,
    //         };
    //         setSupportedConditionMonitorList([...updatedList]);
    //     },
    //     [
    //         globalErrorForMonitorModels,
    //         resetGlobalMonitorModelsError,
    //         supportedConditionMonitorList,
    //         addedSupportedConditionMonitorModels,
    //         setSupportedConditionMonitorList,
    //     ]
    // );
    // const showUpdateButton = useMemo(() => {
    //     const showButton = supportedConditionMonitorList.some((item) => {
    //         if (!item.isNew && item.isUpdated) {
    //             return true;
    //         } else return false;
    //     });
    //     return showButton;
    // }, [supportedConditionMonitorList]);

    // const handleSupportedModelsUpdate = useCallback(() => {
    //     const updatePayload: SupportedNewModel[] = [];
    //     supportedConditionMonitorList.forEach((item) => {
    //         if (item.isUpdated && !item.isNew) {
    //             updatePayload.push({ tenant: item.tenant, modelName: item.modelName });
    //         }
    //     });
    //     updateConditionMonitorModelsRequest(updatePayload);
    // }, [supportedConditionMonitorList]);

    const showDeleteConditionMonitorButton = useMemo(() => {
        const show = supportedConditionMonitorList.some((item) => {
            if (item.isSelected) return true;
            else return false;
        });
        return show;
    }, [supportedConditionMonitorList]);
    return (
        <div className="wrapper-connect-models">
            <ConfigurationHeader
                headerText={SettingsLeftNavOptions.SUPPORTED_CONDITION_MONITOR_MODELS}
            />
            <div className="connect-models-form">
                <Condition when={loadingSupportedConditionMonitors}>
                    <Flex fill center>
                        <Loader sizeClass={LOADER_SIZE.LARGE} type={LOADER_TYPE.RADIAL} />
                    </Flex>
                </Condition>
                <Condition when={!loadingSupportedConditionMonitors}>
                    <SupportedConditionMonitor
                        handleCheckAll={handleCheckAll}
                        isAllSelectedCheckbox={isAllSelectedCheckbox}
                        addedSupportedConditionMonitorModels={addedSupportedConditionMonitorModels}
                        setAddedSupportedConditionMonitorModels={
                            setAddedSupportedConditionMonitorModels
                        }
                        conditionMonitorListToRender={supportedConditionMonitorList}
                        checkWhetherAnyItemIsEmpty={checkWhetherAnyItemIsEmpty}
                        areAllAddedConditionMonitorsValid={areAllAddedConditionMonitorsValid}
                        handleCheckboxChange={handleCheckboxChange}
                        // handleTagsCheckboxChange={handleTagsCheckboxChange}
                        handleDelete={handleDelete}
                        globalErrorForMonitorModels={globalErrorForMonitorModels}
                        resetGlobalMonitorModelsError={resetGlobalMonitorModelsError}
                        setSupportedConditionMonitorList={setSupportedConditionMonitorList}
                    />
                </Condition>
            </div>
            <ButtonBar>
                {showDeleteConditionMonitorButton && (
                    <Button
                        text={deletingNewConditionMonitorModels ? 'Deleting...' : 'Delete'}
                        sizeClass="medium"
                        shape="rounded"
                        type="primary-blue"
                        disabled={
                            deletingNewConditionMonitorModels ||
                            addingNewConditionMonitorModels ||
                            updatingConditionMonitorModels
                        }
                        className="delete-button"
                        onClick={() => handleDelete()}
                    />
                )}
                {/* {showUpdateButton && (
                    <Button
                        text={updatingConditionMonitorModels ? 'Updating...' : 'Update'}
                        sizeClass="medium"
                        shape="rounded"
                        type="primary-blue"
                        disabled={
                            updatingConditionMonitorModels ||
                            addingNewConditionMonitorModels ||
                            deletingNewConditionMonitorModels
                        }
                        className="delete-button"
                        onClick={handleSupportedModelsUpdate}
                    />
                )} */}
                <Button
                    text={addingNewConditionMonitorModels ? 'Saving...' : 'Save'}
                    sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    disabled={
                        isAddSupportedConditionModalsDisabled || addingNewConditionMonitorModels
                    }
                    className="monitor-model-button-save"
                    isLoading={addingNewConditionMonitorModels}
                    onClick={() => {
                        const payload: SupportedNewModel[] = [];
                        supportedConditionMonitorList.forEach((item) => {
                            if (item.isNew) {
                                payload.push({
                                    modelName: item.modelName,
                                    tenant: item.tenant,
                                });
                            }
                        });
                        addNewConditionMonitorModelsRequest(payload);
                    }}
                />
            </ButtonBar>
        </div>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        supportedConditionMonitorListData:
            state.supportedConditionMonitor.supportedConditionMonitorList,
        addingNewConditionMonitorModels:
            state.supportedConditionMonitor.addingNewConditionMonitorModels,
        successFullyAddedMonitorModel:
            state.supportedConditionMonitor.successFullyAddedMonitorModel,
        loadingSupportedConditionMonitors:
            state.supportedConditionMonitor.loadingSupportedConditionMonitors,
        globalErrorForMonitorModels: state.supportedConditionMonitor.globalErrorForMonitorModels,
        deletingNewConditionMonitorModels:
            state.supportedConditionMonitor.deletingNewConditionMonitorModels,
        updatingConditionMonitorModels:
            state.supportedConditionMonitor.updatingConditionMonitorModels,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getSupportedConditionMonitorsRequestFunction: () => {
            dispatch(getSupportedConditionMonitorsRequest());
        },
        addNewConditionMonitorModelsRequest: (payload: SupportedNewModel[]) => {
            dispatch(addNewConditionMonitorModelsRequest(payload));
        },
        addNewConditionMonitorModelsSuccess: (payload: {
            message: string;
            monitorsAddedSuccessFully: string[];
        }) => {
            dispatch(addNewConditionMonitorModelsSuccess(payload));
        },
        resetGlobalMonitorModelsError: () => dispatch(resetGlobalMonitorModelsError()),
        showDeleteModal: (
            monitorsToDeleteLocally: SupportedNewModel[],
            monitorsToDeleteRemotely: SupportedNewModel[],
            onSuccess: () => void,
            deleteLocalModels: () => void
        ) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: `Are you sure you want to delete ${monitorsList([
                            ...monitorsToDeleteRemotely,
                            ...monitorsToDeleteLocally,
                        ]).join(`\n,`)}?`,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.CONFIRM,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                    if (monitorsToDeleteLocally.length === 0) {
                                        dispatch(
                                            deleteConditionMonitorModelsRequest({
                                                supportedConditionMonitors:
                                                    monitorsList(monitorsToDeleteRemotely),
                                                onSuccess,
                                            })
                                        );
                                    } else if (monitorsToDeleteRemotely.length === 0) {
                                        onSuccess();
                                    } else {
                                        deleteLocalModels();
                                        dispatch(
                                            deleteConditionMonitorModelsRequest({
                                                supportedConditionMonitors:
                                                    monitorsList(monitorsToDeleteRemotely),
                                                onSuccess,
                                            })
                                        );
                                    }
                                },
                            },
                        ],
                    },
                })
            );
        },
        // updateConditionMonitorModelsRequest: (payload: SupportedNewModel[]) =>
        //     dispatch(updateConditionMonitorModelsRequest(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportedConditionMonitorTab);
