import { FabricObjectRectangle } from '../UIHelpers/FabricObjectRectangle';
import { ConstructorOptions, Node, SelfPositioningChild } from './types';
import {
    FabricFunctionRectangle,
    getDropdownThemeColors,
    getDropdownAssetType,
} from '../UIHelpers/functionRectangle';
import { AssetsType, Asset, AlarmDetailsProps } from '../types';
import { fabric } from 'fabric';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import ObjectTypeDetail from '../../../transformers/AssetType/ObjectType';

import { FabricRefreshIcon } from '../UIHelpers/functionRectangle/smartChilds/FabricRefreshIcon';
import { FabricAlarmRectangle } from '../UIHelpers/alarmRectangle';
import { FabricDropDownTitle } from '../UIHelpers/titleRectangle';
import { DropDownConnectionHooks } from '../UIHelpers/titleRectangle/smartChilds/connectionHooks';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import { FabricEventRectangle } from '../UIHelpers/eventRectangle';
import EventTypeDetail from '../../../transformers/AssetType/EventType';

class NodeController {
    private _nodes: Node[];
    private _dropDownTitleNodes: FabricDropDownTitle[];
    private _canvas: fabric.Canvas;
    canvasOffSet: { x: number; y: number };

    constructor(options: ConstructorOptions) {
        this._nodes = [];
        this._dropDownTitleNodes = [];
        const { canvasOffSet, canvas } = options;
        this.canvasOffSet = canvasOffSet;
        this._canvas = canvas;
    }

    addObjectType(options: { asset: ObjectTypeDetail }) {
        const dropDownNode = this._dropDownTitleNodes.filter(
            (item) => item.data.asset.nodeId === options.asset.nodeId
        )[0];
        let objectRectangle = new FabricObjectRectangle({ ...options, parent: dropDownNode });
        this.changeNodeSettings(objectRectangle);
        this._nodes.push(objectRectangle);
        this._canvas.add(objectRectangle);
        return objectRectangle;
    }

    changeNodeSettings(node: Node) {
        node.setControlsVisibility({
            bl: false,
            br: true,
            mb: false,
            ml: false,
            mr: false,
            mt: false,
            mtr: false,
            tl: true,
            tr: false,
        });
    }

    changeDropdownTitleSettings(node: FabricDropDownTitle) {
        node.setControlsVisibility({
            bl: false,
            br: true,
            mb: false,
            ml: false,
            mr: false,
            mt: false,
            mtr: false,
            tl: true,
            tr: false,
        });
    }

    addAlarmType(options: { asset: AlarmTypeDetail }) {
        const dropDownNode = this._dropDownTitleNodes.filter(
            (item) => item.data.asset.nodeId === options.asset.nodeId
        )[0];
        const alarmRectangle = new FabricAlarmRectangle({ ...options, parent: dropDownNode });
        this._nodes.push(alarmRectangle);
        this._canvas.add(alarmRectangle);
        return alarmRectangle;
    }

    addEventType(options: { asset: EventTypeDetail }) {
        const dropDownNode = this._dropDownTitleNodes.filter(
            (item) => item.data.asset.nodeId === options.asset.nodeId
        )[0];
        const eventRectangle = new FabricEventRectangle({ ...options, parent: dropDownNode });
        this._nodes.push(eventRectangle);
        this._canvas.add(eventRectangle);
        return eventRectangle;
    }

    addFunctionType(options: { asset: FunctionTypeDetail; editMode: boolean }) {
        const dropDownNode = this._dropDownTitleNodes.filter(
            (item) => item.data.asset.nodeId === options.asset.nodeId
        )[0];
        const functionRectangle = new FabricFunctionRectangle({ ...options, parent: dropDownNode });

        const smartChilds = functionRectangle.data.childs;

        this.changeNodeSettings(functionRectangle);
        this._canvas.add(functionRectangle);
        this._nodes.push(functionRectangle);

        smartChilds.forEach((child) => {
            if (options.editMode) {
                this._canvas.add(child);
            } else {
                if (child instanceof FabricRefreshIcon) {
                    this._canvas.add(child);
                }
            }
        });
        return functionRectangle;
    }

    handleDeleteNode(nodeId: string) {
        this._nodes = this._nodes.filter((node) => {
            if (node.data.asset.nodeId !== nodeId) {
                return true;
            } else {
                this._canvas.remove(node);
                if ('childs' in node.data) {
                    node.data.childs.forEach((child: fabric.Object) => this._canvas.remove(child));
                }
                return false;
            }
        });
        this._dropDownTitleNodes = this._dropDownTitleNodes.filter((node) => {
            if (node.data.asset.nodeId !== nodeId) {
                return true;
            } else {
                this._canvas.remove(node);
                if ('childs' in node.data) {
                    node.data.childs.forEach((child: fabric.Object) => this._canvas.remove(child));
                }
                return false;
            }
        });
    }

    handleViewMode = () => {
        this._nodes.forEach((node) => {
            node.selectable = false;

            if (node instanceof FabricFunctionRectangle) {
                node.data.handleViewMode && node.data.handleViewMode();
                node.data.childs.forEach((child) => {
                    if (!(child instanceof FabricRefreshIcon)) {
                        this._canvas.remove(child);
                    }
                });
            }
        });
        this._dropDownTitleNodes.forEach((node) => {
            node.selectable = false;
            node.data.handleViewMode && node.data.handleViewMode();
        });
    };

    handleEditMode = () => {
        this._nodes.forEach((node) => {
            node.selectable = true;
            if (node instanceof FabricFunctionRectangle) {
                node.data.handleEditMode && node.data.handleEditMode();
                node.data.childs.forEach((child) => {
                    if (child instanceof FabricRefreshIcon) {
                        this._canvas.remove(child);
                    }
                    this._canvas.add(child);
                });
            }
        });
        this._dropDownTitleNodes.forEach((node) => {
            node.selectable = true;
        });
    };

    addNestedChilds = (child: SelfPositioningChild) => {
        const nestedChilds = child.data.childs;
        if (nestedChilds) {
            nestedChilds.forEach((item) => this._canvas.add(item));
        }
    };

    removeNestedChilds = (child: SelfPositioningChild) => {
        const nestedChilds = child.data.childs;
        if (nestedChilds) {
            nestedChilds.forEach((item) => this._canvas.remove(item));
        }
    };

    addNode(options: { asset: Asset }) {
        const { asset } = options;
        const { bgColor, color, iconPath, borderColor } = getDropdownThemeColors(
            getDropdownAssetType(asset)
        );
        let dropDownTitle = new FabricDropDownTitle({
            asset: asset,
            bgColor,
            color,
            iconPath,
            borderColor,
        });
        this.changeDropdownTitleSettings(dropDownTitle);
        const drowpDownChild = dropDownTitle.data.childs;
        this._canvas.add(dropDownTitle);
        drowpDownChild.forEach((child) => {
            this._canvas.add(child);
            if (child instanceof DropDownConnectionHooks) {
                child.sendToBack();
            }
        });
        this._dropDownTitleNodes.push(dropDownTitle);
        return dropDownTitle;
    }

    handleToggleNode = (options: { nodeToToggle: FabricDropDownTitle; isOpen: boolean }) => {
        const nodeId = options.nodeToToggle.nodeId;

        const node = this._nodes.find((node) => node.data.asset.nodeId === nodeId);
        const dropDownNode = this._dropDownTitleNodes.find(
            (node) => node.data.asset.nodeId === nodeId
        );

        if (dropDownNode) {
            if ('childs' in dropDownNode.data) {
                dropDownNode.data.childs.forEach((child) => {
                    if (child instanceof DropDownConnectionHooks) {
                        child.visible = options.isOpen ? false : true;
                    }
                });
            }
        }
        if (!options.isOpen) {
            if (node) {
                this._canvas.remove(node);
                if ('childs' in node.data) {
                    node.data.childs.forEach((child: fabric.Object) => this._canvas.remove(child));
                }
            }
            this._nodes = this._nodes.filter((node) => node.data.asset.nodeId !== nodeId);
        }
    };

    getAllNodes() {
        return this._nodes.map((node) => {
            node.data.asset.position.x = node.left || 0;
            node.data.asset.position.y = node.top || 0;
            node.data.asset.scale = node.scaleX || 1;
            return node;
        });
    }
    getNodeById(nodeId: string) {
        const nodes = this._nodes.filter((node) => node.data.asset.nodeId === nodeId);

        return nodes.length > 0 ? nodes[0] : undefined;
    }

    getAllDropDownTitleNodes() {
        return this._dropDownTitleNodes.map((node) => {
            node.data.asset.position.x = node.left || 0;
            node.data.asset.position.y = node.top || 0;
            node.data.asset.scale = node.scaleX || 1;
            return node;
        });
    }

    getDropDownTitleNodesById(nodeId: string) {
        return this._dropDownTitleNodes.find((node) => {
            return node.data.asset.nodeId === nodeId;
        });
    }

    clear() {
        this._nodes = [];
        this._dropDownTitleNodes = [];
    }
}

export default NodeController;
