import { ActiveModelType } from '../modelsPage/types';
import {
    getAllComputeModelsStore,
    changeActiveModelInstanceConfig,
    getAssetTableDataStore,
    addToSelectedInstance,
    removeFromSelectedInstance,
    updateActiveTabInstanceConfig,
    changeActivePageInstanceConfig,
    changeEnteriesPerPageInstanceConfig,
    handleFilterAssetTableData,
    handleSortAssetTableData,
    handleConfiguredInstanceJson,
    handleLoadingConfiguredInstance,
    handleAssetToggleUnconfiguredInstance,
    saveComputeModelInstanceConfigSuccess,
    deleteComputeModelInstanceConfig,
    deleteComputeModelInstanceConfigSuccess,
    handleViewModeChangeInstanceConfig,
    handleAssetModelConfig,
    clearAssetModelDetailConfig,
    saveComputeModelInstanceConfigError,
    deleteComputeModelInstanceConfigError,
    getMatchingComputeModelsStore,
    getMatchingComputeModelsApi,
    handleIncludeAllChildElements,
    handleTableTotalRecords,
    getAllComputeModelsApi,
    updateSelectedMenuItem,
    updateAssetCriticalityModels,
    updateSelectedCriticalityModel,
    updateInstanceMonitoringList,
    handleAssetConfigureBtnClickAction,
    updateSupportedTypes,
    checkedListofItems,
    checkedListofItemupdate,
    CheckedAllItems,
    updateSelectedSupportedType,
    viewupdateSelectedSupportedType,
    getConfiguredInstanceJson,
    handleLoading,
    getSelectedTypeComputeModelFromJson,
    updateSelectedTypeJson,
    updateAssetConfigModalFields,
    updateActiveModelTypeParams,
    handleConfiguredSaveInstance,
    updateAssetConfigLoader,
    updateOriginalTypeJson,
    updateButtonLoader,
    updateInstanceTypeJsonConditionDetails,
    hanldeInstanceTypeOriginalFunctionsLoading,
    getLibraryReferencesForFunctionTypesRequest,
    getLibraryReferencesForFunctionTypesSuccess,
    getLibraryReferencesForFunctionTypeFailure,
    handleAssetEnableBtnClickAction,
    updateComputeModelInstanceConfig,
    viewandUpdateComputeModelInstanceConfig,
    newModelSaveAvailable,
    changeActivePageInstanceUpdate,
    getLoadedInstace,
    updateSelectedSupportedTypemodel,
    saveComputeModelInstanceConfigUpdate,
} from './actions';
import CanvasController from '../../components/Fabric';
import { TableCheckBoxData } from '../../routes/Assets/PageBody/AssetInstanceBody/AssetTableView/types';
import {
    getAssetInstanceListActionSuccess,
    getAssetInstanceListActionRequest,
    handleAssetToggle,
} from '../assets/actions';
import { ActionTypes } from '../actionTypes';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { INSTANCE_SAVE_MODE } from '../../utils/constants/appConstants';
import { types } from '@babel/core';

export const ACTION_MENU_VALUE = <const>{
    configure: 'configure',
    enableMonitoringStatus: 'enableMonitoring',
    disableMonitoringStatus: 'disableMonitoring',
    delete: 'remove',
    update: 'update',
    //  modify:'Modify'
};
export const IAssetPermission = {
    hasMonitorInstance: false,
    hasDeleteInstance: false,
    hasEnableInstance: false,
    hasDisableInstance: false,
    hasUpdateInstance: false,
};

export type ActionMenuValue =
    | typeof ACTION_MENU_VALUE.configure
    | typeof ACTION_MENU_VALUE.enableMonitoringStatus
    | typeof ACTION_MENU_VALUE.disableMonitoringStatus
    | typeof ACTION_MENU_VALUE.delete
    | typeof ACTION_MENU_VALUE.update;

export const ACTION_MENU_LIST = [
    {
        id: 1,
        label: 'Configure',
        value: ACTION_MENU_VALUE.configure,
        isDisabled: false,
    },
    {
        id: 3,
        label: 'Enable',
        value: ACTION_MENU_VALUE.enableMonitoringStatus,
        isDisabled: false,
    },
    {
        id: 2,
        label: 'Disable',
        value: ACTION_MENU_VALUE.disableMonitoringStatus,
        isDisabled: false,
    },
    {
        id: 4,
        label: 'Remove',
        value: ACTION_MENU_VALUE.delete,
        isDisabled: false,
    },
    {
        id: 5,
        label: 'Update',
        value: ACTION_MENU_VALUE.update,
        isDisabled: false,
    },
    // {
    //     id: 6,
    //     label: 'Modify',
    //     value: ACTION_MENU_VALUE.modify,
    //     isDisabled: false,
    // },
];

export interface InstanceConfigState {
    computeModels: {
        matching: ComputeModelFromJson[];
        all: ComputeModelFromJson[];
        activeTab: string;
    };
    activeModel: ActiveModelType;

    instancesTable: {
        includeChildElements: boolean;
        tableData: {
            byId: TableDataById;
            entities: any[];
            selectedInstances: any[];
        };
        filterData: { entities: any[] };
        pagination: {
            activePage: number;
            enteriesPerPage: number;
            totalRecords: number;
        };
        filters: { [key: string]: { value: string } };
        sorting: { sortBy: string; order: 'ASC' | 'DESC' };
        actionMenuList: typeof ACTION_MENU_LIST;
    };
    isLoading: boolean;
    isComputeModelLoading: boolean;
    isSaving: boolean;
    isupdateing: boolean;
    selectedMenuItem?: ActionMenuValue;
    assetCriticalityModels: ComputeModelToJson[];
    selectedCriticalityModel?: ComputeModelToJson;
    instanceMonitoringList: InstanceMonitoringItem[];
    supportedTypes: SupportedTypes[];
    selectedTypes: SelectedTypes;
    isInstanceConfigured: boolean;
    configFormfields: ConfigFormFields;
    activeTypeParams: ActiveTypeParams;
    assetConfigLoading: boolean;
    buttonLodaing: boolean;
    segregatedModelsLoading: boolean;
    originalTypeData?: ComputeModelToJson;
    originalFunctionLoading: boolean;
    gettingLibraryReferences: boolean;
    newModelSaveAvailable: string;
    isCheckeditems:boolean;
    isCheckeditemupdate :boolean,
    selectedTypesUpdate:[],
    checkedAllItems:[],
    clickedActiveParams:any;

    // CheckBoxAdd: ICheckBoxItem[]
    // previouslySelectedTypes: SelectedTypes;
}


export interface ICheckBoxItem {
    objectId: string;
    typeId: string;
    modelId: string;
    version: string;
}

export interface SegregatedModelItemValue {
    objectId: string;
    typeId: string;
    typeName: string;
    version: string;
}
export interface SegregatedModelItem {
    [key: string]: SegregatedModelItemValue[];
}
export interface TableDataById {
    [key: string]: AssetTableDataJson;
}

export interface AssetTableDataJson {
    amInfo: AmInfo[];
    description: string | null;
    isLeaf: boolean;
    link: string;
    model: string;
    name: string;
    objectId: string;
    type: string;
}

export interface AmInfo {
    assetMonitorModel: string;
    assetMonitorType: string;
    cmeID: string | null;
    configurationStatus: string;
    severity: string;
    status: string;
    latestVersion: string;
    isUpdateAvailable: boolean;
}
export interface ActiveTypeParams {
    modelId: string;
    typeId: string;
    version: string;
    objectId: string;
}

export interface ConfigFormFields {
    name: string;
    tags: string[];
    description: string;
}
export interface SupportedTypes {
    key: string;
    value: {
        modelTypeLabel: string;
        types: { typeId: string; typeName: string; version: string; objectId: string }[];
    };
}

// export interface SelectedTypes extends Omit<SupportedTypes, "value"> {
//     selectedType: string;
// }
export interface SelectedTypes {
    [key: string]: { selectedType: string; version: string; objectId: string };
}

export interface InstanceMonitoringItem {
    key: string;
    value: string[];
}

export interface AssetCriticalityModel {
    lastModified: string;
    model: string;
    name: string;
    objectId: string;
    properties: object;
    type: string;
    variables: object;
    version: number;
}

export type InstanceSaveType =
    | typeof INSTANCE_SAVE_MODE.SAVE
    | typeof INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY
    | typeof INSTANCE_SAVE_MODE.UPDATE
    | typeof INSTANCE_SAVE_MODE.VIEW_AND_UPDATE

export interface SelectedInstances {
    objectId: string;
    model: string;
    sceModuleId: string | null;
}

export interface FunctionTypeBasics {
    name: string;
    model: string;
    typeId: string;
    version: string;
}

export type Actions =
    | ReturnType<
          | typeof getAllComputeModelsStore
          | typeof changeActiveModelInstanceConfig
          | typeof getAssetTableDataStore
          | typeof addToSelectedInstance
          | typeof removeFromSelectedInstance
          | typeof updateActiveTabInstanceConfig
          | typeof changeActivePageInstanceConfig
          | typeof changeEnteriesPerPageInstanceConfig
          | typeof getAssetInstanceListActionSuccess
          | typeof handleFilterAssetTableData
          | typeof handleSortAssetTableData
          | typeof handleAssetToggle
          | typeof handleConfiguredInstanceJson
          | typeof handleLoadingConfiguredInstance
          | typeof handleAssetToggleUnconfiguredInstance
          | typeof updateComputeModelInstanceConfig
          | typeof saveComputeModelInstanceConfigSuccess
          | typeof deleteComputeModelInstanceConfigSuccess
          | typeof handleViewModeChangeInstanceConfig
          | typeof handleAssetModelConfig
          | typeof clearAssetModelDetailConfig
          | typeof saveComputeModelInstanceConfigError
          | typeof deleteComputeModelInstanceConfigError
          | typeof deleteComputeModelInstanceConfig
          | typeof getMatchingComputeModelsStore
          | typeof getMatchingComputeModelsApi
          | typeof handleIncludeAllChildElements
          | typeof handleTableTotalRecords
          | typeof getAllComputeModelsApi
          | typeof getAssetInstanceListActionRequest
          | typeof updateSelectedMenuItem
          | typeof updateAssetCriticalityModels
          | typeof updateSelectedCriticalityModel
          | typeof updateInstanceMonitoringList
          | typeof handleAssetConfigureBtnClickAction
          | typeof updateSupportedTypes
          | typeof updateSelectedSupportedType
          | typeof getConfiguredInstanceJson
          | typeof handleLoading
          | typeof getSelectedTypeComputeModelFromJson
          | typeof updateSelectedTypeJson
          | typeof updateAssetConfigModalFields
          | typeof updateActiveModelTypeParams
          | typeof handleConfiguredSaveInstance
          | typeof updateAssetConfigLoader
          | typeof updateOriginalTypeJson
          | typeof updateInstanceTypeJsonConditionDetails
          | typeof hanldeInstanceTypeOriginalFunctionsLoading
          | typeof getLibraryReferencesForFunctionTypesRequest
          | typeof getLibraryReferencesForFunctionTypesSuccess
          | typeof getLibraryReferencesForFunctionTypeFailure
          | typeof handleAssetEnableBtnClickAction
          | typeof updateButtonLoader
          | typeof viewupdateSelectedSupportedType
          | typeof viewandUpdateComputeModelInstanceConfig
          | typeof newModelSaveAvailable
          | typeof changeActivePageInstanceUpdate
          | typeof getLoadedInstace
          | typeof checkedListofItems
          | typeof updateSelectedSupportedTypemodel
          | typeof saveComputeModelInstanceConfigUpdate
          | typeof checkedListofItemupdate
          |typeof CheckedAllItems
          //   | typeof updatedPreviousSelectedTypes
      >
    | { type: typeof ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL };
