import { fabric } from 'fabric';
import { alramIconSize, alarmIconColor, triggerColor } from './UiConfig';

const scale = alramIconSize / 18;
export interface FabricAlarmIconParams {
    connected: boolean;
}
export class FabricHistoryIcon extends fabric.Text {
    showHistoricalDataModal: () => void;
    triggerOff: () => void;
    triggerOn: () => void;

    constructor(options: FabricAlarmIconParams) {
        super('H', {
            fill: options.connected ? triggerColor : alarmIconColor,
            left: 0,
            fontSize: 14,
            stroke: options.connected ? triggerColor : alarmIconColor,
            originX: 'right',
            originY: 'center',
            scaleX: scale,
            scaleY: scale,
            top: 0,
            hoverCursor: 'pointer',
            selectable: false,
        });

        this.showHistoricalDataModal = () => {};

        this.triggerOff = () => {
            this.set('stroke', alarmIconColor);
            this.set('fill', alarmIconColor);
        };

        this.triggerOn = () => {
            this.set('stroke', triggerColor);
            this.set('fill', triggerColor);
        };
    }
}
