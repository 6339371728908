import React, { useState, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import * as _ from 'lodash';
import './style.scss';
import { If } from '../../../routes/Functions/helper';
import {
    Inputs,
    Endpoint,
    Output,
    Condition,
    Calculation,
    Alarm,
} from '../../../routes/Functions/constants';
import ConditionType from '../../../routes/Functions/FunctionTypes/ConditionType';
import EndPoint from '../../../routes/Functions/FunctionTypes/EndPoint';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CreateFunctionAssetDetails, handlePopoverOpen } from '../../../store/function/action';
import { functionType } from '../../../routes/Functions/type';
import { TabControl, TabItem } from '@abb/abb-common-ux-react';
import {
    LOADER_TYPE,
    LOADER_SIZE,
    TABLE_IO_TYPE,
    FUNCTION_MODE,
} from '../../../utils/constants/appConstants';
import Loader from '../../Loader';

import { StoreState } from '../../../store';
import TableIOType from '../../../routes/Functions/FunctionTypes/TableIOType';
import CalculationLogicFunctionType from './CalculationLogicFunctionType';
import AlarmFunctionType from './AlarmFunctionType';
import { ShowMessageDispatchProps } from '../../../routes/Functions';

interface AppProps {
    isEncrypted: boolean;
}

function FunctionConfiguration(
    props: AppProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps> &
        ShowMessageDispatchProps
) {
    const { isLoading, activeFunction } = props;
    const [tabSelected, updateTabSelected] = useState(functionType.Input as any);
    const [functionProperties, updateFunctionProperties] = useState(
        activeFunction && activeFunction.properties
    );

    useEffect(() => {
        updateFunctionProperties(activeFunction && activeFunction.properties);
    }, [activeFunction]);

    let tabNavList: any = [];

    if (_.has(functionProperties, 'inputs')) {
        tabNavList.push(functionType.Input);
    }

    if (_.has(functionProperties, 'inputs.conditions')) {
        tabNavList.push(functionType.Condition);
        if (_.has(functionProperties, 'inputs.alarmMapping')) {
            tabNavList.push(functionType.Alarm);
        }
    }

    if (_.has(functionProperties, 'inputs.calculations')) {
        tabNavList.push(functionType.Calculation);
    }

    if (_.has(functionProperties, 'outputs')) {
        tabNavList.push(functionType.Output);
    }

    if (_.has(functionProperties, 'settings')) {
        tabNavList.push(functionType.Endpoint);
    }

    const commonProps = {
        activeTab: _.indexOf(tabNavList, tabSelected),
        onTabChange: (oldIndex: any, newIndex: number) => updateTabSelected(tabNavList[newIndex]),
    };

    classnames('nav-btn', 'nav-btn-active');

    console.log(tabNavList, activeFunction, functionProperties);

    useEffect(() => {
        updateTabSelected(tabNavList[0]);
    }, [activeFunction]);

    return (
        <div className="wrapper-function-configuration1">
            {isLoading && (
                <div className="center-div1">
                    <Loader type={LOADER_TYPE.RADIAL} sizeClass={LOADER_SIZE.LARGE} />
                </div>
            )}

            <div className="headerFunction editHeaderPopUp">
                <Fragment>
                    {activeFunction && activeFunction.name && (
                        <div className="align-center-baseline">
                            <Fragment>
                                <b>
                                    {activeFunction &&
                                        `${activeFunction.name} ${activeFunction.version}`}
                                </b>
                            </Fragment>
                        </div>
                    )}
                </Fragment>
            </div>
            <div className="view-mode-func-wrapper">
                <div className="headerNavLinkFunction" onClick={() => handlePopoverOpen(false)}>
                    <TabControl type="primary" {...commonProps}>
                        {tabNavList.map((list: string, listIndex: number) => {
                            return <TabItem key={listIndex} title={list} />;
                        })}
                    </TabControl>
                    <div
                        className={`propertiesView ${
                            _.has(functionProperties, 'inputs.conditions') &&
                            tabSelected === Condition
                                ? 'conditions-tab'
                                : ''
                        }`}
                    >
                        {functionProperties && (
                            <Fragment>
                                <If
                                    cond={
                                        _.has(functionProperties, 'inputs') &&
                                        tabSelected === Inputs
                                    }
                                >
                                    <TableIOType
                                        ioData={functionProperties.inputs}
                                        columnType={TABLE_IO_TYPE.INPUT}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, 'inputs.conditions') &&
                                        tabSelected === Condition
                                    }
                                >
                                    <ConditionType
                                        isEncrypted={props.isEncrypted}
                                        inputs={functionProperties.inputs.conditions}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, 'inputs.alarmMapping') &&
                                        tabSelected === Alarm
                                    }
                                >
                                    <AlarmFunctionType />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, 'inputs.calculations') &&
                                        tabSelected === Calculation
                                    }
                                >
                                    <CalculationLogicFunctionType
                                        functionMode={FUNCTION_MODE.VIEW}
                                        isEncrypted={props.isEncrypted}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, 'outputs') &&
                                        tabSelected === Output
                                    }
                                >
                                    <TableIOType
                                        ioData={functionProperties.outputs}
                                        columnType={TABLE_IO_TYPE.OUTPUT}
                                    />
                                </If>

                                <If
                                    cond={
                                        _.has(functionProperties, 'settings') &&
                                        tabSelected === Endpoint
                                    }
                                >
                                    <EndPoint settings={functionProperties.settings} />
                                </If>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        isLoading: state.exportAsset.isLoading,
        activeFunction: state.exportAsset.selectedFunctionToView.assetsDetails,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FunctionConfiguration);
