export const PERFORM_DROP_DOWN_ACTION = [
    {
        id: 1,
        label: 'Configure',
        value: 'configure',
    },
    {
        id: 2,
        label: 'Start Monitoring',
        value: 'enable/disableMonitoring',
    },
    {
        id: 3,
        label: 'Delete',
        value: 'remove',
    },
];
export const SORT_BY_DROP_DOWN_ACTION = [
    {
        label: 'Configuration Status',
        value: 'Configuration Status',
    },
];
export const PAGE_ENTRY_DROP_DOWN_ACTION = [
    {
        label: '5 entries per page',
        value: 5,
    },
    {
        label: '10 entries per page',
        value: 10,
    },
    {
        label: '25 entries per page',
        value: 25,
    },
    {
        label: '50 entries per page',
        value: 50,
    },
    {
        label: '100 entries per page',
        value: 100,
    },
];
