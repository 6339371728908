import React from 'react';
import { Checkbox } from '@abb/abb-common-ux-react';
import './style.scss';
import { Dispatch } from 'redux';
import { InstanceMonitoringItem, ActionMenuValue } from '../../../../../store/instanceConfig/types';
import { updateInstanceMonitoringList } from '../../../../../store/instanceConfig/actions';
import { connect } from 'react-redux';
import CriticalityCheckboxRow from './CriticalityCheckboxRow';
interface CriticalityModalItemProps {
    data: any;
    selectAllItems: boolean;
    selectedMenuItem: ActionMenuValue | undefined;
}
const CriticalityModalItem = (props: CriticalityModalItemProps) => {
    return (
        <div className="wrapper-criticality-item">
            <div className="item-title">{props.data.name}</div>

            {props.data.amInfo.map((item: any, index: number) => {
                return (
                    <CriticalityCheckboxRow
                        selectAllItems={props.selectAllItems}
                        selectedAsset={props.data}
                        rowData={item}
                        selectedMenuItem={props.selectedMenuItem}
                    />
                );
            })}
        </div>
    );
};

export default CriticalityModalItem;
