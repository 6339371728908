import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { AssetType } from "../../../transformers/AssetType";

import CustomLeftNavigation from "../../../components/CustomLeftNavigation";

import {
    MODEL_TYPES,
    EXPORT_MODELS_MODES,
} from "../../../utils/constants/appConstants";
import SearchBar from "../../../components/SearchBar";
import {
    ModelUsedInstanceType,
    ModelsPageState,
} from "../../../store/modelsPage/types";
import { TypeMenuAction } from "../../../components/LeftNavigationData";
import { ImportModalPayload } from "../../../store/exportAsset/types";
import Condition from "../../../components/shared/Condition";
import Loader from "../../../components/Loader";
import { Button, Dialog, Checkbox } from "@abb/abb-common-ux-react";

import { sce } from "sce-engg-model-19.09";
import Flex from "../../../components/shared/Flex";
import ModalExportDialog from "../../../components/ExportDialog/modalExportDialog";
import InstancesTable from "../../../components/LeftNavigationData/DataRow/InstancesTable";
import { StoreState } from "../../../store";
import { Dispatch } from "redux";
import { changeZipFileNameForExport } from "../../../store/exportAsset/actions";
import { connect } from "react-redux";
import { showModal } from "../../../store/modal/action";
import GetExportZipName from "../../../components/GetExportZipName";
import AssetMenu from "./AssetMenu";
import { isGlobalTenantType } from "../../../utils/helpers";
import { ComputeModelToJson } from "../../../transformers/ComputeModel/toJson/ComputeModelToJson";
import { ComputeModelFromJson } from "../../../transformers/ComputeModel/fromJson/ComputeModelFromJson";

interface TypesLeftNavProps {
    multipleModals?: any;
    showSpinnerModal?: any;
    saveModelsToExport?: any;
    selectedModalDetails?: any;
    computeModelsForExport?: any;
    exportModelsArray: (val: {
        mode: string;
        selectedModalDetails: any[];
    }) => any;
    saveModelsToState?: any;
    CanvasController?: any;
    canvas: any;
    title?: string;
    editMode: boolean;
    searchValue: string;
    supportedModels: {
        key: string;
        value: any;
    }[];
    getComputeModelsApi: (data: any) => any;
    activeAsset: AssetType | null;
    searchText: string;
    computeModels: ModelsPageState["computeModels"];
    activeParentKey: string;
    computeModelsLoading: boolean;
    permissions: {
        hasSave?: boolean;
        hasExport?: boolean;
        hasEdit?: boolean;
        hasDelete?: boolean;
        hasImport?: boolean;
    };
    activeModelId: string;
    isDirty: boolean;
    isNew: boolean;
    usedInstanceList: ModelUsedInstanceType[];
    showMenu: boolean;
    selectedItemId: string;
    onSearch: (search: string) => void;
    getNodeData: (node: AssetType) => void;
    showCreateModel: () => void;
    updateActiveParentKey: (key: string) => void;
    showConfirmationDialog: (modelId: string) => void;
    handeTypeMenuAction: (actionName: TypeMenuAction) => void;
    handleSelectedItem: (itemId: string) => void;
    handleSelectedType: (
        modelId: string,
        node: AssetType,
        selectedItemId: string,
        computeModels: ModelsPageState["computeModels"]
    ) => void;
    toggleImportExportDialog: () => void;
    showImportModal: (payload: ImportModalPayload) => void;
    computeModelDeleting: any;
}

const TypesLeftNav = (
    props: TypesLeftNavProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const {
        multipleModals,
        supportedModels,
        computeModelsForExport,
        exportModelsArray,
    } = props;
    const [isModelDeleted, updateIsModelDeleted] = useState(false);
    const [assetExportList, updateAssetExportList] = useState([] as any[]);

    const [leftNavigationNavData, setLeftNavigationNavData] = useState(
        _.cloneDeep(supportedModels)
    );

    const [computeModelValues, setComputeModelValues] = useState({
        ...props.computeModels.byId,
    });

    const [selected, updateSelected] = useState(props.activeModelId || "");

    const [importDialog, setImportDialog] = useState(false);

    const [usedInstanceList, updateUsedInstanceList] = useState<
        ModelUsedInstanceType[]
    >([]);
    const [showModal, updateModalState] = useState(false);
    const [checkAll, updateCheckAll] = useState(false);
    const [exportLoadedTypes, updateExportLoadedTypes] = useState([] as any);
    const [resetStateTrigger, setResetStateTrigger] = useState(0);
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        updateUsedInstanceList(props.usedInstanceList);
    }, [props.usedInstanceList]);

    const handleActiveModelChange = useCallback(
        (activeModelKey: string) => {
            let associatedModel: any;
            leftNavigationNavData.every((supportedModel: any) => {
                supportedModel.value &&
                    supportedModel.value.every((asset: any) => {
                        if (asset.models) {
                            let assetModelList = asset.models.filter(
                                (item: any) => {
                                    if (item.objectId === activeModelKey) {
                                        return true;
                                    }
                                    return false;
                                }
                            );
                            debugger;
                            associatedModel = assetModelList[0] && asset;
                            if (associatedModel) {
                                return false;
                            }
                        }
                        return true;
                    });
                if (associatedModel) {
                    return false;
                }
                return true;
            });
            let loadedComputedModels = Object.values(computeModelValues).filter(
                (item: any) => {
                    return (
                        item!.associatedObject!.typeId ===
                        associatedModel!.assetRef
                    );
                }
            );
            let loadedComputedModelsById: {
                [key: string]: any;
            } = {};
            loadedComputedModels.forEach((item: any) => {
                loadedComputedModelsById[item.objectId] =
                    computeModelValues[item.objectId];
            });
            associatedModel &&
                props.handleSelectedType(
                    activeModelKey,
                    associatedModel,
                    associatedModel["assetRef"],
                    {
                        byId: loadedComputedModelsById,
                        entities: Object.keys(loadedComputedModelsById),
                    }
                );
        },
        [props.computeModels, computeModelValues, leftNavigationNavData]
    );

    const searchBarPlaceholderText =
        props.title === MODEL_TYPES.OBJECTS.label.toUpperCase()
            ? "Search Object Types"
            : MODEL_TYPES.FUNCTIONS.value;

    useEffect(() => {
        let storedComputedModels = { ...computeModelValues };
        if (isModelDeleted) {
            let loadedComputedModels = Object.values(computeModelValues).filter(
                (item: any) => {
                    return (
                        item.associatedObject.typeId ===
                        props.activeAsset!.assetRef
                    );
                }
            );
            if (
                loadedComputedModels.length >
                props.computeModels.entities.length
            ) {
                loadedComputedModels.forEach((item: any) => {
                    if (!props.computeModels.byId[item.objectId]) {
                        delete storedComputedModels[item.objectId];
                    }
                });
            }
            updateIsModelDeleted(false);
        }
        setComputeModelValues({
            ...storedComputedModels,
            ...props.computeModels.byId,
        });
    }, [props.computeModels, props.computeModels.entities]);

    useEffect(() => {
        if (props.computeModelDeleting.isDeleting) {
            updateIsModelDeleted(true);
        }
    }, [props.computeModelDeleting]);

    useEffect(() => {
        updateSelected(props.activeModelId);
    }, [props.activeModelId]);

    useEffect(() => {
        if (checkAll) {
            handleCheckAll(true);
        }
        if (multipleModals.length !== exportLoadedTypes.length) {
            updateExportLoadedTypes(
                multipleModals.map((supportedModel: any) => {
                    return supportedModel.uniqueTypeId;
                })
            );
        }
    }, [multipleModals]);

    const getTypeMenuProps = () => {
        let menuprops = [
            {
                icon: "abb/edit",
                label: "Create Model",
                onMenuClick: () => {
                    props.showCreateModel();
                },
            },
            {
                icon: "abb/export",
                label: "Export Model",
                onMenuClick: (data: any) => {
                    let modelsToBeExported = [] as any;
                    data.models.forEach((item: any) => {
                        if (item.exportModel) {
                            modelsToBeExported.push(item.exportModel);
                        }
                    });
                    if (modelsToBeExported.length === 0) {
                        modelsToBeExported = [...props.originalComputeModels];
                    }
                    props.showGetFileNameModal(
                        () => onExport(modelsToBeExported),
                        `${data.assetName}_${data.assetVersion}`
                    );
                },
            },
            {
                icon: "abb/import",
                label: "Import Model",
                onMenuClick: () => {
                    setImportDialog(true);
                },
            },
        ];
        return menuprops;
    };

    const getModelMenuProps = (model: any) => {
        let isGlobalModel = isGlobalTenantType(
            (model.modelDetails && model.modelDetails.tags) || [],
            model.modelDetails.typeId
        );
        let menuprops = [];
        if (props.permissions.hasEdit && !isGlobalModel) {
            menuprops.push({
                icon: "abb/edit",
                label: "Edit Model",
                onMenuClick: () => {
                    props.handeTypeMenuAction(TypeMenuAction.OPEN_EDIT_MODE);
                },
            });
        }
        menuprops.push(
            {
                icon: "abb/information-circle-2",
                label: "Details",
                onMenuClick: () => {
                    props.handeTypeMenuAction(TypeMenuAction.SHOW_UPDATE_MODAL);
                },
            },
            {
                icon: "abb/save",
                label: "Save As Model",
                onMenuClick: () => {
                    props.handeTypeMenuAction(TypeMenuAction.SHOW_SAVEAS_MODAL);
                },
            },
            {
                icon: "abb/view",
                label: "View instances",
                onMenuClick: () => {
                    updateModalState(true);
                    props.handeTypeMenuAction(
                        TypeMenuAction.SHOW_USED_INSTANCE_MODAL
                    );
                },
            }
        );
        if (props.permissions.hasDelete && !isGlobalModel) {
            menuprops.push({
                icon: "abb/trash",
                label: "Delete Model",
                onMenuClick: () => {
                    props.handeTypeMenuAction(TypeMenuAction.SHOW_DELETE_MODAL);
                },
            });
        }
        if (
            props.permissions.hasExport &&
            sce.isDeveloper() &&
            !isGlobalModel
        ) {
            menuprops.push({
                icon: "abb/export",
                label: "Export Model",
                onMenuClick: (data: any) => {
                    let modelsToBeExported = [
                        data.exportModel
                            ? data.exportModel
                            : props.originalComputeModels
                                  .filter(
                                      (item) => item.objectId === data.objectId
                                  )
                                  .pop(),
                    ];
                    props.showGetFileNameModal(
                        () => onExport(modelsToBeExported),
                        `${data.modelDetails.name}_${data.modelDetails.version}`
                    );
                },
            });
        }
        return menuprops;
    };

    const isDisplayCheckBox = (data: any) => {
        return !isGlobalTenantType(
            (data.modelDetails && data.modelDetails.tags) || [],
            data.modelDetails.typeId
        );
    };

    useEffect(() => {
        let newSupportedModels = _.cloneDeep(supportedModels);
        newSupportedModels.forEach((supportedModel) => {
            supportedModel["value"].forEach((data: any) => {
                let modelsForExport = props.multipleModals
                    .filter((multipleAssetModelObj: any) => {
                        return (
                            multipleAssetModelObj.uniqueTypeId === data.assetRef
                        );
                    })
                    .pop();
                if (modelsForExport) {
                    let computedModels: any = {
                        byId: {},
                        entities: [],
                    };
                    modelsForExport[data.assetName].forEach(
                        (json: ComputeModelToJson) => {
                            Object.keys(json.properties.functions).forEach(
                                (key) => {
                                    //@ts-ignore
                                    json.properties.functions[key].inhibit = {
                                        ...{
                                            referenceValue: {
                                                dataType: "boolean",
                                            },
                                            actualValue: {
                                                dataType: "boolean",
                                            },
                                        },
                                        ...json.properties.functions[key]
                                            .inhibit,
                                    };
                                }
                            );

                            try {
                                const model = new ComputeModelFromJson(json);
                                computedModels.byId[model.objectId] = model;
                                computedModels.entities.push(model.objectId);
                            } catch (err) {
                                console.log("Invalid json :", err);
                            }
                        }
                    );
                    data.models = Object.values(computedModels.byId);
                    data.models.forEach((item: any) => {
                        computeModelValues[item.objectId] = item;
                    });
                    setComputeModelValues({ ...computeModelValues });
                }
            });
        });
        setLeftNavigationNavData(newSupportedModels);
    }, [supportedModels]);

    const onTypeCollapseOpen = (data: any) => {
        const modelsFetchingDetails = {
            objectRef: data.assetRef,
            version: data.assetVersion,
            asset: data,
            currentRoutePath: `/${
                window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                ]
            }`,
            uniqueTypeId: data.assetRef,
        };
        props.getComputeModelsApi(modelsFetchingDetails);
        // computeModelsForExport(modelsFetchingDetails);
        // if (exportLoadedTypes.indexOf(data.assetRef) > -1) {
        //     exportLoadedTypes.push(data.assetRef);
        //     updateExportLoadedTypes([...exportLoadedTypes]);
        // }
    };

    const handleCheckAll = (checked: boolean) => {
        if (!checked) {
            updateAssetExportList([]);
            return;
        }
        let modelsToExport = [] as any;
        multipleModals.forEach((supportedType: any) => {
            let modelsKey = Object.keys(supportedType).filter(
                (key: string) => key !== "uniqueTypeId"
            )[0];
            modelsToExport = [
                ...modelsToExport,
                ...supportedType[modelsKey].filter((model: any) => {
                    return !isGlobalTenantType(
                        model.properties.model.tags.value,
                        model.properties.model.typeId.value
                    );
                }),
            ];
        });
        updateAssetExportList(modelsToExport);
    };

    const leftNavigationNLevelProps = [
        {
            keyIdentifier: "key",
            label: "key",
            nextLevelKey: "value",
            icon: "abb/folder",
            filter: true,
            filterMethod: (data: any) => {
                let isChildFiltered =
                    data.value &&
                    data.value.filter((asset: any) =>
                        asset.assetName
                            .toLowerCase()
                            .includes(filterText.toLowerCase())
                    ).length > 0;
                return isChildFiltered;
            },
            onCheckBoxClick: (data: any, checked: boolean) => {
                if (!data.value) {
                    return;
                }
                let updatedSelectedModals = [] as any;
                if (checked) {
                    data.value.forEach((supportedModel: any) => {
                        supportedModel.models &&
                            supportedModel.models.forEach((item: any) => {
                                updatedSelectedModals.push(item.exportModel);
                            });
                    });
                } else {
                    data.value.forEach((supportedModel: any) => {
                        supportedModel.models &&
                            supportedModel.models.forEach((item: any) => {
                                updatedSelectedModals =
                                    updatedSelectedModals.filter(
                                        (modalType: any) => {
                                            return (
                                                modalType.objectId !==
                                                item.objectId
                                            );
                                        }
                                    );
                            });
                    });
                }
                if (
                    updatedSelectedModals.length ===
                    Object.values(computeModelValues).filter((model) => {
                        return !isGlobalTenantType(
                            model.modelDetails.tags,
                            model.modelDetails.typeId
                        );
                    }).length
                ) {
                    updateCheckAll(true);
                }
                updateAssetExportList([...updatedSelectedModals]);
            },
        },
        {
            keyIdentifier: "assetRef",
            label: "assetName",
            labelFunction: (data: any) => {
                return `${data.assetName} ${data.assetVersion}`;
            },
            nextLevelKey: "models",
            icon: "abb/object",
            nextLevelAsyncLoader: onTypeCollapseOpen,
            nextLevelAsyncLoading: props.computeModelsLoading,
            floatingMenuProps: getTypeMenuProps(),
            filter: true,
            filterMethod: (data: any) => {
                return data.assetName
                    .toLowerCase()
                    .includes(filterText.toLowerCase());
            },
            showMenuOnlyOnSelect: true,
            showCheckbox: true,
            component: AssetMenu,
            componentProps: {
                assetExportList,
                updateAssetExportList,
                leftNavigationNavData,
                setLeftNavigationNavData,
                handleActiveModelChange,
                computeModelValues,
                setComputeModelValues,
            },
            onCheckBoxClick: (data: any, checked: boolean) => {
                if (
                    !data.models ||
                    exportLoadedTypes.indexOf(data.assetRef) < 0
                ) {
                    return;
                }
                let updatedSelectedModals = [...assetExportList] as any;
                data.models.forEach((item: any) => {
                    updatedSelectedModals = updatedSelectedModals.filter(
                        (modalType: any) => {
                            if (!modalType || !item) {
                                return;
                            }
                            return modalType.objectId !== item.objectId;
                        }
                    );
                });
                if (checked) {
                    data.models.forEach((item: any) => {
                        updatedSelectedModals.push(item.exportModel);
                    });
                }
                if (
                    updatedSelectedModals.length ===
                    Object.values(computeModelValues).filter((model) => {
                        return !isGlobalTenantType(
                            model.modelDetails.tags,
                            model.modelDetails.typeId
                        );
                    }).length
                ) {
                    updateCheckAll(true);
                }
                updateAssetExportList([...updatedSelectedModals]);
            },
        },
        {
            keyIdentifier: "objectId",
            label: "modelDetails.name",
            labelFunction: (data: any) => {
                return `${data.modelDetails.name} ${data.modelDetails.version}`;
            },
            showCheckboxMethod: isDisplayCheckBox,
            onCheckBoxClick: (data: any, checked: boolean) => {
                let updatedSelectedModals = [] as any;
                if (checked) {
                    updatedSelectedModals = [
                        ...assetExportList,
                        data.exportModel,
                    ];
                } else {
                    updatedSelectedModals = assetExportList.filter(
                        (modalType: any) => {
                            return modalType!.objectId !== data!.objectId;
                        }
                    );
                }
                if (
                    updatedSelectedModals.length ===
                    Object.values(computeModelValues).filter((model) => {
                        return !isGlobalTenantType(
                            model.modelDetails.tags,
                            model.modelDetails.typeId
                        );
                    }).length
                ) {
                    updateCheckAll(true);
                }
                updateAssetExportList([...updatedSelectedModals]);
            },
            floatingMenuPropMethod: getModelMenuProps,
            showMenuOnlyOnSelect: true,
            onClick: (data: any) => {
                handleActiveModelChange(data.objectId);
            },
            showToolTip: true,
        },
    ];

    const onExport = (exportModelsList: any[]) => {
        const payload = {
            mode: EXPORT_MODELS_MODES.MULTPLE_OBJECTS,
            selectedModalDetails: exportModelsList,
        };
        exportModelsArray(payload);
        updateAssetExportList([]);
        updateCheckAll(false);
        setResetStateTrigger(resetStateTrigger + 1);
    };
    const isSupportedModelsLoading = () => {
        if (props.infoModelInstances) {
            if (
                props.infoModelInstances.properties &&
                props.infoModelInstances.properties.supportedModels &&
                Object.keys(props.infoModelInstances.properties.supportedModels)
                    .length !== supportedModels.length
            ) {
                return true;
            }
            if (
                props.infoModelInstances.properties &&
                props.infoModelInstances.properties.supportedModels
            ) {
                let returned_value = false;
                Object.keys(
                    props.infoModelInstances.properties.supportedModels
                ).forEach((val, index) => {
                    if (supportedModels[index].key !== val) {
                        returned_value = true;
                    }
                });
                return returned_value;
            }
        }
        return false;
    };

    const getDefaultModelsName = useCallback(() => {
        if (assetExportList.length === 1) {
            return `${assetExportList[0].properties.model.name.value}_${assetExportList[0].properties.model.version.value}`;
        }
        return "Models";
    }, [assetExportList]);

    return (
        <div className="navigation-container">
            <Condition when={sce.isDeveloper()}>
                <Flex row justifyContentBetween>
                    <Button
                        text="New Model"
                        icon="abb/plus-in-circle"
                        sizeClass="small"
                        className="import-new-btn"
                        onClick={() => props.showCreateModel()}
                    />
                    <Button
                        className="import-new-btn"
                        icon="abb/import"
                        text="Import"
                        sizeClass="small"
                        disabled={
                            !props.permissions.hasImport
                                ? true
                                : props.computeModelsLoading
                        }
                        onClick={() => {
                            props.showImportModal({
                                currentRoute: "Types",
                            });
                        }}
                        id="mImportBtn"
                    />
                </Flex>
            </Condition>
            <div>
                <Button
                    className="export-model-btn"
                    icon="abb/export"
                    disabled={
                        !props.permissions.hasExport ||
                        props.computeModelsLoading ||
                        !assetExportList.length
                    }
                    text="Export"
                    type="primary-blue"
                    sizeClass="small"
                    onClick={() =>
                        props.showGetFileNameModal(
                            () => onExport(assetExportList),
                            getDefaultModelsName()
                        )
                    }
                    id="mExportBtn"
                />
            </div>

            <div className="left-search-bar">
                <SearchBar
                    placeholder={`${searchBarPlaceholderText}`}
                    borderColor="border-dark-gray"
                    onChange={(search) => setFilterText(search)}
                    value={filterText}
                />
            </div>

            <div
                className={`select-all-element`}
                onClick={(e) => e.stopPropagation()}
            >
                <Checkbox
                    sizeClass="small"
                    className="checkbox"
                    disabled={false}
                    value={checkAll}
                    onChange={() => {
                        updateCheckAll(!checkAll);
                        handleCheckAll(!checkAll);
                    }}
                />
                <div
                    onClick={(e) => {
                        updateCheckAll(!checkAll);
                        handleCheckAll(!checkAll);
                    }}
                    className="content"
                >
                    <span>Select All</span>
                </div>
            </div>
            <div className="menu-list">
                <Condition
                    when={
                        !supportedModels ||
                        !supportedModels.length ||
                        isSupportedModelsLoading()
                    }
                >
                    <Loader sizeClass="small" type="radial" />
                </Condition>
                <Condition
                    when={
                        supportedModels &&
                        supportedModels.length &&
                        !isSupportedModelsLoading()
                    }
                >
                    <CustomLeftNavigation
                        navData={leftNavigationNavData}
                        nLevelMenuProps={leftNavigationNLevelProps as any}
                        parentSelected={selected}
                        updateParentSelected={updateSelected}
                        checkAllProps={{
                            checkAll,
                            updateCheckAll,
                            onCheckAllClick: handleCheckAll,
                        }}
                        filterText={filterText}
                        resetStateTrigger={resetStateTrigger}
                    />
                </Condition>
            </div>

            {importDialog && (
                <ModalExportDialog
                    showExportDialog={importDialog}
                    onExportClose={() => setImportDialog(false)}
                    importMode={true}
                    importForObjectType={true}
                />
            )}

            <Dialog
                containerClassName="logic-add-dialog new-layout"
                title="Instance List"
                dimBackground={true}
                showCloseButton={true}
                isOpen={showModal}
                onClose={() => {
                    updateModalState(false);
                    updateUsedInstanceList([]);
                }}
                className="instance-dialog-class"
            >
                <InstancesTable usedInstanceList={usedInstanceList} />
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        exportFileName: state.exportAsset.zipFileName,
        originalComputeModels: state.modelsPage.originalComputeModels,
        infoModelInstances: state.settings.infoModelInstance,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeExportZipName: (fileName: string) => {
            dispatch(changeZipFileNameForExport({ fileName }));
        },
        showGetFileNameModal: (exportMethod: () => any, filename: string) => {
            dispatch(
                showModal({
                    component: GetExportZipName,
                    modalTitle: "Add Export File Name",
                    data: {
                        exportMethod,
                        defaultFileName: filename,
                    },
                    closeModal: () =>
                        dispatch(changeZipFileNameForExport({ fileName: "" })),
                    customClassName: "delete-library-modal",
                })
            );
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TypesLeftNav);
