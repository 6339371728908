import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import ReactSelect from 'react-select';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';

import CollapsibleUnit from './CollapsibleUnit';
import { AssetType } from '../../transformers/AssetType';
import { getAssetRef, customReactSelectStyles } from '../../utils/helpers';
import CustomLeftNavigation from '../CustomLeftNavigation';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import CanvasController from '../Fabric';
import { CONFIGURATION_TOOL_PRIVILEGES } from '../../utils/constants/appConstants';
import SearchBar from '../SearchBar';

interface MultipleExportModalProps {
    supportedModels: any;
    multipleModals: any;
    showSpinnerModal: any;
    saveModelsToExport: any;
    selectedModalDetails: any;
    computeModelsForExport: any;
    saveModelsToState: any;
}

const MultipleExportModal = (props: MultipleExportModalProps) => {
    const {
        multipleModals,
        supportedModels,
        showSpinnerModal,
        saveModelsToState,
        saveModelsToExport,
        selectedModalDetails,
        computeModelsForExport,
    } = props;
    const [selectedDropDownList, updateSelectedDropDownList] = useState([] as any);
    const [assetList, updateAssetList] = useState([] as any);
    const [selectedAssets, updateSelectedAssets] = useState([] as any);
    const [canvasJson, updateCanvasJson] = useState(undefined as any);

    const [leftNavigationNavData, setLeftNavigationNavData] = useState(
        _.cloneDeep(supportedModels)
    );

    const canvasController = useMemo(() => {
        let newCanvas = new CanvasController(
            'canvas-export',
            CONFIGURATION_TOOL_PRIVILEGES.MODELS_PAGE
        );
        if (canvasJson) {
            newCanvas.fromJSON({
                json: canvasJson,
                editMode: false,
            });
        }
        return newCanvas;
    }, [canvasJson]);

    const updateMultipleModelDropDowns = (payload: any) => {
        if (!payload) {
            payload = [];
        }
        if (payload.length > selectedAssets.length) {
            const requiredAsset = assetList.filter(
                (item: AssetType) =>
                    item.assetRef === payload[payload.length - 1].value &&
                    item.assetName === payload[payload.length - 1].label
            );
            const modelsFetchingDetails = {
                objectRef: requiredAsset[0].assetRef,
                version: requiredAsset[0].assetVersion,
                asset: requiredAsset[0],
                currentRoutePath: `/${
                    window.location.pathname.split('/')[
                        window.location.pathname.split('/').length - 1
                    ]
                }`,
                uniqueTypeId: payload[payload.length - 1].value,
            };
            computeModelsForExport(modelsFetchingDetails);
        } else {
            let modalsPresent = _.cloneDeep(multipleModals);
            modalsPresent = modalsPresent.filter((modalType: any) => {
                const label = Object.keys(modalType)[0];
                const valueArray: any = Object.values(modalType)[0];
                let value: any;
                if (valueArray.length) {
                    value = valueArray[0].properties.associatedObjectType.typeId.value;
                }
                let present = false;

                if (!value) {
                    value = modalType.uniqueTypeId;
                }

                payload.forEach((dropStructure: any) => {
                    if (dropStructure.label === label) {
                        if (value && dropStructure.value === value) {
                            present = true;
                        } else if (!value) {
                            present = true;
                        }
                    }
                });
                if (present) {
                    return modalType;
                }
            });
            const actionPayload = {
                fetchedModals: modalsPresent,
                isDelete: true,
            };
            saveModelsToState(actionPayload);
            let updatedSelectedModals: any = _.cloneDeep(selectedModalDetails);
            updatedSelectedModals = updatedSelectedModals.filter((modalType: any) => {
                let value: any = modalType.properties.associatedObjectType.typeId.value;
                let present = false;

                payload.forEach((dropStructure: any) => {
                    if (dropStructure.value === value) {
                        present = true;
                    }
                });
                if (present) {
                    return modalType;
                }
            });
            saveModelsToExport(updatedSelectedModals);
        }
        updateSelectedAssets(payload);
    };

    const handleDropDownChange = (payload: any) => {
        let updatedMultipleModals = _.cloneDeep(multipleModals);
        updatedMultipleModals = updatedMultipleModals.filter((modalType: any) => {
            const valueArray: any = Object.values(modalType)[0];
            let value: any;
            if (valueArray.length) {
                value = valueArray[0].properties.associatedObjectType.model.value;
            }
            let present = false;
            if (!value) {
                value = getAssetRef(supportedModels, modalType);
            }
            payload.forEach((dropDownType: any) => {
                if (dropDownType.value && dropDownType.value === value) {
                    present = true;
                }
            });
            if (present) {
                return modalType;
            }
        });
        const actionPayload = {
            fetchedModals: updatedMultipleModals,
            isDelete: true,
        };
        saveModelsToState(actionPayload);
        let updatedSelectedModalDetails: any = _.cloneDeep(selectedModalDetails);
        updatedSelectedModalDetails = updatedSelectedModalDetails.filter(
            (selectedModalType: any) => {
                let value = selectedModalType.properties.associatedObjectType.model.value;
                let present = false;
                payload.forEach((dropDownType: any) => {
                    if (dropDownType.value === value) {
                        present = true;
                    }
                });
                if (present) {
                    return selectedModalType;
                }
            }
        );
        saveModelsToExport(updatedSelectedModalDetails);
        updateSelectedDropDownList(payload);
        updateAssetList([]);
        let updatedAssetsArray: any = [];

        supportedModels.forEach((supportedModelType: any) => {
            payload.forEach((modelType: any) => {
                if (supportedModelType.key === modelType.label) {
                    updatedAssetsArray.push(...supportedModelType.value);
                }
            });
            updateAssetList([...updatedAssetsArray]);
        });
        let updatedSelectedAssetsArray = _.cloneDeep(selectedAssets);
        updatedSelectedAssetsArray = updatedSelectedAssetsArray.filter((item: any) => {
            let present = false;
            payload.forEach((dropDownType: any) => {
                if (item.value.includes(dropDownType.value)) {
                    present = true;
                }
            });
            if (present) {
                return item;
            }
        });
        updateSelectedAssets(updatedSelectedAssetsArray);
    };

    const onTypeCollapseOpen = (data: any) => {
        const modelsFetchingDetails = {
            objectRef: data.assetRef,
            version: data.assetVersion,
            asset: data,
            currentRoutePath: `/${
                window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
            }`,
            uniqueTypeId: data.assetRef,
        };
        computeModelsForExport(modelsFetchingDetails);
    };

    useEffect(() => {
        let isChanged = false;
        leftNavigationNavData.forEach((supportedModel: any) => {
            supportedModel.value &&
                supportedModel.value.forEach((asset: any) => {
                    if (!asset.models) {
                        let assetModelList = multipleModals.filter((multipleAssetModelObj: any) => {
                            return multipleAssetModelObj.uniqueTypeId === asset.assetRef;
                        });
                        asset.models = assetModelList[0] && assetModelList[0][asset.assetName];
                        if (assetModelList[0]) {
                            isChanged = true;
                        }
                    }
                });
        });
        if (isChanged) {
            setLeftNavigationNavData([...leftNavigationNavData]);
        }
    }, [multipleModals]);

    const leftNavigationNLevelProps = [
        {
            keyIdentifier: 'key',
            label: 'key',
            nextLevelKey: 'value',
            icon: 'abb/folder',
        },
        {
            keyIdentifier: 'assetRef',
            label: 'assetName',
            nextLevelKey: 'models',
            icon: 'abb/object',
            nextLevelAsyncLoader: onTypeCollapseOpen,
        },
        {
            keyIdentifier: 'objectId',
            label: 'properties.model.name.value',
            showCheckbox: true,
            onCheckBoxClick: (data: any, checked: boolean) => {
                let updatedSelectedModals = [] as any;
                if (checked) {
                    updatedSelectedModals = [...selectedModalDetails, data];
                } else {
                    updatedSelectedModals = selectedModalDetails.filter((modalType: any) => {
                        return modalType.objectId !== data.objectId;
                    });
                }
                saveModelsToExport([...updatedSelectedModals]);
            },
            onClick: (data: any) => {
                data = _.cloneDeep(data);
                Object.keys(data.properties.functions).forEach((key) => {
                    //@ts-ignore
                    data.properties.functions[key].inhibit = {
                        ...{
                            referenceValue: {
                                dataType: 'boolean',
                            },
                            actualValue: {
                                dataType: 'boolean',
                            },
                        },
                        ...data.properties.functions[key].inhibit,
                    };
                });

                const model = new ComputeModelFromJson(data);
                console.log(canvasController);
                updateCanvasJson(model.json);
            },
        },
    ];

    return (
        <>
            <div className="export-dialog-body-multiple-model">
                <div className="left-nav-for-export">
                    {/* <Dropdown
                        multiselect={true}
                        value={selectedDropDownList}
                        onChange={(event) => handleDropDownChange(event)}
                        placeholder="Select Supported Types"
                        disabled={showSpinnerModal}
                    >
                        {supportedModels.map((supportedModelType: any) => {
                            const value =
                                (supportedModelType.value.length &&
                                    supportedModelType.value[0].assetType) ||
                                supportedModelType.key;
                            return (
                                <DropdownOption
                                    label={supportedModelType.key}
                                    value={value}
                                    key={supportedModelType.key}
                                ></DropdownOption>
                            );
                        })}
                    </Dropdown> */}
                    <div className="left-search-bar">
                        <SearchBar
                            placeholder="Search Model"
                            borderColor="border-dark-gray"
                            onChange={() => {}}
                            value=""
                        />
                    </div>
                    <CustomLeftNavigation
                        navData={leftNavigationNavData}
                        nLevelMenuProps={leftNavigationNLevelProps}
                    />
                </div>
                <div className="canvas-for-export">
                    {/* {assetList.length !== 0 && (
                        <DynamicDropDown
                            itemList={assetList}
                            selectedAssets={selectedAssets}
                            showSpinnerModal={showSpinnerModal}
                            updateMultipleModelDropDowns={updateMultipleModelDropDowns}
                        />
                    )} */}
                    {!canvasJson && <div className="empty-message">No Model Selected to View</div>}
                    <div className={`canvas-container-export`}>
                        <div id="canvas-parent-export" className={''}>
                            <canvas id="canvas-export"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="collapsible-modal-list">
                {multipleModals.map((multiple: any) => {
                    return (
                        <CollapsibleUnit
                            key={multiple.uniqueTypeId}
                            list={multiple}
                            collapseMode="types"
                            saveModelsToExport={saveModelsToExport}
                            selectedModalDetails={selectedModalDetails}
                        />
                    );
                })}
            </div> */}
        </>
    );
};

export default MultipleExportModal;

export const DynamicDropDown = (props: any) => {
    const { selectedAssets, itemList, updateMultipleModelDropDowns, showSpinnerModal } = props;
    const updatedItemList = itemList.map((item: AssetType, index: number) => {
        return {
            isNew: false,
            label: item.assetName,
            value: item.assetRef,
        };
    });

    return (
        <ReactSelect
            styles={customReactSelectStyles}
            isClearable={false}
            options={updatedItemList}
            isMulti
            // placeholder="Select Asset Types "
            isDisabled={showSpinnerModal}
            className="basic-multi-select"
            value={selectedAssets}
            getOptionLabel={(optionObj) => {
                return `${optionObj.label}`;
            }}
            getOptionValue={(optionObj) => {
                return `${optionObj.value}`;
            }}
            onChange={updateMultipleModelDropDowns}
        />
    );
};
