import { ActionTypes } from "../actionTypes";
import { ShowNotificationModalPayload } from "./types";

export function showNotificationModal(payload: ShowNotificationModalPayload) {
    return <const>{
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { ...payload }
    };
}

export function hideNotificationModal() {
    return <const>{
        type: ActionTypes.HIDE_NOTIFICATIOM_MODAL
    };
}
