import { ActionTypes } from '../actionTypes';
import { SupportedConditionMonitorAction, SupportedConditionMonitorState } from './types';

const initialState: SupportedConditionMonitorState = {
    supportedConditionMonitorList: [],
    loadingSupportedConditionMonitors: false,
    addingNewConditionMonitorModels: false,
    successFullyAddedMonitorModel: '',
    deletingNewConditionMonitorModels: false,
    updatingConditionMonitorModels: false,
    globalErrorForMonitorModels: '',
};

const supportedConditionMonitorReducer = (
    state: SupportedConditionMonitorState = initialState,
    action: SupportedConditionMonitorAction
): SupportedConditionMonitorState => {
    switch (action.type) {
        case ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_REQUEST: {
            return { ...state, loadingSupportedConditionMonitors: true };
        }

        case ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_SUCCESS: {
            return {
                ...state,
                loadingSupportedConditionMonitors: false,
                supportedConditionMonitorList: action.payload,
            };
        }

        case ActionTypes.GET_SUPPORTED_CONDITION_MONITORS_FAILURE: {
            return { ...state, loadingSupportedConditionMonitors: false };
        }

        case ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_REQUEST: {
            return { ...state, addingNewConditionMonitorModels: true };
        }

        case ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_SUCCESS: {
            return {
                ...state,
                addingNewConditionMonitorModels: false,
                successFullyAddedMonitorModel: action.payload.message,
            };
        }

        case ActionTypes.ADD_NEW_CONDITION_MONITOR_MODELS_FAILURE: {
            return {
                ...state,
                addingNewConditionMonitorModels: false,
                globalErrorForMonitorModels: action.payload,
            };
        }

        case ActionTypes.DELETE_CONDITION_MONITOR_MODELS_REQUEST: {
            return {
                ...state,
                deletingNewConditionMonitorModels: true,
                loadingSupportedConditionMonitors: true,
            };
        }

        case ActionTypes.DELETE_CONDITION_MONITOR_MODELS_SUCCESS: {
            return {
                ...state,
                deletingNewConditionMonitorModels: false,
                supportedConditionMonitorList: action.payload,
                loadingSupportedConditionMonitors: false,
            };
        }

        case ActionTypes.DELETE_CONDITION_MONITOR_MODELS_FAILURE: {
            return {
                ...state,
                deletingNewConditionMonitorModels: false,
                globalErrorForMonitorModels: action.payload,
                loadingSupportedConditionMonitors: false,
            };
        }

        case ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_REQUEST: {
            return { ...state, updatingConditionMonitorModels: true };
        }

        case ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_SUCCESS: {
            return {
                ...state,
                updatingConditionMonitorModels: false,
                successFullyAddedMonitorModel: action.payload.message,
            };
        }

        case ActionTypes.UPDATE_CONDITION_MONITOR_MODELS_FAILURE: {
            return {
                ...state,
                updatingConditionMonitorModels: false,
                globalErrorForMonitorModels: action.payload,
            };
        }
        case ActionTypes.RESET_GLOBAL_MONITOR_MODELS_ERROR: {
            return { ...state, globalErrorForMonitorModels: '' };
        }

        default: {
            return { ...state };
        }
    }
};

export default supportedConditionMonitorReducer;
