import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Icon } from '@abb/abb-common-ux-react';
import { ModalFooter } from 'reactstrap';
import { AbilityService } from 'sce-engg-model-19.09';

import { Dispatch } from 'redux';

import './style.scss';

interface UpdateModelProps {
    onUpdate: () => any;
    closeModal: () => any;
}

function UpdateModels(props: UpdateModelProps) {
    debugger;

    const handleUpdate = () => {
        props.closeModal();
        props.onUpdate();
    };

    return (
        <div>
            <p>
                If you update the model type,Instance level model changes will be erased. Do you still want
                to Update ?
            </p>
            <ModalFooter>
                <Button
                    type="discreet-black"
                    sizeClass="small"
                    // disabled={deletingLibrary || removingFunctionsFromExistingLibrary}
                    text="Cancel"
                    onClick={props.closeModal}
                />

                <Button
                    type="primary-blue"
                    sizeClass="small"
                    // disabled={!password.length && libraryDetails.isIPProtected && isSameOrigin}
                    text="Update"
                    onClick={handleUpdate}
                    // isLoading={deletingLibrary || removingFunctionsFromExistingLibrary}
                />
            </ModalFooter>
        </div>
    );
}

export default UpdateModels;
