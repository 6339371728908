import React from 'react';
import { connect } from 'react-redux';
import { setDataTypes } from '../../helper';
import { StoreState } from '../../../../store';
import { columns, dropDownOptions } from '../../type';
import InputOutputTableRow from './InputOutputTableRow';
import { TABLE_IO_TYPE, TABLE_ROW_CONSTANTS } from '../../../../utils/constants/appConstants';

interface CustomTableBodyProps {
    calculationData?: any[];
    functionType: {
        name: string;
        isMandatory: boolean;
        dataType: string;
        isDelete?: boolean;
        isDisabled?: boolean;
    }[];
    handleSelectedValue: (
        x: {
            value: any;
            label?: string;
            isNew?: boolean;
        }[],
        y: number
    ) => void;
    tableType: 'INPUT' | 'OUTPUT';
    setMandatory?: (x: number) => void;
    getInputValue?: (x: any, y: number) => void;
    handleUpdateDeleteList: (x: number) => void;
    deleteFunctionItems?: (e: any, index: number) => void;
    getValueAndUpdate: (x: string, y: number) => void;
    getDesValueAndUpdate: (x: string, y: number) => void;
    selectedValue: {
        value: any;
        label?: string | undefined;
        isNew?: boolean | undefined;
    };
}
const getDataType = (type: string) => {
    if (type === dropDownOptions.boolean) {
        return dropDownOptions.boolean;
    } else if (type === dropDownOptions.number || type === dropDownOptions.integer) {
        return dropDownOptions.number;
    } else {
        return 'text';
    }
};

const CustomTableBody = (props: CustomTableBodyProps & ReturnType<typeof mapStateToProps>) => {
    const {
        tableType,
        functionType,
        setMandatory,
        selectedValue,
        getInputValue,
        calculationData,
        functionErrorFlag,
        getValueAndUpdate,
        handleSelectedValue,
        getDesValueAndUpdate,
        handleUpdateDeleteList,
        deleteFunctionItems,
    } = props;

    console.log('CustomTableBody props : ', props);

    const disableSeverityOutput = (index: number) => {
        let updatedFunctions: string[] = [];
        functionType.forEach(
            (condition: CustomTableBodyProps['functionType'][0], conditionIndex: number) => {
                if (conditionIndex !== index) {
                    updatedFunctions.push(condition.name);
                }
            }
        );
        if (updatedFunctions.indexOf('severity') == -1) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <tbody>
            {functionType.map(
                (condition: CustomTableBodyProps['functionType'][0], conditionIndex: number) => {
                    const calcSelectedValue = setDataTypes([condition])[0];
                    let dataTypeSelected: any = getDataType(
                        selectedValue[conditionIndex] && selectedValue[conditionIndex][0]['value']
                    );
                    let conditionsList: any = condition;
                    conditionsList = {
                        [columns.dataType]: conditionsList.dataType,
                        [columns.isMandatory]: conditionsList.isMandatory,
                        [columns.value]: conditionsList.value ? conditionsList.value : '-',
                        [columns.description]: conditionsList.description
                            ? conditionsList.description
                            : '-',
                    };

                    return (
                        <InputOutputTableRow
                            conditionIndex={conditionIndex}
                            functionErrorFlag={functionErrorFlag}
                            tableType={tableType}
                            condition={condition}
                            functionType={functionType}
                            handleUpdateDeleteList={handleUpdateDeleteList}
                            deleteFunctionItems={deleteFunctionItems}
                            getValueAndUpdate={getValueAndUpdate}
                            conditionsList={conditionsList}
                            selectedValue={selectedValue}
                            calcSelectedValue={calcSelectedValue}
                            handleSelectedValue={handleSelectedValue}
                            setMandatory={setMandatory}
                            dataTypeSelected={dataTypeSelected}
                            getInputValue={getInputValue}
                            getDesValueAndUpdate={getDesValueAndUpdate}
                            rowType={TABLE_ROW_CONSTANTS.INPUTOUTPUT}
                            disableSeverityOutput={disableSeverityOutput}
                            functionHeaderDetails={props.functionHeaderDetails}
                        />
                    );
                }
            )}

            {tableType === TABLE_IO_TYPE.OUTPUT &&
                calculationData &&
                calculationData.map(
                    (
                        condition: CustomTableBodyProps['functionType'][0],
                        conditionIndex: number
                    ) => {
                        const calcSelectedValue = setDataTypes([condition])[0];
                        let conditionsList: any = condition;
                        conditionsList = {
                            [columns.dataType]: conditionsList.dataType,
                            [columns.isMandatory]: conditionsList.isMandatory,
                            [columns.value]: conditionsList.value ? conditionsList.value : '-',
                            [columns.description]: conditionsList.description
                                ? conditionsList.description
                                : '-',
                        };

                        return (
                            <InputOutputTableRow
                                functionErrorFlag={functionErrorFlag}
                                conditionIndex={conditionIndex}
                                tableType={tableType}
                                condition={condition}
                                functionType={functionType}
                                handleUpdateDeleteList={handleUpdateDeleteList}
                                deleteFunctionItems={deleteFunctionItems}
                                getValueAndUpdate={getValueAndUpdate}
                                conditionsList={conditionsList}
                                selectedValue={selectedValue}
                                calcSelectedValue={calcSelectedValue}
                                handleSelectedValue={handleSelectedValue}
                                setMandatory={setMandatory}
                                getInputValue={getInputValue}
                                getDesValueAndUpdate={getDesValueAndUpdate}
                                rowType={TABLE_ROW_CONSTANTS.CALCULATIONOUTPUT}
                                functionHeaderDetails={props.functionHeaderDetails}
                            />
                        );
                    }
                )}
        </tbody>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        functionErrorFlag: state.functions.functionErrorFlag,
        functionHeaderDetails: state.functions.functionHeaderDetails,
    };
};

export default connect(mapStateToProps)(CustomTableBody);
