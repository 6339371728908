import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Dialog, Button, Checkbox } from '@abb/abb-common-ux-react';

import './style.scss';
import { StoreState } from '../../store';
import {
    saveModelsToExport,
    exportModels,
    computeModelsForExport,
    saveModelsToState,
    setImportModals,
    importModals,
} from '../../store/exportAsset/actions';
import SpinnerModal from '../SpinnerModal';
import { AssetType } from '../../transformers/AssetType';
import { EXPORT_MODELS_MODES, IMPORT, EXPORT } from '../../utils/constants/appConstants';
import MultipleExportModal from './MutipleModalExport';
import ImportForSingleType from './ImportForSingleType';
import { CONFIRMATION_BUTTON } from '../../utils/constants/uiConstants';
import { showNotificationModal } from '../../store/notificationModal/action';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';

interface ModalExportDialogProps {
    showExportDialog: boolean;
    onExportClose: (val: boolean) => void;
    modelMode?: 'MODEL' | 'OBJECT' | 'MULTIPLE_OBJECTS';
    importMode?: boolean;
    importForObjectType?: boolean;
}
const ModalExportDialog = (
    props: ModalExportDialogProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDisPatchToProps>
) => {
    const {
        modelMode,
        importMode,
        activeModel,
        importModals,
        onExportClose,
        multipleModals,
        supportedModels,
        setImportModals,
        showExportDialog,
        activeModelsList,
        showSpinnerModal,
        exportModelsArray,
        saveModelsToState,
        saveModelsToExport,
        importForObjectType,
        selectedModalDetails,
        computeModelsForExport,
    } = props;

    const [singleModalCheck, updateSingleModalCheck] = useState(false);
    const [activeModelsArray, updateActiveModalsArray] = useState([] as ComputeModelToJson[]);
    const [checkedArray, updateCheckedArray] = useState([] as any);
    const [validImportFile, setValidImportFile] = useState(false);
    const [importErrorMsg, setImportErrorMsg] = useState('');
    const [importObjectTypes, setImportObjectTypes] = useState([] as any);

    const [invalidSingleImport, setInvalidSingleImport] = useState('');

    const handleModalExport = () => {
        if (modelMode) {
            const payload = {
                mode: modelMode,
                selectedModalDetails,
            };
            exportModelsArray(payload);
        }
    };

    useEffect(() => {
        if (showExportDialog) {
            updateSingleModalCheck(false);
            saveModelsToExport(modelMode === EXPORT_MODELS_MODES.MODEL ? [activeModel] : []);
        }
    }, [showExportDialog]);

    useEffect(() => {
        if (modelMode === EXPORT_MODELS_MODES.OBJECT) {
            let modelsArray: any = Object.values(activeModelsList);
            updateActiveModalsArray(modelsArray);
            let checks = modelsArray.map((model: ComputeModelFromJson) => {
                return false;
            });
            updateCheckedArray(checks);
        }
    }, [activeModelsList]);

    const closeMultipleModal = () => {
        const actionPayload = {
            fetchedModals: [],
            isDelete: true,
        };
        saveModelsToState(actionPayload);
        saveModelsToExport([]);
    };

    const checkValidityForImportFile = (val: any) => {
        if (val) {
            if (/.zip$/i.test(val.name) && val.name !== '') {
                setImportErrorMsg('');
                setValidImportFile(true);
            } else {
                setImportErrorMsg('Can Only Import zip files');
            }
        } else {
            setValidImportFile(false);
            setImportErrorMsg('');
        }
    };

    const closeImportExportDialog = () => {
        onExportClose(false);
        setImportModals(false);
        setValidImportFile(false);
        setImportErrorMsg('');
    };

    const handleImport = async () => {
        if (!importMode) {
            handleModalExport();
        }
        onExportClose(false);
        if (!importMode && modelMode === EXPORT_MODELS_MODES.MULTPLE_OBJECTS) {
            closeMultipleModal();
        }
    };

    const checkUnCheck = (index: number) => {
        if (!checkedArray[index]) {
            let modelSelected: ComputeModelToJson[] = _.cloneDeep(selectedModalDetails);
            modelSelected.push(activeModelsArray[index]);
            saveModelsToExport(modelSelected);
        } else {
            let modelSelected: ComputeModelToJson[] = _.cloneDeep(selectedModalDetails);
            modelSelected = modelSelected.filter(
                (model: ComputeModelToJson) =>
                    model.properties.model.name.value !==
                        activeModelsArray[index].properties.model.name.value ||
                    model.properties.model.typeId.value !==
                        activeModelsArray[index].properties.model.typeId.value ||
                    model.properties.model.version.value !==
                        activeModelsArray[index].properties.model.version.value
            );
            saveModelsToExport(modelSelected);
        }
        let updatedCheckedArray = _.cloneDeep(checkedArray);
        updatedCheckedArray[index] = !updatedCheckedArray[index];
        updateCheckedArray(updatedCheckedArray);
    };

    return (
        <Dialog
            isOpen={showExportDialog}
            showCloseButton
            title={importMode ? IMPORT : EXPORT}
            dimBackground={true}
            onClose={() => {
                if (!showSpinnerModal) {
                    onExportClose(false);
                    if (modelMode === EXPORT_MODELS_MODES.MULTPLE_OBJECTS) {
                        closeMultipleModal();
                    }
                    if (importMode) {
                        closeImportExportDialog();
                    }
                }
            }}
            className={`export-dialog-container ${
                !importMode &&
                (modelMode === EXPORT_MODELS_MODES.MODEL ||
                    modelMode === EXPORT_MODELS_MODES.OBJECT)
                    ? 'single-export'
                    : ''
            }${importMode && importForObjectType ? 'local-import' : ''}`}
        >
            <div className="export-dialog-body">
                {importMode && importForObjectType && (
                    <ImportForSingleType
                        importObjectTypes={importObjectTypes}
                        setImportObjectTypes={setImportObjectTypes}
                        invalidSingleImport={invalidSingleImport}
                        setInvalidSingleImport={setInvalidSingleImport}
                        closeImportExportDialog={closeImportExportDialog}
                    />
                )}
                {importMode && !importForObjectType && (
                    <>
                        <input
                            type="file"
                            accept=".zip"
                            onChange={(event: any) => {
                                checkValidityForImportFile(event.target.files[0]);
                            }}
                        ></input>
                        <div className="import-error">{importErrorMsg}</div>
                    </>
                )}
                <div className="overflow-y-class">
                    {!importMode && modelMode === EXPORT_MODELS_MODES.MODEL && activeModel && (
                        <div className="model-mode">
                            <div className="model-name">
                                Export {activeModel.modelDetails.name} into a Zip file
                            </div>
                        </div>
                    )}
                </div>
                {!importMode && modelMode === EXPORT_MODELS_MODES.MULTPLE_OBJECTS && (
                    <MultipleExportModal
                        supportedModels={supportedModels}
                        multipleModals={multipleModals}
                        showSpinnerModal={showSpinnerModal}
                        saveModelsToExport={saveModelsToExport}
                        selectedModalDetails={selectedModalDetails}
                        computeModelsForExport={computeModelsForExport}
                        saveModelsToState={saveModelsToState}
                    />
                )}

                <div className="overflow-y-class">
                    {!importMode &&
                        modelMode === EXPORT_MODELS_MODES.OBJECT &&
                        activeModelsArray.map((modelDetails: ComputeModelToJson, index: number) => {
                            return (
                                <div className="model-mode">
                                    <Checkbox
                                        sizeClass="small"
                                        value={checkedArray[index]}
                                        onChange={() => checkUnCheck(index)}
                                    />
                                    <div className="model-name">
                                        {modelDetails.properties.model.name.value}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>

            {!(importMode && importForObjectType) && (
                <React.Fragment>
                    <div className="button-section">
                        <Button
                            sizeClass="medium"
                            text={CONFIRMATION_BUTTON.CANCEL}
                            type="ghost"
                            disabled={showSpinnerModal}
                            onClick={() => {
                                onExportClose(false);
                                if (modelMode === EXPORT_MODELS_MODES.MULTPLE_OBJECTS) {
                                    closeMultipleModal();
                                }
                                if (importMode) {
                                    closeImportExportDialog();
                                }
                            }}
                        />
                        <Button
                            sizeClass="medium"
                            text={importMode ? 'Import' : 'Export'}
                            type="primary-blue"
                            onClick={handleImport}
                            disabled={
                                importMode
                                    ? !validImportFile
                                    : selectedModalDetails.length
                                    ? false
                                    : true
                            }
                        />
                    </div>
                </React.Fragment>
            )}
        </Dialog>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        multipleModals: state.exportAsset.multipleModels,
        supportedModels: state.modelsPage.supportedModels,
        activeModelsList: state.modelsPage.originalComputeModels,
        activeModel: state.modelsPage.activeModel.modelInstance,
        selectedModalDetails: state.exportAsset.selectedModalDetails,
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        activeAsset: state.modelsPage.activeAsset,
    };
};

const mapDisPatchToProps = (dispatch: Dispatch) => {
    return {
        saveModelsToExport: (val: any) => {
            dispatch(saveModelsToExport(val));
        },
        exportModelsArray: (val: {
            mode: 'MODEL' | 'OBJECT' | 'MULTIPLE_OBJECTS';
            selectedModalDetails: [];
        }) => dispatch(exportModels(val)),
        computeModelsForExport: (payload: {
            objectRef: string;
            asset: AssetType;
            uniqueTypeId: string;
        }) => {
            dispatch(computeModelsForExport(payload));
        },
        saveModelsToState: (payload: any) => {
            dispatch(saveModelsToState(payload));
        },
        setImportModals: (val: boolean) => dispatch(setImportModals(val)),
        importModals: (
            val: {
                model: ComputeModelToJson;
                functionTypes: object[];
            }[]
        ) => dispatch(importModals(val)),
        showNotificationModal: (val: any) => dispatch(showNotificationModal(val)),
    };
};

export default connect(mapStateToProps, mapDisPatchToProps)(ModalExportDialog);
