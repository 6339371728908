import * as _ from "lodash";
import { connect } from "react-redux";
import { StoreState } from "../../../../store";
import {
    Button,
    Checkbox,
    Input,
    Dropdown,
    DropdownOption,
    Icon,
} from "@abb/abb-common-ux-react";
import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    CSSProperties,
    useRef,
} from "react";
import { ConditionState } from "../../constants";
import CustomTitle from "../../../../components/CustomTitle";
import {
    CONFIRMATION_BUTTON,
    HIDDEN_LOGIC_TEXT,
    VALIDATION_MESSAGE,
} from "../../../../utils/constants/uiConstants";
import ReactSelect from "react-select";
import {
    HeaderGroup,
    Row,
    useBlockLayout,
    useFilters,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
    useFlexLayout,
} from "react-table";
import {
    getNewUniqueName,
    finalConditionJSON,
    getDisabledForButton,
    convertedConditionObj,
    hasNegativeValue,
    validateForSwedishAndFinishCharacters,
    getDataForStatusKey,
    hasWhiteSpace,
    hasNoSpecialCharactersAndDot,
    validateForSpecialCharacters_Including_HyphenAndUnderscore,
} from "../../helper";
import { ShowMessageDispatchProps } from "../..";
import { columns, DropDownItem } from "../../type";
import Flex from "../../../../components/shared/Flex";
import FunctionLogic from "../../FunctionLogic";
import { Dispatch } from "redux";
import { FunctionHeaderTypeDetails } from "../../../../store/function/types";
import {
    updateFunctionHeaderTypeDetails,
    handleFunctionErrorFlag,
} from "../../../../store/function/action";
import {
    hasErrorInConditionsData,
    getUniqueName,
    checkForDuplicateConditionName,
    customReactSelectStyles,
    reactSelectMenuStyle,
} from "../../../../utils/helpers";
import CustomLeftNav from "../../../../components/CustomLeftNavigation";
import AddConditionModal from "../../AddConditionModal";
import SubConditionDetail from ".././SubConditionDetail";
import "../style.scss";
import Collapsible from "../../Collapsible";
import Condition from "../../../../components/shared/Condition";
import { jsxElement } from "@babel/types";
import { get } from "lodash";
import { FUNCTION_MODE } from "../../../../utils/constants/appConstants";
import CustomABBInput from "../../../../components/CustomABBInput";

interface SubconditionsViewProps {
    handleConditions?: (data: any) => void;
    handleConditionError?: (value: boolean) => void;
    selectedSubCondition: any;
    selectedConditionIndex: number;
    selectedConditionName: string;
    selectedSubConditionIndex: number;
    setSelectedSubConditionIndex: (index: number) => any;
    getConditionName: (index: number) => string;
    conditionFunctionType?: any;
    updateConditionFunctionType?: (value: any) => any;
    showLogicDialog?: boolean;
    setShowLogicDialog?: (value: boolean) => any;
    logicValue?: any;
    updateLogicValue?: (value: any) => any;
    functionHeaderDetails?: any;
    emptyErrors?: string[];
    functionMode?: "VIEW" | "EDIT" | "CREATE";
    isEncrypted?: boolean;
}

interface TableRowdata {
    name: string;
    severity: number;
    logic: string;
    description: string;
    possibleCause: string;
    suggestedAction: string;
    correctiveAction: string;
    status: string;
    error?: { valid: boolean; text: string };
}

function SubconditionsView(props: SubconditionsViewProps) {
    const {
        conditionFunctionType,
        selectedSubCondition,
        updateConditionFunctionType,
        selectedConditionIndex,
        selectedConditionName,
        selectedSubConditionIndex,
        setSelectedSubConditionIndex,
        getConditionName,
        handleConditionError,
        showLogicDialog,
        setShowLogicDialog,
        logicValue,
        updateLogicValue,
        emptyErrors,
        functionMode,
    } = props;

    const headerDataforSubConditions = useMemo(
        () => [
            {
                Header: "SUB CONDITION NAME",
                accessor: "name",
                width: 200,
                minWidth: 80,
                path: "name",
            },
            {
                Header: "SEVERITY",
                accessor: "severity",
                width: 100,
                minWidth: 50,
                path: "severity",
            },
            {
                Header: "LOGIC",
                accessor: "logic",
                width: 200,
                minWidth: 80,
                path: "logic",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
                width: 200,
                minWidth: 80,
                path: "description",
            },
            {
                Header: "POSSIBLE CAUSE",
                accessor: "possibleCause",
                width: 200,
                minWidth: 80,
                path: "possibleCause",
            },
            {
                Header: "SUGGESTED ACTION",
                accessor: "suggestedAction",
                width: 200,
                minWidth: 80,
                path: "suggestedAction",
            },
            {
                Header: "CORRECTIVE ACTION",
                accessor: "correctiveAction",
                width: 200,
                minWidth: 80,
                path: "correctiveAction",
            },
            {
                Header: "STATUS",
                accessor: "status",
                width: 300,
                minWidth: 300,
                path: "status",
            },
        ],
        [conditionFunctionType]
    );
    const data = useMemo(() => {
        let tableData: TableRowdata[] = [];
        if (
            selectedConditionIndex >= 0 &&
            selectedConditionName &&
            conditionFunctionType[selectedConditionIndex]
        ) {
            conditionFunctionType[selectedConditionIndex][
                selectedConditionName
            ] &&
                conditionFunctionType[selectedConditionIndex][
                    selectedConditionName
                ].forEach((element: any) => {
                    const rowItem: TableRowdata = {
                        name: element.name,
                        severity: element.severity.value,
                        logic: element.logic.value,
                        description: element.description.value,
                        possibleCause: element.possibleCause.value,
                        suggestedAction: element.suggestedAction.value,
                        correctiveAction: element.correctiveAction.value,
                        status: element.status.value
                            ? element.status.value
                            : "",
                    };
                    if (element.error && element.error.text) {
                        rowItem.error = element.error;
                    }
                    tableData.push(rowItem);
                });
        }
        return tableData;
    }, [conditionFunctionType, selectedConditionIndex, selectedConditionName]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    } = useTable(
        //@ts-ignore
        { columns: headerDataforSubConditions, data: data },
        useResizeColumns,
        useFlexLayout
    );

    const dataSetForStatusKey = useMemo(() => {
        if (props.functionHeaderDetails) {
            return getDataForStatusKey([
                ...Object.keys(props.functionHeaderDetails.inputDetails).map(
                    (item) => `$input.${item}`
                ),
            ]);
        }
        return [];
    }, [props.functionHeaderDetails]);

    const handleLogicDialog = (value: any) => {
        updateLogicValue &&
            updateLogicValue({
                condition: conditionFunctionType[selectedConditionIndex],
                name: logicValue.name,
                value: value,
                subConditionIndex: selectedSubConditionIndex,
            });
        setShowLogicDialog && setShowLogicDialog(!showLogicDialog);
    };

    const getFieldUpdate = (val: string, fieldName: string) => {
        const conditionName: string = getConditionName(selectedConditionIndex);
        let hasError = false;
        const currentConditionFunctionType = _.cloneDeepWith(
            conditionFunctionType
        );
        let getObj =
            currentConditionFunctionType[selectedConditionIndex][conditionName][
                selectedSubConditionIndex
            ];
        if (fieldName === "name") {
            let checkObj =
                currentConditionFunctionType[selectedConditionIndex][
                    conditionName
                ];
            const checkObjIndex = _.findIndex(checkObj, { name: val });
            if (checkObjIndex !== -1 || val.trim() === "") {
                handleConditionError && handleConditionError(true);
                let errorText =
                    val.trim() === ""
                        ? VALIDATION_MESSAGE.EMPTY_NAME
                        : `${val} already exists`;
                getObj["error"] = {
                    valid: false,
                    text: errorText,
                };
                getObj[fieldName] = val;
                hasError = true;
            } else if (hasWhiteSpace(val)) {
                let errorText: string = VALIDATION_MESSAGE.SPACE;
                handleConditionError && handleConditionError(true);
                getObj["error"] = {
                    valid: false,
                    text: errorText,
                };
                getObj[fieldName] = val;
                hasError = true;
            } else if (
                !validateForSpecialCharacters_Including_HyphenAndUnderscore(val)
            ) {
                let errorText: string = VALIDATION_MESSAGE.SPECIAL_CHARACTERS;
                handleConditionError && handleConditionError(true);
                getObj["error"] = {
                    valid: false,
                    text: errorText,
                };
                getObj[fieldName] = val;
                hasError = true;
            } else {
                // handleConditionError(false);
                delete getObj["error"];
                getObj[fieldName] = val;
            }
        } else if (fieldName === "severity") {
            console.log(Number(val));
            if (
                (!Number(val) && Number(val) !== 0) ||
                (Number(val) > 1000 && Number(val) < 1)
            ) {
                return;
            } else if (Number(val) > 1000 || val === "0") {
                return;
            } else if (!hasNegativeValue(val) && val !== "" && val !== "0") {
                handleConditionError && handleConditionError(true);
                getObj["severityError"] = {
                    valid: false,
                    text: "Negative values not allowed",
                };
                hasError = true;
            } else {
                // handleConditionError(false);
                delete getObj["severityError"];
                getObj[fieldName][columns.value] =
                    val !== "" ? Number(val) : val;
            }
        } else {
            // handleConditionError(false);

            getObj[fieldName][columns.value] = val;
        }

        currentConditionFunctionType.map(
            (conditionType: any, index: number) => {
                const conditionName: string = getConditionName(index);
                let conditionTypeFunction =
                    currentConditionFunctionType[index][conditionName];
                conditionTypeFunction.map(
                    (conditionList: any, index: number) => {
                        if (conditionList.error) {
                            const errorName: string =
                                conditionList.error["text"].split(" ")[0];
                            const filteredCount: number = _.size(
                                _.filter(conditionTypeFunction, {
                                    name: errorName,
                                })
                            );
                            if (filteredCount === 1) {
                                delete conditionTypeFunction[index]["error"];
                            } else {
                                handleConditionError &&
                                    handleConditionError(true);
                            }
                        }
                    }
                );
            }
        );
        const hasErrorInCondition = hasErrorInConditionsData(
            currentConditionFunctionType
        );

        if (!hasError && !hasErrorInCondition) {
            handleConditionError && handleConditionError(false);
            props.handleConditions &&
                props.handleConditions(
                    finalConditionJSON(currentConditionFunctionType)
                );
        }
        updateConditionFunctionType &&
            updateConditionFunctionType(currentConditionFunctionType);
    };

    const setEmptyError = (field: string, fieldValue: string, name: string) => {
        if (
            selectedSubCondition &&
            selectedSubCondition["severityError"] &&
            field === "severity"
        ) {
            return {
                valid: selectedSubCondition["severityError"]["valid"],
                text: selectedSubCondition["severityError"]["text"],
            };
        } else if (
            emptyErrors &&
            emptyErrors.length > 0 &&
            fieldValue === "" &&
            field === "Severity"
        ) {
            let error = "";
            emptyErrors.forEach((errorText: string): any => {
                if (errorText.includes(name) && errorText.includes(field)) {
                    error = errorText;
                }
            });
            if (error.length) {
                return { valid: false, text: error };
            } else {
                return { valid: true, text: "OK!" };
            }
        } else if (
            emptyErrors &&
            emptyErrors.length > 0 &&
            fieldValue === "" &&
            field === "Logic"
        ) {
            let error = "";
            emptyErrors.forEach((errorText: string): any => {
                if (errorText.includes(name) && errorText.includes(field)) {
                    error = errorText;
                }
            });
            if (error.length) {
                return { valid: false, text: error };
            } else {
                return { valid: true, text: "OK!" };
            }
        } else {
            return { valid: true, text: "OK!" };
        }
    };

    return (
        <>
            <div {...getTableProps()} className="table">
                <div
                    style={{
                        display: "inline-table",
                        position: "sticky",
                        backgroundColor: "#fff",
                        zIndex: 1,
                        top: 0,
                        width: "100%",
                        paddingTop: "2rem",
                    }}
                >
                    {headerGroups.map((headerGroup: any) => (
                        <div
                            {...headerGroup.getHeaderGroupProps({})}
                            className="tr"
                        >
                            {headerGroup.headers.map(
                                (column: any, index: number) => {
                                    return (
                                        <div
                                            className="th"
                                            key={index}
                                            {...column.getHeaderProps()}
                                            //     column.getSortByToggleProps({
                                            //         style: {
                                            //             borderBottom: `solid ${column.isSorted ? '1px' : '1px'
                                            //                 } ${column.isSorted
                                            //                     ? '#3366ff'
                                            //                     : '#bababa'
                                            //                 }`,
                                            //         },
                                            //     })
                                            // )}
                                            onClick={() => {
                                                //column.toggleSortBy();
                                                // props.handleSortAssetTableData({
                                                //     sortBy: column.path,
                                                // });
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                {column.render("Header")}
                                                <div
                                                    className={`${
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? "descending-symbol"
                                                                : "ascending-symbol"
                                                            : ""
                                                    }`}
                                                />
                                            </div>
                                            <div
                                                //@ts-ignore
                                                {...column.getResizerProps()}
                                                className={`resizer ${
                                                    //@ts-ignore
                                                    column.isResizing
                                                        ? "isResizing"
                                                        : ""
                                                }`}
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row: Row<TableRowdata>, i: number) => {
                        prepareRow(row);
                        //const selectedSubCondition = row.original.name;
                        return (
                            <div
                                className={`tr ${
                                    selectedSubConditionIndex === i
                                        ? "selected-row"
                                        : ""
                                }`}
                                {...row.getRowProps({
                                    // style: {
                                    //     backgroundColor: isChecked
                                    //         ? '#dbdbdb'
                                    //         : '',
                                    // },
                                })}
                            >
                                <div
                                    className="td"
                                    {...row.cells[0].getCellProps()}
                                >
                                    <CustomABBInput
                                        className="condition-fields"
                                        dataType="text"
                                        value={row.original.name}
                                        placeholder="Enter Subcondition Name"
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        disabled={
                                            functionMode === FUNCTION_MODE.VIEW
                                        }
                                        onGotFocus={() => {
                                            if (selectedSubConditionIndex !== i)
                                                setSelectedSubConditionIndex(i);
                                        }}
                                        onValueChange={(value) =>
                                            getFieldUpdate(value, "name")
                                        }
                                        validator={() =>
                                            row.original &&
                                            row.original.error &&
                                            row.original.error.text
                                                ? row.original.error
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[1].getCellProps()}
                                >
                                    <CustomABBInput
                                        className="condition-fields required"
                                        dataType="tel"
                                        placeholder="Enter Severity"
                                        value={row.original.severity.toString()}
                                        disabled={
                                            functionMode === FUNCTION_MODE.VIEW
                                        }
                                        onGotFocus={() => {
                                            if (selectedSubConditionIndex !== i)
                                                setSelectedSubConditionIndex(i);
                                        }}
                                        onValueChange={(value) =>
                                            getFieldUpdate(value, "severity")
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        validator={() =>
                                            setEmptyError(
                                                "Severity",
                                                row.original.severity.toString(),
                                                row.original.name
                                            )
                                        }
                                    />
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[2].getCellProps()}
                                >
                                    {functionMode === FUNCTION_MODE.VIEW ? (
                                        <textarea
                                            className="disabled-textarea"
                                            disabled
                                            value={
                                                props.isEncrypted
                                                    ? HIDDEN_LOGIC_TEXT
                                                    : row.original.logic
                                            }
                                            rows={2}
                                        />
                                    ) : (
                                        <div
                                            className="logic-text-container"
                                            onClick={() =>
                                                handleLogicDialog(
                                                    selectedSubCondition &&
                                                        selectedSubCondition
                                                            .logic.value
                                                )
                                            }
                                        >
                                            <CustomABBInput
                                                className="condition-fields required"
                                                dataType="textarea"
                                                placeholder="Logic"
                                                value={row.original.logic}
                                                onGotFocus={() => {
                                                    if (
                                                        selectedSubConditionIndex !==
                                                        i
                                                    )
                                                        setSelectedSubConditionIndex(
                                                            i
                                                        );
                                                }}
                                                onValueChange={(value) =>
                                                    handleLogicDialog(
                                                        selectedSubCondition &&
                                                            selectedSubCondition
                                                                .logic.value
                                                    )
                                                }
                                                showValidationBarWhenInvalid={
                                                    true
                                                }
                                                showValidationIconWhenInvalid={
                                                    true
                                                }
                                                instantValidation={true}
                                                validator={() =>
                                                    setEmptyError(
                                                        "Logic",
                                                        row.original.logic,
                                                        row.original.name
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[3].getCellProps()}
                                >
                                    {functionMode === FUNCTION_MODE.VIEW ? (
                                        <textarea
                                            className="disabled-textarea"
                                            disabled
                                            value={row.original.description || ''}
                                            rows={2}
                                        />
                                    ) : (
                                        <CustomABBInput
                                            className="condition-fields"
                                            dataType="textarea"
                                            value={row.original.description}
                                            onGotFocus={() => {
                                                if (
                                                    selectedSubConditionIndex !==
                                                    i
                                                )
                                                    setSelectedSubConditionIndex(
                                                        i
                                                    );
                                            }}
                                            onValueChange={(value) =>
                                                getFieldUpdate(
                                                    value,
                                                    "description"
                                                )
                                            }
                                            placeholder="Enter Subcondition Description"
                                        />
                                    )}
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[4].getCellProps()}
                                >
                                    {functionMode === FUNCTION_MODE.VIEW ? (
                                        <textarea
                                            className="disabled-textarea"
                                            disabled
                                            value={row.original.possibleCause || ''}
                                            rows={2}
                                        />
                                    ) : (
                                        <CustomABBInput
                                            className="condition-fields"
                                            dataType="textarea"
                                            placeholder="Enter Possible Cause"
                                            value={row.original.possibleCause}
                                            onGotFocus={() => {
                                                if (
                                                    selectedSubConditionIndex !==
                                                    i
                                                )
                                                    setSelectedSubConditionIndex(
                                                        i
                                                    );
                                            }}
                                            onValueChange={(value) =>
                                                getFieldUpdate(
                                                    value,
                                                    "possibleCause"
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[5].getCellProps()}
                                >
                                    {functionMode === FUNCTION_MODE.VIEW ? (
                                        <textarea
                                            className="disabled-textarea"
                                            disabled
                                            value={row.original.suggestedAction || ''}
                                            rows={2}
                                        />
                                    ) : (
                                        <CustomABBInput
                                            className="condition-fields"
                                            dataType="textarea"
                                            placeholder="Enter Suggested Action"
                                            value={row.original.suggestedAction}
                                            onGotFocus={() => {
                                                if (
                                                    selectedSubConditionIndex !==
                                                    i
                                                )
                                                    setSelectedSubConditionIndex(
                                                        i
                                                    );
                                            }}
                                            onValueChange={(value) =>
                                                getFieldUpdate(
                                                    value,
                                                    "suggestedAction"
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[6].getCellProps()}
                                >
                                    {functionMode === FUNCTION_MODE.VIEW ? (
                                        <textarea
                                            className="disabled-textarea"
                                            disabled
                                            value={
                                                row.original.correctiveAction || ''
                                            }
                                            rows={2}
                                        />
                                    ) : (
                                        <CustomABBInput
                                            className="condition-fields"
                                            dataType="textarea"
                                            placeholder="Enter Corrective Action"
                                            value={
                                                row.original.correctiveAction
                                            }
                                            onGotFocus={() => {
                                                if (
                                                    selectedSubConditionIndex !==
                                                    i
                                                )
                                                    setSelectedSubConditionIndex(
                                                        i
                                                    );
                                            }}
                                            onValueChange={(value) =>
                                                getFieldUpdate(
                                                    value,
                                                    "correctiveAction"
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    className="td"
                                    {...row.cells[7].getCellProps()}
                                >
                                    <ReactSelect
                                        className="condition-active"
                                        isClearable={true}
                                        options={dataSetForStatusKey}
                                        isDisabled={
                                            functionMode === FUNCTION_MODE.VIEW
                                        }
                                        onFocus={() => {
                                            if (selectedSubConditionIndex !== i)
                                                setSelectedSubConditionIndex(i);
                                        }}
                                        placeholder="Status"
                                        //@ts-ignore
                                        value={
                                            row.original &&
                                            row.original.status && {
                                                label: row.original.status,
                                                value: row.original.status,
                                            }
                                        }
                                        getOptionLabel={(optionObj: any) => {
                                            return `${optionObj.label}`;
                                        }}
                                        getOptionValue={(optionObj: any) => {
                                            return `${optionObj.value}`;
                                        }}
                                        onChange={(value, actionMeta) => {
                                            let selectedValue =
                                                (value && value.value) || "";
                                            getFieldUpdate(
                                                selectedValue,
                                                "status"
                                            );
                                        }}
                                        styles={{
                                            ...customReactSelectStyles,
                                            menu: reactSelectMenuStyle,
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <div style={{ overflow: 'auto', minHeight: 'calc(100% - 31px)' }}>
                <Flex fill row justifyContentBetween>
                    <CustomABBInput
                        className="condition-fields"
                        dataType="text"
                        value={selectedSubCondition && selectedSubCondition.name}
                        label="Name"
                        placeholder="Enter Subcondition Name"
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                        instantValidation={true}
                        onValueChange={(value) => getFieldUpdate(value, 'name')}
                        validator={() =>
                            selectedSubCondition && selectedSubCondition['error']
                                ? {
                                      valid: selectedSubCondition['error']['valid'],
                                      text: selectedSubCondition['error']['text'],
                                  }
                                : { valid: true, text: 'OK!' }
                        }
                    />

                    <CustomABBInput
                        className="condition-fields"
                        dataType="textarea"
                        label="Description"
                        value={selectedSubCondition && selectedSubCondition.description.value}
                        onValueChange={(value) => getFieldUpdate(value, 'description')}
                        placeholder="Enter Subcondition Description"
                    />
                    <CustomABBInput
                        className="condition-fields required"
                        dataType="tel"
                        label="Severity"
                        placeholder="Enter Severity"
                        value={selectedSubCondition && selectedSubCondition.severity.value}
                        onValueChange={(value) => getFieldUpdate(value, 'severity')}
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                        instantValidation={true}
                        validator={() => setEmptyError('Severity')}
                    />
                </Flex>
                <Flex fill row justifyContentBetween>
                    <CustomABBInput
                        className="condition-fields"
                        dataType="textarea"
                        label="Possible Cause"
                        placeholder="Enter Possible Cause"
                        value={selectedSubCondition && selectedSubCondition.possibleCause.value}
                        onValueChange={(value) => getFieldUpdate(value, 'possibleCause')}
                    />

                    <CustomABBInput
                        className="condition-fields"
                        dataType="textarea"
                        label="Suggested Action"
                        placeholder="Enter Suggested Action"
                        value={selectedSubCondition && selectedSubCondition.suggestedAction.value}
                        onValueChange={(value) => getFieldUpdate(value, 'suggestedAction')}
                    />
                    <CustomABBInput
                        className="condition-fields"
                        dataType="textarea"
                        label="Corrective Action"
                        placeholder="Enter Corrective Action"
                        value={selectedSubCondition && selectedSubCondition.correctiveAction.value}
                        onValueChange={(value) => getFieldUpdate(value, 'correctiveAction')}
                    />
                </Flex>
                <Flex fill row justifyContentBetween>
                    <div
                        className="logic-text-container"
                        onClick={() =>
                            handleLogicDialog(
                                selectedSubCondition && selectedSubCondition.logic.value
                            )
                        }
                    >
                        <Flex fill row>
                            <CustomABBInput
                                className="condition-fields required"
                                dataType="textarea"
                                label="Logic"
                                placeholder="Logic"
                                value={selectedSubCondition && selectedSubCondition.logic.value}
                                onValueChange={(value) =>
                                    handleLogicDialog(
                                        selectedSubCondition && selectedSubCondition.logic.value
                                    )
                                }
                                showValidationBarWhenInvalid={true}
                                showValidationIconWhenInvalid={true}
                                instantValidation={true}
                                validator={() => setEmptyError('Logic')}
                            />
                            <div className="textarea-icon-holder">
                                <Icon
                                    name="abb/plus-in-circle"
                                    sizeClass="small"
                                    className="icon"
                                    onClick={() =>
                                        handleLogicDialog(
                                            selectedSubCondition && selectedSubCondition.logic.value
                                        )
                                    }
                                ></Icon>
                            </div>
                        </Flex>
                    </div>
                </Flex>
                <Flex fill row justifyContentBetween>
                    <Dropdown
                        className="condition-active"
                        label="Status"
                        value={[
                            {
                                value: selectedSubCondition && selectedSubCondition.status.value,
                                label: selectedSubCondition && selectedSubCondition.status.value,
                            },
                        ]}
                        onChange={(event) => {
                            let selectedValue = event[0].value || '';
                            getFieldUpdate(selectedValue, 'status');
                        }}
                        placeholder="Status"
                        clearable={true}
                    >
                        {dataSetForStatusKey.map((item: DropDownItem) => {
                            return (
                                <DropdownOption
                                    label={item.label}
                                    value={item.value}
                                    key={item.label}
                                ></DropdownOption>
                            );
                        })}
                    </Dropdown>
                    <ReactSelect
                        className="condition-active"
                        isClearable={true}
                        options={dataSetForStatusKey}
                        placeholder="Status"
                        value={
                            selectedSubCondition &&
                            selectedSubCondition['status']['value'] && {
                                label: selectedSubCondition['status']['value'],
                                value: selectedSubCondition['status']['value'],
                            }
                        }
                        getOptionLabel={(optionObj: any) => {
                            return `${optionObj.label}`;
                        }}
                        getOptionValue={(optionObj: any) => {
                            return `${optionObj.value}`;
                        }}
                        onChange={(value, actionMeta) => {
                            let selectedValue = (value && value.value) || '';
                            getFieldUpdate(selectedValue, 'status');
                        }}
                    />
                </Flex>
            </div> */}
        </>
    );
}
export default SubconditionsView;
