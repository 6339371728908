import React, { useState, useEffect, useCallback } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Icon, Checkbox } from '@abb/abb-common-ux-react';

import './style.scss';
import { StoreState } from '../../../../../store';
import Condition from '../../../../../components/shared/Condition';
import { SupportedTypes, SelectedTypes } from '../../../../../store/instanceConfig/types';
import {
    changeActiveModelInstanceConfig,
    viewupdateSelectedSupportedType,
    updateSelectedCriticalityModel,
    updateSelectedSupportedType,
    updateSelectedSupportedTypemodel,
    checkedListofItems,
    checkedListofItemupdate,
    CheckedAllItems,
} from '../../../../../store/instanceConfig/actions';
import { getMaskedName } from '../../../../../utils/helpers';
import { ComputeModelToJson } from '../../../../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { SupportedTypesForUpdate } from '..';
import _ from 'lodash';

interface AssetConfigModalItemProps {
    supportedType: SupportedTypesForUpdate;
}

const AssetConfigModalItem = (
    props: AssetConfigModalItemProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { supportedType } = props;

    const [isCollapsed, setIsCollapsed] = useState(true);
    // const [selectedTypeName, updateSelectedTypeName] = useState(undefined as any);
    const [selectedTypeId, updateSelectedTypeId] = useState(undefined as any);
    const [supportedTypeList, updateSupportedTypeList] = useState(supportedType.value.types);
    const [spot, setspot] = useState([] as any);
    const [checked, setChecked] = useState([] as string[]);
    const [selectedTestTypeId, updateTestSelectedTypeId] = useState([] as string[]);
    const [selectedvalue, setSelectedvalue] = useState([] as any);
    const [duplicate, setDuplicate] = useState([] as any);

    const handleSelectedOption = (
        id: string,
        version: string,
        objectId: string,
        name: string,
        selectedItem: string[]
    ) => {
        let selectedSupportedType: SelectedTypes = {};
        let selectedvaluesAvilable: any = _.cloneDeepWith(props.selectedTypesUpdate);

        // let typeValue = selectedTestTypeId

        let duplicateValue = duplicate;
        let checkedvalue = checked;
        let selctedInstance: any = [];
        let instanceValue: string[] = [];

        if (props.selectedSupportedType) {
            selectedSupportedType = { ...props.selectedSupportedType };
        }

        props.selectedInstances.map((e, i) => {
            selctedInstance.push(props.instanceTableDataById[props.selectedInstances[i]]);
        });
        console.log('selected Instance -->', selctedInstance);

        selctedInstance.map((items: any) => {
            items.amInfo.map((selectedInstance: any) => {
                let values = selectedInstance.selectedInstance;
                if (selectedItem.includes(values) && !instanceValue.includes(values)) {
                    instanceValue.push(items.objectId);
                }
            });
        });
        console.log(id + version + selectedItem.join(''));
        console.log(duplicateValue);
        if (duplicateValue.includes(id + version + selectedItem.join(''))) {
            debugger;
            var index = duplicateValue.indexOf(id + version + selectedItem.join(''));
            console.log(duplicateValue, index);
            var valueOfSelection: any = [];
            if (index !== -1) {
                duplicateValue.splice(index, 1);
            }
            // typeValue = typeValue.filter(item => item !== (id + version))

            updateTestSelectedTypeId(duplicateValue);
            setDuplicate(duplicateValue);
            var indexs = selectedvaluesAvilable.forEach((items: any) => {
                valueOfSelection.push(items.typeId);
            });
            let indexss = valueOfSelection.indexOf(id);

            console.log(indexss);

            if (indexss !== -1) {
                selectedvaluesAvilable.splice(indexss, 1);
            }
            setSelectedvalue(selectedvaluesAvilable);
            setChecked(checkedvalue);
        } else {
            checkedvalue.push(id + version);
            setChecked(checkedvalue);

            // typeValue.push(id + version);
            // uniquevalues = checkedvalue.filter((c, index) => {
            // return checkedvalue.indexOf(c) === index;
            // });

            duplicateValue.push(id + version + selectedItem.join(''));
            updateTestSelectedTypeId(duplicateValue);
            setDuplicate(duplicateValue);
            selectedvaluesAvilable.push({
                modelId: supportedType.key,
                objectId: objectId,
                typeId: id,
                version: version,
                selectedInstance: instanceValue,
            });

            setSelectedvalue(selectedvaluesAvilable);
            selectedSupportedType[supportedType.key] = {
                selectedType: id,
                version: version,
                objectId: objectId,
            };
        }
        // selectedvaluesAvilable.forEach((element: any) => {
        //     selectedSupportedType[element.modelId] = {
        //         selectedType: element.typeId,
        //         version: element.version,
        //         objectId: element.objectId,
        //     };
        // });
        props.updateSelectedSupportedType(selectedSupportedType);
        props.updateSelectedSupportedTypemodel(selectedvaluesAvilable);
        console.log(selectedvalue, checkedvalue, 'selectedTestTypeId');
        // props.CheckedAllItems(selectedvalue)

        if (duplicate.length === 1) {
            props.checkedListofItems(false);
            props.checkedListofItemupdate(false);
        } else if (duplicate.length === 0) {
            props.checkedListofItems(true);
            props.checkedListofItemupdate(true);
        } else {
            props.checkedListofItems(true);
            props.checkedListofItemupdate(false);
        }
    };

    useEffect(() => {
        debugger;
        console.log(selectedTestTypeId, 'selectedTestTypeId');
        let selectedObjectId: any = [];
        let assetInstance: any = [];
        props.updateSelectedSupportedType({});
        props.updateSelectedSupportedTypemodel([]);
        props.checkedListofItems(true);
        props.checkedListofItemupdate(true);
        if (props.isInstanceConfigured) {
            // const assetInstance = props.instanceTableDataById[props.selectedInstances[0]];
            // const assetInstanceAmInfo = assetInstance.amInfo;
            console.log(props.selectedInstances, 'props.selectedInstances');

            const assetInstanceAmInfo: any[] = [];
            props.selectedInstances.map((e, i) => {
                assetInstanceAmInfo.push(
                    props.instanceTableDataById[props.selectedInstances[i]].amInfo
                );
                selectedObjectId.push(props.instanceTableDataById[props.selectedInstances[i]]);
                assetInstance.push(
                    props.instanceTableDataById[props.selectedInstances[i]].objectId
                );
            });
            console.log(
                'supportedType --> ',
                supportedType,
                'assetInstanceAmInfo -->',
                assetInstanceAmInfo,
                'selectedObjectId',
                selectedObjectId,
                'assetInstance -->',
                assetInstance
            );
            // const matchedModelType = assetInstanceAmInfo.find(
            //     (item: any) => item[0].assetMonitorModel === supportedType.key
            //     )
            var matchedModelType: any[] = [];
            assetInstanceAmInfo.map((element) => {
                console.log(element);
                const matchedModelType1 = element.find(
                    (item: any) => item.assetMonitorModel === supportedType.key
                );
                if (matchedModelType1) {
                    matchedModelType.push(matchedModelType1);
                }
            });
            console.log(matchedModelType);

            matchedModelType.map((items: any, index) => {
                console.log(items);
                selectedObjectId.forEach((instance: any, instanceIndex: any) => {
                    instance.amInfo &&
                        instance.amInfo.forEach((info: any) => {
                            if (_.isEqual(info, items)) {
                                items['selectedInstance'] = instance.objectId;
                            }
                        });
                });
                //items['selectedInstance'] = selectedObjectId[index].objectId;
            });

            console.log(matchedModelType, 'matchedModelType');
            var items: any[] = [];
            var newItems: any = [];
            matchedModelType.map((item) => {
                let newSelectedTypeId = item.assetMonitorType.split('@')[0];
                const SupportedTypesListData = supportedType.value.types.filter(
                    (item) => item.typeId === newSelectedTypeId
                );

                console.log(SupportedTypesListData, supportedType.value);
                updateSupportedTypeList(SupportedTypesListData);
                items.push(...SupportedTypesListData);
            });
            console.log(items);

            items.map((ele, index) => {
                // newItems.push({
                //     typeId: ele.typeId,
                //     typeName: ele.typeName,
                //     version: ele.version,
                //     objectId: ele.objectId,
                // });
                if (index === 0) {
                    newItems.push({
                        typeId: ele.typeId,
                        typeName: ele.typeName,
                        version: ele.version,
                        objectId: ele.objectId,
                        selectedItem: [matchedModelType[index].selectedInstance],
                    });
                } else {
                    let newItemIndex = newItems.findIndex(
                        (item: any) => item.objectId === ele.objectId
                    );
                    if (newItemIndex !== -1) {
                        newItems[newItemIndex].selectedItem = [
                            ...newItems[newItemIndex].selectedItem,
                            matchedModelType[index].selectedInstance,
                        ];
                    } else {
                        newItems.push({
                            typeId: ele.typeId,
                            typeName: ele.typeName,
                            version: ele.version,
                            objectId: ele.objectId,
                            selectedItem: [matchedModelType[index].selectedInstance],
                        });
                    }
                }
            });
            // newItems.map((item: any, index: any) => {
            //     item.selectedItem = assetInstance[index];
            // });
            console.log(newItems, 'newItems');
            setspot(newItems);
        }
        // else {
        //     updateSupportedTypeList([...supportedType.value.types]);
        // }
    }, []);

    return (
        <div className="asset-config-model-item">
            <div className="collapse-item-header">
                <Icon
                    name={isCollapsed ? 'abb/plus' : 'abb/minus'}
                    sizeClass="small"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
                <div className="collapse-item-title">{supportedType.value.modelTypeLabel}</div>
            </div>
            {!isCollapsed && (
                <div className="collapse-item-list">
                    <Condition when={true}>
                        {supportedType.value.types.length === 0 ? (
                            <div>No types available !</div>
                        ) : (
                            spot.map((item: any) => {
                                console.log('here', selectedTestTypeId, supportedTypeList, spot);
                                const { maskedTypeId } = getMaskedName(item);
                                const Name = item.typeName;
                                const latestVersion = item.version;
                                debugger;
                                return (
                                    <Checkbox
                                        key={item.typeId + item.version}
                                        value={selectedTestTypeId.includes(
                                            item.typeId + item.version + item.selectedItem.join('')
                                        )}
                                        label={`${Name} (${maskedTypeId}) (version ${latestVersion})`}
                                        sizeClass="small"
                                        onChange={() =>
                                            handleSelectedOption(
                                                item.typeId,
                                                item.version,
                                                item.objectId,
                                                item.typeName,
                                                item.selectedItem
                                            )
                                        }
                                    />
                                );
                            })
                        )}
                    </Condition>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        selectedOption: state.instanceConfig.activeModel.id,
        selectedSupportedType: state.instanceConfig.selectedTypes,
        instanceTableDataById: state.instanceConfig.instancesTable.tableData.byId,
        selectedInstances: state.instanceConfig.instancesTable.tableData.selectedInstances,
        isInstanceConfigured: state.instanceConfig.isInstanceConfigured,
        isUpdateing: state.instanceConfig.isupdateing,
        selectedTypes: state.instanceConfig.selectedTypes,
        selectedTypesUpdate: state.instanceConfig.selectedTypesUpdate,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateSelectedOption: (payload: { modelId: string }) => {
            dispatch(changeActiveModelInstanceConfig(payload));
        },
        updateSelectedSupportedTypemodel: (payload: any) => {
            dispatch(updateSelectedSupportedTypemodel(payload));
        },
        updateSelectedSupportedType: (payload: SelectedTypes) => {
            dispatch(updateSelectedSupportedType(payload));
        },
        // viewupdateSelectedSupportedType: (payload: []) => {
        //     dispatch(viewupdateSelectedSupportedType(payload));
        // },

        checkedListofItems: (payload: boolean) => {
            dispatch(checkedListofItems(payload));
        },
        checkedListofItemupdate: (payload: boolean) => {
            dispatch(checkedListofItemupdate(payload));
        },
        handleSelectedCriticalityModel: (payload: ComputeModelToJson) => {
            dispatch(updateSelectedCriticalityModel(payload));
        },
        CheckedAllItems: (selectedvalue: any) => {
            dispatch(CheckedAllItems(selectedvalue));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetConfigModalItem);
