import { IHelpPage as HelpPage } from "./IHelpPage";
import AppSettings from '../../services/AppSettings';


const LINK = AppSettings.connectivityMode == "connected" ? "" : `/${AppSettings.prefix}`;

export const HELP_PAGE: HelpPage[] = [
  {
    title: "Overview",
    path: `${LINK}/onlinehelp/Section_Modes.1.1.html`
  },
  {
    title: "Functions",
    path: `${LINK}/onlinehelp/Section_Modes.1.2.html`
  },
  {
    title: "Creating a new Function",
    path: `${LINK}/onlinehelp/Section_Modes.1.3.html`
  },
  {
    title: "Inserting Function components",
    path: `${LINK}/onlinehelp/Section_Modes.1.4.html`
  },
  {
    title: "Models",
    path: `${LINK}/onlinehelp/Section_Modes.1.5.html`
  },
  {
    title: "Creating a New Model",
    path: `${LINK}/onlinehelp/Section_Modes.1.6.html`
  },
  {
    title: "Inserting Model Components",
    path: `${LINK}/onlinehelp/Section_Modes.1.7.html`
  },
  {
    title: "Assets",
    path: `${LINK}/onlinehelp/Section_Modes.1.8.html`
  },
  {
    title: "Adding Models to monitor assets",
    path: `${LINK}/onlinehelp/Section_Modes.1.9.html`
  }
];
