import { LineWithCircleParams, FabricILWC, FabricOLWC } from './FabricLineWithCircle';

import { FabricInputTrigger, FabricOutputPublish } from '../FabricIOTrigger';
import { FabricHistoryIcon } from '../FabricHistoryIcon';
import { isArrayDatatype } from '../../utils/helpers';
import { IODataTypes } from '../../types';
import { GRID_PIXEL } from '../UiConfig';

export interface LineWithTriggerParams extends LineWithCircleParams {
    triggered?: boolean;
    connected?: boolean;
}

export class FabricOLWP extends FabricOLWC {
    ioPublish: FabricOutputPublish;
    constructor(options: LineWithTriggerParams) {
        super(options);
        this.ioPublish = new FabricOutputPublish({
            triggered: options.triggered || false,
        });
        this.ioPublish.set('left', GRID_PIXEL);
        this.ioPublish.set('originX', 'right');
    }
}

export class FabricILWT extends FabricILWC {
    ioTrigger: FabricInputTrigger;
    historyIcon?: FabricHistoryIcon;
    constructor(options: LineWithTriggerParams) {
        super(options);
        const ioLineWidth = this.ioLine.getScaledWidth();

        this.ioTrigger = new FabricInputTrigger({ triggered: false });
        this.ioTrigger.set('left', ioLineWidth - GRID_PIXEL);
        this.ioTrigger.set('originX', 'left');

        const isArrayType = isArrayDatatype(options.lineText as IODataTypes);
        if (isArrayType) {
            this.historyIcon = new FabricHistoryIcon({
                connected: options.connected || false,
            });
            this.historyIcon.set('left', ioLineWidth + GRID_PIXEL);
            this.historyIcon.set('originX', 'left');
            this.historyIcon.set('originY', 'top');
        }
    }
}
