import _ from 'lodash';
import { IODataTypes, AssetsType } from '../../components/Fabric/types';
import { dataTypeConverterFromJson } from './dataTypeConverter';

export function pathFinder(
    object: any,
    matchingProperty: string,
    pathSeparator?: string,
    paths: string[] = [],
    path: string = ''
) {
    if (typeof object === 'object' && object !== null && object[matchingProperty]) {
        paths.push(path);
    } else {
        if (typeof object === 'object' && object !== null) {
            Object.keys(object).forEach((key) => {
                if (typeof object[key] === 'object' && object[key] !== 'null') {
                    pathFinder(
                        object[key],
                        matchingProperty,
                        pathSeparator,
                        paths,
                        path + `${pathSeparator ? pathSeparator : '.'}` + key
                    );
                }
            });
        }
    }
    return paths;
}

const getJsonAllPaths = (object: any, separator: string) => {
    let pathsArray: any = [];
    const keys = Object.keys(object);
    keys.forEach((key) => {
        let locArray = [key];
        const value = object[key];
        if (typeof value === 'object') {
            const subPathArray = getJsonAllPaths(value, separator);
            const appendedSubPathArray = subPathArray.map((pathKey: string) => {
                return key + separator + pathKey;
            });
            locArray = [...locArray, ...appendedSubPathArray];
        }
        pathsArray = [...pathsArray, ...locArray];
    });
    return pathsArray;
};

export const getAllMatchingPaths = (object: any, keyword: string, separator: string) => {
    const allPathsArray = getJsonAllPaths(object, separator);
    const filteredPaths = allPathsArray.filter((path: string) => {
        const pathArray = path.split(separator);
        return pathArray[pathArray.length - 1] === keyword;
    });
    const mappedPaths = filteredPaths.map((path: string) => {
        const pathArray = path.split(separator);
        return pathArray.splice(0, pathArray.length - 1).join(separator);
    });
    return mappedPaths;
};

export function outputFinder(variables: any) {
    const paths = pathFinder(variables, 'dataType');

    const outputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        connected: boolean;
        alarmId?: string;
        eventId?: string;
    }[] = [];
    paths.forEach((path) => {
        path = path.slice(1);
        const output = _.get(variables, path, null);

        if (output) {
            const splitedPath = path.split('.');
            const dataType = dataTypeConverterFromJson({
                dataType: output.dataType,
                items: output.items,
            });

            if (dataType) {
                let outputItem: typeof outputs[0] = {
                    id: output.path,
                    dataType,
                    name: splitedPath[splitedPath.length - 1],
                    path: path,
                    connected: false,
                };

                if (output.alarmId) {
                    outputItem = { ...outputItem, alarmId: output.alarmId };
                }
                if (output.eventId) {
                    outputItem = { ...outputItem, eventId: output.eventId };
                }
                outputs.push(outputItem);
            }
        }
    });

    return outputs;
}
