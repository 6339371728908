import _ from 'lodash';
import React from 'react';
import './style.scss';
import { TabControl, TabItem } from '@abb/abb-common-ux-react';

interface TabNavListProps {
    tabNavList: any;
    errorFlag: boolean;
    allowRemove: boolean;
    applyClass?: boolean;
    activeTab: number;
    onTabChange?: (oldIndex: number, newIndex: number) => void;
}

function TabNavList(props: TabNavListProps) {
    const { tabNavList, errorFlag, allowRemove, activeTab, onTabChange } = props;

    return (
        <TabControl
            type="primary"
            className="tab-list"
            allowRemove={allowRemove}
            activeTab={activeTab}
            onTabChange={onTabChange}
        >
            {tabNavList.map((list: string, listIndex: number) => {
                return <TabItem disabled={errorFlag ? true : false} key={listIndex} title={list} />;
            })}
        </TabControl>
    );
}

export default TabNavList;
