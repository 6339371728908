import React, { SyntheticEvent, useState } from "react";
import "./styles.scss";

interface AppProps {
    id?: string;
    checked?: any;
    isDisable?: any;
    name?: any;
    className?: string;
    onChange?: any;
    onClick?: (e: SyntheticEvent) => void;
}
function CustomCheckBox(props: AppProps) {
    return (
        <div className={`wrapper-checkbox ${props.className}`}>
            <label className="checkbox-container">
                <input
                    type="checkbox"
                    id={`${props.id ? props.id : ""}`}
                    checked={props.checked}
                    disabled={props.isDisable || false}
                    onClick={props.onClick}
                    name={props.name}
                    onChange={props.onChange}
                />
                <span className="checkmark" />
            </label>
        </div>
    );
}

export default CustomCheckBox;
