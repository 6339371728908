import React, { Fragment } from "react";
import { If } from "../../../helper";
import { DropDownOptions } from "../../../constants";
import {
    Checkbox,
    Dropdown,
    DropdownOption,
    Icon,
    Input,
    ToggleSwitch,
} from "@abb/abb-common-ux-react";
import {
    TABLE_IO_TYPE,
    TABLE_ROW_CONSTANTS,
} from "../../../../../utils/constants/appConstants";
import ReactSelect from "react-select";
import {
    customReactSelectStyles,
    reactSelectMenuStyle,
} from "../../../../../utils/helpers";
import CustomTooltip from "../../../../../components/CustomTooltip";
import CustomABBInput from "../../../../../components/CustomABBInput";

interface InputOutputTableRowProps {
    conditionIndex: number;
    tableType: "INPUT" | "OUTPUT";
    condition: any;
    functionType: {
        name: string;
        isMandatory: boolean;
        dataType: string;
        isDelete?: boolean;
        isDisabled?: boolean;
    }[];
    handleUpdateDeleteList: (x: number) => void;
    deleteFunctionItems?: (e: any, index: number) => void;
    getValueAndUpdate: (x: string, y: number) => void;
    conditionsList: any;
    selectedValue: {
        value: any;
        label?: string | undefined;
        isNew?: boolean | undefined;
    };
    calcSelectedValue: any;
    handleSelectedValue: (
        x: {
            value: any;
            label?: string;
            isNew?: boolean;
        }[],
        y: number
    ) => void;
    setMandatory?: (x: number) => void;
    dataTypeSelected?: any;
    getInputValue?: (x: any, y: number) => void;
    getDesValueAndUpdate: (x: string, y: number) => void;
    rowType: "INPUTOUTPUT" | "CALCULATIONOUTPUT";
    disableSeverityOutput?: (x: number) => boolean;
    functionHeaderDetails: any;
    functionErrorFlag: boolean;
}

const InputOutputTableRow = (props: InputOutputTableRowProps) => {
    const {
        rowType,
        tableType,
        condition,
        functionType,
        conditionIndex,
        getValueAndUpdate,
        conditionsList,
        selectedValue,
        setMandatory,
        getInputValue,
        calcSelectedValue,
        handleSelectedValue,
        dataTypeSelected,
        getDesValueAndUpdate,
        handleUpdateDeleteList,
        disableSeverityOutput,
        functionHeaderDetails,
        functionErrorFlag,
        deleteFunctionItems,
    } = props;
    return (
        <tr key={conditionIndex}>
            <td className="width-5">
                <Checkbox
                    id={`${
                        tableType === TABLE_IO_TYPE.INPUT
                            ? "CB_Function_Input"
                            : "CB_Function_Output"
                    }${conditionIndex}`}
                    sizeClass={"small"}
                    value={condition.isDelete ? true : false}
                    disabled={
                        functionErrorFlag ||
                        (rowType === "INPUTOUTPUT"
                            ? conditionIndex > functionType.length - 1
                            : true) ||
                        (tableType === TABLE_IO_TYPE.OUTPUT &&
                            condition["name"] === "severity" &&
                            Object.keys(functionHeaderDetails.conditionDetails)
                                .length !== 0)
                    }
                    onChange={() => handleUpdateDeleteList(conditionIndex)}
                />
            </td>
            <td
                className={`${
                    tableType === TABLE_IO_TYPE.INPUT ? "width-20" : "width-30"
                }`}
            >
                <CustomABBInput
                    type="normal"
                    dataType="text"
                    value={condition["name"]}
                    onValueChange={(val) =>
                        getValueAndUpdate(val, conditionIndex)
                    }
                    disabled={
                        (rowType === TABLE_ROW_CONSTANTS.INPUTOUTPUT
                            ? conditionIndex > functionType.length - 1
                            : true) ||
                        (tableType === TABLE_IO_TYPE.OUTPUT &&
                            condition["name"] === "severity" &&
                            Object.keys(functionHeaderDetails.conditionDetails)
                                .length !== 0 &&
                            disableSeverityOutput &&
                            disableSeverityOutput(conditionIndex))
                    }
                    id={`variable_name_${condition["name"]}_${conditionIndex}`}
                    className="functionClass"
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    instantValidation={true}
                    validator={() =>
                        condition["error"]
                            ? {
                                  valid: condition["error"]["valid"],
                                  text: condition["error"]["text"],
                              }
                            : { valid: true, text: "OK!" }
                    }
                />
            </td>
            <Fragment>
                <If cond={conditionsList.hasOwnProperty("dataType")}>
                    <td
                        className={`${
                            tableType === TABLE_IO_TYPE.INPUT
                                ? "width-20"
                                : "width-30"
                        }`}
                    >
                        <div className="condition-function-configuration-Edit">
                            <div className="DropDownList">
                                
                                <ReactSelect
                                closeMenuOnScroll={true}
                                menuPlacement='auto'
                                menuPortalTarget={document.body}
                                    id={`${
                                        tableType === TABLE_IO_TYPE.INPUT
                                            ? "DDL_Function_DataType$"
                                            : "DDL_Function_Output_DataType"
                                    }${conditionIndex}`}
                                    placeholder="Select DataType"
                                    isDisabled={
                                        (rowType ===
                                        TABLE_ROW_CONSTANTS.INPUTOUTPUT
                                            ? conditionIndex >
                                              functionType.length - 1
                                            : true) ||
                                        (tableType === TABLE_IO_TYPE.OUTPUT &&
                                            condition["name"] === "severity" &&
                                            Object.keys(
                                                functionHeaderDetails.conditionDetails
                                            ).length !== 0)
                                    }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isClearable={false}
                                    options={DropDownOptions}
                                    value={
                                        rowType ===
                                        TABLE_ROW_CONSTANTS.INPUTOUTPUT
                                            ? selectedValue[conditionIndex]
                                                ? selectedValue[conditionIndex]
                                                : [
                                                      {
                                                          label: calcSelectedValue[0]
                                                              .label,
                                                          value: calcSelectedValue[0]
                                                              .value,
                                                          name: conditionIndex.toString(),
                                                      },
                                                  ]
                                            : [
                                                  {
                                                      label: calcSelectedValue[0]
                                                          .label,
                                                      value: calcSelectedValue[0]
                                                          .value,
                                                      isNew: false,
                                                  },
                                              ]
                                    }
                                    getOptionLabel={(optionObj: any) => {
                                        return `${optionObj.label}`;
                                    }}
                                    getOptionValue={(optionObj: any) => {
                                        return `${optionObj.value}`;
                                    }}
                                    onChange={(value, actionMeta) => {
                                        handleSelectedValue(
                                            [
                                                {
                                                    value: value!.value,
                                                    label: value!.label,
                                                },
                                            ],
                                            conditionIndex
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </td>
                </If>
                {tableType === TABLE_IO_TYPE.INPUT && (
                    <If cond={conditionsList.hasOwnProperty("isMandatory")}>
                        <td className="width-10">
                            <Checkbox
                                id={`CB_Function_isMandatory${conditionIndex}`}
                                sizeClass={"small"}
                                value={condition["isMandatory"]}
                                onChange={() =>
                                    setMandatory && setMandatory(conditionIndex)
                                }
                            />
                        </td>
                    </If>
                )}
                {tableType === TABLE_IO_TYPE.INPUT && (
                    <If cond={conditionsList.hasOwnProperty("value")}>
                        <td className="width-20">
                            {dataTypeSelected === "boolean" ? (
                                <ToggleSwitch
                                    id={`Toggle_Function_Value${conditionIndex}`}
                                    label={
                                        condition["value"] ? "True" : "False"
                                    }
                                    showIcons={true}
                                    disabled={false}
                                    value={condition["value"]}
                                    onChange={(newState) => {
                                        getInputValue &&
                                            getInputValue(
                                                newState,
                                                conditionIndex
                                            );
                                    }}
                                />
                            ) : (
                                <CustomABBInput
                                    type="normal"
                                    dataType={dataTypeSelected}
                                    value={condition["value"]}
                                    onValueChange={(val) => {
                                        getInputValue &&
                                            getInputValue(val, conditionIndex);
                                    }}
                                    id={`Input_Function_Value${conditionIndex}`}
                                    className="functionClass"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    validator={() =>
                                        condition["numberError"]
                                            ? {
                                                  valid: condition[
                                                      "numberError"
                                                  ]["valid"],
                                                  text: condition[
                                                      "numberError"
                                                  ]["text"],
                                              }
                                            : {
                                                  valid: true,
                                                  text: "OK!",
                                              }
                                    }
                                />
                            )}
                        </td>
                    </If>
                )}

                <If cond={conditionsList.hasOwnProperty("description")}>
                    <td
                        className={
                            tableType === TABLE_IO_TYPE.INPUT
                                ? "width-25"
                                : "width-30"
                        }
                    >
                        <CustomABBInput
                            type="normal"
                            dataType="textarea"
                            value={condition["description"]}
                            onValueChange={(val) =>
                                getDesValueAndUpdate(val, conditionIndex)
                            }
                            disabled={
                                (rowType === TABLE_ROW_CONSTANTS.INPUTOUTPUT
                                    ? conditionIndex > functionType.length - 1
                                    : true) ||
                                (tableType === TABLE_IO_TYPE.OUTPUT &&
                                    condition["name"] === "severity" &&
                                    Object.keys(
                                        functionHeaderDetails.conditionDetails
                                    ).length !== 0)
                            }
                            id={`${
                                tableType === TABLE_IO_TYPE.INPUT
                                    ? "Input_Function_Description"
                                    : "Input_Function_Output_Description"
                            }${conditionIndex}`}
                            className="functionClass"
                        />
                    </td>
                </If>
            </Fragment>
            <td className="width-5 delete-icon">
                <CustomTooltip
                    position="top"
                    text={`Delete ${
                        tableType === TABLE_IO_TYPE.OUTPUT ? "Output" : "Input"
                    }`}
                >
                    <Icon
                        name="abb/trash"
                        sizeClass="medium"
                        className={
                            functionErrorFlag ||
                            (rowType === "INPUTOUTPUT"
                                ? conditionIndex > functionType.length - 1
                                : true) ||
                            (tableType === TABLE_IO_TYPE.OUTPUT &&
                                condition["name"] === "severity" &&
                                Object.keys(
                                    functionHeaderDetails.conditionDetails
                                ).length !== 0) ||
                            condition.isDelete
                                ? "disabled"
                                : ""
                        }
                        onClick={(e) => {
                            if (
                                deleteFunctionItems &&
                                !(
                                    functionErrorFlag ||
                                    (rowType === "INPUTOUTPUT"
                                        ? conditionIndex >
                                          functionType.length - 1
                                        : true) ||
                                    (tableType === TABLE_IO_TYPE.OUTPUT &&
                                        condition["name"] === "severity" &&
                                        Object.keys(
                                            functionHeaderDetails.conditionDetails
                                        ).length !== 0) ||
                                    condition.isDelete
                                )
                            ) {
                                deleteFunctionItems(e, conditionIndex);
                            }
                        }}
                    />
                </CustomTooltip>
            </td>
        </tr>
    );
};
export default InputOutputTableRow;
