import { fabric } from "fabric";
import {
    alramIconSize,
    triggerColor,
    noTriggerAlarmStrokeWidth,
    activeRefreshIconColor,
    disabledRefreshIconColor,
} from "../../UiConfig";
import { FabricFunctionRectangle } from "..";
import { SelfPositioningChild } from "../../../NodeController/types";
import FunctionTypeDetail from "../../../../../transformers/AssetType/FunctionType";

const refreshIconPath =
    "M9,7h6V1H13.52V3.7a7,7,0,1,0-.4,9.07l-1-1.07a5.51,5.51,0,1,1,.81-6.2H9Z";

const scale = alramIconSize / 18;
export interface FabricRefreshIconParams {
    group: FabricFunctionRectangle;
    asset: FunctionTypeDetail;
}
export class FabricRefreshIcon
    extends fabric.Path
    implements SelfPositioningChild
{
    data: SelfPositioningChild["data"] & { parent: FabricFunctionRectangle };

    constructor(options: FabricRefreshIconParams) {
        const { group, asset } = options;

        const isUpdateAvailable = !!asset.isUpdateAvailable;
        super(refreshIconPath, {
            stroke: isUpdateAvailable
                ? activeRefreshIconColor
                : disabledRefreshIconColor,
            strokeWidth: noTriggerAlarmStrokeWidth,
            fill: isUpdateAvailable
                ? activeRefreshIconColor
                : disabledRefreshIconColor,
            left: group.getPointByOrigin("center", "center").x || 0,
            originX: "right",
            originY: "center",
            scaleX: scale,
            scaleY: scale,
            top: group.getPointByOrigin("center", "center").y || 0,
            hoverCursor: "pointer",
            selectable: false,
        });

        this.data = {
            parent: group,
            rePosition: () => {
                this.left = group.getPointByOrigin("center", "center").x || 0;
                this.top = group.getPointByOrigin("center", "center").y || 0;
                this.scaleX = (group.scaleX || 1) * scale;
                this.scaleY = (group.scaleX || 1) * scale;
                this.bringForward();
                this.setCoords();
            },
        };
    }
}
