import React, { useState, useCallback, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Input, Button, ToggleSwitch } from "@abb/abb-common-ux-react";

import "./style.scss";
import { StoreState } from "../../../store";
import { addFunctionsToNewLibraryRequest } from "../../../store/function/action";
import { validatePasswordRegex } from "../../../utils/helpers";
import { PASSWORD_MSG } from "../../../utils/constants/appConstants";
import CustomABBInput from "../../../components/CustomABBInput";

export interface FunctionDetailsRequiredByLibrary {
    model: string;
    name: string;
    typeId: string;
    version: string;
    description: string;
    tags: string[];
}

export type AddNewLibraryWithFunctionsPayloadtype = {
    libraryName: string;
    password: string;
    withIPProtection: boolean;
};

const CreateLibraryPopup = (
    props: ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const [libraryName, setLibraryName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorDetails, setErrorDetails] = useState({
        libraryName: "",
        password: "",
        confirmPassword: "",
    });
    const [encryptionSwitch, setEncryptionSwitch] = useState(true);
    const [passwordType, setPasswordType] = useState(true);

    useEffect(() => {
        if(!props.addingNewLibrary && props.isLibraryExist) {
            setErrorDetails({
                ...errorDetails,
                libraryName: "This name already exists",
            });
        }

    },[props.addingNewLibrary,props.isLibraryExist])

    const togglePasswordType = useCallback(
        () => setPasswordType(!passwordType),
        [passwordType]
    );

    const toggleEncryptionSwitch = useCallback(() => {
        setEncryptionSwitch(!encryptionSwitch);
    }, [encryptionSwitch, setEncryptionSwitch]);

    const {
        addingNewLibrary,
        libraryAndFunctionsList,
        addFunctionsToNewLibraryRequest,
    } = props;

    const createNewLibrary = useCallback(
        (libraryDetails: AddNewLibraryWithFunctionsPayloadtype) => {
            addFunctionsToNewLibraryRequest(libraryDetails);
        },
        [addFunctionsToNewLibraryRequest]
    );

    const checkForValidations = useCallback(
        (libName, password, confirmPassword) => {
            let returnVal = true;
            if (libName.length && libraryAndFunctionsList.length) {

                if (returnVal) {
                    setErrorDetails({
                        ...errorDetails,
                        libraryName: "",
                    });
                } else {
                    return;
                }
            }

            if (password.length && !validatePasswordRegex(password)) {
                returnVal = false;
                setErrorDetails({
                    ...errorDetails,
                    password: PASSWORD_MSG.MIN_REQUIREMENT,
                });
                return;
            } else if (confirmPassword !== password) {
                returnVal = false;
                setErrorDetails({
                    ...errorDetails,
                    confirmPassword: PASSWORD_MSG.MISMATCH,
                });
            } else {
                setErrorDetails({
                    ...errorDetails,
                    password: "",
                    confirmPassword: "",
                });
            }

            if (returnVal) {
                const payload = {
                    libraryName: libName,
                    password,
                    withIPProtection: encryptionSwitch,
                };
                createNewLibrary(payload);
            }
        },
        [
            libraryAndFunctionsList,
            setErrorDetails,
            createNewLibrary,
            encryptionSwitch,
        ]
    );

    return (
        <div>
            <div>Library Name</div>
            <CustomABBInput
                placeholder="Please enter library name"
                dataType="text"
                value={libraryName}
                onValueChange={(value: string) => {
                    setLibraryName(value);
                    if (errorDetails.libraryName.length) {
                        setErrorDetails({ ...errorDetails, libraryName: "" });
                    }
                }}
                validator={() =>
                    errorDetails.libraryName.length
                        ? {
                              valid: false,
                              text: errorDetails.libraryName,
                          }
                        : {
                              valid: true,
                              text: "Ok",
                          }
                }
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                instantValidation
            />
            <div className="password">Password</div>
            <CustomABBInput
                placeholder="Please enter password"
                dataType={passwordType ? "password" : "text"}
                value={password}
                disabled={!encryptionSwitch}
                onValueChange={(value: string) => {
                    if (errorDetails.password.length) {
                        setErrorDetails({ ...errorDetails, password: "" });
                    }
                    setPassword(value);
                }}
                validator={() =>
                    errorDetails.password.length
                        ? {
                              valid: false,
                              text: errorDetails.password,
                          }
                        : {
                              valid: true,
                              text: "Ok",
                          }
                }
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                instantValidation
                icon={passwordType ? "abb/view" : "abb/hide"}
                onIconClick={() => {
                    if (password.length > 0 || passwordType === false) {
                        togglePasswordType();
                    }
                }}
            />

            <div className="password">Confirm Password</div>
            <CustomABBInput
                placeholder="Please enter password"
                dataType="password"
                value={confirmPassword}
                disabled={!encryptionSwitch}
                onValueChange={(value: string) => {
                    if (errorDetails.confirmPassword.length) {
                        setErrorDetails({
                            ...errorDetails,
                            confirmPassword: "",
                        });
                    }
                    setConfirmPassword(value);
                }}
                validator={() =>
                    errorDetails.confirmPassword.length
                        ? {
                              valid: false,
                              text: errorDetails.confirmPassword,
                          }
                        : {
                              valid: true,
                              text: "Ok",
                          }
                }
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                instantValidation
            />

            <div className="encryption-switch">
                <ToggleSwitch
                    label="With Encryption"
                    value={encryptionSwitch}
                    onChange={() => {
                        toggleEncryptionSwitch();
                        setPassword("");
                        setConfirmPassword("");
                    }}
                />
            </div>

            <div className="button-section">
                <Button
                    text={addingNewLibrary ? "Creating..." : "Create"}
                    sizeClass="small"
                    type="primary-blue"
                    disabled={
                        libraryName.length === 0 ||
                        ((password.length === 0 ||
                            confirmPassword.length === 0) &&
                            encryptionSwitch)
                    }
                    isLoading={addingNewLibrary}
                    onClick={() =>
                        checkForValidations(
                            libraryName,
                            password,
                            confirmPassword
                        )
                    }
                />
            </div>
        </div>
    );
};

const mapStateToProps = (store: StoreState) => {
    return {
        addingNewLibrary: store.functions.addingNewLibrary,
        libraryAndFunctionsList: store.functions.libraryAndFunctionsList,
        isLibraryExist: store.functions.isLibraryExist,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addFunctionsToNewLibraryRequest: (
            payload: AddNewLibraryWithFunctionsPayloadtype
        ) => dispatch(addFunctionsToNewLibraryRequest(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateLibraryPopup);
