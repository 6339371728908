import { ActionTypes } from "../actionTypes";
import { LoginActions, LoginState } from "./types";

const initialState: LoginState = {
    tenantName: "",
    isLoggedIn: false,
    loadingStatus: false,
    isLoading: false,
    errorLogin: false,
};

export const loginReducer = (
    state = initialState,
    action: LoginActions
): LoginState => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST: {
            return {
                ...state,
                tenantName: action.payload.tenantName,
                errorLogin: false,
            };
        }

        case ActionTypes.HANDLE_ERROR_LOGIN: {
            const errorStatus = action.payload;
            return {
                ...state,
                errorLogin: errorStatus,
            };
        }

        case ActionTypes.UPDATE_IS_LOGGED_IN: {
            const isLoggedIn = action.payload;
            return {
                ...state,
                isLoggedIn,
            };
        }
        case ActionTypes.HANDLE_LOADING_STATUS: {
            const loadingStatus = action.payload;
            return {
                ...state,
                loadingStatus,
            };
        }
        case ActionTypes.HANDLE_LOGIN_LOADING: {
            const isLoading = action.payload;
            return {
                ...state,
                isLoading,
            };
        }

        default: {
            return state;
        }
    }
};
