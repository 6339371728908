import React from 'react';
import { FunctionMode } from '../../../../store/function/types';
import { FUNCTION_MODE } from '../../../../utils/constants/appConstants';
import { columns } from '../../type';

interface CalculationTableHeaderProps {
    functionMode?: FunctionMode;
}

function CalculationTableHeader(props: CalculationTableHeaderProps) {
    return (
        <thead>
            <tr>
                <th className="width-3" />
                <th className={`text-uppercase width-15 `} onClick={() => {}}>
                    NAME
                </th>
                <th className="width-20">VARIABLE TYPE</th>

                <th className="text-uppercase width-13.5">{columns.description}</th>
                <th className="text-uppercase width-13.5">
                    {
                        props.functionMode && props.functionMode !== FUNCTION_MODE.VIEW && (
                            <span style={{ color: '#f03040' }}>* </span>
                        )
                    }
                    {columns.logic}
                </th>
            </tr>
        </thead>
    );
}

export default CalculationTableHeader;
