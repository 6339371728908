//import { AbilityB2CModel, OIDCModel } from '@iaedge/auth';

// @ts-ignore
const config = window.config;
export default class AppSettings {
    public static get sceEngineeringApiUrl(): string {
        return config.SCEEngineeringApiUrl;
    }

    public static get productVariantName(): string {
        return config.ProductVariant;
    }

    public static get cacheLocation(): string {
        return config.cacheLocation;
    }

    public static get b2cName(): string {
        return config.b2cName;
    }

    public static get solution(): string {
        return config.solution;
    }

    public static get clientId(): string {
        return config.clientId;
    }

    public static get redirectUrl(): string {
        return config.redirectUrl;
    }

    public static get permissions(): object {
        return config.permissions;
    }

    public static get prefix(): string {
        return config.prefix;
    }

    public static get productName(): string {
        return config.productName;
    }

    public static get instanceName(): string {
        return config.instanceName;
    }

    public static get releaseVersion(): string {
        return config.releaseInfo.releaseVersion;
    }

    public static get releaseDate(): string {
        return config.releaseInfo.releaseDate;
    }

    public static get releaseTime(): string {
        return config.releaseInfo.releaseTime;
    }

    public static get releaseInfoAbout(): string {
        return config.releaseInfo.about;
    }

    public static get releaseLimitation(): string {
        return config.releaseInfo.limitation;
    }

    public static get releaseIssue(): string {
        return config.releaseInfo.issue;
    }

    public static get AuthScheme(): string {
        return config.AuthScheme;
    }
    // public static get AbilityB2C(): AbilityB2CModel {
    //     return config.AbilityB2C;
    // }
    // public static get OIDC(): OIDCModel {
    //     return config.OIDC;
    // }
    public static get connectivityMode(): string {
        return config.connectivityMode;
    }
    public static get postLogoutRedirectUrl(): string {
        return config.postLogoutRedirectUrl;
    }
    public static get authority(): string {
        return config.authority;
    }
    public static get privacyURL(): string {
        return config.privacyURL;
    }
    public static get removeSolutionFromAuthority(): string {
        return config.removeSolutionFromAuthority;
    }
    public static get automaticSilentRenew(): string {
        return config.automaticSilentRenew;
    }
    public static get IsCOD(): boolean {
        return config.connectivityMode === 'connected' ? false : true;
    }
    public static get IpPasswordLength(): { min: number; max: number } {
        return (config && config.ipPasswordValidationLength) || { min: 8, max: 15 };
    }
    public static get zoomSliderConfig(): {
        sliderMin: number;
        sliderMax: number;
        sliderStep: number;
    } {
        return (config && config.zoomSlider) || { slidermin: 10, sliderMax: 200, sliderStep: 10 };
    }
    public static get tokenRenewalOffsetSeconds(): number {
        return config.tokenRenewalOffsetSeconds;
    }
}
