import React, { useState, useEffect, useCallback } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@abb/abb-common-ux-react';
import {
    HeaderGroup,
    Row,
    useBlockLayout,
    useFilters,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
    useFlexLayout,
} from "react-table";

import Flex from '../../../components/shared/Flex';
import ButtonBar from '../ButtonBar';
import { StoreState } from '../../../store';
import { IEdgeList } from '../../../store/assets/types';
import './style.scss';
import ConfigurationHeader from '../Header';
import { SettingsLeftNavOptions } from '../../../store/settings/types';
import { getCleanUpInstancesRequest, getCleanUpInstancesSuccess, getConnectivityEdgesActionRequest, removeCleanUpInstances } from '../../../store/assets/actions';
import CheckBox from '../../../components/CustomCheckbox';
import CustomABBInput from "../../../components/CustomABBInput";
import _ from 'lodash';
import { CONFIRMATION_BUTTON, CONFIRMATION_TITLE } from '../../../utils/constants/uiConstants';
import { hideDialog, showDialog } from '../../../store/dialog/action';
import MessageModal from '../../../components/MessageModal';
import Condition from '../../../components/shared/Condition';
import Loader from '../../../components/Loader';

interface TableRowdata {
    sceModuleId: string,
    model: string,
    type: string,
    objectId:string,
    name: string,
    version: string,
    subRows?: TableRowdata[];
}

const SceCleanUpTab = ( props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> ) => {
    const [edgeList, updateEdgeList] = useState<IEdgeList[]>([]);
    const [selectedEdge, updateSelectedEdge] = useState<IEdgeList>();
    const [cleanUpInstances, updateCleanUpInstances] = useState<any[]>([]);
    const [selectedCleanUpInstances, setSelectedCleanUpInstances] = useState<any[]>([]);
    const [searchFilters, updateFilters] = useState<{ [key: string]: { value: string } }>({})

    const columns = React.useMemo(
        () => [
            {
                Header: "NAME",
                accessor: "name",
                width: 200,
                minWidth: 200,
                path: "name",
            },
            {
                Header: "MODEL TYPE",
                accessor: "type",
                minWidth: 200,
                path: "type",
            },
        ],
        [props.cleanUpInstances]
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 100,
            width: 100,
            maxWidth: 400,
            Filter: ({
                column: {
                    filterValue,
                    preFilteredRows,
                    setFilter,
                    id,
                    path,
                    filters,
                },
                handleFilter,
            }: {
                column: {
                    filterValue: any;
                    preFilteredRows: any;
                    setFilter: any;
                    id: any;
                    path: any;
                    filters: any;
                };
                handleFilter: (payload: {
                    field: string;
                    value: string;
                }) => void;
            }) => {
                const count = preFilteredRows.length;
                return (
                    <CustomABBInput
                        dataType={"text"}
                        value={
                            searchFilters[path] ? searchFilters[path].value : ""
                        }
                        onValueChange={(value:any) => {
                            setFilter(value || undefined);
                            handleFilter({ field: path, value: value || "" }); // Set undefined to remove the filter entirely
                        }}
                        placeholder={`Search in ${count} entries...`}
                    />
                );
            },
            filters: {  },
        }),
        [props.cleanUpInstances]
    );

    const data = React.useMemo(() => {
        let tableData: TableRowdata[] = [];
        props.cleanUpInstances.forEach((data) => {
            const existingIndex = tableData.findIndex(element => element.name === data.name);
            if(existingIndex >= 0) {
                //@ts-ignore
                tableData[existingIndex].subRows = [...tableData[existingIndex].subRows, {
                sceModuleId: data.sceModuleId,
                model: data.model,
                type: data.type,
                objectId:data.objectId,
                name: "",
                version: data.version,
                subRows: [],
                }]
            }
            else {
                const rowItem: TableRowdata = {
                sceModuleId: data.sceModuleId,
                model: data.model,
                type: data.type,
                objectId:data.objectId,
                name: data.name,
                version: data.version,
                subRows: [],
            };
            tableData.push(rowItem);
            }   
        });
        console.log(tableData, "tableData");
        return tableData;
    }, [props.cleanUpInstances]);

    useEffect(() => {
        props.getConnectivityEdgesList();
        return () => {
            updateEdgeList([]);
            props.setCleanUpInstances([]);
        }
    }, []);

    useEffect(() => {
        updateEdgeList(props.edgeList);
        if (props.edgeList && Array.isArray(props.edgeList) && props.edgeList.length > 0) {
            updateSelectedEdge(props.edgeList[0]);
        }
    }, [props.edgeList]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    } = useTable(
        //@ts-ignore
        { columns, data: data, defaultColumn },
        useFilters,
        useSortBy,
        useFlexLayout,
        useRowSelect
    );

    const handleAllInstanceCheckboxClick = (e:any) => {
            if (e.target) {
                if (e.target.checked) {
                   setSelectedCleanUpInstances(props.cleanUpInstances);
                } else {
                    setSelectedCleanUpInstances([]);
                }
            }
        };

    const handleRowCheckChange = (e:any, instance:any) => {
        //console.log("aminfo1", props.aminfo)
        const target = e.target;
        if (target && target.id) {
            let updatedSelectedInstances = _.cloneDeepWith(selectedCleanUpInstances);

            if (target.checked) {
                    updatedSelectedInstances.push(instance);
            } else if (!target.checked) {
                   updatedSelectedInstances= updatedSelectedInstances.filter((element) => element.objectId !== instance.objectId)
            }
            updatedSelectedInstances = [...updatedSelectedInstances, ...instance.subRows]
            setSelectedCleanUpInstances(updatedSelectedInstances);
        }
    };

    const removeSelectedInstances = () => {
        props.showMessageModal({
            title: CONFIRMATION_TITLE.DELETE_MODEL,
            handleStandardButton: () => {
                let multipleInstances = selectedCleanUpInstances.map(element => {
                    return {objectId: element.objectId, model: element.model, sceModuleId: element.sceModuleId }
                });
                props.removeCleanUpInstances({multipleInstances: multipleInstances, edgeId: selectedEdge!.objectId});
                setSelectedCleanUpInstances([]);
            },
            warningText: 'Are you sure you want to remove selected Instances?'
        })
    }

    const handleFilters = (payload: any) => {
        const { field, value } = payload;
            let filters = searchFilters;

            const filter = filters[field];
            if (filter) {
                if (value) {
                    filter.value = value;
                } else {
                    delete filters[field];
                }
            } else {
                filters[field] = { value };
            }
            updateFilters(filters);
    }

    

    return (
        <div className="wrapper-connect-models" style={{overflow:'hidden'}}>
            <ConfigurationHeader headerText={SettingsLeftNavOptions.SCE_CLEAN_UP} />
            <div className="connect-models-form">
                <div className="parent-asset-config-form">
                    <div className="asset-config-form restore-edge-form ">
                        <Flex fill>Edges</Flex>
                        <Flex fill>
                            <select
                                className="selection"
                                value={
                                    selectedEdge !== null && selectedEdge !== undefined
                                        ? selectedEdge.objectId
                                        : ''
                                }
                                onChange={(e) => {
                                    const selectedObject = edgeList.find(
                                        (x) => x.objectId === e.currentTarget.value
                                    );
                                    if (selectedObject) {
                                        updateSelectedEdge(selectedObject);
                                    }
                                }}
                            >
                                {edgeList.map((item) => (
                                    <option key={item.objectId} value={item.objectId}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <div>
                                <Button
                                    text="Run"
                                    sizeClass="medium"
                                    shape="rounded"
                                    type="primary-blue"
                                    className="customDeleteButton"
                                    style={{ marginLeft: `12px` }}
                                    disabled={!selectedEdge || props.deleteCleanUpLoading}
                                    onClick={() => {
                                        if(selectedEdge)
                                        props.getCleanUpInstances(selectedEdge.objectId)
                                    }}
                                />
                            </div>
                        </Flex>
                    </div>
                </div>
            </div>
            <Condition when={props.isLoading}>
            <Flex fill center>
                        <Loader sizeClass={'large'} type="radial" />
                    </Flex>
            </Condition>
            <Condition when={!props.isLoading && props.cleanUpInstances.length}>
                <div className='wrapper-instances-table'>
            <div className='remove-button-wrapper'>
                    <Button text='Remove'
                     disabled={selectedCleanUpInstances.length === 0 || props.deleteCleanUpLoading} 
                     onClick={removeSelectedInstances} sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    isLoading={props.deleteCleanUpLoading}
                    className="customDeleteButton" />
            </div>
            <div {...getTableProps()} className="table" style={props.deleteCleanUpLoading ? {pointerEvents: 'none'} : {}}>
            <div
                            style={{
                                display: "inline-table",
                                position: "sticky",
                                backgroundColor: "#fff",
                                zIndex: 1,
                                top: 0,
                                width: "100%",
                            }}
                        >
                            {headerGroups.map(
                                (headerGroup: HeaderGroup<TableRowdata>) => (
                                    <>
                                        <div
                                            {...headerGroup.getHeaderGroupProps(
                                                {}
                                            )}
                                            className="tr"
                                        >
                                            <div
                                                {...headerGroup.headers[0].getHeaderProps(
                                                    {
                                                        style: {
                                                            width: "100px",
                                                            maxWidth: "100px",
                                                            minWidth: "100px",
                                                            borderBottom:
                                                                "solid 1px #bababa",

                                                            borderTop:
                                                                "1px solid #bababa",

                                                            position:
                                                                "relative",
                                                        },
                                                    }
                                                )}
                                                className="th"
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        width: "2px",
                                                        height: "100%",
                                                        backgroundColor:
                                                            "#bababa",
                                                        left: "0px",
                                                        top: "0px",
                                                    }}
                                                ></div>
                                                <CheckBox
                                                    onClick={(e) =>
                                                          props.cleanUpInstances.length > 0 &&
                                                        handleAllInstanceCheckboxClick(
                                                            e
                                                        )
                                                    }
                                                    isDisable={
                                                        props.cleanUpInstances.length === 0
                                                    }
                                                    checked={
                                                        props.cleanUpInstances.length ===
                                                            selectedCleanUpInstances.length &&
                                                        props.cleanUpInstances.length > 0
                                                    }
                                                    
                                                />
                                                <div className={`resizer`} />
                                            </div>
                                            {headerGroup.headers.map(
                                                (column: any) => {
                                                    return (
                                                        <div
                                                            className="th"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(
                                                                    {
                                                                        style: {
                                                                            borderBottom: `solid ${
                                                                                column.isSorted
                                                                                    ? "1px"
                                                                                    : "1px"
                                                                            } ${
                                                                                column.isSorted
                                                                                    ? "#3366ff"
                                                                                    : "#bababa"
                                                                            }`,
                                                                        },
                                                                    }
                                                                )
                                                            )}
                                                            onClick={() => {
                                                                column.toggleSortBy();
                                                                // props.handleSortAssetTableData(
                                                                //     {
                                                                //         sortBy: column.path,
                                                                //     }
                                                                // );
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                {column.render(
                                                                    "Header"
                                                                )}
                                                                <div
                                                                    className={`${
                                                                        column.isSorted
                                                                            ? column.isSortedDesc
                                                                                ? "descending-symbol"
                                                                                : "ascending-symbol"
                                                                            : ""
                                                                    }`}
                                                                />
                                                                <div className={`resizer`} />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div
                                            className="tr"
                                            {...headerGroup.getHeaderGroupProps(
                                                {}
                                            )}
                                        >
                                            <div
                                                {...headerGroup.headers[0].getHeaderProps(
                                                    {
                                                        style: {
                                                            width: "100px",
                                                            minWidth: "100px",
                                                            maxWidth: "100px",
                                                        },
                                                    }
                                                )}
                                            ></div>
                                            {headerGroup.headers.map(
                                                (column: any) => {
                                                    return (
                                                        <div
                                                            {...column.getHeaderProps(
                                                                {
                                                                    style: {
                                                                        padding:
                                                                            "10px",
                                                                    },
                                                                }
                                                            )}
                                                        >
                                                            <div>
                                                                {column.canFilter
                                                                    ? column.render(
                                                                          "Filter",
                                                                          {
                                                                              handleFilter: handleFilters,
                                                                          }
                                                                      )
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        {props.cleanUpInstances.length > 0 ? (
                                            <>
                                                {/* {props.filterData.length ===
                                                    0 && (
                                                    <div className="capitaliseText">
                                                        No instances Available
                                                    </div>
                                                )} */}
                                            </>
                                        ) : (
                                            <div className="capitaliseText">
                                                No instances Available
                                            </div>
                                        )}
                                    </>
                                )
                            )}
            </div>
            <div {...getTableBodyProps()} className="tbody">
                <Condition when={props.deleteCleanUpLoading}>
                <Flex fill center>
                        <Loader sizeClass={'large'} type="radial" />
                    </Flex>
                </Condition>
                <Condition when={!props.deleteCleanUpLoading}>
                    {rows.map((row: Row<TableRowdata>, i: number) => {
                        console.log(row);
                        
                                prepareRow(row);
                                
                                //@ts-ignore;
                                const selectedObjectId = row.original.objectId;
                                //console.log("selectedObjectId", selectedObjectId, props.selectedInstances)
                                const isChecked = _.cloneDeepWith(selectedCleanUpInstances).some((element) => element.objectId === selectedObjectId);
                                    

                                return (
                                    <>
                                        <div
                                            {...row.getRowProps({
                                                style: {
                                                    backgroundColor: isChecked
                                                        ? "#dbdbdb"
                                                        : "",
                                                },
                                            })}
                                            className="tr"
                                        >
                                            <div
                                                className="td"
                                                {...row.cells[0].getCellProps({
                                                    style: {
                                                        width: "100px",
                                                        minWidth: "100px",
                                                        maxWidth: "100px",
                                                    },
                                                })}
                                            >
                                                <div className="asset-menu-tabledata tr">
                                                    <CheckBox
                                                         checked={isChecked}
                                                         onChange={(e:any) =>
                                                             handleRowCheckChange(e,row.original)
                                                         }
                                                         id={selectedObjectId}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            {row.cells.map((cell) => {
                                                // console.log(cell, "row.cells")
                                                // console.log(tableRowData, 'amInfoData')

                                                return (
                                                    <div
                                                        {...cell.getCellProps()}
                                                        className="td"
                                                    >
                                                        {
                                                        // cell.column.Header ===
                                                        // "MODEL TYPE"
                                                        //     ? getMonitorType(
                                                        //           cell.row
                                                        //               .original
                                                        //               .assetMonitorType
                                                        //       )
                                                             cell.render(
                                                                  "Cell"
                                                              )}

                                                        {/* <div>
                                                            <div>
                                                                {cell.column
                                                                    .Header ===
                                                                    "MODEL TYPE" &&
                                                                getMonitorTypeUpdate(
                                                                    cell.row
                                                                        .original
                                                                        .assetMonitorType
                                                                ) ? (
                                                                    // props.configureUpdateStatus ?
                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="abb/synchronize"
                                                                            color={
                                                                                "#0000ff"
                                                                            }
                                                                            sizeClass="small"
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                color: "#0000ff",
                                                                            }}
                                                                        >
                                                                            {
                                                                                " update Available"
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {row.subRows.length > 0 &&
                                            row.subRows.map((item, index) => {
                                                prepareRow(item);
                                                return (
                                                    <div
                                                        className="tr"
                                                        {...row.getRowProps({
                                                            style: {
                                                                backgroundColor:
                                                                    isChecked
                                                                        ? "#dbdbdb"
                                                                        : "",
                                                            },
                                                        })}
                                                    >
                                                        <div
                                                            className="td"
                                                            {...row.cells[0].getCellProps(
                                                                {
                                                                    style: {
                                                                        width: "100px",
                                                                        minWidth:
                                                                            "100px",
                                                                        maxWidth:
                                                                            "100px",
                                                                    },
                                                                }
                                                            )}
                                                        />
                                                        {item.cells.map(
                                                            (cell) => {
                                                                //console.log("item.cells", item.cells)
                                                                return (
                                                                    <div
                                                                        className="td"
                                                                        {...cell.getCellProps()}
                                                                    >
                                                                        {cell.render(
                                                                                  "Cell"
                                                                              )}

                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </>
                                );
                            })}
                </Condition>
                            
                        </div>
            </div>
            </div>
            </Condition>
            
        </div>
    )

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getConnectivityEdgesList: () => {
            dispatch(getConnectivityEdgesActionRequest());
        },
        getCleanUpInstances: (edgeId: string) => {
            dispatch(getCleanUpInstancesRequest(edgeId));
        },
        setCleanUpInstances: (payload: any) => {
            dispatch(getCleanUpInstancesSuccess(payload))
        },
        removeCleanUpInstances: (payload: any) => {
            dispatch(removeCleanUpInstances(payload));
        },
        showMessageModal: (payload: {
            title: string;
            handleStandardButton: () => void;
            warningText: string;
        }) => {
            const {
                title,
                handleStandardButton,
                warningText,
            } = payload;
            const rejectButtonText = CONFIRMATION_BUTTON.CANCEL;
            const acceptButtonText = CONFIRMATION_BUTTON.CONFIRM
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: title,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: warningText,
                        standardButtonsOnBottom: [
                            {
                                text: rejectButtonText,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: acceptButtonText,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    handleStandardButton();
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};
const mapStateToProps = (store: StoreState) => {
    return {
        edgeList: store.assets.connectivityEdgeList,
        cleanUpInstances: store.assets.cleanUpInstances,
        isLoading: store.assets.sysEdgeLoading,
        deleteCleanUpLoading: store.assets.deleteCleanUpLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SceCleanUpTab);