import { combineReducers } from 'redux';

import history from './history';
import modalReducer from './modal/reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { loginReducer } from './login/reducer';
import { loaderReducer } from './loader/reducer';
import assetsReducer from './assets/reducer';
import messageModalReducer from './messageModal/reducer';
import { instanceConfigReducer } from './instanceConfig/reducer';
import FunctionReducer from './function/reducer';
import { connectRouter } from 'connected-react-router';
import NotificationModalReducer from './notificationModal/reducer';
import { modelsPageReducer } from './modelsPage/reducer';
import configurationTool from './modelsPage/saga/configurationTool';
import { configurationToolReducer } from './configurationTool/reducer';
import { exportAssetReducer } from './exportAsset/reducer';
import alarmModalReducer from './AlarmModal/reducer';
import dialogReducer from './dialog/reducer';
import gridReducer from './grid/reducer';
import supportedConditionMonitorReducer from './supportedConditionMonitors/reducer';
import settingReducer from './settings/reducer';

const allReducers = combineReducers({
    login: loginReducer,
    modal: modalReducer,
    toastr: toastrReducer,
    loader: loaderReducer,
    router: connectRouter(history),
    messageModal: messageModalReducer,
    assets: assetsReducer,
    instanceConfig: instanceConfigReducer,
    functions: FunctionReducer,
    notificationModal: NotificationModalReducer,
    modelsPage: modelsPageReducer,
    configurationTool: configurationToolReducer,
    exportAsset: exportAssetReducer,
    alarmModal: alarmModalReducer,
    dialog: dialogReducer,
    grid: gridReducer,
    supportedConditionMonitor: supportedConditionMonitorReducer,
    settings: settingReducer,
});

export default allReducers;
