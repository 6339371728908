import React, { useState, useEffect } from 'react';

import { Checkbox } from '@abb/abb-common-ux-react';
import { Dispatch } from 'redux';
import {
    InstanceMonitoringItem,
    ActionMenuValue,
} from '../../../../../../store/instanceConfig/types';
import { updateInstanceMonitoringList } from '../../../../../../store/instanceConfig/actions';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../store';
import _ from 'lodash';

export interface CriticalityRowCheckBoxProps {
    rowData: any;
    selectedAsset: any;
    selectAllItems: boolean;
    selectedMenuItem: ActionMenuValue | undefined;
}
export function CriticalityCheckboxRow(
    props: CriticalityRowCheckBoxProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) {
    const { rowData, selectedAsset, handleInstanceMonitoringList, instanceMonitoringList } = props;
    const [checkBoxStatus, updateCheckBoxStatus] = useState(false);
    const handleCheckBoxStatus = () => {
        const isChecked = !checkBoxStatus;

        // adding item to instanceMonitoring List

        const listItem: InstanceMonitoringItem = {
            key: selectedAsset.objectId,
            value: [rowData.assetMonitorModel],
        };

        const isItemPresent = instanceMonitoringList.find(
            (item) => item.key === selectedAsset.objectId
        );
        let updatedInstanceMonitoringList = [...instanceMonitoringList];

        if (isItemPresent) {
            const itemIndex = _.findIndex(instanceMonitoringList, isItemPresent);
            if (isChecked) {
                // add it to array.
                if (isItemPresent['value'].length > 0) {
                    updatedInstanceMonitoringList[itemIndex].value = [
                        ...isItemPresent.value,
                        rowData.assetMonitorModel,
                    ];
                }
            } else {
                //remove from list.

                const valueIndex = _.indexOf(isItemPresent.value, rowData.assetMonitorModel);

                if (valueIndex > -1) {
                    const updatedValue = isItemPresent.value.filter(
                        (item) => item !== rowData.assetMonitorModel
                    );

                    if (updatedValue.length === 0) {
                        updatedInstanceMonitoringList = instanceMonitoringList.filter(
                            (item) => item.key !== selectedAsset.objectId
                        );
                    } else {
                        updatedInstanceMonitoringList[itemIndex].value = [...updatedValue];
                    }
                }
            }
        } else {
            // add item if not present
            updatedInstanceMonitoringList = [...instanceMonitoringList, listItem];
        }

        handleInstanceMonitoringList([...updatedInstanceMonitoringList]);
        updateCheckBoxStatus(!checkBoxStatus);
    };

    useEffect(() => {
        const isItemPresent = instanceMonitoringList.find(
            (item) => item.key === selectedAsset.objectId
        );

        if (isItemPresent) {
            const selected = isItemPresent.value.find((item) => item === rowData.assetMonitorModel);
            if (selected) {
                updateCheckBoxStatus(true);
            } else {
                updateCheckBoxStatus(false);
            }
        } else {
            updateCheckBoxStatus(false);
        }

        console.log('isItemPresent :', isItemPresent);
    }, [instanceMonitoringList]);

    let value = '';
    if (rowData.assetMonitorType && rowData.assetMonitorType.toLowerCase().includes('.tenantid.')) {
        value = rowData.assetMonitorType.split('.').reverse().splice(2).reverse().join('.');
        const version = rowData.assetMonitorType
            .split('.')
            [rowData.assetMonitorType.split('.').length - 1].split('@');
        if (version.length > 1) {
            value = `${value}@${version[version.length - 1]}`;
        }
    } else {
        value = rowData.assetMonitorType;
    }
    const maskedTypeId =
        value && value.toLowerCase().includes('abb.local.')
            ? value.split('.').splice(2).join('.')
            : value;
    return (
        <div className="criticality-item-data">
            <div>
                <Checkbox
                    id={`${selectedAsset.name}${maskedTypeId}`}
                    value={checkBoxStatus}
                    sizeClass="small"
                    onChange={handleCheckBoxStatus}
                    disabled={
                        props.selectedMenuItem &&
                        props.selectedMenuItem.search(
                            rowData.configurationStatus.toLowerCase().slice(0, -1)
                        ) !== -1
                    }
                />
            </div>
            <div className="asset-model-type-text">{maskedTypeId}</div>
            <div className="asset-model-status-text">{rowData.configurationStatus}</div>
        </div>
    );
}
const mapStateToProps = (state: StoreState) => {
    return {
        instanceMonitoringList: state.instanceConfig.instanceMonitoringList,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleInstanceMonitoringList: (payload: InstanceMonitoringItem[]) => {
            dispatch(updateInstanceMonitoringList(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CriticalityCheckboxRow);
