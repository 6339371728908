import { AlarmEventType, ALARM_TYPE } from '../../../components/Fabric/types';

interface Params {
    nodeId: string;
    modelId: string;
    alarmId: string;
    ioName: string;
    isAlarmProperty: boolean;
    alarmType: AlarmEventType;
}

export function generateAlarmId(options: Params) {
    const { nodeId, ioName, alarmId, modelId, isAlarmProperty, alarmType } = options;
    return `${nodeId}${
        alarmType === ALARM_TYPE.GENERIC ? '*' : ''
    }#/${modelId}/alarms/alarmDefinitionId/${alarmId}/${isAlarmProperty ? 'value/' : ''}${ioName}`;
}
// same format is used by output finder
export function generateAlarmInputPath(options: {
    nodeId: string;
    ioType: string;
    ioName: string;
}) {
    const { nodeId, ioType, ioName } = options;
    return `${nodeId}.${ioType}.${ioName}`;
}

export function generateAlarmInputId(options: { nodeId: string; ioType: string; ioName: string }) {
    const { nodeId, ioName, ioType } = options;
    return `#properties/alarmTypes/${nodeId}/${ioType}/${ioName}`;
}
