import React from 'react';
import CanvasController from '../../../../components/Fabric';
import ZoomSlider from '../../../../components/Fabric/UIComponents/ZoomSlider';
import './style.scss';
import { Button, Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import Flex from '../../../../components/shared/Flex';
import { TOOLTIP_MESSAGES } from '../../../../utils/constants/uiConstants';
import CustomTooltip from '../../../../components/CustomTooltip';
interface CanvasBottomBarProps {
    canvas: CanvasController;
    className: string;
    isEditMode: boolean;
}

export default function CanvasBottomBar(props: CanvasBottomBarProps) {
    return (
        <Flex className={`${props.className} bottom-bar`} row fill>
            <Flex row className="bottom-bar-controls">
                {props.isEditMode && (
                    <CustomTooltip position="top" text={TOOLTIP_MESSAGES.FIT_TO_SCREEN}>
                        <Button
                            icon="abb/make-same-size"
                            sizeClass="small"
                            type="discreet-black"
                            onClick={() => props.canvas.adjustSize()}
                        />
                    </CustomTooltip>
                )}
                <ZoomSlider canvas={props.canvas} classname={'canvas-zoom-slider'}></ZoomSlider>
            </Flex>
        </Flex>
    );
}
