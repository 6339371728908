import React from "react";
import "./style.scss";
import Flex from "../../../components/shared/Flex";
import { StoreState } from "../../../store";
import { connect } from "react-redux";

export function InstanceName(props: ReturnType<typeof mapStateToProps>) {
    return (
        <Flex row className="wrapper-instance-name">
            <div className="instance-name">{!props.isLoading ? props.instanceName : ""}</div>
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        instanceName:
            state.instanceConfig.activeModel.modelInstance.modelDetails.name,
            isLoading: state.instanceConfig.isLoading,
    };
};

export default connect(mapStateToProps)(InstanceName);
