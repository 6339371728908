import React from 'react';
import useReactRouter from 'use-react-router';
import { withRouter, RouteComponentProps } from 'react-router';
import * as _ from 'lodash';
import Flex from '../../shared/Flex';
import ActionIcon from './ActionIcon';
import './styles.scss';
import { AppTopNavi, AppTopNaviItem, AppHeader } from '@abb/abb-common-ux-react';
import { ROUTE_PATHNAME, CONFIGURATION_TOOL_MODE } from '../../../utils/constants/appConstants';
import { StoreState } from '../../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import MessageModal from '../../MessageModal';
import {
    UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
    CONFIRMATION_BUTTON,
} from '../../../utils/constants/uiConstants';
import { updateConfigurationToolMode } from '../../../store/configurationTool/actions';
import { ConfigurationToolState } from '../../../store/configurationTool/types';
import { hideDialog, showDialog } from '../../../store/dialog/action';

enum NavBarLinks {
    assets = 'Objects',
    models = 'Models',
    functions = 'Functions',
    settings = 'Settings',
}

const NavBarTabsMapStateToProps = (state: StoreState) => {
    return {
        mode: state.configurationTool.mode,
    };
};
const NavBarTabsMapDispatchToProps = (dispatch: Dispatch, ownProps: any) => {
    return {
        handleRouteChange: (url: string) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    ownProps.history.push(url);
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },

        updateConfigurationToolMode: (payload: { mode: ConfigurationToolState['mode'] }) => {
            dispatch(updateConfigurationToolMode(payload));
        },
    };
};
export const NavBarTabs = withRouter(
    connect(NavBarTabsMapStateToProps, NavBarTabsMapDispatchToProps)(SimpleNavBarTabs)
);

export function SimpleNavBarTabs(
    props: ReturnType<typeof NavBarTabsMapStateToProps> &
        ReturnType<typeof NavBarTabsMapDispatchToProps> &
        RouteComponentProps
) {
    const { history, location } = useReactRouter();

    const loadAssets = () => {
        if (
            location.pathname === ROUTE_PATHNAME.HOME &&
            props.mode === CONFIGURATION_TOOL_MODE.EDIT
        ) {
            props.handleRouteChange(ROUTE_PATHNAME.OBJECTS);
        } else {
            history.push(ROUTE_PATHNAME.OBJECTS);
        }
    };

    const loadModels = () => {
        props.updateConfigurationToolMode({
            mode: CONFIGURATION_TOOL_MODE.VIEW,
        });
        history.push(ROUTE_PATHNAME.HOME);
    };

    const loadFunctions = () => {
        if (
            location.pathname === ROUTE_PATHNAME.HOME &&
            props.mode === CONFIGURATION_TOOL_MODE.EDIT
        ) {
            props.handleRouteChange(ROUTE_PATHNAME.FUNCTIONS);
        } else {
            history.push(ROUTE_PATHNAME.FUNCTIONS);
        }
    };
    const loadSettings = () => {
        if (
            location.pathname === ROUTE_PATHNAME.HOME &&
            props.mode === CONFIGURATION_TOOL_MODE.EDIT
        ) {
            props.handleRouteChange(ROUTE_PATHNAME.FUNCTIONS);
        } else {
            history.push(ROUTE_PATHNAME.SETTINGS);
        }
    };

    const Links: any = [
        {
            name: NavBarLinks.assets,
            icon: 'abb/list',
            text: 'Instances',
            url: ROUTE_PATHNAME.OBJECTS,
            isActive: false,
            onClick: loadAssets,
        },
        {
            name: NavBarLinks.models,
            icon: 'abb/document',
            text: 'Models',
            url: ROUTE_PATHNAME.HOME,
            isActive: false,
            onClick: loadModels,
        },
        {
            name: NavBarLinks.functions,
            icon: 'abb/network',
            text: 'Functions',
            url: ROUTE_PATHNAME.FUNCTIONS,
            isActive: false,
            onClick: loadFunctions,
        },
        {
            name: NavBarLinks.settings,
            icon: 'abb/advanced-settings',
            text: 'Configurations',
            url: ROUTE_PATHNAME.SETTINGS,
            isActive: false,
            onClick: loadSettings,
        },
    ];

    return (
        <AppTopNavi className="navigation-items">
            {Links.map((link: any, linkIndex: number) => {
                return (
                    <AppTopNaviItem
                        key={linkIndex}
                        className="nav-active-link"
                        text={link.text}
                        icon={link.icon}
                        active={location.pathname === link.url}
                        onClick={link.onClick}
                    />
                );
            })}
        </AppTopNavi>
    );
}

function ActionBar(props: any) {
    return (
        <AppHeader className="navbar flex-even header-action-bar-container">
            {/* left side of navbar */}
            <NavBarTabs />
            <Flex fill>
                <ActionIcon />
            </Flex>
        </AppHeader>
    );
}

export default ActionBar;
